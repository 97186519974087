define('lxso/helpers/is-tt-price-count-under-max', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isTtPriceCountUnderMax = isTtPriceCountUnderMax;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function isTtPriceCountUnderMax(params) {
    var _params = _slicedToArray(params, 3),
        MAX_TICKETS_NUM = _params[0],
        prices = _params[1],
        ticketTypeId = _params[2];

    var pricesLinkedToTT = prices.filter(function (p) {
      return p.get('ticketTypeId') === ticketTypeId;
    });
    return pricesLinkedToTT.length < MAX_TICKETS_NUM;
  }

  exports.default = Ember.Helper.helper(isTtPriceCountUnderMax);
});