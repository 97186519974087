define('lxso/routes/activity/offer-list', ['exports', 'lxso/config/environment', 'lxso/mixins/publish-api', 'lxso/mixins/example-modal', 'lodash/collection', 'lxso/mixins/sort-status-alphabetical', 'lxso/mixins/option-group/ticket-types-v4', 'lxso/utils/omniture'], function (exports, _environment, _publishApi, _exampleModal, _collection2, _sortStatusAlphabetical, _ticketTypesV, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_exampleModal.default, _publishApi.default, _sortStatusAlphabetical.default, _ticketTypesV.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    featureFlags: Ember.inject.service(),
    routeHistory: Ember.inject.service(),
    publishValidation: Ember.inject.service('publish-validation'),
    omniture: {
      pageName: 'page.LX-Supply.OptionsList'
    },

    model: function model() {
      var activity = this.modelFor('activity');
      var ticketTypesV4 = void 0;
      if (activity.get('migrationStatus.ticketType')) {
        ticketTypesV4 = this.store.findRecord('ticket-types-v4', activity.id, { reload: true });
      }

      return Ember.RSVP.hash({
        ticketTypesV4: ticketTypesV4
      });
    },
    setupController: function setupController(controller, model) {
      var activity = this.modelFor('activity');
      this.controller.set('activity', activity);
      this.getAllOffers();
      var platformId = this.get('session.data.authenticated.selectedBranch.platform_id');
      this.controller.set('platformId', platformId);
      this.controller.set('testTrue', true);
      var isGTBranchAndExternalUser = this.controller.get('activity.isGTBranchAndExternalUser');
      var defaultProperties = {
        optionsTableHeaderBaseKey: 'pages.optionsQuickView.list.table.',
        optionsTableHeaderKeys: Ember.A(['optionGroupTitle', 'status', 'scheduleAndOptionsId', 'commentaryAndLanguage', 'bookingCutoff', 'cancellationPolicy', 'foodAndBeverage', 'update']),
        optionsTableKeys: Ember.A(['optionGroupTitle', 'status', 'scheduleAndOptionsId', 'commentaryAndLanguage', 'bookingCutoff', 'cancellationPolicy', 'foodAndBeverage']),
        itemsPerPage: 5,
        isFilterStatusModalOpen: false,
        isFilterApplyDisabled: Ember.computed('allStatuses.@each.value', function () {
          var selectedFiltersLength = this.get('allStatuses').filterBy('value', true).length > 0;
          return !selectedFiltersLength;
        }),
        ticketTypesV4: model.ticketTypesV4,
        isTicketsOpen: true,
        editingTicketType: model.ticketTypesV4 ? model.ticketTypesV4.get('ticketTypes').filter(function (tt) {
          return tt.get('isSelected');
        }).length === 0 && !isGTBranchAndExternalUser : false
      };

      if (platformId && !activity.thirdPartySupply) {
        defaultProperties.initStatus = ['active', 'suspended'];
      } else {
        defaultProperties.initStatus = ['active'];
      }

      this.controller.setProperties(defaultProperties);
    },
    getAllOffers: function getAllOffers() {
      var _this = this;

      var activity = this.controller.get('activity');
      var activityId = activity.get('id');

      var url = this.get('featureFlags').checkEnabled('OptionsQuickView') ? _environment.default.api.host + '/api/v3/activity/' + activityId + '/offerListWithDetails' : _environment.default.api.host + '/api/v3/activity/' + activityId + '/offers';

      this.get('ajaxWrapper').ajaxGet(url).then(function (offers) {
        _this.controller.setProperties({
          compareBy: 'Name (A-Z)',
          offers: _this.sortByStatusAndAlphabetical(offers, false, 'offer')
        });
      }).then(function () {
        _this.offerTitleConcatenator(_this.controller.get('offers'));
        _this.setAvailableStatuses(_this.controller.get('offers'));
        _this.initStatusFilter(_this.controller.get('initStatus'));
        _this.publishValidations();
      });
    },
    groupMultipleOffers: function groupMultipleOffers(offers) {
      var offersWithoutGroupId = offers.filter(function (offer) {
        return !('offerGroupId' in offer);
      });
      var offersWithGroupId = offers.filter(function (offer) {
        return 'offerGroupId' in offer;
      });
      var groupedOffers = _collection2.default.groupBy(offersWithGroupId, function (offer) {
        return offer.offerGroupId + '::' + offer.status;
      });

      // loop over each multiOfferGroup
      for (var groupedOffer in groupedOffers) {
        var multiOffer = groupedOffers[groupedOffer];

        // group all offers in multiOfferGroup by language type and languages
        var groupedByLanguages = _collection2.default.groupBy(multiOffer, function (off) {
          var languages = off.richLanguageInfo.languages.map(function (lang) {
            return lang.tourLanguage;
          });
          return '' + off.richLanguageInfo.languageType + '#' + ('' + languages.join(''));
        });

        var offIndex = 0;
        for (var groupingLanguage in groupedByLanguages) {
          var groupedOfferByLanguage = groupedByLanguages[groupingLanguage];
          var scheduleTextArray = [];
          var offerIdArray = [];

          for (var i = 0; i < groupedOfferByLanguage.length; i++) {
            scheduleTextArray.push(groupedOfferByLanguage[i].scheduleText);
            offerIdArray.push(groupedOfferByLanguage[i].id);
          }

          // for each language-based offer group, add only one entry in the final offer list
          var tempObj = {
            isMultipleOption: true,
            offerGroupId: groupedOfferByLanguage[0].offerGroupId,
            title: groupedOfferByLanguage[0].title,
            shortTitle: groupedOfferByLanguage[0].shortTitle,
            status: groupedOfferByLanguage[0].status,
            scheduleText: scheduleTextArray,
            richLanguageInfo: groupedOfferByLanguage[0].richLanguageInfo,
            id: offerIdArray,
            bookingCutoffInHours: groupedOfferByLanguage[0].bookingCutoffInHours,
            cancellationPolicyType: groupedOfferByLanguage[0].cancellationPolicyType,
            cancellationCutoffInHours: groupedOfferByLanguage[0].cancellationCutoffInHours,
            offerAttributes: {
              mealType: groupedOfferByLanguage[0].offerAttributes.mealType
            },
            groupOfferCount: Object.keys(groupedByLanguages).length,
            offerIndex: offIndex
          };
          offersWithoutGroupId.push(tempObj);
          offIndex++;
        }
      }

      offersWithoutGroupId = this.sortByStatusAndAlphabetical(offersWithoutGroupId, false, 'optionsQuickView');

      // add index for each offer group that appears on the table for striped table view
      var offGroupId = -1;
      var offGroupIndex = 0;
      for (var _i = 0; _i < offersWithoutGroupId.length; _i++) {
        if (offersWithoutGroupId[_i].offerGroupId && offersWithoutGroupId[_i].offerGroupId != offGroupId) {
          offGroupIndex++;
          Ember.set(offersWithoutGroupId[_i], 'offerGroupIndex', offGroupIndex);
          offGroupId = offersWithoutGroupId[_i].offerGroupId;
        } else if (offersWithoutGroupId[_i].offerGroupId) {
          Ember.set(offersWithoutGroupId[_i], 'offerGroupIndex', offGroupIndex);
        } else {
          offGroupIndex++;
          Ember.set(offersWithoutGroupId[_i], 'offerGroupIndex', offGroupIndex);
        }
      }
      this.controller.set('optionsAfterAddingGroups', offersWithoutGroupId);
    },
    setAvailableStatuses: function setAvailableStatuses(offers) {
      var availableStatuses = [];
      offers.forEach(function (offer) {
        var status = offer.get('status');
        if (availableStatuses.indexOf(status) < 0) {
          availableStatuses.push(status);
        }
      });
      this.controller.set('availableStatuses', availableStatuses);
    },
    setFilteredOptions: function setFilteredOptions(selectedStatuses) {
      var offers = this.controller.get('offers');
      var filteredOptions = offers;
      if (!selectedStatuses) {
        filteredOptions = offers;
      } else {
        var selectedStats = [];
        selectedStatuses.forEach(function (stat) {
          selectedStats.push(stat.toLowerCase());
        });
        filteredOptions = offers.filter(function (offer) {
          return selectedStats.indexOf(offer.get('status')) >= 0;
        });
      }

      if (this.get('featureFlags').checkEnabled('MultipleOptionsGroup')) {
        this.groupMultipleOffers(filteredOptions);
        this.controller.set('filteredOptions', this.controller.get('optionsAfterAddingGroups'));
      } else {
        this.controller.set('filteredOptions', filteredOptions);
      }

      this.setSelectedStatusMessage(selectedStatuses);
    },
    setSelectedStatusMessage: function setSelectedStatusMessage(selectedStatuses) {
      var _this2 = this;

      var statusMessage = '';
      var selectedStatusesLower = selectedStatuses.map(function (item) {
        return _this2.get('i18n').t('pages.optionsList.statusFilter.' + item).toString().toLowerCase();
      }).sort();
      if (selectedStatusesLower && selectedStatusesLower.length > 0) {
        if (selectedStatusesLower.length === 1) {
          statusMessage = selectedStatusesLower[0];
        } else {
          statusMessage = selectedStatusesLower.slice(0, selectedStatusesLower.length - 1).join(', ');
          statusMessage += ' ' + this.get('i18n').t('common.words.and');
          statusMessage += ' ' + selectedStatusesLower[selectedStatusesLower.length - 1];
        }
      }
      this.controller.set('selectedStatusMessage', statusMessage);
    },
    initStatusFilter: function initStatusFilter(filterStatuses) {
      var _this3 = this;

      var availableStatuses = this.controller.get('availableStatuses');
      var selectedStatuses = filterStatuses ? filterStatuses : availableStatuses;
      var is3p = this.controller.get('activity.thirdPartySupply');
      var allStatusList = void 0;

      if (this.controller.get('platformId') && !is3p) {
        allStatusList = ['active', 'inactive', 'suspended'];
      } else {
        allStatusList = ['active', 'inactive'];
      }

      this.controller.set('selectedStatuses', selectedStatuses);
      var allStatuses = allStatusList.map(function (status) {
        return {
          name: status,
          label: _this3.get('i18n').t('pages.optionsList.statusFilter.' + status),
          helpText: _this3.get('i18n').t('pages.optionsList.statusFilter.' + status + 'HelpText'),
          value: availableStatuses && selectedStatuses.indexOf(status) >= 0
        };
      });
      this.controller.set('allStatuses', allStatuses);
      this.setFilteredOptions(selectedStatuses);
    },


    // Concatenates offer title and offer id for display:
    offerTitleConcatenator: function offerTitleConcatenator(offers) {
      for (var i = 0; i < offers.length; i++) {
        var titleAndId = offers[i].title + ' (' + offers[i].id + ')';
        offers[i].combinedTitle = titleAndId;
      }
      this.controller.set('offers', offers);
    },
    publishValidations: function publishValidations() {
      var _this4 = this;

      var isGroundTransfer = this.controller.get('activity.isViewOnlyForGroundTransfer');
      this.controller.set('isActivityPublished', this.controller.get('activity.isActivityPublished'));
      this.get('publishValidation').getPublishValidation(this.controller.get('activity')).then(function (result) {
        var invalidPublishable = 0;
        for (var key in result) {
          var isSkip = isGroundTransfer && (key === 'hasHighlights' || key === 'hasInclusions' || key === 'hasRedemptionInstructions' || key === 'hasHealthAndSafetyInfo');
          var isSkipHealthAndSafety = key === 'hasHealthAndSafetyInfo' && !_this4.get('featureFlags').checkEnabled('HealthAndSafety');
          if (!isSkip && result[key] === false && !isSkipHealthAndSafety) {
            invalidPublishable++;
          }
        }

        _this4.controller.set('isPublishable', invalidPublishable === 0);

        var overviewValidation = result.hasDescription && (result.hasHighlights || isGroundTransfer);
        _this4.controller.set('validOverview', overviewValidation);

        var detailsValidation = (result.hasInclusions || isGroundTransfer) && result.hasKnowBeforeYouBook && result.hasKnowBeforeYouGo;
        _this4.controller.set('validDetails', detailsValidation);

        _this4.controller.set('validPhotos', result.hasPhoto);
        _this4.controller.set('validLocation', result.hasLocation);
        if (_this4.controller.get('activity.isHealthAndSafetyEnabled')) {
          _this4.controller.set('validHealthAndSafetyInfo', result.hasHealthAndSafetyInfo);
        } else {
          _this4.controller.set('validHealthAndSafetyInfo', true);
        }

        var policiesValidation = result.hasContactInformation && (result.hasRedemptionInstructions || isGroundTransfer);
        _this4.controller.set('validPolicies', policiesValidation);

        var pricingValidation = result.hasPricing;
        _this4.controller.set('validPricing', pricingValidation);
      });
    },


    actions: {
      offerStatusModalClick: function offerStatusModalClick() {
        this.controller.set('selectedStatusesCopy', this.controller.get('selectedStatuses'));
        this.controller.toggleProperty('isFilterStatusModalOpen');
      },
      cancelOptionsFilter: function cancelOptionsFilter() {
        this.initStatusFilter(this.controller.get('selectedStatusesCopy'));
        this.controller.set('isFilterStatusModalOpen', false);
      },
      applyOptionsFilter: function applyOptionsFilter(allStatuses) {
        var selectedStatusArray = [];
        allStatuses.forEach(function (status) {
          if (status.value === true) {
            selectedStatusArray.push(status.name);
          }
        });
        this.controller.set('selectedStatuses', selectedStatusArray);
        this.setFilteredOptions(this.controller.get('selectedStatuses'));
        this.controller.set('isFilterStatusModalOpen', false);
      },
      queryParamsDidChange: function queryParamsDidChange() {
        // Only call getAlOffers if controller has loaded (this is an edge case and only
        // occurs if user reloads while viewing a translation
        if (this.controller) {
          this.getAllOffers();
        }
      },
      newOffer: function newOffer(isDisabled) {
        if (!isDisabled) {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.CreateOption');
          this.transitionTo('activity.offer', 'new');
        }
      },
      viewOffer: function viewOffer(offerId) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.ViewOption');
        var currentLanguage = this.controller.get('activity').get('language');
        this.transitionTo('activity.offer', offerId, { queryParams: { language: currentLanguage } });
      },
      editOffer: function editOffer(offer) {
        var offerId = void 0;
        var isMultipleOfferGroup = Boolean(offer.isMultipleOption);
        if (this.get('featureFlags').checkEnabled('OptionsQuickView')) {
          if (offer.isMultipleOption) {
            offerId = offer.offerGroupId;
          } else {
            offerId = offer.id;
          }
        } else {
          offerId = offer;
        }
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.EditOption');
        this.transitionTo('activity.offer', offerId, { queryParams: {
            isOfferGroup: isMultipleOfferGroup } });
      },
      deactivate: function deactivate(offer) {
        var offerId = void 0;
        var isMultipleOption = false;
        if (this.get('featureFlags').checkEnabled('OptionsQuickView')) {
          isMultipleOption = offer.isMultipleOption;
          if (isMultipleOption) {
            offerId = offer.offerGroupId;
          } else {
            offerId = offer.id;
          }
        } else {
          offerId = offer;
        }
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.DeactivateOption');
        this.transitionTo('activity.offer-list.deactivate', offerId, { queryParams: { isOfferGroup: isMultipleOption } });
      },
      clone: function clone(offer) {
        var offerId = void 0;
        var isMultipleOption = false;
        if (this.get('featureFlags').checkEnabled('OptionsQuickView')) {
          isMultipleOption = offer.isMultipleOption;
          if (isMultipleOption) {
            offerId = offer.offerGroupId;
          } else {
            offerId = offer.id;
          }
        } else {
          offerId = offer;
        }
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.CloneOption');
        this.transitionTo('activity.offer', offerId, { queryParams: { clone: 'true', isOfferGroup: isMultipleOption } });
      },
      promoteOffer: function promoteOffer(option) {
        var optionId = void 0;
        if (this.get('featureFlags').checkEnabled('OptionsQuickView')) {
          optionId = option.id;
        } else {
          optionId = option;
        }
        var promotionId = 'new';
        var productId = this.controller.get('activity.id');
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Optionslist.promotion');
        this.get('routeHistory').setPromotionBackURL('activity.offer-list');
        this.get('routeHistory').setProductId(productId);
        this.transitionTo('promotion', promotionId, {
          queryParams: {
            optionId: optionId,
            productId: productId,
            clone: null,
            campaignId: null
          }
        });
      },
      invalidPublish: function invalidPublish() {
        this.get('session').set('data.fromPublish', true);
      },
      publish: function publish() {
        var activityId = this.controller.get('activity.id');
        var route = this;
        route.transitionTo('activity.publish', activityId);
      },
      willTransition: function willTransition() {
        delete window.pageName;
      },
      didTransition: function didTransition() {
        window.pageName = 'offerList';
        this.controller.set('errors', '');
        this.controller.set('saving', false);
        this.getAllOffers();
      }
    }
  });
});