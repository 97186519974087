define('lxso/components/generated-input-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['componentClasses'],
    change: function change() {
      //computed property not workingz/
      var generatedValue = this.get('prefix') ? this.get('prefix') + ', ' + this.get('titleValue') + ' ' + this.get('postfix') : this.get('titleValue') + ' ' + this.get('postfix');
      this.set('generatedValue', generatedValue);
    }
  });
});