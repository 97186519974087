define('lxso/helpers/format-two-digit-decimal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatTwoDigitDecimal = formatTwoDigitDecimal;
  function formatTwoDigitDecimal(params) {
    return parseFloat(params[0]).toFixed(2);
  }

  exports.default = Ember.Helper.helper(formatTwoDigitDecimal);
});