define('lxso/utils/change-history-details-display-modes/generic', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'generic',
    diff: function diff(oldValue, newValue) {
      return diffEntire(oldValue, newValue);
    }
  };


  function diffEntire(oldValue, newValue) {
    if (oldValue === newValue) {
      return [{ value: oldValue }];
    }

    return [{ added: true, value: newValue }, { removed: true, value: oldValue }];
  }
});