define('lxso/adapters/platform-url-configuration', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      data.platformConfigurationId = parseInt(snapshot.id);

      serializer.serializeIntoHash(data, type, snapshot);
      data.platformId = snapshot._belongsToIds.platform;
      delete data.platform;
      var platformId = snapshot._belongsToIds.platform;
      var configurationEnv = snapshot._attributes.environment;
      var url = this.host + '/' + this.namespace + '/platforms/' + platformId + '/configurations/' + configurationEnv;

      return this.ajax(url, 'PUT', { data: data });
    },
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);

      data.platformId = snapshot._belongsToIds.platform;
      delete data.platform;
      var platformId = snapshot._belongsToIds.platform;
      var configurationEnv = snapshot._attributes.environment;
      var url = this.host + '/' + this.namespace + '/platforms/' + platformId + '/configurations/' + configurationEnv;

      return this.ajax(url, 'POST', { data: data });
    }
  });
});