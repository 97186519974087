define('lxso/utils/offer-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function anyRejects(validations) {
    'use strict';

    var isRejected = validations.any(function (promise) {
      return promise.state === 'rejected';
    });
    return isRejected;
  }

  function normalizeScheduleErrors(validationErrors) {
    'use strict';

    return validationErrors.map(function (scheduleAndLangGroupError) {
      return scheduleAndLangGroupError.value;
    });
  }

  function normalizeLangErrors(validationErrors) {
    'use strict';

    return validationErrors.map(function (langError) {
      return langError.reason;
    });
  }

  function buildValidationArray(validationGroup) {
    return validationGroup.filterBy('state', 'rejected').map(function (promise) {
      return promise.reason;
    }).reduce(function (a, b) {
      return Object.assign(a, b);
    }, []);
  }

  exports.anyRejects = anyRejects;
  exports.normalizeScheduleErrors = normalizeScheduleErrors;
  exports.normalizeLangErrors = normalizeLangErrors;
  exports.buildValidationArray = buildValidationArray;
});