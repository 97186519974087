define('lxso/services/threep-supplier', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    isSupplier3P: function isSupplier3P() {
      var isThirdParty = this.get('session.data.authenticated.selectedBranch.thirdPartySupply');
      return typeof isThirdParty !== 'undefined' ? isThirdParty : false;
    }
  });
});