define('lxso/utils/reports-relabel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getReportLabel;
  function getReportLabel(key) {
    switch (key) {
      case 'Supplierbranchid':
        return 'Supplier Branch ID';
      case 'Supplierbranchname':
        return 'Supplier Branch Name';
      case 'Salestatus':
        return 'Status';
      case 'Transactiondatetime':
        return 'Status Date';
      case 'OriginalBookingDate':
        return 'Original Booking Date';
      case 'Activitytitle':
        return 'Product Title';
      case 'Offertitle':
        return 'Option Title';
      case 'SupplierReferenceNumber':
        return 'Itinerary Number';
      case 'Vouchernumber':
        return 'Voucher Number';
      case 'Tickettype':
        return 'Ticket Type';
      case 'PriceCode':
        return 'Price Code';
      case 'LeadTraveler':
        return 'Lead Traveler';
      case 'Travelerphone':
        return 'Traveler Phone';
      case 'Traveleremail':
        return 'Traveler Email';
      case 'Netamount':
        return 'Net Price';
      case 'Netcurrency':
        return 'Net Currency';
      case 'Baseprice':
        return 'Retail Price';
      case 'Basecurrency':
        return 'Retail Currency';
      case 'PointOfSale':
        return 'Point of Sale';
      case 'DestinationArrivalDate':
        return 'Travel Date';
      case 'DestinationDepartureFlightDate':
        return 'Return Travel Date';
      case 'PickupLocation':
        return 'Pickup Location';
      case 'DropoffLocation':
        return 'Dropoff Location';
      case 'DestinationArrivalFlightNumber':
        return 'Arrival Airline and Flight Number ';
      case 'DestinationArrivalFlightTime':
        return 'Flight Arrival Time ';
      case 'DestinationDepartureFlightNumber':
        return 'Departure Airline and Flight Number ';
      case 'DestinationDepartureFlightTime':
        return 'Flight Departure Time ';
      case 'Journey':
        return 'Journey';
      case 'NotificationEmailStatus':
        return 'Notification Email Status';
      case 'Saleid':
        return 'Sale ID';
      case 'Offerid':
        return 'Option ID';
      case 'Activityid':
        return 'Product ID';
      case 'CustomerStayingAt':
        return 'Customer Staying At';
      case 'originalNetAmount':
        return 'Original Net Price';
      case 'originalNetAmountCurrency':
        return 'Original Net Currency';
      case 'originalBasePrice':
        return 'Original Retail Price';
      case 'originalBasePriceCurrency':
        return 'Original Retail Currency';
      case 'promotionId':
        return 'Promotion ID';
      case 'BQ Traveler Information':
        return 'BQ Traveler Information';
      case 'BQ Pickup Information':
        return 'BQ Pickup Information';
      case 'BQ DropOff Information':
        return 'BQ Dropoff Information';
      default:
        return key;
    }
  }
});