define('lxso/serializers/account-settings/change-password', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var id = 'password-for-user';

  exports.default = _emberData.default.JSONSerializer.extend({
    normalize: function normalize(typeClass, hash) {
      'use strict';

      hash.id = id;
      return this._super.apply(this, arguments);
    },
    serializeJson: function serializeJson(json) {
      'use strict';

      json.id = id;
      delete json.confirmPassword;
      return json;
    },
    serialize: function serialize(snapshot, options) {
      'use strict';

      var json = this._super.apply(this, arguments);
      return this.serializeJson(json);
    }
  });
});