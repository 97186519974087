define('lxso/models/ticket-type', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    ageRange: (0, _validation.validator)(function (value, options, model) {
      var min = model.get('restrictions.firstObject.min');
      var max = model.get('restrictions.firstObject.max');
      var retrictionType = model.get('restrictions.firstObject.restriction_type_id');
      if (retrictionType === 2) {
        return min > max ? model.get('i18n').t('errors.customMessages.offers.capacityRange').string : true;
      } else {
        return min > max ? model.get('i18n').t('errors.customMessages.offers.ageRange').string : true;
      }
    }, { volatile: true }),
    guest_type_id: (0, _validation.validator)('presence', {
      presence: true
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    activity: _emberData.default.belongsTo('activity'),
    i18n: Ember.inject.service(),
    //id is handled automagically by Ember
    internal_note: _emberData.default.attr('string', { defaultValue: '' }),
    //if this is here, ticket_type_name doesn't show
    //partnerTicketTypeId: DS.attr('number', { defaultValue: '0'}),
    referenced: _emberData.default.attr('boolean', { defaultValue: false }),
    //promotion is a single object
    promotion: (0, _attributes.fragment)('fragments/ticket-type/promotion', { defaultValue: {
        origin_ticket_type_id: 0,
        ticket_type_id: 0,
        promo_type_id: 0,
        percent_discount: 0,
        supplier_discount: 0,
        description: ''
      } }),
    guest_type_id: _emberData.default.attr('number'),
    ticket_type_name: _emberData.default.attr('string', { defaultValue: 'required' }),
    restrictions: (0, _attributes.fragmentArray)('fragments/ticket-type/restrictions', { defaultValue: [{ restriction_type_id: 1, min: 0, max: 100, is_primary: true }]
    })
  });
});