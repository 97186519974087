define('lxso/serializers/price-group-v4', ['exports', 'ember-data', 'lxso/constants/option-group', 'lxso/constants/common', 'lodash'], function (exports, _emberData, _optionGroup, _common, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      var activityId = snapshot.adapterOptions.activityId;
      var offerIds = snapshot.adapterOptions.offerIds;
      var availabilityType = snapshot.adapterOptions.availabilityType;
      var scheduleSuperType = snapshot.adapterOptions.scheduleSuperType;
      return convertDataToApiRequestModel(json, activityId, offerIds, availabilityType, scheduleSuperType);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      this.normalizePriceGroups(payload);
      this.normalizeAvailability(payload);
      return this._super.apply(this, arguments);
    },
    normalizePriceGroups: function normalizePriceGroups(priceGroups) {
      priceGroups.forEach(function (priceGroup) {
        priceGroup.bookingDatesDifferent = !!priceGroup.bookingStart;
        priceGroup.prices.map(function (price) {
          return Object.assign(price, { margin: parseFloat(price.margin.toFixed(_common.MARGIN_DECIMAL_PLACES)) });
        });
      });
    },
    normalizeAvailability: function normalizeAvailability(priceGroups) {
      priceGroups.forEach(function (priceGroup) {
        if (priceGroup.schedules && priceGroup.schedules.length) {
          if (isSchedulesAndAvailabilitySameForAllOffers(priceGroup)) {
            priceGroup.availabilityByDays = {
              priceGroupId: priceGroup.priceGroupId,
              availabilities: getAvailabilityByDaysFromOfferSchedule(priceGroup.schedules[0], priceGroup.availabilities)
            };
          } else {
            priceGroup.availabilityByOffers = getAvailabilityByOffers(priceGroup.schedules, priceGroup.availabilities);
          }
        } else {
          priceGroup.availabilityByDays = {
            priceGroupId: priceGroup.priceGroupId
          };
        }
      });
    }
  });


  var isSchedulesAndAvailabilitySameForAllOffers = function isSchedulesAndAvailabilitySameForAllOffers(priceGroup) {
    var isStartTypesBasedSchedule = _optionGroup.START_TIME_BASED_SCHEDULES.includes(priceGroup.scheduleType);
    priceGroup.schedules.forEach(function (offerSchedule) {
      var offerScheduleHash = offerSchedule.details.map(function (d) {
        if (isStartTypesBasedSchedule) {
          return '' + d.daysAvailable.join(',');
        } else {
          return d.startTime + '::' + d.endTime + '::' + d.daysAvailable.join(',');
        }
      }).join(':::');
      offerSchedule.offerScheduleHash = offerScheduleHash;
    });
    var offerIdsInSchedules = priceGroup.schedules.map(function (schedule) {
      return schedule.offerId;
    }).uniq();
    var isPriceGroupHavingAllOfferSchedules = _lodash.default.difference(priceGroup.allOfferIdsUnderOfferGroup, offerIdsInSchedules).length === 0;
    var isSchedulesSameForAllOffers = priceGroup.schedules.every(function (schedule) {
      return schedule.offerScheduleHash === priceGroup.schedules[0].offerScheduleHash;
    });

    var isAvailabilitySameForAllOffers = !priceGroup.availabilities || priceGroup.availabilities.every(function (availability) {
      return JSON.stringify(priceGroup.availabilities[0].daysAvailable) === JSON.stringify(availability.daysAvailable);
    });

    return isPriceGroupHavingAllOfferSchedules && isSchedulesSameForAllOffers && isAvailabilitySameForAllOffers;
  };

  var getAvailabilityByDaysFromOfferSchedule = function getAvailabilityByDaysFromOfferSchedule(offerSchedule, availabilities) {
    var availabilityDays = offerSchedule.details.map(function (scheduleDetail) {
      return [].concat(_toConsumableArray(scheduleDetail.daysAvailable.map(function (day) {
        return {
          day: day,
          startTime: scheduleDetail.startTime,
          endTime: scheduleDetail.endTime,
          available: true,
          capacity: availabilities && availabilities.length ? availabilities[0].daysAvailable.find(function (avDay) {
            return avDay.day === day;
          }).capacity : null
        };
      })));
    }).flat();
    var daysWithAvailability = availabilityDays.map(function (d) {
      return d.day;
    });
    var missingAvailabilityDays = _optionGroup.AVAILABILITY_DAYS_ARRAY.filter(function (day) {
      return !daysWithAvailability.includes(day);
    });
    var missingAvailabilities = missingAvailabilityDays.map(function (day) {
      return {
        day: day,
        available: false
      };
    });
    return [].concat(_toConsumableArray(availabilityDays), _toConsumableArray(missingAvailabilities)).sort(function (a, b) {
      return _optionGroup.AVAILABILITY_DAYS_ARRAY.indexOf(a.day) - _optionGroup.AVAILABILITY_DAYS_ARRAY.indexOf(b.day);
    });
  };

  var getAvailabilityByOffers = function getAvailabilityByOffers(schedules, availabilities) {
    if (availabilities && availabilities.length) {
      var groupedAvailabilityAndSchedules = [];
      availabilities.forEach(function (offerAvailability) {
        var offerAvailabilityAndSchedulesOfDays = [];
        var offerSchedule = schedules.find(function (schedule) {
          return schedule.offerId === offerAvailability.offerId;
        });
        offerAvailability.daysAvailable.forEach(function (availabilityDay) {
          var dayScheduleDetail = offerSchedule.details.find(function (scheduleDetail) {
            return scheduleDetail.daysAvailable.includes(availabilityDay.day);
          });
          if (dayScheduleDetail) {
            offerAvailabilityAndSchedulesOfDays.push({
              isSelected: true,
              available: true,
              days: [availabilityDay.day],
              offerId: offerAvailability.offerId,
              capacity: availabilityDay.capacity,
              startTime: dayScheduleDetail.startTime,
              endTime: dayScheduleDetail.endTime
            });
          }
        });
        var groupedAvailabilityAndSchedulesOfAnOffer = _lodash.default.groupBy(offerAvailabilityAndSchedulesOfDays, function (availDay) {
          return availDay.capacity + '-' + availDay.startTime + '-' + availDay.endTime;
        });

        _lodash.default.forEach(groupedAvailabilityAndSchedulesOfAnOffer, function (availDaysOfSameCapacityAndScheduleTime, groupKey) {
          groupedAvailabilityAndSchedules.push(Object.assign({}, availDaysOfSameCapacityAndScheduleTime[0], {
            days: availDaysOfSameCapacityAndScheduleTime.map(function (availDay) {
              return availDay.days;
            }).flat()
          }));
        });
      });
      return groupedAvailabilityAndSchedules;
    } else {
      var groupedSchedules = [];
      schedules.forEach(function (offerSchedule) {
        offerSchedule.details.forEach(function (scheduleDetail) {
          groupedSchedules.push({
            isSelected: scheduleDetail.daysAvailable.length > 0,
            available: scheduleDetail.daysAvailable.length > 0,
            days: scheduleDetail.daysAvailable,
            offerId: offerSchedule.offerId,
            capacity: 0,
            startTime: scheduleDetail.startTime,
            endTime: scheduleDetail.endTime
          });
        });
      });
      return groupedSchedules;
    }
  };

  var convertDataToApiRequestModel = function convertDataToApiRequestModel(priceGroup, activityId, offerIds, availabilityType, scheduleSuperType) {
    delete priceGroup.bookingDatesDifferent;
    delete priceGroup.status;
    priceGroup.prices = priceGroup.prices.filter(function (p) {
      return p.isSelected || p.priceId;
    });
    if (priceGroup.availabilityByDays && priceGroup.availabilityByDays.availabilities) {
      addPriceGroupSchedules(priceGroup, activityId, offerIds, scheduleSuperType);
      if (availabilityType === _optionGroup.AVAILABILITY_TYPES.LIMITED) {
        priceGroup.availabilities = getPriceGroupAvailability(priceGroup, offerIds);
      }
    } else {
      updateSchedulesForAvailabilityByOffers(priceGroup, activityId, offerIds, scheduleSuperType);
    }
    return priceGroup;
  };

  var addPriceGroupSchedules = function addPriceGroupSchedules(priceGroup, activityId, offerIds, scheduleSuperType) {
    var availableSchedules = priceGroup.availabilityByDays.availabilities.filter(function (aDay) {
      return aDay.available;
    });
    var groupedSchedules = _lodash.default.groupBy(availableSchedules, function (aDay) {
      return aDay.startTime + ':::' + aDay.endTime;
    });
    //creating separate schedules for each offer makes it easy to handle at the API.
    priceGroup.schedules = offerIds.map(function (offerId) {
      return {
        offerId: offerId,
        scheduleType: scheduleSuperType,
        activityId: activityId,
        details: Object.values(groupedSchedules).map(function (schedulesWithSameStartAndEndTime) {
          return {
            startTime: scheduleSuperType === _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION ? schedulesWithSameStartAndEndTime[0].startTime : null,
            endTime: scheduleSuperType === _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION ? schedulesWithSameStartAndEndTime[0].endTime : null,
            daysAvailable: schedulesWithSameStartAndEndTime.map(function (schedule) {
              return schedule.day;
            })
          };
        })
      };
    });
  };

  var getPriceGroupAvailability = function getPriceGroupAvailability(priceGroup, offerIds) {
    var availableSchedules = priceGroup.availabilityByDays.availabilities.filter(function (aDay) {
      return aDay.available;
    });
    var availability = [{
      priceGroupId: priceGroup.priceGroupId,
      offerIds: offerIds,
      daysAvailable: availableSchedules.map(function (aDay) {
        return {
          day: aDay.day,
          capacity: aDay.capacity
        };
      })
    }];

    return availability;
  };

  var updateSchedulesForAvailabilityByOffers = function updateSchedulesForAvailabilityByOffers(priceGroup, activityId, offerIds, scheduleSuperType) {
    priceGroup.availabilityByOffers = priceGroup.availabilityByOffers.filter(function (availabilityByOfferRow) {
      return availabilityByOfferRow.isSelected;
    });
    var scheduleAndAvailabilityGroupedWithOfferId = _lodash.default.groupBy(priceGroup.availabilityByOffers, function (scheduleAndAvailability) {
      return scheduleAndAvailability.offerId;
    });

    var schedules = [];
    var availabilities = [];
    _lodash.default.forEach(scheduleAndAvailabilityGroupedWithOfferId, function (scheduleAndAvailabilityGroupOfAnOffer, offerId) {
      var offerSchedule = {
        offerId: offerId,
        activityId: activityId,
        scheduleType: scheduleSuperType,
        details: []
      };
      var offerAvailability = {
        priceGroupId: priceGroup.priceGroupId,
        offerIds: [offerId],
        daysAvailable: []
      };
      scheduleAndAvailabilityGroupOfAnOffer.forEach(function (scheduleAndAvailability) {
        scheduleAndAvailability.days.forEach(function (day) {
          offerAvailability.daysAvailable.push({
            day: day,
            capacity: scheduleAndAvailability.capacity
          });
        });
      });
      availabilities.push(offerAvailability);

      var availDaysGroupedBySameScheduleTimes = _lodash.default.groupBy(scheduleAndAvailabilityGroupOfAnOffer, function (scheduleAndAvailability) {
        return scheduleAndAvailability.startTime + ':::' + scheduleAndAvailability.endTime;
      });
      _lodash.default.forEach(availDaysGroupedBySameScheduleTimes, function (schedules, scheduleTime) {
        offerSchedule.details.push({
          startTime: scheduleSuperType === _optionGroup.SCHEDULE_TYPES.START ? null : schedules[0].startTime,
          endTime: scheduleSuperType === _optionGroup.SCHEDULE_TYPES.START ? null : schedules[0].endTime,
          daysAvailable: [].concat(_toConsumableArray(schedules.map(function (s) {
            return s.days;
          }).flat())).flat()
        });
      });
      schedules.push(offerSchedule);
    });
    var offerIDsWithSchedulesAndAvailabilities = Object.keys(scheduleAndAvailabilityGroupedWithOfferId).map(function (offerId) {
      return parseInt(offerId);
    });
    var offerIDsMissingSchedulesAndAvailabilities = offerIds.filter(function (offerId) {
      return !offerIDsWithSchedulesAndAvailabilities.includes(offerId);
    });
    addSchedulesAndAvailabilitiesForMissingOffers(priceGroup, offerIDsMissingSchedulesAndAvailabilities, availabilities, schedules);
  };

  var addSchedulesAndAvailabilitiesForMissingOffers = function addSchedulesAndAvailabilitiesForMissingOffers(priceGroup, offerIds, availabilities, schedules) {
    var availabilityOfMissingOffers = offerIds.map(function (offerId) {
      return Object.assign({}, availabilities[0], {
        offerIds: [offerId],
        daysAvailable: availabilities[0].daysAvailable.map(function (day) {
          return Object.assign({}, day, { capacity: 0 });
        })
      });
    });
    var schedulesOfMissingOffers = offerIds.map(function (offerId) {
      return Object.assign({}, schedules[0], {
        offerId: offerId,
        details: [Object.assign({}, schedules[0].details[0], {
          daysAvailable: []
        })]
      });
    });
    priceGroup.availabilities = [].concat(_toConsumableArray(availabilities), _toConsumableArray(availabilityOfMissingOffers));
    priceGroup.schedules = [].concat(_toConsumableArray(schedules), _toConsumableArray(schedulesOfMissingOffers));
  };
});