define('lxso/validators/array-content-object-number', ['exports', 'ember-cp-validations/validators/number', 'lxso/mixins/array-validation-model'], function (exports, _number, _arrayValidationModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArrayContentObjectNumber = _number.default.extend(_arrayValidationModel.default, {});
  //this takes an array of objects and uses the built in number validator on
  //on the passed property of each of those objects
  ArrayContentObjectNumber.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return ['model.' + attribute + '.[]'];
    }
  });

  exports.default = ArrayContentObjectNumber;
});