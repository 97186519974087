define('lxso/models/asset', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'lxso/config/environment'], function (exports, _emberData, _attributes, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var heroString = 'lxheromedia';

  exports.default = _emberData.default.Model.extend({
    mediavault: Ember.inject.service('mediavault'),

    activity: _emberData.default.belongsTo('activity'),
    fileId: _emberData.default.attr('string'),
    supplier: _emberData.default.attr('string'),
    tags: _emberData.default.attr('array'),
    caption: _emberData.default.attr('string'),
    approval: _emberData.default.attr('string'),
    rejectReason: _emberData.default.attr('string'),
    available: (0, _attributes.fragmentArray)('asset/image'),
    name: _emberData.default.attr('string'),
    activityId: _emberData.default.attr('number'),

    displayImage: Ember.computed('available.[]', {
      get: function get() {
        var available = this.get('available');
        var maxWidth = 0;

        available.forEach(function (item) {
          if (item.get('width') > maxWidth) {
            maxWidth = item.get('width');
          }
        });

        return available.find(function (item) {
          return item.get('width') >= maxWidth;
        });
      }
    }),

    heroImage: Ember.computed('tags', {
      get: function get() {
        return this.get('tags').any(function (tag) {
          return tag == heroString;
        });
      },
      set: function set(key, value) {
        'use strict';

        var newTags = this.get('tags').addObject(heroString).filter(function (tag) {
          return tag != heroString || value;
        });
        this.set('tags', newTags);
        return value;
      }
    }),

    /**
     * Upload a file to this asset in Media Vault
     * File must be a Plupload file instance
     * @param  {class} file - Plupload file instance
     * @return {promise}    - File upload promise
     */
    uploadFile: function uploadFile(file, productId) {
      return this.get('mediavault').uploadFileToAsset(file, this, productId);
    }
  });
});