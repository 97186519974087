define('lxso/routes/activity/health-and-safety', ['exports', 'lxso/mixins/example-modal', 'lxso/utils/form-validator', 'lxso/utils/offer-group', 'lxso/mixins/feature-flag'], function (exports, _exampleModal, _formValidator, _offerGroup, _featureFlag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_featureFlag.default, _exampleModal.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    featureFlags: Ember.inject.service(),
    routeHistory: Ember.inject.service(),
    publishValidation: Ember.inject.service('publish-validation'),
    featureName: 'HealthAndSafety',
    omniture: {
      pageName: 'page.LX-Supply.TravelerHealthAndSafety'
    },

    init: function init() {
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var activityId = this.modelFor('activity').get('id');
      var healthAndSafetyInfo = this.store.findRecord('health-and-safety', activityId, { reload: true });
      return new Ember.RSVP.hash({
        healthAndSafetyInfo: healthAndSafetyInfo
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.revertToPreviousVersion();
      }
    },
    revertToPreviousVersion: function revertToPreviousVersion() {
      var healthAndSafetyInfo = this.controller.get('healthAndSafetyInfo');
      var healthAndSafetyInfoCopy = this.controller.get('healthAndSafetyInfoCopy');
      healthAndSafetyInfo.set('healthAndSafetySelected', healthAndSafetyInfoCopy.healthAndSafetySelected);
      healthAndSafetyInfo.set('enhancedCleaningQuestions', healthAndSafetyInfoCopy.enhancedCleaningQuestions);
      healthAndSafetyInfo.set('travelerSafetyQuestions', healthAndSafetyInfoCopy.travelerSafetyQuestions);
      healthAndSafetyInfo.set('covidStatusQuestions', healthAndSafetyInfoCopy.covidStatusQuestions);
    },
    setChangesMade: function setChangesMade() {
      var _this = this;

      this.controller.set('changesMade', true);
      if (this.controller.get('errors')) {
        this.validateHealthAndSafetyInfo().then(function () {
          _this.controller.set('errors', '');
          _this.controller.set('hasErrors', false);
        }).catch(function (errors) {
          _this.controller.set('errors', errors);
          _this.controller.set('hasErrors', true);
        });
      }
    },
    validateCovidStatusQuestionsFunction: function validateCovidStatusQuestionsFunction(healthAndSafetyCovidStatusFeature, modal, covidStatusList) {
      var covidStatusQuestions = [];
      if (healthAndSafetyCovidStatusFeature) {
        covidStatusQuestions = modal.get('covidStatusQuestions').map(function (q) {
          if (covidStatusList.includes(q.get('qid'))) {
            return (0, _formValidator.validate)({ model: q, attributes: ['additionalOption', 'covidStatusOption'] });
          }
        });
      }
      return covidStatusQuestions;
    },
    validateHealthAndSafetyInfo: function validateHealthAndSafetyInfo() {
      var safetyAccessoryList = this.controller.get('safetyAccessoryList');
      var covidStatusList = this.controller.get('covidStatusList');
      var dropdownList = this.controller.get('dropdownList');
      var healthAndSafetyCovidStatusFeature = this.get('featureFlags').checkEnabled('HealthAndSafetyCovidStatus');
      var healthAndSafetySelected = this.controller.get('healthAndSafetyInfo').get('healthAndSafetySelected') == 'Yes';
      var enhancedCleaning = this.controller.get('healthAndSafetyInfo').get('enhancedCleaningQuestions').map(function (q) {
        if (safetyAccessoryList.includes(q.get('qid'))) {
          return (0, _formValidator.validate)({ model: q, attributes: ['additionalOption'] });
        }
      });
      var covidStatusQuestions = this.validateCovidStatusQuestionsFunction(healthAndSafetyCovidStatusFeature, this.controller.get('healthAndSafetyInfo'), covidStatusList);
      var travelerSafety = this.controller.get('healthAndSafetyInfo').get('travelerSafetyQuestions').map(function (q) {
        if (safetyAccessoryList.includes(q.get('qid')) || dropdownList.includes(q.get('qid'))) {
          return (0, _formValidator.validate)({ model: q, attributes: ['additionalOption'] });
        }
      });

      return this.buildPromiseAsPerCovidStatusFeature(healthAndSafetyCovidStatusFeature, healthAndSafetySelected, enhancedCleaning, travelerSafety, covidStatusQuestions);
    },
    buildPromiseAsPerCovidStatusFeature: function buildPromiseAsPerCovidStatusFeature(isCovidStatusFeatureEnabled, healthAndSafetySelected, enhancedCleaning, travelerSafety, covidStatusQuestions) {
      var hasECErrors = false;
      var allErrors = {};
      if (isCovidStatusFeatureEnabled) {
        var ec = (0, _formValidator.validate)({
          model: this.controller.get('healthAndSafetyInfo'),
          attributes: ['enhancedCleaningQuestions', 'travelerSafetyQuestions', 'covidStatusQuestions']
        });
        return new Ember.RSVP.Promise(function (resolve, reject) {
          ec.catch(function (errors) {
            Object.assign(allErrors, errors);
            hasECErrors = true;
          }).finally(function () {
            Ember.RSVP.allSettled(enhancedCleaning).then(function (validatedEnhancedCleaning) {
              Ember.RSVP.allSettled(travelerSafety).then(function (validatedTravelerSafety) {
                Ember.RSVP.allSettled(covidStatusQuestions).then(function (validateCovidStatusQuestions) {
                  if (healthAndSafetySelected && ((0, _offerGroup.anyRejects)(validatedEnhancedCleaning) || (0, _offerGroup.anyRejects)(validatedTravelerSafety) || (0, _offerGroup.anyRejects)(validateCovidStatusQuestions) || hasECErrors)) {
                    Object.assign(allErrors, {
                      enhancedCleaningErrors: (0, _offerGroup.normalizeLangErrors)(validatedEnhancedCleaning),
                      travelerSafetyErrors: (0, _offerGroup.normalizeLangErrors)(validatedTravelerSafety),
                      covidStatusQuestionErrors: (0, _offerGroup.normalizeLangErrors)(validateCovidStatusQuestions)
                    });
                    reject(allErrors);
                  } else {
                    resolve();
                  }
                });
              });
            });
          });
        });
      } else {
        var _ec = (0, _formValidator.validate)({
          model: this.controller.get('healthAndSafetyInfo'),
          attributes: ['enhancedCleaningQuestions', 'travelerSafetyQuestions']
        });
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _ec.catch(function (errors) {
            Object.assign(allErrors, errors);
            hasECErrors = true;
          }).finally(function () {
            Ember.RSVP.allSettled(enhancedCleaning).then(function (validatedEnhancedCleaning) {
              Ember.RSVP.allSettled(travelerSafety).then(function (validatedTravelerSafety) {
                if (healthAndSafetySelected && ((0, _offerGroup.anyRejects)(validatedEnhancedCleaning) || (0, _offerGroup.anyRejects)(validatedTravelerSafety) || hasECErrors)) {
                  Object.assign(allErrors, {
                    enhancedCleaningErrors: (0, _offerGroup.normalizeLangErrors)(validatedEnhancedCleaning),
                    travelerSafetyErrors: (0, _offerGroup.normalizeLangErrors)(validatedTravelerSafety)
                  });
                  reject(allErrors);
                } else {
                  resolve();
                }
              });
            });
          });
        });
      }
    },
    saveCopy: function saveCopy() {
      var healthAndSafetyInfoCopy = JSON.parse(JSON.stringify(this.controller.get('healthAndSafetyInfo')));
      this.controller.set('healthAndSafetyInfoCopy', healthAndSafetyInfoCopy);
    },
    setupController: function setupController(controller, model) {
      controller.set('route', this);
      this._super(controller, model.params);
      var healthAndSafetyCovidStatusFeature = this.get('featureFlags').checkEnabled('HealthAndSafetyCovidStatus');
      this.controller.set('healthAndSafetyCovidStatusFeature', healthAndSafetyCovidStatusFeature);
      if (!healthAndSafetyCovidStatusFeature) {
        model.healthAndSafetyInfo.covidStatusQuestions = [];
      }
      this.controller.set('healthAndSafetyInfo', model.healthAndSafetyInfo);
      this.controller.set('activity', this.modelFor('activity'));
      this.controller.set('activity', this.modelFor('activity'));
      this.controller.set('answerOptionsArray', ['Yes', 'No']);
      this.controller.set('notApplicableList', [1, 2, 7, 11, 18]);
      this.controller.set('dropdownList', [12]);
      this.controller.set('safetyAccessoryList', [3, 5, 6]);
      this.controller.set('covidStatusList', [18]);
      this.controller.set('numberOfDaysPriorValues', Array(30 - 14 + 1).fill().map(function (_, idx) {
        return 14 + idx;
      }));
      this.controller.set('minAgeValues', Array(21).fill().map(function (_, idx) {
        return 1 + idx;
      }));
      this.controller.set('disabledMode', true);

      var vacantDurationDropdownMap = new Map([['30,30', '30 minutes'], ['60,120', '1-2 hours'], ['180,2147483647', '3 or more hours'], ['1440,2147483647', 'Over 1 day']]);

      var proofOfCovid19Map = [{ key: 'anyTest', value: 'Any Test' }, { key: 'pcr', value: 'PCR' }, { key: 'antigen', value: 'Antigen/rapid' }, { key: 'notApplicable', value: 'Not applicable' }];
      var acceptedTestTypes = {
        'label': proofOfCovid19Map.map(function (item) {
          return item.value;
        }),
        'value': proofOfCovid19Map.map(function (item) {
          return item.key;
        })
      };

      var testTakenTimeMap = [{ key: 24, value: '24 hours' }, { key: 48, value: '48 hours' }, { key: 72, value: '72 hours' }, { key: -1, value: 'Not applicable' }];
      var timePriorToTest = {
        'label': testTakenTimeMap.map(function (item) {
          return item.value;
        }),
        'value': testTakenTimeMap.map(function (item) {
          return item.key;
        })
      };

      var selectedCovidStatusOptions = {
        acceptedTestType: undefined,
        timePriorToTest: undefined
      };
      model.healthAndSafetyInfo.get('covidStatusQuestions').map(function (covidStatusQuestion) {
        var proofOfCovid19Negative = covidStatusQuestion.get('covidStatusOption').proofOfCovid19Negative;
        if (!proofOfCovid19Negative) {
          return;
        };
        selectedCovidStatusOptions.timePriorToTest = (proofOfCovid19Negative.timePriorToTest || 0).toString();
        selectedCovidStatusOptions.acceptedTestType = Object.keys(proofOfCovid19Negative.acceptedTestTypes).find(function (i) {
          return proofOfCovid19Negative.acceptedTestTypes[i] === true;
        });
      });

      this.saveCopy();
      this.controller.set('isNewHealthAndSafetyInfo', model.healthAndSafetyInfo.get('healthAndSafetySelected') === '');
      var isAlreadyPublished = this.get('session.data.fromPublish') && controller.get('activity').get('isActivityPublished');
      this.controller.set('isAlreadyPublished', isAlreadyPublished);
      this.controller.set('vacantDurationDropdownKeys', Array.from(vacantDurationDropdownMap.keys()));
      this.controller.set('vacantDurationDropdownValues', Array.from(vacantDurationDropdownMap.values()));

      this.controller.set('additionalOptionCheckboxList', ['Masks', 'Gloves']);

      this.controller.set('additionalOptionCovidStatusList', ['proofOfCovid19Vaccine', 'proofOfCovidInfection', 'proofOfCovid19Negative']);
      this.controller.set('selectedAdditionalOptionCovidStatusList', []);
      this.controller.set('changesMade', false);
      this.controller.set('errors', '');
      this.controller.set('proofOfCovid19Map', proofOfCovid19Map);
      this.controller.set('selectedCovidStatusOptions', selectedCovidStatusOptions);
      this.controller.set('acceptedTestTypes', acceptedTestTypes);
      this.controller.set('timePriorToTest', timePriorToTest);
      this.controller.set('proofOfCovid19List', Array.from(proofOfCovid19Map.map(function (element) {
        return element.key;
      })));
      this.controller.set('testTakenTimeMap', testTakenTimeMap);
      this.controller.set('testTakenTimeList', Array.from(testTakenTimeMap.map(function (element) {
        return element.key;
      })));
    },
    getEmptyCovidStatusOptionItem: function getEmptyCovidStatusOptionItem(selected) {
      var item = void 0;
      if (selected === 'proofOfCovid19Vaccine') {
        item = {};
      } else if (selected == 'proofOfCovidInfection') {
        item = true;
      } else if (selected == 'proofOfCovid19Negative') {
        item = {
          acceptedTestTypes: {
            anyTest: false,
            pcr: false,
            antigen: false,
            notApplicable: false
          },
          timePriorToTest: undefined
        };
      }
      return item;
    },


    actions: {
      selectHealthAndSafetyInfo: function selectHealthAndSafetyInfo(selected) {
        this.controller.set('healthAndSafetyInfo.healthAndSafetySelected', selected);
        var isNewHealthAndSafetyInfo = this.controller.get('isNewHealthAndSafetyInfo');
        if (isNewHealthAndSafetyInfo) {
          this.controller.set('disabledMode', false);
        }
        if (selected === 'No') {
          this.setChangesMade();
        }
      },
      selectQuestionOption: function selectQuestionOption(selected, question) {
        Ember.set(question, 'answer', selected);
        this.setChangesMade();
      },
      selectDeselectAdditionalOption: function selectDeselectAdditionalOption(selected, question) {
        var additionalOptions = Ember.get(question, 'additionalOption');
        if (additionalOptions.includes(selected)) {
          additionalOptions.removeObject(selected);
        } else {
          additionalOptions.pushObject(selected);
        }
        Ember.set(question, 'additionalOption', additionalOptions);
        this.setChangesMade();
      },
      setAdditionalCovidStatusNumberOfDaysPriorDropdownOption: function setAdditionalCovidStatusNumberOfDaysPriorDropdownOption(question, selected) {
        var covidStatusOption = question.get('covidStatusOption');
        var proofOfCovid19Vaccine = Ember.get(covidStatusOption, 'proofOfCovid19Vaccine');
        if (selected.length) {
          Ember.set(proofOfCovid19Vaccine, 'numberOfDaysPrior', parseInt(selected));
        } else {
          Ember.set(proofOfCovid19Vaccine, 'numberOfDaysPrior', null);
        }
        Ember.set(question, 'proofOfCovid19Vaccine', proofOfCovid19Vaccine);
        Ember.set(question, 'additionalOption', [selected]);
        this.setChangesMade();
      },
      setAdditionalCovidStatusMinAgePriorDropdownOption: function setAdditionalCovidStatusMinAgePriorDropdownOption(question, selected) {
        var covidStatusOption = question.get('covidStatusOption');
        var proofOfCovid19Vaccine = Ember.get(covidStatusOption, 'proofOfCovid19Vaccine');
        if (selected.length) {
          Ember.set(proofOfCovid19Vaccine, 'minAge', parseInt(selected));
        } else {
          Ember.set(proofOfCovid19Vaccine, 'minAge', null);
        }
        Ember.set(question, 'proofOfCovid19Vaccine', proofOfCovid19Vaccine);
        Ember.set(question, 'additionalOption', [selected]);
        this.setChangesMade();
      },
      selectDeselectCovidStatusAdditionalOption: function selectDeselectCovidStatusAdditionalOption(selected, question) {
        var covidStatusOption = question.get('covidStatusOption');
        if (covidStatusOption == undefined) {
          covidStatusOption = {};
        }
        if (covidStatusOption.hasOwnProperty(selected)) {
          delete covidStatusOption[selected];
          if (selected == 'proofOfCovid19Negative') {
            question.set('additionalOption', []);
          }
        } else {
          covidStatusOption[selected] = this.getEmptyCovidStatusOptionItem(selected);
        }
        question.set('covidStatusOption', covidStatusOption);
        question.notifyPropertyChange('covidStatusOption');
        this.setChangesMade();
      },
      selectDeselectAcceptedTestTypesAdditionalOption: function selectDeselectAcceptedTestTypesAdditionalOption(question, property, selected) {
        var covidStatusOption = question.get('covidStatusOption');
        Object.keys(covidStatusOption.proofOfCovid19Negative[property]).map(function (testType) {
          if (testType === selected) {
            covidStatusOption.proofOfCovid19Negative[property][testType] = true;
          } else {
            covidStatusOption.proofOfCovid19Negative[property][testType] = false;
          }
        });
        question.set('covidStatusOption', covidStatusOption);
        question.set('additionalOption', [selected]);
        this.setChangesMade();
      },
      selectDeselectTimePriorToTestAdditionalOption: function selectDeselectTimePriorToTestAdditionalOption(question, property, selected) {
        var covidStatusOption = JSON.parse(JSON.stringify(question.get('covidStatusOption')));
        covidStatusOption.proofOfCovid19Negative[property] = parseInt(selected);
        question.set('covidStatusOption', covidStatusOption);
        question.set('additionalOption', [selected]);
        this.setChangesMade();
      },
      selectDeselectCovidTestTakenTimeStatusAdditionalOption: function selectDeselectCovidTestTakenTimeStatusAdditionalOption(selected, question, property) {
        var additionalOptions = Ember.get(question, 'additionalOption');
        var covidStatusOption = question.get('covidStatusOption');
        var exactSelected = selected == 'notApplicable1' ? 'notApplicable' : selected;
        if (additionalOptions.includes(selected)) {
          additionalOptions.removeObject(selected);
          covidStatusOption.proofOfCovid19Negative[property][exactSelected] = false;
        } else {
          additionalOptions.pushObject(selected);
          covidStatusOption.proofOfCovid19Negative[property][exactSelected] = true;
        }
        Ember.set(question, 'additionalOption', additionalOptions);
        question.set('covidStatusOption', covidStatusOption);
        question.notifyPropertyChange('additionalOption');
        this.setChangesMade();
      },
      setAdditionalDropdownOption: function setAdditionalDropdownOption(question, selected) {
        var additionalOptions = [selected];
        if (selected.length) {
          Ember.set(question, 'additionalOption', additionalOptions);
        } else {
          Ember.set(question, 'additionalOption', []);
        }
        this.setChangesMade();
      },
      editSetup: function editSetup() {
        this.controller.set('disabledMode', false);
        this.saveCopy();
      },
      submitForm: function submitForm(path) {
        var _this2 = this;

        var route = this;
        var activity = route.controller.get('activity');
        this.validateHealthAndSafetyInfo().then(function () {
          _this2.controller.set('errors', '');
          _this2.controller.set('hasErrors', false);
          return _this2.controller.get('healthAndSafetyInfo').save();
        }).then(function () {
          _this2.controller.set('disabledMode', true);
          var isNew = _this2.controller.get('healthAndSafetyInfo').get('healthAndSafetySelected') == '';
          _this2.controller.set('isNewHealthAndSafetyInfo', isNew);
          _this2.saveCopy();
          if (path === 'continue') {
            _this2.send('continue');
          } else {
            _this2.get('notifications').success(_this2.get('i18n').t('components.alert.saveSuccess'));
          }
        }).catch(function (errors) {
          _this2.controller.set('errors', errors);
          _this2.controller.set('hasErrors', true);
          (0, _formValidator.scrollToFirstError)();
        });
      },
      continue: function _continue() {
        var route = this;
        var activity = route.controller.get('activity');
        if (this.get('session.data.fromPublish') && !activity.get('isActivityPublished')) {
          this.get('session').set('data.fromPublish', false);
          route.transitionTo('activity.publish');
        } else {
          route.transitionTo('activity.customer-service', activity.get('id'));
        }
      },
      cancelSave: function cancelSave() {
        this.revertToPreviousVersion();
        this.controller.set('disabledMode', true);
        this.controller.set('changesMade', false);
      }
    }
  });
});