define('lxso/routes/user', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lxso/config/environment', 'lxso/mixins/enum-getter'], function (exports, _authenticatedRouteMixin, _featureFlag, _requirePermission, _environment, _enumGetter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, _enumGetter.default, {

    requiredPermission: 'V3InternalSettings',
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    featureFlags: Ember.inject.service(),

    model: function model(params) {
      if (params.userId === 'new') {
        return {
          user: this.store.createRecord('user')
        };
      } else {
        var user = this.store.findRecord('user', params.userId, { reload: true });
        return new Ember.RSVP.hash({
          user: user
        });
      }
    },
    afterModel: function afterModel(model, transition) {
      var isNewUser = model.user.get('isNew');
      if (isNewUser && !this.get('featureFlags').checkEnabled('V3UserManagementWrite')) {
        transition.abort();
        var error = new Ember.Error('V3UserManagementWrite feature is not enabled');
        this.intermediateTransitionTo('route-not-found', error);
      }
    },
    setupController: function setupController(ctrl, model) {
      var isNewUser = model.user.get('isNew');
      ctrl.set('user', model.user);
      ctrl.set('isNew', isNewUser);
      this.initSupplierSearchTypeAhead(ctrl);
    },
    transformSupplierData: function transformSupplierData(inputData) {
      var outputData = {
        data: []
      };
      inputData.forEach(function (item) {
        if (item.status.toUpperCase() === 'ACTIVE' || item.status.toUpperCase() === 'INACTIVE') {
          outputData.data.push(item);
        }
      });
      return outputData;
    },
    initSupplierSearchTypeAhead: function initSupplierSearchTypeAhead(ctrl) {
      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
      var url = domain + '/suppliers/search?name=';
      ctrl.set('supplierBranchSource', this.getEnumFunctionWithTransform(url, this.transformSupplierData));
      ctrl.set('isBasicDirty', false);
      ctrl.set('isSupplierInfoDirty', false);
    },
    resetController: function resetController() {
      if (!this.controller.get('isNew')) {
        this.controller.get('user').rollbackAttributes();
        this.controller.set('user', undefined);
        this.controller.set('isNew', undefined);
      }
      this.resetServerFailureMessage();
    },
    resetServerFailureMessage: function resetServerFailureMessage() {
      this.controller.set('userSaveServerError', undefined);
      this.controller.set('userSupplierInfoSaveServerError', undefined);
    },
    setSaveLoadText: function setSaveLoadText(section, status) {
      if (section === 'basic') {
        this.controller.set('savingUserBasic', status);
      } else {
        this.controller.set('savingUserSupplierInfo', status);
      }
    },
    getSupplierBranchDetails: function getSupplierBranchDetails(supplierId) {
      var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/suppliers/' + supplierId;
      return this.get('ajaxWrapper').ajaxGet(url);
    },
    getSupplierInfoById: function getSupplierInfoById(supplierId) {
      var supplierDetails = this.controller.get('user.accessibleSuppliersWithBranches').filter(function (branchesSelected) {
        return branchesSelected.get('supplierId') === supplierId;
      });

      if (supplierDetails[0]) {
        return supplierDetails[0];
      } else {
        return [];
      }
    },
    selectDeselectAllSupplierBranches: function selectDeselectAllSupplierBranches(supplierId, isSelect) {
      var supplierDetails = this.getSupplierInfoById(supplierId);

      if (supplierDetails) {
        supplierDetails.get('allBranches').forEach(function (x) {
          x.set('isSelected', isSelect);
        });
      }
    },
    selectDeselectOneBranch: function selectDeselectOneBranch(supplierId, isSelect) {
      var supplierDetails = this.getSupplierInfoById(supplierId);
      if (!isSelect && supplierDetails) {
        supplierDetails.set('selectAll', isSelect);
      } else if (isSelect && supplierDetails) {
        var totalNumberOfBranches = supplierDetails.get('allBranches.length');
        var totalSelectedBranches = supplierDetails.get('allBranches').filter(function (x) {
          return x.get('isSelected') === true;
        }).get('length');

        if (totalNumberOfBranches === totalSelectedBranches) {
          supplierDetails.set('selectAll', isSelect);
        }
      }
    },
    convertDataToJSON: function convertDataToJSON(units) {
      return units.map(function (x) {
        return x.toJSON();
      });
    },
    convertDataToApiFormat: function convertDataToApiFormat(accessibleSuppliersWithBranches) {
      var allPromiseArray = [];
      accessibleSuppliersWithBranches.forEach(function (accessibleBranch) {
        var attributesSuppliers = [];
        var attributeObjectSupplier = {};
        attributeObjectSupplier.id = 1;
        attributeObjectSupplier.value = accessibleBranch.supplierId;
        attributeObjectSupplier.setGuid = '';
        attributeObjectSupplier.name = 'SupplierID';
        attributesSuppliers.push(attributeObjectSupplier);

        if (accessibleBranch.allBranches && accessibleBranch.allBranches.length && !accessibleBranch.hasToBeRemoved) {
          var branchesTotalLength = accessibleBranch.allBranches.length;
          var selectedBranchesLength = accessibleBranch.allBranches.filter(function (x) {
            return x.isSelected === true;
          }).get('length');

          if (branchesTotalLength === selectedBranchesLength) {
            var attributeObjectSupplierBranchAll = {};
            attributeObjectSupplierBranchAll.id = 2;
            attributeObjectSupplierBranchAll.value = -1;
            attributeObjectSupplierBranchAll.setGuid = '';
            attributeObjectSupplierBranchAll.name = 'SupplierBranchID';
            attributesSuppliers.push(attributeObjectSupplierBranchAll);
          } else {
            accessibleBranch.allBranches.forEach(function (branches) {
              if (branches.isSelected) {
                var attributeObjectSupplierBranch = {};
                attributeObjectSupplierBranch.id = 2;
                attributeObjectSupplierBranch.value = branches.supplier_branch_id;
                attributeObjectSupplierBranch.setGuid = '';
                attributeObjectSupplierBranch.name = 'SupplierBranchID';
                attributesSuppliers.push(attributeObjectSupplierBranch);
              }
            });
          }
        }

        allPromiseArray.push(attributesSuppliers);
      });

      return allPromiseArray;
    },
    saveSupplierInfoPromise: function saveSupplierInfoPromise() {
      var _this = this;

      var userId = this.controller.get('user.id');
      var url = _environment.default.api.host + '/api/v3/users/' + userId + '/attributes';

      var accessibleSuppliersWithBranches = this.convertDataToJSON(this.controller.get('user.accessibleSuppliersWithBranches'));

      var attributesList = this.convertDataToApiFormat(accessibleSuppliersWithBranches);
      var attributesPromiseList = [];

      attributesList.forEach(function (requestObj) {
        var requestData = {};
        requestData.attributes = requestObj;

        var requestPromise = _this.get('ajaxWrapper').ajaxPut(url, requestData);
        attributesPromiseList.push(requestPromise);
      });

      return Ember.RSVP.map(attributesPromiseList, function (data) {
        return data;
      });
    },


    actions: {
      openStep: function openStep(index) {
        var link = document.getElementsByClassName('toggle-trigger supplier-settings-' + index);
        $(link).toggleClass('open');

        var dataTable = document.getElementsByClassName('supplier-link-user user-link-table-' + index);
        $(dataTable).toggleClass('close');
      },
      branchSelected: function branchSelected(item) {
        if (!Ember.isBlank(item.supplier_id) && !Ember.isBlank(item.name)) {
          var supplierBranchAlreadyAdded = this.controller.get('user.accessibleSuppliersWithBranches').any(function (branchesSelected) {
            return branchesSelected.get('supplierId') === item.supplier_id;
          });

          if (!supplierBranchAlreadyAdded) {
            var newSupplierObject = this.controller.get('user.accessibleSuppliersWithBranches').createFragment({
              supplierId: item.supplier_id,
              supplierName: item.name
            });

            newSupplierObject.set('status', item.status);

            this.getSupplierBranchDetails(item.supplier_id).then(function (supplierInfo) {
              supplierInfo.branches.forEach(function (supplierBranch) {
                supplierBranch.isSelected = true;
                newSupplierObject.get('allBranches').createFragment(supplierBranch);
              });

              newSupplierObject.set('isBranchesLoading', false);
            });
          }
        }
      },
      removeSelectedSupplier: function removeSelectedSupplier(supplierId) {
        var supplierObj = this.getSupplierInfoById(supplierId);
        supplierObj.set('hasToBeRemoved', true);
      },
      resetServerErrors: function resetServerErrors() {
        this.resetServerFailureMessage();
      },
      saveUserInfo: function saveUserInfo(section) {
        var _this2 = this;

        this.send('resetServerErrors');
        this.setSaveLoadText(section, true);
        this.controller.user.save({ adapterOptions: { 'action': 'saveUser', section: section } }).then(function (userRes) {
          _this2.controller.set('lastSaved', 'userBasic');
          _this2.controller.set('isBasicDirty', false);
          setTimeout(function () {
            _this2.transitionTo('user', userRes.get('id'));
          }, 600);
          _this2.get('notifications').success(_this2.get('i18n').t('pages.userManagement.userInfo.saveInfo'));
        }).catch(function (saveError) {
          _this2.controller.set('userSaveServerError', Object.assign({}, saveError));
        }).finally(function () {
          _this2.setSaveLoadText(section, false);
        });
      },
      saveSupplierInfo: function saveSupplierInfo() {
        var _this3 = this;

        this.send('resetServerErrors');
        this.setSaveLoadText('supplierInfo', true);
        this.saveSupplierInfoPromise().then(function (successRes) {
          return _this3.controller.get('user').reload();
        }).then(function () {
          _this3.controller.get('user.accessibleSuppliersWithBranches').forEach(function (branch) {
            branch.set('hasToBeRemoved', false);
          });
          _this3.controller.get('user.accessibleSuppliersWithBranches').rollbackAttributes();
          _this3.controller.get('user').notifyPropertyChange('accessibleSuppliersWithBranches');
          _this3.controller.set('isSupplierInfoDirty', false);
          _this3.controller.set('lastSaved', 'userSupplierInfo');
          _this3.get('notifications').success(_this3.get('i18n').t('pages.userManagement.userInfo.saveInfo'));
        }).catch(function (errorRes) {
          _this3.controller.set('userSupplierInfoSaveServerError', Object.assign({}, errorRes));
        }).finally(function () {
          _this3.setSaveLoadText('supplierInfo', false);
        });
      },
      cancelChanges: function cancelChanges(section) {
        var userData = JSON.parse(JSON.stringify(this.controller.get('user')));
        var didSupplierInfoChanged = 'accessibleSuppliersWithBranches' in this.controller.get('user').changedAttributes();
        if (section === 'basic' && !this.controller.get('isNew')) {
          this.controller.get('user').rollbackAttributes();
          this.controller.set('isBasicDirty', false);

          if (didSupplierInfoChanged) {
            this.controller.set('user.accessibleSuppliersWithBranches', userData.accessibleSuppliersWithBranches);
          }
        } else if (section === 'basic' && this.controller.get('isNew')) {
          this.controller.set('isBasicDirty', false);
          this.transitionTo('manage-users');
        } else {
          this.controller.get('user.accessibleSuppliersWithBranches').rollbackAttributes();
          this.controller.set('isSupplierInfoDirty', false);
        }
      },
      changePassword: function changePassword() {
        this.transitionTo('change-password', this.controller.get('user.id'));
      },
      supplierBranchCheckAction: function supplierBranchCheckAction(supplierId, isChecked) {
        this.selectDeselectOneBranch(supplierId, isChecked);
      },
      selectAllBranches: function selectAllBranches(supplierId, value) {
        this.selectDeselectAllSupplierBranches(supplierId, value);
      },
      updateUserRole: function updateUserRole(roleId) {
        this.controller.set('user.role', roleId);
      },
      backToManageUsers: function backToManageUsers() {
        this.transitionTo('manage-users');
      }
    }
  });
});