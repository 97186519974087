define('lxso/helpers/ticket-type-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ticketTypeMap = ticketTypeMap;
  function ticketTypeMap(guestTypeId /*, hash*/) {
    var guestTypesArray = ['', 'traveler', 'adult', 'infant', 'child', 'youth', 'senior'];
    return guestTypesArray[guestTypeId];
  }

  exports.default = Ember.Helper.helper(ticketTypeMap);
});