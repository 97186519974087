define('lxso/services/option-group-service', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajaxWrapper: Ember.inject.service(),

    setCrudMode: function setCrudMode(crudMode) {
      this.set('crudMode', crudMode);
    },
    getCrudMode: function getCrudMode() {
      return this.get('crudMode');
    },
    setSectionToEdit: function setSectionToEdit(section) {
      this.set('sectionToEdit', section);
    },
    getSectionToEdit: function getSectionToEdit() {
      return this.get('sectionToEdit');
    },
    triggerPriceCreateForNewOptions: function triggerPriceCreateForNewOptions(optionIds, optionGroupId) {
      var _this = this;

      var priceCreateRequests = optionIds.map(function (optionId) {
        var url = _environment.default.api.host + '/api/v3/offerGroup/' + optionGroupId + '/add-price-for-offer/' + optionId;
        return _this.get('ajaxWrapper').ajaxPut(url);
      });
      return Ember.RSVP.all(priceCreateRequests);
    }
  });
});