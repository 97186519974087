define('lxso/components/time-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    is12Hour: Ember.computed('timeFormat', function () {
      return this.get('timeFormat') === '12';
    }),
    timeAmPm: 'AM',
    classNameBindings: ['classesToAdd'],
    classesToAdd: Ember.computed('hideTimeIcon', function () {
      if (this.get('hideTimeIcon')) {
        return '';
      } else {
        return 'smalltablet-col-1-1 mobile-col-1-1 uitk-col desktop-col-1-4 tablet-col-1-4';
      }
    }),
    validField: Ember.computed('invalid', 'sequentialInvalid', function () {
      return this.get('invalid') || this.get('sequentialInvalid');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var value = this.get('display');
      this.setAmPmOnValueChange(value);
    },
    setAmPmOnValueChange: function setAmPmOnValueChange(value) {
      if (value && value.split(':')[0] > 0) {
        if (this.get('timeFormat') === '24') {
          var hrs = parseInt(value.split(':')[0]);
          if (hrs > 12) {
            this.set('timeAmPm', 'PM');
          } else {
            this.set('timeAmPm', 'AM');
          }
        } else if (this.get('timeFormat') === '12') {
          if (this.get('timeAmPm') === 'PM') {
            var _hrs = parseInt(value.split(':')[0]);
            var mins = value.split(':')[1];
            this.set('display', _hrs + 12 + ':' + mins);
          } else {
            var _hrs2 = parseInt(value.split(':')[0]);
            var _mins = value.split(':')[1];
            this.set('display', _hrs2 - 12 + ':' + _mins);
          }
        }
      }
    },


    actions: {
      formatInputForValidation: function formatInputForValidation(input) {
        /* formatInput takes whatever the user put in,
          adds a colon and 0 padding if it needs it,
          sends it through validation,
          and then calls the component action
          */
        if (typeof input !== 'undefined') {
          if (/\d{2}:\d{2}/.test(input)) {
            //perfectly formatted data, such as: 00:00
            this.send('validateTimeData', input);
          } else if (/0:0/.test(input)) {
            //0:0
            this.send('validateTimeData', '00:00');
          } else if (/\d{4}/.test(input)) {
            //4 digits but no colon, such as: 0400 or 1200
            var formatted = input.slice(0, 2) + ':' + input.slice(2, 4);
            this.send('validateTimeData', formatted);
          } else if (/\d:\d{2}/.test(input)) {
            //missing leading 0 but trailing digits present, such as: 5:00
            var _formatted = '0' + input;
            this.send('validateTimeData', _formatted);
          } else if (/\d{3}/.test(input)) {
            //missing leading 0 but trailing digits present, such as: 500
            var _formatted2 = '0' + input.slice(0, 1) + ':' + input.slice(1, 3);
            this.send('validateTimeData', _formatted2);
          } else if (/\d{2}:\d/.test(input)) {
            //missing 0 after colon, such as: 05:5
            var _formatted3 = input.slice(0, 2) + ':0' + input.slice(3, 4);
            this.send('validateTimeData', _formatted3);
          } else if (/\d:\d/.test(input)) {
            //missing leading 0 and 0 after colon, such as: 5:5
            var _formatted4 = '0' + input.slice(0, 1) + ':0' + input.slice(2, 3);
            this.send('validateTimeData', _formatted4);
          } else {
            // this data is too poorly formatted to format for you
            // set to invalid, unless the field is blank
            if (input.length !== 0) {
              this.set('invalid', true);
              this.sendAction('setValidationErrorOnUpdate', true);
            }
          }
        }
      },
      validateTimeData: function validateTimeData(input) {
        // Takes the formatted user input and determines if
        // it's invalid for other reasons, such as being an hour over 23
        //data must come in in this format: 00:00

        if (input.length !== 5) {
          Ember.Logger.error('input with a length other than 5!');
          this.set('invalid', true);
        }
        var is12Hour = this.get('is12Hour');
        var leftInvalid = false;
        var rightInvalid = false;

        //expected format: 01:34 (the colon is at index 2)
        var left = input.slice(0, 2);
        var right = input.slice(3, 5);

        if (is12Hour && parseInt(left) > 12 || !is12Hour && parseInt(left) > 23 || parseInt(left) < 0) {
          leftInvalid = true;
        }

        if (parseInt(right) > 59 || parseInt(right) < 0) {
          rightInvalid = true;
        }

        this.sendAction('setValidationErrorOnUpdate', leftInvalid || rightInvalid);
        if (leftInvalid || rightInvalid) {
          this.set('invalid', true);
        } else {
          this.set('display', input);
          //both left and right are valid, send this stuff along
          this.set('invalid', false);
          var validatedTime = input;

          this.set('time', validatedTime);
          this.set('left', left);
          this.set('right', right);
          var timeObj = {
            text: validatedTime,
            hours: left,
            minutes: right
          };
          this.setAmPmOnValueChange(input);
          //send the timeObj created above to the model
          this.sendAction('action', timeObj);
        }
      },
      setTimeFormat: function setTimeFormat(format) {
        this.set('timeAmPm', format);
        this.setAmPmOnValueChange(this.get('display'));
      }
    }
  });
});