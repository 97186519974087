define('lxso/mixins/supplier-settings/margin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    initSupplierMarginSettings: function initSupplierMarginSettings() {
      this.controller.set('isSupplierBranchMarginOpen', true);
      this.controller.set('supplierMarginEdit', false);
    },
    resetMarginOverride: function resetMarginOverride() {
      'use strict';

      var mOverride = this.get('store').peekAll('supplier-branch/margin-overrides');
      mOverride.forEach(function (margin) {
        margin.rollbackAttributes();
      });
    },
    resetTicketMargin: function resetTicketMargin() {
      var ticketsDetails = this.get('store').peekAll('supplier-branch/ticket-type-margin');
      ticketsDetails.forEach(function (tickets) {
        tickets.rollbackAttributes();
      });
    },
    resetOfferMargin: function resetOfferMargin() {
      var offerDetails = this.get('store').peekAll('supplier-branch/offer-margin');
      offerDetails.forEach(function (offer) {
        offer.rollbackAttributes();
      });
    },
    resetOfferGroupMargin: function resetOfferGroupMargin() {
      var offerDetails = this.get('store').peekAll('supplier-branch/offer-group-margin');
      offerDetails.forEach(function (offer) {
        offer.rollbackAttributes();
      });
    },
    saveMarginList: function saveMarginList() {
      var marginPromiseList = [];
      this.controller.get('marginOverrideList').get('content').forEach(function (marginOverRide) {
        var marginModel = marginOverRide.save();
        marginPromiseList.push(marginModel);
      });
      return Ember.RSVP.map(marginPromiseList, function (data) {
        return data;
      });
    },
    updateBaseMargin: function updateBaseMargin() {
      var supplierBranch = this.controller.get('supplierBranch');
      return supplierBranch.updateSupplierBranchMargin({
        'margin': (supplierBranch.get('baseMargin') / 100).toFixed(4) * 1,
        'update_existing_prices': false
      });
    },
    convertMarginDecimalToPercent: function convertMarginDecimalToPercent(decimal) {
      return (decimal * 100).toFixed(2);
    },


    actions: {
      cancelMarginEdit: function cancelMarginEdit() {
        this.controller.get('supplierBranch').rollbackAttributes();
        this.send('setSupplierMarginEdit', false);
      },
      showSuccess: function showSuccess() {
        this.controller.set('lastSaved', 'supplierMarginSettings');
        this.get('notifications').success(this.get('i18n').t('components.alert.saveSuccess'));
      },
      setSupplierMarginEdit: function setSupplierMarginEdit(state) {
        if (!state || this.isEditAllowed('supplierMarginSetting')) {
          this.controller.set('supplierMarginEdit', state);
        } else {
          this.controller.set('sectionToBeEdited', 'marginSettingsSection');
        }
      },
      cancelSupplierMarginEdit: function cancelSupplierMarginEdit() {
        this.controller.get('supplierBranch').rollbackAttributes();
        this.resetMarginOverride();
        this.resetTicketMargin();
        this.resetOfferMargin();
        this.resetOfferGroupMargin();
        this.send('setSupplierMarginEdit', false);
      },
      saveMarginSettings: function saveMarginSettings() {
        var _this = this;

        var supplierBranch = this.controller.get('supplierBranch');
        this.controller.set('lastSaved', 'supplierMarginSettings');
        this.controller.set('isSavingMargin', true);
        this.updateBaseMargin().then(function (resp) {
          supplierBranch.set('baseMargin', _this.convertMarginDecimalToPercent(resp.margin));
          supplierBranch.reload();
          return _this.saveMarginList();
        }).then(function () {
          _this.controller.set('marginSaveError', undefined);
          _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
          _this.send('setSupplierMarginEdit', false);
        }).catch(function (marginListErr) {
          _this.controller.set('marginSaveError', marginListErr);
        }).finally(function () {
          _this.controller.set('isSavingMargin', false);
        });
      },
      goToActivity: function goToActivity(activityId) {
        this.transitionTo('activity.activity-settings', activityId);
      },
      goToOptions: function goToOptions(activityId, optionId) {
        this.transitionTo('activity.offer', activityId, optionId);
      }
    }
  });
});