define('lxso/components/daterange-picker/component', ['exports', 'lxso/utils/date-validators'], function (exports, _dateValidators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    uitk: Ember.inject.service('uitk'),
    dateFormat: Ember.inject.service('date-format'),

    tagName: '',

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('uitk').datepicker.subscribe(function (date, element) {
        var update = _this.get('updaters').get(element.attr('id'));
        update && update(date);
      });
    },
    dateFromEvent: function dateFromEvent(event) {
      var value = event.target.value;
      return Ember.isPresent(value) ? moment(value, this.get('dateFormat.DATEPICKER_FORMAT'), true) : null;
    },
    updateFrom: function updateFrom(date) {
      this.sendAction('updateFrom', date);
    },
    updateTo: function updateTo(date) {
      this.sendAction('updateTo', date);
    },


    defaultRangeLengthInDays: Ember.computed('defaultRangeLength', function () {
      var defaultRangeLength = this.get('defaultRangeLength');
      return defaultRangeLength && defaultRangeLength.asDays() - 1;
    }),

    minRangeLengthInDays: Ember.computed('minRangeLength', function () {
      var minRangeLength = this.get('minRangeLength');
      return minRangeLength && minRangeLength.asDays() - 1;
    }),

    maxRangeLengthInDays: Ember.computed('maxRangeLength', function () {
      var maxRangeLength = this.get('maxRangeLength');
      return maxRangeLength && maxRangeLength.asDays() - 1;
    }),

    updaters: Ember.computed('fromId', 'toId', function () {
      return new Map([[this.get('fromId'), this.updateFrom], [this.get('toId'), this.updateTo]]);
    }),

    values: Ember.computed('fromDate', 'toDate', 'firstValidDate', function () {
      var dateFormat = this.get('dateFormat.DATEPICKER_FORMAT');

      function format(date) {
        return Ember.isPresent(date) ? date.format(dateFormat) : null;
      }

      return {
        from: format(this.get('fromDate')),
        to: format(this.get('toDate')),
        firstValidDate: format(this.get('firstValidDate'))
      };
    }),

    valid: Ember.computed('fromDate', 'toDate', function () {
      var from = this.get('fromDate');
      var to = this.get('toDate');
      var bothEmpty = !from && !to;
      var fromIsValid = from && from.isValid();
      var toIsValid = to && to.isValid();
      var maxRange = this.get('maxRangeLength');
      var minRange = this.get('minRangeLength');
      var skipRangeCheck = this.get('skipRangeCheck');
      var rangeIsValid = true;
      if (!skipRangeCheck || !bothEmpty) {
        rangeIsValid = (0, _dateValidators.isSameOrBefore)(from, to, 'day');
      }

      if (maxRange) {
        rangeIsValid = rangeIsValid && (0, _dateValidators.isInclusiveRangeLengthIsAtMost)(maxRange, from, to);
      }

      if (minRange) {
        rangeIsValid = rangeIsValid && (0, _dateValidators.isInclusiveRangeLengthIsAtLeast)(minRange, from, to);
      }

      var validity = {
        from: bothEmpty || fromIsValid && rangeIsValid,
        to: bothEmpty || toIsValid && rangeIsValid
      };

      this.sendAction('setValidity', validity);
      return validity;
    }),

    actions: {
      from: function from(event) {
        var fromMomentValue = this.dateFromEvent(event);
        if (fromMomentValue) {
          this.updateFrom(fromMomentValue);

          // This part is a temporary fix till this uitk jira ticket
          // (https://jira/jira/browse/CSE-2798) is finished and
          // we update to that version which contains the fix
          var from = Ember.$('#' + this.get('fromId'));
          var to = Ember.$('#' + this.get('toId'));
          var toMomentValue = moment(to.val(), this.get('dateFormat.DATEPICKER_FORMAT'), true);
          if (fromMomentValue.isValid() && toMomentValue.isValid() && toMomentValue.isBefore(fromMomentValue)) {
            to.val(from.val());
            this.updateTo(fromMomentValue);
          }
          // end of fix
        }
      },
      to: function to(event) {
        var toMomentValue = this.dateFromEvent(event);
        if (toMomentValue) {
          this.updateTo(toMomentValue);

          // This part is a temporary fix till this uitk jira ticket
          // (https://jira/jira/browse/CSE-2798) is finished and
          // we update to that version which contains the fix
          var fromValue = Ember.$('#' + this.get('fromId')).val();
          var to = Ember.$('#' + this.get('toId'));
          var fromMomentValue = moment(fromValue, this.get('dateFormat.DATEPICKER_FORMAT'), true);
          if (fromMomentValue.isValid() && toMomentValue && toMomentValue.isBefore(fromMomentValue)) {
            to.val(fromValue);
            this.updateTo(fromMomentValue);
          }
          // end of fix
        }
      }
    }
  });
});