define('lxso/mixins/feature-flag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isFeatureEnabled: function isFeatureEnabled(features, featureName) {
      return features.any(function (item) {
        return item.get('name') === featureName && item.get('isEnabled');
      });
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var superResult = this._super.apply(this, arguments);
      return this.store.findAll('enums/feature').then(function (features) {
        var featureName = _this.get('featureName');
        if (!_this.isFeatureEnabled(features, featureName)) {
          transition.abort();
          var error = new Ember.Error(featureName + ' feature is not enabled');
          _this.intermediateTransitionTo('route-not-found', error);
        }
        return superResult;
      });
    }
  });
});