define('lxso/models/fragments/price-group-v4/ticket-type-price', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    basePrice: [(0, _validation.validator)('number', {
      gte: 0,
      allowString: true,
      descriptionKey: 'errors.descriptionKeys.retailPrice',
      disabled: Ember.computed('model.basePrice', 'model.isSelected', function () {
        return this.get('model.basePrice') === '' || !this.get('model.isSelected');
      })
    }), (0, _validation.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isSelected')
    })],
    netPrice: [(0, _validation.validator)('number', {
      gte: 0,
      allowString: true,
      descriptionKey: 'errors.descriptionKeys.netPrice',
      disabled: Ember.computed('model.netPrice', 'model.isSelected', function () {
        return this.get('model.netPrice') === '' || !this.get('model.isSelected');
      })
    }), (0, _validation.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isSelected')
    })],
    margin: [(0, _validation.validator)('number', {
      gte: 0,
      allowString: true,
      descriptionKey: 'errors.descriptionKeys.margin',
      disabled: Ember.computed('model.margin', 'model.isSelected', function () {
        return this.get('model.margin') === '' || !this.get('model.isSelected');
      })
    }), (0, _validation.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isSelected')
    })],
    minimumTickets: [(0, _validation.validator)('presence', {
      presence: true,
      descriptionKey: 'errors.descriptionKeys.minimumTickets',
      disabled: Ember.computed.not('model.isSelected')
    })],
    priceCode: [(0, _validation.validator)('length', {
      min: 0,
      max: 20,
      descriptionKey: 'errors.descriptionKeys.priceCodeSKU',
      disabled: Ember.computed.not('model.isSelected')
    })]
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    priceId: _emberData.default.attr('number'),
    ticketTypeId: _emberData.default.attr('number'),
    basePrice: _emberData.default.attr('number'),
    netPrice: _emberData.default.attr('number'),
    margin: _emberData.default.attr('number'),
    minimumTickets: _emberData.default.attr('number'),
    priceCode: _emberData.default.attr('string'),
    isSelected: _emberData.default.attr('boolean', { defaultValue: true })
  });
});