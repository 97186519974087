define('lxso/validators/new-option-group-booking-cutoff', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NewOptionGroupBookingCutoff = _base.default.extend({
    i18n: Ember.inject.service(),
    permissions: Ember.inject.service(),

    validate: function validate(value, options, model, attribute) {
      'use strict';

      var isInternal = this.get('permissions').checkEnabled('V3InternalSettings');
      var cutoffMax = isInternal ? 504 : 24;
      var cutoffInHours = parseInt(model.get('bookingCutoffInHours')) || 0;
      if (cutoffInHours > cutoffMax) {
        if (isInternal) {
          return this.get('i18n').t('pages.optionGroup.optionGroupSection.details.validationMessages.bookingCutoff504').string;
        } else {
          return this.get('i18n').t('pages.optionGroup.optionGroupSection.details.validationMessages.bookingCutoff24').string;
        }
      }
      return true;
    }
  });

  exports.default = NewOptionGroupBookingCutoff;
});