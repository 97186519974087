define('lxso/routes/change-history', ['exports', 'lxso/config/environment', 'lxso/mixins/require-permission', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag'], function (exports, _environment, _requirePermission, _authenticatedRouteMixin, _featureFlag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, {

    requiredPermission: 'V3ChangeHistory',
    uitk: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    queryParams: {
      from: { refreshModel: false },
      to: { refreshModel: false },
      productId: { refreshModel: false },
      offerId: { refreshModel: false },
      changeDetail: { refreshModel: false }
    },

    model: function model(params) {
      'use strict';

      var _this = this;

      return this.store.query('changeHistory', { id: params.supplierBranchId }).catch(function () {
        _this.intermediateTransitionTo('error');
      });
    },
    setupController: function setupController(controller, model) {
      var supplierBranchId = model.query.id;

      if (supplierBranchId) {
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/supplier_branches/' + supplierBranchId;
        this.get('ajaxWrapper').ajaxGet(url).then(function (results) {
          controller.set('supplierBranchName', results.supplier_branch_name);
        });
      }
      controller.set('model', model);
    },


    actions: {
      willTransition: function willTransition() {
        //for tests
        delete window.pageName;
        this.get('uitk').modal.close();
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'selectScheduleGroup';
      }
    }
  });
});