define('lxso/adapters/short-activity', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    query: function query(store, type, _query) {
      var url = this.host + '/' + this.namespace + '/supplier_branches/' + _query.supplierId + '/activities';
      return this.ajax(url, 'GET');
    }
  });
});