define('lxso/utils/change-history-details-display-modes/v4-price-group', ['exports', 'lxso/utils/change-history-details-display-modes/list', 'lxso/utils/change-history-details-display-modes/date', 'lxso/utils/change-history-details-display-modes/v4-schedule'], function (exports, _list, _date, _v4Schedule) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = {
    partialName: 'v4-price-group',
    diff: function diff(oldValue, newValue, _ref) {
      var i18n = _ref.i18n;

      var oldData = oldValue ? getParsedData(oldValue) : {};
      var newData = newValue ? getParsedData(newValue) : {};
      var offerIds = [].concat(_toConsumableArray(getScheduleOfferIds(oldData)), _toConsumableArray(getScheduleOfferIds(newData))).uniq();
      function difference(displayMode, areDisplayable, value) {
        var newValue = value(newData);
        var oldValue = value(oldData);

        return areDisplayable(oldValue, newValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }

      return {
        travelStart: difference(_date.default, present, function (data) {
          return data.travelStart;
        }),
        travelEnd: difference(_date.default, present, function (data) {
          return data.travelEnd;
        }),
        bookingStart: difference(_date.default, present, function (data) {
          return data.bookingStart;
        }),
        bookingEnd: difference(_date.default, present, function (data) {
          return data.bookingEnd;
        }),
        prices: difference(_list.default, present, function (data) {
          return getPriceStrings(data.prices);
        }),
        schedules: getSchedulesOldAndNewByOffer(_v4Schedule.default, oldData.schedules, newData.schedules, offerIds)
      };
    }
  };


  function getParsedData(serializedDate) {
    var priceGroup = JSON.parse(serializedDate);
    priceGroup.prices = priceGroup.prices && priceGroup.prices.length ? priceGroup.prices.map(function (price) {
      return JSON.parse(price);
    }) : [];
    priceGroup.schedules = priceGroup.schedules && priceGroup.schedules.length ? priceGroup.schedules.map(function (schedule) {
      var parsedSchedule = JSON.parse(schedule);
      parsedSchedule.details = parsedSchedule.details.map(function (detail) {
        return JSON.parse(detail);
      });
      return parsedSchedule;
    }) : [];
    return priceGroup;
  }

  function getPriceStrings(prices) {
    return prices ? prices.map(function (price) {
      var priceStr = 'Ticket type: ' + price.ticketType + '; Min tickets: ' + price.minimumTickets + ';';
      priceStr += ' Base price: ' + formatPrice(price.basePrice) + '; Net price: ' + formatPrice(price.netPrice) + ';';
      priceStr += ' Margin: ' + formatPrice(price.margin) + ';';
      if (price.priceCode) {
        priceStr += ' Price code: ' + price.priceCode;
      }
      return priceStr;
    }) : [];
  }

  function getSchedulesOldAndNewByOffer(displayMode, oldSchedules, newSchedules, offerIds) {
    return offerIds.map(function (offerId) {
      var oldValue = oldSchedules ? oldSchedules.find(function (schedule) {
        return schedule.offerId === offerId;
      }) : null;
      var newValue = newSchedules ? newSchedules.find(function (schedule) {
        return schedule.offerId === offerId;
      }) : null;
      return {
        displayMode: displayMode,
        oldValue: oldValue,
        newValue: newValue
      };
    });
  }

  function present(oldValue, newValue) {
    return Ember.isPresent(oldValue) || Ember.isPresent(newValue);
  }

  function formatPrice(price) {
    return parseFloat(price);
  }

  function getScheduleOfferIds(priceGroup) {
    return priceGroup && priceGroup.schedules ? priceGroup.schedules.map(function (s) {
      return s.offerId;
    }) : [];
  }
});