define('lxso/authenticators/sso', ['exports', 'ember-simple-auth/authenticators/base', 'lxso/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var loginUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/system/login';

  exports.default = _base.default.extend({
    restore: function restore(sessionData) {
      var resolve = new Ember.RSVP.resolve(sessionData);
      return resolve;
    },
    authenticate: function authenticate() {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: _environment.default.api.host + '/' + _environment.default.api.namespace + '/system/user_infos',
          type: 'get',
          crossDomain: true,
          cache: false,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8',
          xhrFields: {
            withCredentials: true
          }
        })

        // Authentication success
        .done(function (response) {
          resolve(response);
        })

        // Authentication failure
        .fail(function (reason) {
          reject(reason);
        });
      });
    },
    invalidate: function invalidate() {
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$.ajax({
          url: loginUrl,
          type: 'delete',
          crossDomain: true,
          cache: false,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8',
          xhrFields: {
            withCredentials: true
          }
        }).always(function () {
          Ember.run.scheduleOnce('afterRender', function () {
            'use strict';

            window.location = _environment.default.api.host + '/logout?redirect=' + _environment.default.baseURL;
          });
          resolve();
        });
      });
    }
  });
});