define('lxso/helpers/get-commentary-type-supported-langs', ['exports', 'lxso/constants/language-type-ids'], function (exports, _languageTypeIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getCommentaryTypeSupportedLangs = getCommentaryTypeSupportedLangs;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function getCommentaryTypeSupportedLangs(params) {
    var _params = _slicedToArray(params, 2),
        languageEnum = _params[0],
        languageTypeId = _params[1];

    var supportedLanguageEnum = languageEnum.filter(function (language) {
      return !_languageTypeIds.SPOKEN_CHINESE_LANG_IDS.includes(language.id) && !_languageTypeIds.WRITTEN_CHINESE_LANG_IDS.includes(language.id) || _languageTypeIds.SPOKEN_CHINESE_LANG_IDS.includes(language.id) && _languageTypeIds.SPOKEN_LANG_TYPE_IDS.includes(languageTypeId) || _languageTypeIds.WRITTEN_CHINESE_LANG_IDS.includes(language.id) && languageTypeId === _languageTypeIds.LANG_TYPE_IDS.WRITTEN_GUIDE;
    });
    return supportedLanguageEnum;
  }

  exports.default = Ember.Helper.helper(getCommentaryTypeSupportedLangs);
});