define('lxso/transforms/moment-datetime', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var dateFormat = moment.ISO_8601;

  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return moment(serialized, dateFormat, true);
    },
    serialize: function serialize(deserialized) {
      var validMomentObject = moment.isMoment(deserialized) && deserialized.isValid();
      if (!validMomentObject) {
        throw new Ember.Error('Not a valid moment object: ' + deserialized);
      }

      return deserialized.format(dateFormat);
    }
  });
});