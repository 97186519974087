define('lxso/validators/array-input-regex-validation', ['exports', 'lxso/validators/input-regex-validation', 'lxso/mixins/array-validation-model'], function (exports, _inputRegexValidation, _arrayValidationModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArrayInputRegex = _inputRegexValidation.default.extend(_arrayValidationModel.default);

  ArrayInputRegex.reopenClass({
    getDependentsFor: function getDependentsFor(attribute) {
      return ['model.' + attribute + '.[]'];
    }
  });

  exports.default = ArrayInputRegex;
});