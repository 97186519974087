define('lxso/validators/og-availability-by-offers', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OgAvailabilityByOffers = _base.default.extend({
    i18n: Ember.inject.service(),
    validate: function validate(availabilityByOffers, options, model, attribute) {
      var errorMessage = this.get('i18n').t('pages.optionGroup.errorMessages.minOneAvailabilityOffer').string;
      var selectedOfferAvailabilities = availabilityByOffers.filter(function (availabilityByOffer) {
        return availabilityByOffer.get('isSelected');
      });
      if (selectedOfferAvailabilities.length < 1) {
        return errorMessage;
      } else {
        return true;
      }
    }
  });

  exports.default = OgAvailabilityByOffers;
});