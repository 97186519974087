define('lxso/adapters/suppliers', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'api/v1',

    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var sId = snapshot.id;
      var url = this.host + '/' + this.namespace + '/suppliers/' + sId;

      return this.ajax(url, 'PUT', { data: data });
    },
    queryRecord: function queryRecord(store, type, query) {
      var url = void 0;
      if (query.isBasic && query.branchId) {
        url = this.host + '/' + this.namespace + '/suppliers/' + query.supplierId + '?basic=true&branchId=' + query.branchId;
      } else {
        url = this.host + '/' + this.namespace + '/suppliers/' + query.supplierId;
      }
      return this.ajax(url, 'GET');
    }
  });
});