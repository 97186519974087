define('lxso/services/response-errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    i18n: Ember.inject.service(),

    init: function init() {
      this.set('errors', []);
    },
    clearErrors: function clearErrors() {
      this.set('errors', []);
    },
    addErrors: function addErrors(payload, status) {
      var _this = this;

      //unsure if this if is used anywhere
      // if (payload && payload.errors) {
      //
      //   payload.errors.forEach((error) => {
      //
      //     // Set all 500 type errors to 500
      //     if (error.status >= 500) {
      //       error.status = 500;
      //     }
      //
      //     if (error.detail === 'The adapter operation timed out') {
      //       error.status = 'timeout';
      //     }
      //
      //     // If the error is not a 400 and is not already in the errors array, add it
      //     if (error.status !== 400 && this.get('errors').includes(error.status) === false) {
      //       this.get('errors').pushObject(error.status);
      //     }
      //   });
      //
      // }
      var serverError = { generic: this.get('i18n').t('errors.serverError') }; //adds generic message
      if (status === 500 || !status && payload && payload.errors) {
        if (this.get('errors').includes(serverError) === false) {
          this.get('errors').pushObject(serverError);
        }
      } else if (status > 299) {
        if (payload.details) {
          payload.details.forEach(function (detail) {
            if (_this.get('errors').includes(detail.message) === false) {
              _this.get('errors').pushObject(detail);
            }
          });
        } else if (payload.additionalInfo) {
          var keys = Object.keys(payload.additionalInfo);
          keys.forEach(function (key) {
            if (key != 'request-uuid') {
              _this.get('errors').pushObject({ message: payload.additionalInfo[key], key: key });
            }
          });
          if (this.get('errors').length === 0) {
            if (payload.errorMessage) {
              this.get('errors').pushObject({ message: payload.errorMessage });
            } else {
              this.get('errors').pushObject({ message: serverError });
            }
          }
        } else if (payload.errors) {
          if (this.get('errors').includes(payload) === false) {
            this.get('errors').pushObject(payload);
          }
        }
      }
    }
  });
});