define('lxso/helpers/convert-minutes-to-days', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convertMinutesToDays = convertMinutesToDays;
  function convertMinutesToDays(params) {
    return params[0] ? Math.floor(params[0] / (24 * 60)) : params[1];
  }

  exports.default = Ember.Helper.helper(convertMinutesToDays);
});