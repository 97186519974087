define('lxso/components/offer-hours-of-operation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      toggleDaysFunction: function toggleDaysFunction() {
        this.set('day.available', !this.get('day.available'));
      }
    }
  });
});