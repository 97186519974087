define('lxso/components/notification-message', ['exports', 'ember-cli-notifications/components/notification-message', 'lxso/config/environment'], function (exports, _notificationMessage, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var config = _environment.default['ember-cli-notifications'] || {};

  exports.default = _notificationMessage.default.extend({
    icons: config.icons || 'font-awesome'
  });
});