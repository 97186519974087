define('lxso/models/zone-association', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    zoneId: _emberData.default.attr('number'),
    offerId: _emberData.default.attr('number'),
    activityId: _emberData.default.attr('number'),
    zoneName: _emberData.default.attr('string'),
    offerTitle: _emberData.default.attr('string'),
    offerStatus: _emberData.default.attr('string'),
    activityTitle: _emberData.default.attr('string')
  });
});