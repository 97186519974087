define('lxso/services/route-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    setPromotionBackURL: function setPromotionBackURL(routeName) {
      if (routeName) {
        this.set('promotionBackRoute', routeName);
      } else {
        this.set('promotionBackRoute', undefined);
      }
    },
    getPromotionBackURL: function getPromotionBackURL() {
      return this.get('promotionBackRoute');
    },
    setProductId: function setProductId(productId) {
      this.set('productId', productId);
    },
    getProductId: function getProductId() {
      return this.get('productId');
    }
  });
});