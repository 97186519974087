define('lxso/mixins/platform-settings/url-configurations', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    initUrlConfiguration: function initUrlConfiguration(model) {
      this.controller.set('saving', false);

      this.controller.set('integrationUrls', model.integrationUrl);
      this.controller.set('isIntegrationUrlOpen', true);
      this.controller.set('editingIntegration', false);
      this._calcIsCustomUrlChecked(model.integrationUrl);

      this.controller.set('productionUrls', model.productionUrl);
      this.controller.set('isProductionUrlOpen', true);
      this.controller.set('editingProduction', false);
      this._calcIsCustomUrlChecked(model.productionUrl);
    },
    _calcIsCustomUrlChecked: function _calcIsCustomUrlChecked(urls) {
      var isIntegration = urls.get('environment') === 'Integration';
      var isChecked = urls.get('checkOfferDateAvailabilityRelativePath').substring(1) || urls.get('commitSaleRelativePath').substring(1) || urls.get('commitSaleCancellationRelativePath').substring(1) || urls.get('createSaleRelativePath').substring(1) || urls.get('createSaleCancellationRelativePath').substring(1) || urls.get('releaseSaleRelativePath').substring(1) || urls.get('releaseFulfillmentRelativePath').substring(1) || urls.get('pricingIngestionRelativePath').substring(1) || urls.get('releaseSaleCancellationRelativePath').substring(1);
      if (isIntegration) {
        this.controller.set('isIntegrationCustomUrlChecked', !!isChecked);
      } else {
        this.controller.set('isProductionCustomUrlChecked', !!isChecked);
      }
    },
    setErrors: function setErrors(type, errors) {
      if (type === 'integration') {
        this.controller.set('integrationErrors', errors);
      } else {
        this.controller.set('productionErrors', errors);
      }
    },
    clearErrors: function clearErrors(type) {
      if (type === 'integration') {
        this.controller.set('integrationErrors', '');
      } else {
        this.controller.set('productionErrors', '');
      }
    },
    createNewPlatform: function createNewPlatform(environment) {
      var platform = this.controller.get('platform');
      var newPlatformUrls = this.store.createRecord('platformUrlConfiguration', {
        platform: platform,
        environment: environment
      });
      return newPlatformUrls;
    },
    _validatePlatformUrl: function _validatePlatformUrl(type) {
      var validationData = void 0;
      if (type === 'integration') {
        validationData = {
          isCustomUrlChecked: this.controller.get('isIntegrationCustomUrlChecked'),
          model: this.controller.get('integrationUrls')
        };
      } else {
        validationData = {
          isCustomUrlChecked: this.controller.get('isProductionCustomUrlChecked'),
          model: this.controller.get('productionUrls')
        };
      }

      var attributes = [];
      if (validationData.isCustomUrlChecked) {
        attributes = ['baseUrl', 'checkOfferDateAvailabilityRelativePath', 'commitSaleRelativePath', 'commitSaleCancellationRelativePath', 'createSaleRelativePath', 'createSaleCancellationRelativePath', 'releaseSaleRelativePath', 'releaseSaleCancellationRelativePath', 'releaseFulfillmentRelativePath', 'pricingIngestionRelativePath'];
      } else {
        attributes = ['baseUrl'];
      }
      return (0, _formValidator.validate)({ model: validationData.model, attributes: attributes });
    },

    actions: {
      savePlatformUrlSection: function savePlatformUrlSection(platformData) {
        var isIntegration = platformData.get('environment') === 'Integration';
        var route = this;
        route.controller.set('saving', true);
        var type = void 0;
        if (isIntegration) {
          route.controller.set('lastSaved', 'integrationUrl');
          type = 'integration';
        } else {
          type = 'production';
          route.controller.set('lastSaved', 'productionUrl');
        }
        this._validatePlatformUrl(type).then(function () {
          return platformData.save();
        }).then(function (success) {
          if (isIntegration) {
            route.controller.set('isIntegrationUrlFormDirty', false);
            route.controller.set('editingIntegration', false);
          } else {
            route.controller.set('isProductionUrlFormDirty', false);
            route.controller.set('editingProduction', false);
          }
          route.clearErrors(type);
          route.get('notifications').success(route.get('i18n').t('components.alert.saveSuccess'));
          route._calcIsCustomUrlChecked(platformData);
        }).catch(function (errors) {
          Ember.Logger.error('error updating ' + type + ' urls', errors);
          route.setErrors(type, errors);
        }).finally(function () {
          route.controller.set('saving', false);
        });
      },
      validatePlatformUrl: function validatePlatformUrl(type) {
        var _this = this;

        this._validatePlatformUrl(type).then(function () {
          _this.clearErrors(type);
        }).catch(function (errors) {
          Ember.Logger.error(type + ' urls configuration validation errors', errors);
          _this.setErrors(type, errors);
        });
      },
      editPlatformUrlSection: function editPlatformUrlSection(type) {
        if (this.controller.get('isAnySectionEditing')) {
          this.controller.set('sectionToBeEdited', type);
        } else {
          if (type === 'integration') {
            this.controller.set('editingIntegration', true);
          } else {
            this.controller.set('editingProduction', true);
          }
        }
      },
      cancelEditPlatformUrlSection: function cancelEditPlatformUrlSection(type) {
        if (type === 'integration') {
          var integrationUrls = this.controller.get('integrationUrls');
          if (integrationUrls.get('isNew')) {
            integrationUrls.rollbackAttributes();
            //rollbacking new record will delete the record.
            //Create a new record to store data entered after rollback
            var newIntegrationUrls = this.createNewPlatform('Integration');
            this.controller.set('integrationUrls', newIntegrationUrls);
          } else {
            integrationUrls.rollbackAttributes();
          }
          this._calcIsCustomUrlChecked(integrationUrls);
          this.controller.set('isIntegrationUrlFormDirty', false);
          this.controller.set('editingIntegration', false);
        } else {
          var productionUrls = this.controller.get('productionUrls');
          if (productionUrls.get('isNew')) {
            productionUrls.rollbackAttributes();
            var newProductionUrls = this.createNewPlatform('Production');
            this.controller.set('productionUrls', newProductionUrls);
          } else {
            productionUrls.rollbackAttributes();
          }
          this._calcIsCustomUrlChecked(productionUrls);
          this.controller.set('isProductionUrlFormDirty', false);
          this.controller.set('editingProduction', false);
        }
        this.clearErrors(type);
      }
    }
  });
});