define('lxso/helpers/bq-offer-group-details-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          productAndOfferGroupDetails = _ref2[0];

      var i18n = this.get('i18n');
      var productsCount = productAndOfferGroupDetails.get('length');
      var offerGroupsCount = 0;
      productAndOfferGroupDetails.forEach(function (product) {
        offerGroupsCount += product.get('offerGroups.length');
      });
      var productsLabel = productsCount === 1 ? i18n.t('pages.bookingQuestions.list.offerDetails.product') : i18n.t('pages.bookingQuestions.list.offerDetails.products');
      var optionGroupsLabel = offerGroupsCount === 1 ? i18n.t('pages.bookingQuestions.list.offerDetails.optionGroup') : i18n.t('pages.bookingQuestions.list.offerDetails.optionGroups');
      if (!offerGroupsCount) {
        return productsCount + ' ' + productsLabel;
      } else {
        return productsCount + ' ' + productsLabel + '; ' + offerGroupsCount + ' ' + optionGroupsLabel;
      }
    }
  });
});