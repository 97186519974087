define('lxso/serializers/account-settings/supplier-branch', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    normalize: function normalize(model, hash) {
      hash.supplierBranchId = this.extractId(model, hash);
      return this._super.apply(this, arguments);
    }
  });
});