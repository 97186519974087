define('lxso/validators/duplicate-tickets', ['exports', 'ember-cp-validations/validators/base', 'lxso/utils/overlap-failure-reducer'], function (exports, _base, _overlapFailureReducer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DuplicateTickets = _base.default.extend({
    store: Ember.inject.service(),

    validate: function validate(value, options, model, attribute) {
      'use strict';

      var _this = this;

      var priceArray = model.get('prices');
      var errorMessage = model.get('i18n').t('errors.customMessages.offers.duplicateTicket').string;
      var failures = [];
      var vehicleTicketTypeID = 51;

      var ticketTypeArray = priceArray.map(function (price, index) {
        var ticketTypeId = price.get('ticketTypeId');
        var ticketType = _this.get('store').peekRecord('ticket-type', ticketTypeId);
        var guestTypeId = ticketType.get('guest_type_id');
        return { ticketTypeId: ticketTypeId, guestTypeId: guestTypeId };
      });

      //for GT we allow multiple Vehcile tickets in same price group, but not two tickets with same capacity
      for (var i = 0; i < ticketTypeArray.get('length'); i++) {
        if (ticketTypeArray[i].guestTypeId == vehicleTicketTypeID) {
          for (var j = 1; j < ticketTypeArray.get('length'); j++) {
            if (i != j && ticketTypeArray[i].ticketTypeId == ticketTypeArray[j].ticketTypeId) {
              failures.push('ticketType' + i, 'ticketType' + j);
            }
          }
        }
      }

      if (failures.length > 0) {
        return (0, _overlapFailureReducer.overlapFailureReducer)(failures, errorMessage);
      } else {
        return true;
      }
    }
  });

  exports.default = DuplicateTickets;
});