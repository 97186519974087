define('lxso/helpers/is-active-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isActiveLink = isActiveLink;
  function isActiveLink(params) {
    if (params[1].includes('activity')) {
      if (params[0] === 'products' && params[2]) {
        return true;
      } else if (params[0] === 'product-setup' && !params[2]) {
        return true;
      } else if (params[0] === 'product-setup' && params[1] === 'createactivity') {
        return true;
      }
    } else if (params[0] === params[1]) {
      return true;
    }
    return false;
  }

  exports.default = Ember.Helper.helper(isActiveLink);
});