define('lxso/components/help-icon/component', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    omnitureMap: {
      retailPrice: 'RetailPriceHelp',
      priceCode: 'PriceCodeHelp',
      referencePrice: 'RefPriceHelp',
      netPrice: 'NetPriceHelp',
      offerStartTime: 'StartTimeHelp',
      vbpMaxPax: 'MaxPaxHelp',
      vbpMinPax: 'MinPaxHelp',
      priceType: 'PriceTypeHelp'
    },
    actions: {
      helpAction: function helpAction(helpItem) {
        var omnitureKey = this.omnitureMap[helpItem];
        if (omnitureKey) {
          var omnitureEvent = 'page.LX-Supply.' + omnitureKey;
          (0, _omniture.sendOmnitureDataOnClick)(omnitureEvent);
        }
      }
    }
  });
});