define('lxso/serializers/health-and-safety', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalize: function normalize(typeClass, hash) {
      var enhancedCleaningQuestionsDefault = [1, 2, 3, 4];
      var travelerSafetyQuestionsDefault = [5, 6, 7, 8, 9, 10, 11, 12, 13];
      var covidStatusQuestionsDefault = [18];
      hash.id = hash.activityID;

      if (!hash.healthAndSafetySelected) {
        hash.healthAndSafetySelected = '';
        hash.isNewHealthAndSafetyInfo = true;
      } else {
        hash.healthAndSafetySelected = hash.healthAndSafetySelected.answer;
      }
      if (!hash.healthAndSafetySelected || hash.healthAndSafetySelected == 'No') {
        hash.enhancedCleaningQuestions = this.createEmptyHealthAndSafetyQuestions(enhancedCleaningQuestionsDefault);
        hash.travelerSafetyQuestions = this.createEmptyHealthAndSafetyQuestions(travelerSafetyQuestionsDefault);
        hash.covidStatusQuestions = this.createCovidStatusQuestions(covidStatusQuestionsDefault);
      } else {
        hash.enhancedCleaningQuestions = this.serializeHealthAndSafetyQuestions(hash.healthAndSafetyQuestions, enhancedCleaningQuestionsDefault);
        hash.travelerSafetyQuestions = this.serializeHealthAndSafetyQuestions(hash.healthAndSafetyQuestions, travelerSafetyQuestionsDefault);
        hash.covidStatusQuestions = this.serializeHealthAndSafetyQuestionsForCovidStatus(hash.healthAndSafetyQuestions, covidStatusQuestionsDefault);
      }
      return this._super.apply(this, arguments);
    },
    createEmptyHealthAndSafetyQuestions: function createEmptyHealthAndSafetyQuestions(model) {
      return model.map(function (id) {
        var item = { qid: id,
          answer: '',
          additionalOption: Ember.A([]) };
        return item;
      });
    },
    createCovidStatusQuestions: function createCovidStatusQuestions(model) {
      return model.map(function (id) {
        var item = { qid: id,
          answer: '',
          covidStatusOption: {},
          additionalOption: Ember.A([]) };
        return item;
      });
    },
    serializeHealthAndSafetyQuestionsForCovidStatus: function serializeHealthAndSafetyQuestionsForCovidStatus(model, defaultList) {
      var _this = this;

      var questions = model.filter(function (q) {
        return defaultList.includes(q.qid);
      }).map(function (question) {
        var item = { qid: question.qid,
          answer: question.answer,
          covidStatusOption: question.additionalOption,
          additionalOption: _this.serializeHealthAndSafetyQuestionsForCovidStatusForAddition(question.additionalOption) };
        return item;
      });
      if (questions.length == 0) {
        questions = this.createCovidStatusQuestions(defaultList);
      }
      return questions;
    },
    serializeHealthAndSafetyQuestionsForCovidStatusForAddition: function serializeHealthAndSafetyQuestionsForCovidStatusForAddition(covidAdditionalOption) {
      var additionalOption = [];
      if (covidAdditionalOption.proofOfCovid19Negative) {
        if (covidAdditionalOption.proofOfCovid19Negative.acceptedTestTypes) {
          Object.entries(covidAdditionalOption.proofOfCovid19Negative.acceptedTestTypes).filter(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
                k = _ref2[0],
                v = _ref2[1];

            return v == true;
          }).forEach(function (_ref3) {
            var _ref4 = _slicedToArray(_ref3, 2),
                k = _ref4[0],
                v = _ref4[1];

            return additionalOption.push(k);
          });
        }
        if (covidAdditionalOption.proofOfCovid19Negative.timePriorToTest) {
          Object.entries(covidAdditionalOption.proofOfCovid19Negative.timePriorToTest).filter(function (_ref5) {
            var _ref6 = _slicedToArray(_ref5, 2),
                k = _ref6[0],
                v = _ref6[1];

            return v == true;
          }).forEach(function (_ref7) {
            var _ref8 = _slicedToArray(_ref7, 2),
                k = _ref8[0],
                v = _ref8[1];

            return additionalOption.push(k);
          });
        }
        if (covidAdditionalOption.proofOfCovid19Negative.acceptedTestTypes.notApplicable) {
          additionalOption.push('notApplicable1');
        }
      }
      return additionalOption;
    },
    serializeHealthAndSafetyQuestions: function serializeHealthAndSafetyQuestions(model, defaultList) {
      var _this2 = this;

      var questions = model.filter(function (q) {
        return defaultList.includes(q.qid);
      }).map(function (question) {
        var item = { qid: question.qid,
          answer: question.answer,
          additionalOption: _this2.convertToAdditionalOption(question.additionalOption) };
        return item;
      });
      return questions;
    },
    serializeHealthAndSafetyQuestionsAndCreateCovidStatus: function serializeHealthAndSafetyQuestionsAndCreateCovidStatus(model, defaultList) {
      var questions = this.serializeHealthAndSafetyQuestions(model, defaultList);
      if (questions.length == 0) {
        questions = this.createCovidStatusQuestions(defaultList);
      }
      return questions;
    },
    convertToAdditionalOption: function convertToAdditionalOption(additionalOption) {
      var additionalOptionList = Ember.A([]);
      if (additionalOption) {
        if (additionalOption.gloves) {
          additionalOptionList.pushObject('Gloves');
        }
        if (additionalOption.masks) {
          additionalOptionList.pushObject('Masks');
        }
        if (additionalOption.vacantTimeRangeStart) {
          additionalOptionList.pushObject(additionalOption.vacantTimeRangeStart + ',' + additionalOption.vacantTimeRangeEnd);
        }
      }
      return additionalOptionList;
    }
  });
});