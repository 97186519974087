define('lxso/helpers/is-primary-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isPrimaryImage = isPrimaryImage;
  function isPrimaryImage(params) {
    //params: index, type
    return params[0] === 0 && params[1] === 'saved' ? true : false;
  }

  exports.default = Ember.Helper.helper(isPrimaryImage);
});