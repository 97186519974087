define('lxso/validators/notification-unique-email', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NotificationUniqueEmail = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var array = model.get(options.array);
      var emailList = array.map(function (item) {
        return item.email;
      });
      var newEmail = value;
      if (emailList.indexOf(newEmail) >= 0) {
        return model.get('i18n').t('errors.customMessages.supplierSettings.duplicateEmail').string;
      } else {
        return true;
      }
    }
  });

  exports.default = NotificationUniqueEmail;
});