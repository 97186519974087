define('lxso/components/extra-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    nameInHeader: Ember.inject.service(),
    showPlatform: Ember.computed.and('nameInHeader.displayPlatform', 'platform'),
    displayed: Ember.computed.alias('nameInHeader.shouldDisplay'),
    content: Ember.computed.alias('nameInHeader.name'),
    platform: Ember.computed.alias('nameInHeader.platformName')
  });
});