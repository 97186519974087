define('lxso/components/bulk-cancellation-for-multiple-supplier/component', ['exports', 'lxso/utils/add-json-api-errors-to-payload', 'lxso/config/environment', 'lxso/constants/cancellation-option', 'lxso/mixins/enum-getter'], function (exports, _addJsonApiErrorsToPayload, _environment, _cancellationOption, _enumGetter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SAMPLE_FILE_NAME = 'bulk-cancellation-sample.csv';
  var oneHundredYearsAgo = moment().subtract(100, 'months');
  var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
  var state = 'active,suspendedexpedia,suspendedpaused';
  var url = domain + '/supplier_branches/search_typeahead?state=' + state + '&name=';
  var url3p = domain + '/supplier_branches/search_typeahead?is3p=true&state=' + state + '&name=';
  var supplierUrl = domain + '/suppliers/search?state=' + state + '&name=';
  var labelPrefix = 'pages.bulkUpdate.bulkCancellationMultipleSupplier';

  exports.default = Ember.Component.extend(_enumGetter.default, {
    responseErrors: Ember.inject.service(),
    fileSaver: Ember.inject.service(),
    i18n: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    dateFormat: Ember.inject.service(),
    jsXlsx: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    init: function init() {
      this._super.apply(this, arguments);
      var i18n = this.get('i18n');
      this.setProperties({
        bulkCancelUploadFile: null,
        bulkCancellationRequestModalOpen: false,
        processingRequest: false,
        hasFailures: false,
        showReasonText: false,
        bulkCancellationConfirmationFlag: false,
        selectedSearchOptionsIndex: 0,
        itemsPerPage: 10,
        isSearchClicked: false,
        sortParam: 'travelDate',
        sortableHeaderKeys: Ember.A(['travelDate']),
        cancellationReportTableHeaderBasekey: 'pages.cancellation.labels.',
        reasonDropDown: {
          options: Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL.keys()),
          values: Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL.values()),
          default: Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL.values())[0]
        },
        searchOptions: [this.get('i18n').t(labelPrefix + '.option2.searchOptions.supplierName'), this.get('i18n').t(labelPrefix + '.option2.searchOptions.supplierBranchName'), this.get('i18n').t(labelPrefix + '.option2.searchOptions.supplierBranchName3P'), this.get('i18n').t(labelPrefix + '.option2.searchOptions.supplierBranchID'), this.get('i18n').t(labelPrefix + '.option2.searchOptions.productID'), this.get('i18n').t(labelPrefix + '.option2.searchOptions.itineraryNumber')],
        cancellationReportTableHeaderKeys: Ember.A(['productTitle', 'optionTitle', 'itineraryNumber', 'saleID', 'saleStatus', 'travelDate', 'retailPrice']),
        searchParametersSelected: Ember.computed('idToSearch', 'dateRangeFrom', 'dateRangeTo', 'selectedInTypeaheadId', function () {
          if (this.get('selectedSearchOptionsIndex') == 5) {
            return !!this.get('idToSearch');
          } else if (!!this.get('dateRangeFrom') && !!this.get('dateRangeTo')) {
            if (this.get('selectedSearchOptionsIndex') > 2) {
              return !!this.get('idToSearch');
            } else {
              return !!this.get('selectedInTypeaheadId');
            }
          } else {
            return false;
          }
        }),
        cancellationReportTableKeys: Ember.A(['productTitle', 'optionTitle', 'itineraryNumber', 'saleID', 'saleStatus', 'travelDate', 'retailPrice']),
        sortedReports: Ember.computed('reports', 'sortParam', 'sortAscending', function () {
          var sortedReports = [];
          sortedReports = this.get('reports').sort(function (a, b) {
            if (moment(a.travelDate) < moment(b.travelDate)) {
              return -1;
            } else if (moment(a.travelDate) > moment(b.travelDate)) {
              return 1;
            } else {
              return 0;
            }
          });

          if (this.get('sortAscending')) {
            return sortedReports;
          } else {
            return sortedReports.reverse();
          }
        })
      });
      if (this.get('featureFlags').checkEnabled('PastDateCancellation')) {
        this.set('minValidDate', oneHundredYearsAgo);
      }
      this.set('supplierSource', this.getEnumFunction(supplierUrl));
      this.set('supplierBranchSource', this.getEnumFunction(url));
      this.set('supplierBranchSource3p', this.getEnumFunction(url3p));
      this.set('dateRangeFrom', '');
      this.set('dateRangeTo', '');
      this.set('searchOption2InputLabel', this.get('searchOptions')[0]);
    },


    actions: {
      showOption2ConfirmationModal: function showOption2ConfirmationModal() {
        this.set('selectedOption', 2);
        this.setReasonDropDown(2);
        this.set('bulkCancellationRequestModalOpen', true);
        this.set('hideServerErrors', true);
        var labelTerms1 = this.get('i18n').t(labelPrefix + '.modal.option_2.terms_1');
        var labelTerms2 = this.get('i18n').t(labelPrefix + '.modal.option_2.terms_2');
        this.set('reasonDropDownLabel', this.get('i18n').t(labelPrefix + '.modal.reasonSelectLabelNotOptional'));
        this.set('confirmModalCheckBoxLabel', labelTerms1 + ' ' + this.get('selectedBookingValue') + ' ' + labelTerms2);
        this.set('errors', undefined);
      },
      selectDeselectRow: function selectDeselectRow(saleRowIndex, saleRow) {
        var rep = this.get('reports');
        Ember.set(rep.objectAt(saleRowIndex), 'isSelected', !saleRow.isSelected);
        var count = rep.filter(function (r) {
          return r.isSelected;
        }).length;
        this.set('selectedForCancellationCount', count);
        if (count === rep.length) {
          this.set('isAllRowSelectedForCancellation', true);
        } else {
          this.set('isAllRowSelectedForCancellation', false);
        }
        this.calculateSelectedBookingValue();
      },
      selectDeselectAllRows: function selectDeselectAllRows() {
        var rep = this.get('reports');
        var selected = this.toggleProperty('isAllRowSelectedForCancellation');
        rep.forEach(function (item) {
          if (!item.isDisabled) {
            Ember.set(item, 'isSelected', selected);
          }
        });
        if (selected) {
          this.set('selectedForCancellationCount', rep.filter(function (item) {
            return item.isSelected;
          }).length);
        } else {
          this.set('selectedForCancellationCount', 0);
        }
        this.calculateSelectedBookingValue();
      },
      hideResponseModal: function hideResponseModal() {
        this.set('bulkCancellationResponseModalOpen', false);
        this.hideRequestModal();
      },
      uploadFile: function uploadFile(file) {
        if (file && file.target.files[0] && file.target.files[0].name) {
          this.set('bulkCancelUploadFile', file.target.files[0].name);
          this.set('selectedOption', 1);
          this.setReasonDropDown(1);
          this.set('bulkCancellationRequestModalOpen', true);
          this.set('confirmModalCheckBoxLabel', this.get('i18n').t(labelPrefix + '.modal.option_1.terms_1'));
          this.set('reasonDropDownLabel', this.get('i18n').t(labelPrefix + '.modal.reasonSelectLabel'));
        } else {
          this.set('selectReasonFlag', false);
          this.set('bulkCancelUploadFile', null);
          this.set('bulkCancellationRequestModalOpen', false);
        }
      },
      setSortParamsForTable: function setSortParamsForTable(sortParam, sortAscending) {
        this.set('sortParam', sortParam);
        this.set('sortAscending', sortAscending);
      },
      cancelBulkCancellationByFile: function cancelBulkCancellationByFile() {
        this.set('bulkCancelUploadFile', null);
        this.set('bulkCancellationRequestModalOpen', false);
      },
      changeSelectedSearchOptionsIndex: function changeSelectedSearchOptionsIndex(index) {
        this.set('selectedSearchOptionsIndex', index);
        this.set('searchOption2InputLabel', this.get('searchOptions')[index]);
        this.set('selectedInTypeaheadId', '');
        this.set('idToSearch', '');
      },
      setReason: function setReason(value) {
        this.set('reasonForCancellation', value);
        if (value !== Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL.values())[0]) {
          if (value === 'OTHER') {
            this.set('showReasonText', true);
            this.set('cancelReasonText', '');
            this.set('selectReasonFlag', false);
          } else {
            this.set('showReasonText', false);
            this.set('selectReasonFlag', true);
            this.set('cancelReasonText', '');
          }
        } else {
          this.set('showReasonText', false);
          this.set('selectReasonFlag', false);
          this.set('cancelReasonText', '');
        }
      },
      setReasonText: function setReasonText(value) {
        this.set('cancelReasonText', value.target.value);
        if (this.get('cancelReasonText').length > 0) {
          this.set('selectReasonFlag', true);
        } else {
          this.set('selectReasonFlag', false);
        }
      },
      hideConfirmationModal: function hideConfirmationModal() {
        this.set('errors', {});
        this.set('hideServerErrors', true);
        this.hideRequestModal();
      },
      triggerFileSelect: function triggerFileSelect() {
        $('#bulk-cancel-file').trigger('click');
      },
      bulkCancellationConfirmation: function bulkCancellationConfirmation() {
        this.set('bulkCancellationRequestModalOpen', true);
        this.set('bulkCancellationConfirmationFlag', !this.get('bulkCancellationConfirmationFlag'));
      },
      downloadSampleCSV: function downloadSampleCSV() {
        var sampleData = 'Sale ID\n666\n10667';
        this.createCsvAndDownload(sampleData, SAMPLE_FILE_NAME);
      },
      itemSelectedInTypeahead: function itemSelectedInTypeahead(item) {
        if (this.get('selectedSearchOptionsIndex') > 0) {
          this.set('selectedInTypeaheadId', item.value);
        } else {
          this.set('selectedInTypeaheadId', item.supplier_id);
        }
      },
      resetFile: function resetFile() {
        this.resetFileInput();
      },
      updateCancelFromDate: function updateCancelFromDate(date) {
        this.set('dateRangeFrom', date);
        this.set('formattedFromDate', moment(date).format('MM/DD/YYYY'));
        var toDate = this.get('dateRangeTo');
        if (toDate === '' || moment(date).isAfter(moment(toDate))) {
          this.set('dateRangeTo', date);
          this.set('formattedToDate', moment(date).format('MM/DD/YYYY'));
        }
      },
      updateCancelToDate: function updateCancelToDate(date) {
        this.set('dateRangeTo', date);
        this.set('formattedToDate', moment(date).format('MM/DD/YYYY'));
        if (this.get('dateRangeFrom') === '') {
          this.set('dateRangeFrom', date);
          this.set('formattedFromDate', moment(date).format('MM/DD/YYYY'));
        }
      },
      searchBooking: function searchBooking() {
        var params = this.buildSearchParameter();
        this.findReportsBySearchParam(params);
      },
      cancelSubmit: function cancelSubmit() {
        if (this.get('selectedOption') == 1) {
          this.postCancellationByFile();
        } else {
          this.postCancellation();
        }
      }
    },
    setReasonDropDown: function setReasonDropDown(option) {
      if (option === 1) {
        this.set('reasonDropDown', {
          options: Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_FILE_UPLOAD_AND_CANCEL.keys()),
          values: Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_FILE_UPLOAD_AND_CANCEL.values()),
          default: Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_FILE_UPLOAD_AND_CANCEL.values())[0]
        });
      } else {
        this.set('reasonDropDown', {
          options: Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL.keys()),
          values: Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL.values()),
          default: Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL.values())[0]
        });
      }
    },
    getValueFromArray: function getValueFromArray(array, index) {
      return array && array[index] ? array[index] : '';
    },
    createCsvAndDownload: function createCsvAndDownload(data, fileName) {
      var blob = new Blob(['\uFEFF', data], { type: 'text/csv' });
      this.get('fileSaver').saveAs(blob, fileName);
    },
    resetFileInput: function resetFileInput() {
      $('#bulk-cancel-file').val('');
      this.set('bulkCancelUploadFile', null);
    },
    searchButtonEnable: function searchButtonEnable() {
      this.set('searchParametersSelected', false);
    },
    hideRequestModal: function hideRequestModal() {
      this.set('bulkCancellationRequestModalOpen', false);
      this.set('selectReasonFlag', false);
      this.set('showReasonText', false);
      this.set('cancelReasonText', '');
      this.set('bulkCancellationConfirmationFlag', false);
      this.resetFileInput();
    },
    buildSearchParameter: function buildSearchParameter() {
      var parameterStr = '';
      var index = this.get('selectedSearchOptionsIndex');
      if (index == 5) {
        parameterStr = 'supplierReferenceNumber=' + this.get('idToSearch');
      } else {
        parameterStr = this.buildDateParameterForSearch();
        if (this.get('selectedSearchOptionsIndex') > 2) {
          parameterStr = parameterStr + '&' + this.findParameterNameFromSearchOptionsIndex(index) + '=';
          parameterStr = '' + parameterStr + this.get('idToSearch');
        } else {
          parameterStr = parameterStr + '&' + this.findParameterNameFromSearchOptionsIndex(index) + '=';
          parameterStr = '' + parameterStr + this.get('selectedInTypeaheadId');
        }
      }
      return parameterStr;
    },
    findParameterNameFromSearchOptionsIndex: function findParameterNameFromSearchOptionsIndex(index) {
      if (index == 5) {
        return 'supplierReferenceNumber';
      } else if (index == 4) {
        return 'activityId';
      } else if (index == 3) {
        return 'activityId';
      } else if (index == 2) {
        return 'supplierBranchID';
      } else if (index == 1) {
        return 'supplierBranchID';
      } else {
        return 'supplierID';
      }
    },
    buildDateParameterForSearch: function buildDateParameterForSearch() {
      var dateFormat = this.get('dateFormat.URL_DATE_FORMAT');
      var params = 'startDate=' + this.get('dateRangeFrom').format(dateFormat) + '&endDate=' + this.get('dateRangeTo').format(dateFormat);
      return params;
    },
    findReportsBySearchParam: function findReportsBySearchParam(params) {
      var self = this;
      self.set('reports', []);
      self.set('isLoading', true);
      self.set('hideServerErrors', true);
      self.set('errors', undefined);
      var getReportsUrl = _environment.default.api.host + '/api/v3/cancellation?' + params;
      self.get('ajaxWrapper').ajaxGet(getReportsUrl).then(function (res) {
        var response = res.map(function (r) {
          return Object.assign(r, { isSelected: false, isDisabled: true });
        }).filter(function (r) {
          return r.saleStatus === 'complete' || r.saleStatus === 'Credit applied';
        });
        self.set('isSearchClicked', true);
        self.set('isAllRowsDisabled', false);
        var dateFormat = 'MM-DD-YYYY';
        response.forEach(function (r) {
          r.travelDate = moment(r.travelDate).format(dateFormat);
          if (r.saleStatus === 'cancelled') {
            r.saleStatus = 'Cancelled on ' + moment(r.statusDate).format('MM/DD/YYYY');
          } else if (r.saleStatus === 'Pending cancellation') {
            r.saleStatus = 'Pending cancellation ' + moment(r.cancelDate).format('MM/DD/YYYY');
          } else if (r.saleStatus === 'complete') {
            r.saleStatus = 'Active';
            r.isDisabled = false;
          }
        });
        //Check if any rows valid for cancellation
        if (!response.filter(function (item) {
          return !item.isDisabled;
        }).length > 0) {
          self.set('isAllRowsDisabled', true);
        }
        self.set('reports', response);
        self.set('totalResultsCount', response.length);
      }).catch(function (err) {
        return err;
      }).finally(function () {
        self.set('isLoading', false);
        self.set('showChangeSearchButton', true);
      });
    },
    postCancellationByFile: function postCancellationByFile() {
      var _this = this;

      var cancelFile = document.getElementById('bulk-cancel-file').files[0];
      if (cancelFile) {
        this.set('processingRequest', true);
        var formData = new FormData();
        formData.append('file', cancelFile);
        var reasonCode = this.get('reasonForCancellation');
        if (reasonCode == undefined) {
          reasonCode = '';
        }
        var reason = this.get('cancelReasonText');
        if (reasonCode === 'OTHER') {
          formData.append('reasonCode', 'OTHER');
          formData.append('reason', reason);
        } else {
          formData.append('reasonCode', reasonCode);
        }
        var self = this;
        Ember.$.ajax({
          type: 'PUT',
          url: _environment.default.api.host + '/api/v3/cancellation/byfile',
          mimeType: 'multipart/form-data',
          data: formData,
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          processData: false,
          contentType: false,
          success: function success(data) {
            self.setCancellationResponseSuccessMessage();
            self.resetAfterSubmittingRequest(data);
          },
          error: function error(xhr) {
            if (xhr.status === 400) {
              _this.set('processingRequest', false);
              var errors = (0, _addJsonApiErrorsToPayload.default)(Ember.$.parseJSON(xhr.responseText), xhr.status);
              _this.set('errors', { 'validationErrors': [errors.errorMessage] });
              _this.set('hideServerErrors', false);
            } else {
              self.setCancellationResponseErrorMessage();
              self.resetAfterSubmittingRequest();
            }
          }
        });
      }
    },
    calculateSelectedBookingValue: function calculateSelectedBookingValue() {
      var bookingValue = this.get('reports').filter(function (r) {
        return r.isSelected;
      }).reduce(function (r1, r2) {
        return r1 + parseFloat(r2.retailPrice);
      }, 0);
      this.set('selectedBookingValue', bookingValue.toFixed(2));
    },
    setCancellationResponseSuccessMessage: function setCancellationResponseSuccessMessage() {
      this.set('active', 'five');
      this.set('responseModalHeader', this.get('i18n').t(labelPrefix + '.responseModal.successHeader'));
      this.set('responseModalMessage', this.get('i18n').t(labelPrefix + '.responseModal.successMessage'));
      this.setCancellationCallSuccess();
    },
    setCancellationResponseErrorMessage: function setCancellationResponseErrorMessage() {
      this.set('responseModalHeader', this.get('i18n').t(labelPrefix + '.responseModal.errorHeader'));
      this.set('responseModalMessage', this.get('i18n').t(labelPrefix + '.responseModal.errorMessage'));
      this.setCancellationCallError();
    },
    setCancellationCallSuccess: function setCancellationCallSuccess() {
      this.set('responseIconClass', 'icon-success success');
    },
    setCancellationCallError: function setCancellationCallError() {
      this.set('responseIconClass', 'icon icon-warn sso-error');
    },
    resetAfterSubmittingRequest: function resetAfterSubmittingRequest() {
      this.set('bulkCancellationResponseModalOpen', true);
      this.set('processingRequest', false);
      this.set('bulkCancellationRequestModalOpen', false);
      this.set('errors', {});
      this.set('hideServerErrors', true);
    },
    postCancellation: function postCancellation() {
      var _this2 = this;

      var url = _environment.default.api.host + '/api/v3/cancellation';
      var saleIDsArray = this.get('reports').filter(function (r) {
        return r.isSelected === true;
      }).map(function (r) {
        return r.saleID;
      });
      this.set('processingRequest', true);
      var reasonCodeStr = this.get('reasonForCancellation');
      if (reasonCodeStr == undefined) {
        reasonCodeStr = '';
      }
      var reason = this.get('cancelReasonText');

      if (reasonCodeStr === Array.from(_cancellationOption.SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL.values())[0]) {
        reasonCodeStr = 'OTHER';
        reason = '';
      }
      var postPayLoad = {
        saleIDs: saleIDsArray,
        reasonCode: reasonCodeStr,
        reason: reason
      };

      this.get('ajaxWrapper').ajaxPut(url, postPayLoad).then(function (response) {
        _this2.set('bulkCancellationRequestModalOpen', false);
        _this2.set('bulkCancellationConfirmationFlag', false);
        _this2.set('bulkCancellationResponseModalOpen', true);
        _this2.send('searchBooking');
        _this2.set('isAllRowSelectedForCancellation', false);
        _this2.set('selectedForCancellationCount', 0);
        _this2.set('hideServerErrors', true);
        _this2.get('responseErrors').clearErrors();
        _this2.setCancellationResponseSuccessMessage();
        _this2.set('processingRequest', false);
      }).catch(function (error) {
        Ember.Logger.error('Error While processing cancellation', error);
        _this2.set('bulkCancellationRequestModalOpen', false);
        _this2.set('bulkCancellationConfirmationFlag', false);
        _this2.set('bulkCancellationResponseModalOpen', true);
        _this2.setCancellationResponseErrorMessage();
        _this2.set('processingRequest', false);
        if (error.responseText) {
          var errors = (0, _addJsonApiErrorsToPayload.default)(Ember.$.parseJSON(error.responseText), error.status);
          _this2.set('errors', errors);
          _this2.set('hideServerErrors', false);
        } else {
          _this2.set('hideServerErrors', false);
          _this2.set('errors', error);
        }
      });
    }
  });
});