define('lxso/helpers/first-existing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.firstExisting = firstExisting;
  function firstExisting(params) {
    if (params[0] === 0 || params[0] === 1) {
      params[0] += '';
    }
    if (params[1] === 0 || params[1] === 1) {
      params[1] += '';
    }
    return params[0] ? params[0] : params[1];
  }

  exports.default = Ember.Helper.helper(firstExisting);
});