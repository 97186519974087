define('lxso/utils/booking-questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getQuestionLabelForQuestionId;
  var bookingQuestionIdLabelMap = {
    'travelerName': 'Name',
    'travelerWeight': 'Weight',
    'travelerDob': 'DOB',
    'passportNumber': 'PassportNumber',
    'passportIssueCountry': 'PassportNationality',
    'passportExpiry': 'PassportExpirationDate',
    'travelerHeight': 'Height'
  };
  function getQuestionLabelForQuestionId(questionId) {
    return bookingQuestionIdLabelMap[questionId];
  }
});