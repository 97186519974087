define('lxso/utils/change-history-details-display-modes/price-with-currency', ['exports', 'lxso/utils/change-history-details-display-modes/generic'], function (exports, _generic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'delegator',
    diff: function diff(oldValue, newValue, _ref) {
      var session = _ref.session;

      var currency = session.get('data.authenticated.selectedBranch.currency_type');

      function format(price) {
        if (price !== undefined) {
          return price + ' ' + currency;
        }
      }

      return {
        displayMode: _generic.default,
        oldValue: format(oldValue),
        newValue: format(newValue)
      };
    }
  };
});