define('lxso/helpers/has-property', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasProperty = hasProperty;
  function hasProperty(params /*, hash*/) {
    return params[0] != undefined && params[0].hasOwnProperty(params[1]);
  }

  exports.default = Ember.Helper.helper(hasProperty);
});