define('lxso/components/uitk-typeahead/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();

      this.set('filterField', this.get('source-filter-field'));
      this.set('highlightedIndex', -1);
      this.set('selectedItem', {});
    },


    dataChanged: function () {
      this.setDataSource();
    }.observes('data'),

    setDataSource: function setDataSource() {
      var route = this;
      var dat = route.get('data');

      // determine if the data passed in is static or a function and set the source accordingly
      if (typeof dat === 'function') {
        route.set('source', []);
      } else {
        route.set('source', dat);
      }
    },


    externalErrorChanged: function () {
      if (_typeof(this.get('external-error')) === 'object' && this.get('external-error').length > 0) {
        this.set('invalid', true);
      } else if (!this.get('external-error')) {
        this.set('invalid', false);
      }
    }.observes('external-error'),

    attachEvents: function () {
      var _this = this;

      var typeaheadId = '#' + this.get('typeahead-id');
      var labelId = '#' + this.get('label-id');
      var typeAheadParentNode = $(labelId)[0].parentNode;
      // Clears Typeahead results and tooltip when the Tooltip loses focus
      Ember.$(typeaheadId).on('blur', function (event) {
        // TODO: Make the related target check jQuery selectors and check the nearest tooltip class
        // TODO: instead of A to make sure the <a> click happened inside a tooltip window
        if (typeAheadParentNode.contains(event.relatedTarget) && event.relatedTarget.id != 'a-typeahead-close') {
          $(typeaheadId).focus();
        } else {
          _this.set('items', []);
          if (_this.get('isMultiSelectEnabled')) {
            _this.set('inputText', '');
          }
        }
      });

      Ember.$(typeaheadId).on('paste', function (event) {
        _this.send('filter', event.originalEvent.clipboardData.getData('text'), event);
      });

      this.setDataSource();
    }.on('didInsertElement'),

    willClearRender: function willClearRender() {
      this._super.apply(this, arguments);
      Ember.$('#' + this.get('typeahead-id')).off('blur paste');
    },
    filterInput: function filterInput(input, evt) {
      var keyCode = (typeof evt === 'undefined' ? 'undefined' : _typeof(evt)) === 'object' ? evt.which : -1;

      if (keyCode === 13 || keyCode === 27 || keyCode === 38 || keyCode === 39 || keyCode === 40) {
        this.send('highlightResult', input, evt);
      } else {
        this.set('highlightedIndex', -1);

        // Sets the typeahead-start variable to either the passed in value or 0 by default
        var typeaheadStart = this.get('typeahead-start');
        var startCharacter = typeaheadStart ? parseInt(typeaheadStart) : 0;
        var query = input;
        var returnArray = [];
        var dat = this.get('data');
        var canCache = this.get('can-cache');
        var willCache = typeof canCache === 'boolean' ? canCache : true;
        var typeahead = this;
        typeahead.set('selectedItem', {});

        // Doesn't let this function run on undefined
        var isMultiSelectEnabled = typeahead.get('isMultiSelectEnabled');

        if (typeof query === 'undefined' || query === null) {
          query = '';
        } else {
          query = query.trim();
        }

        var trimmedQueryLength = query.trim() ? query.trim().length : undefined;

        var filterData = function filterData() {
          var source = typeahead.get('source');
          var queryReg = new RegExp("[A-Za-z!@#$%^&';:/?\\-_,~`<>. ]*");
          var regQuery = queryReg.exec(query)[0];
          var reg = new RegExp(regQuery.split('').join('\\w*').replace(/\W/, ''), 'i');

          var filterField = typeahead.get('source-filter-field');
          var filteredArray = [];
          // Builds a new array if there's a filter field
          if (filterField) {
            if (source) {
              Ember.$.each(source, function (index, item) {
                filteredArray.push({ name: item[filterField], original: item });
              });
            }
          } else {
            Ember.$.each(source, function (index, item) {
              filteredArray.push({ name: item });
            });
          }

          // Don't show anything if there is nothing in the input
          if (trimmedQueryLength === 0 || trimmedQueryLength < startCharacter) {
            typeahead.set('items', []);
          } else {
            // Keeps the filter from firing until the minimum start character has been reached
            if (trimmedQueryLength >= startCharacter || !trimmedQueryLength && isMultiSelectEnabled) {
              if (filterField) {
                filteredArray = typeahead.sortData(query, filteredArray);
                returnArray = filteredArray.filter(function (item) {
                  if (item.name.match(reg)) {
                    return item;
                  }
                  return false;
                });
              } else {
                source = typeahead.sortData(query, source);
                returnArray = source.filter(function (item) {
                  if (item.name.match(reg)) {
                    return item;
                  }
                  return false;
                });
              }

              var isExactMatch = returnArray && returnArray.length === 1 && input.trim().toLowerCase() === returnArray[0].name.trim().toLowerCase();
              if (isExactMatch) {
                typeahead.send('setInputValue', returnArray[0]);
              } else {
                // Returns the correct number of items based on the limit passed in, if there was one
                if (typeahead.get('limit')) {
                  var limit = parseInt(typeahead.get('limit'));
                  typeahead.set('items', returnArray.slice(0, limit));
                } else {
                  typeahead.set('items', returnArray.slice(0, 20));
                }
              }
            }
          }
        };

        if (willCache) {
          if ((startCharacter === 0 || trimmedQueryLength >= startCharacter) && typeof dat === 'function') {
            if (trimmedQueryLength === startCharacter) {
              dat(query).then(function (resp) {
                Array.isArray(resp.data) ? typeahead.set('source', resp.data) : typeahead.set('source', resp.data.data);
                if (resp.hasOwnProperty('count')) {
                  typeahead.set('count', resp.count);
                }
                filterData();
              });
            } else if (trimmedQueryLength > startCharacter) {
              filterData();
            }
          }
        } else if (typeof dat === 'function') {
          if (trimmedQueryLength >= startCharacter) {
            dat(query).then(function (resp) {
              if ((typeof resp === 'undefined' ? 'undefined' : _typeof(resp)) === 'object') {
                Array.isArray(resp.data) ? typeahead.set('source', resp.data) : typeahead.set('source', resp.data.data);
                if (resp.hasOwnProperty('count')) {
                  typeahead.set('count', resp.count);
                }

                filterData();
              }
            });
          }
        } else {
          filterData();
        }

        if ((typeof dat === 'undefined' ? 'undefined' : _typeof(dat)) === 'object') {
          filterData();
        }

        if (typeof dat === 'function' && trimmedQueryLength < startCharacter) {
          typeahead.set('source', []);
          typeahead.set('items', []);
        }

        // If nothing is in the input, send the action with no item
        if (trimmedQueryLength === 0) {
          this.sendAction('action');
        }
      }
    },


    actions: {
      preventSubmit: function preventSubmit(input, evt) {
        if (evt.which === 13) {
          evt.preventDefault();
        }
      },
      focusInput: function focusInput() {
        var isMultiSelectEnabled = this.get('isMultiSelectEnabled');
        var items = this.get('items');
        if (isMultiSelectEnabled && (!items || !items.length)) {
          var _Ember$run;

          (_Ember$run = Ember.run).debounce.apply(_Ember$run, [this, this.filterInput].concat(Array.prototype.slice.call(arguments), [100]));
        }
      },
      filter: function filter() {
        var _Ember$run2;

        // Debounce the filter function to avoid too many ajax calls
        (_Ember$run2 = Ember.run).debounce.apply(_Ember$run2, [this, this.filterInput].concat(Array.prototype.slice.call(arguments), [300]));
        //set a timeout to populate after 300 miliseconds
        if (arguments[0] === '') {
          this.sendAction('action', { display: '', value: '' });
        }
      },
      emptyItems: function emptyItems() {
        this.set('items', []);
      },
      checkBoxClick: function checkBoxClick(event) {
        event.preventDefault();
        return false;
      },
      selectAll: function selectAll(items) {
        items.forEach(function (item, index) {
          item.original.set('isSelected', true);
        });
        this.sendAction('selectOrDeselectAllAction', true, items);
      },
      deSelectAll: function deSelectAll(items) {
        items.forEach(function (item, index) {
          item.original.set('isSelected', false);
        });
        this.sendAction('selectOrDeselectAllAction', false, items);
      },
      setInputValue: function setInputValue(item, items) {
        var isMultiSelectEnabled = this.get('isMultiSelectEnabled');
        var isAutoClearInput = this.get('auto-clear-input');
        if (isAutoClearInput) {
          this.set('inputText', '');
        } else {
          if (!isMultiSelectEnabled) {
            this.set('inputText', item.name);
          }
        }
        if (isMultiSelectEnabled) {
          this.sendAction('action', item.original);
        } else {
          this.set('selectedItem', item);
          this.set('invalid', false);
          if (_typeof(item.original) === 'object') {
            this.sendAction('action', item.original);
          } else {
            this.sendAction('action', item);
          }
          this.set('items', []);
        }
      },
      highlightResult: function highlightResult(input, evt) {
        var keyCode = evt.which;
        var index = this.get('highlightedIndex');
        var items = this.get('items');

        switch (keyCode) {
          case 13:
            {
              evt.preventDefault();
              if (index > -1) {
                this.send('setInputValue', items[index]);
              }
              break;
            } // enter
          case 27:
            {
              this.send('emptyItems');
              break;
            } // ESC
          case 38:
            {
              if (index >= 0) {
                index--;
                this.set('highlightedIndex', index);
              }
              break;
            } // up
          case 39:
            {
              if (index > -1) {
                this.send('setInputValue', items[index]);
              }
              break;
            } // right
          case 40:
            {
              if (index < items.length) {
                index++;
              } else {
                index = -1;
              }
              this.set('highlightedIndex', index);
              break;
            } // down
        }
      },
      clearStyle: function clearStyle() {
        this.set('highlightedIndex', -1);
      }
    },
    sortData: function sortData(query, array) {
      var builder = [];
      var input = query;
      var data = array;
      var len = input.length;

      var ret = distanceSort(input, data, builder, len);

      function distanceSort(input, data, builder, n) {
        var maker = builder;
        if ((typeof maker === 'undefined' ? 'undefined' : _typeof(maker)) !== 'object') {
          maker = [];
        }

        // base case to end recursion
        if (n === 0) {
          // put the rest of the array into the builder if it's not empty by now
          if (data.length !== 0) {
            data.sort(function (obj1, obj2) {
              if (obj1.name < obj2.name) {
                return -1;
              }
              if (obj1.name > obj2.name) {
                return 1;
              }
              return 0;
            });

            Ember.$.each(data, function (index, item) {
              maker.push(item);
            });
          }
          return maker;
        }

        // only sort if something has been typed
        if (typeof input === 'string' && input.length !== 0) {
          var matchArray = [];

          // starts with trying to match the whole input and adds it to the builder
          // if found until base case
          //Ember.$.each(data, function(index, item){
          for (var index = 0; index < data.length; index++) {
            var item = data[index];
            if ((typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object' && item.name.substring(0, n).toLowerCase() === input.substring(0, n).toLowerCase()) {
              matchArray.push(item);

              // taking this out of the data pool after using it
              data.splice(index, 1);
              index--;
            }
          }

          // sorts each temp array before pushing into the builder
          matchArray.sort(function (obj1, obj2) {
            if (obj1.name < obj2.name) {
              return -1;
            }
            if (obj1.name > obj2.name) {
              return 1;
            }
            return 0;
          });

          maker = maker.concat(matchArray);

          // the 90's called and they want their recursion back
          return distanceSort(input, data, maker, n - 1);
        } else {
          return data;
        }
      }

      return ret;
    },


    itemsNotEmpty: Ember.computed('items', {
      get: function get() {
        var items = this.get('items');
        if (items) {
          return items.length;
        } else {
          return false;
        }
      }
    })
  });
});