define('lxso/services/date-format', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    API_DATE_FORMAT: 'YYYY-MM-DD',
    URL_DATE_FORMAT: 'YYYY-MM-DD',
    DATEPICKER_FORMAT: 'MM/DD/YYYY',
    USA_DATE_FORMAT: 'MMM DD, YYYY',
    SHORT_USA_DATE_FORMAT: 'MMM D, YYYY',
    LOCALIZED_DATE_FORMAT: 'L',
    LONG_USA_FORMAT: 'dddd, MMM DD YYYY', // Tuesday, Jan 26 2016
    LONG_USA_FORMAT_WITH_TIME: 'ddd, MMM DD YYYY HH:mm', // Tue, Jan 26 2016 18:02
    LONG_USA_FORMAT_WITH_TIME_AND_TZ: 'ddd, MMM DD YYYY HH:mm (UTC Z)', // Tue, Jan 26 2016 18:02 (UTC +01:00),

    asLongDateWithTimezone: function asLongDateWithTimezone(date) {
      return date.format(this.get('LONG_USA_FORMAT_WITH_TIME_AND_TZ'));
    }
  });
});