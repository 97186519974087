define('lxso/utils/change-history-details-display-modes/sentence-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'sentence-with-label',
    diff: function diff(oldValue, newValue) {
      'use strict';

      var delimiter = '||.';
      var transformedOldValue = '' + oldValue + delimiter;
      var transformedNewValue = '' + newValue + delimiter;
      return JsDiff.diffSentences(transformedOldValue, transformedNewValue).map(function (item) {
        return { value: item.value.replace(delimiter, ''), added: item.added, removed: item.removed };
      });
    }
  };
});