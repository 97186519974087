define('lxso/services/v4-logistics-service', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajaxWrapper: Ember.inject.service(),

    updateRedemption: function updateRedemption(activityId, body) {
      var url = _environment.default.api.hostV4 + '/logistics/' + activityId + '/redemption';
      return this.get('ajaxWrapper').ajaxPut(url, body);
    },
    updateActivityAddress: function updateActivityAddress(activityId, requestBody) {
      var url = _environment.default.api.hostV4 + '/logistics/' + activityId + '/location';
      var v4RequestBody = convertLocationToV4Model(requestBody);
      return this.get('ajaxWrapper').ajaxPut(url, v4RequestBody);
    },
    updateRedemptionAddresses: function updateRedemptionAddresses(activityId, requestBody) {
      var url = _environment.default.api.hostV4 + '/logistics/' + activityId + '/locations/redemption';
      var v4RequestBody = {
        addresses: requestBody.addresses.map(function (v3Location) {
          return convertLocationToV4Model(v3Location);
        })
      };
      return this.get('ajaxWrapper').ajaxPut(url, v4RequestBody);
    },
    updateTermsAndConditions: function updateTermsAndConditions(activityId, requestBody) {
      var body = {
        liabilityWaiver: requestBody.liability_waiver,
        ageRestriction: requestBody.age_restriction,
        optionalContent: requestBody.optional_content,
        updateActivityContent: requestBody.update_activity_content
      };
      var url = _environment.default.api.hostV4 + '/logistics/' + activityId + '/terms-and-condition';
      return this.get('ajaxWrapper').ajaxPut(url, body);
    }
  });


  function convertLocationToV4Model(v3Location) {
    var v4Location = Object.assign({}, v3Location, {
      addressName: v3Location.address_name,
      streetSecondLine: v3Location.street_second_line,
      postalCode: v3Location.postal_code,
      phoneNumber: v3Location.phone_number,
      formattedAddress: v3Location.formatted_address
    });
    delete v4Location.address_name;
    delete v4Location.street_second_line;
    delete v4Location.postal_code;
    delete v4Location.phone_number;
    delete v4Location.formatted_address;
    return v4Location;
  }
});