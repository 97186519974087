define('lxso/components/supplier-branch-settings/component', ['exports', 'lxso/utils/form-validator', 'lxso/utils/auto-renew-rate'], function (exports, _formValidator, _autoRenewRate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var autoRenewRateTranslations = new Map([[true, 'Enabled'], [false, 'Disabled']]);
  exports.default = Ember.Component.extend({

    staticData: Ember.inject.service(),
    featureFlags: Ember.inject.service(),

    timeZone: Ember.computed('supplierBranch.default_timezone', function () {
      var default_timezone = this.get('supplierBranch.default_timezone');
      if (default_timezone === 'UTC' || default_timezone.includes('00:00')) {
        default_timezone = 'UTC±00:00';
      }
      var filtered = this.get('staticData').getTimeZones().filter(function (item) {
        return item.tz === default_timezone;
      });
      return filtered[0].tz + ' ' + filtered[0].label;
    }),

    timeZoneDropdownOptions: Ember.computed(function () {
      return this.get('staticData').getTimeZones().map(function (item) {
        return item.tz + ' ' + item.label;
      });
    }),

    timeZoneDropdownValues: Ember.computed(function () {
      return this.get('staticData').getTimeZones().map(function (item) {
        return item.tz;
      });
    }),

    isSuspendedPausedAndExpediaFlagEnabled: Ember.computed('featureFlags', function () {
      return this.get('featureFlags').checkEnabled('SuspendedPausedAndExpedia');
    }),

    statusOptions: Ember.computed('isSuspendedPausedAndExpediaFlagEnabled', function () {
      return this.get('isSuspendedPausedAndExpediaFlagEnabled') ? Ember.A(['Active', 'Inactive', 'Suspended - Expedia', 'Suspended - Paused']) : Ember.A(['Active', 'Inactive']);
    }),

    statusValues: Ember.computed('isSuspendedPausedAndExpediaFlagEnabled', function () {
      return this.get('isSuspendedPausedAndExpediaFlagEnabled') ? Ember.A(['Active', 'Inactive', 'SuspendedExpedia', 'SuspendedPaused']) : Ember.A(['Active', 'Inactive']);
    }),

    validateSupplierBranchSettings: function validateSupplierBranchSettings() {
      return (0, _formValidator.validate)({
        model: this.get('supplierBranch'),
        attributes: ['status', 'affiliate_id', 'salesforce_code', 'supplier_branch_type']
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('isActivityType', this.get('supplierBranch.supplier_branch_type') === 'Activity');
      (0, _autoRenewRate.setupAutoRenewRateDropdown)(this);
    },


    actions: {
      validateIfInvalid: function validateIfInvalid() {
        var _this = this;

        if (this.get('errors')) {
          this.validateSupplierBranchSettings().then(function () {
            _this.set('errors', []);
          }).catch(function (errors) {
            _this.set('errors', errors);
          });
        }
      },
      setSupplierBranchType: function setSupplierBranchType(branchType) {
        this.set('supplierBranchType', branchType);
        this.sendAction('supplierBranchTypeSelected', branchType);
      },
      retryVendorBranchId: function retryVendorBranchId(supplierDetails) {
        this.set('showSupplierBranchSettingsNotification', true);
        this.sendAction('retryVendorBranchId', supplierDetails);
      },
      supplierBranchStatusSelected: function supplierBranchStatusSelected(status) {
        this.sendAction('supplierBranchStatusSelected', status);
        this.send('validateIfInvalid');
      },
      supplierBranchAutoRenewRateSelected: function supplierBranchAutoRenewRateSelected(isAutoRenewRateEnabled) {
        var booleanAutoRenewRate = isAutoRenewRateEnabled == 'true';
        this.sendAction('supplierBranchAutoRenewRateSelected', booleanAutoRenewRate);
        this.send('validateIfInvalid');
      },
      editSupplierBranchSettings: function editSupplierBranchSettings() {
        if (!this.get('statusValues').includes(this.get('supplierBranch.status'))) {
          this.set('supplierBranch.status', '');
        }
        this.sendAction('editSupplierBranchSettings');
      },
      cancelEditSupplierBranchSettings: function cancelEditSupplierBranchSettings() {
        this.set('errors', []);
        this.sendAction('cancelEditSupplierBranchSettings');
      },
      saveSupplierBranchSettings: function saveSupplierBranchSettings() {
        var _this2 = this;

        this.validateSupplierBranchSettings().then(function () {
          _this2.set('errors', []);
          _this2.sendAction('saveSupplierBranchSettings');
        }).catch(function (errors) {
          _this2.set('errors', errors);
        });
      },
      timeZoneSelected: function timeZoneSelected(timeZone) {
        this.sendAction('timeZoneSelected', timeZone);
      }
    }
  });
});