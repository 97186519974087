define('lxso/routes/cancellation', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/utils/add-json-api-errors-to-payload', 'lxso/mixins/feature-flag', 'lxso/mixins/date-range', 'lxso/mixins/require-permission', 'lxso/config/environment', 'lxso/constants/cancellation-option'], function (exports, _authenticatedRouteMixin, _addJsonApiErrorsToPayload, _featureFlag, _dateRange, _requirePermission, _environment, _cancellationOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var SELECT_SEARCH_TYPES_MAP = new Map([['Date range', 'date-range-div'], ['Itinerary number', 'supplier-reference-number-div']]);
  var firstValidDateBeforeMonth = moment().add(-1, 'months');

  var maxDateRangeLength = moment.duration(18, 'months');

  var today = moment();

  var DEFAULT_SELECT_SEARCH_TYPE = SELECT_SEARCH_TYPES_MAP.values().next().value;

  exports.default = Ember.Route.extend(_requirePermission.default, _authenticatedRouteMixin.default, _dateRange.default, {
    omniture: { pageName: 'page.LX-Supply.Cancellations' },
    uitk: Ember.inject.service(),
    requiredPermission: 'V3Product',
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    dateFormat: Ember.inject.service(),
    responseErrors: Ember.inject.service(),
    maxDateRange: moment.duration(92, 'days'),
    threepSupplier: Ember.inject.service(),
    featureFlags: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        //Selected search
        // selectedSearchType: {refreshModel: false},

        // Search by supplierReferenceNumber
        supplierReferenceNumber: { refreshModel: false },

        // Search by dateRange
        from: { refreshModel: false },
        to: { refreshModel: false },

        // Sort
        sortBy: { refreshModel: false },
        order: { refreshModel: false },

        // Filters
        status: { refreshModel: false }
      };
      this.set('queryParams', queryParams);
      this.set('cancelReasonText', '');
    },
    model: function model(params) {
      var _this = this;

      var selectedSupplierBranch = this.get('session.session.authenticated.selectedBranch');
      var branchesHavingUserAccess = void 0;
      var supplierBranches = void 0;
      var activities = void 0;
      if (selectedSupplierBranch.thirdPartySupply) {
        var SelectedBranchId = selectedSupplierBranch.supplier_branch_id;
        branchesHavingUserAccess = { supplier_branch_id_list: [SelectedBranchId] };
        supplierBranches = [selectedSupplierBranch];
        var activityPath = branchesHavingUserAccess.supplier_branch_id_list.map(function (k) {
          return _environment.default.api.host + '/' + _environment.default.api.namespace + '/supplier_branches/' + k + '/activities';
        });
        activities = Ember.RSVP.all(activityPath.map(function (ac) {
          return _this.get('ajaxWrapper').ajaxGet(ac);
        }));
      } else {
        var branchesHavingAccessURL = _environment.default.api.host + '/' + _environment.default.api.namespace + '/userManagement/supplier_branch_ids';
        branchesHavingUserAccess = this.get('ajaxWrapper').ajaxGet(branchesHavingAccessURL, null, {
          cache: false,
          xhrFields: {
            withCredentials: true
          }
        }).then(function (res) {
          return res;
        }).catch(function (err) {
          if (err.status === 300) {
            return { supplier_branch_id_list: [-1] };
          }
          return err;
        });
        supplierBranches = this.store.findRecord('suppliers', params.supplierId, { reload: true });
        activities = supplierBranches.then(function (sb) {
          var sbIds = sb.get('branches').filter(function (b) {
            return b.status == 'Active';
          }).map(function (k) {
            return k.supplier_branch_id;
          });
          var activityPath = sbIds.map(function (k) {
            return _environment.default.api.host + '/' + _environment.default.api.namespace + '/supplier_branches/' + k + '/activities';
          });
          return Ember.RSVP.all(activityPath.map(function (ac) {
            return _this.get('ajaxWrapper').ajaxGet(ac);
          }));
        });
      }
      return new Ember.RSVP.hash({
        supplierBranches: supplierBranches,
        params: params,
        branchesHavingUserAccess: branchesHavingUserAccess,
        activities: activities });
    },
    setSearchType: function setSearchType() {
      var selectedValue = this.controller.get('model.selectedSearchType');
      if (!selectedValue) {
        selectedValue = this.getOrSetDefault('selectSearchByTypes.selected', DEFAULT_SELECT_SEARCH_TYPE);
      }
      this.send('selectSearchBy', selectedValue);
    },
    findReports: function findReports(params) {
      var self = this;
      self.controller.set('reports', []);
      self.controller.set('isLoading', true);
      self.controller.set('hideServerErrors', true);
      self.controller.set('errors', undefined);
      var getReportsUrl = _environment.default.api.host + '/api/v3/cancellation?' + params;
      self.get('ajaxWrapper').ajaxGet(getReportsUrl).then(function (res) {
        var response = res.map(function (r) {
          return Object.assign(r, { isSelected: false, isDisabled: true });
        });
        self.controller.set('isAllRowsDisabled', false);
        var dateFormat = 'MM-DD-YYYY';
        response.forEach(function (r) {
          r.travelDate = moment(r.travelDate).format(dateFormat);
          if (r.saleStatus === 'cancelled') {
            r.saleStatus = 'Cancelled on ' + moment(r.statusDate).format('MM/DD/YYYY');
          } else if (r.saleStatus === 'Pending cancellation') {
            r.saleStatus = 'Pending cancellation ' + moment(r.cancelDate).format('MM/DD/YYYY');
          } else if (r.saleStatus === 'complete') {
            r.saleStatus = 'Active';
            r.isDisabled = false;
          }
        });
        //Check if any rows valid for cancellation
        if (!response.filter(function (item) {
          return !item.isDisabled;
        }).length > 0) {
          self.controller.set('isAllRowsDisabled', true);
        }
        if (self.controller.get('selectSearchForTypes') === 'Active') {
          var activeBookings = response.filter(function (r) {
            return r.saleStatus === 'Active' || r.saleStatus === 'Credit applied';
          });
          self.controller.set('reports', activeBookings);
          self.controller.set('totalResultsCount', activeBookings.length);
        } else {
          self.controller.set('reports', response);
          self.controller.set('totalResultsCount', response.length);
        }
      }).catch(function (err) {
        return err;
      }).finally(function () {
        self.controller.set('isLoading', false);
        self.controller.set('showChangeSearchButton', true);
      });
    },
    getOrSetDefault: function getOrSetDefault(name, defaultValue) {
      var controllerValue = this.get(name);
      return controllerValue ? controllerValue : this.controller.set(name, defaultValue);
    },
    calculateSelectedBookingValue: function calculateSelectedBookingValue() {
      var bookingValue = this.controller.get('reports').filter(function (r) {
        return r.isSelected;
      }).reduce(function (r1, r2) {
        return r1 + parseFloat(r2.retailPrice);
      }, 0);
      this.controller.set('selectedBookingValue', bookingValue.toFixed(2));
    },
    triggerTransitionTo: function triggerTransitionTo(updatedParams) {
      var queryParams = Ember.$.extend(this.get('model'), updatedParams);
      this.transitionTo({ queryParams: queryParams });
    },
    setupController: function setupController(controller, model) {
      controller.set('route', this);
      this._super(controller, model.params);
      var is3PSupplier = this.get('threepSupplier').isSupplier3P();
      controller.set('supplierBranchId', this.get('session.data.authenticated.selectedBranch.supplier_branch_id'));
      controller.set('is3PSupplier', is3PSupplier);

      var branchesHavingUserAccess = model.branchesHavingUserAccess && model.branchesHavingUserAccess.supplier_branch_id_list ? model.branchesHavingUserAccess.supplier_branch_id_list : [];
      var supplierBranches = void 0;
      var activities = void 0;
      if (is3PSupplier) {
        supplierBranches = model.supplierBranches;
        var sbIds = supplierBranches.map(function (k) {
          return k.supplier_branch_id;
        });
        var sbActMap = {};
        sbIds.map(function (id, index) {
          return sbActMap[id] = model.activities[index];
        });
        activities = supplierBranches.map(function (sb) {
          return sbActMap[sb.supplier_branch_id];
        }).flatten();
      } else {
        var supplierBranchesForSupplier = model.supplierBranches.get('branches');

        supplierBranches = supplierBranchesForSupplier.filter(function (item) {
          /*Meaning super user having access to all branches*/
          if (branchesHavingUserAccess.indexOf(-1) >= 0) {
            return item.is_active;
          } else {
            var branchesList = branchesHavingUserAccess && branchesHavingUserAccess.length ? branchesHavingUserAccess : [];
            return item.is_active && branchesList.indexOf(item.supplier_branch_id) >= 0;
          }
        }).sortBy('supplier_branch_name');
        var _sbIds = supplierBranchesForSupplier.filter(function (b) {
          return b.status == 'Active';
        }).map(function (k) {
          return k.supplier_branch_id;
        });
        var _sbActMap = {};
        _sbIds.map(function (id, index) {
          return _sbActMap[id] = model.activities[index];
        });
        activities = supplierBranches.map(function (sb) {
          return _sbActMap[sb.supplier_branch_id];
        }).flatten();
      }

      controller.set('selectSearchByTypes', {
        selected: DEFAULT_SELECT_SEARCH_TYPE,
        options: [],
        values: []
      });

      SELECT_SEARCH_TYPES_MAP.forEach(function (value, key) {
        controller.get('selectSearchByTypes.options').push(key);
        controller.get('selectSearchByTypes.values').push(value);
      });
      controller.set('reasonDropDown', {
        options: Array.from(_cancellationOption.SELECT_REASON_ARRAY.keys()),
        values: Array.from(_cancellationOption.SELECT_REASON_ARRAY.values()),
        default: Array.from(_cancellationOption.SELECT_REASON_ARRAY.values())[0]
      });

      if (this.get('featureFlags').checkEnabled('PastDateCancellation')) {
        controller.set('minValidDate', firstValidDateBeforeMonth);
      }
      controller.set('dateRangeFrom', '');
      controller.set('dateRangeTo', '');
      controller.set('isAllRowSelectedForCancellation', false);
      controller.set('selectedForCancellationCount', 0);
      controller.set('showCancellationModal', false);
      controller.set('bulkCancellationConfirmationFlag', false);
      controller.set('selectReasonFlag', false);
      controller.set('buttonHideFlag', true);
      controller.set('selectSearchForTypes', 'Active');
      controller.set('showSupplierReferenceSearch', false);
      controller.set('selectedBookingValue', 0);
      controller.set('showCancellationResponseModal', false);
      controller.set('searchParametersSelected', false);
      controller.set('showChangeSearchButton', false);
      controller.set('reports', []);
      controller.set('supplierReferenceNumber', '');
      this.controller.set('showReasonText', false);
      this.controller.set('hideServerErrors', true);
      this.controller.set('isLoading', false);

      this.controller.set('isSearchAllBookings', false);
      this.controller.set('supplierBranchNames', supplierBranches.map(function (branch) {
        return branch.supplier_branch_name;
      }));
      this.controller.set('supplierBranchValues', supplierBranches.map(function (branch) {
        return branch.supplier_branch_id;
      }));

      this.controller.set('branchAndProductGroups', this.getBranchAndProductGroups(supplierBranches, activities, is3PSupplier));

      controller.setProperties({
        cancellationReportTableHeaderBasekey: 'pages.cancellation.labels.',
        cancellationReportTableHeaderKeys: Ember.A(['productTitle', 'optionTitle', 'itineraryNumber', 'saleID', 'saleStatus', 'travelDate', 'retailPrice']),
        sortableHeaderKeys: Ember.A(['travelDate']),
        sortParam: 'travelDate',
        itemsPerPage: 10,
        cancellationReportTableKeys: Ember.A(['productTitle', 'optionTitle', 'itineraryNumber', 'saleID', 'saleStatus', 'travelDate', 'retailPrice']),
        selectedEntityId: 'allBranches',
        selectedEntityType: 'Branches',
        sortedReports: Ember.computed('reports', 'sortParam', 'sortAscending', function () {
          var sortedReports = [];
          sortedReports = this.get('reports').sort(function (a, b) {
            if (moment(a.travelDate) < moment(b.travelDate)) {
              return -1;
            } else if (moment(a.travelDate) > moment(b.travelDate)) {
              return 1;
            } else {
              return 0;
            }
          });

          if (this.get('sortAscending')) {
            return sortedReports;
          } else {
            return sortedReports.reverse();
          }
        }),
        searchParametersSelected: Ember.computed('supplierReferenceNumber', 'dateRangeFrom', 'dateRangeTo', 'selectSearchByTypes.selected', function () {
          if (this.get('selectSearchByTypes.selected') === 'supplier-reference-number-div') {
            return !!this.get('supplierReferenceNumber');
          } else {
            return !!this.get('dateRangeFrom') && !!this.get('dateRangeTo');
          }
        })
      });
    },
    getBranchAndProductGroups: function getBranchAndProductGroups(branches, activities) {
      var branchItems = [{
        option: 'All Branches',
        value: 'allBranches'
      }];
      branchItems.push.apply(branchItems, _toConsumableArray(branches.map(function (branch) {
        return {
          option: branch.supplier_branch_name,
          value: branch.supplier_branch_id
        };
      })));

      var activityItems = [{
        option: 'All Products',
        value: 'allProducts'
      }];
      activityItems.push.apply(activityItems, _toConsumableArray(activities.filter(function (act) {
        return !!act;
      }).map(function (activity) {
        return {
          option: activity.long_title,
          value: activity.activity_id
        };
      })));

      if (this.controller.get('is3PSupplier')) {
        return Ember.A([{
          groupName: 'Products',
          items: activityItems
        }]);
      } else {
        return Ember.A([{
          groupName: 'Branches',
          items: branchItems
        }, {
          groupName: 'Products',
          items: activityItems
        }]);
      }
    },


    actions: {
      searchFor: function searchFor(search) {
        this.clearInputAndErrors();
        this.controller.set('isSearchAllBookings', search === 'allBookings');
      },
      triggerDateRangePicker: function triggerDateRangePicker() {
        var collapsed = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        this.set('isDateRangePickerCollapsed', collapsed);
        this.notifyPropertyChange('isDateRangePickerCollapsed');
      },
      setSupplierReferenceNumber: function setSupplierReferenceNumber(e) {
        this.controller.set('supplierReferenceNumber', e.target.value);
      },
      searchOnDatetype: function searchOnDatetype(event) {
        var searchDateType = event.target.value.trim();
        this.triggerTransitionTo({ searchDateType: searchDateType });
      },
      selectSearchBy: function selectSearchBy(selectedValue) {
        if (selectedValue === 'date-range-div') {
          this.controller.set('supplierReferenceNumber', '');
        } else {
          this.controller.set('dateRangeFrom', '');
          this.controller.set('dateRangeTo', '');
        }
        this.controller.set('selectSearchByTypes.selected', selectedValue);
        this.controller.set('model.selectedSearchType', selectedValue);
        this.controller.toggleProperty('showSupplierReferenceSearch');
        this.triggerTransitionTo({ selectedSearchType: selectedValue });
      },
      selectBranchOrProduct: function selectBranchOrProduct(value, group) {
        this.controller.set('selectedEntityId', value);
        this.controller.set('selectedEntityType', group);
      },
      selectSearchFor: function selectSearchFor(selectedValue) {
        this.controller.set('selectSearchForTypes', selectedValue);
      },
      filterOnBranch: function filterOnBranch(selected) {
        this.controller.set('supplierBranch', selected);
      },
      updateFromDate: function updateFromDate(date) {
        this.controller.set('dateRangeFrom', date);
        this.controller.set('formattedFromDate', moment(date).format('MM/DD/YYYY'));
        var toDate = this.controller.get('dateRangeTo');
        if (toDate === '' || moment(date).isAfter(moment(toDate))) {
          this.controller.set('dateRangeTo', date);
          this.controller.set('formattedToDate', moment(date).format('MM/DD/YYYY'));
        }
      },
      updateToDate: function updateToDate(date) {
        this.controller.set('dateRangeTo', date);
        this.controller.set('formattedToDate', moment(date).format('MM/DD/YYYY'));
        if (this.controller.get('dateRangeFrom') === '') {
          this.controller.set('dateRangeFrom', date);
          this.controller.set('formattedFromDate', moment(date).format('MM/DD/YYYY'));
        }
      },
      selectDeselectRow: function selectDeselectRow(saleRowIndex, saleRow) {
        var rep = this.controller.get('reports');
        Ember.set(rep.objectAt(saleRowIndex), 'isSelected', !saleRow.isSelected);
        var count = rep.filter(function (r) {
          return r.isSelected;
        }).length;
        this.controller.set('selectedForCancellationCount', count);
        if (count === rep.length) {
          this.controller.set('isAllRowSelectedForCancellation', true);
        } else {
          this.controller.set('isAllRowSelectedForCancellation', false);
        }
        this.calculateSelectedBookingValue();
      },
      selectDeselectAllRows: function selectDeselectAllRows() {
        var rep = this.controller.get('reports');
        var selected = this.controller.toggleProperty('isAllRowSelectedForCancellation');
        rep.forEach(function (item) {
          if (!item.isDisabled) {
            Ember.set(item, 'isSelected', selected);
          }
        });
        if (selected) {
          this.controller.set('selectedForCancellationCount', rep.filter(function (item) {
            return item.isSelected;
          }).length);
        } else {
          this.controller.set('selectedForCancellationCount', 0);
        }
        this.calculateSelectedBookingValue();
      },
      onCancellationSelectAll: function onCancellationSelectAll() {
        this.controller.toggleProperty('saleRow.isSelected');
      },
      setSortParams: function setSortParams(sortParam, sortAscending) {
        this.controller.set('sortParam', sortParam);
        this.controller.set('sortAscending', sortAscending);
      },
      searchReports: function searchReports() {
        var model = this.controller.get('model');
        this.controller.set('isAllRowSelectedForCancellation', false);
        var dateFormat = this.get('dateFormat.URL_DATE_FORMAT');
        var params = 'supplierID=' + model.supplierId;
        if (this.controller.get('supplierReferenceNumber')) {
          params = params + '&supplierReferenceNumber=' + this.controller.get('supplierReferenceNumber');
        } else if (this.controller.get('dateRangeFrom') && this.controller.get('dateRangeTo')) {
          params = params + '&startDate=' + this.controller.get('dateRangeFrom').format(dateFormat) + '&endDate=' + this.controller.get('dateRangeTo').format(dateFormat);
        }
        if (!(this.controller.get('selectedEntityId') === 'allProducts' || this.controller.get('selectedEntityId') === 'allBranches')) {
          if (this.controller.get('selectedEntityType') === 'Branches') {
            params = params + '&supplierBranchID=' + this.controller.get('selectedEntityId');
          } else {
            params = params + '&activityId=' + this.controller.get('selectedEntityId');
          }
        }
        if (this.controller.get('is3PSupplier')) {
          params = params + '&supplierBranchID=' + this.controller.get('supplierBranchId');
        }
        this.findReports(params);
      },
      showConfirmationModal: function showConfirmationModal() {
        this.controller.set('showCancellationModal', true);
        this.controller.set('hideServerErrors', true);
        this.controller.set('errors', undefined);
      },
      changeSearchParams: function changeSearchParams() {
        this.controller.set('showChangeSearchButton', false);
      },
      hideConfirmationModal: function hideConfirmationModal() {
        this.controller.set('showCancellationModal', false);
        this.controller.set('bulkCancellationConfirmationFlag', false);
        this.controller.set('selectReasonFlag', false);
        this.controller.set('showReasonText', false);
        this.controller.set('cancelReasonText', '');
      },
      bulkCancellationConfirmation: function bulkCancellationConfirmation() {
        this.controller.toggleProperty('bulkCancellationConfirmationFlag');
      },
      hideModal: function hideModal() {
        this.controller.set('showCancellationResponseModal', false);
      },
      setReason: function setReason(value) {
        this.controller.set('reasonForCancellation', value);
        if (value !== Array.from(_cancellationOption.SELECT_REASON_ARRAY.values())[0]) {
          if (value === 'OTHER') {
            this.controller.toggleProperty('showReasonText');
            this.controller.set('selectReasonFlag', false);
          } else {
            this.controller.set('showReasonText', false);
            this.controller.set('selectReasonFlag', true);
            this.controller.set('cancelReasonText', '');
          }
        } else {
          this.controller.set('showReasonText', false);
          this.controller.set('selectReasonFlag', false);
          this.controller.set('cancelReasonText', '');
        }
      },
      setReasonText: function setReasonText(value) {
        this.controller.set('cancelReasonText', value.target.value);
        if (this.controller.get('cancelReasonText').length > 0) {
          this.controller.set('selectReasonFlag', true);
        }
      },
      postCancellation: function postCancellation() {
        var _this2 = this;

        var url = _environment.default.api.host + '/api/v3/cancellation';
        var saleIDs = this.controller.get('reports').filter(function (r) {
          return r.isSelected === true;
        }).map(function (r) {
          return r.saleID;
        });
        var reasonCode = this.controller.get('reasonForCancellation');
        var reason = this.controller.get('cancelReasonText');
        var postPayLoad = {
          saleIDs: saleIDs,
          reasonCode: reasonCode,
          reason: reason
        };

        this.get('ajaxWrapper').ajaxPut(url, postPayLoad).then(function (response) {
          _this2.controller.set('showCancellationModal', false);
          var bulkCancellationConfirmationFlag = _this2.controller.get('bulkCancellationConfirmationFlag');
          _this2.controller.set('bulkCancellationConfirmationFlag', !bulkCancellationConfirmationFlag);
          _this2.controller.set('showCancellationModal', false);
          _this2.controller.set('showCancellationResponseModal', true);
          _this2.send('searchReports');
          _this2.controller.set('isAllRowSelectedForCancellation', false);
          _this2.controller.set('selectedForCancellationCount', 0);
          _this2.controller.set('hideServerErrors', true);
          _this2.get('responseErrors').clearErrors();
        }).catch(function (error) {
          Ember.Logger.error('Error While processing cancellation', error);
          _this2.controller.set('showCancellationModal', false);
          var bulkCancellationConfirmationFlag = _this2.controller.get('bulkCancellationConfirmationFlag');
          _this2.controller.set('bulkCancellationConfirmationFlag', !bulkCancellationConfirmationFlag);
          if (error.responseText) {
            var errors = (0, _addJsonApiErrorsToPayload.default)(Ember.$.parseJSON(error.responseText), error.status);
            _this2.controller.set('errors', errors);
            _this2.controller.set('hideServerErrors', false);
          } else {
            _this2.controller.set('hideServerErrors', false);
            _this2.controller.set('errors', error);
          }
        });
      }
    }
  });
});