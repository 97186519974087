define('lxso/components/uitk-alert/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var iconTypes = {
    error: 'warn',
    success: 'success',
    info: 'info',
    warn: 'warn'
  };

  exports.default = Ember.Component.extend({
    type: 'info',

    iconType: Ember.computed('type', function () {
      return iconTypes[this.get('type')];
    }),

    withCloseButton: Ember.computed.and('closeButton', 'id')
  });
});