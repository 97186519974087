define('lxso/constants/status-v4', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var STATUS_V4 = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    SUSPENDED: 'SUSPENDED',
    SUSPENDED_EXPEDIA: 'SUSPENDED_EXPEDIA',
    SUSPENDED_PAUSED: 'SUSPENDED_PAUSED',
    PENDING_DELETION: 'PENDING_DELETION'
  };

  exports.default = STATUS_V4;
});