define('lxso/models/supplier-branch/supplier-support', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    email: [(0, _validation.validator)('format', {
      allowBlank: false,
      type: 'email',
      messageKey: 'errors.customMessages.supplierSupport.emailFormat'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    support_id: _emberData.default.attr('number', { defaultValue: -1 }),
    title: _emberData.default.attr('string', { defaultValue: '' }),
    email: _emberData.default.attr('string', { defaultValue: '' }),
    phone: (0, _attributes.fragment)('supplier-branch/supplier-support/phone'),
    address: (0, _attributes.fragment)('supplier-branch/supplier-support/address'),
    hours_of_operation: (0, _attributes.fragmentArray)('supplier-branch/supplier-support/hours-of-operation')
  });
});