define('lxso/mixins/enabler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),

    checkEnabled: function checkEnabled(flagName) {
      var _this = this;

      return this.get('store').peekAll(this.get('enablerModel')).any(function (flag) {
        return flag.get('name') === flagName && flag.get(_this.get('enablerFieldToCheck'));
      });
    },


    permissions: Ember.computed('session.isAuthenticated', function () {
      return this.get('store').findAll(this.get('enablerModel'), { reload: true });
    }),
    refreshFlags: Ember.observer('sessions.isAuthenticated', function () {
      this.get('store').findAll(this.get('enablerModel'), { reload: true });
    })
  });
});