define('lxso/routes/activity/options-example', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController() {
      var activity = this.modelFor('activity');

      // create - works
      var option = this.store.createRecord('option', {
        activity: activity,
        title: 'title',
        description: 'description',
        duration: 10,
        duration_type_id: 1,
        language_id: 1
      });

      option.save().then(function (option) {
        // get activity from option - works
        option.get('activity').then(function () {});

        // update - works
        // option.set('title', 'new title');
        // option.save();

        // delete - works
        // option.destroyRecord();
      });

      // find single option - works
      this.store.queryRecord('option', {
        id: 1,
        activity_id: activity.get('id')
      });
    }
  });
});