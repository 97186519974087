define('lxso/models/price-template/price-template-detail/ticket-type', ['exports', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'ember-data'], function (exports, _emberDataModelFragments, _attributes, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    ticket_type: _emberData.default.belongsTo('price-template-detail'),
    internal_note: _emberData.default.attr('string', { defaultValue: '' }),
    referenced: _emberData.default.attr('boolean', { defaultValue: false }),
    promotion: (0, _attributes.fragment)('ticket-type/promotion'), //promotion is a single object
    guest_type_id: _emberData.default.attr('number'),
    ticket_type_name: _emberData.default.attr('string', { defaultValue: '' }),
    restrictions: (0, _attributes.fragmentArray)('ticket-type/restrictions') //restrictions are an array of objects
  });
});