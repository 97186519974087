define('lxso/models/offer-price-availability', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    optionGroup: _emberData.default.belongsTo('option-group-deprecated'),
    option_id: _emberData.default.attr('number'),
    activity_option_id: _emberData.default.attr('number'),
    activity_option_group_id: _emberData.default.attr('number'),
    offer_price_availability_id: _emberData.default.attr('number'),
    schedule_template_id: _emberData.default.attr('number'),
    price_template_id: _emberData.default.attr('number'),
    start_time: _emberData.default.attr('string'),
    end_time: _emberData.default.attr('string'),

    // price_template and schedule_template require attrs embedded always in
    // serializer and the EmbeddedRecordsMixin
    // or you get a passing classes ember error. Note: you cannot use async: true with this.
    price_template: _emberData.default.belongsTo('price-template'),
    schedule_template: _emberData.default.belongsTo('schedule'),
    status: _emberData.default.attr('string'),
    create_date: _emberData.default.attr('string'),
    created_by: _emberData.default.attr('string'),
    offer_id: _emberData.default.attr('number'),
    is_limited_inventory: _emberData.default.attr('boolean'),
    availability: _emberData.default.attr('array'),
    limited_inventory: _emberData.default.belongsTo('limited-inventory')
  });
});