define('lxso/utils/date-validators', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function isSameOrBefore(from, to, precision) {
    return from.isValid() && to.isValid() && from.isSameOrBefore(to, precision);
  }

  function isSameOrAfter(from, to, precision) {
    return isSameOrBefore(to, from, precision);
  }

  function toExclusiveDuration(inclusiveDuration) {
    return moment.duration(inclusiveDuration).subtract(1, 'day');
  }

  function isInclusiveRangeLengthIsAtMost(maxDuration, from, to) {
    'use strict';

    var maxTo = moment(from).add(toExclusiveDuration(maxDuration));
    return isSameOrBefore(to, maxTo);
  }

  function isInclusiveRangeLengthIsAtLeast(minDuration, from, to) {
    'use strict';

    var minTo = moment(from).add(toExclusiveDuration(minDuration));
    return isSameOrAfter(to, minTo);
  }

  exports.isSameOrBefore = isSameOrBefore;
  exports.isSameOrAfter = isSameOrAfter;
  exports.isInclusiveRangeLengthIsAtMost = isInclusiveRangeLengthIsAtMost;
  exports.isInclusiveRangeLengthIsAtLeast = isInclusiveRangeLengthIsAtLeast;
});