define('lxso/models/price-group-v4', ['exports', 'ember-data-model-fragments/attributes', 'lxso/utils/validation', 'ember-data', 'lxso/constants/status-v4'], function (exports, _attributes, _validation, _emberData, _statusV) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    travelStart: [(0, _validation.validator)('presence', {
      presence: true
    }), (0, _validation.validator)('valid-date', {
      lazy: true
    }), (0, _validation.validator)('custom-date', {
      after: 'now',
      before: Ember.computed.readOnly('model.travelEnd'),
      messageKey: 'travelDates',
      dependantKeys: ['model.travelEnd'],
      allowOnEdit: true
    })],
    travelEnd: [(0, _validation.validator)('presence', {
      presence: true
    }), (0, _validation.validator)('valid-date', {
      lazy: true
    }), (0, _validation.validator)('custom-date', {
      after: 'now',
      messageKey: 'travelDates',
      allowOnEdit: true,
      validateAfterDateAlways: true
    })],
    bookingStart: [(0, _validation.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.bookingDatesDifferent')
    }), (0, _validation.validator)('valid-date', {
      disabled: Ember.computed.not('model.bookingDatesDifferent'),
      lazy: true
    }), (0, _validation.validator)('custom-date', {
      after: 'now',
      before: Ember.computed.readOnly('model.bookingEnd'),
      messageKey: 'bookingDates',
      dependantKeys: ['model.travelStart', 'model.travelEnd', 'model.bookingEnd', 'model.bookingDatesDifferent'],
      disabled: Ember.computed.not('model.bookingDatesDifferent'),
      lazy: true
    }), (0, _validation.validator)('date-must-be-before-or-same', {
      dateA: Ember.computed.readOnly('model.bookingStart'),
      dateB: Ember.computed.readOnly('model.travelStart'),
      messageKey: 'bookingDateBeforeOrSame',
      dependantKeys: ['model.bookingStart', 'model.travelStart', 'model.bookingDatesDifferent'],
      disabled: Ember.computed.not('model.bookingDatesDifferent'),
      lazy: true
    })],
    bookingEnd: [(0, _validation.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.bookingDatesDifferent')
    }), (0, _validation.validator)('valid-date', {
      disabled: Ember.computed.not('model.bookingDatesDifferent'),
      lazy: true
    }), (0, _validation.validator)('date-must-be-before-or-same', {
      dateA: Ember.computed.readOnly('model.bookingEnd'),
      dateB: Ember.computed.readOnly('model.travelEnd'),
      messageKey: 'bookingToDateBeforeOrSame',
      dependantKeys: ['model.bookingEnd', 'model.travelEnd', 'model.bookingDatesDifferent'],
      disabled: Ember.computed.not('model.bookingDatesDifferent'),
      lazy: true
    }), (0, _validation.validator)('custom-date', {
      after: 'now',
      messageKey: 'bookingDates',
      allowOnEdit: true,
      validateAfterDateAlways: true,
      disabled: Ember.computed.not('model.bookingDatesDifferent')
    })],
    prices: [(0, _validation.validator)('option-group-prices', {
      volatile: true
    })],
    availabilityByDays: [(0, _validation.validator)('og-availability-by-days', {
      volatile: true
    })],
    availabilityByOffers: [(0, _validation.validator)('og-availability-by-offers', {
      volatile: true
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    priceGroupId: _emberData.default.attr('number'),
    offerGroupId: _emberData.default.attr('number'),
    travelStart: _emberData.default.attr('date-string'),
    travelEnd: _emberData.default.attr('date-string'),
    bookingStart: _emberData.default.attr('date-string'),
    bookingEnd: _emberData.default.attr('date-string'),
    bookingDatesDifferent: _emberData.default.attr('boolean', { defaultValue: false }),
    prices: (0, _attributes.fragmentArray)('fragments/price-group-v4/ticket-type-price'),
    availabilityByDays: (0, _attributes.fragment)('fragments/price-group-v4/availability-by-days'),
    availabilityByOffers: (0, _attributes.fragmentArray)('fragments/price-group-v4/availability-by-offer'),
    status: _emberData.default.attr('string', { defaultValue: _statusV.default.ACTIVE }),
    i18n: Ember.inject.service(),
    activityId: _emberData.default.attr('number')
  });
});