define('lxso/validators/valid-postal-codes', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ValidPostalCodes = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      'use strict';

      var postalCodes = model.get('postalCodes');
      var errorValues = [];

      postalCodes.forEach(function (code) {
        var alphaNum = !!code.match(/^[A-Za-z0-9\- ]+$/i);
        var lengthOK = code.length > 0;
        var valid = alphaNum && lengthOK;

        if (!valid) {
          errorValues.push(code);
        }
      });

      if (errorValues.length > 0) {
        return errorValues.join(',') + ':;:is invalid and cannot be added';
      } else {
        return true;
      }
    }
  });

  exports.default = ValidPostalCodes;
});