define('lxso/helpers/get-object-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getObjectItem = getObjectItem;
  function getObjectItem(params /*, hash*/) {
    if (params[0]) {
      return params[0][params[1]];
    } else {
      return null;
    }
  }

  exports.default = Ember.Helper.helper(getObjectItem);
});