define('lxso/helpers/format-date-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDateString = formatDateString;
  function formatDateString(params /*, hash*/) {
    var MINUTES_IN_HOUR = 60;
    var HOURS_IN_DAY = 24;
    var MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;

    var result = [];
    var totalMinutes = params[0];

    var days = Math.floor(totalMinutes / MINUTES_IN_DAY);
    if (days === 1) {
      result.push(days + ' day');
    } else if (days > 1) {
      result.push(days + ' days');
    }

    var hours = Math.floor((totalMinutes - days * MINUTES_IN_DAY) / MINUTES_IN_HOUR);
    if (hours === 1) {
      result.push(hours + ' hour');
    } else if (hours > 1) {
      result.push(hours + ' hours');
    }

    var minutes = totalMinutes - days * MINUTES_IN_DAY - hours * MINUTES_IN_HOUR;
    if (minutes === 1) {
      result.push(minutes + ' minute');
    } else if (minutes > 0) {
      result.push(minutes + ' minutes');
    }

    return result.join(', ');
  }

  exports.default = Ember.Helper.helper(formatDateString);
});