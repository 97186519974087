define('lxso/utils/change-history-details-display-modes/schedule', ['exports', 'lxso/utils/change-history-details-display-modes/schedule-details'], function (exports, _scheduleDetails) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'schedule',
    diff: function diff(oldValue, newValue) {
      return {
        displayMode: _scheduleDetails.default,
        oldValue: oldValue && JSON.parse(oldValue),
        newValue: newValue && JSON.parse(newValue)
      };
    }
  };
});