define('lxso/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-simple-auth/configuration', 'lxso/config/environment', 'uitk', 'lxso/mixins/example-modal', 'lxso/utils/omniture', 'lxso/utils/is-mobile'], function (exports, _applicationRouteMixin, _configuration, _environment, _uitk, _exampleModal, _omniture, _isMobile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _exampleModal.default, {
    session: Ember.inject.service('session'),
    i18n: Ember.inject.service(),
    threepSupplier: Ember.inject.service(),
    authUtil: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (!window.google) {
        // Maps code
        _uitk.default.map.config = _environment.default.maps;

        _uitk.default.map.loadApi();
      }

      if (window.mOxie) {
        window.mOxie.Mime.mimes.jpg = 'image/jpg';
      }

      this.get('notifications').setDefaultAutoClear(true);
      this.get('notifications').setDefaultClearDuration(3200);

      return Ember.RSVP.Promise.all([this.store.findAll('enums/feature'), this.store.findAll('system/permission')]);
    },
    afterModel: function afterModel() {
      'use strict';

      Ember.$('#app-loader').remove();
    },
    setupController: function setupController() {
      this.setSupplierBranchDisplay();
      // Add the session and session data to the controller so templates
      // can access the state
      this.controller.set('session', this.get('session'));

      //this.controller.set('ingestedFlags', this.get('session.data.authenticated.selectedBranch.ingestedFlags'));
      //console.log('ingested', this.controller.get('ingestedFlags'));

      this.controller.set('activityTitle', 'Initial setup');
      if (!(0, _isMobile.default)()) {
        this.controller.set('isSideMenuOpen', true);
      }
    },


    // Activated from didTransition and setupController to ensure the values are
    // set both on a route transition and a hard refresh
    setSupplierBranchDisplay: function setSupplierBranchDisplay() {
      var branchName = this.get('session.data.authenticated.selectedBranch.supplier_branch_name');
      this.controller.set('isBranchSelected', branchName ? true : false);

      var branches = this.get('session.data.authenticated.supplierBranches');
      var isSuperUser = this.get('session.data.authenticated.isSuperUser');

      this.controller.set('isSuperUser', isSuperUser);

      this.controller.set('is3PSupplier', this.get('threepSupplier').isSupplier3P());

      this.controller.set('menuDropdownText', branchName ? branchName : this.get('i18n').t('header.menuText'));

      if (isSuperUser || branches) {
        this.controller.set('showSuppliersLink', isSuperUser || branches.get('length') > 1 && typeof branchName === 'string');
      }
    },
    getPricingIngestion: function getPricingIngestion() {
      var ingestedFlags = this.get('session.data.authenticated.selectedBranch.ingestedFlags');
      var pricingIngestion = ingestedFlags.find(function (item) {
        return item.ingestedFlag === 'pricingIngestion';
      });
      if (pricingIngestion && pricingIngestion.hasOwnProperty('validity')) {
        return pricingIngestion.validity;
      } else {
        return false;
      }
    },
    sendOmnitureDataOnMenuClick: function sendOmnitureDataOnMenuClick(route) {
      var menuOmnitureDataMap = {
        'products': 'page.LX-Supply.menuProducts',
        'manage-availability': 'page.LX-Supply.menuManageAvailability',
        'report': 'page.LX-Supply.menuReports',
        'reviews': 'page.LX-Supply.menuReviews',
        'change-history': 'page.LX-Supply.menuChangeHistory',
        'product-setup': 'page.LX-Supply.menuCreateNewProduct',
        'account-settings': 'page.LX-Supply.menuAccountSettings',
        'supplier-branch-settings': 'page.LX-Supply.menuSupplierBranchSettings',
        'platform-list': 'page.LX-Supply.menuPlatformList',
        'hotel-zone-list': 'page.LX-Supply.menuHotelZoneList',
        'manage-users': 'page.LX-Supply.menuManageUsers',
        'promotion-list': 'page.LX-Supply.Menu.promotion'
      };
      var linkName = menuOmnitureDataMap[route];
      (0, _omniture.sendOmnitureDataOnClick)(linkName);
    },


    actions: {
      //these functions need to be defined at a high route level
      updateFromDate: function updateFromDate() {},
      updateToDate: function updateToDate() {},
      applicationLogin: function applicationLogin() {
        // Transition to the authentication route
        this.transitionTo(_configuration.default.authenticationRoute);
      },
      menuTransition: function menuTransition(params, queryParams) {
        this.sendOmnitureDataOnMenuClick(params[0]);
        this.transitionTo.apply(this, _toConsumableArray(params).concat([{ queryParams: queryParams }]));
      },
      updateMenuData: function updateMenuData() {
        this.setSupplierBranchDisplay();
      },
      toggleSideNavMenu: function toggleSideNavMenu() {
        this.controller.toggleProperty('isSideMenuOpen');
      },
      hideSideMenu: function hideSideMenu() {
        this.controller.set('isSideMenuOpen', false);
      },
      applicationLogout: function applicationLogout(isSourceEpc) {
        // Set a userInitiatedLogout property on the route
        this.set('userInitiatedLogout', true);
        var sessionData = this.get('session.data');
        if (sessionData.authenticated.authenticator === 'authenticator:oidc' && !isSourceEpc) {
          this.get('authUtil').epcLogout();
        } else {
          // Invalidate the session
          this.get('session').invalidate();
        }
      },
      didTransition: function didTransition() {
        this.setSupplierBranchDisplay();
      }
    }

  });
});