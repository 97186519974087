define('lxso/validators/has-many-with-index', ['exports', 'ember-cp-validations/validators/has-many', 'ember-cp-validations/utils/utils'], function (exports, _hasMany, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var ArrayContentObjectNumber = _hasMany.default.extend({
    validate: function validate(value) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (value) {
        if ((0, _utils.isPromise)(value)) {
          return value.then(function (models) {
            return _this.validate.apply(_this, [models].concat(_toConsumableArray(args)));
          });
        }
        var validations = value.map(function (m) {
          return m.get('validations').get('messages');
        });

        var messageList = new Ember.A([]);
        var messageIndexLists = {};

        validations.forEach(function (validation) {
          return validation.forEach(function (message) {
            var messageIndex = messageList.indexOf(message);
            if (messageIndex === -1) {
              messageList.push(message);
              messageIndexLists[messageList.length - 1] = new Ember.A([]);
            }
          });
        });

        validations.forEach(function (validation) {
          messageList.forEach(function (message, messageIdx) {
            messageIndexLists[messageIdx].push(validation.indexOf(message) === -1 ? 0 : 1);
          });
        });

        return value.map(function (m) {
          return m.get('validations');
        }); //new Ember.A(new Ember.A([errorList]));
      }

      return true;
    }
  });
  //this takes an array of objects and uses the built in number validator on
  //on the passed property of each of those objects

  exports.default = ArrayContentObjectNumber;
});