define('lxso/adapters/price-group-v4', ['exports', 'lodash', 'lxso/adapters/application', 'lxso/constants/option-group', 'lxso/constants/common', 'lxso/utils/random-key-generator'], function (exports, _lodash, _application, _optionGroup, _common, _randomKeyGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _application.default.extend({
    ajaxWrapper: Ember.inject.service(),
    responseErrors: Ember.inject.service(),

    query: function query(store, type, _ref) {
      var _this = this;

      var optionGroupId = _ref.optionGroupId,
          availabilityType = _ref.availabilityType,
          scheduleType = _ref.scheduleType,
          offerIds = _ref.offerIds;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var promises = [];
        var priceAndScheduleUrl = _this.hostV4 + '/price-group/simplifiedprice/offergroup/' + optionGroupId;
        var availabilityUrl = _this.hostV4 + '/availability/availabilities?offerGroupId=' + optionGroupId;
        promises.push(_this.ajax(priceAndScheduleUrl, 'GET'));
        if (availabilityType === _optionGroup.AVAILABILITY_TYPES.LIMITED) {
          promises.push(_this.ajax(availabilityUrl, 'GET'));
        }
        Ember.RSVP.all(promises).then(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
              priceGroupResponse = _ref3[0],
              availabilitiesResponse = _ref3[1];

          var availabilities = availabilitiesResponse ? availabilitiesResponse.availabilityGroups : null;
          var priceGroupsMappedWithAvailability = mapAvailabilitiesAndPriceGroupResponseModel(priceGroupResponse, availabilities, scheduleType, offerIds);
          resolve(priceGroupsMappedWithAvailability);
        }).catch(function (errorResponse) {
          resolve([]);
        });
      });
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _this2 = this;

      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var availabilityType = snapshot.adapterOptions.availabilityType;

      serializer.serializeIntoHash(data, type, snapshot);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.createPriceGroup(resolve, reject, data, availabilityType);
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this3 = this;

      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var _snapshot$adapterOpti = snapshot.adapterOptions,
          availabilityType = _snapshot$adapterOpti.availabilityType,
          primaryId = _snapshot$adapterOpti.primaryId;

      serializer.serializeIntoHash(data, type, snapshot, primaryId);
      data.id = primaryId;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.updatePriceGroup(resolve, reject, data, availabilityType);
      });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var data = {};
      snapshot.adapterOptions = {
        offerIds: []
      };
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var priceGroupId = data.priceGroupId;
      return this.deletePrices(priceGroupId, data.prices);
    },
    fetchUpdatedPricesFromSimplifiedPriceGroups: function fetchUpdatedPricesFromSimplifiedPriceGroups(priceGroupId, priceGroup, resolve, reject) {
      var _this4 = this;

      setTimeout(function () {
        var simplifiedPriceGroupUrl = _this4.hostV4 + '/price-group/simplifiedprice/pricegroup/' + priceGroupId;
        _this4.ajax(simplifiedPriceGroupUrl, 'GET').then(function (simplifiedPriceGroupResponse) {
          if (!priceGroup.id) {
            priceGroup.id = (0, _randomKeyGenerator.default)();
          }
          priceGroup.prices = simplifiedPriceGroupResponse.simplifiedPriceGroups[0].priceResponses.map(function (price) {
            return Object.assign(price, {
              isSelected: true,
              margin: parseFloat(price.margin.toFixed(_common.MARGIN_DECIMAL_PLACES))
            });
          });
          stripTimezoneFromPriceGroupDates(priceGroup);
          resolve(priceGroup);
        }).catch(function (priceAndScheduleError) {
          reject(priceAndScheduleError);
        });
      }, _common.TIME_DELAY_MS_FOR_FETCH_AFTER_UPDATE);
    },
    createPriceGroup: function createPriceGroup(resolve, reject, data, availabilityType) {
      var _this5 = this;

      var priceAndScheduleUrl = this.hostV4 + '/price-group/detailed';
      var offerScheduleDetailsRequestModels = constructOfferScheduleDetailsRequestModels(data);
      var priceAndScheduleRequestBody = constructPriceGroupCreateRequestModel(data, offerScheduleDetailsRequestModels);

      return this.ajax(priceAndScheduleUrl, 'POST', { data: priceAndScheduleRequestBody }).then(function (priceAndScheduleResponse) {
        var priceGroupId = priceAndScheduleResponse.priceGroupId;
        data.priceGroupId = priceGroupId;
        return _this5.updateAvailabilities(availabilityType, 'POST', data, offerScheduleDetailsRequestModels)
        //POST response contain priceGroupId only. We make a follower GET call to get updated prices
        .then(function () {
          return _this5.fetchUpdatedPricesFromSimplifiedPriceGroups(priceGroupId, data, resolve, reject);
        });
      }).catch(function (priceGroupError) {
        return reject(priceGroupError);
      });
    },
    updatePriceGroup: function updatePriceGroup(resolve, reject, data, availabilityType) {
      var _this6 = this;

      var priceGroupId = data.priceGroupId;

      var _$partition = _lodash.default.partition(data.prices, function (price) {
        return price.isSelected;
      }),
          _$partition2 = _slicedToArray(_$partition, 2),
          pricesToCreateOrUpdate = _$partition2[0],
          pricesToDelete = _$partition2[1];

      var _$partition3 = _lodash.default.partition(pricesToCreateOrUpdate, function (price) {
        return price.priceId > 0;
      }),
          _$partition4 = _slicedToArray(_$partition3, 2),
          pricesToUpdate = _$partition4[0],
          pricesToCreate = _$partition4[1];

      var priceDates = constructPriceDates(data);
      var offerScheduleDetailsRequestModels = constructOfferScheduleDetailsRequestModels(data);

      this.deletePrices(priceGroupId, pricesToDelete).then(function () {
        return _this6.createOrUpdateIndividualPrices(priceGroupId, pricesToUpdate, priceDates, offerScheduleDetailsRequestModels, false);
      }).then(function () {
        return _this6.createOrUpdateIndividualPrices(priceGroupId, pricesToCreate, priceDates, offerScheduleDetailsRequestModels, true);
      }).then(function (createdPrices) {
        pricesToCreate.forEach(function (price, index) {
          return Object.assign(price, { priceId: createdPrices[index].priceId });
        });
        return _this6.updateAvailabilities(availabilityType, 'POST', data, offerScheduleDetailsRequestModels);
      }).then(function () {
        data.prices = [].concat(_toConsumableArray(pricesToUpdate), _toConsumableArray(pricesToCreate));
        stripTimezoneFromPriceGroupDates(data);
        resolve(data);
      }).catch(function (error) {
        return reject(error);
      });
    },
    updateAvailabilities: function updateAvailabilities(availabilityType, methodType, priceGroup, offerScheduleDetailsRequestModels) {
      var _this7 = this;

      var availabilityUrl = this.hostV4 + '/availability/';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (availabilityType === _optionGroup.AVAILABILITY_TYPES.LIMITED) {
          var availabilityRequestBody = constructAvailabilityRequestModel(priceGroup, offerScheduleDetailsRequestModels);
          _this7.ajax(availabilityUrl, methodType, { data: availabilityRequestBody }).then(function () {
            return resolve();
          }).catch(function (error) {
            return reject(error);
          });
        } else {
          resolve();
        }
      });
    },
    createOrUpdateIndividualPrices: function createOrUpdateIndividualPrices(priceGroupId, prices, priceDates, offerScheduleDetailsRequestModels, isNewPrice) {
      var _this8 = this;

      var methodType = isNewPrice ? 'POST' : 'PUT';
      var singlePriceUpdateUrl = this.hostV4 + '/price-group/' + priceGroupId + '/price';

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var responses = [];
        prices.reduce(function (accumulator, price, index) {
          var priceUpdateRequest = constructPriceOfferIdScheduleDetailsRequestModels(price, priceDates, offerScheduleDetailsRequestModels);
          return accumulator.then(function (response) {
            if (index > 0) {
              responses.push(response);
            }
            return _this8.ajax(singlePriceUpdateUrl, methodType, { data: priceUpdateRequest });
          }).catch(function (error) {
            return reject(error);
          });
        }, Ember.RSVP.resolve()).then(function (lastResponse) {
          responses.push(lastResponse);
          resolve(responses);
        });
      });
    },
    deletePrices: function deletePrices(priceGroupId, pricesToDelete) {
      var _this9 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var promises = pricesToDelete.map(function (price) {
          var priceDeleteUrl = _this9.hostV4 + '/price-group/' + priceGroupId + '/price/' + price.priceId;
          return _this9.ajax(priceDeleteUrl, 'DELETE');
        });
        Ember.RSVP.all(promises).then(function (success) {
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  });


  function mapAvailabilitiesAndPriceGroupResponseModel(priceGroupResponse, availabilities, scheduleType, offerIds) {
    var simplifiedPriceGroups = priceGroupResponse ? priceGroupResponse.simplifiedPriceGroups : [];
    return simplifiedPriceGroups.map(function (simplifiedPriceGroup) {
      var simplifiedPriceGroupWithMapping = {
        id: (0, _randomKeyGenerator.default)(),
        scheduleType: scheduleType,
        priceGroupId: simplifiedPriceGroup.priceGroupId,
        travelStart: simplifiedPriceGroup.travelStart,
        travelEnd: simplifiedPriceGroup.travelEnd,
        bookingStart: simplifiedPriceGroup.bookingStart,
        bookingEnd: simplifiedPriceGroup.bookingEnd,
        prices: simplifiedPriceGroup.priceResponses,
        schedules: simplifiedPriceGroup.offerIdScheduleDetailsResponses,
        allOfferIdsUnderOfferGroup: offerIds,
        activityId: priceGroupResponse.activityId
      };
      if (availabilities) {
        var scheduleOfferIds = simplifiedPriceGroup.offerIdScheduleDetailsResponses.map(function (sd) {
          return sd.offerId;
        });
        var schedulePriceIds = simplifiedPriceGroup.priceResponses.map(function (p) {
          return p.priceId;
        });
        var availabilityLinkedToPG = availabilities.find(function (a) {
          return schedulePriceIds.includes(a.priceId);
        });
        //assumes that within a PG, all priceIds has same offer availabilities linked to it
        simplifiedPriceGroupWithMapping.availabilities = availabilityLinkedToPG ? availabilityLinkedToPG.availabilities.filter(function (a) {
          return scheduleOfferIds.includes(a.offerId);
        }).uniqBy('offerId') : null;
      }
      return simplifiedPriceGroupWithMapping;
    });
  }

  function constructOfferScheduleDetailsRequestModels(priceGroup) {
    return priceGroup.schedules.map(function (schedule) {
      return Object.assign({}, {
        offerId: schedule.offerId,
        scheduleType: schedule.scheduleType,
        scheduleDetailsRequestModels: schedule.details
      });
    }).filter(function (offerSchedule) {
      return offerSchedule.scheduleDetailsRequestModels.map(function (sd) {
        return sd.daysAvailable;
      }).flat().length > 0;
    });
  }

  function constructPriceGroupCreateRequestModel(priceGroup, offerScheduleDetailsRequestModels) {
    var priceDates = constructPriceDates(priceGroup);
    return {
      offerGroupId: priceGroup.offerGroupId,
      activityId: priceGroup.activityId,
      priceOfferIdScheduleDetailsRequestModels: priceGroup.prices.map(function (price) {
        return constructPriceOfferIdScheduleDetailsRequestModels(price, priceDates, offerScheduleDetailsRequestModels);
      })
    };
  }

  function constructPriceOfferIdScheduleDetailsRequestModels(price, priceDates, offerScheduleDetailsRequestModels) {
    return {
      offerScheduleDetailsRequestModels: offerScheduleDetailsRequestModels,
      priceDetailsRequestModel: Object.assign({}, price, priceDates)
    };
  }

  function constructPriceDates(priceGroup) {
    return Object.assign({}, {
      travelStart: priceGroup.travelStart ? priceGroup.travelStart.split('T')[0] : null,
      travelEnd: priceGroup.travelEnd ? priceGroup.travelEnd.split('T')[0] : null,
      bookingStart: priceGroup.bookingStart ? priceGroup.bookingStart.split('T')[0] : null,
      bookingEnd: priceGroup.bookingEnd ? priceGroup.bookingEnd.split('T')[0] : null
    });
  }

  function constructAvailabilityRequestModel(priceGroup, offerScheduleDetailsRequestModels) {
    var startDate = moment(priceGroup.travelStart).isBefore() ? moment.utc().format('YYYY-MM-DD') : priceGroup.travelStart.split('T')[0];
    return {
      activityId: priceGroup.activityId,
      startDate: startDate,
      endDate: priceGroup.travelEnd ? priceGroup.travelEnd.split('T')[0] : null,
      offerAvailabilities: constructOfferAvailabilities(priceGroup, offerScheduleDetailsRequestModels)
    };
  }

  function constructOfferAvailabilities(priceGroup, offerScheduleDetailsRequestModels) {
    return priceGroup.availabilities.map(function (availability) {
      var schedulesForOfferAvailability = offerScheduleDetailsRequestModels.filter(function (offerSchedule) {
        return availability.offerIds.includes(offerSchedule.offerId);
      });
      return {
        offerScheduleDetailsRequestModels: schedulesForOfferAvailability,
        daysAvailable: availability.daysAvailable
      };
    }).filter(function (av) {
      return av.offerScheduleDetailsRequestModels.length > 0;
    });
  }

  function stripTimezoneFromPriceGroupDates(priceGroup) {
    priceGroup.travelStart = priceGroup.travelStart ? priceGroup.travelStart.split('T')[0] : priceGroup.travelStart;
    priceGroup.travelEnd = priceGroup.travelEnd ? priceGroup.travelEnd.split('T')[0] : priceGroup.travelEnd;
    priceGroup.bookingStart = priceGroup.bookingStart ? priceGroup.bookingStart.split('T')[0] : priceGroup.bookingStart;
    priceGroup.bookingEnd = priceGroup.bookingEnd ? priceGroup.bookingEnd.split('T')[0] : priceGroup.bookingEnd;
    return priceGroup;
  }
});