define('lxso/helpers/is-user-inactive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isUserInactive = isUserInactive;
  function isUserInactive(user) {
    var userRoles = user[0] && user[0].get('roles') ? user[0].get('roles').split(', ') : [];
    if (user[0] && user[0].get('status') && user[0].get('status').toUpperCase() === 'INACTIVE' && (userRoles.indexOf('V3 Supply Manager') >= 0 || userRoles.indexOf('Report Reader') >= 0)) {
      return true;
    }
  }

  exports.default = Ember.Helper.helper(isUserInactive);
});