define("lxso/utils/arrays", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function containSameElements(xs, ys) {
    _require(Array.isArray(xs), "'" + xs + "' is not an array.");
    _require(Array.isArray(ys), "'" + ys + "' is not an array.");
    return xs.every(function (x) {
      return ys.includes(x);
    }) && ys.every(function (y) {
      return xs.includes(y);
    });
  }

  function getValuesListFromKeyValuePairs(keyValuePairs) {
    var list = Ember.A([]);
    for (var item in keyValuePairs) {
      list.push(keyValuePairs[item]);
    }
    return list;
  };

  function _require(condition, message) {
    if (!condition) {
      throw "Illegal argument: " + message;
    }
  }

  exports.containSameElements = containSameElements;
  exports.getValuesListFromKeyValuePairs = getValuesListFromKeyValuePairs;
});