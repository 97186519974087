define('lxso/validators/array-length', ['exports', 'ember-cp-validations/validators/number'], function (exports, _number) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArrayLength = _number.default.extend({
    value: function value(model, attribute) {
      return model.get(attribute).get('length');
    }
  });

  ArrayLength.reopenClass({
    getDependentsFor: function getDependentsFor(attribute) {
      return ['model.' + attribute + '.[]'];
    }
  });

  exports.default = ArrayLength;
});