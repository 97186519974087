define('lxso/models/video', ['exports', 'ember-data', 'lxso/utils/validation', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _validation, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    link: {
      descriptionKey: 'pages.videos.videoLinkLabel',
      validators: [(0, _validation.validator)('format', {
        type: 'url',
        allowBlank: true
      }), (0, _validation.validator)('presence', true), (0, _validation.validator)('format', {
        regex: /^(http(s)?:\/\/)?(www\.)?youtube.com\/watch\?v=.+$/,
        messageKey: 'pages.videos.youtubeVideoValidation',
        allowBlank: true
      })]
    }
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    activity_id: _emberData.default.attr('number'),
    link: _emberData.default.attr('string'),
    videoId: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    hero: _emberData.default.attr('boolean'),
    rejectReason: _emberData.default.attr('string', { defaultValue: '' })
  });
});