define('lxso/mixins/option-group/price-v3', ['exports', 'lodash/array', 'lxso/constants/option-group', 'lxso/utils/form-validator', 'lxso/utils/offer-group', 'lxso/utils/omniture', 'lxso/utils/to-percent-without-percent-sign', 'lxso/config/environment'], function (exports, _array2, _optionGroup, _formValidator, _offerGroup, _omniture, _toPercentWithoutPercentSign, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PRICE_ATTRS_IN_OPTION_GROUP = Ember.A(['pricesByVolume', 'pricingIngestionEnabled']);

  //This mixin has code copied and refactored from routes/activity/offer.js for integrating V4 option group with V3 Prices
  //This is for a temporary work around until V4 Price APIs are ready.

  exports.default = Ember.Mixin.create({
    permissions: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    netPrice: Ember.inject.service(),
    optionGroupService: Ember.inject.service(),

    setUpPriceV3Section: function setUpPriceV3Section() {
      this.setUpPriceAccordion();
      this.setupV3PriceGroup();
      this.setPricingIngestion();
      this._setScheduleSupertype(this.controller.get('optionGroup.attributes.scheduleType'));
      this.setOfferIdForMarginAndExternalReference();
      this.loadMargin();
    },
    setUpPriceAccordion: function setUpPriceAccordion() {
      var isEnabled = this.controller.get('isOptionGroupOptionsEnabled') && this.controller.get('optionGroup.offers.length') > 0;
      this.controller.set('isOptionGroupPriceV3Enabled', isEnabled);
      var sectionToEdit = this.get('optionGroupService').getSectionToEdit();
      this.controller.set('isOptionGroupPriceV3Open', sectionToEdit === _optionGroup.SECTIONS.V3_PRICING);
    },
    setupV3PriceGroup: function setupV3PriceGroup() {
      var _this = this;

      if (!this.controller.get('isOptionGroupPriceV3Enabled')) {
        return;
      }
      var scheduleType = this.controller.get('optionGroup.attributes.scheduleType');
      if (this.controller.get('v3OptionGroup.prices.length')) {
        this.controller.get('v3OptionGroup.prices').forEach(function (data) {
          data.set('priceUpdateAlertVisible', false);
          if (data.get('isNew')) {
            _this.controller.set('editingPg', true);
            if (scheduleType === _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION) {
              data.get('availabilities.firstObject.availability').forEach(function (day) {
                day.open = '00:00';
                day.close = '23:59';
              });
            }
          } else {
            _this.controller.set('hasExistingPrices', true);
          }
        });
      } else {
        this.controller.set('editingPg', true);
        var priceGroup = this.store.createRecord('priceGroup');
        var price = this.store.createRecord('ticket-detail');
        priceGroup.get('prices').pushObject(price);
        priceGroup.set('priceUpdateAlertVisible', false);
        if (scheduleType === _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION) {
          priceGroup.get('availabilities.firstObject.availability').forEach(function (day) {
            day.open = '00:00';
            day.close = '23:59';
          });
        }
        this.controller.get('v3OptionGroup.prices').pushObject(priceGroup);
      }
    },
    validatePriceFields: function validatePriceFields(pg) {
      var priceGroupAttributes = ['travelStart', 'travelEnd', 'bookingStart', 'bookingEnd', 'someAvailability'];

      var ticketValidations = ['retailPrice', 'marketingPrice', 'ticketTypeId', 'validMinMaxRange', 'priceCodeSKU'];

      if (this.controller.get('optionGroup.attributes.pricesByVolume')) {
        ticketValidations.push('minimumTravelers', 'maximumTravelers');
        priceGroupAttributes.push('noGroupOverlap');
      } else {
        priceGroupAttributes.push('duplicateTickets', 'noOverlapTicketTypes', 'uniqueTicketTypes');
      }

      var pgGroup = (0, _formValidator.validate)({ model: pg,
        attributes: priceGroupAttributes
      });

      var tickets = pg.get('prices').filter(function (p) {
        return p.get('operation') !== 'delete';
      }).map(function (ticket, idx) {
        return (0, _formValidator.validate)({
          model: ticket,
          attributes: ticketValidations
        });
      });

      var dayAvailabilityValidationArray = new Ember.A(['capacity']);
      if (this.controller.get('optionGroup.attributes.scheduleType') === _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION) {
        dayAvailabilityValidationArray.pushObjects(['open', 'close', 'timeRange']);
      }

      var dayAvailability = pg.get('availabilities.firstObject.availability').map(function (day, idx) {
        return (0, _formValidator.validate)({
          model: day,
          attributes: dayAvailabilityValidationArray
        });
      });

      function buildValidationArray(validationGroup) {
        return validationGroup.filterBy('state', 'rejected').map(function (promise) {
          return promise.reason;
        }).reduce(function (a, b) {
          return Object.assign(a, b);
        }, []);
      }

      function compileMessagesForSingleDisplay(validations) {
        var messages = new Ember.A([]);
        validations.forEach(function (error) {
          if (error.reason) {
            for (var messageKey in error.reason) {
              messages.pushObjects(error.reason[messageKey]);
            }
          }
        });
        return messages.uniq();
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.RSVP.allSettled(dayAvailability).then(function (availabilityValidations) {
          return Ember.RSVP.allSettled(tickets).then(function (ticketValidations) {
            return Ember.RSVP.allSettled([pgGroup]).then(function (pgGroupValidations) {
              var priceGroupErrors = buildValidationArray(pgGroupValidations);

              var availabilityMessages = compileMessagesForSingleDisplay(availabilityValidations);
              priceGroupErrors.availability = availabilityMessages || {};

              var allValidationErrors = {
                priceGroupErrors: priceGroupErrors,
                ticketErrors: ticketValidations,
                availabilityErrors: availabilityValidations
              };
              //use directly from promise settling as some filtering and building
              //can take a event loop
              if ((0, _offerGroup.anyRejects)(pgGroupValidations) || (0, _offerGroup.anyRejects)(ticketValidations) || (0, _offerGroup.anyRejects)(availabilityValidations)) {
                reject(allValidationErrors);
              } else {
                resolve();
              }
            });
          });
        });
      });
    },
    createClonePg: function createClonePg(pg) {
      var _this2 = this;

      var generatePriceArray = function generatePriceArray(clonePrices, prices) {
        prices.forEach(function (price) {
          if (!price.get('isNew')) {
            clonePrices.pushObject(_this2.store.createRecord('ticket-detail', {
              ticketTypeId: price.get('ticketTypeId').toString(),
              retailPrice: price.get('retailPrice'),
              marketingPrice: price.get('marketingPrice'),
              priceCodeSKU: price.get('priceCodeSKU'),
              minimumTravelers: price.get('minimumTravelers'),
              maximumTravelers: price.get('maximumTravelers'),
              groupPricePlanType: price.get('groupPricePlanType'),
              partnerTicketTypeId: price.get('partnerTicketTypeId'),
              margin: price.get('margin')
            }));
          }
        });
      };

      var generateAvailabilityArray = function generateAvailabilityArray(cloneAvailability, availability) {
        cloneAvailability.forEach(function (day, idx) {
          day.setProperties({
            day: availability.objectAt(idx).get('day'),
            open: availability.objectAt(idx).get('open'),
            close: availability.objectAt(idx).get('close'),
            available: availability.objectAt(idx).get('available'),
            capacity: availability.objectAt(idx).get('capacity')
          });
        });
      };

      var clone = this.store.createRecord('price-group', {
        travelStart: pg.get('travelStart'),
        travelEnd: pg.get('travelEnd'),
        bookingStart: pg.get('bookingStart'),
        bookingEnd: pg.get('bookingEnd'),
        bookingDatesDifferent: pg.get('bookingDatesDifferent')
      });

      generatePriceArray(clone.get('prices'), pg.get('prices'));
      generateAvailabilityArray(clone.get('availabilities.firstObject.availability'), pg.get('availabilities.firstObject.availability'));

      return clone;
    },
    removeDispersedTickets: function removeDispersedTickets(priceGroup) {
      priceGroup.get('prices').forEach(function (priceInfo, index) {
        if (!priceInfo.get('ticketTypeId')) {
          priceGroup.get('prices').removeAt(index);
        }
      });
    },
    setPricingIngestion: function setPricingIngestion() {
      var supplierConnectivityStatus = this.get('session').get('data.authenticated.selectedBranch.connectivity_status');
      var platformId = this.get('session').get('data.authenticated.selectedBranch.platform_id');
      if (supplierConnectivityStatus === undefined || supplierConnectivityStatus === false) {
        this.controller.set('connectedSupplier', false);
      } else {
        this.controller.set('connectedSupplier', true);
        this.controller.set('optionGroup.platformId', platformId);
      }
      if (this.controller.get('optionGroup.attributes.pricingIngestionEnabled')) {
        var pricingIngestionFlag = this.controller.get('optionGroup.attributes.pricingIngestionEnabled');
        this.controller.set('isPricingIngestion', pricingIngestionFlag);
      } else {
        this.controller.set('isPricingIngestion', false);
      }
    },
    setOfferIdForMarginAndExternalReference: function setOfferIdForMarginAndExternalReference() {
      var firstOffer = this.controller.get('optionGroup.offers').objectAt(0);
      var offerId = firstOffer && firstOffer.get('offerIds') ? firstOffer.get('offerIds')[0] : null;
      this.controller.set('offerIdForMarginFetch', offerId);
    },
    loadMargin: function loadMargin() {
      if (this.controller.get('offerIdForMarginFetch')) {
        this.get('netPrice').load(this.controller.get('offerIdForMarginFetch'));
      }
    },
    _setScheduleSupertype: function _setScheduleSupertype(type) {
      switch (type) {
        case _optionGroup.SCHEDULE_TYPES.VALID_DAYS:
        case _optionGroup.SCHEDULE_TYPES.VALID_HOURS:
          this.controller.set('scheduleSupertype', 'hours');
          break;
        case _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION:
          this.controller.set('scheduleSupertype', 'hours');
          break;
        case _optionGroup.SCHEDULE_TYPES.START:
        case _optionGroup.SCHEDULE_TYPES.MULTI_DAY:
          this.controller.set('scheduleSupertype', 'start');
          break;
      }
    },
    _cancelEditingPg: function _cancelEditingPg() {
      this.controller.set('editingPg', false);
      this.controller.set('editingPgIndex', null);
      this.controller.set('dirty', false);
    },


    actions: {
      setScheduleSupertype: function setScheduleSupertype(type) {
        this._setScheduleSupertype(type);
      },
      openPricingStep: function openPricingStep() {
        if (this.controller.get('isOptionGroupPriceV3Enabled')) {
          this.controller.toggleProperty('isOptionGroupPriceV3Open');
        }
      },
      saveOptionGroupPriceAttrs: function saveOptionGroupPriceAttrs() {
        var _this3 = this;

        this.controller.set('savingOptionGroupPriceAttrs', true);
        this.controller.set('lastUpdated', _optionGroup.SECTIONS.PRICE_ATTRIBUTES);
        this.controller.get('optionGroup').save({ adapterOptions: { section: _optionGroup.SECTIONS.DETAILS } }).then(function () {
          _this3.controller.set('isOptionGroupUpdateEnabled', true);
        }).finally(function () {
          _this3.controller.set('savingOptionGroupPriceAttrs', false);
        });
      },
      addPriceGroup: function addPriceGroup() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AddPriceGroup');
        this.controller.set('editingPg', true);
        var priceGroup = this.store.createRecord('priceGroup');
        var price = this.store.createRecord('ticket-detail');
        priceGroup.get('prices').pushObject(price);
        var scheduleType = this.controller.get('optionGroup.attributes.scheduleType');
        if (scheduleType === _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION) {
          priceGroup.get('availabilities.firstObject.availability').forEach(function (day) {
            day.open = '00:00';
            day.close = '23:59';
          });
        }

        this.controller.get('v3OptionGroup.prices').pushObject(priceGroup);
      },
      addPrices: function addPrices(pg, volumeTicketTypeId, volumeGroupType, volumeGroupTicketType) {
        var price = this.store.createRecord('ticket-detail', {
          ticketTypeId: volumeTicketTypeId,
          groupPricePlanType: volumeGroupType,
          groupTicketTypeId: volumeGroupTicketType
        });
        //set operation to 'create' when new ticket is added to existing Price Group
        if (pg.get('id') !== null) {
          price.set('operation', 'create');
        }
        //add margin value to newly created ticket in VBP price
        if (typeof volumeTicketTypeId !== 'undefined') {
          price.set('margin', (0, _toPercentWithoutPercentSign.default)(this.get('netPrice').getMargin(volumeTicketTypeId)));
        }
        pg.get('prices').pushObject(price);
      },
      clonePg: function clonePg(pg) {
        var clone = this.createClonePg(pg);
        this.setDirtyAttribute();
        //refactor with add price gorup
        this.controller.set('editingPg', true);
        this.controller.get('v3OptionGroup.prices').pushObject(clone);

        Ember.run.later(function () {
          var anchorArray = Ember.$('.price-group-anchor:last');
          Ember.$('html, body').animate({
            scrollTop: anchorArray.offset().top
          }, 250);
        });
      },
      openDeactivatePg: function openDeactivatePg(pg, priceIds) {
        this.controller.set('deactivatePriceGroupOpen', true);
        this.controller.set('currentPg', pg);
        this.controller.set('currentPriceIds', priceIds);
      },
      deactivatePg: function deactivatePg() {
        var _this4 = this;

        var deactivatePriceGroupOpen = this.controller.get('deactivatePriceGroupOpen');

        if (deactivatePriceGroupOpen) {
          this.controller.set('isProcessingConfirmAction', true);
          var optionGroupId = this.controller.get('optionGroup.id');
          var pg = this.controller.get('currentPg');
          var priceIds = this.controller.get('currentPriceIds');
          var priceGroupId = this.controller.get('currentPg.priceGroupId');
          var url = _environment.default.api.host + '/api/v3/offerGroup/' + optionGroupId + '/price/' + priceGroupId;

          this.get('ajaxWrapper').ajaxDelete(url, { priceIds: priceIds }, undefined, { dataType: 'text' }).then(function () {
            var offerPrices = _this4.controller.get('v3OptionGroup.prices');
            offerPrices.removeObject(pg);
            _this4.controller.set('currentPg', null);
            _this4.controller.set('currentPriceIds', null);
            _this4.controller.set('deactivatePriceGroupOpen', false);
            if (offerPrices.get('length') === 0) {
              _this4.send('addPriceGroup');
            }
          }).catch(function (err) {
            Ember.Logger.error('error deleting', err);
            if (err && err.responseText && err.status) {
              var errorMessage = JSON.parse(err.responseText).errorMessage;
              var payloadErrors = {
                details: [{
                  message: errorMessage
                }]
              };
              _this4.controller.set('serverErrors', payloadErrors);
              _this4.controller.set('serverErrorStatus', err.status);
            }
          }).finally(function () {
            _this4.controller.set('isProcessingConfirmAction', false);
          });
        }
      },
      closeDeactivatePg: function closeDeactivatePg() {
        this.controller.set('deactivatePriceGroupOpen', false);
      },
      validatePricingIfInvalid: function validatePricingIfInvalid(pg, setDirty) {
        var _this5 = this;

        if (setDirty) {
          this.setDirtyAttribute();
        }

        if (this.controller.get('pricingErrors')) {
          this.validatePriceFields(pg).then(function () {
            return _this5.controller.set('pricingErrors', null);
          }).catch(function (errors) {
            return _this5.controller.set('pricingErrors', errors);
          });
        }
      },
      updatePrices: function updatePrices() {
        var _this6 = this;

        var offerGroupId = this.controller.get('optionGroup.id');
        this.controller.set('lastUpdated', _optionGroup.SECTIONS.PRICE_ATTRIBUTES);
        this.controller.set('updatingPriceWithIngestion', true);
        var data = {};
        var url = _environment.default.api.hostV4 + '/offer-group/' + offerGroupId + '/triggerPricingIngestion';

        this.get('ajaxWrapper').ajaxPut(url, data).then(function () {
          _this6.controller.set('isOptionGroupUpdateEnabled', true);
          _this6.get('notifications').success(_this6.get('i18n').t('pages.optionGroup.optionGroupSection.priceV3.pricingIngestion.triggerSuccess'));
        }).catch(function () {
          _this6.get('notifications').error(_this6.get('i18n').t('pages.optionGroup.optionGroupSection.priceV3.pricingIngestion.triggerFailure'));
        }).finally(function () {
          _this6.controller.set('updatingPriceWithIngestion', false);
        });
      },
      savePriceGroup: function savePriceGroup(priceGroup, pgIndex) {
        var _this7 = this;

        var optionGroupId = this.controller.get('optionGroup.id');
        this.controller.set('lastUpdated', _optionGroup.SECTIONS.V3_PRICING);
        this.removeDispersedTickets(priceGroup);
        var isNewPg = priceGroup.get('isNew');
        this.validatePriceFields(priceGroup).then(function () {
          var changedAttributes = Object.keys(_this7.controller.get('optionGroup.attributes').changedAttributes());
          var hasPriceAttrsChanged = _array2.default.intersection(changedAttributes, PRICE_ATTRS_IN_OPTION_GROUP).length > 0;
          if (hasPriceAttrsChanged) {
            return _this7.controller.get('optionGroup').save({ adapterOptions: { section: _optionGroup.SECTIONS.DETAILS } });
          } else {
            return;
          }
        }).then(function () {
          _this7.controller.set('savingPg', true);
          var optionGroup = _this7.controller.get('optionGroup');
          var isLimitedInventory = optionGroup.get('attributes.availabilityType') === _optionGroup.AVAILABILITY_TYPES.LIMITED;
          var isStartTime = optionGroup.get('attributes.scheduleType') === _optionGroup.SCHEDULE_TYPES.START;
          var isMultiDay = optionGroup.get('attributes.scheduleType') === _optionGroup.SCHEDULE_TYPES.MULTI_DAY;
          // this is only to fix the specific case for editing start time && limited inventory
          // further discussion is pending to refactor this
          if (isLimitedInventory && (isStartTime || isMultiDay)) {
            priceGroup.get('availabilities').forEach(function (availabilities) {
              availabilities.get('availability').forEach(function (dayAvail) {
                var isAvailable = dayAvail.get('capacity') > 0;
                dayAvail.set('available', isAvailable);
              });
            });
          }

          var currentPg = _this7.controller.get('currentPg');
          return priceGroup.save({
            adapterOptions: {
              offerId: optionGroupId,
              oldPg: currentPg,
              isOfferGroup: true,
              isLimitedInventory: isLimitedInventory,
              travelStartPrev: _this7.controller.get('travelStartPrev'),
              travelEndPrev: _this7.controller.get('travelEndPrev')
            } });
        }).then(function (priceGroup) {
          _this7.controller.set('isOptionGroupUpdateEnabled', true);
          if (!_this7.controller.get('hasExistingPrices')) {
            _this7.controllerFor('activity.option-group').toggleProperty('refreshSideNav');
            _this7.controller.set('hasExistingPrices', true);
          }
          //ember isn't updating id's of new price (ticket-details)
          //and creates new ones
          //this removes the stale prices (ticket-detail) after save
          var pricesToRemove = priceGroup.get('prices').map(function (price) {
            if (!price.get('id')) {
              return price;
            }
          }).compact();
          pricesToRemove.forEach(function (priceToRemove) {
            return priceToRemove.rollbackAttributes();
          });

          _this7.controller.set('editingPg', false);
          _this7.controller.set('editingPgIndex', null);
          _this7.controller.set('dirty', false);

          _this7.controller.set('saveStep', 2);
          // notification on saving prices disabled for v4 option group page
          // this.get('notifications').success(this.get('i18n').t('components.alert.saveSuccess'));
          if (_this7.get('featureFlags').checkEnabled('PriceUpdate') && !isNewPg) {
            priceGroup.set('priceUpdateAlertVisible', true);
          }
        }).catch(function (pricingErrors) {
          Ember.Logger.error('error saving price group', pricingErrors);
          _this7.controller.set('editingPgIndex', pgIndex);
          _this7.controller.set('pricingErrors', Object.assign({}, pricingErrors));
          (0, _formValidator.scrollToFirstError)();
        }).finally(function () {
          _this7.controller.set('savingPg', false);
        });
      },
      editPriceGroup: function editPriceGroup(pgIndex, pg) {
        this.controller.set('editingPg', true);
        this.controller.set('currentPg', pg);
        this.controller.set('travelStartPrev', pg.get('travelStart').toString());
        this.controller.set('travelEndPrev', pg.get('travelEnd').toString());
        this.controller.set('editingPgIndex', pgIndex);
      },
      cancelEditingPg: function cancelEditingPg() {
        this._cancelEditingPg();
      },
      willTransition: function willTransition() {
        //reset platform name
        var activityExternalRefRes = this.controllerFor('activity').get('activityExternalRefRes');
        this.get('nameInHeader').setPartnerActivityOrOfferId(activityExternalRefRes, activityExternalRefRes.partnerActivityId);

        if (this.controller.get('editingPg') && this.controller.get('v3OptionGroup.prices')) {
          var pgIdx = this.controller.get('editingPgIndex');
          var pg = this.controller.get('v3OptionGroup.prices').objectAt(pgIdx);

          if (pg) {
            var isNew = pg.get('isNew');

            if (isNew) {
              pg.destroyRecord();
            } else {
              pg.rollbackAttributes();
            }
          }
        }
        //for tests
        delete window.pageName;
        // this.resetToInitialState();
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'optionGroup';
        this.controller.set('errors', '');
        this.controller.set('pricingErrors', '');
        this.controller.set('editingPgIndex', null);
        this.controller.set('saving', false);
        this.controller.set('savingTicket', false);
        this.controller.set('savingPg', false);
        return true;
      }
    }
  });
});