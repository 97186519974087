define('lxso/utils/overlap-failure-reducer', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.overlapFailureReducer = overlapFailureReducer;
  function overlapFailureReducer(failures, errorMessage) {
    var uniqueFailures = [];
    for (var i = 0; i < failures.length; i++) {
      if (!_lodash.default.includes(uniqueFailures, failures[i])) {
        uniqueFailures.push(failures[i]);
      }
    }
    var failureString = uniqueFailures.join(',');
    return failureString + ':;:' + errorMessage;
  }
});