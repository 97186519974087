define('lxso/validators/custom-promotion-details-fund', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomPromotionDetailsFund = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var errorMessage = model.get('i18n').t('' + options.messageKey).string;
      var expediaFund = model.get('expediaFund') || 0;
      var supplierFund = model.get('supplierFund') || 0;
      var totalDiscount = model.get('totalDiscount') || 0;

      if (model.expediaFund && model.supplierFund && !options.isAbsolute) {
        /*
        * Decimal place validation
        */
        if (parseFloat(expediaFund).toString().split('.')[1] && parseFloat(expediaFund).toString().split('.')[1].length > 2 || parseFloat(supplierFund).toString().split('.')[1] && parseFloat(supplierFund).toString().split('.')[1].length > 2) {
          return model.get('i18n').t('pages.promotion.promotionDetails.validationMsg.twoDecimalPlacesAllowed').string;
        }

        /*
        *  Sum should be equal to 100 when split funded and discount type is percentage
        */

        if (parseInt(model.get('expediaFund') * 100) / 100 + parseInt(model.get('supplierFund') * 100) / 100 !== 100) {
          return model.get('i18n').t('pages.promotion.promotionDetails.validationMsg.supplierExpediaSumInvalid').string;
        }
      } else if (model.expediaFund && model.supplierFund && options.isAbsolute) {
        if (parseFloat(expediaFund).toString().split('.')[1] && parseFloat(expediaFund).toString().split('.')[1].length > 2 || parseFloat(supplierFund).toString().split('.')[1] && parseFloat(supplierFund).toString().split('.')[1].length > 2) {
          return model.get('i18n').t('pages.promotion.promotionDetails.validationMsg.twoDecimalPlacesAllowed').string;
        }

        /*
         *  Sum should be equal to total discount when split funded and discount type is absolute
         */

        var sumAmount = parseFloat(expediaFund) + parseFloat(supplierFund);

        if (parseFloat(sumAmount.toFixed(2)) !== parseFloat(totalDiscount)) {
          return model.get('i18n').t('pages.promotion.promotionDetails.validationMsg.shouldBeEqualToSupplierPlusExpedia').string;
        }
      }

      return true;
    }
  });

  CustomPromotionDetailsFund.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = CustomPromotionDetailsFund;
});