define('lxso/models/fragments/option-group/cancellation-policy', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    cutoffInHours: {
      descriptionKey: 'pages.optionGroup.optionGroupSection.details.cancellationPolicy.cutoffHours',
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('number', {
        gte: 0,
        allowString: true,
        messageKey: 'errors.customMessages.offers.mustBeNumber'
      }), (0, _validation.validator)('option-group-cancellation-cutoff', {
        volatile: true,
        messageKey: 'pages.optionGroup.optionGroupSection.details.validationMessages.cancellationCutoff'
      })]
    },
    cutoffInHoursNew: {
      descriptionKey: 'pages.optionGroup.optionGroupSection.details.cancellationPolicy.cutoffHours',
      validators: [(0, _validation.validator)('new-option-group-cancellation-cutoff', {
        volatile: true
      })]
    }
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    policyType: _emberData.default.attr('string'),
    cutoffInHours: _emberData.default.attr('number')
  });
});