define('lxso/routes/platform', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/platform-settings/platform-details', 'lxso/mixins/require-permission', 'lxso/mixins/platform-settings/operational', 'lxso/mixins/platform-settings/url-configurations', 'lxso/mixins/platform-settings/authentication'], function (exports, _authenticatedRouteMixin, _featureFlag, _platformDetails, _requirePermission, _operational, _urlConfigurations, _authentication) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, _urlConfigurations.default, _operational.default, _platformDetails.default, _authentication.default, {
    requiredPermission: 'PlatformRead',
    i18n: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      if (params.platformId === 'new') {
        return {
          platform: this.store.createRecord('platform')
        };
      } else {
        var platform = this.store.findRecord('platform', params.platformId, { reload: true });
        return new Ember.RSVP.hash({
          platform: platform,
          integrationUrl: platform.then(function (platform) {
            if (platform.get('hasIntegrationUrlConfiguration')) {
              return platform.get('integrationUrlConfiguration');
            } else {
              var integrationUrlConfiguration = _this.store.createRecord('platformUrlConfiguration', {
                platform: platform,
                environment: 'Integration'
              });
              return integrationUrlConfiguration;
            }
          }),
          productionUrl: platform.then(function (platform) {
            if (platform.get('hasProductionUrlConfiguration')) {
              return platform.get('productionUrlConfiguration');
            } else {
              var productionUrlConfiguration = _this.store.createRecord('platformUrlConfiguration', {
                platform: platform,
                environment: 'Production'
              });
              return productionUrlConfiguration;
            }
          }),
          platformSupplierBranches: platform.then(function (platform) {
            return platform.get('platformSupplierBranches');
          })
        });
      }
    },
    setupController: function setupController(ctrl, model) {
      var _this2 = this;

      this.controller.set('errors', []);
      this.initPlatformDetails(model.platform);
      var isNewPlatform = model.platform.get('isNew');
      ctrl.setProperties({
        isNew: isNewPlatform,
        isAnySectionEditing: Ember.computed.or('editingPlatformSettingsOperational', 'editingIntegration', 'editingProduction', 'editingAuthentication', 'editingPlatformDetails')
      });

      if (!isNewPlatform) {
        this.initPlatformOperational();
        this.initUrlConfiguration(model);
        this.initAuthentication(model);
      }
      Ember.run.next(function () {
        _this2.setupStep(isNewPlatform);
      });
    },
    setupStep: function setupStep(isNew) {
      if (isNew) {
        var link = document.getElementsByClassName('title-main toggle-trigger platform-details-section');
        $(link).addClass('open');
      } else {
        var _link = document.getElementsByClassName('title-main toggle-trigger');
        $(_link).addClass('open');
      }
    },
    resetSections: function resetSections() {
      this.controller.set('editingPlatformSettingsOperational', false);
      this.controller.set('editingIntegration', false);
      this.controller.set('editingProduction', false);
      this.controller.set('editingAuthentication', false);
      this.controller.set('editingPlatformDetails', false);
    },
    resetController: function resetController() {
      var platform = this.controller.get('platform');
      var integrationUrls = this.controller.get('integrationUrls');
      var productionUrls = this.controller.get('productionUrls');

      if (platform && platform.get('hasDirtyAttributes')) {
        this.controller.get('platform').rollbackAttributes();
      }

      if (integrationUrls && integrationUrls.get('hasDirtyAttributes')) {
        integrationUrls.rollbackAttributes();
      }

      if (productionUrls && productionUrls.get('hasDirtyAttributes')) {
        productionUrls.rollbackAttributes();
      }

      this.resetSections();
    },


    actions: {
      openStep: function openStep(step, stepClass) {
        var isNew = this.controller.get('isNew');
        var link = document.getElementsByClassName('title-main toggle-trigger ' + stepClass);
        if (!isNew || isNew && stepClass === 'details-section') {
          this.controller.toggleProperty(step);
          $(link).toggleClass('open');
        }
      },
      resetCurrentSection: function resetCurrentSection() {
        var sectionToBeEdited = this.controller.get('sectionToBeEdited');
        if (this.controller.get('editingPlatformDetails')) {
          this.send('cancelPlatformDetails');
        } else if (this.controller.get('editingPlatformSettingsOperational')) {
          this.send('cancelPlatformOperational');
        } else if (this.controller.get('editingIntegration')) {
          this.send('cancelEditPlatformUrlSection', 'integration');
        } else if (this.controller.get('editingProduction')) {
          this.send('cancelEditPlatformUrlSection', 'production');
        } else if (this.controller.get('editingAuthentication')) {
          this.send('cancelAuthenticationSupplierBranch');
        }

        switch (sectionToBeEdited) {
          case 'platformDetails':
            this.send('editPlatformDetails');
            break;
          case 'operational':
            this.send('editPlatformOperational');
            break;
          case 'integration':
          case 'production':
            this.send('editPlatformUrlSection', sectionToBeEdited);
            break;
          case 'authentication':
            this.send('editAuthenticationSupplierBranch', this.controller.get('authSuppBranchToBeEdited'));
            break;
        }

        this.controller.set('sectionToBeEdited', null);
      },
      cancelSectionEdit: function cancelSectionEdit() {
        this.controller.set('sectionToBeEdited', null);
      }
    }
  });
});