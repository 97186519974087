define('lxso/services/supplier-branch', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    nameInHeader: Ember.inject.service(),
    activityMigrateSupport: Ember.inject.service(),

    /**
     * Returns or fetches the Supplier Branch ID for the current user.
     * Stores the result on the simple auth session object for future checks.
     * Will reject the request if there is more than one supplier branch or not found.
     *
     * @return {integer} Supplier ID
     */
    getId: function getId() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var supplierBranch = _this.getSupplierBranch();

        // Resolve right now, based on the session data
        if (supplierBranch) {
          resolve(supplierBranch);
        } else {
          // Otherwise, look up the Supplier Branch ID
          var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/userManagement/supplier_branch_ids';
          _this.get('ajaxWrapper').ajaxGet(url, null, {
            cache: false,
            xhrFields: {
              withCredentials: true
            }
          }).then(function (result) {
            var supplierBranchList = result && result.supplier_branch_id_list ? result.supplier_branch_id_list : [];
            return _this.loadActiveSupplierBranches(supplierBranchList);
          }).then(function (activeSupplierBranches) {
            var activeSupplierBranchIds = activeSupplierBranches.map(function (item) {
              return item.supplier_branch_id;
            });

            // Save the result to the session store
            _this.updateSupplierBranches(activeSupplierBranchIds);

            // Resolve the supplier branch
            var supplierBranch = _this.getSupplierBranch();
            // Resolve, based on the session data
            if (supplierBranch) {
              resolve(supplierBranch);
            } else {
              reject('Supplier Branch Required');
            }
          }).catch(function (reason) {
            if (reason.status === 300) {
              _this.setSuperUser();
              resolve();
            } else {
              reject(reason);
            }
          });
        }
      });
    },
    loadActiveSupplierBranches: function loadActiveSupplierBranches(supplierBranchIds) {
      var statusesToLoad = ['Active', 'SuspendedPaused', 'SuspendedExpedia'];
      return this.getSupplierBranchData(supplierBranchIds).then(function (supplierBranches) {
        return supplierBranches.filter(function (branch) {
          return statusesToLoad.includes(branch.status);
        });
      });
    },
    getSupplierBranchData: function getSupplierBranchData(ids) {
      var _this2 = this;

      var branchPromises = [];

      ids.forEach(function (branch) {
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/supplier_branches/' + branch;
        branchPromises.push(_this2.get('ajaxWrapper').ajaxGet(url, null, {
          xhrFields: {
            withCredentials: true
          }
        }));
      });
      return Ember.RSVP.all(branchPromises);
    },
    getActivitySupplierBranchData: function getActivitySupplierBranchData(activityId) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.get('activityMigrateSupport').activityGet(activityId, 'fields=basic', false).then(function (activity) {
          var supplierBranchId = activity.supplier_branch.supplier_branch_id;
          resolve(supplierBranchId);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    getSelectedBranch: function getSelectedBranch() {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var supplierBranch = _this4.get('session.data.authenticated.selectedBranch');

        // Resolve right now, based on the session data
        if (supplierBranch) {
          resolve(supplierBranch);
        } else {
          // Otherwise, look up the Supplier Branch ID
          reject('No supplier branch selected');
        }
      });
    },
    getAllBranchesBySupplierID: function getAllBranchesBySupplierID(supplierId) {
      var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/suppliers/' + supplierId;
      var supplierBranches = this.get('ajaxWrapper').ajaxGet(url, null, {
        xhrFields: {
          withCredentials: true
        }
      }).then(function (supplierData) {
        var branches = supplierData.branches;
        return branches;
      });

      return Ember.RSVP.resolve(supplierBranches);
    },


    /**
     * Get the supplier branch in the session store
     * Return false if the array contains a single supplier ID
     */
    getSupplierBranch: function getSupplierBranch() {
      var supplierBranches = this.get('session.data.authenticated.supplierBranches');

      // Return the Supplier Branch ID if the supplier branch list is set and has a length of 1
      if (supplierBranches) {
        return supplierBranches;
      } else {
        // Otherwise, return false
        return false;
      }
    },
    setSuperUser: function setSuperUser() {
      var sessionData = this.get('session.data');

      Ember.set(sessionData.authenticated, 'isSuperUser', true);
      this.get('session.store').persist(sessionData);
    },
    updateSelectedBranch: function updateSelectedBranch(branch, supplierBranches) {
      var _this5 = this;

      var sessionData = this.get('session.data');

      Ember.set(sessionData.authenticated, 'selectedBranch', branch);

      var branchData = void 0;
      if (supplierBranches) {
        branchData = supplierBranches;
      } else {
        branchData = this.getSupplierBranchData([branch.supplier_branch_id]);
      }

      branchData.then(function (supplierBranchData) {
        Ember.set(sessionData.authenticated, 'supplierBranchData', supplierBranchData[0]);
        _this5.get('nameInHeader').setSupplerDetailsChanged();
      });

      this.get('session.store').persist(sessionData);
    },
    updateSelectedBranchAsync: function updateSelectedBranchAsync(branch) {
      var _this6 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var sessionData = _this6.get('session.data');
        Ember.set(sessionData.authenticated, 'selectedBranch', branch);
        _this6.getSupplierBranchData([branch.supplier_branch_id]).then(function (supplierBranchData) {
          Ember.set(sessionData.authenticated, 'supplierBranchData', supplierBranchData[0]);
          _this6.get('nameInHeader').setSupplerDetailsChanged();
          _this6.get('session.store').persist(sessionData);
          resolve(supplierBranchData);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    updateSelectedBranchInSession: function updateSelectedBranchInSession(branch) {
      var sessionData = this.get('session.data');
      Ember.set(sessionData.authenticated, 'selectedBranch', branch);
      Ember.set(sessionData.authenticated, 'supplierBranchData', branch);
      this.get('nameInHeader').setSupplerDetailsChanged();
      this.get('session.store').persist(sessionData);
    },


    /**
     * Update supplier branches in the session store.
     * There isn't really a sanctioned way to do this with Ember Simple Auth, but
     * utilizing the `persist` method on the store we can do it.
     * We're storing this information on the user object so it will expire on logout.
     */
    updateSupplierBranches: function updateSupplierBranches(list) {
      var sessionData = this.get('session.data');
      Ember.set(sessionData.authenticated, 'supplierBranches', list);
      this.get('session.store').persist(sessionData);
    },


    /**
     * Remove supplier branches from the session store
     */
    deleteSupplierBranches: function deleteSupplierBranches() {
      var sessionData = this.get('session.data');
      delete sessionData.authenticated.supplierBranches;
      this.get('session.store').persist(sessionData);
    }
  });
});