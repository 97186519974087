define('lxso/serializers/offer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    featureFlags: Ember.inject.service(),
    attrs: {
      prices: { embedded: 'always' }
      // schedules: {embedded: 'always'}
    },
    // serialize(snapshot) {
    //taken from the example https://emberjs.com/api/data/classes/DS.Serializer.html#method_normalizeResponse
    //solves the warning issue about embedded records coming in undefined
    // const json = {
    //   id: snapshot.id
    // };

    // snapshot.eachRelationship((key, relationship) => {
    //   if (relationship.kind === 'belongsTo') {
    //     json[key] = snapshot.belongsTo(key, {id: true});
    //   } else if (relationship.kind === 'hasMany') {
    //     json[key] = snapshot.hasMany(key, {ids: true});
    //   }
    // });

    //   return json;
    // },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var lastSavedOfferAttrs = JSON.parse(localStorage.getItem('lastSavedOfferAttrs'));
      if (!payload.prices.length && lastSavedOfferAttrs && id == lastSavedOfferAttrs.offerOrGroupId) {
        payload.isPriceByVolume = !!lastSavedOfferAttrs.isVbp;
        payload.offerAttributes.isPriceByVolume = !!lastSavedOfferAttrs.isVbp;
      }
      return this._super.apply(this, arguments);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.offerAttributes) {
        payload.offerAttributes.languageInfo.languageIDs = payload.offerAttributes.languageInfo.languagesId;
      }

      if (payload.offerGroupId && this.get('featureFlags').checkEnabled('MultipleOptionsGroup')) {
        payload.id = payload.offerGroupId;
        payload.isMultipleOptionGroup = true;
        payload.offerAttributes = {
          isEligibleInPrebookPaths: payload.isEligibleInPrebookPaths,
          isHotelPickupEnabled: payload.isHotelPickupEnabled,
          isPriceByVolume: payload.isPriceByVolume,
          isPricingIngestion: payload.isPricingIngestion,
          mealType: payload.mealType,
          scheduleType: payload.scheduleType
        };
      }

      if (payload.offerDetails) {
        payload.durationInMinutes = payload.offerDetails.length ? payload.offerDetails[0].durationInMinutes : 0;
        payload.offerDetails = payload.offerDetails.map(function (offerDetail) {
          return Object.assign(offerDetail, {
            startTime: offerDetail.startTime ? offerDetail.startTime.slice(0, 5) : '',
            endTime: offerDetail.endTime ? offerDetail.endTime.slice(0, 5) : ''
          });
        });
      }

      if (payload.shortTitle === null || payload.shortTitle === '') {
        payload.shortTitle = payload.title;
      }

      //set margin for tickets in each price group
      if (payload.prices && payload.prices.length) {
        payload.prices.forEach(function (priceArr) {
          priceArr.prices.forEach(function (price) {
            var netPrice = price.netPrice;
            var retailPrice = price.retailPrice;
            price.margin = ((1 - netPrice / retailPrice) * 100).toFixed(2);
          });
        });
      }
      return this._super.apply(this, arguments);
    }
  });
});