define('lxso/serializers/activity', ['exports', 'ember-data', 'lxso/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, { //jsonAPIserializer is what ember wants by default
    //jsonapi.org for some docs
    isNewSerializerAPI: true,
    primaryKey: 'activity_id',
    attrs: {
      offers: { embedded: 'always' }
    },
    featureFlags: Ember.inject.service(),

    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      // Add a links object to find associated assets.
      // Usually this would be an actual url, however, we need to dynamically
      // query our API for the Media Vault API key and this method won't handle a promise.
      // Instead, we'll place a dummy value here to fool the adapter into running
      // the hasMany method in `adapters/activity.js`.
      // We'll determine the actual url at that point.


      //makes ajax call here
      //adding strings to the payload here. When a single response comes back,
      // we add these items so ember knows where to find them

      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
      payload.links = {
        assets: 'x',
        supplier_branch_full: domain + '/supplier_branches/' + payload.supplier_branch.supplier_branch_id,
        ticketTypes: domain + '/activities/' + payload.activity_id + '/ticket_types'
      };

      var isContentAndLogisticsMigratedToV4 = payload.migrationStatus && payload.migrationStatus.content === true && payload.migrationStatus.logistics === true;
      var isMigrateOverrideEnabled = this.get('featureFlags').checkEnabled('V4ContentAndLogisticsMigrationOverride');
      payload.isContentAndLogisticsMigratedToV4 = isContentAndLogisticsMigratedToV4 || isMigrateOverrideEnabled;

      // payload.isMigrated = payload.operational.version === '4';
      // All activity APIs are not migrated together.
      // Currently we can not use v4 APIs for all API end points even though activity is version 4
      return this._super.apply(this, arguments);
    }
  });
});