define('lxso/utils/change-history-details-display-modes/date', ['exports', 'lxso/utils/change-history-details-display-modes/generic'], function (exports, _generic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'delegator',
    diff: function diff(oldValue, newValue) {
      return {
        displayMode: _generic.default,
        oldValue: formatDate(oldValue),
        newValue: formatDate(newValue)
      };
    }
  };


  function formatDate(date) {
    return date && moment(date).format('YYYY-MM-DD');
  }
});