define('lxso/adapters/booking-questions', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    namespace: 'api/v3',

    query: function query(store, type, _query) {
      var branchId = _query.branchId;
      var url = this.host + '/' + this.namespace + '/bookingQuestionTemplate/supplierBranch/' + branchId;
      return this.ajax(url, 'GET');
    },
    findRecord: function findRecord(store, type, id) {
      var url = this.host + '/' + this.namespace + '/bookingQuestionTemplate/' + id;
      return this.ajax(url, 'GET');
    },
    createRecord: function createRecord(store, type, snapshot) {
      var url = this.host + '/api/v3/bookingQuestionTemplate';
      var data = {
        'name': snapshot._attributes.name,
        'supplierBranchId': snapshot._attributes.supplierBranchId,
        'bookingQuestionDetails': snapshot.adapterOptions.ungroupedQuestions,
        'bookingQuestionOfferDetails': snapshot.record.offerDetails
      };
      return this.ajax(url, 'POST', { data: data });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var url = this.host + '/' + this.namespace + '/bookingQuestionTemplate/' + snapshot.id;
      var data = {
        'name': snapshot._attributes.name,
        'bookingQuestionTemplateId': parseInt(snapshot.id),
        'supplierBranchId': snapshot._attributes.supplierBranchId,
        'bookingQuestionDetails': snapshot.adapterOptions.ungroupedQuestions,
        'bookingQuestionOfferDetails': snapshot.adapterOptions.groupedOfferDetails
      };
      return this.ajax(url, 'PUT', { data: data });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var url = this.host + '/' + this.namespace + '/bookingQuestionTemplate/' + snapshot.id;
      return this.ajax(url, 'DELETE');
    }
  });
});