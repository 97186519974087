define('lxso/components/lx-ckeditor/component', ['exports', 'lodash/string', 'npm:showdown', 'npm:to-markdown', 'lxso/mixins/example-modal'], function (exports, _string, _npmShowdown, _npmToMarkdown, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_exampleModal.default, {
    classNames: 'lx-ckeditor',
    showWarning: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var languagesList = [];
      var hasLangMentioned = false;
      var isMultipleOptionGroup = this.get('isMultipleOptionGroup');
      if (this.get('languages') && this.get('languages').length) {
        languagesList = this.get('languages').map(function (lang) {
          return lang.label;
        });
      }
      var thisComponent = this;
      var textarea = this.element.querySelector('.lx-ckeditor-textarea');
      var editor = this.editor = CKEDITOR.replace(textarea, {
        toolbar: [['Bold', 'Italic'], ['BulletedList']],
        removePlugins: 'elementspath',
        resize_enabled: false,
        fillEmptyBlocks: false,
        bodyId: 'lx-ckeditor-body',
        scayt_autoStartup: true
      });

      // On instance ready hide toolbar, convert current value to html for display
      editor.on('instanceReady', function (e) {
        var value = _this.get('value');
        var toolbar = e.editor.element.$.nextSibling.children[1].firstChild;
        toolbar.style.display = 'none';

        if (value) {
          _this.toHtml(value);
          _this.getCount(e.editor.getData());
          var converted = _this.get('value');
          e.editor.setData(converted);
        } else {
          _this.getCount('');
        }
      });

      // Hide toolbar on blur
      editor.on('blur', function (e) {
        var toolbar = e.editor.element.$.nextSibling.children[1].firstChild;
        toolbar.style.display = 'none';
        $('.lx-ckeditor').removeClass('ck-editor-toolbar');
        thisComponent.sendAction('validateIfInvalid');
      });

      // Show toolbar on focus
      editor.on('focus', function (e) {
        if (_this.get('showToolbar')) {
          var toolbar = e.editor.element.$.nextSibling.children[1].firstChild;
          toolbar.style.display = 'block';
          $('.lx-ckeditor').addClass('ck-editor-toolbar');
        }
      });

      // Update value on change
      editor.on('change', function (e) {
        var editorData = e.editor.getData();
        _this.getCount(editorData);

        // Set 'htmlString' to current editor data
        _this.set('htmlString', editorData);

        // Convert to markdown for saving to DB
        _this.toMarkDown(editorData);

        if (isMultipleOptionGroup) {
          hasLangMentioned = false;
          languagesList.forEach(function (lang) {
            if (hasLangMentioned == false && editorData.toLowerCase().includes(lang.toLowerCase())) {
              hasLangMentioned = true;
            }
          });
          thisComponent.set('showWarning', hasLangMentioned);
        }

        if (_this.get('errors')) {
          thisComponent.sendAction('validateIfInvalid');
        }
      });

      // Stop allowing user to type and update value if character max has been reached
      editor.on('key', function (e) {
        var count = _this.getCount(e.editor.getData());
        var key = e.data.keyCode;
        thisComponent.sendAction('dirty');

        // prevent bold, italic, and underline if commands should be disabled.
        if (_this.get('disableCommands') && (key === 1114178 || key === 1114185 || key === 1114197)) {
          e.cancel();
        }
        // Lets user delete, or use keyboard shortcuts for copy, paste, and select all.
        if (key === 8 || key === 46 || key === 1114177 || key === 1114198 || key === 1114179) {
          return true;
        }
        return count <= 0 ? false : true;
      });

      // If user pastes too much text (text beyond character count), we want to truncate it
      editor.on('afterPaste', function (e) {
        var editorContent = _this.stripAddedChars(e.editor.getData());
        var maxAllowed = _this.get('max-count');
        var length = _this.stripHtml(editorContent).length;

        if (length > maxAllowed) {
          var returnString = '';
          var regularCharsCount = 0;
          var inTag = false;
          var i = 0;

          while (regularCharsCount < maxAllowed) {
            if (inTag === false && editorContent[i] !== '<') {
              returnString += editorContent[i];
              regularCharsCount++;
            } else if (editorContent[i] === '<') {
              returnString += editorContent[i];
              inTag = true;
            } else if (editorContent[i] === '>') {
              returnString += editorContent[i];
              inTag = false;
            } else if (inTag === true) {
              returnString += editorContent[i];
            }
            i++;
          }
          e.editor.setData(returnString);
        }
      });
    },
    stripHtml: function stripHtml(htmlString) {
      var htmlRegex = /(<([^>]+)>)/ig;
      var emptyLineRegex = /\*[\r\n]/gm;
      var tabRegex = /\t+/g;
      var spaceCloseTag = /&nbsp;<\/p>/g;
      var spaceCharRegex = /&nbsp;/g;
      var zero = /\u200B/g;

      var replaced = htmlString.replace(spaceCloseTag, '</p>').replace(htmlRegex, '').replace(spaceCharRegex, ' ').replace(emptyLineRegex, '').replace(tabRegex, '').replace(zero, '');

      return replaced;
    },


    // Primarily for paste event, formats html to be editor acceptible and not break the counter.
    stripAddedChars: function stripAddedChars(htmlString) {
      var spaceCloseTag = /&nbsp;<\/p>/g;
      var spaceCharRegex = /&nbsp;/g;
      var zero = /\u200B/g;

      var replaced = htmlString.replace(spaceCloseTag, '</p>').replace(spaceCharRegex, ' ').replace(zero, '');

      return replaced;
    },
    getCount: function getCount(editorData) {
      var valueStripped = this.stripHtml(editorData);
      var valueTrimmed = _string.default.trim(valueStripped);

      var length = valueTrimmed === undefined ? 0 : valueTrimmed.length;
      var remaining = this.get('max-count') - length;
      this.set('count', remaining);
      return remaining;
    },


    // Set value to markdown
    toMarkDown: function toMarkDown(htmlString) {
      // Remove spaces that will cause problems with the conversion
      var spaceCharRegex = /&nbsp;/g;
      var start = /<strong>\s+|<em>\s+/g;
      var end = /\s+(?=<\/strong>)|\s+(?=<\/em>)/g;

      var removed = htmlString.replace(start, '').replace(end, '').replace(spaceCharRegex, ' ');

      // Convert to markdown and set values
      var converted = (0, _npmToMarkdown.default)(removed);
      this.set('value', converted);
      this.set('markdown', converted);
    },


    // Convert markdown to html for display
    toHtml: function toHtml(markDown) {
      var converter = new _npmShowdown.default.Converter();
      var htmlString = converter.makeHtml(markDown);

      this.set('value', htmlString);
      this.set('htmlString', htmlString);
    },
    willDestroyElement: function willDestroyElement() {
      this.editor.destroy();
      this.editor = null;
    },


    actions: {
      onPlaceHolderFocus: function onPlaceHolderFocus() {
        this.editor.focus();
      }
    }
  });
});