define('lxso/services/name-in-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var pagesWithBranchName = [/^products/, /^createactivity/, /^activity\..*/, /^manage-availability\..*/, /^reviews$/, /^change-history$/, /^supplier-branch-settings$/, /^hotel-zone-list$/, /^hotel-zone$/, /^account-settings\..*/, /^promotion-list$/, /^promotion$/, /^campaign-list/, /^cancellation$/, /^booking-questions-list$/];

  var pagesWithSupplierName = [/^report$/];

  var pagesWithPlatformName = [/^products/, /^createactivity/, /^activity\..*/, /^manage-availability\..*/, /^reviews$/, /^change-history$/, /^supplier-branch-settings$/, /^cancellation$/, /^booking-questions-list$/, /^report$/, /^account-settings\..*/, /^promotion-list$/, /^promotion$/, /^campaign-list$/];

  var pagesWithPartnerIdAsPlatform = [/^activity\..*/];

  function hasMatch(xs, x) {
    return xs.some(function (pattern) {
      return x.match(pattern);
    });
  }

  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    permissions: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    threepSupplier: Ember.inject.service(),

    currentPage: '',
    pagesWithBranchName: pagesWithBranchName,
    pagesWithSupplierName: pagesWithSupplierName,
    pagesWithPlatformName: pagesWithPlatformName,
    supplierDetailsChanged: false,
    pagesWithPartnerIdAsPlatform: pagesWithPartnerIdAsPlatform,

    shouldDisplay: Ember.computed.or('displayBranch', 'displaySupplier'),

    displayBranch: Ember.computed('currentPage', function () {
      return hasMatch(this.get('pagesWithBranchName'), this.get('currentPage'));
    }),

    displaySupplier: Ember.computed('currentPage', function () {
      return hasMatch(this.get('pagesWithSupplierName'), this.get('currentPage'));
    }),

    displayPlatform: Ember.computed('currentPage', function () {
      var connectivityStatus = this.get('session.session.authenticated.selectedBranch.connectivity_status');
      var hasPlatformAccess = this.get('permissions').checkEnabled('PlatformRead');
      var platformInfoEnableForSuppliers = 'ConnectedPlatformInfoSuppliers';

      var showPlatformName = connectivityStatus && hasPlatformAccess && hasMatch(this.get('pagesWithPlatformName'), this.get('currentPage'));

      if (this.get('featureFlags').checkEnabled(platformInfoEnableForSuppliers)) {
        showPlatformName = connectivityStatus && hasMatch(this.get('pagesWithPlatformName'), this.get('currentPage'));
      }

      var showPartnerIdAsPlatform = hasMatch(this.get('pagesWithPartnerIdAsPlatform'), this.get('currentPage'));

      if (showPlatformName && !showPartnerIdAsPlatform) {
        this.setPlatformFromPFId();
      }

      return showPlatformName;
    }),

    name: Ember.computed('displayBranch', 'displaySupplier', 'supplierDetailsChanged', function () {
      var nameProperty = '';
      if (this.get('displayBranch') || this.get('displaySupplier') && this.get('threepSupplier').isSupplier3P()) {
        nameProperty = 'session.data.authenticated.selectedBranch.supplier_branch_name';
      } else if (this.get('displaySupplier')) {
        nameProperty = 'session.data.authenticated.selectedBranch.supplier_name';
      }
      return nameProperty && this.get(nameProperty);
    }),

    setPlatformFromPFId: function setPlatformFromPFId() {
      var _this = this;

      var platformId = this.get('session').get('session.authenticated.selectedBranch.platform_id');
      if (platformId) {
        this.get('store').find('platform', platformId).then(function (platform) {
          _this.set('platformName', platform.get('name'));
        });
      } else {
        this.set('platformName', undefined);
      }
    },

    /*
     * @param {platformData} contains details from external_reference api
     * and is passed from the activity route handler
     */
    setPartnerActivityOrOfferId: function setPartnerActivityOrOfferId(platformData, partnerId) {
      if (platformData && partnerId) {
        this.set('platformName', partnerId);
      } else {
        this.set('platformName', undefined);
      }
    },
    setNeededFor: function setNeededFor(route) {
      this.set('currentPage', this.pageName(route));
    },
    pageName: function pageName(route) {
      return route.map(function (x) {
        return x.name;
      }).get('lastObject');
    },
    setSupplerDetailsChanged: function setSupplerDetailsChanged() {
      this.toggleProperty('supplierDetailsChanged');
    }
  });
});