define('lxso/models/fragments/reports/bq-traveler-info', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _emberDataModelFragments, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    Name: _emberData.default.attr('string'),
    DOB: _emberData.default.attr('string'),
    Weight: _emberData.default.attr('string'),
    Height: _emberData.default.attr('string'),
    PassportNumber: _emberData.default.attr('string'),
    PassportNationality: _emberData.default.attr('string'),
    PassportExpirationDate: _emberData.default.attr('string')
  });
});