define('lxso/validators/find-duplicate-postal-codes', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FindDuplicatePostalCodes = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      'use strict';

      var postalCodes = model.get('postalCodes').sort();
      var errorValues = [];

      postalCodes.forEach(function (code, index) {
        if (code === postalCodes[index + 1]) {
          errorValues.push(code);
        }
      });

      if (errorValues.length > 0) {
        var values = errorValues.join(',');
        return values + ':;:is duplicate of a previously added code';
      } else {
        return true;
      }
    }
  });

  exports.default = FindDuplicatePostalCodes;
});