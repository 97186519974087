define('lxso/mixins/array-validation-model', ['exports', 'lodash/array'], function (exports, _array2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Mixin.create({
    validate: function validate(array, options, model, attribute) {
      'use strict';

      var errorIndexes = [];
      var errorString = void 0;
      var errorChars = [];
      var errorCharsJoined = '';
      for (var i = 0; i < array.length; i++) {
        var value = options.attributeName ? Ember.get(array[i], options.attributeName) : array[i];
        var result = this._super(value, options, model, attribute);
        if (typeof result === 'string') {
          errorIndexes.push(i);
          errorString = result;
        } else if ((typeof result === 'undefined' ? 'undefined' : _typeof(result)) === 'object') {
          if (options.composableErrorMessage === true) {
            errorString = result.errorText;
            errorIndexes.push(i);
            errorChars = _array2.default.union(errorChars, result.errorCharacters);
          }
        }
      }
      if (errorString && options.composableErrorMessage) {
        errorCharsJoined = errorChars.join(' ');
        errorString = errorString + ': ' + errorCharsJoined;
      }
      return errorString ? errorIndexes + ':;:' + errorString : true;
    }
  });
});