define('lxso/validators/og-ticket-type-restriction', ['exports', 'ember-cp-validations/validators/base', 'lxso/utils/overlap-failure-reducer'], function (exports, _base, _overlapFailureReducer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OgTicketTypeRestriction = _base.default.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    validate: function validate(restrictions, options, model, attribute) {
      var selectedTicketTypes = model.get('restrictions').filter(function (tt) {
        return tt.get('isSelected');
      });

      var errorMessage = void 0;
      var failures = [];

      //min tickets >  max
      if (!failures.length) {
        errorMessage = this.get('i18n').t('pages.optionGroup.tickets.errorMessages.maxTicketsLessThanMin');
        for (var i = 0; i < selectedTicketTypes.length; i++) {
          var ttA = selectedTicketTypes[i];
          var min = parseInt(ttA.get('minimumTicketsPerBooking'));
          var max = parseInt(ttA.get('maximumTicketsPerBooking'));
          if (min > max) {
            failures.push('ticketTypeAge' + ttA.get('label'));
          }
        }
      }

      if (failures.length > 0) {
        return (0, _overlapFailureReducer.overlapFailureReducer)(failures, errorMessage);
      } else {
        return true;
      }
    }
  });

  exports.default = OgTicketTypeRestriction;
});