define('lxso/models/fragments/promotion/promotion-details', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    activities: [(0, _validation.validator)('array-length', {
      gte: 1,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.promoDetailsProductList'
    })]
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    activities: _emberData.default.attr('array', { defaultValue: [] })
  });
});