define('lxso/components/option-group/details/component', ['exports', 'lxso/constants/option-group', 'lxso/utils/form-validator', 'lxso/utils/array-values'], function (exports, _optionGroup, _formValidator, _arrayValues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),
    classNameBindings: ['componentClasses'],
    optionGroupDisabledObserver: Ember.observer('isOptionGroupDisabled', function (sender, key, value, rev) {
      var isDisabled = this.get('isOptionGroupDisabled');
      this.setDescriptionReadOnly(isDisabled);
    }),
    durationInMinutesObserver: Ember.observer('optionGroup.attributes.durationDaysPart', 'optionGroup.attributes.durationHoursPart', 'optionGroup.attributes.durationMinutesPart', function () {
      if (this.get('optionGroup') && this.get('optionGroup.attributes')) {
        var daysPart = parseInt(this.get('optionGroup.attributes.durationDaysPart')) || 0;
        var hoursPart = parseInt(this.get('optionGroup.attributes.durationHoursPart')) || 0;
        var minutesPart = parseInt(this.get('optionGroup.attributes.durationMinutesPart')) % 60 || 0;
        var durationInMinutes = daysPart * 24 * 60 + hoursPart * 60 + minutesPart;
        this.set('optionGroup.attributes.durationInMinutes', durationInMinutes);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.setScheduleTypes();
      this.setMealTypes();
      this.setAvailabilityTypes();
      this.setCancellationPolicyTypes();
      this.setValidDaysAndHoursDropDown();
    },
    setScheduleTypes: function setScheduleTypes() {
      var scheduleTranslationBaseKey = 'pages.optionGroup.optionGroupSection.details.scheduleType.label';
      var isGTBranch = this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
      var schedules = isGTBranch ? _optionGroup.SCHEDULE_TYPES_ARRAY_GT : _optionGroup.SCHEDULE_TYPE_ARRAY;
      this.setRadioButtonGroupData(schedules, scheduleTranslationBaseKey, 'scheduleTypes');
      this.set('SCHEDULE_TYPES', _optionGroup.SCHEDULE_TYPES);
    },
    setMealTypes: function setMealTypes() {
      var mealTypesBaseKey = 'pages.optionGroup.optionGroupSection.details.addOns.label';
      this.setRadioButtonGroupData(_optionGroup.MEAL_TYPES_ARRAY, mealTypesBaseKey, 'mealTypes');
    },
    setAvailabilityTypes: function setAvailabilityTypes() {
      var availabilityTypesBaseKey = 'pages.optionGroup.optionGroupSection.details.ticketAvailabilityType.label';
      this.setRadioButtonGroupData(_optionGroup.AVAILABILITY_TYPES_ARRAY, availabilityTypesBaseKey, 'availabilityTypes');
    },
    setCancellationPolicyTypes: function setCancellationPolicyTypes() {
      var cancellationPolicyTypesBaseKey = 'pages.optionGroup.optionGroupSection.details.cancellationPolicy.label';
      this.setRadioButtonGroupData(_optionGroup.CANCELLATION_POLICY_TYPES_ARRAY, cancellationPolicyTypesBaseKey, 'cancellationPolicyTypes');
      this.set('CANCELLATION_POLICY_TYPES', _optionGroup.CANCELLATION_POLICY_TYPES);
    },
    setRadioButtonGroupData: function setRadioButtonGroupData(values, labelBaseKey, modelKey) {
      var _this = this;

      var labelValuePairsForRadioButtons = values.map(function (value) {
        return {
          label: _this.get('i18n').t(labelBaseKey + '.' + value),
          value: value
        };
      });
      this.set(modelKey, labelValuePairsForRadioButtons);
    },
    setValidDaysAndHoursDropDown: function setValidDaysAndHoursDropDown() {
      var possibleMultiDays = (0, _arrayValues.getArrayOfValues)(22);
      this.set('multiDaysOptions', possibleMultiDays);

      var possibleValidDays = (0, _arrayValues.getArrayOfValues)(30);
      possibleValidDays.push(60, 90);
      this.set('validDaysOptions', possibleValidDays);

      var possibleHours = (0, _arrayValues.getArrayOfValues)(100);
      this.set('validHoursOptions', possibleHours);
    },
    validateOptionGroupDetails: function validateOptionGroupDetails() {
      var bookingCutoffValidations = this.get('optionGroup.isNew') ? 'bookingCutoffInHoursNew' : 'bookingCutoffInHours';
      var cancellationCutoffValidations = [];
      if (this.get('optionGroup.cancellationPolicy.policyType') === _optionGroup.CANCELLATION_POLICY_TYPES.REFUNDABLE) {
        cancellationCutoffValidations = this.get('optionGroup.isNew') ? ['cutoffInHoursNew'] : ['cutoffInHours'];
      }
      return (0, _formValidator.validate)({ model: this.get('optionGroup'), attributes: ['shortTitle'] }, { model: this.get('optionGroup.attributes'), attributes: ['mealType', 'optionGroupDuration', 'validityDays'] }, { model: this.get('optionGroup.bookingCutoff'), attributes: [bookingCutoffValidations] }, { model: this.get('optionGroup.cancellationPolicy'), attributes: cancellationCutoffValidations });
    },
    _validateIfInvalid: function _validateIfInvalid() {
      var _this2 = this;

      if (this.get('errors')) {
        this.validateOptionGroupDetails().then(function () {
          _this2.set('errors', null);
        }).catch(function (errors) {
          _this2.set('errors', errors);
        });
      }
    },
    setDescriptionReadOnly: function setDescriptionReadOnly(isDisabled) {
      var descriptionEditor = CKEDITOR.instances['lx-ckeditor textarea-option-group-description'];
      if (descriptionEditor) {
        descriptionEditor.setReadOnly(isDisabled);
      }
    },
    resetDuration: function resetDuration() {
      this.set('optionGroup.attributes.durationInMinutes', 0);
      this.set('optionGroup.attributes.durationDaysPart', null);
      this.set('optionGroup.attributes.durationHoursPart', null);
      this.set('optionGroup.attributes.durationMinutesPart', null);
    },
    _setFormDirty: function _setFormDirty(isDirty) {
      this.set('isDirty', isDirty);
    },


    actions: {
      setFormDirty: function setFormDirty() {
        this._setFormDirty(true);
      },
      openStep: function openStep() {
        this.toggleProperty('isOptionGroupDetailsOpen');
      },
      validateIfInvalid: function validateIfInvalid() {
        this._validateIfInvalid();
      },
      handleOptionGroupTitleChange: function handleOptionGroupTitleChange() {
        this._setFormDirty(true);
        this._validateIfInvalid();
      },
      handleOptionGroupDaysPartChange: function handleOptionGroupDaysPartChange(event) {
        this._setFormDirty(true);
        var daysInput = parseInt(event.target.value);
        var days = isFinite(daysInput) ? daysInput : null;
        this.set('optionGroup.attributes.durationDaysPart', days);
        this._validateIfInvalid();
      },
      handleOptionGroupHoursPartChange: function handleOptionGroupHoursPartChange(event) {
        this._setFormDirty(true);
        var hoursInput = parseInt(event.target.value);
        var hours = isFinite(hoursInput) ? hoursInput : null;
        if (this.get('optionGroup.attributes.scheduleType') === _optionGroup.SCHEDULE_TYPES.START || this.get('optionGroup.attributes.scheduleType') === _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION) {
          this.set('optionGroup.attributes.durationHoursPart', hours % 24);
        } else {
          this.set('optionGroup.attributes.durationHoursPart', hours);
        }
        this._validateIfInvalid();
      },
      handleOptionGroupMinutesPartChange: function handleOptionGroupMinutesPartChange(event) {
        this._setFormDirty(true);
        var minutesInput = parseInt(event.target.value);
        var minutes = isFinite(minutesInput) ? minutesInput % 60 : null;
        this.set('optionGroup.attributes.durationMinutesPart', minutes);
        this._validateIfInvalid();
      },
      handleOptionGroupValidityDaysChange: function handleOptionGroupValidityDaysChange(event) {
        this._setFormDirty(true);
        var daysInput = parseInt(event.target.value);
        var days = isFinite(daysInput) ? daysInput : null;
        this.set('optionGroup.attributes.validityDays', days);
        this._validateIfInvalid();
      },
      handleBookingCutoffHoursChange: function handleBookingCutoffHoursChange(event) {
        this._setFormDirty(true);
        var cutoffInHours = parseInt(event.target.value);
        this.set('optionGroup.bookingCutoff.bookingCutoffInHours', cutoffInHours);
        this._validateIfInvalid();
      },
      handleCancellationCutoffHoursChange: function handleCancellationCutoffHoursChange(event) {
        this._setFormDirty(true);
        var cutoffInHours = parseInt(event.target.value);
        this.set('optionGroup.cancellationPolicy.cutoffInHours', cutoffInHours);
        this._validateIfInvalid();
      },
      handleScheduleTypeSelect: function handleScheduleTypeSelect(scheduleType) {
        this._setFormDirty(true);
        this.resetDuration();
        this.set('optionGroup.attributes.scheduleType', scheduleType);
        var isGTBranch = this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
        if (!isGTBranch) {
          if (scheduleType === _optionGroup.SCHEDULE_TYPES.START || scheduleType === _optionGroup.SCHEDULE_TYPES.MULTI_DAY) {
            this.set('optionGroup.bookingCutoff.cutoffType', _optionGroup.BOOKING_CUTOFF_TYPES.START_TIME);
          } else {
            this.set('optionGroup.bookingCutoff.cutoffType', _optionGroup.BOOKING_CUTOFF_TYPES.CLOSING_TIME);
          }
        }
        this.sendAction('setScheduleSupertype', scheduleType);
        this._validateIfInvalid();
      },
      handleMealTypeSelect: function handleMealTypeSelect(mealType) {
        this._setFormDirty(true);
        this.set('optionGroup.attributes.mealType', mealType);
        this._validateIfInvalid();
      },
      handleAvailabilityTypeSelect: function handleAvailabilityTypeSelect(availabilityType) {
        this._setFormDirty(true);
        this.set('optionGroup.attributes.availabilityType', availabilityType);
      },
      handleCancellationPolicyTypeSelect: function handleCancellationPolicyTypeSelect(cancellationPolicyType) {
        this._setFormDirty(true);
        this.set('optionGroup.cancellationPolicy.policyType', cancellationPolicyType);
        if (cancellationPolicyType === _optionGroup.CANCELLATION_POLICY_TYPES.NON_REFUNDABLE) {
          this.set('optionGroup.cancellationPolicy.cutoffInHours', null);
        }
      },
      handleHotelPickupCheckboxChange: function handleHotelPickupCheckboxChange() {
        this._setFormDirty(true);
        if (this.get('optionGroup.pickupEnabled')) {
          this.get('optionGroup').set('pickupInfo', {
            pickupType: _optionGroup.PICKUP_TYPES.OPTIONAL
          });
        }
      },
      saveOfferGroupDetails: function saveOfferGroupDetails() {
        var _this3 = this;

        this.validateOptionGroupDetails().then(function () {
          _this3.set('errors', null);
          _this3.saveOptionGroupDetails(_this3.get('optionGroup'));
        }).catch(function (errors) {
          _this3.set('errors', errors);
          (0, _formValidator.scrollToFirstError)();
        });
      }
    }
  });
});