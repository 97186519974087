define('lxso/components/photo-audit/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    uitk: Ember.inject.service(),

    actions: {
      delete: function _delete() {
        'use strict';

        this.sendAction('x-action');
      },
      changeApprovalStatus: function changeApprovalStatus(id, status) {
        'use strict';

        if (status === 'approved') {
          this.sendAction('approve-action', id);
        } else if (status === 'rejected') {
          this.sendAction('reject-action', id);
        }
      },
      selectHeroImage: function selectHeroImage() {
        'use strict';

        this.sendAction('selectHeroImage');
      },
      selectRejectReason: function selectRejectReason(reason) {
        this.sendAction('reject-reason-action', reason);
      },
      selectTag: function selectTag(tag) {
        this.sendAction('tag-set-action', tag);
      },
      editCaption: function editCaption() {
        this.set('oldCaption', this.get('caption'));
        this.set('oldTags', this.get('tags'));
        this.set('editingDetails', true);
      },
      saveCaption: function saveCaption() {
        this.set('isSavingCaption', true);
        var tagsArray = this.get('tags').toString().split(',');
        this.sendAction('saveCaption', this.get('caption'), tagsArray);
        this.set('tags', tagsArray);
        this.set('editingDetails', false);
        this.set('isSavingCaption', false);
      },
      cancelEditCaption: function cancelEditCaption() {
        this.set('caption', this.get('oldCaption'));
        this.set('tags', this.get('oldTags'));
        this.set('editingDetails', false);
      }
    }
  });
});