define('lxso/models/activity/cancellation-policy-model', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    //refundable, N hours
    minimum_hours: [(0, _validation.validator)('number', {
      integer: true,
      allowString: true,
      gte: 0
    }), (0, _validation.validator)(function (value, options, model) {
      if (model.get('refundable') === true) {
        //if this activity is refundable, then check that the hours is > 0 and a number
        if (parseInt(value) <= 0 || parseInt(value) === NaN) {
          return 'Must be a number greater than 0';
        } else {
          return true;
        }
      } else {
        //this activity is not refundable, we don't need to validate any user input
        return true;
      }
    })]
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    percent_refund: _emberData.default.attr('number', { defaultValue: 0 }),
    fee: _emberData.default.attr('number', { defaultValue: 0 }),
    minimum_hours: _emberData.default.attr('number', { defaultValue: 0 }),
    refundable: _emberData.default.attr('boolean', { defaultValue: false })
  });
});