define('lxso/models/fragments/campaign/campaign-details', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({

    campaignName: _emberData.default.attr('string'),
    bookStartDate: _emberData.default.attr('date-string'),
    bookEndDate: _emberData.default.attr('date-string'),
    travelStartDate: _emberData.default.attr('date-string'),
    travelEndDate: _emberData.default.attr('date-string'),
    joinByDate: _emberData.default.attr('date-string'),
    discount: _emberData.default.attr('number'),
    duration: _emberData.default.attr('string'),
    campaignId: _emberData.default.attr('number'),
    campaignHeader: _emberData.default.attr('string'),
    campaignBody: _emberData.default.attr('string'),
    sortIndex: _emberData.default.attr('number'),
    campaignType: _emberData.default.attr('string'),
    isPromotionAssociated: _emberData.default.attr('boolean')
  });
});