define('lxso/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'lxso/config/environment', 'lxso/utils/add-json-api-errors-to-payload', 'lxso/utils/http-status-codes'], function (exports, _emberData, _dataAdapterMixin, _environment, _addJsonApiErrorsToPayload, _httpStatusCodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    responseErrors: Ember.inject.service('response-errors'),
    sessionValidator: Ember.inject.service('session-validator'),

    authorizer: 'authorizer:lxpc',
    host: _environment.default.api.host,
    hostV4: _environment.default.api.hostV4,
    namespace: _environment.default.api.namespace,

    ajax: function ajax(url, type, options) {
      var opt = options || {}; // options may be undefined
      opt.crossDomain = true;
      opt.timeout = 120000;
      if (url.indexOf(_environment.default.api.host) !== -1 || url.indexOf(_environment.default.api.hostV4) !== -1) {
        opt.xhrFields = {
          withCredentials: true
        };
      }
      return this._super(url, type, opt);
    },
    pathForType: function pathForType(type) {
      return Ember.String.pluralize(Ember.String.underscore(type));
    },
    handleResponse: function handleResponse(status, headers, payload) {
      var _this = this,
          _arguments = arguments;

      // Add errors in JSON API format to the payload

      var payl = (0, _addJsonApiErrorsToPayload.default)(payload, status);

      // Add response errors to response errors service

      this.get('responseErrors').clearErrors();
      this.get('responseErrors').addErrors(payl, status);

      return this.get('sessionValidator').validate(status, function () {
        return _this._super.apply(_this, _arguments);
      }, function () {
        var argumentsWithoutStatus = [].concat(Array.prototype.slice.call(_arguments)).slice(1);
        return _this._super.apply(_this, [_httpStatusCodes.Unauthorized].concat(_toConsumableArray(argumentsWithoutStatus)));
      });
    }
  });
});