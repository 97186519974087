define('lxso/mixins/option-group/ticket-types-v4', ['exports', 'lxso/constants/option-group'], function (exports, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      openStep: function openStep(step) {
        switch (step) {
          case 'setup-tickets':
            {
              this.controller.toggleProperty('isTicketsOpen');
              break;
            }
        }
      },
      editTicketTypes: function editTicketTypes() {
        this.controller.set('editingTicketType', true);
      },
      cancelTicketTypes: function cancelTicketTypes(ticketTypesV4) {
        ticketTypesV4.rollbackAttributes();
        this.controller.set('editingTicketType', false);
      },
      saveTickets: function saveTickets(ticketTypesV4) {
        var _this = this;

        this.controller.set('lastUpdated', _optionGroup.SECTIONS.TICKETS);
        this.controller.set('isSavingTicketTypes', true);
        this.controller.get('ticketTypesV4').save().then(function () {
          _this.controller.set('editingTicketType', false);
          _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
        }).finally(function () {
          _this.controller.set('isSavingTicketTypes', false);
        });
      }
    }
  });
});