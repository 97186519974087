define('lxso/validators/option-group-cancellation-cutoff', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OptionGroupCancellationCutoff = _base.default.extend({
    i18n: Ember.inject.service(),
    permissions: Ember.inject.service(),

    validate: function validate(value, options, model, attribute) {
      'use strict';

      var i18n = this.get('i18n');
      var isInternal = this.get('permissions').checkEnabled('V3InternalSettings');
      var changedAttributes = model.changedAttributes();
      var previousValue = changedAttributes.cutoffInHours ? changedAttributes.cutoffInHours[0] : 0;
      var validationError = isInternal ? i18n.t('pages.optionGroup.optionGroupSection.details.validationMessages.cancellationCutoff1440').string : i18n.t('pages.optionGroup.optionGroupSection.details.validationMessages.cancellationCutoff').string;
      if (isInternal) {
        if (value > 1440) {
          return validationError;
        }
      } else {
        if (previousValue && value > previousValue) {
          return validationError;
        }
      }

      return true;
    }
  });

  exports.default = OptionGroupCancellationCutoff;
});