define('lxso/services/js-xlsx', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    createXlsxFile: function createXlsxFile(data, sheetName, fileName) {
      var ws = XLSX.utils.json_to_sheet(data);
      exportExcel(ws, sheetName, fileName);
    },
    createXlsxFromArrayOfArrays: function createXlsxFromArrayOfArrays(aoa, sheetName, fileName) {
      var ws = XLSX.utils.aoa_to_sheet(aoa);
      exportExcel(ws, sheetName, fileName);
    }
  });


  function exportExcel(ws, sheetName, fileName) {
    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* write workbook and force a download */
    XLSX.writeFile(wb, fileName);
  }
});