define('lxso/validators/time-format', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TimeFormat = _base.default.extend({
    validate: function validate(value, options, model) {
      var errorMessage = model.get('i18n').t('errors.customMessages.offers.invalidTime').string;

      if (typeof value != 'string') {
        return errorMessage;
      }

      var startTimePieces = value.split(':');
      var hours = startTimePieces[0];
      var minutes = startTimePieces[1];

      if (hours < 0 || hours > 23 || isNaN(hours) || minutes < 0 || minutes > 59 || !minutes || isNaN(minutes)) {
        return errorMessage;
      } else {
        return true;
      }
    }
  });

  exports.default = TimeFormat.extend();
});