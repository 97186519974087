define('lxso/helpers/convert-minutes-to-hours', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convertMinutesToHours = convertMinutesToHours;
  function convertMinutesToHours(params) {
    return params[0] ? Math.floor(params[0] / 60) : params[1];
  }

  exports.default = Ember.Helper.helper(convertMinutesToHours);
});