define('lxso/initializers/inject-notifications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'notifications', 'service:notification-messages-service');
    application.inject('component', 'notifications', 'service:notification-messages-service');
  }

  exports.default = {
    name: 'inject-notifications',
    initialize: initialize
  };
});