define('lxso/models/supplier-branch', ['exports', 'ember-data', 'ember-api-actions', 'lxso/utils/validation', 'lxso/utils/auto-renew-rate'], function (exports, _emberData, _emberApiActions, _validation, _autoRenewRate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    baseMargin: [(0, _validation.validator)('number', {
      gte: 0,
      allowString: true,
      messageKey: 'errors.customMessages.supplierBranch.marginTooSmall'
    }), (0, _validation.validator)('number', {
      lte: 100,
      allowString: true,
      messageKey: 'errors.customMessages.supplierBranch.marginTooBig'
    }), (0, _validation.validator)('format', {
      regex: /^\d+(\.\d{1,2})?$/,
      messageKey: 'errors.customMessages.supplierBranch.marginWrongFormat'
    })],
    status: [(0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'errors.customMessages.offers.noSelectionMade'
    })],
    affiliate_id: [(0, _validation.validator)('number', {
      gte: 1,
      allowString: true,
      allowBlank: true,
      descriptionKey: 'pages.supplierBranchSettings.supplierBranchSettings.accountingVendorID'
    }), (0, _validation.validator)('presence', {
      presence: true,
      allowBlank: false,
      descriptionKey: 'pages.supplierBranchSettings.supplierBranchSettings.accountingVendorID',
      disabled: Ember.computed.readOnly('model.isNew')
    })],
    supplier_id: [(0, _validation.validator)('number', {
      gte: 1,
      allowString: true,
      allowBlank: false,
      messageKey: 'errors.customMessages.supplierBranch.selectValidSupplier'
    })],
    salesforce_code: [(0, _validation.validator)('presence', {
      presence: true,
      descriptionKey: 'pages.supplierBranchSettings.supplierBranchSettings.salesforceID'
    }), (0, _validation.validator)('length', {
      max: 18,
      allowString: true,
      messageKey: 'errors.customMessages.supplierBranch.salesforceTooLong'
    })],
    supplier_branch_name: [(0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'pages.newSupplierBranch.validation.supplierBranchName'
    })],
    default_timezone: [(0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'pages.newSupplierBranch.validation.timezone'
    })],
    currency_type: [(0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'pages.newSupplierBranch.validation.currencyType'
    })],
    supplier_branch_type: [(0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'pages.newSupplierBranch.validation.currencyTyoe'
    })],
    isAutoRenewRateEnabled: [(0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'errors.customMessages.offers.noSelectionMade'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    supplier_branch_name: _emberData.default.attr('string'),
    ingestedFlags: _emberData.default.attr('array'),
    supplier_support: _emberData.default.hasMany('supplier-branch/supplier-support', { async: false }),
    schedules: _emberData.default.hasMany('schedule', { async: true }), // Defined in serializer
    hotelZones: _emberData.default.hasMany('hotel-zone', { async: true }),
    baseMargin: _emberData.default.attr('string'),
    platform: _emberData.default.belongsTo('platform'),
    connectivity_status: _emberData.default.attr('boolean'),
    notification_settings: _emberData.default.hasMany('supplier-branch/notification-setting', { async: false }),
    supplier_id: _emberData.default.attr('number'),
    supplier_name: _emberData.default.attr('string'),
    status: _emberData.default.attr('string', { defaultValue: 'Inactive' }),
    currency_type: _emberData.default.attr('string'),
    supplier_branch_type: _emberData.default.attr('string'),
    default_timezone: _emberData.default.attr('string'),
    vendor_branch_id: _emberData.default.attr('number', { defaultValue: 0 }),
    vendor_id: _emberData.default.attr('string', { defaultValue: 0 }),
    affiliate_id: _emberData.default.attr('string', { defaultValue: '' }),
    salesforce_code: _emberData.default.attr('string'),
    company_code: _emberData.default.attr('number', { defaultValue: 10122 }),
    invoice_required: _emberData.default.attr('boolean', { defaultValue: false }),
    pay_date_basis: _emberData.default.attr('string', { defaultValue: 'Activity Date' }),
    primary_dm: _emberData.default.attr('string'),
    partner_id: _emberData.default.attr('number'),
    partner_supplier_branch_id: _emberData.default.attr('string'),

    updatedDate: _emberData.default.attr('string'),
    api_key: _emberData.default.attr('string'),
    externalSupplierBranchId: _emberData.default.attr('string'),
    platformId: _emberData.default.attr('number'),
    auto_renew_rate_enabled: _emberData.default.attr('boolean', { defaultValue: false }),

    autoRenewRateStatusText: Ember.computed('auto_renew_rate_enabled', function () {
      return (0, _autoRenewRate.getAutoRateRenewLabel)(this.get('auto_renew_rate_enabled'));
    }),

    activities: (0, _emberApiActions.instanceOp)({
      path: 'activities',
      type: 'get'
    }),

    updateSupplierSupport: (0, _emberApiActions.instanceOp)({
      path: 'support',
      type: 'put'
    }),

    updateNotificationSettings: (0, _emberApiActions.instanceOp)({
      path: 'notifications',
      type: 'put'
    }),

    reviews: (0, _emberApiActions.instanceOp)({
      path: 'reviews',
      type: 'get'
    }),

    updateSupplierBranchMargin: (0, _emberApiActions.instanceOp)({
      path: 'margin',
      type: 'put'
    }),

    updateOperational: (0, _emberApiActions.instanceOp)({
      path: 'operational',
      type: 'put'
    }),

    updateBasic: (0, _emberApiActions.instanceOp)({
      path: 'basic',
      type: 'put'
    }),

    updateReferences: (0, _emberApiActions.instanceOp)({
      path: 'references',
      type: 'put'
    }),

    validationStatus: (0, _emberApiActions.instanceOp)({
      path: 'validation_status',
      type: 'get'
    })
  });
});