define('lxso/serializers/offer-price-availability', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      schedule_template: { embedded: 'always' },
      price_template: { embedded: 'always' },
      limited_inventory: { embedded: 'always' }
    },
    primaryKey: 'offer_price_availability_id',
    normalize: function normalize(unknown, payload) {
      if (payload.limited_inventory) {
        var availability = Ember.merge({
          // variableCapacity: true,
          start_time: payload.start_time
        }, payload.limited_inventory);
        payload.availability = [availability];
      }
      return this._super.apply(this, arguments);
    }
  });
});