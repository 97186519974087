define('lxso/utils/reports-to-array', ['exports', 'lxso/utils/reports-relabel'], function (exports, _reportsRelabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = convertReportsToArray;
  function convertReportsToArray(objects, recordAttributeMap) {
    var excelData = [];
    var headersArray = headers(recordAttributeMap);
    excelData.push(headersArray);
    objects.map(function (object) {
      return excelData.push(data(recordAttributeMap, object));
    });
    return excelData;
  }

  function transformToArray(recordAttributeMap, getValue) {
    var result = [];
    recordAttributeMap.forEach(function (value, key) {
      result.push(getValue(key));
    });
    return result;
  }

  function headers(recordAttributeMap) {
    return transformToArray(recordAttributeMap, _reportsRelabel.default);
  }

  function data(recordAttributeMap, object) {
    return transformToArray(recordAttributeMap, function (key) {
      return object.get(key);
    });
  }
});