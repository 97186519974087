define('lxso/serializers/booking-questions', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    primaryKey: 'bookingQuestionTemplateId',
    normalize: function normalize(typClass, payload) {
      if (payload.bookingQuestionDetails) {
        var bookingQuestionDetails = payload.bookingQuestionDetails.map(function (bookingQuestionDetail) {
          bookingQuestionDetail.fieldId = bookingQuestionDetail.id;
          delete bookingQuestionDetail.id;
          return bookingQuestionDetail;
        });
        payload.bookingQuestionDetails = bookingQuestionDetails;
      }

      if (payload.bookingQuestionOfferDetails) {
        payload.bookingQuestionOfferDetailsMap = payload.bookingQuestionOfferDetails;
      }
      return this._super.apply(this, arguments);
    }
  });
});