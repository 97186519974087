define('lxso/services/phone-util', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    formatPhone: function formatPhone(phoneNumber) {
      var formattedNumber = void 0;
      var phoneNumberUtil = libphonenumber.PhoneNumberUtil;
      if (phoneNumber && phoneNumberUtil.isViablePhoneNumber(phoneNumber)) {
        var parasbleNumber = '+' + phoneNumber;
        var phoneUtilInstance = phoneNumberUtil.getInstance();
        try {
          var parsedNumber = phoneUtilInstance.parse(parasbleNumber);
          // E.164
          formattedNumber = phoneUtilInstance.format(parsedNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
        } catch (e) {
          return phoneNumber;
        }
      }
      return formattedNumber ? formattedNumber : phoneNumber;
    }
  });
});