define('lxso/controllers/reviews', ['exports', 'lxso/utils/omniture', 'lxso/utils/date-validators', 'lxso/utils/csv-download', 'lxso/mixins/date-range'], function (exports, _omniture, _dateValidators, _csvDownload, _dateRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ASCENDING = 'asc';
  var DESCENDING = 'desc';

  /**
   * IMPORTANT:
   * Updates to this list are NOT automatically reflected in the <SELECT> element
   * displayed by reviews.jsp
   **/

  var SORT_SEPARATOR = 'Formatted:';
  var RATING_FIELDS_SORT_OPTIONS = ['arrivalDate' + SORT_SEPARATOR + ASCENDING, 'arrivalDate' + SORT_SEPARATOR + DESCENDING, 'receivedDate' + SORT_SEPARATOR + ASCENDING, 'receivedDate' + SORT_SEPARATOR + DESCENDING];

  exports.default = Ember.Controller.extend(_dateRange.default, {
    reviewHelper: Ember.inject.service('review-helper'),
    reviewVault: Ember.inject.service('reviewvault'),
    maxDateRange: moment.duration(90, 'days'),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    fileSaver: Ember.inject.service(),
    supplierBranch: Ember.inject.service('supplier-branch'),
    session: Ember.inject.service(),

    currentSort: Ember.computed('sortBy', 'order', function () {
      'use strict';

      var sortBy = this.get('sortBy');
      var order = this.get('order');

      return '' + sortBy + SORT_SEPARATOR + order;
    }),

    sortValues: RATING_FIELDS_SORT_OPTIONS,

    getNextPage: function getNextPage() {
      'use strict';

      var route = this.get('route');
      var page = this.get('page');
      var newQueryParams = { page: page };
      newQueryParams.page = +page + 1;
      route.transitionTo({ queryParams: newQueryParams });
    },
    loadReviews: function loadReviews() {
      'use strict';

      var route = this.get('route');
      var params = this.get('params');
      var reviewVault = this.get('reviewVault');
      reviewVault.loadReviews(route, this, params);
    },
    resetLoadedReviews: function resetLoadedReviews(isNextPage) {
      'use strict';

      var loadedReviews = this.get('loadedReviews');

      if (loadedReviews && !isNextPage) {
        this.set('loadedReviews', []);
        this.set('route.endlessScrollService.oldScrollTop', 0);
        this.set('noMoreReviews', false);
      }
    },
    getReviews: function getReviews(loadedReviews) {
      'use strict';

      var reviewHelper = this.get('reviewHelper');
      return loadedReviews ? loadedReviews.map(reviewHelper.setup.computeReview) : [];
    },


    reviews: Ember.computed('loadedReviews', function () {
      'use strict';

      return this.getReviews(this.get('loadedReviews'));
    }),

    aggregatedReviewRating: function aggregatedReviewRating() {
      'use strict';

      var route = this.get('route');
      var params = this.get('params');
      var reviewVault = this.get('reviewVault');
      reviewVault.aggregatedReviewRating(route, this, params);
    },


    aggregatedReviews: Ember.computed('aggregateRatingData', function () {
      'use strict';

      return this.get('aggregateRatingData');
    }),

    updateSidePanel: function updateSidePanel() {
      'use strict';

      var excludeUncommented = this.includeUncommented === 'false';
      Ember.$('#filterHasComments').prop('checked', excludeUncommented);

      var ratingFilterId = '#filter_' + this.ratingFilter;
      Ember.$(ratingFilterId).prop('checked', true);

      this.notifyPropertyChange('model');
    },
    exportReviewsByDateRange: function exportReviewsByDateRange() {
      var _this = this;

      var route = this.get('route');
      var params = this.get('params');
      var dateFormat = this.get('dateFormat.URL_DATE_FORMAT');
      var startDate = this.get('dateRangeFrom').format(dateFormat);
      var endDate = this.get('dateRangeTo').format(dateFormat);
      var reviewVault = this.get('reviewVault');
      var fileSaver = this.get('fileSaver');
      var filenamePrefix = this.get('session.session.authenticated.selectedBranch.supplier_name');

      this.set('isReviewDownloading', true);
      reviewVault.runExportReviewQuery(route, params, startDate, endDate).then(function (successRes) {
        (0, _csvDownload.createCsvAndDownload)(successRes, filenamePrefix, fileSaver);
        _this.set('isReviewDownloading', false);
      });
    },


    isRangeLengthValid: Ember.computed('from', 'to', function () {
      'use strict';

      var maxDateRange = this.get('maxDateRange');
      var from = this.get('dateRangeFrom');
      var to = this.get('dateRangeTo');
      return (0, _dateValidators.isInclusiveRangeLengthIsAtMost)(maxDateRange, from, to);
    }),

    actions: {
      commentFilterChange: function commentFilterChange(event) {
        'use strict';

        var reviewHelper = this.get('reviewHelper');
        var route = this.get('route');
        reviewHelper.actions.commentFilterChange(route, event);
      },
      ratingFilterChange: function ratingFilterChange(event) {
        'use strict';

        var reviewHelper = this.get('reviewHelper');
        var route = this.get('route');
        reviewHelper.actions.ratingFilterChange(route, event);
      },
      downloadReviews: function downloadReviews() {
        if (this.get('dateRangeIsValid')) {
          this.exportReviewsByDateRange();
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.reviews.download-reviews');
        }
      },
      sortReviews: function sortReviews(sortType) {
        'use strict';

        var prop1Value = '';
        switch (sortType) {
          case 'arrivalDateFormatted:asc':
            prop1Value = 'Arrival date (ascending)';
            break;
          case 'arrivalDateFormatted:desc':
            prop1Value = 'Arrival date (descending)';
            break;
          case 'receivedDateFormatted:asc':
            prop1Value = 'Dates received (ascending)';
            break;
          case 'receivedDateFormatted:desc':
            prop1Value = 'Dates received (descending)';
            break;
          default:
            prop1Value = '';
            break;
        }
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.sort.review-list', prop1Value);

        var reviewHelper = this.get('reviewHelper');
        var route = this.get('route');
        reviewHelper.actions.sortReviews(route, sortType);
      },
      reviewActivitySelect: function reviewActivitySelect(selectedActivity) {
        'use strict';

        var reviewHelper = this.get('reviewHelper');
        var route = this.get('route');
        reviewHelper.actions.reviewActivitySelect(route, selectedActivity);
      }
    }
  });
});