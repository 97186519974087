define('lxso/services/loading-time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    getLoadTime: function getLoadTime() {
      var connectionStartTime = window.performance.timing.connectStart;
      var currentTime = new Date().getTime();
      return (currentTime - connectionStartTime) / 1000;
    }
  });
});