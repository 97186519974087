define('lxso/mixins/error-list-indexes-helpers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    errorStrings: Ember.computed('errors', {
      get: function get() {
        var errors = this.get('errors');
        if (errors) {
          return errors.map(function (error) {
            if (error.split(':;:').length > 1) {
              return error.split(':;:')[1];
            } else {
              return error.split(':;:')[0];
            }
          });
        }
      }
    }),

    errorIndexes: Ember.computed('errors', {
      get: function get() {
        var errors = this.get('errors');
        if (errors) {
          return errors.map(function (error) {
            var errorIndexesString = error.split(':;:')[0];
            var errorIndexesStringArray = errorIndexesString.split(',');
            return errorIndexesStringArray.map(function (errorIndexString) {
              return parseInt(errorIndexString);
            });
          }).reduce(function (a, b) {
            return a.concat(b);
          }, []);
        } else {
          return [];
        }
      }
    })
  });
});