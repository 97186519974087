define('lxso/validators/custom-promotion-discount-by-fund-type', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomPromotionDiscountByFundType = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var expediaFund = model.get('expediaFund') || 0;
      var supplierFund = model.get('supplierFund') || 0;
      var totalDiscount = model.get('totalDiscount') || 0;
      var freeLXOptionSelected = model.get('freeLXOptionSelected') || 0;

      var isExpediaFundedPromoWithTotalDiscountMoreThan100Percent = expediaFund && !supplierFund && totalDiscount > 100;
      var isSplitFundedPromoWithTotalDiscountMoreThan75Percent = expediaFund && supplierFund && totalDiscount > 75;
      var isSupplierFundedPromoWithTotalDiscountMoreThan75Percent = !expediaFund && supplierFund && totalDiscount > 75;

      if (freeLXOptionSelected) {
        if (isExpediaFundedPromoWithTotalDiscountMoreThan100Percent) {
          return model.get('i18n').t('pages.promotion.promotionDetails.validationMsg.percentageMoreThan100').string;
        } else if (isSplitFundedPromoWithTotalDiscountMoreThan75Percent || isSupplierFundedPromoWithTotalDiscountMoreThan75Percent) {
          return model.get('i18n').t('pages.promotion.promotionDetails.validationMsg.percentageMoreThan75').string;
        }
      } else {
        if (totalDiscount > 75) {
          return model.get('i18n').t('pages.promotion.promotionDetails.validationMsg.percentageMoreThan75').string;
        }
      }

      return true;
    }
  });

  CustomPromotionDiscountByFundType.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = CustomPromotionDiscountByFundType;
});