define('lxso/routes/oidc-auth', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'lxso/utils/omniture'], function (exports, _unauthenticatedRouteMixin, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    featureFlags: Ember.inject.service(),
    session: Ember.inject.service('session'),
    authUtil: Ember.inject.service(),
    queryParams: {
      code: ''
    },

    model: function model(params) {
      return params;
    },
    setupController: function setupController(params) {
      var _this = this;

      Ember.run.next(function () {
        _this.authenticate(params.code);
      });
    },
    authenticate: function authenticate(token) {
      var _this2 = this;

      var data = { 'tokenCode': decodeURI(token) };
      this.get('session').authenticate('authenticator:oidc', data).then(function () {
        return _this2.store.queryRecord('account-settings', {});
      }).then(function (userInfo) {
        var roleId = userInfo.get('role.roleId');
        // Send completion data to Omniture
        (0, _omniture.default)({
          events: 'event7', //event7 tracks successful login
          prop1: roleId
        });
      }).catch(function () {
        _this2.get('authUtil').epcLogout();
      }).finally(function () {
        if (!_this2.get('session.isAuthenticated')) {
          _this2.transitionTo('login');
        }
      });
    }
  });
});