define('lxso/routes/activity/customer-service', ['exports', 'lxso/mixins/publish-api', 'lodash/array', 'lxso/utils/omniture', 'lxso/utils/form-validator', 'lxso/mixins/example-modal'], function (exports, _publishApi, _array2, _omniture, _formValidator, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var printedVoucherMap = new Map([['Voucherless', false], ['Print', true]]);

  exports.default = Ember.Route.extend(_exampleModal.default, _publishApi.default, {
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    session: Ember.inject.service('session'),
    featureFlags: Ember.inject.service(),
    staticData: Ember.inject.service('static-data'),
    loadingTime: Ember.inject.service(),
    permissions: Ember.inject.service(),
    v4ContentService: Ember.inject.service(),
    v4LogisticsService: Ember.inject.service(),
    v4CatalogService: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.PoliciesAndRedemption'
    },

    model: function model() {
      var activity = this.modelFor('activity');
      var activityId = activity.get('id');
      var isContentAndLogisticsMigratedToV4 = activity.get('isContentAndLogisticsMigratedToV4');
      if (isContentAndLogisticsMigratedToV4) {
        return Ember.RSVP.hash({
          termsAndCondition: activity.get('termsAndConditions'),
          operatorCancellation: activity.get('operatorCancellation'),
          isContentAndLogisticsMigratedToV4: isContentAndLogisticsMigratedToV4
        });
      } else {
        return Ember.RSVP.hash({
          termsAndCondition: this.store.queryRecord('terms_condition', activityId),
          operatorCancellation: this.store.queryRecord('operator_cancellation', activityId),
          isContentAndLogisticsMigratedToV4: isContentAndLogisticsMigratedToV4
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      var activity = this.modelFor('activity');
      this.controller.set('activity', activity);
      this.controller.set('activityRoute', this.controllerFor('activity'));
      this.controller.set('percentRefund', 100);
      this.setupAgeRestriction();
      var countryCodeSource = this.get('staticData').getCountryCodes();

      //Country code uses the typeahead with its own validation
      var countryCodeValidate = function countryCodeValidate(arr, query, selected) {
        //valid cases: has a selected item, or length is 0
        //selected is the list element the user clicked
        //query is the stuff the user typed in the box
        if (query.length > 0 && Object.keys(selected).length > 0 || query.length === 0) {
          return false;
        } else {
          return true;
        }
      };

      this.controller.set('countryCodeValidate', countryCodeValidate);

      countryCodeSource.forEach(function (val) {
        val.label = val.phoneCode + ' (' + val.label + ')';
      });

      this.controller.set('country_code', '');
      this.controller.set('countryCodeSource', countryCodeSource);

      this.controller.set('termsAndCondition', model.termsAndCondition);
      this.controller.set('operatorCancellation', model.operatorCancellation);
      this.controller.set('isContentAndLogisticsMigratedToV4', model.isContentAndLogisticsMigratedToV4);

      this.setupRollbacks();
      this.setupPrintedVoucher();

      this.setupOptionalContentAndAgeRestrictions();

      controller.set('dirty', false);
      Ember.run.next(function () {
        var timeTaken = _this.get('loadingTime').getLoadTime();
        console.info('%c Activity polices and redemption page load time is ' + timeTaken + ' seconds', 'font-weight: bold');
      });
    },
    resetController: function resetController() {},
    setupRollbacks: function setupRollbacks() {
      this.controller.set('voucherCheckedCopy', this.controller.get('activity.attributes.redemption_type'));
      this.controller.set('liabilityWaiverCheckedCopy', this.controller.get('termsAndCondition.liability_waiver'));
      this.controller.set('cancellationAddendumCopy', this.controller.get('activity.policy.cancellation_addendum'));

      this.controller.set('isAgeRestrictedCopy', this.controller.get('termsAndCondition.isAgeRestricted'));
      this.controller.set('ageRestrictionCopy', this.controller.get('termsAndCondition.age_restriction'));
      this.controller.set('isOptionalContentCopy', this.controller.get('termsAndCondition.isOptionalContent'));

      //optional content
      this.controller.set('optionalContentCopy', this.controller.get('termsAndCondition.optional_content'));
      //redemption instructions
      this.controller.set('redemptionInstructionsCopy', this.controller.get('activity.things.redemption_instructions'));
      //booking cutoff
      this.controller.set('bookingCutoffCopy', this.controller.get('activity.bookingCutOff'));

      // operator cancellation
      this.controller.set('groupSizeNotMetCopy', this.controller.get('operatorCancellation.groupSizeNotMet'));
      this.controller.set('inclementWeatherCopy', this.controller.get('operatorCancellation.inclementWeather'));
      this.controller.set('cancellationContentCopy', this.controller.get('operatorCancellation.optionalContent'));
    },
    rollbackToLastSaved: function rollbackToLastSaved() {
      this.controller.set('activity.attributes.redemption_type', this.controller.get('voucherCheckedCopy'));
      this.controller.set('termsAndCondition.liability_waiver', this.controller.get('liabilityWaiverCheckedCopy'));
      this.controller.set('activity.policy.cancellation_addendum', this.controller.get('cancellationAddendumCopy'));

      var cancellationPolicy = this.controller.get('activity.policy.cancellation_policy');
      if (cancellationPolicy && cancellationPolicy.objectAt(0)) {
        cancellationPolicy.objectAt(0).set('minimum_hours', this.controller.get('minHoursCopy'));
      }

      this.controller.set('termsAndCondition.isAgeRestricted', this.controller.get('isAgeRestrictedCopy'));
      this.controller.set('termsAndCondition.age_restriction', this.controller.get('ageRestrictionCopy'));

      this.controller.set('termsAndCondition.isOptionalContent', this.controller.get('isOptionalContentCopy'));

      //optioncal content
      this.controller.set('termsAndCondition.optional_content', this.controller.get('optionalContentCopy'));
      //redemption instructions
      this.controller.set('activity.things.redemption_instructions', this.controller.get('redemptionInstructionsCopy'));
      //booking cutoff
      this.controller.set('activity.bookingCutOff', this.controller.get('bookingCutoffCopy'));

      // operator cancellation
      this.controller.set('operatorCancellation.groupSizeNotMet', this.controller.get('groupSizeNotMetCopy'));
      this.controller.set('operatorCancellation.inclementWeather', this.controller.get('inclementWeatherCopy'));
      this.controller.set('operatorCancellation.optionalContent', this.controller.get('cancellationContentCopy'));
    },


    // todo: We can remove this when we enable V3Offers
    /* Customer may cancel in X hours */
    setupRefundable: function setupRefundable() {
      var activity = this.controller.get('activity');
      var existingPolicies = activity.get('policy.cancellation_policy');

      if (existingPolicies.get('length') === 0) {
        //there is no existing policy yet, so minHours is an empty string
        this.controller.set('minHours', '');
        this.controller.set('refundable', true);
      } else {
        //we already have some policy data, so get the saved minimum_hours
        this.controller.set('minHours', existingPolicies.objectAt(0).get('minimum_hours'));
        //if the saved minimum_hours is > 0, then it's refundable (if 0, then this product is not refundable)
        if (this.controller.get('minHours') > 0) {
          this.controller.set('refundable', true);
        } else {
          this.controller.set('refundable', false);
        }
      }
    },
    setupOptionalContentAndAgeRestrictions: function setupOptionalContentAndAgeRestrictions() {
      var controller = this.controller;
      this.controller.set('isAgeRestricted', controller.get('termsAndCondition.isAgeRestricted'));
      this.controller.set('isOptionalContent', controller.get('termsAndCondition.isOptionalContent'));
    },
    setupAgeRestriction: function setupAgeRestriction() {
      var ageRestrictionOptions = ['Select'];
      var ageRestrictionValues = ['0'];
      for (var i = 1; i <= 30; i++) {
        ageRestrictionOptions.push(i);
        ageRestrictionValues.push(i);
      }
      this.controller.set('ageRestrictionOptions', ageRestrictionOptions);
      this.controller.set('ageRestrictionValues', ageRestrictionValues);
    },
    setupUpdateOptionalContent: function setupUpdateOptionalContent() {
      var i18n = this.get('i18n');
      var updatedContent = [];
      var textPath = 'pages.customerService.labels';
      if (this.controller.get('termsAndCondition.liability_waiver')) {
        updatedContent.push(i18n.t(textPath + '.liabilityWaiver').string);
      }
      if (this.controller.get('termsAndCondition.isAgeRestricted')) {
        var ageRestriction = [];
        var age = this.controller.get('termsAndCondition.age_restriction').toString();
        ageRestriction.push(i18n.t(textPath + '.ageRestrictionBefore').string);
        ageRestriction.push(age);
        ageRestriction.push(i18n.t(textPath + '.ageRestrictionAfter').string);
        updatedContent.push(ageRestriction.join(' '));
      }
      if (this.controller.get('termsAndCondition.isOptionalContent')) {
        updatedContent.push(this.controller.get('termsAndCondition.optional_content'));
      }
      return updatedContent.join('. ');
    },
    setupCancellationAddendum: function setupCancellationAddendum() {
      var i18n = this.get('i18n');
      var cancellationStrings = [];

      if (this.controller.get('operatorCancellation.groupSizeNotMet') && this.controller.get('operatorCancellation.inclementWeather')) {
        cancellationStrings.push(i18n.t('pages.customerService.labels.weatherAndNumberSold').string);
      } else if (this.controller.get('operatorCancellation.groupSizeNotMet')) {
        cancellationStrings.push(i18n.t('pages.customerService.labels.numberSold').string);
      } else if (this.controller.get('operatorCancellation.inclementWeather')) {
        cancellationStrings.push(i18n.t('pages.customerService.labels.weather').string);
      }

      if (this.controller.get('operatorCancellation.isOptionalContent')) {
        cancellationStrings.push(this.controller.get('operatorCancellation.optionalContent'));
      }

      var cancellationAddendum = cancellationStrings.length === 0 ? '' : cancellationStrings.join('. ');
      return cancellationAddendum;
    },
    setupPrintedVoucher: function setupPrintedVoucher() {
      var activity = this.controller.get('activity');
      /* Printed voucher vs. voucherless checkbox */
      //[] Printed voucher = checked if "Print", unchecked if "Voucherless"
      var redemptionType = activity.get('attributes.redemption_type');
      this.controller.set('printedVoucherChecked', printedVoucherMap.get(redemptionType));
    },
    validateRequiredFields: function validateRequiredFields() {
      var i18n = this.get('i18n');
      var controller = this.controller;

      controller.set('errors', []);
      this.controller.get('activity.customer_support_ids').arrayContentDidChange();

      // todo: Pull this out when we enable V3Offers, and use the else

      var validationArray = [{ model: controller.get('activity'), attributes: ['bookingCutOff', 'customer_support_ids'] }];
      if (!controller.get('activity.isViewOnlyForGroundTransfer') || this.get('permissions').checkEnabled('V3InternalSettings')) {
        validationArray.push({ model: controller.get('activity.things'), attributes: ['redemption_instructions'] });
      }
      return _formValidator.validate.apply(undefined, validationArray).catch(function (err) {
        return controller.set('errors', err);
      }).finally(function () {
        if (controller.get('displaySupportContacts').length == 0) {
          controller.set('errors.contact_information', [i18n.t('errors.customMessages.contactInformation').string]);
        }
        if (controller.get('ageRestrictionChecked') && controller.get('termsAndCondition.age_restriction') === 0) {
          if (controller.get('errors').length === 0) {
            controller.set('errors', {});
          }
          controller.set('errors.age_restriction', [i18n.t('errors.customMessages.termsAndCondition').string]);
        }
        var errors = controller.get('errors');

        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (Ember.isEmpty(errors)) {
            resolve();
          } else {
            controller.set('saving', false);
            reject(errors);
          }
        });
      });
    },
    isPublishNeeded: function isPublishNeeded() {
      var termsAndConditionOptContent = this.controller.get('termsAndCondition.optional_content');
      var termsAndConditionOptContentSaved = this.controller.get('optionalContentCopy');

      var operatorCancellationOptContent = this.controller.get('operatorCancellation.optionalContent');
      var operatorCancellationOptContentSaved = this.controller.get('cancellationContentCopy');

      var redemptionInstructions = this.controller.get('activity.things.redemption_instructions');
      var redemptionInstructionsSaved = this.controller.get('redemptionInstructionsCopy');

      var isPublishNeeded = termsAndConditionOptContent != termsAndConditionOptContentSaved || operatorCancellationOptContent != operatorCancellationOptContentSaved || redemptionInstructions != redemptionInstructionsSaved;

      return isPublishNeeded;
    },

    // todo: We can remove this when we enable V3Offers:
    createCancellationPolicy: function createCancellationPolicy() {
      var minimum_hours = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      this.controller.get('activity.policy').set('cancellation_policy', [this.store.createFragment('activity/cancellation-policy-model', {
        percent_refund: 0,
        fee: 0,
        minimum_hours: minimum_hours,
        refundable: true
      })]);
    },


    actions: {
      toggleVoucherType: function toggleVoucherType() {
        var _this2 = this;

        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.CustomerPresentVoucherCheckbox');
        this.setDirtyAttribute();
        var printedVoucherChecked = this.controller.toggleProperty('printedVoucherChecked');

        printedVoucherMap.forEach(function (value, key) {
          if (value == printedVoucherChecked) {
            _this2.controller.set('activity.attributes.redemption_type', key);
          }
        });
      },
      validateBookingCutoff: function validateBookingCutoff() {
        if (!Ember.isEmpty(this.controller.get('errors'))) {
          this.validateRequiredFields();
        }
      },


      // todo: We can remove this when we enable V3Offers
      //only for when the user manually types in min hours for a refundable activity
      updateMinHours: function updateMinHours(num) {
        this.setDirtyAttribute();
        var existingPolicies = this.controller.get('activity.policy.cancellation_policy');

        //only make a new fragment if there aren't any already
        if (existingPolicies.get('length') === 0) {
          this.createCancellationPolicy(num);
        } else {
          existingPolicies.objectAt(0).set('minimum_hours', num);
        }
        if (!Ember.isEmpty(this.controller.get('errors'))) {
          this.validateRequiredFields();
        }
      },


      // todo: We can remove this when we enable V3Offers
      chooseRefundType: function chooseRefundType(value) {
        /* Value is a string that comes in as either 'nonRefundable' or 'refundable' */
        this.setDirtyAttribute();

        //update the local vars
        if (value === 'nonRefundable') {
          //User chose "nonRefundable" radio button
          this.controller.set('minHours', 0);
          this.controller.set('refundable', false);
        } else {
          //otherwise, reveal the field and let the user type the number (updateMinHours)
          this.controller.set('minHours', '');
          this.controller.set('refundable', true);
        }

        var existingPolicies = this.controller.get('activity.policy.cancellation_policy');
        //make a new fragment or update the existing one
        if (existingPolicies.get('length') === 0) {
          this.createCancellationPolicy(0);
        } else {
          existingPolicies.objectAt(0).set('minimum_hours', this.controller.get('minHours'));
        }
        existingPolicies.objectAt(0).set('refundable', this.controller.get('refundable'));

        //re-run validation
        if (!Ember.isEmpty(this.controller.get('errors'))) {
          this.validateRequiredFields();
        }
      },
      addCustomerContactPoint: function addCustomerContactPoint() {
        this.setDirtyAttribute();
        var route = this;
        var activity = route.controller.get('activity');

        route.replaceWith('activity.customer-service.customer-contact-point', activity.id);
      },
      selectCustomerContactPoint: function selectCustomerContactPoint() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AddContactInfo');
        this.setDirtyAttribute();
        var route = this;
        var activity = route.controller.get('activity');

        route.replaceWith('activity.customer-service.select-contact', activity.id);
      },
      validateRedemptionInstruction: function validateRedemptionInstruction() {
        this.setDirtyAttribute();
        if (!Ember.isEmpty(this.controller.get('errors'))) {
          this.validateRequiredFields();
        }
      },
      selectAgeRestriction: function selectAgeRestriction(ageRestriction) {
        this.setDirtyAttribute();
        this.controller.set('isAgeRestricted', ageRestriction > 0);
        this.controller.set('termsAndCondition.age_restriction', parseInt(ageRestriction));
        if (!Ember.isEmpty(this.controller.get('errors'))) {
          this.validateRequiredFields();
        }
      },
      updateCustomerService: function updateCustomerService() {
        var _this3 = this;

        var i18n = this.get('i18n');
        var route = this;
        var controller = this.controller;
        var activity = route.controller.get('activity');
        var existingPolicies = activity.get('policy.cancellation_policy');
        var isPublishNeeded = this.isPublishNeeded();

        if (activity.get('isActivityPublished')) {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Publish');
        } else {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Save');
        }

        controller.set('saving', true);

        this.validateRequiredFields().then(function () {
          var updatedContent = _this3.setupUpdateOptionalContent();
          var cancellationAddendum = _this3.setupCancellationAddendum();
          var tc = _this3.controller.get('termsAndCondition');
          tc.set('update_activity_content', updatedContent);
          activity.set('policy.terms_and_conditions', updatedContent);
          activity.set('policy.cancellation_addendum', cancellationAddendum);
          if (_this3.controller.get('isContentAndLogisticsMigratedToV4')) {
            return _this3.get('v4LogisticsService').updateTermsAndConditions(activity.get('id'), tc.toJSON());
          } else {
            return tc.save();
          }
        }).then(function () {
          var operatorCancellation = _this3.controller.get('operatorCancellation');
          if (_this3.controller.get('isContentAndLogisticsMigratedToV4')) {
            return _this3.get('v4CatalogService').updateOperatorCancellation(activity.get('id'), operatorCancellation.toJSON());
          } else {
            return operatorCancellation.save();
          }
        }).then(function () {
          if (activity.get('isContentAndLogisticsMigratedToV4')) {
            return _this3.get('v4ContentService').updateThings(activity.get('id'), activity.get('things'));
          } else {
            return activity.updateThings(activity.get('things'));
          }
        }).then(function () {
          if (activity.get('isContentAndLogisticsMigratedToV4')) {
            var body = {
              printedVoucherRequired: _this3.controller.get('printedVoucherChecked'),
              redemptionInstructions: activity.get('things.redemption_instructions')
            };
            return _this3.get('v4LogisticsService').updateRedemption(activity.get('id'), body);
          } else {
            return;
          }
        }).then(function () {
          if (activity.get('isContentAndLogisticsMigratedToV4')) {
            var body = {
              redemptionType: activity.get('attributes.redemption_type')
            };
            return _this3.get('v4CatalogService').updateRedemptionType(activity.get('id'), body);
          } else {
            return;
          }
        }).then(function () {
          return activity.updatePolicy(activity.get('policy'));
        }).then(function () {
          //get the supplier branch id so it can be fed to the 'products' route
          _this3.setupRollbacks();

          // Send completion data to Omniture
          (0, _omniture.default)({
            pageName: 'page.LX-Supply.ProductComplete',
            events: 'event1', //event1 is "registration" in omniture
            products: ';LX:' + activity.get('id') + ';;'
          });

          controller.set('saving', false);

          _this3.controller.set('dirty', false);
          if (activity.get('isActivityPublished')) {
            if (isPublishNeeded) {
              _this3.publishChanges(activity).catch(function () {
                _this3.get('notifications').error(_this3.get('i18n').t('components.alert.publishFailed'));
                _this3.controller.set('saving', false);
              });
            } else {
              _this3.get('notifications').success(_this3.get('i18n').t('components.alert.saveSuccess'));
            }
          } else {
            if (_this3.get('session.data.fromPublish')) {
              _this3.get('session').set('data.fromPublish', false);
              route.transitionTo('activity.publish');
            } else {
              if (_this3.controller.get('activity').get('migrationStatus.offer')) {
                route.transitionTo('activity.option-group', _this3.controller.get('activity').get('id'));
              } else {
                route.transitionTo('activity.offer-list', _this3.controller.get('activity').get('id'));
              }
            }
          }
        }).catch(function (reason) {
          controller.set('saving', false);
          controller.set('errors', reason);
          (0, _formValidator.scrollToFirstError)();
          Ember.Logger.error('Something went wrong: ', reason);
        });
      },
      removeContact: function removeContact(support) {
        var _this4 = this;

        this.controller.set('errors', []);
        var supportIds = this.controller.get('activity.customer_support_ids');

        if (supportIds.length === 1) {
          this.controller.set('errors.contact_information', [this.get('i18n').t('errors.customMessages.atLeastOneContact').string]);
        } else {
          supportIds.splice(supportIds.indexOf(parseInt(support.id)), 1);
          this.controller.get('activity').updateCustomerService({ 'customer_support_ids': supportIds }).then(function (resp) {
            var result = [];

            _this4.controller.get('activity.supplier_branch_full').then(function (data) {
              var support = data.get('supplier_support');

              support.forEach(function (val) {
                if (supportIds.indexOf(parseInt(val.get('id'))) !== -1) {
                  result.push(val);
                }
              });

              _this4.controller.set('displaySupportContacts', result);
            });
          });
        }
      },
      editContact: function editContact() {
        this.controller.set('disableActionsMenu', true);
      },
      cancelEditContactInfo: function cancelEditContactInfo() {
        this.controller.set('disableActionsMenu', false);
      },
      saveContactInfo: function saveContactInfo(editedSupport) {
        var _this5 = this;

        var activity = this.controller.get('activity');
        var supplierBranch = this.controller.get('supplierBranch');
        var currentSupplierSupport = [];
        var supplierSupport = [];
        var postSupplierIdArr = [];
        this.controller.set('saving', true);
        this.controller.set('disableActionMenu', false);
        supplierBranch.get('supplier_support').forEach(function (val) {
          if (val.id) {
            currentSupplierSupport.push(val);
          }
        });
        currentSupplierSupport.forEach(function (val) {
          if (val.id) {
            supplierSupport.push(parseInt(val.id));
          }
        });
        currentSupplierSupport.pushObject(editedSupport);
        var supplierSupportArray = currentSupplierSupport.toArray();
        this.controller.set('dirty', false);

        (0, _formValidator.validate)({
          model: editedSupport.get('phone'),
          attributes: ['phone_country_code', 'phone_area_code', 'phone', 'phone_ext', 'phone_usage']
        }, {
          model: editedSupport,
          attributes: ['email']
        }).then(function () {
          var updateSupplierBranch = supplierBranch.updateSupplierSupport({
            supplier_support: supplierSupportArray
          }).then(function (resp) {
            resp.supplier_support.forEach(function (val) {
              postSupplierIdArr.push(val.support_id);
            });
            var dif = _array2.default.difference(postSupplierIdArr, supplierSupport);
            var ids = _this5.controller.get('activity.customer_support_ids') ? _this5.controller.get('activity.customer_support_ids') : [];
            if (dif.length === 1) {
              ids.push(dif[0]);
            }
            _this5.controller.set('activity.customer_support_ids', ids);
            _this5.controller.get('activity').updateCustomerService({ 'customer_support_ids': ids });
          });
          return updateSupplierBranch;
        }).then(function () {
          var suppBranch = _this5.controller.get('activity.supplier_branch_full');
          return suppBranch;
        }).then(function (resp) {
          var reload = resp.reload();
          return reload;
        }).then(function () {
          _this5.replaceWith('activity.customer-service', activity.get('id'));
        }).catch(function (reason) {
          _this5.controller.set('saving', false);
          _this5.controller.set('saveErrors', reason);
          Ember.Logger.error('Something went wrong: ', reason);
        }).finally(function () {
          _this5.controller.set('disableActionsMenu', false);
          _this5.controller.set('saving', false);
        });
      },
      toggleAgeRestriction: function toggleAgeRestriction(ageRestriction) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AgeParentLiabilityWaiverCheckbox');
        this.setDirtyAttribute();
        var checked = ageRestriction.target.checked;
        this.controller.set('ageRestrictionChecked', checked);
        if (!checked) {
          this.controller.set('termsAndCondition.age_restriction', 0);
        }
      },
      toggleOptionalContent: function toggleOptionalContent(optionalContent) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.TandCOtherCheckbox');
        this.setDirtyAttribute();
        var checked = optionalContent.target.checked;
        this.controller.set('optionalContentChecked', checked);
        if (!checked) {
          this.controller.set('termsAndCondition.optional_content', '');
        }
      },
      toggleNumberSold: function toggleNumberSold() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.ProdCancelGroupSizeNotMetCheckbox');
        this.setDirtyAttribute();
        var groupSize = this.controller.get('operatorCancellation.groupSizeNotMet');
        this.controller.set('operatorCancellation.groupSizeNotMet', !groupSize);
      },
      toggleWeather: function toggleWeather() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.ProdCancelBasedWeatherCheckbox');
        this.setDirtyAttribute();
        var weather = this.controller.get('operatorCancellation.inclementWeather');
        this.controller.set('operatorCancellation.inclementWeather', !weather);
      },
      toggleCancellationContent: function toggleCancellationContent(isOptional) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.OperatorCancellationOtherCheckbox');
        this.setDirtyAttribute();
        if (isOptional) {
          this.controller.set('operatorCancellation.optionalContent', '');
        }
      },
      toggleLiabilityWaiver: function toggleLiabilityWaiver(checkboxEvent) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.liabilityWaiverCheckbox');
        this.setDirtyAttribute();
        var checked = checkboxEvent.target.checked;
        this.controller.set('termsAndCondition.liability_waiver', checked);
      },
      setDirtyAction: function setDirtyAction() {
        this.setDirtyAttribute();
        return true;
      },
      willTransition: function willTransition(transition) {
        var transitionRoutes = transition.targetName.split('.');
        var transitioningIntoCustomerService = void 0;
        if (transitionRoutes.length > 1 && transitionRoutes[1] === 'customer-service') {
          transitioningIntoCustomerService = true;
        }
        if (this.controller.get('dirty') && !transitioningIntoCustomerService) {
          this.rollbackToLastSaved();
          this.setupPrintedVoucher();
          this.setupRefundable();
          this.setupOptionalContentAndAgeRestrictions();
        }
        //for tests
        delete window.pageName;
      },
      didTransition: function didTransition() {
        var _this6 = this;

        //for tests
        window.pageName = 'policiesAndRedemption';
        this.controller.set('errors', '');

        this.store.find('supplier-branch', this.controller.get('activity.supplier_branch.supplier_branch_id')).then(function (resp) {
          _this6.controller.set('supplierBranch', resp);
          _this6.controller.set('supplierSupport', resp.get('supplier_support'));

          var support = resp.get('supplier_support');
          var result = [];

          support.forEach(function (val) {
            var id = parseInt(val.get('id'));
            if (_this6.controller.get('activity.customer_support_ids').indexOf(id) !== -1) {
              result.push(val);
            }
          });

          // Sorts the results based on ID
          result.sort(function (obj1, obj2) {
            var iObj1 = parseInt(obj1.id);
            var iObj2 = parseInt(obj2.id);
            if (iObj1 < iObj2) {
              return -1;
            }
            if (iObj1 > iObj2) {
              return 1;
            }
            return 0;
          });

          _this6.controller.set('displaySupportContacts', result);
        });

        this.controller.set('saving', false);
      }
    }
  });
});