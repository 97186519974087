define('lxso/validators/option-group-booking-cutoff', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OptionGroupBookingCutoff = _base.default.extend({
    i18n: Ember.inject.service(),
    permissions: Ember.inject.service(),

    validate: function validate(value, options, model, attribute) {
      'use strict';

      var isInternal = this.get('permissions').checkEnabled('V3InternalSettings');
      var changedAttributes = model.changedAttributes();
      var previousValue = changedAttributes.bookingCutoffInHours ? changedAttributes.bookingCutoffInHours[0] : 0;
      var validationError = isInternal ? this.get('i18n').t('pages.optionGroup.optionGroupSection.details.validationMessages.bookingCutoff504').string : this.get('i18n').t('pages.optionGroup.optionGroupSection.details.validationMessages.bookingCutoff').string;
      if (isInternal) {
        if (value > 504) {
          return validationError;
        }
      } else {
        if (previousValue && value > previousValue) {
          return validationError;
        }
      }
      return true;
    }
  });

  OptionGroupBookingCutoff.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = OptionGroupBookingCutoff;
});