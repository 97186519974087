define('lxso/utils/change-history-details-display-modes/languages-supported', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lxso/utils/change-history-details-display-modes/list'], function (exports, _generic, _list) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'list-with-label',
    diff: function diff(oldValue, newValue) {
      var oldData = oldValue ? JSON.parse(oldValue) : {};
      var newData = newValue ? JSON.parse(newValue) : {};

      var oldValuesArr = [];
      var newValuesArr = [];

      for (var i = 0; i < oldData.length; i++) {
        oldValuesArr.push([oldData[i].locale, oldData[i].translationRequired]);
        newValuesArr.push([newData[i].locale, newData[i].translationRequired]);
      }

      return {
        displayMode: _list.default,
        oldValue: oldValuesArr,
        newValue: newValuesArr
      };
    }
  };
});