define('lxso/models/price-group', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    travelStart: [(0, _validation.validator)('presence', {
      presence: true
    }), (0, _validation.validator)('valid-date', {
      lazy: true
    }), (0, _validation.validator)('custom-date', {
      after: 'now',
      before: Ember.computed.readOnly('model.travelEnd'),
      messageKey: 'travelDates',
      dependantKeys: ['model.travelEnd'],
      allowOnEdit: true
    })],
    travelEnd: [(0, _validation.validator)('presence', {
      presence: true
    }), (0, _validation.validator)('valid-date', {
      lazy: true
    })],
    bookingStart: [(0, _validation.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.bookingDatesDifferent')
    }), (0, _validation.validator)('valid-date', {
      disabled: Ember.computed.not('model.bookingDatesDifferent'),
      lazy: true
    }), (0, _validation.validator)('custom-date', {
      after: 'now',
      before: Ember.computed.readOnly('model.bookingEnd'),
      messageKey: 'bookingDates',
      dependantKeys: ['model.travelStart', 'model.travelEnd', 'model.bookingEnd', 'model.bookingDatesDifferent'],
      disabled: Ember.computed.not('model.bookingDatesDifferent'),
      lazy: true
    }), (0, _validation.validator)('date-must-be-before-or-same', {
      dateA: Ember.computed.readOnly('model.bookingStart'),
      dateB: Ember.computed.readOnly('model.travelStart'),
      messageKey: 'bookingDateBeforeOrSame',
      dependantKeys: ['model.bookingStart', 'model.travelStart', 'model.bookingDatesDifferent'],
      disabled: Ember.computed.not('model.bookingDatesDifferent'),
      lazy: true
    })],
    bookingEnd: [(0, _validation.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.bookingDatesDifferent')
    }), (0, _validation.validator)('valid-date', {
      disabled: Ember.computed.not('model.bookingDatesDifferent'),
      lazy: true
    }), (0, _validation.validator)('date-must-be-before-or-same', {
      dateA: Ember.computed.readOnly('model.bookingEnd'),
      dateB: Ember.computed.readOnly('model.travelEnd'),
      messageKey: 'bookingToDateBeforeOrSame',
      dependantKeys: ['model.bookingEnd', 'model.travelEnd', 'model.bookingDatesDifferent'],
      disabled: Ember.computed.not('model.bookingDatesDifferent'),
      lazy: true
    })],
    someAvailability: (0, _validation.validator)('some-availability', {
      volatile: true
    }),
    duplicateTickets: (0, _validation.validator)('duplicate-tickets', {
      array: 'prices',
      volatile: true
    }),
    noGroupOverlap: (0, _validation.validator)('no-group-overlap', {
      array: 'prices',
      volatile: true
    }),
    noOverlapTicketTypes: (0, _validation.validator)('no-overlap-ticket-types', {
      array: 'prices',
      volatile: true
    }),
    uniqueTicketTypes: (0, _validation.validator)('unique-ticket-types', {
      array: 'prices',
      volatile: true
    })
  });

  var availabilityDefaults = {
    open: '09:00',
    close: '18:00',
    available: true,
    capacity: 100
  };

  exports.default = _emberData.default.Model.extend(Validations, {
    i18n: Ember.inject.service(),
    offer: _emberData.default.belongsTo('offer'),

    prices: _emberData.default.hasMany('ticket-detail'),
    priceGroupId: _emberData.default.attr('number'),
    travelStart: _emberData.default.attr('date-string'),
    travelEnd: _emberData.default.attr('date-string'),
    bookingStart: _emberData.default.attr('date-string'),
    bookingEnd: _emberData.default.attr('date-string'),
    bookingDatesDifferent: _emberData.default.attr('boolean', { defaultValue: false }),
    availabilities: (0, _attributes.fragmentArray)('fragments/prices/availability', { defaultValue: [{ availability: [Object.assign({ day: 'Sun' }, availabilityDefaults), Object.assign({ day: 'Mon' }, availabilityDefaults), Object.assign({ day: 'Tue' }, availabilityDefaults), Object.assign({ day: 'Wed' }, availabilityDefaults), Object.assign({ day: 'Thu' }, availabilityDefaults), Object.assign({ day: 'Fri' }, availabilityDefaults), Object.assign({ day: 'Sat' }, availabilityDefaults)] }] }),

    scheduleId: _emberData.default.attr('number')
  });
});