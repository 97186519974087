define('lxso/helpers/before-or-on-current-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.beforeOrOnCurrentDate = beforeOrOnCurrentDate;
  function beforeOrOnCurrentDate(params /*, hash*/) {
    return moment(new Date()).format() <= moment(new Date(params[0])).format();
  }

  exports.default = Ember.Helper.helper(beforeOrOnCurrentDate);
});