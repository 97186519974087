define('lxso/utils/change-history-details-display-modes/photo', ['exports', 'lxso/utils/change-history-details-display-modes/generic'], function (exports, _generic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'delegator',
    diff: function diff(oldValue, newValue) {
      'use strict';

      var oldFile = oldValue ? JSON.parse(oldValue) : {};
      var newFile = newValue ? JSON.parse(newValue) : {};

      return {
        oldValue: oldFile.imageName,
        newValue: newFile.imageName,
        displayMode: _generic.default
      };
    }
  };
});