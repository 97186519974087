define('lxso/models/activity/things', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation', 'lxso/utils/regexPatterns'], function (exports, _emberData, _emberDataModelFragments, _validation, _regexPatterns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    know_before_you_book: {
      description: 'Know Before You Book',
      validators: [(0, _validation.validator)('array-length', {
        min: 1
      }), (0, _validation.validator)('array-content-presence', {
        presence: true,
        messageKey: 'errors.customMessages.kbyb.requiresOne'
      }), (0, _validation.validator)('array-content-length', {
        min: 4,
        max: 300,
        messageKey: 'errors.customMessages.kbyb.tooShort'
      }), (0, _validation.validator)('array-content-format', {
        regex: _regexPatterns.validForm.validFormChar,
        messageKey: 'errors.customMessages.kbyb.format'
      })]
    },
    know_before_you_go: {
      description: 'Know Before You Go',
      validators: [(0, _validation.validator)('array-length', {
        min: 1
      }), (0, _validation.validator)('array-content-presence', {
        presence: true,
        messageKey: 'errors.customMessages.kbyg.requiresOne'
      }), (0, _validation.validator)('array-content-length', {
        min: 4,
        max: 300,
        messageKey: 'errors.customMessages.kbyg.tooShort'
      }), (0, _validation.validator)('array-content-format', {
        regex: _regexPatterns.validForm.validFormChar,
        messageKey: 'errors.customMessages.kbyg.format'
      })]
    },
    redemption_instructions: {
      description: 'Redemption Instructions',
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        messageKey: 'errors.customMessages.redemptionInstruction.isEmpty'
      })]
    },
    gt_instructions: {
      description: 'GT Instructions',
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        ignoreBlank: false,
        messageKey: 'errors.customMessages.gtSettings.gtInstruction.isEmpty'
      })]
    }

  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    know_before_you_book: _emberData.default.attr('array'),
    know_before_you_go: _emberData.default.attr('array'),
    redemption_instructions: _emberData.default.attr('string'),
    gt_instructions: _emberData.default.attr('string')
  });
});