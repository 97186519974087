define('lxso/components/map-location2/component', ['exports', 'uitk', 'lxso/config/environment', 'lxso/utils/validation', 'lxso/utils/form-validator', 'lxso/mixins/example-modal'], function (exports, _uitk, _environment, _validation, _formValidator, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    city: (0, _validation.validator)('presence', {
      presence: true,
      description: 'City'
    }),
    country: (0, _validation.validator)('presence', {
      presence: true,
      description: 'Country'
    }),
    addressResults: (0, _validation.validator)('address-results', {
      messageKey: 'errors.customMessages.addressResults.noMatch'
    }),
    latitude: (0, _validation.validator)('presence', {
      messageKey: 'errors.customMessages.addressResults.noMatch'
    }),
    formatted_address: (0, _validation.validator)('length', {
      min: 1,
      lazy: false,
      messageKey: 'errors.customMessages.populateMap'
    })
  });

  var thresholdOptions = [300, 500, 1000, 2000, 5000, 10000, 20000, 50000];
  var defaultLatLong = [47.613583, -122.196481];
  var GEOCODE_FAIL_RESPONSE = 'GEOCODING_FAILED';

  exports.default = Ember.Component.extend(_exampleModal.default, Validations, {
    classNames: 'map-location',
    featureFlags: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    updateMapInProgress: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.setValidity(this.get('index'), false);

      //used for country validation from countries type ahead
      var countryValidate = function countryValidate(arr, query, selected) {
        if (typeof query !== 'undefined' && query.length > 0 && Object.keys(selected).length !== 0) {
          return false;
        } else {
          return true;
        }
      };
      this.set('countryValidate', countryValidate);

      this.set('center-coordinates', defaultLatLong[0] + ',' + defaultLatLong[1]);
      if (!_uitk.default.map.data) {
        _uitk.default.map.data = {};
      }
      Ember.run.scheduleOnce('afterRender', function () {
        if (window.google) {
          var city = _this.get('city');
          var country = _this.get('country');
          var timezone = _this.get('timezone');
          var formatted = _this.get('formatted_address');
          var latitude = _this.get('latitude');
          var longitude = _this.get('longitude');
          _this.set('countryDisplay', country);
          if (country) {
            var countryObject = _this.get('countrySource').findBy('display', country);
            _this.set('countryCode', countryObject ? countryObject.value : country);
          }

          var map = _this.initMap(latitude, longitude);
          _this.addressAutocompleteInit();

          if (Ember.isPresent(timezone)) {
            _this.set('timezoneDisplay', timezone.replace('_', ' '));
          }

          if (Ember.isPresent(latitude) && Ember.isPresent(longitude)) {
            _this.setCustomMapMarker(map, latitude, longitude);
            if (!_this.get('timezone')) {
              _this.setTimeZone(latitude, longitude);
            }
          } else if (Ember.isPresent(city) && Ember.isPresent(country)) {
            _this.validateLocation().then(function () {
              _this.updateMap();
            });
          }
        }
      });
    },
    getDefaultMapOptions: function getDefaultMapOptions() {
      var defaultOptions = {
        title: false,
        subtitle: false,
        center: '0,0',
        zoomToFit: false,
        template: 'uitk-map-template',
        sidebar: false,
        sidebarContent: '',
        mapType: 'ROADMAP',
        maxZIndex: 5000,
        fullscreen: true,
        lastFocusable: null,
        scaleControl: true,
        scrollwheel: false,
        draggable: false,
        zoom: 15
      };
      return defaultOptions;
    },
    initMap: function initMap(latitude, longitude) {
      var latLong = void 0;
      var mapOptions = void 0;

      if (latitude || longitude) {
        latLong = new google.maps.LatLng(latitude, longitude);
        mapOptions = Object.assign({}, this.getDefaultMapOptions(), {
          center: latLong
        });
      } else {
        latLong = new (Function.prototype.bind.apply(google.maps.LatLng, [null].concat(defaultLatLong)))();
        mapOptions = Object.assign({}, this.getDefaultMapOptions(), {
          center: latLong,
          zoom: 2
        });
      }

      var mapId = this.get('location-id');
      var map = new google.maps.Map(document.getElementById(mapId), mapOptions);
      return map;
    },
    setCustomMapMarker: function setCustomMapMarker(map, latitude, longitude) {
      var _this2 = this;

      var latLong = new google.maps.LatLng(latitude, longitude);
      var marker = new google.maps.Marker({
        position: latLong,
        map: map,
        draggable: true,
        icon: 'images/blu-marker.png'
      });
      this.setValidity(this.get('index'), true);

      google.maps.event.addListener(marker, 'dragend', function (evt) {
        _this2.setAddressDirty(_this2.get('index'), true);
        _this2.set('latitude', evt.latLng.lat().toFixed(6));
        _this2.set('longitude', evt.latLng.lng().toFixed(6));
      });
    },
    keyUp: function keyUp(e) {
      var _this3 = this;

      var tabKeyPressed = parseInt(e.keyCode) === 9;

      this.setAddressDirty(this.get('index'), true);

      if (this.get('usingManualEntry') && !tabKeyPressed) {
        this.set('formatted_address', '');
      }

      if (this.get('mapErrors')) {
        this.validateLocation().then(function () {
          return _this3.set('mapErrors', undefined);
        }).catch(function (err) {
          return _this3.set('mapErrors', err);
        });
      }
    },
    change: function change() {
      this.setAddressDirty(this.get('index'), true);
    },
    addressAutocompleteInit: function addressAutocompleteInit() {
      // Create autocomplete object, restricting search to geographical location types:
      var autocomplete = new google.maps.places.Autocomplete( /**@type{!HTMLInputElement}**/document.getElementById('address-formatted-' + this.get('index')), { types: ['geocode'] });

      // Set listener on autocomplete object, will update map when address is selected:
      this.addressSelected(autocomplete);
      return autocomplete;
    },
    addressSelected: function addressSelected(autocomplete) {
      $('#address-formatted-' + this.get('index')).keypress(function (event) {
        if (event.keyCode === 10 || event.keyCode === 13) {
          event.preventDefault();
        }
      });

      var that = this;
      autocomplete.addListener('place_changed', function () {
        var place = this.getPlace();
        that.emptyFields();
        that.fillInAddress(place);
        that.updateMap();
      });
    },
    fillInAddress: function fillInAddress(place) {
      // Create variables to concatenate address later
      var streetNumber = null;
      var streetName = null;
      var premise = null;
      var sublocality_level_1 = null;
      var sublocality_level_2 = null;
      var administrative_area_level_2 = null;

      // Set formatted address
      this.set('formatted_address', place.formatted_address);

      // Loop through address components and set them to their form/ember value based on thier google 'type'
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = place.address_components[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var component = _step.value;

          var element = component.types[0];
          switch (element) {
            case 'street_number':
              streetNumber = component.long_name;
              break;
            case 'route':
              streetName = component.long_name;
              break;
            case 'premise':
              premise = component.long_name;
              break;
            case 'sublocality_level_2':
              sublocality_level_2 = component.long_name;
              break;
            case 'sublocality_level_1':
              sublocality_level_1 = component.long_name;
              break;
            case 'locality':
              this.set('city', component.long_name);
              break;
            case 'administrative_area_level_1':
              this.set('address_state', component.long_name);
              break;
            case 'administrative_area_level_2':
              administrative_area_level_2 = component.long_name;
              break;
            case 'country':
              this.countryFilter(component);
              break;
            case 'postal_code':
              this.set('postal_code', component.long_name);
              break;
          }
        }

        // Concat street address if needed
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (streetNumber !== null && streetName !== null) {
        var fullStreet = '' + streetNumber + ' ' + ('' + streetName);
        this.set('street', fullStreet);
      } else if (streetName !== null) {
        this.set('street', streetName);
      } else if (premise !== null || sublocality_level_2 !== null || sublocality_level_1 !== null) {
        var street = [premise, sublocality_level_2, sublocality_level_1].filter(function (field) {
          return !!field;
        }).join(', ');
        this.set('street', street);
      } else if (administrative_area_level_2 !== null) {
        this.set('street', administrative_area_level_2);
      }
    },
    countryFilter: function countryFilter(country) {
      var baseArray = this.get('countrySource');
      var exactLocationMatch = baseArray.findBy('display', country.long_name);
      if (exactLocationMatch) {
        this.set('countryDisplay', exactLocationMatch.display);
        this.set('country', exactLocationMatch.display);
        this.set('countryCode', exactLocationMatch.value);
      } else {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = baseArray[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var arrayCountry = _step2.value;

            if (arrayCountry.display.includes(country.long_name) && country.long_name !== '') {
              this.set('countryDisplay', arrayCountry.display);
              this.set('country', arrayCountry.display);
              this.set('countryCode', arrayCountry.value);
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }
    },
    validateLocation: function validateLocation() {
      return (0, _formValidator.validate)({ model: this, attributes: ['city', 'country'] });
    },
    setGeocodeErrors: function setGeocodeErrors() {
      var errors = {
        geocode: this.get('i18n').t('errors.customMessages.geocode.noMatch')
      };
      this.set('mapErrors', errors);
      (0, _formValidator.scrollToFirstError)();
      this.setValidity(this.get('index'), false);
      this.sendAction('mapLocationValidityChange', errors);
    },


    //force validate components so errors show
    //if invalid components exist
    onValidateComponentTrigger: function () {
      var _this4 = this;

      (0, _formValidator.validate)({ model: this, attributes: ['city', 'country', 'formatted_address'] }).catch(function (err) {
        if (!_this4.get('usingManualEntry')) {
          _this4.toggleForm();
        }
        _this4.set('mapErrors', err);
        (0, _formValidator.scrollToFirstError)();
      });
    }.observes('validateComponentTrigger'),

    fetchLatLongFromAddressFields: function fetchLatLongFromAddressFields() {
      var _this5 = this;

      var addressParts = [];
      if (this.get('street')) {
        addressParts.push(this.get('street'));
      }
      if (this.get('street_second_line')) {
        addressParts.push(this.get('street_second_line'));
      }
      var requestBody = {
        bypassCache: false,
        name: this.get('address_name'),
        address: addressParts.join(', '),
        city: this.get('city'),
        state: this.get('address_state'),
        postCode: this.get('postal_code'),
        country: this.get('countryCode'),
        latitude: null,
        longitude: null
      };

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this5.fetchLatLongFromAddressFieldsWithDynamicThreshold(resolve, reject, requestBody, 0);
      });
    },
    fetchLatLongFromAddressFieldsWithDynamicThreshold: function fetchLatLongFromAddressFieldsWithDynamicThreshold(resolve, reject, requestBody, thresholdIndex) {
      var _this6 = this;

      var geocoderUrl = _environment.default.api.hostV4 + '/logistics/geocode';
      var payload = Object.assign({}, requestBody, {
        threshold: thresholdOptions[thresholdIndex] });
      var latLong = {};
      this.get('ajaxWrapper').ajaxPost(geocoderUrl, payload).then(function (response) {
        latLong = response;
      }).finally(function () {
        if (latLong && latLong.latitude && latLong.longitude) {
          resolve(latLong);
        } else if (thresholdOptions[thresholdIndex + 1]) {
          _this6.fetchLatLongFromAddressFieldsWithDynamicThreshold(resolve, reject, requestBody, thresholdIndex + 1);
        } else {
          reject({
            response: GEOCODE_FAIL_RESPONSE
          });
        }
      });
    },
    setTimeZone: function setTimeZone(latitude, longitude) {
      var _this7 = this;

      var timezoneUrl = _environment.default.api.hostV4 + '/logistics/timezone?latitude=' + latitude + '&longitude=' + longitude;
      return this.get('ajaxWrapper').ajaxGet(timezoneUrl).then(function (response) {
        _this7.set('timezoneDisplay', response.timezone.replace('_', ' '));
        _this7.set('timezone', response.timezone);
      });
    },
    updateMap: function updateMap() {
      var _this8 = this;

      this.set('mapErrors', null);
      this.set('updateMapInProgress', true);
      this.validateLocation().then(function () {
        return _this8.fetchLatLongFromAddressFields();
      }).then(function (latLong) {
        if (latLong && latLong.latitude && latLong.longitude) {
          var latitude = latLong.latitude;
          var longitude = latLong.longitude;
          _this8.updateLatLong(latitude, longitude);
          _this8.setValidity(_this8.get('index'), true);
          _this8.sendAction('mapLocationValidityChange', null);
          if (_this8.get('usingManualEntry')) {
            _this8.setFormattedAddressFromAddressFields();
          }
        } else {
          _this8.setGeocodeErrors();
        }
      }).catch(function (errors) {
        if (errors.response === GEOCODE_FAIL_RESPONSE) {
          _this8.setGeocodeErrors();
        } else {
          Ember.Logger.error('errors object', errors);
          _this8.set('mapErrors', errors);
          (0, _formValidator.scrollToFirstError)();
          _this8.setValidity(_this8.get('index'), false);
          _this8.sendAction('mapLocationValidityChange', errors);
        }
      }).finally(function () {
        _this8.set('updateMapInProgress', false);
      });
    },
    setFormattedAddressFromAddressFields: function setFormattedAddressFromAddressFields() {
      var _this9 = this;

      var fields = ['street', 'street_second_line', 'city', 'address_state', 'postal_code', 'countryDisplay'].map(function (name) {
        return _this9.get(name).trim();
      }).filter(function (field) {
        return field && field.length > 0;
      }).join(', ');
      this.set('formatted_address', fields);
    },
    updateLatLong: function updateLatLong(latitude, longitude) {
      this.set('latitude', latitude);
      this.set('longitude', longitude);
      var map = this.initMap(latitude, longitude);
      this.setCustomMapMarker(map, latitude, longitude);
      this.setTimeZone(latitude, longitude);
    },
    emptyFields: function emptyFields() {
      this.set('city', '');
      this.set('countryDisplay', '');
      this.set('country', '');
      this.set('countryCode', '');
      this.set('address_state', '');
      this.set('postal_code', '');
      this.set('street', '');
      this.set('street_second_line', '');
      this.set('formatted_address', '');
    },
    toggleForm: function toggleForm() {
      var link = document.getElementById('a-enter-manually-expand-' + this.get('index'));
      var form = document.getElementById('address-form-' + this.get('index'));

      //Expand form
      if (form.style.display === 'block') {
        form.style.display = 'none';
        this.set('usingManualEntry', false);
      } else {
        form.style.display = 'block';
        this.set('usingManualEntry', true);
      }

      //Toggle arrow
      $(link).toggleClass('open');
    },


    actions: {
      checkEmpty: function checkEmpty(event) {
        //If autocomplete empties, form empties
        if (event.target.value.length === 0) {
          this.emptyFields();
        }
      },
      validateIfInvalid: function validateIfInvalid(e) {
        var _this10 = this;

        this.setAddressDirty(this.get('index'), true);
        if (this.get('mapErrors')) {
          this.validateLocation().then(function () {
            _this10.set('mapErrors', undefined);
            _this10.sendAction('setValidity', _this10.get('index'), true);
            _this10.sendAction('mapLocationValidityChange', false);
          }).catch(function (err) {
            _this10.set('mapErrors', err);
            _this10.sendAction('setValidity', _this10.get('index'), false);
            _this10.sendAction('mapLocationValidityChange', err);
          });
        }
      },
      populateMap: function populateMap() {
        this.updateMap();
      },
      selectedCountry: function selectedCountry(data) {
        this.set('country', data.display);
        this.set('countryCode', data.value);
        this.send('validateIfInvalid');
      },


      // Sets bounds of search within geographical location of user:
      geolocate: function geolocate() {
        var that = this;
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            var geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            var circle = new google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy
            });
          });
        }
      },
      expandForm: function expandForm() {
        this.toggleForm();
      }
    }
  });
});