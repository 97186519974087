define('lxso/components/price-template-details/component', ['exports', 'lxso/mixins/check-enabled'], function (exports, _checkEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_checkEnabled.default, {
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.getV3StrikeThroughFlagStatus();
    },
    getV3StrikeThroughFlagStatus: function getV3StrikeThroughFlagStatus() {
      this.checkEnabled('neededPermission', 'permissions', 'hasPermission');
      this.checkEnabled('neededFeatureFlag', 'featureFlags', 'hasFeature');
    },


    baseInputId: Ember.computed('viewIndex', 'inModal', function () {
      var baseInputId = 'input-new-price-group-retail-price-' + this.get('price-index');
      var viewIndex = this.get('viewIndex');
      if (viewIndex) {
        baseInputId = baseInputId.concat('-' + viewIndex);
      }
      if (this.get('inModal')) {
        baseInputId = baseInputId.concat('-select');
      }
      return baseInputId;
    }),
    marketingInputId: Ember.computed('viewIndex', 'inModal', function () {
      var marketingInputId = 'input-new-price-group-marketing-price-' + this.get('price-index');
      var viewIndex = this.get('viewIndex');
      if (viewIndex) {
        marketingInputId = marketingInputId.concat('-' + viewIndex);
      }
      if (this.get('inModal')) {
        marketingInputId = marketingInputId.concat('-select');
      }
      return marketingInputId;
    }),

    actions: {
      /*
       Sets reference price to match base if reference price is
       empty (because it has not been edited yet). Only updates
       reference price to match base price if reference price is
       untouched (a pristine reference price is an empty quote '').
        If the user can't see the reference price (called "marketing price"
       in the UI), then set the reference price to zero.
        This is behind a feature flag and permission flag "V3StrikeThroughPricing"
       */
      editedBase: function editedBase() {
        var reference = this.get('ptd.reference');
        if (reference === '') {
          var flag = this.get('store').get('hasV3StrikeThroughPricingFlag');
          var permission = this.get('store').get('hasV3StrikeThroughPricingPermission');
          if (flag && permission) {
            /* The flag exists and it's set to true for this user
            in that case, set the reference to match the base */
            var base = this.get('ptd.base');
            this.set('ptd.reference', base);
          } else {
            /* The user can't see the marketing price (reference price) field,
             so set it to zero if it's an empty quote.
             */
            this.set('ptd.reference', 0);
          }
        }
      }
    }
  });
});