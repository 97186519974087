define('lxso/validators/no-overlap-ticket-types', ['exports', 'ember-cp-validations/validators/base', 'lxso/utils/overlap-failure-reducer'], function (exports, _base, _overlapFailureReducer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var NoOverlapTicketTypes = _base.default.extend({
    store: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    validate: function validate(value, options, model, attribute) {
      'use strict';

      var _this = this;

      var priceArray = model.get('prices');
      var studentGuestTypeId = 10;
      var vehicleTicketTypeID = 51;
      var errorMessage = model.get('i18n').t('errors.customMessages.offers.ticketTypeOverlap').string;
      var failures = [];
      var isPriceUpdateEnabled = this.get('featureFlags').checkEnabled('PriceUpdate');
      var minMaxAges = priceArray.map(function (price, index) {
        var ticketTypeId = price.get('ticketTypeId');
        var ticketType = _this.get('store').peekRecord('ticket-type', ticketTypeId);
        var guestTypeId = ticketType.get('guest_type_id');
        if (guestTypeId !== studentGuestTypeId && guestTypeId !== vehicleTicketTypeID) {
          var restriction = ticketType.get('restrictions').objectAt(0);
          var minAge = restriction.get('min');
          var maxAge = restriction.get('max');
          if (isPriceUpdateEnabled) {
            //relax check for delete and create of same ticket during PG edit
            var operation = price.get('operation') === '' ? 'create' : price.get('operation');
            return [minAge, maxAge, index, operation];
          } else {
            return [minAge, maxAge, index];
          }
        }
      }).compact();

      minMaxAges.sort(function (a, b) {
        var minMaxA = a[0];
        var minMaxB = b[0];
        if (minMaxA < minMaxB) {
          return -1;
        } else {
          return minMaxA > minMaxB ? 1 : 0;
        }
      });

      for (var i = 0; i < minMaxAges.length; i++) {
        var currentMin = minMaxAges[i][0];
        var currentMax = minMaxAges[i][1];
        var currentIndex = minMaxAges[i][2];
        for (var j = 0; j < minMaxAges.length; j++) {
          var checkingMin = minMaxAges[j][0];
          var checkingMax = minMaxAges[j][1];
          var checkingIndex = minMaxAges[j][2];
          //relax check for delete and create of same ticket during PG edit
          var ifSameOperation = true;
          if (isPriceUpdateEnabled) {
            var currentOperation = minMaxAges[i][3];
            var checkingOperation = minMaxAges[j][3];
            ifSameOperation = currentOperation === checkingOperation;
          }
          if (currentIndex !== checkingIndex && ifSameOperation) {
            if (currentMin === checkingMin || currentMin === checkingMax || currentMax === checkingMin || currentMax === checkingMax || currentMin < checkingMin && currentMax > checkingMin || currentMax > checkingMax && currentMin < checkingMax) {
              failures.push('ticketTypeAge' + currentIndex, 'ticketTypeAge' + checkingIndex);
            }
          }
        }
      }

      if (failures.length > 0) {
        return (0, _overlapFailureReducer.overlapFailureReducer)(failures, errorMessage);
      } else {
        return true;
      }
    }
  });

  exports.default = NoOverlapTicketTypes;
});