define('lxso/components/accordion-box/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.$().find('.slidedescription').hide();
    },


    actions: {
      toggleAccordion: function toggleAccordion() {
        this.set('accordion', !this.get('accordion'));
        this.$().find('.slidedescription').slideToggle('slow');
      }
    }

  });
});