define('lxso/models/campaign-meta-data', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    campaignId: _emberData.default.attr('number'),
    promotion: _emberData.default.belongsTo('promotion'),
    promotionMetaData: (0, _attributes.fragment)('fragments/campaign/promotion-meta-data')
  });
});