define('lxso/constants/option-group', ['exports', 'lxso/utils/arrays'], function (exports, _arrays) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.AVAILABILITY_DAYS_ARRAY = exports.AVAILABILITY_DAYS_ABBR = exports.AVAILABILITY_DAYS = exports.CRUD_MODES = exports.SECTIONS = exports.STATUS = exports.MEAL_TYPES = exports.AVAILABILITY_TYPES = exports.PICKUP_TYPES = exports.BOOKING_CUTOFF_TYPES = exports.CANCELLATION_POLICY_TYPES = exports.SCHEDULE_TYPES = exports.CANCELLATION_POLICY_TYPES_ARRAY = exports.BOOKING_CUTOFF_TYPES_ARRAY = exports.AVAILABILITY_TYPES_ARRAY = exports.MEAL_TYPES_ARRAY = exports.OPERATING_HOURS_BASED_SCHEDULES = exports.START_TIME_BASED_SCHEDULES = exports.SCHEDULE_TYPES_ARRAY_GT = exports.SCHEDULE_TYPE_ARRAY = undefined;

  var _AVAILABILITY_DAYS_AB;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var SCHEDULE_TYPES = {
    START: 'START',
    HOURS_OF_OPERATION: 'HOURS_OF_OPERATION',
    MULTI_DAY: 'MULTI_DAY',
    VALID_DAYS: 'VALID_DAYS',
    VALID_HOURS: 'VALID_HOURS'
  };

  var SCHEDULE_TYPE_ARRAY = (0, _arrays.getValuesListFromKeyValuePairs)(SCHEDULE_TYPES);

  var SCHEDULE_TYPES_ARRAY_GT = Ember.A([SCHEDULE_TYPES.START, SCHEDULE_TYPES.HOURS_OF_OPERATION]);
  var START_TIME_BASED_SCHEDULES = Ember.A([SCHEDULE_TYPES.START, SCHEDULE_TYPES.MULTI_DAY]);
  var OPERATING_HOURS_BASED_SCHEDULES = Ember.A([SCHEDULE_TYPES.HOURS_OF_OPERATION, SCHEDULE_TYPES.VALID_HOURS, SCHEDULE_TYPES.VALID_DAYS]);

  var MEAL_TYPES = {
    FOOD_ONLY: 'FOOD_ONLY',
    DRINKS_ONLY: 'DRINKS_ONLY',
    FOOD_AND_DRINKS: 'FOOD_AND_DRINKS',
    NOT_INCLUDED: 'NOT_INCLUDED'
  };

  var MEAL_TYPES_ARRAY = (0, _arrays.getValuesListFromKeyValuePairs)(MEAL_TYPES);

  var AVAILABILITY_TYPES = {
    LIMITED: 'LIMITED',
    UNLIMITED: 'UNLIMITED'
  };

  var AVAILABILITY_TYPES_ARRAY = (0, _arrays.getValuesListFromKeyValuePairs)(AVAILABILITY_TYPES);

  var BOOKING_CUTOFF_TYPES = {
    START_TIME: 'START_TIME',
    CLOSING_TIME: 'CLOSING_TIME',
    PICKUP_TIME: 'PICKUP_TIME'
  };

  var BOOKING_CUTOFF_TYPES_ARRAY = (0, _arrays.getValuesListFromKeyValuePairs)(BOOKING_CUTOFF_TYPES);

  var CANCELLATION_POLICY_TYPES = {
    REFUNDABLE: 'Refundable',
    NON_REFUNDABLE: 'Non Refundable'
  };

  var CANCELLATION_POLICY_TYPES_ARRAY = (0, _arrays.getValuesListFromKeyValuePairs)(CANCELLATION_POLICY_TYPES);

  var PICKUP_TYPES = {
    OPTIONAL: 'OPTIONAL'
  };

  var STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    SUSPENDED: 'SUSPENDED'
  };

  var SECTIONS = {
    OPTION_GROUP: 'OPTION_GROUP',
    DETAILS: 'DETAILS',
    OPTIONS: 'OPTIONS',
    V3_PRICING: 'V3_PRICING',
    PRICE_ATTRIBUTES: 'PRICE_ATTRIBUTES',
    TICKETS: 'TICKETS',
    V4_PRICE_GROUP: 'V4_PRICE_GROUP',
    V4_PRICE_ATTRIBUTES: 'V4_PRICE_ATTRIBUTES',
    OG_TTR: 'OG_TTR'
  };

  var CRUD_MODES = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE'
  };

  var AVAILABILITY_DAYS = {
    SUNDAY: 'SUNDAY',
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY'
  };

  var AVAILABILITY_DAYS_ABBR = (_AVAILABILITY_DAYS_AB = {}, _defineProperty(_AVAILABILITY_DAYS_AB, AVAILABILITY_DAYS.SUNDAY, 'Sun'), _defineProperty(_AVAILABILITY_DAYS_AB, AVAILABILITY_DAYS.MONDAY, 'Mon'), _defineProperty(_AVAILABILITY_DAYS_AB, AVAILABILITY_DAYS.TUESDAY, 'Tue'), _defineProperty(_AVAILABILITY_DAYS_AB, AVAILABILITY_DAYS.WEDNESDAY, 'Wed'), _defineProperty(_AVAILABILITY_DAYS_AB, AVAILABILITY_DAYS.THURSDAY, 'Thu'), _defineProperty(_AVAILABILITY_DAYS_AB, AVAILABILITY_DAYS.FRIDAY, 'Fri'), _defineProperty(_AVAILABILITY_DAYS_AB, AVAILABILITY_DAYS.SATURDAY, 'Sat'), _AVAILABILITY_DAYS_AB);

  var AVAILABILITY_DAYS_ARRAY = (0, _arrays.getValuesListFromKeyValuePairs)(AVAILABILITY_DAYS);

  exports.SCHEDULE_TYPE_ARRAY = SCHEDULE_TYPE_ARRAY;
  exports.SCHEDULE_TYPES_ARRAY_GT = SCHEDULE_TYPES_ARRAY_GT;
  exports.START_TIME_BASED_SCHEDULES = START_TIME_BASED_SCHEDULES;
  exports.OPERATING_HOURS_BASED_SCHEDULES = OPERATING_HOURS_BASED_SCHEDULES;
  exports.MEAL_TYPES_ARRAY = MEAL_TYPES_ARRAY;
  exports.AVAILABILITY_TYPES_ARRAY = AVAILABILITY_TYPES_ARRAY;
  exports.BOOKING_CUTOFF_TYPES_ARRAY = BOOKING_CUTOFF_TYPES_ARRAY;
  exports.CANCELLATION_POLICY_TYPES_ARRAY = CANCELLATION_POLICY_TYPES_ARRAY;
  exports.SCHEDULE_TYPES = SCHEDULE_TYPES;
  exports.CANCELLATION_POLICY_TYPES = CANCELLATION_POLICY_TYPES;
  exports.BOOKING_CUTOFF_TYPES = BOOKING_CUTOFF_TYPES;
  exports.PICKUP_TYPES = PICKUP_TYPES;
  exports.AVAILABILITY_TYPES = AVAILABILITY_TYPES;
  exports.MEAL_TYPES = MEAL_TYPES;
  exports.STATUS = STATUS;
  exports.SECTIONS = SECTIONS;
  exports.CRUD_MODES = CRUD_MODES;
  exports.AVAILABILITY_DAYS = AVAILABILITY_DAYS;
  exports.AVAILABILITY_DAYS_ABBR = AVAILABILITY_DAYS_ABBR;
  exports.AVAILABILITY_DAYS_ARRAY = AVAILABILITY_DAYS_ARRAY;
});