define('lxso/validators/promotion-custom-date', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PromotionCustomDate = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var errorMessage = model.get('i18n').t('' + options.messageKey).string;
      var thisDate = new Date(value);

      if (options.before) {
        var beforeDate = void 0;
        if (attribute === 'bookingEndDate' && model.get('campaignId') > 0 && new Date(model.get('bookingEndUpperBound')) > new Date(options.before)) {
          beforeDate = new Date(model.get('bookingEndUpperBound'));
          errorMessage = model.get('i18n').t('errors.customMessages.promotion.dates.bookingEndDateBeforeUpperBound').string.concat(model.get('bookingEndUpperBound'));
        } else {
          beforeDate = new Date(options.before);
        }
        if (beforeDate.getTime() < thisDate.getTime()) {
          return errorMessage;
        }
      }

      if (options.after) {
        var afterDate = options.after === 'now' ? new Date() : new Date(options.after);
        afterDate.setHours(0, 0, 0, 0);
        if (attribute === 'bookingEndDate') {
          if (model.get('campaignId') > 0 && new Date(model.get('bookingEndLowerBound')) > afterDate && new Date(model.get('bookingEndLowerBound')) > new Date(model.get('bookingStartDate'))) {
            afterDate = new Date(model.get('bookingEndLowerBound'));
            errorMessage = model.get('i18n').t('errors.customMessages.promotion.dates.bookingEndDateAfterLowerBound').string.concat(model.get('bookingEndLowerBound'));
          } else if (new Date(model.get('bookingStartDate')) > afterDate && new Date(model.get('bookingStartDate')) > new Date(model.get('travelEndDate'))) {
            afterDate = new Date(model.get('bookingStartDate'));
            errorMessage = model.get('i18n').t('errors.customMessages.promotion.dates.bookingEndDate').string;
          } else {
            errorMessage = model.get('i18n').t('errors.customMessages.promotion.dates.bookingEndDateAfterNow').string;
          }
        } else if (attribute === 'travelEndDate') {
          if (model.get('campaignId') > 0 && new Date(model.get('travelEndLowerBound')) > afterDate) {
            afterDate = new Date(model.get('travelEndLowerBound'));
            errorMessage = model.get('i18n').t('errors.customMessages.promotion.dates.travelEndDateAfterLowerBound').string.concat(model.get('travelEndLowerBound'));
          } else {
            errorMessage = options.after === 'now' ? model.get('i18n').t('errors.customMessages.promotion.dates.travelEndDateAfterNow').string : model.get('i18n').t('errors.customMessages.promotion.dates.travelEndDate').string;
          }
        } else if (attribute === 'bookingStartDate' && model.get('campaignId') > 0 && new Date(model.get('bookingStartLowerBound')) > afterDate) {
          afterDate = new Date(model.get('bookingStartLowerBound'));
          errorMessage = model.get('i18n').t('errors.customMessages.promotion.dates.bookingStartDateAfterLowerBound').string.concat(model.get('bookingStartLowerBound'));
        } else if (attribute === 'travelStartDate' && model.get('campaignId') > 0 && new Date(model.get('travelStartLowerBound')) > afterDate) {
          afterDate = new Date(model.get('travelStartLowerBound'));
          errorMessage = model.get('i18n').t('errors.customMessages.promotion.dates.travelStartDateAfterLowerBound').string.concat(model.get('travelStartLowerBound'));
        }

        var isDirty = model.get('hasDirtyAttributes');
        if (afterDate.getTime() > thisDate.getTime() && isDirty) {
          return errorMessage;
        }
      }

      if (model.get('campaignId') > 0) {
        var boundDate = void 0;
        if (attribute === 'bookingStartDate') {
          boundDate = new Date(model.get('bookingStartUpperBound'));
          errorMessage = model.get('i18n').t('errors.customMessages.promotion.dates.bookingStartDateBeforeUpperBound').string.concat(model.get('bookingStartUpperBound'));
        } else if (attribute === 'travelStartDate') {
          boundDate = new Date(model.get('travelStartUpperBound'));
          errorMessage = model.get('i18n').t('errors.customMessages.promotion.dates.travelStartDateBeforeUpperBound').string.concat(model.get('travelStartUpperBound'));
        } else if (attribute === 'travelEndDate') {
          boundDate = new Date(model.get('travelEndUpperBound'));
          errorMessage = model.get('i18n').t('errors.customMessages.promotion.dates.travelEndDateBeforeUpperBound').string.concat(model.get('travelEndUpperBound'));
        }
        if (boundDate < thisDate) {
          return errorMessage;
        }
      }
      return true;
    }
  });

  PromotionCustomDate.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = PromotionCustomDate;
});