define('lxso/mixins/supplier-settings/email-notification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    initNotificationSettings: function initNotificationSettings() {
      this.controller.set('editNotificationSettings', false);
    },
    reLoadSupplier: function reLoadSupplier() {
      return this.controller.get('supplierBranch').reload();
    },


    actions: {
      editNotificationSettings: function editNotificationSettings(state) {
        if (!state || this.isEditAllowed('notificationSettings')) {
          this.controller.set('editNotificationSettings', state);
        } else {
          this.controller.set('sectionToBeEdited', 'notificationSettings');
        }
      },
      cancelNotificationSettings: function cancelNotificationSettings() {
        this.controller.get('supplierBranch').rollbackAttributes();
        this.send('editNotificationSettings', false);
      },
      savedNotification: function savedNotification() {
        var _this = this;

        this.controller.set('lastSaved', 'notificationSettings');
        this.get('notifications').success(this.get('i18n').t('components.alert.saveSuccess'));
        this.reLoadSupplier().then(function () {
          _this.send('editNotificationSettings', false);
        });
      }
    }
  });
});