define('lxso/components/offer-group/multi-offer-schedule/component', ['exports', 'lxso/constants/language-type-ids', 'lxso/utils/omniture'], function (exports, _languageTypeIds, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    hasMultipleSchedules: Ember.computed('schedules', function () {
      return this.get('schedules.length') > 1;
    }),

    actions: {
      validateIfInvalid: function validateIfInvalid() {
        this.sendAction('validateIfInvalid');
      },
      updateTitlePrefix: function updateTitlePrefix() {
        this.sendAction('updateTitlePrefix');
      },
      multiDaysChange: function multiDaysChange(schedule, days) {
        var durationInMinutes = parseInt(days) * 24 * 60;
        schedule.set('durationInMinutes', durationInMinutes);
      },
      addSchedule: function addSchedule() {
        this.get('schedules').pushObject({
          tagId: new Date().getTime().toString(),
          startTime: '',
          endTime: ''
        });
        this.notifyPropertyChange('schedules');
        //Delete button for each schedule doesnt update correctly without notifyPropertyChange
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AddAnotherStartAndEndTime');
      },
      deleteSchedule: function deleteSchedule(schedule) {
        var offerIds = schedule.get('offerIds');
        this.updateLanguageIdMap(offerIds);
        this.updateOfferDetailsOnDelete(offerIds);
        this.get('schedules').removeObject(schedule);
        this.notifyPropertyChange('schedules');
        this.sendAction('validateIfInvalid');
        //Delete button for each schedule doesnt update correctly without notifyPropertyChange
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.DeleteStartAndEndTime');
      }
    },
    updateOfferDetailsOnDelete: function updateOfferDetailsOnDelete(offerIds) {
      if (offerIds.length && offerIds.length) {
        var offerDetails = this.get('scheduleAndLanguageInfo.offerDetails');
        offerDetails.forEach(function (offerDetail) {
          if (offerIds.includes(offerDetail.offerId)) {
            offerDetail.status = 'inactive';
          }
        });
      }
    },
    updateLanguageIdMap: function updateLanguageIdMap(offerIds) {
      var languageTypeId = this.get('scheduleAndLanguageInfo.languageInfo.languageTypeId');
      if (offerIds && offerIds.length && languageTypeId === _languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY) {
        var langInfo = this.get('scheduleAndLanguageInfo').get('languageInfo');
        var langIdOffersMap = langInfo.data.langIdOffersMap || langInfo.langIdOffersMap;
        // ToDo: Refactor above line of code
        langIdOffersMap.forEach(function (value, key) {
          var offerIdsLinkedToLangId = value.filter(function (offerId) {
            return !offerIds.includes(offerId);
          });
          langIdOffersMap.set(key, offerIdsLinkedToLangId);
        });
      }
    }
  });
});