define('lxso/models/fragments/user-management/accessible-supplier-branch', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _emberDataModelFragments, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    supplierId: _emberData.default.attr('number'),
    supplierName: _emberData.default.attr('string'),
    isBranchesLoading: _emberData.default.attr('boolean', { defaultValue: true }),
    status: _emberData.default.attr('string', { defaultValue: 'Active' }),
    selectAll: _emberData.default.attr('boolean', { defaultValue: true }),
    hasToBeRemoved: _emberData.default.attr('boolean', { defaultValue: false }),
    allBranches: (0, _attributes.fragmentArray)('fragments/user-management/supplier-branches-list')
  });
});