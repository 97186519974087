define('lxso/instance-initializers/reset-response-errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* appInstance */{
    Ember.Route.reopen({
      responseErrors: Ember.inject.service('response-errors'),

      render: function render() {
        this._super.apply(this, arguments);
        this.get('responseErrors').clearErrors();
      }
    });
  }

  exports.default = {
    name: 'reset-response-errors',
    initialize: initialize
  };
});