define('lxso/routes/promotion-list', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-supplier-branch', 'lxso/mixins/feature-flag', 'lxso/utils/omniture'], function (exports, _authenticatedRouteMixin, _requireSupplierBranch, _featureFlag, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, {
    requiredPermission: 'PromotionRW',
    i18n: Ember.inject.service(),
    permissions: Ember.inject.service(),
    routeHistory: Ember.inject.service(),
    loadingTime: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    queryParams: {
      campaignId: { refreshModel: true }
    },
    omniture: {
      pageName: 'page.LX-Supply.Promotion-list'
    },

    model: function model(params) {
      var branchId = params.id;
      return Ember.RSVP.hash({
        promotions: this.store.query('promotion', { branchId: branchId }),
        campaignId: params.campaignId
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      var i18n = this.get('i18n');
      var isManageCampaignEnabled = this.get('featureFlags').checkEnabled('ManageCampaign');

      controller.setProperties({
        initStatus: ['Active'],
        promotions: model.promotions,
        campaignId: model.campaignId,
        supplierBranchCurrency: this.get('session.data.authenticated.selectedBranch.currency_type'),
        filteredPromotions: Ember.computed('promotions', 'promotions.@each.status', 'selectedStatuses', function () {
          var promotions = this.get('promotions');
          var selectedStatuses = this.get('selectedStatuses');
          if (!selectedStatuses) {
            return promotions;
          } else {
            return promotions.filter(function (promotion) {
              return selectedStatuses.indexOf(promotion.get('status')) >= 0;
            });
          }
        }),
        sortedPromotions: Ember.computed('filteredPromotions', 'filteredPromotions.@each.status', 'sortParam', 'sortAscending', function () {
          var sortParam = this.get('sortParam');
          var sortedPromotions = [];
          if (sortParam === 'status') {
            var statusMap = new Map([['Active', 'Active'], ['Inactive', 'De-activated'], ['Expired', 'Expired']]);
            sortedPromotions = this.get('filteredPromotions').sort(function (a, b) {
              if (statusMap.get(a.get('status')) < statusMap.get(b.get('status'))) {
                return -1;
              } else if (statusMap.get(a.get('status')) > statusMap.get(b.get('status'))) {
                return 1;
              } else {
                return 0;
              }
            });
          } else {
            sortedPromotions = this.get('filteredPromotions').sortBy(this.get('sortParam'));
          }
          if (this.get('sortAscending')) {
            return sortedPromotions;
          } else {
            return sortedPromotions.reverse();
          }
        }),
        promoTableHeaderBasekey: 'pages.promotionList.promotionTable.',
        isInternalUser: this.get('permissions').checkEnabled('V3InternalSettings'),
        promoTableHeaderKeys: Ember.computed('isInternalUser', function () {
          var isInternalUser = this.get('isInternalUser');
          if (isManageCampaignEnabled) {
            if (this.get('isInternalUser')) {
              return Ember.A(['promotionId', 'name', 'promotionType', 'status', 'funder', 'discount', 'audience', 'shoppingPath', 'selected', 'bookingDates', 'travelDates', 'update']);
            } else {
              return Ember.A(['promotionId', 'name', 'promotionType', 'status', 'discount', 'audience', 'selected', 'bookingDates', 'travelDates', 'update']);
            }
          } else {
            //This else part will be removed on launching manage campaign
            if (this.get('isInternalUser')) {
              return Ember.A(['promotionId', 'name', 'status', 'funder', 'discount', 'audience', 'shoppingPath', 'selected', 'bookingDates', 'travelDates', 'update']);
            } else {
              return Ember.A(['promotionId', 'name', 'status', 'discount', 'audience', 'selected', 'bookingDates', 'travelDates', 'update']);
            }
          }
        }),
        sortableHeaderKeys: Ember.A(['name', 'status', 'promotionType']),
        promoTableKeys: Ember.computed('isInternalUser', function () {
          var isInternalUser = this.get('isInternalUser');
          if (isManageCampaignEnabled) {
            if (this.get('isInternalUser')) {
              return Ember.A(['promotionId', 'name', 'promotionType', 'status', 'funders', 'totalDiscount', 'normalizedAudiences', 'normalizedShoppingPath', 'products', 'bookingDates', 'travelDates']);
            } else {
              return Ember.A(['promotionId', 'name', 'promotionType', 'status', 'totalDiscount', 'normalizedAudiences', 'products', 'bookingDates', 'travelDates']);
            }
          } else {
            //This else part will be removed on launching manage campaign
            if (this.get('isInternalUser')) {
              return Ember.A(['promotionId', 'name', 'status', 'funders', 'totalDiscount', 'normalizedAudiences', 'normalizedShoppingPath', 'products', 'bookingDates', 'travelDates']);
            } else {
              return Ember.A(['promotionId', 'name', 'status', 'totalDiscount', 'normalizedAudiences', 'products', 'bookingDates', 'travelDates']);
            }
          }
        }),
        promoTableActionMap: Ember.A({
          products: 'onProductsClick'
        }),
        itemsPerPage: 10,
        sortParam: 'status',
        sortAscending: true,
        availableStatuses: Ember.computed('promotions', 'promotions.@each.status', function () {
          //contains list of all statuses available on the promotion list of selected branch
          var promotions = this.get('promotions');
          var availableStatuses = [];
          promotions.forEach(function (promotion) {
            var status = promotion.get('status');
            if (availableStatuses.indexOf(status) < 0) {
              availableStatuses.push(status);
            }
          });
          return availableStatuses;
        }),
        selectedStatusMessage: Ember.computed('selectedStatuses', function () {
          var selectedStatuses = this.get('selectedStatuses');
          var selectedStatusesLower = selectedStatuses.map(function (item) {
            return i18n.t('pages.promotionList.statusFilter.' + item.toLowerCase()).toString().toLowerCase();
          }).sort();
          if (selectedStatusesLower) {
            var statusMessage = '';
            if (selectedStatusesLower.length === 0) {
              statusMessage = '';
            } else if (selectedStatusesLower.length === 1) {
              statusMessage += selectedStatusesLower[0] + ' ' + i18n.t('pages.promotionList.promotions');
            } else {
              statusMessage += selectedStatusesLower.slice(0, selectedStatusesLower.length - 1).join(', ');
              statusMessage += ' ' + i18n.t('common.words.and');
              statusMessage += ' ' + selectedStatusesLower[selectedStatusesLower.length - 1];
              statusMessage += ' ' + i18n.t('pages.promotionList.promotions');
            }
            return statusMessage;
          } else {
            return '';
          }
        }),
        isFilterStatusModalOpen: false,
        isFilterApplyDisabled: Ember.computed('allStatuses.@each.value', function () {
          var selectedFiltersLength = this.get('allStatuses').filterBy('value', true).length > 0;
          return !selectedFiltersLength;
        })
      });
      var campaignId = this.controller.get('campaignId');
      this.initStatusFilter(this.controller.get('initStatus'));
      if (campaignId != undefined) {
        this.controller.set('sortParam', 'promotionType');
        var promotionId = void 0;
        var index = controller.get('sortedPromotions').findIndex(function (promotion) {
          promotionId = promotion.get('promotionId');
          return campaignId == promotion.get('campaignId');
        });
        if (index > 0) {
          Ember.run.later(function () {
            var anchorElement = Ember.$('#promotion'.concat(promotionId)).offset();
            if (anchorElement) {
              Ember.$('html, body').animate({
                scrollTop: anchorElement.top - 100
              }, 250);
            }
          });
          var itemsPerPage = controller.get('itemsPerPage');
          var currentPage = Math.ceil((index + 1) / itemsPerPage);
          controller.set('currentPageFromCampaign', currentPage);
        }
      }
      Ember.run.next(function () {
        var timeTaken = _this.get('loadingTime').getLoadTime();
        console.info('%c Promotion list page load time is ' + timeTaken + ' seconds', 'font-weight: bold');
      });
    },
    initStatusFilter: function initStatusFilter(filterStatuses) {
      var _this2 = this;

      var availableStatuses = this.controller.get('availableStatuses');
      var selectedStatuses = filterStatuses ? filterStatuses : availableStatuses;
      var allStatusList = ['Active', 'Inactive', 'Expired'];
      this.controller.set('selectedStatuses', selectedStatuses);
      var allStatuses = allStatusList.map(function (status) {
        return {
          name: status,
          label: _this2.get('i18n').t('pages.promotionList.statusFilter.' + status.toLowerCase()),
          helpText: _this2.get('i18n').t('pages.promotionList.statusFilter.' + status.toLowerCase() + 'HelpText'),
          value: availableStatuses && selectedStatuses.indexOf(status) >= 0
        };
      });
      this.controller.set('allStatuses', allStatuses);
    },
    setPromotionBackURL: function setPromotionBackURL() {
      this.get('routeHistory').setPromotionBackURL('promotion-list');
    },


    actions: {
      createPromotion: function createPromotion() {
        this.setPromotionBackURL();
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.create.promotion-list');
        this.transitionTo('promotion', 'new', {
          queryParams: {
            productId: null,
            optionId: null,
            clone: null,
            campaignId: null
          }
        });
      },
      statusModalClick: function statusModalClick() {
        this.controller.set('selectedStatusesCopy', this.controller.get('selectedStatuses'));
        this.controller.toggleProperty('isFilterStatusModalOpen');
      },
      cancelPromoFilter: function cancelPromoFilter() {
        this.initStatusFilter(this.controller.get('selectedStatusesCopy'));
        this.controller.set('isFilterStatusModalOpen', false);
      },
      applyPromoFilter: function applyPromoFilter(allStatuses) {
        var selectedStatusArray = [];
        allStatuses.forEach(function (status) {
          if (status.value === true) {
            selectedStatusArray.push(status.name);
          }
        });
        this.controller.set('selectedStatuses', selectedStatusArray);
        this.controller.set('isFilterStatusModalOpen', false);
      },
      onProductsClick: function onProductsClick(item) {
        this.controller.set('promotionDetailModal', item);
      },
      setSortParams: function setSortParams(sortParam, sortAscending) {
        this.controller.set('sortParam', sortParam);
        this.controller.set('sortAscending', sortAscending);
      },
      editRow: function editRow(item) {
        this.setPromotionBackURL();
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.edit.promotion-list');
        this.transitionTo('promotion', item.id, {
          queryParams: {
            productId: null,
            optionId: null,
            clone: null,
            campaignId: null
          }
        });
      },
      deactivateRow: function deactivateRow(promotion) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.deactivate.promotion-list');
        this.controller.set('deactivateSubmitDisabled', false);
        this.controller.set('selectedPromotion', promotion);
        this.controller.set('isDeactivateModalOpen', true);
      },
      deactivateSubmit: function deactivateSubmit() {
        var _this3 = this;

        this.controller.set('deactivateSubmitDisabled', true);
        var promotion = this.controller.get('selectedPromotion');
        promotion.set('status', 'Inactive');
        var selectedStatuses = this.controller.get('selectedStatuses');
        promotion.save({ adapterOptions: { operation: 'deactivatePromotions' } }).then(function (saveResponse) {
          _this3.get('notifications').success(_this3.get('i18n').t('components.alert.saveSuccess'));
          _this3.send('cancelDeactivate');
          _this3.initStatusFilter(selectedStatuses);
        }).catch(function (err) {
          promotion.rollbackAttributes();
          _this3.get('notifications').error(_this3.get('i18n').t('components.alert.saveFailure'));
        });
      },
      cancelDeactivate: function cancelDeactivate() {
        this.controller.set('isDeactivateModalOpen', false);
        this.controller.set('selectedPromotion', null);
      },
      cloneRow: function cloneRow(item) {
        this.setPromotionBackURL();
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.clone.promotion-list');
        this.transitionTo('promotion', item.id, {
          queryParams: {
            productId: null,
            optionId: null,
            clone: true,
            campaignId: null
          }
        });
      },
      cancelProductDetailsView: function cancelProductDetailsView() {
        this.controller.set('promotionDetailModal', false);
      }
    }
  });
});