define('lxso/validators/price-compare-base-reference', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /*
   The reference price (known as "marketing price" in the UI)
   must be greater than the base price (known as "retail price" in the UI).
  
   This validation should only run if the feature flag
   "V3StrikeThroughPricing" is turned on and the user
   has "V3StrikeThroughPricing" permission.
  */

  var priceCompareBaseReference = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      'use strict';

      //set on edit.js when the model is created

      var flagStatus = model.store.get('hasV3StrikeThroughPricingFlag') && model.store.get('hasV3StrikeThroughPricingPermission');

      var newPrices = value;
      var errorIndexes = [];
      var errorString = void 0;

      if (flagStatus) {
        //check that marketing price > base price
        for (var i = 0; i < newPrices.length; i++) {
          if (newPrices[i].reference < newPrices[i].base) {
            var result = this.createErrorMessage('referenceLessThanBase', value, options);
            errorIndexes.push(i);
            errorString = result;
          }
        }
      }

      //flag or no flag, this validator has to return something
      return errorString ? errorIndexes + ':;:' + errorString : true;
    }
  });

  exports.default = priceCompareBaseReference;
});