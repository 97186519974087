define('lxso/components/dropdown-menu/component', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    manageUsersQueryParams: {
      firstName: null,
      lastName: null,
      email: null,
      supplier: null,
      supplierBranch: null
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentRoute', this.get('router.currentRouteName'));
    },

    routeObserver: Ember.observer('router.currentRouteName', function () {
      this.set('currentRoute', this.get('router.currentRouteName'));
    }),
    actions: {
      sendAction: function sendAction() {
        this.sendAction('action');
      },
      sendOmnitureDataForMenuItem: function sendOmnitureDataForMenuItem(value) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.' + value);
      },
      menuTransition: function menuTransition(params, queryParams) {
        this.sendAction('menuTransition', params, queryParams);
      },
      sendOmnitureDataForNavItem: function sendOmnitureDataForNavItem(value) {
        this.sendAction('sendOmnitureDataForNavItem', value);
      }
    }
  });
});