define('lxso/routes/activity/offer-list/deactivate', ['exports', 'lxso/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _environment, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    queryParams: {
      offerId: '',
      isOfferGroup: {
        refreshModel: true
      }
    },
    i18n: Ember.inject.service(),

    model: function model(params, transition) {
      var query = {
        offerId: params.optionId,
        isOfferGroup: params.isOfferGroup
      };
      return query;
    },
    setupController: function setupController(ctrl, model) {
      this.controller.set('activity', this.modelFor('activity'));
      var offers = this.controller.get('activity.offers');
      this.controller.set('offerid', model.offerId);
      this.controller.set('isOfferGroup', model.isOfferGroup);
    },


    actions: {
      close: function close() {
        this.replaceWith('activity.offer-list');
      },
      deactivate: function deactivate() {
        var _this = this;

        // Disable button so that it cannot be clicked multiple times:
        $('#button-continue-modal-offer-deactivate').prop('disabled', true);

        var offerId = this.controller.get('offerid');
        var isOfferGroup = this.controller.get('isOfferGroup');
        // Data we'll send to the API:
        var data = {
          'status': 'inactive'
        };

        // Update status in offer operational API:
        var url = void 0;
        if (isOfferGroup === 'true') {
          url = _environment.default.api.host + '/api/v3/offerGroup/' + offerId;
        } else {
          url = _environment.default.api.host + '/api/v3/offer/' + offerId;
        }

        this.get('ajaxWrapper').ajaxDelete(url, data).then(function () {
          _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
          _this.transitionTo('activity.offer-list');
        }).catch(function () {
          return _this.get('notifications').error(_this.get('i18n').t('components.alert.saveFailure'));
        });
      },
      willTransition: function willTransition() {
        //for tests
        delete window.pageName;
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'OfferListDeactivateDialog';
      }
    }
  });
});