define('lxso/routes/activity/option-group-settings', ['exports', 'lxso/mixins/require-permission', 'lxso/utils/form-validator', 'lxso/mixins/feature-flag', 'lxso/config/environment', 'lxso/mixins/enum-getter', 'lxso/utils/arrays'], function (exports, _requirePermission, _formValidator, _featureFlag, _environment, _enumGetter, _arrays) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_requirePermission.default, _enumGetter.default, {
    requiredPermission: 'V3InternalSettings',
    session: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    store: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.OptionGroupSettings'
    },
    queryParams: {
      optionGroupId: { refreshModel: false }
    },

    model: function model(params) {
      var allCatalogsUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/catalogs';
      var activityId = this.modelFor('activity').get('id');
      var optionGroupCatalogDetailsUrl = _environment.default.api.hostV4 + '/activity/' + activityId + '/offer-group-catalog-details';
      return new Ember.RSVP.hash({
        params: params,
        allCatalogs: this.get('ajaxWrapper').ajaxGet(allCatalogsUrl),
        optionGroupCatalogDetails: this.get('ajaxWrapper').ajaxGet(optionGroupCatalogDetailsUrl)
      });
    },
    setupController: function setupController(controller, model) {
      var _this3 = this;

      controller.setProperties({
        params: model.params,
        activity: this.modelFor('activity'),
        optionGroups: model.optionGroupCatalogDetails.catalogDetails.map(function (ogDetails) {
          return Object.assign({}, ogDetails.offerGroup, {
            catalogs: ogDetails.catalogs,
            isOpen: ogDetails.offerGroup.offerGroupId == controller.get('optionGroupId')
          });
        }),
        sortedOptionGroups: Ember.computed('optionGroups', function () {
          return this.get('optionGroups').sortBy('status', 'shortTitle');
        }),
        offerCatalogs: Ember.computed('optionGroups', 'params', 'optionGroupId', function () {
          var _this = this;

          return this.get('optionGroups').find(function (og) {
            return og.offerGroupId == _this.get('optionGroupId');
          }).catalogs;
        }),
        offerCatalogsIds: Ember.computed('offerCatalogs', function () {
          return this.get('offerCatalogs').getEach('catalogId');
        }),
        allCatalogs: model.allCatalogs,
        allCatalogsArray: Ember.computed('offerCatalogsIds', 'offerCatalogs', function () {
          return this.get('allCatalogs').map(function (item) {
            return Ember.Object.create(item);
          });
        }),
        allCatalogsWithIsSelected: Ember.computed('allCatalogsArray', function () {
          var _this2 = this;

          var allCatalogsArray = this.get('allCatalogsArray');
          return allCatalogsArray.map(function (item) {
            Ember.set(item, 'isSelected', _this2.get('offerCatalogsIds').includes(item.id));
            return item;
          });
        }),
        isCatalogsDirty: Ember.computed('offerCatalogsCopy', 'offerCatalogs', 'editing', function () {
          var editing = this.get('editing');
          var offerCatalogsCopyIds = this.get('offerCatalogsCopy').map(function (catalog) {
            return catalog.catalogId;
          });
          var offerCatalogsIds = this.get('offerCatalogs').map(function (catalog) {
            return catalog.catalogId;
          });
          if (editing) {
            return !(0, _arrays.containSameElements)(offerCatalogsCopyIds, offerCatalogsIds);
          } else {
            return false;
          }
        }),
        isSaveEnabled: Ember.computed('isCatalogsDirty', 'editing', 'isApplyCatalogsToActiveOptions', 'isApplyCatalogsToInactiveOptions', function () {
          var isCatalogsDirty = this.get('isCatalogsDirty');
          var isApplyCatalogsToInactive = this.get('isApplyCatalogsToInactiveOptions');
          var isApplyCatalogsToActive = this.get('isApplyCatalogsToActiveOptions');
          return isCatalogsDirty || isApplyCatalogsToActive || isApplyCatalogsToInactive;
        }),
        selectedCatalog: null,
        editing: false,
        saving: false,
        isApplyCatalogsToActiveOptions: false,
        isApplyCatalogsToInactiveOptions: false
      });

      Ember.run.next(function () {
        _this3.setupStep();
      });
    },
    setupStep: function setupStep() {
      var optionGroupId = this.controller.get('optionGroupId');
      var optionClassName = 'option-group-' + optionGroupId;
      var links = $('.title-main.toggle-trigger.' + optionClassName);
      $(links).addClass('open');
    },
    setCatalogUpdateData: function setCatalogUpdateData(newCatalogs, optionGroup) {
      var catalogIds = newCatalogs.map(function (c) {
        return c.catalogId;
      });
      var catalogInfo = {};
      catalogInfo.offerGroupId = parseInt(optionGroup.offerGroupId);
      catalogInfo.catalogIds = catalogIds;
      return catalogInfo;
    },
    saveCatalogInformation: function saveCatalogInformation(data) {
      var allCatalogsUrl = _environment.default.api.hostV4 + '/offer-group/update-catalogs';
      return this.get('ajaxWrapper').ajaxPut(allCatalogsUrl, data);
    },


    actions: {
      loading: function loading(transition, optionSettingsRoute) {
        if (optionSettingsRoute.controller) {
          optionSettingsRoute.controller.set('isOptionLoading', true);
          transition.promise.finally(function () {
            optionSettingsRoute.controller.set('isOptionLoading', false);
          });
        }
      },
      openStep: function openStep(optionGroup, stepClass) {
        var allOptionsLink = $('.option-settings-page .title-main.toggle-trigger');
        $(allOptionsLink).removeClass('open');
        if (optionGroup.isOpen) {
          Ember.set(optionGroup, 'isOpen', false);
          this.controller.set('optionGroupId', null);
        } else {
          this.controller.get('optionGroups').forEach(function (og) {
            return Ember.set(og, 'isOpen', false);
          });
          this.controller.set('optionGroupId', optionGroup.offerGroupId);
          Ember.set(optionGroup, 'isOpen', true);
        }
      },
      goToActivitySettings: function goToActivitySettings() {
        this.transitionTo('activity.activity-settings');
      },
      catalogSelected: function catalogSelected(catalog) {
        this.controller.set('selectedCatalog', catalog);
      },
      addCatalog: function addCatalog(catalog) {
        var offerCatalogs = this.controller.get('offerCatalogs');
        if (catalog.id && offerCatalogs.map(function (c) {
          return c.catalogId;
        }).indexOf(catalog.id) < 0) {
          this.controller.get('offerCatalogs').pushObject({
            catalogId: catalog.id,
            catalogName: catalog.name
          });
          this.controller.notifyPropertyChange('offerCatalogs');
        }
      },
      removeCatalog: function removeCatalog(catalog) {
        if (catalog.id || catalog.catalogId) {
          var catalogsToRemove = this.controller.get('offerCatalogs').filter(function (item) {
            return item.catalogId === catalog.id || item.catalogId === catalog.catalogId;
          });
          if (catalogsToRemove.length) {
            this.controller.get('offerCatalogs').removeObjects(catalogsToRemove);
          }
          this.controller.notifyPropertyChange('offerCatalogs');
        }
      },
      addCatalogFromTypeAhead: function addCatalogFromTypeAhead(catalog) {
        if (!catalog || !catalog.id) {
          return;
        }
        var isCatalogSelected = catalog.toggleProperty('isSelected');
        if (isCatalogSelected) {
          this.send('addCatalog', catalog);
        } else {
          this.send('removeCatalog', catalog);
        }
      },
      selectOrDeselectAllCatalogs: function selectOrDeselectAllCatalogs(isSelectAll, items) {
        var _this4 = this;

        if (isSelectAll) {
          items.forEach(function (item, index) {
            item.original.set('isSelected', true);
            _this4.send('addCatalog', item.original);
          });
        } else {
          items.forEach(function (item, index) {
            item.original.set('isSelected', false);
            _this4.send('removeCatalog', item.original);
          });
        }
      },
      editOption: function editOption() {
        var offerCatalogsCopy = JSON.parse(JSON.stringify(this.controller.get('offerCatalogs')));
        this.controller.set('offerCatalogsCopy', offerCatalogsCopy);
        this.controller.set('editing', true);
      },
      saveOptionSettings: function saveOptionSettings(isApplyCatalogsToActiveOptions, isApplyCatalogsToInactiveOptions) {
        var _this5 = this;

        var selectedOption = this.controller.get('optionGroups').find(function (og) {
          return og.offerGroupId == _this5.controller.get('optionGroupId');
        });
        this.controller.set('saving', true);

        var isCatalogsDirty = this.controller.get('isCatalogsDirty');
        var promises = [];
        var catalogData = [];

        if (isCatalogsDirty) {
          // selectedOption.catalogs = JSON.parse(JSON.stringify(this.controller.get('offerCatalogs')));
          Ember.set(selectedOption, 'catalogs', JSON.parse(JSON.stringify(this.controller.get('offerCatalogs'))));
          catalogData.push(this.setCatalogUpdateData(this.controller.get('offerCatalogs'), selectedOption));
        }

        if (isApplyCatalogsToActiveOptions || isApplyCatalogsToInactiveOptions) {
          this.controller.get('optionGroups').forEach(function (optionGroup) {
            if (isApplyCatalogsToActiveOptions && optionGroup.status.toLowerCase() == 'active' && optionGroup.offerGroupId !== selectedOption.offerGroupId) {
              Ember.set(optionGroup, 'catalogs', JSON.parse(JSON.stringify(_this5.controller.get('offerCatalogs'))));
              catalogData.push(_this5.setCatalogUpdateData(_this5.controller.get('offerCatalogs'), optionGroup));
            }

            if (isApplyCatalogsToInactiveOptions && optionGroup.status.toLowerCase() == 'inactive' && optionGroup.offerGroupId !== selectedOption.offerGroupId) {
              Ember.set(optionGroup, 'catalogs', JSON.parse(JSON.stringify(_this5.controller.get('offerCatalogs'))));
              // optionGroup.catalogs = JSON.parse(JSON.stringify(this.controller.get('offerCatalogs')));
              catalogData.push(_this5.setCatalogUpdateData(_this5.controller.get('offerCatalogs'), optionGroup));
            }
          });
        }
        this.controller.notifyPropertyChange('optionGroups');
        if (isCatalogsDirty || isApplyCatalogsToActiveOptions || isApplyCatalogsToInactiveOptions) {
          promises.push(this.saveCatalogInformation({
            offerGroupCatalogs: catalogData
          }));
        }

        Ember.RSVP.all(promises).then(function () {
          _this5.controller.set('errors', []);
          _this5.get('notifications').success(_this5.get('i18n').t('components.alert.saveSuccess'));
          _this5.controller.set('editing', false);
          _this5.controller.set('isApplyCatalogsToActiveOptions', false);
          _this5.controller.set('isApplyCatalogsToInactiveOptions', false);
        }).catch(function (errors) {
          Ember.Logger.error('error saving option group settings', errors);
          _this5.controller.set('errors', errors);
          _this5.get('notifications').error(_this5.get('i18n').t('components.alert.saveFailure'));
        }).finally(function () {
          _this5.controller.set('saving', false);
        });
      },
      cancelEditOptions: function cancelEditOptions() {
        this.controller.set('offerCatalogs', this.controller.get('offerCatalogsCopy'));
        this.controller.set('editing', false);
        this.controller.set('isApplyCatalogsToActiveOptions', false);
        this.controller.set('isApplyCatalogsToInactiveOptions', false);
      }
    }

  });
});