define('lxso/components/tour-select-box/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      selectAction: function selectAction() {
        if (this.attrs.radioId === 'by-traveler') {
          Ember.$('#radio-option-by-traveler').prop('checked', true);
        } else if (this.attrs.radioId === 'by-age') {
          Ember.$('#radio-option-by-age').prop('checked', true);
        }

        this.sendAction('selectAction');
      }
    }
  });
});