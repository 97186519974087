define('lxso/components/ticket-detail/component', ['exports', 'lxso/utils/to-percent', 'lxso/utils/to-percent-without-percent-sign'], function (exports, _toPercent, _toPercentWithoutPercentSign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    netPrice: Ember.inject.service(),
    permissions: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    currency: Ember.computed(function () {
      return this.get('session.data.authenticated.selectedBranch.currency_type');
    }),

    netPriceOverride: Ember.computed('notNew', 'isMarginOverride', 'marginOverride', 'marginValue', 'ticket.retailPrice', 'ticket.margin', function () {
      //if margin is NaN during ticket 0 price, set marginValue to 0 to calculate correct net price
      var marginValue = void 0;
      if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
        marginValue = this.get('ticket.margin');
      } else {
        marginValue = this.get('marginValue');
      }

      if (typeof marginValue !== 'undefined') {
        marginValue = isNaN(parseInt(marginValue)) ? 0 : marginValue;
      } else {
        marginValue = 0;
      }

      var netPrice = parseFloat(this.get('ticket.retailPrice')) * ((100 - parseFloat(marginValue)) / 100);
      var currencyType = this.get('currency');
      switch (currencyType) {
        case 'XPF':
        case 'JPY':
        case 'KRW':
        case 'VUV':
          netPrice = Math.round(netPrice);
          break;
      }

      if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
        this.set('ticket.netPrice', netPrice);
      } else {
        if (this.get('permissions').checkEnabled('V3InternalSettings')) {
          this.set('ticket.netPrice', netPrice);
        }
      }

      if (this.get('isMarginOverride') || !this.get('notNewOrEditingOld')) {
        return netPrice;
      }
    }),

    marginValue: Ember.computed('notNewOrEditingOld', 'ticket.ticketTypeId', 'notNew', function () {
      var notNew = this.get('notNew');
      var ticket = this.get('ticket');
      var retailPrice = ticket.get('retailPrice');
      var netPrice = ticket.get('netPrice');
      var ticketTypeId = ticket.get('ticketTypeId');

      if (this.get('isMarginOverride')) {
        return Number.parseFloat(this.get('marginOverride'));
      }
      return notNew ? (0, _toPercent.default)([netPrice, retailPrice]) : (0, _toPercent.default)([this.get('netPrice').getMargin(ticketTypeId)]);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var firstPG = this.get('offer.prices').objectAt(0);
      var firstPGType = firstPG.get('prices').objectAt(0).get('groupPricePlanType');

      this.set('isConnectedSupplier', this.get('session').get('data.authenticated.selectedBranch.connectivity_status'));

      var firstTicket = this.get('priceGroup').get('prices').objectAt(0);

      if (this.get('isPriceByVolume') && !firstTicket.get('ticketTypeId')) {
        firstTicket.set('groupPricePlanType', 'PerTraveler');
        this.set('masterPricePlanTypeSet', false);
      }

      if (this.get('isPriceByVolume') && this.get('offer.prices.length') > 1 && firstPG !== this.get('priceGroup')) {
        firstTicket.set('groupPricePlanType', firstPGType);
        this.set('masterPricePlanTypeSet', true);
      }
      //set margin for ticket
      var price = this.get('ticket');
      if (price.get('id')) {
        var netPrice = price.get('netPrice');
        var retailPrice = price.get('retailPrice');
        price.set('margin', (0, _toPercentWithoutPercentSign.default)(1 - netPrice / retailPrice));
      }
    },


    actions: {
      ticketTypeChange: function ticketTypeChange(ticketIndex, ticketId, ticketName) {
        var currentTicketSelection = this.get('priceGroup').get('prices').objectAt(ticketIndex);

        var ticketTypeId = ticketId;
        currentTicketSelection.set('ticketTypeId', ticketTypeId);

        if (this.get('isPriceByVolume')) {
          this.get('priceGroup').get('prices').forEach(function (price) {
            price.set('ticketTypeId', ticketTypeId);
          });
          this.sendAction('setTicketTypeSet', true);
        }
        if (this.get('isPriceByVolume') && !this.get('notNew') || !this.get('isPriceByVolume')) {
          if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
            //add margin to normal price when ticketTypeId is updated to existing ticketType
            currentTicketSelection.set('margin', (0, _toPercentWithoutPercentSign.default)(this.get('netPrice').getMargin(this.get('priceGroup').get('prices').objectAt(ticketIndex).get('ticketTypeId'))));
          }
        }

        this.toggleProperty('isPriceSectionDirty');
        this.sendAction('validateIfInvalid', this.get('priceGroup'));
      },
      priceTypeChange: function priceTypeChange(ticketIndex, priceTypeValue) {
        var currentTicketSelection = this.get('priceGroup').get('prices').objectAt(ticketIndex);

        if (currentTicketSelection.get('ticketTypeId')) {
          this.get('priceGroup').get('prices').forEach(function (price) {
            price.set('groupPricePlanType', priceTypeValue);
          });
          this.sendAction('setTicketTypeSet', true);
        }
      },
      removeTicket: function removeTicket(ticket, ticketIndex) {
        //set operation to 'delete' when existing ticket is removed from PG
        if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
          if (this.get('ticket').get('id') !== null) {
            this.get('ticket').set('operation', 'delete');
          } else {
            this.get('priceGroup.prices').removeObject(ticket);
          }
        } else {
          this.get('priceGroup.prices').removeObject(ticket);
        }
        this.sendAction('validateIfInvalid', this.get('priceGroup'));
      },
      setMarginOverride: function setMarginOverride() {
        if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
          if (!this.get('notNewOrEditingOld') && !this.get('isMarginOverride') && this.get('permissions').checkEnabled('V3InternalSettings')) {
            this.set('isMarginOverride', true);
            //make margin blank only when price group is new, otherwise leave as it is
            if (!this.get('notNew')) {
              this.set('ticket.margin', '');
            }
          }
        } else {
          if (!this.get('notNew') && !this.get('isMarginOverride') && this.get('permissions').checkEnabled('V3InternalSettings')) {
            this.set('isMarginOverride', true);
            this.set('marginValue', '');
          }
        }
      },
      marginChanged: function marginChanged() {
        var marginValue = void 0;
        if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
          marginValue = parseFloat(this.get('ticket.margin')).toFixed(2);
        } else {
          marginValue = this.get('marginValue');
        }
        if (isNaN(marginValue)) {
          var ticketTypeId = this.get('ticket.ticketTypeId');
          marginValue = (0, _toPercentWithoutPercentSign.default)(this.get('netPrice').getMargin(ticketTypeId));
        } else {
          marginValue = Math.abs(marginValue);
        }
        if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
          this.sendAction('validateIfInvalid', this.get('priceGroup'));
          this.set('ticket.margin', marginValue);
        } else {
          this.set('marginValue', marginValue);
        }
      },
      groupMinChange: function groupMinChange(ticketIndex, minValue) {
        var currentTicketSelection = this.get('priceGroup').get('prices').objectAt(ticketIndex);

        currentTicketSelection.set('minimumTravelers', parseInt(minValue));
      },
      groupMaxChange: function groupMaxChange(ticketIndex, maxValue) {
        var currentTicketSelection = this.get('priceGroup').get('prices').objectAt(ticketIndex);

        currentTicketSelection.set('maximumTravelers', parseInt(maxValue));
      },
      validateIfInvalid: function validateIfInvalid() {
        this.sendAction('validateIfInvalid');
      }
    }
  });
});