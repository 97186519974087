define('lxso/utils/change-history-details-display-modes/offer', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lxso/utils/change-history-details-display-modes/list', 'lodash'], function (exports, _generic, _list, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var scheduleTypeTranslations = new Map([['Start', 'startTime'], ['Hours of Operation', 'operatingHours'], ['Multi-day', 'multiDay'], ['Valid Days', 'validDays'], ['Valid Hours', 'validHours']]);
  var capacityTypeTranslations = new Map([[true, 'limited'], [false, 'unlimited']]);
  var refundableTranslations = new Map([[true, 'refundable'], [false, 'nonrefundable']]);
  var priceByVolumeTranslations = new Map([[true, 'Yes'], [false, 'No']]);
  var hotelPickupTranslations = new Map([[true, 'Yes'], [false, 'No']]);
  var passEligibleTranslations = new Map([[true, 'Yes'], [false, 'No']]);
  var passOnlyEligibleTranslations = new Map([[true, 'Yes'], [false, 'No']]);

  exports.default = {
    partialName: 'offer',
    diff: function diff(oldValue, newValue, _ref) {
      var i18n = _ref.i18n;

      var oldData = oldValue ? JSON.parse(oldValue) : {};
      var newData = newValue ? JSON.parse(newValue) : {};

      function difference(displayMode, value) {
        var newValue = value(newData);
        var oldValue = value(oldData);

        return !_lodash.default.isEqual(oldValue, newValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }

      function translate(path, key) {
        return key && i18n.t(path + '.' + key).string;
      }

      function time(unit, value) {
        if (value !== undefined) {
          return i18n.t('pages.changeHistory.labels.offerDetail.' + unit, _defineProperty({}, unit, value)).string;
        }
      }

      return {
        scheduleType: difference(_generic.default, function (data) {
          return translate('pages.offer.scheduleTypes', scheduleTypeTranslations.get(data.scheduleType));
        }),
        startTime: difference(_generic.default, function (data) {
          return formatTime(parseTime(data.startTime));
        }),
        endTime: difference(_generic.default, function (data) {
          return formatTime(endTime(data.endTime, data.duration));
        }),
        duration: difference(_generic.default, function (data) {
          return time('minutes', data.duration);
        }),
        bookingCutOffHours: difference(_generic.default, function (data) {
          return time('hours', data.bookingCutOffHours);
        }),
        refundable: difference(_generic.default, function (data) {
          return translate('pages.offer.cancellationPolicyTypes', refundableTranslations.get(data.refundable));
        }),
        cancellationHours: difference(_generic.default, function (data) {
          return time('hours', data.minimumHours);
        }),
        meal: difference(_generic.default, function (data) {
          return data.meal;
        }),
        languageType: difference(_generic.default, function (data) {
          return data.languageInfo && translate('pages.offer.languageTypes', data.languageInfo.languageTypeId);
        }),
        languages: difference(_list.default, function (data) {
          return data.languageInfo && data.languageInfo.languages || [];
        }),
        title: difference(_generic.default, function (data) {
          return data.title;
        }),
        description: difference(_generic.default, function (data) {
          return data.description;
        }),
        capacityType: difference(_generic.default, function (data) {
          return translate('pages.offer.capacityTypes', capacityTypeTranslations.get(data.isLimitedInventory));
        }),
        isPriceByVolume: difference(_generic.default, function (data) {
          return priceByVolumeTranslations.get(data.isPriceByVolume);
        }),
        isHotelPickupEnabled: difference(_generic.default, function (data) {
          return hotelPickupTranslations.get(data.isHotelPickupEnabled);
        }),
        isEligibleInPrebookPaths: difference(_generic.default, function (data) {
          return passOnlyEligibleTranslations.get(data.isEligibleInPrebookPaths);
        })
      };
    }
  };


  var timeFormat = 'HH:mm';

  function endTime(end, duration) {
    return end && duration && moment(end, timeFormat).add(duration, 'minutes');
  }

  function parseTime(time) {
    return time && moment(time, timeFormat);
  }

  function formatTime(time) {
    return time && time.format(timeFormat);
  }
});