define('lxso/serializers/supplier-branch', ['exports', 'ember-data', 'lxso/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    primaryKey: 'supplier_branch_id',
    namespace: 'api/v3',

    attrs: {
      supplier_support: { embedded: 'always' },
      notification_settings: { embedded: 'always' },
      platform: { serialize: 'id', deserialize: 'id' }
    },

    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var branches = payload.branches;
      payload.supplier_branch_id = branches[branches.length - 1].supplier_branch_id;
      return this._super.apply(this, arguments);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      // Add a links object to find associated schedules
      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace + '/supplier_branches';
      payload.links = {
        schedules: domain + '/' + payload.supplier_branch_id + '/schedule_templates',
        hotelZones: _environment.default.api.host + '/' + this.namespace + '/locationLists/' + payload.supplier_branch_id
      };

      // api/v1/supplier_connectivity/id and api/v1/supplier_branches/id returns different keynames
      // for same attributes. The code below makes the key names to match supplier branch model

      if (payload.platformId) {
        payload.platform_id = payload.platformId;
        payload.supplier_branch_id = payload.id;
        payload.connectivity_status = payload.connectivityStatus;
        payload.supplier_branch_name = payload.supplierBranchName;
        delete payload.platformId;
        delete payload.id;
        delete payload.connectivityStatus;
        delete payload.supplierBranchName;
      } else {
        payload.baseMargin = (payload.margin * 100).toFixed(2);
      }
      payload.platform = payload.platform_id;
      return this._super.apply(this, arguments);
    },
    normalizeFindHasManyResponse: function normalizeFindHasManyResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayLoad = payload.map(function (resp) {
        Object.assign(resp, {
          supplier_branch_id: resp.id,
          connectivity_status: resp.connectivityStatus,
          supplier_branch_name: resp.supplierBranchName
        });
        delete resp.id;
        delete resp.connectivityStatus;
        delete resp.supplierBranchName;
        return resp;
      });
      return this._super(store, primaryModelClass, newPayLoad, id, requestType);
    }
  });
});