define('lxso/validators/offer-group-schedule-overlap', ['exports', 'ember-cp-validations/validators/base', 'lxso/utils/overlap-failure-reducer'], function (exports, _base, _overlapFailureReducer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OfferGroupOverlapValidation = _base.default.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    validate: function validate(value, options, model, attribute) {
      'use strict';

      var schedules = model.get(attribute);
      var errorMessage = this.get('i18n').t('errors.customMessages.offerGroup.duplicateSchedules').string;
      var failures = [];

      var schedulesArray = schedules.map(function (schedule, index) {
        return { startTime: schedule.get('startTime'), endTime: schedule.get('endTime') };
      });

      for (var i = 0; i < schedulesArray.get('length'); i++) {
        for (var j = 1; j < schedulesArray.get('length'); j++) {
          if (i != j && schedulesArray[i].startTime == schedulesArray[j].startTime && schedulesArray[i].endTime == schedulesArray[j].endTime) {
            failures.push('schedule' + i, 'schedule' + j);
          }
        }
      }

      if (failures.length > 0) {
        return (0, _overlapFailureReducer.overlapFailureReducer)(failures, errorMessage);
      } else {
        return true;
      }
    }
  });

  exports.default = OfferGroupOverlapValidation;
});