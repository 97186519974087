define('lxso/components/promotions/promotion-name/component', ['exports', 'lxso/utils/form-validator', 'lxso/utils/omniture'], function (exports, _formValidator, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    validateName: function validateName() {
      return (0, _formValidator.validate)({
        model: this.get('promotion'),
        attributes: ['name']
      });
    },

    actions: {
      editSection: function editSection() {
        this.sendAction('edit');
      },
      validatePromotionNameIfInvalid: function validatePromotionNameIfInvalid() {
        var _this = this;

        if (this.get('validationErrors')) {
          this.validateName().then(function () {
            _this.set('validationErrors', '');
          }).catch(function (errors) {
            _this.set('validationErrors', errors);
          });
        }
      },
      continueSection: function continueSection() {
        var _this2 = this;

        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.promotion-new.continue.name');
        this.validateName().then(function () {
          _this2.set('validationErrors', '');
          _this2.sendAction('continue');
        }).catch(function (errors) {
          _this2.set('validationErrors', errors);
        });
      }
    }
  });
});