define('lxso/mixins/date-range', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var today = moment();
  var oneHundredYearsAgo = moment().subtract(100, 'years');

  exports.default = Ember.Mixin.create({
    dateFormat: Ember.inject.service('date-format'),

    minValidDate: oneHundredYearsAgo,

    from: today,
    to: today,

    dateRange: {
      from: today,
      to: today
    },

    dateRangeFrom: Ember.computed('from', {
      get: function get(key) {
        return this.set('dateRange.from', this.parseDateParam('from'));
      },
      set: function set(key, value) {
        this.setDateParam('from', value);
        return this.set('dateRange.from', value);
      }
    }),

    dateRangeTo: Ember.computed('to', {
      get: function get(key) {
        return this.set('dateRange.to', this.parseDateParam('to'));
      },
      set: function set(key, value) {
        this.setDateParam('to', value);
        return this.set('dateRange.to', value);
      }
    }),

    parseDateParam: function parseDateParam(paramName) {
      var dateFormat = this.get('dateFormat.URL_DATE_FORMAT');
      return moment(this.get(paramName), dateFormat, true);
    },
    setDateParam: function setDateParam(paramName, date) {
      var dateFormat = this.get('dateFormat.URL_DATE_FORMAT');

      if (date && date.isValid()) {
        var formatedDate = date.format(dateFormat);
        this.set(paramName, formatedDate);
        this.set('model.' + paramName, formatedDate);
      }
    },


    actions: {
      updateFrom: function updateFrom(date) {
        'use strict';

        this.set('dateRangeFrom', date);
      },
      updateTo: function updateTo(date) {
        'use strict';

        this.set('dateRangeTo', date);
      },
      setValidity: function setValidity(dateRangeValidity) {
        'use strict';

        this.set('dateRangeIsValid', dateRangeValidity.from && dateRangeValidity.to);
      }
    }
  });
});