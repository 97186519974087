define('lxso/adapters/campaign-meta-data', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    namespace: 'api/v3',

    findRecord: function findRecord(store, type, campaignId) {
      var url = this.host + '/' + this.namespace + '/campaign/' + campaignId;
      return this.ajax(url, 'GET');
    }
  });
});