define('lxso/models/option-group', ['exports', 'ember-data-model-fragments/attributes', 'lxso/utils/validation', 'ember-data', 'lxso/utils/regexPatterns'], function (exports, _attributes, _validation, _emberData, _regexPatterns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Validations = (0, _validation.build)({
    shortTitle: {
      descriptionKey: 'pages.optionGroup.optionGroupSection.details.optionGroupTitle',
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('length', {
        min: 4,
        disabled: Ember.computed.not('model.shortTitle')
      }), (0, _validation.validator)('format', {
        regex: _regexPatterns.validForm.validFormChar,
        messageKey: 'errors.customMessages.offers.invalidChars',
        disabled: Ember.computed.not('model.shortTitle')
      })]
    },
    offers: {
      validators: [(0, _validation.validator)('option-group-array-length', {
        volatile: true
      })]
    },
    optionGroupOverlap: (0, _validation.validator)('option-group-overlap', {
      volatile: true
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    offerGroupId: _emberData.default.attr('number'),
    activityId: _emberData.default.attr('number'),
    shortTitle: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    bookingCutoff: (0, _attributes.fragment)('fragments/option-group/booking-cutoff'),
    cancellationPolicy: (0, _attributes.fragment)('fragments/option-group/cancellation-policy'),
    attributes: (0, _attributes.fragment)('fragments/option-group/attributes'),
    pickupEnabled: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    pickupInfo: (0, _attributes.fragment)('fragments/option-group/pickup-info'),
    offers: (0, _attributes.fragmentArray)('fragments/option-group/offer-detail')
  });
});