define('lxso/helpers/ember-object-to-json', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.emberObjectToJson = emberObjectToJson;
  function emberObjectToJson(params) {
    return JSON.parse(JSON.stringify(params[0]));
  }

  exports.default = Ember.Helper.helper(emberObjectToJson);
});