define('lxso/components/promotions/promotion-modal/promotion-select-products/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      onProductSelect: function onProductSelect(product) {
        if (product.get('isProductSelected')) {
          product.set('isProductSelected', false);
        } else {
          product.set('isProductSelected', true);
        }

        var isAnyProductSelected = this.get('productList').any(function (product) {
          return product.isProductSelected;
        });

        if (isAnyProductSelected) {
          this.sendAction('setProductModalSave', true);
        } else {
          this.sendAction('setProductModalSave', false);
        }
      },
      selectAllProducts: function selectAllProducts(productList) {
        productList.forEach(function (product) {
          product.set('isProductSelected', true);
        });
        this.sendAction('setProductModalSave', true);
      },
      deselectAllProducts: function deselectAllProducts(productList) {
        productList.forEach(function (product) {
          product.set('isProductSelected', false);
        });
        this.sendAction('setProductModalSave', false);
      }
    }
  });
});