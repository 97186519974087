define('lxso/components/bulk-update-price-extend/component', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    responseErrors: Ember.inject.service(),
    fileSaver: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        travelEndDatePickerLowerBound: moment().add(1, 'days').format('MM/DD/YYYY'),
        bulkUploadFileName: '',
        optionIdsColName: 'Option IDs',
        processingRequest: false,
        travelEndDate: ''
      });
    },


    actions: {
      uploadFile: function uploadFile(file) {
        if (file && file.target.files[0] && file.target.files[0].name) {
          this.set('bulkUploadFileName', file.target.files[0].name);
        } else {
          this.set('bulkUploadFileName', undefined);
        }
      },
      triggerFileSelect: function triggerFileSelect() {
        $('#bulk-upload-file').trigger('click');
      },
      downloadSampleCSV: function downloadSampleCSV() {
        var sampleData = this.get('optionIdsColName') + '\n101\n102';
        var fileName = 'bulk-extend-prices-sample.csv';
        var blob = new Blob(['\uFEFF', sampleData], { type: 'text/csv' });
        this.get('fileSaver').saveAs(blob, fileName);
      },
      resetFile: function resetFile() {
        $('#bulk-upload-file').val('');
        this.set('bulkUploadFileName', undefined);
      },
      submitForm: function submitForm() {
        var _this = this;

        this.get('responseErrors').clearErrors();
        var csvFile = document.getElementById('bulk-upload-file').files[0];
        if (csvFile) {
          this.set('processingRequest', true);
          this.getText(csvFile).then(function (data) {
            return _this.validateCsvFileAndGetRows(data);
          }).then(function (rows) {
            var url = _environment.default.api.host + '/api/v3/bulkUpdate/priceEndDateExtend';
            var body = _this.getBulkUpdateRequestBody(rows, _this.get('travelEndDate'));
            return _this.get('ajaxWrapper').ajaxPost(url, body);
          }).then(function (response) {
            _this.get('notifications').success(_this.get('i18n').t('pages.bulkUpdate.priceExtension.successMessage'));
            _this.set('errors', null);
          }).catch(function (errors) {
            if (errors.responseJSON) {
              _this.get('responseErrors').addErrors(errors.responseJSON, errors.status);
            } else {
              _this.set('errors', errors);
            }
          }).finally(function () {
            _this.set('processingRequest', false);
            _this.clearForm();
          });
        }
      },
      setTravelDate: function setTravelDate(date) {
        this.set('travelEndDate', date);
      }
    },

    getText: function getText(file) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
          return resolve(reader.result);
        };
        reader.onerror = function (error) {
          return reject(error);
        };
      });
    },
    validateCsvFileAndGetRows: function validateCsvFileAndGetRows(data) {
      var _this2 = this;

      var optionIdColName = this.get('optionIdsColName');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var rows = _this2.getCsvRows(data).filter(function (n) {
          return n;
        });
        var validationErrors = [];
        var headRow = rows.length > 0 ? rows[0].trim() : null;
        if (headRow != optionIdColName) {
          validationErrors.push('Column "' + optionIdColName + '" missing');
        } else {
          if (rows.length == 1) {
            validationErrors.push('No options found');
          }
          if (rows.length > 1001) {
            validationErrors.push('Maximum of 1000 ' + optionIdColName + ' are supported at a time');
          }
        }
        if (validationErrors.length) {
          reject({ validationErrors: validationErrors });
        } else {
          resolve(rows);
        }
      });
    },
    getBulkUpdateRequestBody: function getBulkUpdateRequestBody(rows, travelEndDate) {
      return {
        optionIds: rows.splice(1).map(function (optionId) {
          return parseInt(optionId);
        }),
        travelEndDate: moment(travelEndDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
      };
    },
    clearForm: function clearForm() {
      this.send('resetFile');
      this.set('travelEndDate', '');
    },
    getCsvRows: function getCsvRows(data) {
      return data.split(/\r\n|\r|\n/);
    }
  });
});