define('lxso/models/fragments/prices/availability', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _emberDataModelFragments, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    startDate: _emberData.default.attr('date-string'),
    endDate: _emberData.default.attr('date-string'),
    availability: (0, _attributes.fragmentArray)('fragments/prices/day-availability')
  });
});