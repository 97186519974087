define('lxso/models/supplier-branch/notification-setting', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    notificationSettingId: _emberData.default.attr('number'),
    supplierBranchId: _emberData.default.attr('number'),
    notificationTypeId: _emberData.default.attr('string'),
    supplierEmail: _emberData.default.attr('string'),
    sendBeforeDays: _emberData.default.attr('number'),
    isInstant: _emberData.default.attr('boolean'),
    instantBookingCutOffHrs: _emberData.default.attr('number')
  });
});