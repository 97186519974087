define('lxso/routes/account-settings/change-password', ['exports', 'lxso/utils/form-validator', 'lxso/mixins/feature-flag'], function (exports, _formValidator, _featureFlag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    userMigration: Ember.inject.service('user-migration'),

    model: function model() {
      return this.store.createRecord('account-settings/change-password', {
        password: '',
        confirmPassword: ''
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var needsMigration = this.get('userMigration').isNeeded();
      var isNewUser = this.get('userMigration').isNewUser();

      controller.set('forced', needsMigration || isNewUser);
    },


    actions: {
      changePassword: function changePassword() {
        var _this = this;

        var controller = this.controller;
        var model = this.modelFor('account-settings/change-password');

        (0, _formValidator.validate)({ model: model, attributes: ['password', 'confirmPassword'] }).then(function () {
          return model.save();
        }).then(function () {
          controller.set('password', '');
          controller.set('confirmPassword', '');
          _this.get('notifications').success(_this.get('i18n').t('pages.changePassword.alerts.saveSuccess'));
          setTimeout(function () {
            _this.transitionTo('logout');
          }, 1000);
        }).catch(function (errors) {
          controller.set('errors', errors);
          if (errors.forEach) {
            Object.keys(errors).forEach(function (key) {
              return errors[key].forEach(function (error) {
                return _this.get('notifications').error(error);
              });
            });
          } else {
            console.error('!!! Save error can not be iterated: ', errors);
          }
        });
      }
    }
  });
});