define('lxso/models/supplier', ['exports', 'ember-data', 'ember-api-actions', 'lxso/utils/validation'], function (exports, _emberData, _emberApiActions, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    name: [(0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'pages.newSupplier.validation.supplierName'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string', { defaultValue: '' }),
    vendor_id: _emberData.default.attr('number', { defaultValue: 0 }),
    reviews: (0, _emberApiActions.instanceOp)({
      path: 'reviews',
      type: 'get'
    })
  });
});