define('lxso/validators/valid-min-max-range', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ValidMinMaxRange = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      'use strict';

      if (options.min > options.max || options.max < options.min) {
        return model.get('i18n').t('errors.customMessages.offers.individualGroupRange').string;
      } else {
        return true;
      }
    }
  });

  exports.default = ValidMinMaxRange;
});