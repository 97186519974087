define('lxso/components/bulk-update-3p-status/component', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SUPPLIER_BRANCH_COL_NAME = 'SupplierBranchId';
  var PRODUCTS_COL_NAME = 'ActivityId';
  var SAMPLE_FILE_NAME = 'bulk-update-3p-sample.csv';
  var FAILED_REPORT_FILE_NAME = 'bulk-update-3p-failure-report.csv';

  exports.default = Ember.Component.extend({
    responseErrors: Ember.inject.service(),
    fileSaver: Ember.inject.service(),
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        bulkUploadFile: null,
        processingRequest: false,
        selectedStatus: 'deactivation',
        selectedDeactivationReason: null,
        hasFailures: false,

        deactivationReasons: [this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.duplicate'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.restrictedLocation'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.restrictedGeography'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.healthAndSafety'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.prohibitedContent'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.localizedContent'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.poorContent'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.pricingError'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.bug'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.fraud'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.prohibitedGroundTransport'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.destinationCleanUp'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.duplicate3p'), this.get('i18n').t('pages.bulkUpdate.thirdParty.deactivationReasons.other')]
      });
    },


    actions: {
      uploadFile: function uploadFile(file) {
        if (file && file.target.files[0] && file.target.files[0].name) {
          this.set('bulkUploadFile', file.target.files[0]);
        } else {
          this.set('bulkUploadFile', null);
        }
      },
      triggerFileSelect: function triggerFileSelect() {
        $('#bulk-upload-file').trigger('click');
      },
      downloadSampleCSV: function downloadSampleCSV() {
        var sampleData = SUPPLIER_BRANCH_COL_NAME + ',' + PRODUCTS_COL_NAME + '\n101,100001\n102';
        this.createCsvAndDownload(sampleData, SAMPLE_FILE_NAME);
      },
      resetFile: function resetFile() {
        $('#bulk-upload-file').val('');
        this.set('bulkUploadFile', null);
      },
      submitForm: function submitForm() {
        var _this = this;

        this.get('responseErrors').clearErrors();
        this.set('hasFailures', false);
        var formData = new FormData();
        var isDeactivation = this.get('selectedStatus') === 'deactivation';
        var reason = this.get('selectedDeactivationReason');

        if (isDeactivation === true && reason) {
          formData.append('reason', reason);
        }

        var url = isDeactivation ? _environment.default.api.host + '/api/v3/supplierBranch/deactivate' : _environment.default.api.host + '/api/v3/supplierBranch/reactivate';

        formData.append('file', this.get('bulkUploadFile'));

        this.set('processingRequest', true);
        $.ajax({
          url: url,
          type: 'PUT',
          data: formData,
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          dataType: 'json',
          processData: false,
          contentType: false,
          headers: {
            Accept: 'application/json'
          }
        }).done(function (successResponse) {
          _this.set('showBulkUploadResModal', true);
          _this.set('bulkUploadSuccessResponse', successResponse);
          if (successResponse.failedActivityIds || successResponse.failedSupplierBranchIds) {
            _this.set('hasFailures', true);
          }
        }).fail(function (errorResponse) {
          _this.get('responseErrors').addErrors(errorResponse.responseJSON, errorResponse.status);
        }).always(function () {
          _this.set('processingRequest', false);
          _this.send('resetFile');
        });
      },
      changeStatus: function changeStatus(status) {
        this.resetForm();
        this.set('selectedStatus', status);
        this.setProperties({
          selectedDeactivationReason: null
        });
      },
      changeDeactivationReason: function changeDeactivationReason(reason) {
        this.set('selectedDeactivationReason', reason);
      },
      closeBulkUploadSuccessResModal: function closeBulkUploadSuccessResModal() {
        var bulkUploadRes = this.get('bulkUploadSuccessResponse');
        if (bulkUploadRes.failedActivityIds || bulkUploadRes.failedSupplierBranchIds) {
          this.generateReportsBulkUpload(bulkUploadRes);
        }
        this.set('showBulkUploadResModal', false);
        this.set('bulkUploadSuccessResponse', null);
      }
    },

    resetForm: function resetForm() {
      this.get('responseErrors').clearErrors();
      this.send('resetFile');
    },
    generateReportsBulkUpload: function generateReportsBulkUpload(response) {
      var csvData = SUPPLIER_BRANCH_COL_NAME + ',' + PRODUCTS_COL_NAME;
      var failedSupplierBranchIds = response.failedSupplierBranchIds,
          failedActivityIds = response.failedActivityIds;

      var failedSupplierBranchIdsCount = !!failedSupplierBranchIds ? failedSupplierBranchIds.length : 0;
      var failedActivityIdsCount = !!failedActivityIds ? failedActivityIds.length : 0;
      var rowCount = Math.max(failedActivityIdsCount, failedSupplierBranchIdsCount);
      for (var i = 0; i < rowCount; i++) {
        csvData = csvData + '\r\n' + this.getValueFromArray(failedSupplierBranchIds, i) + ',';
        csvData = '' + csvData + this.getValueFromArray(failedActivityIds, i);
      }
      this.createCsvAndDownload(csvData, FAILED_REPORT_FILE_NAME);
    },
    getValueFromArray: function getValueFromArray(array, index) {
      return array && array[index] ? array[index] : '';
    },
    createCsvAndDownload: function createCsvAndDownload(data, fileName) {
      var blob = new Blob(['\uFEFF', data], { type: 'text/csv' });
      this.get('fileSaver').saveAs(blob, fileName);
    }
  });
});