define('lxso/components/availability-schedule-display/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dateFormat: Ember.inject.service('date-format'),

    formatDate: function formatDate(dateValue) {
      var pickerFormat = this.get('dateFormat.DATEPICKER_FORMAT');
      var apiFormat = this.get('dateFormat.API_DATE_FORMAT');
      var usaFormat = this.get('dateFormat.SHORT_USA_DATE_FORMAT');
      return moment(dateValue, [apiFormat, pickerFormat]).format(usaFormat);
    },


    fromDate: Ember.computed('from', function () {
      var from = this.get('from');
      return this.formatDate(from);
    }),

    toDate: Ember.computed('to', function () {
      var to = this.get('to');
      return this.formatDate(to);
    })
  });
});