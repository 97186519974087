define('lxso/helpers/errors-for-tt-overlap', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.errorsForTtOverlap = errorsForTtOverlap;


  /*
  params example
  const params = [
    "capacityTicketTypesOverlap",
    { capacityTicketTypesOverlap: ["capacityTicketTypePersonCount2:;:Min persons should be less than or equal to max"]},
    "capacityTicketTypePersonCount",
    0
  ]
  */

  function errorsForTtOverlap(params) {
    if (params[1] && params[1][params[0]] && params[1][params[0]][0]) {
      var errors = params[1][params[0]][0];
      var errorString = errors.split(':;:')[1];
      var errorIndexesArray = errors.split(':;:')[0].split(',');
      var elementIndex = '' + params[2] + params[3];

      if (!params[3] && isNaN(params[3])) {
        return [errorString];
      } else if (_lodash.default.includes(errorIndexesArray, elementIndex)) {
        return [errorString];
      }
    }
  }

  exports.default = Ember.Helper.helper(errorsForTtOverlap);
});