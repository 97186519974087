define('lxso/mixins/multi-offer-support', ['exports', 'lodash', 'lxso/constants/language-type-ids', 'lxso/utils/form-validator', 'lxso/utils/offer-group', 'lxso/utils/omniture'], function (exports, _lodash, _languageTypeIds, _formValidator, _offerGroup, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create({
    initMultipleOptionSetup: function initMultipleOptionSetup(controller) {
      var offer = controller.get('offer');
      var param = controller.get('param');
      controller.set('showGroupSchedulesAndCommentary', true);
      if (this.get('featureFlags').checkEnabled('MultipleOptionsGroup')) {
        controller.set('timeFormat', '24');
        if (this.controller.get('offer.isNew') && !(param.clone === 'true')) {
          offer.set('isMultipleOptionGroup', true);
          // Multi Option group is available only for Start time schedule.
          // By default start option is selected as schedule and we can set multi option as ON on init.
        } else if (param.clone === 'true' && offer.get('offerDetails')) {
          offer.set('isMultipleOptionGroup', true);
          this.groupSchedulesAndCommentary(offer, false);
        } else if (offer.get('offerDetails')) {
          this.groupSchedulesAndCommentary(offer, false);
        }
      }
    },
    getOfferIdForMarginAndExternalReference: function getOfferIdForMarginAndExternalReference(offer) {
      var offerId = void 0;
      if (this.get('featureFlags').checkEnabled('MultipleOptionsGroup') && offer.get('isMultipleOptionGroup') === true) {
        var offerDetails = offer.get('offerDetails');
        offerId = offerDetails && offerDetails.length && offerDetails[0].offerId ? offerDetails[0].offerId : offer.id;
      } else {
        offerId = offer.id;
      }
      return offerId;
    },
    groupSchedulesAndCommentary: function groupSchedulesAndCommentary(offer, isCancelClick) {
      this.controller.set('offer.deletedOfferDetails', []);
      //Offer deleted using delete schedule and commentary will be stored in here.
      var offerDetails = void 0;
      if (isCancelClick) {
        offerDetails = offer.get('offerDetailsCopy');
      } else {
        offerDetails = offer.get('offerDetails');
      }
      var activeOfferDetails = offerDetails.filter(function (offer) {
        return offer.status.toLowerCase() === 'active';
      });
      var noCommentaryOffers = activeOfferDetails.filter(function (offer) {
        return offer.languageInfo.languageTypeId == _languageTypeIds.LANG_TYPE_IDS.NO_COMMENTARY;
      });
      var audioCommentaryOffers = activeOfferDetails.filter(function (offer) {
        return offer.languageInfo.languageTypeId == _languageTypeIds.LANG_TYPE_IDS.AUDIO_GUIDE;
      });
      var liveCommentaryOffers = activeOfferDetails.filter(function (offer) {
        return offer.languageInfo.languageTypeId == _languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY;
      });

      var schedulesAndLanguageInfo = [];
      if (noCommentaryOffers.length) {
        var languageInfo = {
          tagId: new Date().getTime().toString(),
          languageIDs: Ember.A([]),
          languageTypeId: _languageTypeIds.LANG_TYPE_IDS.NO_COMMENTARY
        };
        var schedules = noCommentaryOffers.map(function (offer) {
          return {
            tagId: new Date().getTime().toString() + offer.offerId,
            offerIds: [offer.offerId],
            startTime: offer.startTime,
            endTime: offer.endTime,
            durationInMinutes: offer.durationInMinutes
          };
        });
        schedulesAndLanguageInfo.push({
          schedules: schedules,
          languageInfo: languageInfo,
          offerDetails: noCommentaryOffers
        });
      }

      if (audioCommentaryOffers.length) {
        if (!this.get('featureFlags').checkEnabled('MultipleOptionsGroupAllSchedules')) {
          var offersGroupedBySameLangIds = _lodash.default.groupBy(audioCommentaryOffers, function (offer) {
            return offer.languageInfo.languageIDs.join('-');
          });
          var audioCommentarySchedulesAndLanguageInfo = Object.values(offersGroupedBySameLangIds).map(function (scheduleGroup) {
            var languageInfo = {
              tagId: new Date().getTime().toString(),
              languageIDs: Ember.A(scheduleGroup[0].languageInfo.languageIDs),
              languageTypeId: scheduleGroup[0].languageInfo.languageTypeId
            };
            var schedules = scheduleGroup.map(function (offer) {
              return {
                tagId: new Date().getTime().toString() + offer.offerId,
                offerIds: [offer.offerId],
                startTime: offer.startTime,
                endTime: offer.endTime,
                durationInMinutes: offer.durationInMinutes
              };
            });
            return {
              schedules: schedules,
              languageInfo: languageInfo,
              offerDetails: scheduleGroup
            };
          });
          schedulesAndLanguageInfo.push.apply(schedulesAndLanguageInfo, _toConsumableArray(audioCommentarySchedulesAndLanguageInfo));
        } else {
          this.setCommentaryStuff(schedulesAndLanguageInfo, audioCommentaryOffers, _languageTypeIds.LANG_TYPE_IDS.AUDIO_GUIDE);
        }
      }

      if (liveCommentaryOffers.length) {
        this.setCommentaryStuff(schedulesAndLanguageInfo, liveCommentaryOffers, _languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY);
      }
      offer.set('schedulesAndLanguageInfo', schedulesAndLanguageInfo);
    },
    setCommentaryStuff: function setCommentaryStuff(schedulesAndLanguageInfo, commentaryOffers, commentaryType) {
      var offersGroupedBySameLangIds = _lodash.default.groupBy(commentaryOffers, function (offer) {
        return offer.languageInfo.languageIDs.join('-');
      });
      var offersGroupedBySameLangIdsValues = Object.values(offersGroupedBySameLangIds);
      var offersGroupedBySameLangIdsAndSameSchedules = _lodash.default.groupBy(offersGroupedBySameLangIdsValues, function (scheduleGroup) {
        var scheduleHash = scheduleGroup.reduce(function (schedulesHash, schedule) {
          var schedulesHashUpdated = schedulesHash ? schedulesHash + ' :: ' + schedule.startTime + ' - ' + schedule.endTime + ' - ' + schedule.durationInMinutes : schedule.startTime + ' - ' + schedule.endTime + ' - ' + schedule.durationInMinutes;
          return schedulesHashUpdated;
        }, '');
        return scheduleHash;
      });

      var _loop = function _loop(scheduleGroupKey) {
        var scheduleGroup = offersGroupedBySameLangIdsAndSameSchedules[scheduleGroupKey];
        var langIdOffersMap = new Map();
        scheduleGroup.forEach(function (offerGroupOfSameLangId) {
          var offerIdsLinkedToGivenLangId = offerGroupOfSameLangId.reduce(function (offerIds, offerDetail) {
            return [].concat(_toConsumableArray(offerIds), [offerDetail.offerId]);
          }, []);
          var commonLangId = offerGroupOfSameLangId[0].languageInfo.languageIDs[0];
          langIdOffersMap.set(commonLangId, offerIdsLinkedToGivenLangId);
        });

        var allLangIds = scheduleGroup.reduce(function (langIds, schedulesOfSameLang) {
          return [].concat(_toConsumableArray(langIds), _toConsumableArray(schedulesOfSameLang[0].languageInfo.languageIDs));
        }, []);
        var allOffers = scheduleGroup.reduce(function (allOffers, offersGroupedByLangId) {
          return [].concat(_toConsumableArray(allOffers), _toConsumableArray(offersGroupedByLangId));
        }, []);
        var offersGroupedByStartEnd = _lodash.default.groupBy(allOffers, function (offer) {
          return offer.startTime + ' - ' + offer.endTime + ' - ' + offer.durationInMinutes;
        });
        var allOfferDetailsInGivenScheduleAndCommentary = scheduleGroup.reduce(function (offerDetailsPerGroup, offerDetailsPerLangGroup) {
          return [].concat(_toConsumableArray(offerDetailsPerGroup), _toConsumableArray(offerDetailsPerLangGroup));
        }, []);

        var allLangOfferIds = [];
        var schedules = [];
        for (var offersWithSameStartEndKey in offersGroupedByStartEnd) {
          var offers = offersGroupedByStartEnd[offersWithSameStartEndKey];
          var offerIds = offers.map(function (offer) {
            return offer.offerId;
          });
          allLangOfferIds.push.apply(allLangOfferIds, _toConsumableArray(offerIds));

          var schedule = {
            tagId: new Date().getTime().toString() + ' : ' + offerIds.join('-'),
            offerIds: offerIds,
            startTime: offers[0].startTime,
            endTime: offers[0].endTime,
            durationInMinutes: offers[0].durationInMinutes
          };
          schedules.push(schedule);
        }
        var languageInfo = {
          languageIDs: Ember.A(allLangIds),
          langIdOffersMap: langIdOffersMap,
          languageTypeId: commentaryType,
          tagId: new Date().getTime().toString() + ' : ' + allLangOfferIds.join('-')

        };
        schedulesAndLanguageInfo.push({
          offerDetails: allOfferDetailsInGivenScheduleAndCommentary,
          languageInfo: languageInfo,
          schedules: schedules
        });
      };

      for (var scheduleGroupKey in offersGroupedBySameLangIdsAndSameSchedules) {
        _loop(scheduleGroupKey);
      }
    },
    setMultiOfferModel: function setMultiOfferModel(offer) {
      var _this = this;

      if (!(this.get('featureFlags').checkEnabled('MultipleOptionsGroup') && offer.get('isMultipleOptionGroup') === true)) {
        return;
      }
      var schedulesAndLanguageGroups = offer.get('schedulesAndLanguageInfo');
      var offerDetails = this.controller.get('offer.deletedOfferDetails') || [];
      //Initialize offerDetails with offers deleted using `Delete schedule and commentary` link
      schedulesAndLanguageGroups.forEach(function (schedulesAndLanguageGroup) {
        var inactiveOfferDetails = _this.getInactiveOfferDetails(schedulesAndLanguageGroup);
        var schedules = schedulesAndLanguageGroup.get('schedules');
        var languageInfo = schedulesAndLanguageGroup.get('languageInfo');
        var schedulesMappedWithLanguages = [];
        if (languageInfo.get('languageTypeId') == _languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY || languageInfo.get('languageTypeId') == _languageTypeIds.LANG_TYPE_IDS.AUDIO_GUIDE && _this.get('featureFlags').checkEnabled('MultipleOptionsGroupAllSchedules')) {
          var _langIdOffersMap = languageInfo.langIdOffersMap || languageInfo.data.langIdOffersMap;
          // ToDo: Refactor above line of code
          languageInfo.get('languageIDs').forEach(function (langId) {
            var schedulesMappedWithSingleLangId = schedules.map(function (schedule) {
              var offerDetail = {
                startTime: schedule.get('startTime'),
                endTime: schedule.get('endTime'),
                durationInMinutes: schedule.get('durationInMinutes'),
                status: schedule.get('status'),
                languageInfo: {
                  languageIDs: [langId],
                  languageTypeId: languageInfo.get('languageTypeId')
                }
              };
              if (!offer.get('isNew')) {
                _this.setOfferIdToUpdate(offerDetail, schedule, _langIdOffersMap, langId);
              }
              return offerDetail;
            });
            schedulesMappedWithLanguages = [].concat(_toConsumableArray(schedulesMappedWithLanguages), _toConsumableArray(schedulesMappedWithSingleLangId));
          });
        } else {
          var schedulesMappedWithLanguageInfo = schedules.map(function (schedule) {
            return {
              offerId: schedule.get('offerIds')[0],
              startTime: schedule.get('startTime'),
              endTime: schedule.get('endTime'),
              status: schedule.get('status'),
              durationInMinutes: schedule.get('durationInMinutes'),
              languageInfo: {
                languageIDs: languageInfo.get('languageIDs'),
                languageTypeId: languageInfo.get('languageTypeId')
              }
            };
          });
          schedulesMappedWithLanguages = [].concat(_toConsumableArray(schedulesMappedWithLanguages), _toConsumableArray(schedulesMappedWithLanguageInfo));
        }
        offerDetails = [].concat(_toConsumableArray(offerDetails), _toConsumableArray(schedulesMappedWithLanguages), _toConsumableArray(inactiveOfferDetails));
      });
      offer.set('offerDetails', offerDetails);
      var hasNewOffers = offerDetails.filter(function (offer) {
        return !offer.offerId;
      }).length > 0;
      this.controller.set('hasNewOffers', hasNewOffers);
    },
    getInactiveOfferDetails: function getInactiveOfferDetails(schedulesAndLanguageGroup) {
      return schedulesAndLanguageGroup.get('offerDetails').filter(function (offer) {
        return offer.status === 'inactive' && offer.offerId;
      });
    },
    setOfferIdToUpdate: function setOfferIdToUpdate(offerDetail, schedule, langIdOffersMap, langId) {
      if (langIdOffersMap) {
        var scheduleOfferIds = schedule.get('offerIds');
        var offerIdsMappedToLangId = langIdOffersMap.get(langId);
        if (offerIdsMappedToLangId && offerIdsMappedToLangId.length) {
          var commonOfferIds = offerIdsMappedToLangId.filter(function (offerId) {
            return scheduleOfferIds.includes(offerId);
          });
          if (commonOfferIds.length) {
            offerDetail.offerId = commonOfferIds[0];
          }
        }
      }
    },
    setOfferGroupCopy: function setOfferGroupCopy() {
      if (this.isOfferGroupAndFlagEnabled()) {
        var offerDetails = JSON.parse(JSON.stringify(this.controller.get('offer.offerDetails')));
        this.controller.set('offer.offerDetailsCopy', offerDetails);
      }
    },
    rollBackOfferGroup: function rollBackOfferGroup() {
      if (this.isOfferGroupAndFlagEnabled()) {
        this.groupSchedulesAndCommentary(this.controller.get('offer'), true);
      }
    },
    isOfferGroupAndFlagEnabled: function isOfferGroupAndFlagEnabled() {
      return this.get('featureFlags').checkEnabled('MultipleOptionsGroup') && this.controller.get('offer.isMultipleOptionGroup');
    },
    validateOfferGroupFields: function validateOfferGroupFields(nonOfferGroupValidations) {
      var schedulesAndLanguageInfo = this.controller.get('offer.schedulesAndLanguageInfo');
      var scheduleAttrs = this.controller.get('offer.offerAttributes.scheduleType') == 'Start' || this.controller.get('offer.offerAttributes.scheduleType') == 'Multi-day' ? ['startTime', 'endTime'] : [];
      var langAttrs = ['languageTypeId', 'languageIDs'];
      var allErrors = {};
      var hasNonOfferGroupErrors = false;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        nonOfferGroupValidations.catch(function (errors) {
          Object.assign(allErrors, errors);
          hasNonOfferGroupErrors = true;
        }).finally(function () {
          var scheduleValidations = schedulesAndLanguageInfo.map(function (scheduleAndLangInfo) {
            return Ember.RSVP.allSettled(scheduleAndLangInfo.get('schedules').map(function (schedule) {
              return (0, _formValidator.validate)({
                model: schedule,
                attributes: scheduleAttrs
              });
            }));
          });

          var langInfoValidations = schedulesAndLanguageInfo.map(function (scheduleAndLangInfo) {
            return (0, _formValidator.validate)({
              model: scheduleAndLangInfo.get('languageInfo'), attributes: langAttrs
            }, {
              model: scheduleAndLangInfo, attributes: ['schedules']
            });
          });

          Ember.RSVP.allSettled(scheduleValidations).then(function (schedulesValidated) {
            Ember.RSVP.allSettled(langInfoValidations).then(function (langInfoValidated) {
              var flattenedScheduleAndLangInfo = schedulesValidated.map(function (item) {
                return item.value;
              }).flat();
              if ((0, _offerGroup.anyRejects)(flattenedScheduleAndLangInfo) || (0, _offerGroup.anyRejects)(langInfoValidated) || hasNonOfferGroupErrors) {
                Object.assign(allErrors, {
                  schedules: (0, _offerGroup.normalizeScheduleErrors)(schedulesValidated),
                  schedulesAndLangInfo: (0, _offerGroup.normalizeLangErrors)(langInfoValidated)
                });
                reject(allErrors);
              } else {
                resolve();
              }
            });
          });
        });
      });
    },


    actions: {
      addScheduleAndCommentary: function addScheduleAndCommentary() {
        this.controller.get('offer.schedulesAndLanguageInfo').createFragment({
          schedules: [{
            tagId: new Date().getTime().toString(),
            startTime: '',
            endTime: ''
          }],
          languageInfo: {
            tagId: new Date().getTime().toString(),
            languageIDs: Ember.A([5]),
            languageTypeId: 0
          }
        });
        this.setDirtyAttribute();
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AddAnotherScheduleAndCommentary');
      },
      deleteScheduleAndCommentary: function deleteScheduleAndCommentary(scheduleAndLangInfo, salIndex) {
        this.controller.get('offer.schedulesAndLanguageInfo').removeObject(scheduleAndLangInfo);
        this.setDirtyAttribute();
        var offerDetailsToRemove = scheduleAndLangInfo.get('offerDetails').filter(function (offer) {
          return offer.offerId;
        }).map(function (offer) {
          return Object.assign({}, offer, {
            status: 'inactive'
          });
        });
        var deletedOffers = this.controller.get('offer.deletedOfferDetails');
        var updatedDeletedOffers = [].concat(_toConsumableArray(deletedOffers), _toConsumableArray(offerDetailsToRemove));
        this.controller.set('offer.deletedOfferDetails', updatedDeletedOffers);
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.DeleteScheduleAndCommentary');
      },
      saveOfferAndPrice: function saveOfferAndPrice() {
        var _this2 = this;

        // We are not using this function now. Refactor as necessary and use this logic
        // if we need to save offer and price together.
        var priceGroup = this.controller.get('offer.prices').objectAt(0);
        var offerGroupId = void 0;
        this.controller.set('lastUpdated', 'priceGroup');
        this.removeDispersedTickets(priceGroup);
        var activityId = this.controller.get('activity.id');
        var pgIndex = 0;

        this.validatePriceFields(priceGroup).then(function () {
          return _this2.controller.get('offer').save({ adapterOptions: { activityId: activityId } });
        }).then(function (createdOffer) {
          _this2.controller.set('savingPg', true);
          var offer = _this2.controller.get('offer');
          offerGroupId = createdOffer.id;
          var isMultipleOptionGroup = offer.get('isMultipleOptionGroup');
          var isLimitedInventory = offer.get('isLimitedInventory');
          var isStartTime = offer.get('offerAttributes.scheduleType') === 'Start';

          // this is only to fix the specific case for editing start time && limited inventory
          // further discussion is pending to refactor this
          if (isLimitedInventory && isStartTime) {
            priceGroup.get('availabilities').forEach(function (availabilities) {
              availabilities.get('availability').forEach(function (dayAvail) {
                var isAvailable = dayAvail.get('capacity') > 0;
                dayAvail.set('available', isAvailable);
              });
            });
          }

          var currentPg = _this2.controller.get('currentPg');
          return priceGroup.save({ adapterOptions: { offerId: offerGroupId, oldPg: currentPg,
              isOfferGroup: isMultipleOptionGroup } });
        }).then(function (priceGroup) {
          //ember isn't updating id's of new price (ticket-details)
          //and creates new ones
          //this removes the stale prices (ticket-detail) after save
          var pricesToRemove = priceGroup.get('prices').map(function (price) {
            if (!price.get('id')) {
              return price;
            }
          }).compact();
          pricesToRemove.forEach(function (priceToRemove) {
            return priceToRemove.rollbackAttributes();
          });

          _this2.controller.set('editingPg', false);
          _this2.controller.set('editingPgIndex', null);
          _this2.controller.set('dirty', false);

          _this2.controller.set('saveStep', 2);
          _this2.get('notifications').success(_this2.get('i18n').t('components.alert.saveSuccess'));
          _this2.transitionTo('activity.offer', offerGroupId, { queryParams: { isOfferGroup: true } });
        }).catch(function (pricingErrors) {
          Ember.Logger.error('error saving price group', pricingErrors);
          _this2.controller.set('editingPgIndex', pgIndex);
          _this2.controller.set('pricingErrors', Object.assign({}, pricingErrors));
          (0, _formValidator.scrollToFirstError)();
        }).finally(function () {
          _this2.controller.set('savingPg', false);
        });
      },
      continueOfferGroup: function continueOfferGroup() {
        var _this3 = this;

        // We are not using this function now. Refactor as necessary and
        // use it for running just offer validation and use saveOfferAndPrice fn for saving offer and price together
        this.controller.set('errors', undefined);
        this.controller.set('lastUpdated', 'saveOffer');
        this.controller.set('showScheduleChangeConfirmationModal', false);
        this.setMultiOfferModel(this.controller.get('offer'));
        this.validateFields().then(function () {
          // this.controller.set('saving', true);
          _this3.generateLongTitle();
          _this3.verifyPublishAttributes();
          _this3.controller.set('offer.setupComplete', true);
          _this3.controller.set('offer.editingSetup', false);
          CKEDITOR.instances['lx-ckeditor textarea-offer-description'].setReadOnly(true);
          _this3.send('openStep', 'setup-prices');
        }).catch(function (error) {
          _this3.controller.set('saving', false);
          _this3.controller.set('errors', error);
          Ember.Logger.error('error saving offer', error);
          (0, _formValidator.scrollToFirstError)();
        });
      },
      validDaysChangeSchedule: function validDaysChangeSchedule(schedule, days) {
        var durationInMinutes = parseInt(days) * 24 * 60;
        schedule.set('durationInMinutes', durationInMinutes);
      }
    }
  });
});