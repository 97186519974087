define('lxso/utils/string-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.presentable = presentable;


  /**
   * Creates presentable strings from templates
   * @example
   * // returns 'by Robert C. Martin' if author='Robert C. Martin'
   * // returns '' if author=undefined
   * presentable`by ${author}`
   * @param {string[]} strings the string parts from the template
   * @param {...*} args the values to be inserted into the string
   * @return {string} the substituted string or an empty string if any of the args was empty
   */
  function presentable(strings) {
    for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    if (args.any(Ember.isEmpty)) {
      return '';
    }

    return strings.reduce(function (result, nextPart, i) {
      var nextArg = args[i - 1];
      return '' + result + nextArg + nextPart;
    });
  }
});