define('lxso/models/fragments/option-group/attributes', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    mealType: {
      descriptionKey: 'pages.optionGroup.optionGroupSection.details.addOns.label.foodAndBeverages',
      validators: [(0, _validation.validator)('presence', true)]
    },
    optionGroupDuration: {
      validators: [(0, _validation.validator)('option-group-duration', {
        volatile: true
      })]
    },
    validityDays: {
      validators: [(0, _validation.validator)('option-group-validity-days', {
        volatile: true
      })]
    }
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    availabilityType: _emberData.default.attr('string'),
    durationInMinutes: _emberData.default.attr('number'),
    autoRenewRateEnabled: _emberData.default.attr('boolean'),
    pricesByVolume: _emberData.default.attr('boolean'),
    pricingIngestionEnabled: _emberData.default.attr('boolean'),
    mealType: _emberData.default.attr('string'),
    scheduleType: _emberData.default.attr('string'),
    validityDays: _emberData.default.attr('number'),
    durationDaysPart: _emberData.default.attr('number'),
    durationHoursPart: _emberData.default.attr('number'),
    durationMinutesPart: _emberData.default.attr('number')
  });
});