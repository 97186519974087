define('lxso/models/activity/v2offer', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'ember-api-actions'], function (exports, _emberData, _attributes, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    offer_id: _emberData.default.attr('number'),
    internal_title: _emberData.default.attr('string'),
    oiid: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    isConnectivityEnabled: _emberData.default.attr('boolean'),
    attributes: (0, _attributes.fragment)('activity/v2offer/offer-attributes', { defaultValue: {
        airport: '',
        // airportLabel: '',
        direction: '',
        zones: function zones() {
          return [];
        },
        duration_type: 'typical',
        duration: 0,
        airline: '',
        hotel: ''
      } }),

    catalogs: _emberData.default.attr('array'),

    updateCatalogs: (0, _emberApiActions.instanceOp)({
      path: 'catalogs',
      type: 'put'
    }),
    getCatalogs: (0, _emberApiActions.instanceOp)({
      path: 'catalogs',
      type: 'get'
    }),
    updateAttributes: (0, _emberApiActions.instanceOp)({
      path: 'attributes',
      type: 'put'
    }),
    getAttributes: (0, _emberApiActions.instanceOp)({
      path: 'attributes',
      type: 'get'
    }),
    getGTAttributes: (0, _emberApiActions.instanceOp)({
      path: 'attributes?fields=gt',
      type: 'get'
    }),
    updateConnectivityStatus: (0, _emberApiActions.instanceOp)({
      path: 'external_references',
      type: 'put'
    }),
    createTshopId: (0, _emberApiActions.instanceOp)({
      path: 'oiid',
      type: 'post'
    })
  });
});