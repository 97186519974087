define('lxso/services/session-validator', ['exports', 'lxso/utils/http-status-codes'], function (exports, _httpStatusCodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var invalidStatuses = new Set([_httpStatusCodes.Unauthorized, _httpStatusCodes.Forbidden]);

  exports.default = Ember.Service.extend({
    validate: function validate(status, proceed, logout) {
      if (invalidStatuses.has(status)) {
        sessionStorage.setItem('autoSessionLogout', true);
        return logout();
      }

      return proceed();
    }
  });
});