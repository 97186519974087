define('lxso/serializers/stop-sell', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'uid',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, { stopSell: payload }, id, requestType);
    }
  });
});