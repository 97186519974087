define('lxso/components/video-upload/component', ['exports', 'lxso/mixins/example-modal'], function (exports, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_exampleModal.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    isGTBranch: Ember.computed(function () {
      return this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
    }),
    rejectReasons: Ember.computed(function () {
      return Ember.A([this.get('i18n').t('pages.videos.rejectReasons.inappropriateContent'), this.get('i18n').t('pages.videos.rejectReasons.inaccurateContent'), this.get('i18n').t('pages.videos.rejectReasons.poorSound'), this.get('i18n').t('pages.videos.rejectReasons.poorVideo'), this.get('i18n').t('pages.videos.rejectReasons.copyRight'), this.get('i18n').t('pages.videos.rejectReasons.thirdPartyVideo')]);
    }),
    actions: {
      saveVideoLink: function saveVideoLink(video) {
        this.saveVideo(video);
      },
      deleteVideo: function deleteVideo(video) {
        this.deleteVideo(video);
      },
      rejectVideo: function rejectVideo(video) {
        this.rejectVideo(video);
      },
      approveVideo: function approveVideo(video) {
        this.approveVideo(video);
      },
      rejectReasonChange: function rejectReasonChange(reason) {
        this.rejectReasonChange(reason);
      }
    }
  });
});