define('lxso/services/json-to-xml-spreadsheet', ['exports', 'lxso/utils/reports-relabel'], function (exports, _reportsRelabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultNameSpace = 'urn:schemas-microsoft-com:office:spreadsheet';

  var emptyDocument = '<?xml version="1.0" encoding="UTF-8"?>\n<?mso-application progid="Excel.Sheet"?>\n<Workbook xmlns="' + defaultNameSpace + '" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n  <ss:Worksheet ss:Name="Report"/>\n</Workbook>';

  var cellTypes = new Map([['string', { typeName: 'String', format: function format(x) {
      return x;
    } }], ['number', { typeName: 'Number', format: function format(x) {
      return x;
    } }], ['date', { typeName: 'DateTime', format: function format(x) {
      return x.toISOString();
    } }], ['boolean', { typeName: 'Boolean', format: function format(x) {
      return Number(x);
    } }]]);

  function findCellType(type) {
    return cellTypes.has(type) ? cellTypes.get(type) : cellTypes.get('string');
  }

  function elementCreator(spreadsheet) {
    function element(tagName) {
      return spreadsheet.createElementNS(defaultNameSpace, tagName);
    }

    function data(item) {
      var dataElement = element('Data');
      var cellType = findCellType(item.type);
      dataElement.setAttribute('ss:Type', cellType.typeName);
      dataElement.textContent = cellType.format(item.value);
      return dataElement;
    }

    function cell(item) {
      var cellElement = element('Cell');
      cellElement.appendChild(data(item));
      return cellElement;
    }

    return {
      row: function row(items) {
        var rowElement = element('Row');
        items.forEach(function (item) {
          rowElement.appendChild(cell(item));
        });
        return rowElement;
      },
      table: function table() {
        return element('Table');
      }
    };
  }

  function transform(recordAttributeMap, getValue) {
    var result = [];
    recordAttributeMap.forEach(function (value, key) {
      result.push({ type: value.type, value: getValue(key) });
    });
    return result;
  }

  function headers(recordAttributeMap) {
    return transform(recordAttributeMap, _reportsRelabel.default);
  }

  function data(recordAttributeMap, object) {
    return transform(recordAttributeMap, function (key) {
      return object.get(key);
    });
  }

  var parser = new DOMParser();

  function createSpreadsheet() {
    return parser.parseFromString(emptyDocument, 'application/xml');
  }

  var serializer = new XMLSerializer();

  exports.default = Ember.Service.extend({
    convert: function convert(objects, recordAttributeMap, includeHeaderRow) {
      var spreadsheet = createSpreadsheet();
      var create = elementCreator(spreadsheet);

      var table = create.table();

      if (includeHeaderRow) {
        table.appendChild(create.row(headers(recordAttributeMap)));
      }

      objects.map(function (object) {
        return create.row(data(recordAttributeMap, object));
      }).forEach(function (row) {
        return table.appendChild(row);
      });

      spreadsheet.getElementsByTagNameNS(defaultNameSpace, 'Worksheet')[0].appendChild(table);
      return serializer.serializeToString(spreadsheet);
    }
  });
});