define('lxso/adapters/platform', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'api/v1',

    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.host + '/' + this.namespace + '/platforms/';

      serializer.serializeIntoHash(data, type, snapshot);
      var payload = {
        name: data.name,
        email: data.supportDistributionList
      };

      return this.ajax(url, 'POST', { data: payload });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);

      data.email = data.supportDistributionList;
      delete data.supportDistributionList;
      var platformId = snapshot.id;
      var url = this.host + '/' + this.namespace + '/platforms/' + platformId;

      return this.ajax(url, 'PUT', { data: data });
    }
  });
});