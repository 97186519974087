define('lxso/models/fragments/ticket-types-v4/capacity-ticket-type', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation', 'lxso/constants/base-ticket-types'], function (exports, _emberData, _emberDataModelFragments, _validation, _baseTicketTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({});

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    ticketTypeId: _emberData.default.attr('number'),
    baseType: _emberData.default.attr('string', { defaultValue: _baseTicketTypes.BASE_TYPES.CAPACITY }),
    minPerGroup: _emberData.default.attr('number', { defaultValue: 1 }),
    maxPerGroup: _emberData.default.attr('number'),
    isSelected: _emberData.default.attr('boolean', { defaultValue: true })
  });
});