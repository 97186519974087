define('lxso/helpers/get-paginated-index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getPaginatedIndex = getPaginatedIndex;
  function getPaginatedIndex(params) {
    var index = params[0];
    var pageSize = params[1];
    var currentPage = params[2];

    return index + pageSize * (currentPage - 1);
  }

  exports.default = Ember.Helper.helper(getPaginatedIndex);
});