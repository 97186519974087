define('lxso/utils/change-history-details-display-modes/promotion', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lodash'], function (exports, _generic, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'promotion',
    diff: function diff(oldValue, newValue, services) {
      var oldData = oldValue ? JSON.parse(oldValue) : {};
      var newData = newValue ? JSON.parse(newValue) : {};

      function difference(displayMode, value) {
        var newValue = value(newData);
        var oldValue = value(oldData);

        return !_lodash.default.isEqual(oldValue, newValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }

      return {
        name: difference(_generic.default, function (data) {
          return data.name;
        }),
        bookingDate: difference(_generic.default, function (data) {
          return showTime(data.bookingStartDate, data.bookingEndDate);
        }),
        travellingDate: difference(_generic.default, function (data) {
          return showTime(data.travelStartDate, data.travelEndDate);
        }),
        status: difference(_generic.default, function (data) {
          return data.status;
        }),
        totalDiscount: difference(_generic.default, function (data) {
          return amountType(data.totalDiscount, data.expediaFundType, services);
        }),
        fundingType: difference(_generic.default, function (data) {
          return data.funding;
        }),
        expediaFund: difference(_generic.default, function (data) {
          return amountType(data.expediaFund, data.expediaFundType, services);
        }),
        supplierFund: difference(_generic.default, function (data) {
          return amountType(data.supplierFund, data.expediaFundType, services);
        }),
        audiences: difference(_generic.default, function (data) {
          return audienceSection(data.audiences, services);
        }),
        shoppingPath: difference(_generic.default, function (data) {
          return shoppingPathSection(data.shoppingPaths, services);
        }),
        promotionDetail: difference(_generic.default, function (data) {
          return productsSelected(data.promotionDetail, services);
        })
      };
    }
  };


  function showTime(start, end) {
    if (start && end) {
      return start + ' to ' + end;
    }
  }

  function amountType(amount, amountType, services) {
    if (amount !== undefined) {
      if (amountType === 'Some(Absolute)') {
        var currency = services.session.get('data.authenticated.selectedBranch.currency_type');
        return amount + ' ' + currency;
      } else {
        return amount + ' ' + services.i18n.t('pages.promotion.promotionDetails.symbol.percentage').string;
      }
    }
  }

  function audienceSection(audiences, services) {
    if (audiences) {
      var _audienceSection = '';
      var limitText = services.i18n.t('pages.promotion.promotionAudience.limitTo').string;

      switch (audiences.length) {
        case 1:
          _audienceSection = limitText + ' ' + audiences[0].audience;
          break;
        case 2:
          _audienceSection = limitText + ' ' + audiences[0].audience + ', ' + audiences[1].audience;
          break;
        case 3:
          _audienceSection = limitText + ' ' + audiences[0].audience + ', \n        ' + audiences[1].audience + ' and ' + audiences[2].audience;
          break;
      }
      return _audienceSection;
    }
  }

  function shoppingPathSection(shoppingPath, services) {
    if (shoppingPath) {
      var _shoppingPathSection = '';
      var limitText = services.i18n.t('pages.promotion.promotionAudience.limitTo').string;

      switch (shoppingPath.length) {
        case 1:
          _shoppingPathSection = limitText + ' ' + shoppingPath[0].shoppingPath;
          break;
        case 2:
          _shoppingPathSection = limitText + ' ' + shoppingPath[0].shoppingPath + ', ' + shoppingPath[1].shoppingPath;
          break;
      }
      return _shoppingPathSection;
    }
  }

  function productsSelected(productsArray, services) {
    if (productsArray) {
      var uniqueProducts = productsArray.uniqBy('activityId');
      var message = services.i18n.t('pages.promotion.promotionDetails.productSelected').string;
      if (uniqueProducts.length > 1) {
        message = services.i18n.t('pages.promotion.promotionDetails.productsSelected').string;
      }

      return uniqueProducts.length + ' ' + message;
    }
  }
});