define('lxso/utils/change-history-details-display-modes/limited-inventory', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lxso/utils/change-history-details-display-modes/table'], function (exports, _generic, _table) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'limited-inventory',
    diff: function diff(oldValue, newValue) {
      var oldData = oldValue ? JSON.parse(oldValue) : {};
      var newData = newValue ? JSON.parse(newValue) : {};

      return {
        startDate: {
          oldValue: format(oldData.startDate),
          newValue: format(newData.startDate),
          displayMode: _generic.default
        },
        endDate: {
          oldValue: format(oldData.endDate),
          newValue: format(newData.endDate),
          displayMode: _generic.default
        },
        capacity: {
          oldValue: toDays(oldData),
          newValue: toDays(newData),
          displayMode: _table.default
        }
      };
    }
  };


  function format(dateString) {
    return dateString ? moment(dateString).format('YYYY-MM-DD') : '';
  }

  function toDays(limitedInventoryChangeDetail) {
    return [limitedInventoryChangeDetail.sunday, limitedInventoryChangeDetail.monday, limitedInventoryChangeDetail.tuesday, limitedInventoryChangeDetail.wednesday, limitedInventoryChangeDetail.thursday, limitedInventoryChangeDetail.friday, limitedInventoryChangeDetail.saturday];
  }
});