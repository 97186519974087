define('lxso/validators/option-group-array-length', ['exports', 'ember-cp-validations/validators/base', 'lxso/constants/option-group'], function (exports, _base, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OptionGroupArrayLengthValidation = _base.default.extend({
    i18n: Ember.inject.service(),
    validate: function validate(value, options, model, attribute) {
      var offers = model.get(attribute).filter(function (offer) {
        return offer.get('status') === _optionGroup.STATUS.ACTIVE;
      });
      var errorMessage = this.get('i18n').t('pages.optionGroup.errorMessages.minOneOption').string;
      if (offers.length < 1) {
        return errorMessage;
      } else {
        return true;
      }
    }
  });

  exports.default = OptionGroupArrayLengthValidation;
});