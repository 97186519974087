define('lxso/utils/option-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function anyRejects(validations) {
    'use strict';

    var isRejected = validations.any(function (promise) {
      return promise.state === 'rejected';
    });
    return isRejected;
  }

  function normalizeOffersErrors(validationErrors) {
    'use strict';

    return validationErrors.map(function (offerError) {
      return offerError.reason;
    });
  }

  exports.anyRejects = anyRejects;
  exports.normalizeOffersErrors = normalizeOffersErrors;
});