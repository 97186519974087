define('lxso/utils/change-history-details-display-modes/list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'list',
    diff: function diff(oldValues, newValues) {
      var arrayDiff = JsDiff.diffArrays(oldValues, newValues);
      var returnDiff = [];
      var transfer = function transfer(item, arr) {
        return item.value.forEach(function (value) {
          return arr.push({ value: value, removed: item.removed, added: item.added });
        });
      };
      var tempDiff = [];

      arrayDiff.forEach(function (item) {
        if (item.count == 1) {
          transfer(item, returnDiff);
        } else {
          if (item.removed) {
            transfer(item, tempDiff);
          } else if (item.added) {
            if (!Ember.isEmpty(tempDiff)) {
              tempDiff.forEach(function (temp) {
                returnDiff.push(temp);
                returnDiff.push({ value: item.value.shift(), added: true });
              });
              tempDiff = [];
            } else {
              transfer(item, returnDiff);
            }
          } else {
            if (!Ember.isEmpty(tempDiff)) {
              tempDiff.forEach(function (temp) {
                return returnDiff.push(temp);
              });
              tempDiff = [];
            }
            transfer(item, returnDiff);
          }
        }
      });

      if (!Ember.isEmpty(tempDiff)) {
        tempDiff.forEach(function (item) {
          return returnDiff.push(item);
        });
      }

      return returnDiff;
    }
  };
});