define('lxso/models/activity/operator-cancellation', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    groupSizeNotMet: _emberData.default.attr('boolean', { defaultValue: false }),
    inclementWeather: _emberData.default.attr('boolean', { defaultValue: false }),
    optionalContent: _emberData.default.attr('string', { defaultValue: '' }),

    isOptionalContent: Ember.computed('optionalContent', function () {
      return this.get('optionalContent').length > 0;
    })
  });
});