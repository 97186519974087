define('lxso/validators/markdown-stripped-length', ['exports', 'ember-cp-validations/validators/length', 'lxso/utils/markdown-stripper'], function (exports, _length, _markdownStripper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MarkdownStrippedLength = _length.default.extend({
    value: function value(model, attribute) {
      return (0, _markdownStripper.default)(model.get(attribute));
    }
  });

  exports.default = MarkdownStrippedLength;
});