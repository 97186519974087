define('lxso/components/supplier-notification-settings/component', ['exports', 'lxso/utils/validation', 'lxso/utils/form-validator', 'lxso/utils/array-values', 'lxso/config/environment', 'lodash'], function (exports, _validation, _formValidator, _arrayValues, _environment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Validations = (0, _validation.build)({
    'currentEditRow.email': {
      validators: [(0, _validation.validator)('format', {
        allowBlank: true,
        type: 'email',
        messageKey: 'errors.customMessages.supplierSettings.notificationEmail'
      }), (0, _validation.validator)('presence', true), (0, _validation.validator)('notification-unique-email', {
        array: 'viewNotificationData'
      })]
    }
  });

  exports.default = Ember.Component.extend(Validations, {
    optionsList: (0, _arrayValues.getArrayOfValues)(72),
    isNotificationSettingsOpen: true,
    defaultNotificationInterval: 72,
    defaultArrivalInterval: 1,
    ajaxWrapper: Ember.inject.service(),
    fileSaver: Ember.inject.service(),
    i18n: Ember.inject.service(),
    isFormDirty: false,
    viewNotificationData: [],
    showInstantMailCutOffHours: true,

    show: Ember.computed('viewNotificationData.@each.isArrival', 'viewNotificationData.@each.isInstant', function () {
      var hasArrival = this.get('viewNotificationData').filter(function (category) {
        return category.isArrival === true;
      });

      var hasInstant = this.get('viewNotificationData').filter(function (category) {
        return category.isInstant === true;
      });

      if (!hasArrival.length > 0 && !hasInstant.length) {
        this.setNotificationSettingsInfo();
      }

      return {
        arrival: hasArrival.length > 0,
        instant: hasInstant.length > 0
      };
    }),

    enableAdd: Ember.computed('currentEditRow.isInstant', 'currentEditRow.isBooking', 'currentEditRow.isArrival', 'currentEditRow.isCancellation', 'currentEditRow.isReview', 'currentEditRow.email', function () {
      return (this.get('currentEditRow.isInstant') || this.get('currentEditRow.isBooking') || this.get('currentEditRow.isArrival') || this.get('currentEditRow.isCancellation') || this.get('currentEditRow.isReview')) && this.get('currentEditRow.email.length');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('currentEditRow', {});
      this.setNotificationEmailData();
      this.resetState();
    },
    keyUp: function keyUp() {
      this.resetErrors();
    },
    resetErrors: function resetErrors() {
      this.set('notificationErrors', undefined);
      this.set('notificationEditErrors', undefined);
    },
    setFormDirty: function setFormDirty(status) {
      this.set('isFormDirty', status);
    },
    resetState: function resetState() {
      if (this.get('editNotificationSettings')) {
        this.set('currentEditRow', {});
        this.resetErrors();
        this.setFormDirty(false);
      }
    },
    setNotificationSettingsInfo: function setNotificationSettingsInfo(notificationInterval, arrivalReminder) {
      this.set('selectedNotificationInterval', notificationInterval || this.get('defaultNotificationInterval'));
      this.set('selectedArrivalReminder', arrivalReminder || this.get('defaultArrivalInterval'));
    },
    setNotificationEmailData: function setNotificationEmailData() {
      var notificationSettingsRecord = this.get('supplierBranch').get('notification_settings');
      var notificationSettingsConvertedToArray = [];
      if (notificationSettingsRecord) {
        notificationSettingsConvertedToArray = notificationSettingsRecord.map(function (x) {
          return x.toJSON();
        });
      }
      this.convertToCustomViewData(notificationSettingsConvertedToArray);
      var supplierBranchId = Number(this.get('supplierBranch').id);
      var showInstantMailCutOffHours = !_environment.default.instantMailCutOffHrsOverrideBranchIds.contains(supplierBranchId);
      this.set('showInstantMailCutOffHours', showInstantMailCutOffHours);
      this.setAllBookingLabel();
      this.addAllBookingOptionForInstantCutOffHrOverride(showInstantMailCutOffHours);
    },
    addAllBookingOptionForInstantCutOffHrOverride: function addAllBookingOptionForInstantCutOffHrOverride(showInstantMailCutOffHours) {
      var allBookingLabel = this.get('allBookingLabel');
      if (!showInstantMailCutOffHours && this.optionsList.indexOf(allBookingLabel) < 0) {
        this.optionsList.push(allBookingLabel);
      }
    },
    setAllBookingLabel: function setAllBookingLabel() {
      var labelText = this.get('i18n').t('helpText.supplierBranchSettings.emailNotification.allBookingLabel');
      this.set('allBookingLabel', labelText.string);
    },
    convertToCustomViewData: function convertToCustomViewData(notificationData) {
      var customViewData = [];
      var notificationInterval = this.get('defaultNotificationInterval');
      var arrivalReminder = this.get('defaultArrivalInterval');

      notificationData.forEach(function (notificationItem) {
        if (notificationItem.supplierEmail && notificationItem.supplierEmail.length) {
          var emailIds = notificationItem.supplierEmail.split(',');
          emailIds.forEach(function (emailId) {
            var obj = {};
            var alreadyAdded = _lodash.default.find(customViewData, { email: emailId });

            if (!alreadyAdded) {
              obj.email = emailId;
              switch (notificationItem.notificationTypeId) {
                case 'Booking':
                  if (notificationItem.isInstant) {
                    obj.isInstant = true;
                    notificationInterval = parseInt(notificationItem.instantBookingCutOffHrs);
                  } else {
                    obj.isBooking = true;
                  }
                  break;
                case 'Arrival':
                  obj.isArrival = true;
                  arrivalReminder = parseInt(notificationItem.sendBeforeDays);
                  break;
                case 'Cancellation':
                  if (notificationItem.isInstant) {
                    obj.isInstant = true;
                    notificationInterval = parseInt(notificationItem.instantBookingCutOffHrs);
                  } else {
                    obj.isCancellation = true;
                  }
                  break;
                case 'Review':
                  obj.isReview = true;
                  break;
              }
              customViewData.push(obj);
            } else {
              var elementIndex = _lodash.default.findIndex(customViewData, { email: emailId });
              switch (notificationItem.notificationTypeId) {
                case 'Booking':
                  if (notificationItem.isInstant) {
                    customViewData[elementIndex].isInstant = true;
                    notificationInterval = parseInt(notificationItem.instantBookingCutOffHrs);
                  } else {
                    customViewData[elementIndex].isBooking = true;
                  }
                  break;
                case 'Arrival':
                  customViewData[elementIndex].isArrival = true;
                  arrivalReminder = parseInt(notificationItem.sendBeforeDays);
                  break;
                case 'Cancellation':
                  if (notificationItem.isInstant) {
                    customViewData[elementIndex].isInstant = true;
                    notificationInterval = parseInt(notificationItem.instantBookingCutOffHrs);
                  } else {
                    customViewData[elementIndex].isCancellation = true;
                  }
                  break;
                case 'Review':
                  customViewData[elementIndex].isReview = true;
                  break;
              }
            }
          });
        }
      });

      this.setNotificationSettingsInfo(notificationInterval, arrivalReminder);
      this.set('viewNotificationData', customViewData);
    },
    getDefaultNotificationRecord: function getDefaultNotificationRecord() {
      var _this = this;

      var notificationSettingsRecord = this.get('supplierBranch').get('notification_settings');
      var notificationSettings = [];
      if (notificationSettingsRecord) {
        notificationSettings = notificationSettingsRecord.map(function (x) {
          return x.toJSON();
        });
      }

      var supplierBId = parseInt(this.get('supplierBranch').get('id'));
      var instantBookingObj = notificationSettings.filter(function (item) {
        return item.notificationTypeId.match('Booking') && item.isInstant === true;
      });
      var bookingObj = notificationSettings.filter(function (item) {
        return item.notificationTypeId.match('Booking') && item.isInstant === false;
      });
      var instantCancelObj = notificationSettings.filter(function (item) {
        return item.notificationTypeId.match('Cancellation') && item.isInstant === true;
      });
      var cancelObj = notificationSettings.filter(function (item) {
        return item.notificationTypeId.match('Cancellation') && item.isInstant === false;
      });
      var reviewObj = notificationSettings.filter(function (item) {
        return item.notificationTypeId.match('Review') && item.isInstant === false;
      });

      if (instantBookingObj && !instantBookingObj.length) {
        notificationSettings.insertAt(0, {
          'notificationSettingId': '',
          'supplierBranchId': supplierBId,
          'notificationTypeId': 'Booking',
          'supplierEmail': '',
          'isInstant': true,
          'instantBookingCutOffHrs': this.get('selectedNotificationInterval') || this.get('defaultNotificationInterval'),
          'sendBeforeDays': -1
        });
      }

      if (bookingObj && !bookingObj.length) {
        notificationSettings.insertAt(1, {
          'notificationSettingId': '',
          'supplierBranchId': supplierBId,
          'notificationTypeId': 'Booking',
          'supplierEmail': '',
          'isInstant': false,
          'instantBookingCutOffHrs': this.get('defaultNotificationInterval'),
          'sendBeforeDays': -1
        });
      }

      if (!notificationSettings.isAny('notificationTypeId', 'Arrival')) {
        notificationSettings.insertAt(2, {
          'notificationSettingId': '',
          'supplierBranchId': supplierBId,
          'notificationTypeId': 'Arrival',
          'supplierEmail': '',
          'isInstant': false,
          'instantBookingCutOffHrs': this.get('defaultNotificationInterval'),
          'sendBeforeDays': this.get('selectedArrivalReminder') || this.get('defaultArrivalInterval')
        });
      }

      if (cancelObj && !cancelObj.length) {
        notificationSettings.insertAt(3, {
          'notificationSettingId': '',
          'supplierBranchId': supplierBId,
          'notificationTypeId': 'Cancellation',
          'supplierEmail': '',
          'isInstant': false,
          'instantBookingCutOffHrs': this.get('defaultNotificationInterval'),
          'sendBeforeDays': -1
        });
      }

      if (instantCancelObj && !instantCancelObj.length) {
        notificationSettings.insertAt(4, {
          'notificationSettingId': '',
          'supplierBranchId': supplierBId,
          'notificationTypeId': 'Cancellation',
          'supplierEmail': '',
          'isInstant': true,
          'instantBookingCutOffHrs': this.get('selectedNotificationInterval') || this.get('defaultNotificationInterval'),
          'sendBeforeDays': -1
        });
      }

      if (reviewObj && !reviewObj.length) {
        notificationSettings.insertAt(5, {
          'notificationSettingId': '',
          'supplierBranchId': supplierBId,
          'notificationTypeId': 'Review',
          'supplierEmail': '',
          'isInstant': false,
          'instantBookingCutOffHrs': this.get('defaultNotificationInterval'),
          'sendBeforeDays': -1
        });
      }

      notificationSettings.forEach(function (notificationRowData) {
        if (notificationRowData.notificationTypeId === 'Arrival') {
          notificationRowData.sendBeforeDays = parseInt(_this.get('selectedArrivalReminder')) || _this.get('defaultArrivalInterval');
        }

        if (notificationRowData.notificationTypeId === 'Booking' && notificationRowData.isInstant) {
          notificationRowData.instantBookingCutOffHrs = parseInt(_this.get('selectedNotificationInterval')) || _this.get('defaultNotificationInterval');
        }

        if (notificationRowData.notificationTypeId === 'Cancellation' && notificationRowData.isInstant) {
          notificationRowData.instantBookingCutOffHrs = parseInt(_this.get('selectedNotificationInterval')) || _this.get('defaultNotificationInterval');
        }
      });
      return notificationSettings;
    },
    convertToApiFormatData: function convertToApiFormatData() {
      var notificationSettingsData = this.getDefaultNotificationRecord();
      var viewNotificationData = this.get('viewNotificationData');
      var emailIds = {
        instantEmailIds: [],
        bookingEmailIds: [],
        arrivalEmailIds: [],
        cancellationEmailIds: [],
        reviewEmailIds: []
      };
      viewNotificationData.forEach(function (viewDataRow) {
        if (viewDataRow && viewDataRow.isInstant) {
          emailIds.instantEmailIds.push(viewDataRow.email);
        }

        if (viewDataRow && viewDataRow.isBooking) {
          emailIds.bookingEmailIds.push(viewDataRow.email);
        }

        if (viewDataRow && viewDataRow.isArrival) {
          emailIds.arrivalEmailIds.push(viewDataRow.email);
        }

        if (viewDataRow && viewDataRow.isCancellation) {
          emailIds.cancellationEmailIds.push(viewDataRow.email);
        }

        if (viewDataRow && viewDataRow.isReview) {
          emailIds.reviewEmailIds.push(viewDataRow.email);
        }
      });

      var cancellationData = _lodash.default.find(notificationSettingsData, function (data) {
        return data.notificationTypeId === 'Cancellation' && !data.isInstant;
      });
      cancellationData.supplierEmail = emailIds.cancellationEmailIds.join();

      var instantCancellationData = _lodash.default.find(notificationSettingsData, function (data) {
        return data.notificationTypeId === 'Cancellation' && data.isInstant;
      });
      instantCancellationData.supplierEmail = emailIds.instantEmailIds.join();

      var arrivalData = _lodash.default.find(notificationSettingsData, function (data) {
        return data.notificationTypeId === 'Arrival';
      });
      arrivalData.supplierEmail = emailIds.arrivalEmailIds.join();

      var bookingData = _lodash.default.find(notificationSettingsData, function (data) {
        return data.notificationTypeId === 'Booking' && !data.isInstant;
      });
      bookingData.supplierEmail = emailIds.bookingEmailIds.join();

      var instantData = _lodash.default.find(notificationSettingsData, function (data) {
        return data.notificationTypeId === 'Booking' && data.isInstant;
      });
      instantData.supplierEmail = emailIds.instantEmailIds.join();

      var reviewData = _lodash.default.find(notificationSettingsData, function (data) {
        return data.notificationTypeId === 'Review';
      });
      reviewData.supplierEmail = emailIds.reviewEmailIds.join();

      return notificationSettingsData;
    },
    validateNotificationFields: function validateNotificationFields() {
      return (0, _formValidator.validate)({
        model: this, attributes: ['currentEditRow.email']
      });
    },
    validateNotificationEmailFields: function validateNotificationEmailFields(rowData) {
      var _this2 = this;

      var viewNotificationData = this.get('viewNotificationData');
      var emailList = viewNotificationData.map(function (item) {
        return item.email;
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var promise = {};
        if (rowData && rowData.editingRow.email && rowData.editingRow.email.length) {
          var regExpEmail = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
          var mailIsValid = regExpEmail.test(rowData.editingRow.email);
          if (!mailIsValid) {
            promise.viewNotificationData = [_this2.get('i18n').t('components.notificationSettings.message.invalidEmailId')];
          }

          if (!rowData.editingRow.isInstant && !rowData.editingRow.isBooking && !rowData.editingRow.isArrival && !rowData.editingRow.isCancellation && !rowData.editingRow.isReview) {
            promise.viewNotificationData = [_this2.get('i18n').t('components.notificationSettings.message.selectOptions')];
          }
          var duplicateEmails = emailList.filter(function (mail) {
            return mail === rowData.editingRow.email;
          });
          if (duplicateEmails.length > 1) {
            promise.viewNotificationData = [_this2.get('i18n').t('errors.customMessages.supplierSettings.duplicateEmail')];
          }
        } else {
          promise.viewNotificationData = [_this2.get('i18n').t('components.notificationSettings.message.emailRequired')];
        }

        if (!Ember.isEmpty(promise) && !(Object.keys(promise).length === 0)) {
          reject(promise);
        } else {
          resolve(promise);
        }
      });
    },
    getNotificationSettings: function getNotificationSettings(url) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.get('ajaxWrapper').ajaxGet(url, null, { dataType: '' }).then(function (results) {
          return resolve(results);
        }).catch(function (reason) {
          return reject(reason);
        });
      });
    },
    createCsvAndDownload: function createCsvAndDownload(data) {
      var blob = new Blob([data], { type: 'text/csv' });
      var fileName = this.createCsvFileName();
      this.get('fileSaver').saveAs(blob, fileName);
    },
    createCsvFileName: function createCsvFileName() {
      var supplierName = this.get('supplierBranch.supplier_branch_name').split(' ').join('_');
      var currentDate = new Date();
      var isoName = supplierName + '_' + currentDate.toISOString();
      return isoName.replace(/\W/g, '') + '.csv';
    },
    isFormValid: function isFormValid() {
      return !this.get('notificationErrors') && !this.get('notificationEditErrors');
    },
    findIndexAndValue: function findIndexAndValue(index) {
      var customViewData = this.get('viewNotificationData');
      var editingRow = customViewData.objectAt(index);
      return {
        editIndex: index,
        editingRow: editingRow
      };
    },


    actions: {
      addEmail: function addEmail() {
        var _this4 = this;

        this.validateNotificationFields().then(function () {
          _this4.set('notificationErrors', undefined);
          _this4.get('viewNotificationData').pushObject(_this4.get('currentEditRow'));
          _this4.set('currentEditRow', {});
          _this4.setFormDirty(true);
        }).catch(function (err) {
          _this4.set('notificationErrors', err);
        });
      },
      openDeleteModal: function openDeleteModal(selectedId) {
        var customViewData = this.get('viewNotificationData');
        this.set('selectedEmailIndex', selectedId);
        this.set('selectedEmail', customViewData[selectedId].email);
        if (customViewData.length == 1) {
          this.set('singleEmailDeletionConfirmation', true);
        } else {
          this.set('multipleEmailDeletionConfirmation', true);
        }
      },
      closeDeleteSingleEmail: function closeDeleteSingleEmail() {
        this.set('singleEmailDeletionConfirmation', false);
      },
      closeDeleteMultipleEmail: function closeDeleteMultipleEmail() {
        this.set('multipleEmailDeletionConfirmation', false);
      },
      addEmailAction: function addEmailAction() {
        this.set('singleEmailDeletionConfirmation', false);
        var selectedId = this.get('selectedEmailIndex');
        document.getElementById('email-text-notification-settings-input').focus();
      },
      removeEmail: function removeEmail() {
        this.get('viewNotificationData').removeAt(this.get('selectedEmailIndex'));
        this.set('multipleEmailDeletionConfirmation', false);
        this.setFormDirty(true);
      },
      emailRowActions: function emailRowActions(type, notificationIndex) {
        var _this5 = this;

        var getRowData = this.findIndexAndValue(notificationIndex);

        switch (type) {
          case 'cancel':
            this.set('isRowEditing', false);
            this.get('viewNotificationData').replace(getRowData.editIndex, 1, [this.get('lastSavedRowData')]);
            this.set('lastSavedRowData', undefined);
            this.set('notificationEditErrors', undefined);
            Ember.set(getRowData.editingRow, 'isEditingRow', false);
            break;
          case 'update':
            this.validateNotificationEmailFields(getRowData).then(function () {
              _this5.set('notificationEditErrors', undefined);
              _this5.set('isRowEditing', false);
              _this5.set('lastSavedRowData', Ember.copy(getRowData.editingRow));
              Ember.set(getRowData.editingRow, 'isEditingRow', false);
            }).catch(function (err) {
              return _this5.set('notificationEditErrors', err);
            });
            break;
          case 'edit':
            this.set('isRowEditing', true);
            this.set('lastSavedRowData', Ember.copy(getRowData.editingRow));
            this.get('viewNotificationData').replace(getRowData.editIndex, 1, [getRowData.editingRow]);
            Ember.set(getRowData.editingRow, 'isEditingRow', true);
            break;
        }

        this.setFormDirty(true);
      },
      saveNotificationSettings: function saveNotificationSettings() {
        var _this6 = this;

        var supplierBranch = this.get('supplierBranch');
        var inputData = this.convertToApiFormatData();
        var formValid = this.isFormValid();

        if (formValid) {
          this.set('savingNotificationSettings', true);
          supplierBranch.updateNotificationSettings({
            notificationSettings: inputData
          }).then(function (resp) {
            _this6.sendAction('savedNotification');
          }).finally(function () {
            _this6.set('isRowEditing', false);
            _this6.set('savingNotificationSettings', false);
            _this6.setFormDirty(false);
          });
        }
      },
      setInstantNotification: function setInstantNotification(value) {
        this.set('selectedNotificationInterval', value);
        this.setFormDirty(true);
      },
      setArrivalReminder: function setArrivalReminder(value) {
        this.set('selectedArrivalReminder', value);
        this.setFormDirty(true);
      },
      editNotificationSettings: function editNotificationSettings() {
        this.sendAction('editNotificationSettings', true);
      },
      cancelEditNotification: function cancelEditNotification() {
        this.set('isRowEditing', false);
        this.sendAction('cancelNotificationSettings');
      },
      openStep: function openStep(step) {
        var link = document.getElementsByClassName('title-main toggle-trigger ' + step);
        this.toggleProperty('isNotificationSettingsOpen');
        $(link).toggleClass('open');
      },
      exportSupplierBranch: function exportSupplierBranch() {
        var _this7 = this;

        var supplierBranchId = this.get('supplierBranch.id');
        var link = _environment.default.api.host + '/api/v1/supplier_branches/' + supplierBranchId + '/exportNotification';
        this.getNotificationSettings(link).then(function (successRes) {
          _this7.createCsvAndDownload(successRes);
        });
      }
    }

  });
});