define('lxso/serializers/price-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    featureFlags: Ember.inject.service(),
    attrs: {
      prices: {
        embedded: 'always'
      }
    },

    normalize: function normalize(typeClass, hash) {
      if (hash.id === '' || hash.id == null) {
        hash.id = hash.scheduleId + ' ' + hash.travelStart + ' ' + hash.travelEnd + ' ' + hash.bookingStart + ' ' + hash.bookingEnd;
        if (hash.priceGroupId) {
          hash.id = hash.priceGroupId + ' ' + hash.id;
        }
      }

      if (hash.priceGroupId && this.get('featureFlags').checkEnabled('MultipleOptionsGroup')) {
        //This price is part of an offer group
        hash.prices = hash.prices.map(function (p) {
          return Object.assign(p, {
            id: p.id + '-' + hash.priceGroupId + '-' + p.ticketTypeId + '-' + p.minimumTravelers + '-' + p.maximumTravelers,
            minMaxOld: p.minimumTravelers + '::' + p.maximumTravelers,
            ticketTypeIdOld: p.ticketTypeId
          });
        });
      }
      return this._super.apply(this, arguments);
    },
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, typeClass, hash, oldPriceGroupId) {
      hash.id = '' + oldPriceGroupId;
      return this._super.apply(this, arguments);
    }
  });
});