define('lxso/components/activity-setup-section/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.updateDataOnChange();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.updateDataOnChange();
    },


    isActivityFormDirty: false,

    updateDataOnChange: function updateDataOnChange() {
      var supplierOverride = Ember.get(this, 'activity.operational.supplierOverrideName') ? Ember.get(this, 'activity.operational.supplierOverrideName') : null;
      if (supplierOverride) {
        this.set('isOverrideEnabled', true);
      } else {
        this.set('isOverrideEnabled', false);
      }
      this.notifyPropertyChange('displayValueSupplierName');
    },


    displayValueSupplierName: Ember.computed('activity.operational.supplierOverrideName', 'isOverrideEnabled', {
      get: function get() {
        var supplierNameOverrideText = Ember.get(this, 'activity.operational.supplierOverrideName');
        var branchName = this.get('session.session.authenticated.selectedBranch.supplier_name');
        if (this.get('isOverrideEnabled')) {
          return supplierNameOverrideText ? supplierNameOverrideText : branchName;
        } else {
          return branchName;
        }
      },
      set: function set(key, value) {
        return value;
      }
    }),

    hasOfId: Ember.computed('activity.ofid', function () {
      return this.get('activity.ofid') && parseInt(this.get('activity.ofid')) > 0;
    }),

    actions: {
      setActivityFormDirty: function setActivityFormDirty(value) {
        this.set('isActivityFormDirty', value);
      },
      retry: function retry() {
        var _this = this;

        var activity = this.get('activity');
        var inputData = {
          'activity_title': activity.get('basic.internal_title')
        };
        this.set('processingOfId', true);
        activity.createTeaShopId(inputData).then(function (res) {
          activity.set('ofid', res.ofid);
        }).finally(function () {
          _this.set('processingOfId', false);
        });
      },
      editSetup: function editSetup() {
        this.sendAction('toggleEditMode', 'activitySetup');
      },
      connectivityStatusChange: function connectivityStatusChange() {
        this.send('setActivityFormDirty', true);
        this.toggleProperty('activity.isConnectivityEnabled');
      },
      saveActivitySetUp: function saveActivitySetUp() {
        var supplierOverrideName = null;
        if (this.get('isOverrideEnabled')) {
          supplierOverrideName = this.get('displayValueSupplierName');
        }
        this.set('activity.operational.supplierOverrideName', supplierOverrideName);
        this.send('setActivityFormDirty', false);
        this.sendAction('saveActivitySetUpData', this.get('activity.operational'), this.get('activity.attributes'));
      },
      cancelEditActivitySetup: function cancelEditActivitySetup() {
        this.send('setActivityFormDirty', false);
        this.sendAction('cancelEditActivitySetup');
      },
      overrideChange: function overrideChange() {
        this.toggleProperty('isOverrideEnabled');
        this.send('setActivityFormDirty', true);
      }
    }
  });
});