define('lxso/components/booking-questions/bq-modals/bq-products-options-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    uitk: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('noProductsSelected', this.get('selectedProducts').length <= 0);
      this.set('noOptionsSelected', this.get('selectedProductsAndOptions').length <= 0);
      this.set('finalSelectedProductsCount', this.get('selectedProductsAndOptions').uniqBy('activityId').length);
    },


    actions: {
      onProductSelect: function onProductSelect(product) {
        var selectedCount = 0;
        product.set('isProductSelected', !product.isProductSelected);

        this.get('productList').forEach(function (listProduct) {
          if (listProduct.id == product.id) {
            listProduct.set('isProductSelected', product.get('isProductSelected'));
          }
          if (listProduct.isProductSelected) {
            selectedCount++;
          }
        });
        this.set('noProductsSelected', selectedCount <= 0);

        // set select all checkbox
        this.set('productsSelectAllEnabled', selectedCount > 0 && selectedCount == this.get('productList').length);
      },
      onOptionSelect: function onOptionSelect(option) {
        var totalOptionsCount = 0;
        var selectedOptionsCount = 0;
        option.set('isOptionSelected', !option.isOptionSelected);
        var selectedProducts = this.get('selectedProducts');
        selectedProducts.forEach(function (selectedProduct) {
          selectedProduct.offers.forEach(function (listOption) {
            if (listOption.id == option.id) {
              listOption.set('isOptionSelected', option.isOptionSelected);
            }
            if (listOption.isOptionSelected) {
              selectedOptionsCount++;
            }
            totalOptionsCount++;
          });
        });
        this.set('noOptionsSelected', selectedOptionsCount <= 0);

        //set select all checkbox
        this.set('optionsSelectAllEnabled', selectedOptionsCount > 0 && selectedOptionsCount == totalOptionsCount);
      },
      toggleSelectAllProducts: function toggleSelectAllProducts() {
        var _this = this;

        var selectedCount = 0;
        this.set('productsSelectAllEnabled', !this.get('productsSelectAllEnabled'));
        this.get('productList').forEach(function (listProduct) {
          listProduct.set('isProductSelected', _this.get('productsSelectAllEnabled'));
          if (listProduct.isProductSelected) {
            selectedCount++;
          }
        });
        this.set('noProductsSelected', selectedCount <= 0);
      },
      toggleSelectAllOptions: function toggleSelectAllOptions() {
        var _this2 = this;

        var selectedOptionsCount = 0;
        this.set('optionsSelectAllEnabled', !this.get('optionsSelectAllEnabled'));
        var productAccordionsCount = this.get('selectedProducts').length;
        this.get('selectedProducts').forEach(function (selectedProduct) {
          selectedProduct.offers.forEach(function (listOption) {
            listOption.set('isOptionSelected', _this2.get('optionsSelectAllEnabled'));
            if (listOption.isOptionSelected) {
              selectedOptionsCount++;
            }
          });
          // open up accordions and display all selected/unselected options
          var index = 0;
          while (index < productAccordionsCount) {
            var link = document.getElementsByClassName('toggle-trigger product-options-accordion-' + index);
            $(link).toggleClass('open', true);
            var optionsList = document.getElementsByClassName('product-options-list options-list-' + index);
            $(optionsList).toggleClass('close', false);
            index++;
          }
        });
        this.set('noOptionsSelected', selectedOptionsCount <= 0);
      },
      goBackToProductsSection: function goBackToProductsSection() {
        this.set('optionsView', false);
        this.set('productsView', true);
        this.set('noOptionsSelected', this.get('selectedProductsAndOptions').length <= 0);
        this.set('noProductsSelected', this.get('selectedProducts').length <= 0);
      },
      saveProductSelection: function saveProductSelection() {
        this.send('setSelectedProductsList');
        this.set('productsView', false);
        this.set('optionsView', true);
      },
      saveOptionsSelection: function saveOptionsSelection() {
        this.send('setSelectedOptionsList');
        this.sendAction('completeProductsAndOptionsSection');
      },
      setSelectedProductsList: function setSelectedProductsList() {
        var selectedProducts = [];
        this.get('productList').forEach(function (product) {
          if (product.isProductSelected) {
            selectedProducts.push(product);
          }
        });
        this.set('selectedProducts', selectedProducts);
      },
      setSelectedOptionsList: function setSelectedOptionsList() {
        var selectedProductsAndOptions = [];
        this.get('selectedProducts').forEach(function (selectedProduct) {
          selectedProduct.offers.forEach(function (option) {
            if (option.isOptionSelected) {
              var tempProduct = {};
              tempProduct.activityId = selectedProduct.id;
              tempProduct.offerId = option.id;
              selectedProductsAndOptions.push(tempProduct);
            }
          });
        });
        var uniqueListOfProductsOptions = selectedProductsAndOptions.uniqBy('activityId');
        this.set('selectedProductsAndOptions', selectedProductsAndOptions);
        this.set('finalSelectedProductsCount', uniqueListOfProductsOptions.length);
      },
      toggleProductOptionsAccordion: function toggleProductOptionsAccordion(index) {
        var link = document.getElementsByClassName('toggle-trigger product-options-accordion-' + index);
        $(link).toggleClass('open');

        var optionsList = document.getElementsByClassName('product-options-list options-list-' + index);
        $(optionsList).toggleClass('close');
      }
    }
  });
});