define('lxso/routes/hotel-zone-list', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lxso/mixins/hotel-zone/deactivate-hotel-zone', 'lxso/config/environment', 'lxso/mixins/enum-getter'], function (exports, _authenticatedRouteMixin, _featureFlag, _requirePermission, _deactivateHotelZone, _environment, _enumGetter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, _deactivateHotelZone.default, _enumGetter.default, {
    requiredPermission: 'V3InternalSettings',
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    model: function model(param, transition) {
      /*eslint-disable */
      return this.store.findRecord('supplier-branch', param.supplierBranchId).then(function (supplierBranch) {
        return supplierBranch.get('hotelZones');
      });
      /*eslint-enable */
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      var active = Ember.A([]);
      var inactive = Ember.A([]);

      model.forEach(function (zone) {
        if (zone.get('status') === 'ACTIVE') {
          active.push(zone);
        } else {
          inactive.push(zone);
        }
      });

      active.sortBy('zoneName');
      inactive.sortBy('zoneName');

      this.controller.set('hotelZones', active.concat(inactive));
      this.controller.set('searchBy', 'zoneName');
      this.clearInputAndErrors();
      this.controller.set('isSearchById', false);
      var zoneUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/location_lists/search/zones?nameDetails=';
      this.controller.set('zoneNamesSource', this.getEnumFunction(zoneUrl));
      this.controller.set('expressSearchLabel', Ember.computed('searchBy', function () {
        var searchBy = _this.controller.get('searchBy');
        var searchByPart1 = _this.get('i18n').t('pages.selectBranch.expressSearch.searchByLabel');
        var searchByPart2 = _this.get('i18n').t('pages.selectBranch.expressSearch.searchLabel.' + searchBy);
        var label = '' + searchByPart1 + searchByPart2;
        return label;
      }));
    },
    clearInputAndErrors: function clearInputAndErrors() {
      this.controller.set('idToSearch', '');
      this.controller.set('branchFilter', '');
      this.controller.set('errors', '');
    },
    loadZonePage: function loadZonePage(zoneId) {
      var _this2 = this;

      if (zoneId) {
        this.store.findRecord('hotel-zone', zoneId).then(function () {
          _this2.transitionTo('hotel-zone', zoneId);
        }).catch(function (errors) {
          _this2.controller.set('errors', [_this2.get('i18n').t('pages.selectBranch.expressSearch.unavailableZone')]);
        });
      }
    },
    validateSearchId: function validateSearchId(searchId) {
      var searchIdNum = Number(searchId);
      if (searchIdNum > 0 && Number.isInteger(searchIdNum)) {
        return true;
      } else {
        return this.get('i18n').t('pages.selectBranch.expressSearch.invalidIdMessage');
      }
    },


    actions: {
      editZone: function editZone(zoneId) {
        this.transitionTo('hotel-zone', zoneId);
      },
      expressSearch: function expressSearch(searchBy) {
        this.clearInputAndErrors();
        this.controller.set('searchBy', searchBy);
        this.controller.set('isSearchById', searchBy === 'zoneId');
      },
      onSearchById: function onSearchById(idToSearch) {
        this.controller.set('errors', '');
        var isValid = this.validateSearchId(idToSearch);
        if (isValid === true) {
          this.loadZonePage(idToSearch);
        } else {
          this.controller.set('errors', [isValid]);
        }
      },
      zoneSelectedInTypeahead: function zoneSelectedInTypeahead(item) {
        this.loadZonePage(item.id);
      }
    }
  });
});