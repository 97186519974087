define('lxso/adapters/ticket-types-simple', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    //we have to findAll because the api only gives us ticket types as an array, not as individuals
    //queryRecord is only for use with query.id, which there's no matching api route for

    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.host + '/' + this.namespace + '/activities/' + snapshot.record.get('activityId') + '/ticket_types';

      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'PUT', { data: data.ticketTypesSimple });
    }
  });
});