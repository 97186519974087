define('lxso/mixins/booking-questions/booking-questions-common', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    initGroupedTravelerInfo: [{
      id: 1,
      name: 'Traveler name',
      isEnabled: false,
      children: []
    }, {
      id: 2,
      name: 'Traveler date of birth',
      isEnabled: false,
      children: []
    }, {
      id: null,
      name: 'Passport information',
      isEnabled: false,
      children: [{
        id: 3,
        name: 'Passport nationality',
        isEnabled: false,
        children: []
      }, {
        id: 4,
        name: 'Passport number',
        isEnabled: false,
        children: []
      }, {
        id: 5,
        name: 'Passport expiration date',
        isEnabled: false,
        children: []
      }]
    }, {
      id: null,
      name: 'Safety information',
      isEnabled: false,
      children: [{
        id: 6,
        name: 'Weight',
        isEnabled: false,
        children: []
      }, {
        id: 7,
        name: 'Height',
        isEnabled: false,
        children: []
      }]
    }],

    initGroupedTravelerInfoWithoutPassportDob: [{
      id: 1,
      name: 'Traveler name',
      isEnabled: false,
      children: []
    }, {
      id: null,
      name: 'Safety information',
      isEnabled: false,
      children: [{
        id: 6,
        name: 'Weight',
        isEnabled: false,
        children: []
      }, {
        id: 7,
        name: 'Height',
        isEnabled: false,
        children: []
      }]
    }],

    initGroupedPickupInfo: [{
      id: null,
      name: 'Arrival locations',
      isEnabled: false,
      children: [{
        id: 8,
        name: 'Airports',
        description: 'Will include airport, flight number, arrival date & time and dropoff location.',
        isEnabled: false,
        children: []
      }, {
        id: 9,
        name: 'Train stations',
        description: 'Will include a train station, train number, arrival date & time and dropoff location.',
        isEnabled: false,
        children: []
      }, {
        id: 10,
        name: 'Bus stations',
        description: 'Will include bus station, bus number, arrival date & time and dropoff location.',
        isEnabled: false,
        children: []
      }, {
        id: 11,
        name: 'Ship or cruise ports',
        description: 'Will include port, ship/cruise number, arrival date & time and dropoff location.',
        isEnabled: false,
        children: []
      }, {
        id: 12,
        name: 'Other',
        description: 'Will include arrival method, pickup location, arrival date & time and dropoff location.',
        isEnabled: false,
        children: []
      }]
    }, {
      id: 13,
      name: 'Hotels',
      description: 'Hotel name and location.',
      isEnabled: false,
      children: []
    }],

    initGroupedDropoffInfo: [{
      id: null,
      name: 'Dropoff locations',
      isEnabled: false,
      children: [{
        id: 14,
        name: 'Airports',
        description: 'Will include airport, flight number, departure date & time and pickup location.',
        isEnabled: false,
        children: []
      }, {
        id: 15,
        name: 'Train stations',
        description: 'Will include a train station, train number, departure date & time and pickup location.',
        isEnabled: false,
        children: []
      }, {
        id: 16,
        name: 'Bus stations',
        description: 'Will include bus station, bus number, departure date & time and pickup location.',
        isEnabled: false,
        children: []
      }, {
        id: 17,
        name: 'Ship or Cruise ports',
        description: 'Will include port, ship/cruise number, departure date & time and pickup location.',
        isEnabled: false,
        children: []
      }, {
        id: 18,
        name: 'Other',
        description: 'Will include dropoff method, dropoff location, dropoff date & time and pickup location.',
        isEnabled: false,
        children: []
      }]
    }, {
      id: 19,
      name: 'Hotels',
      description: 'Hotel name and location.',
      isEnabled: false,
      children: []
    }],

    initBookingQuestionDetails: function initBookingQuestionDetails() {
      this.controller.set('travelerInfoSelectAllEnabled', false);
      this.controller.set('pickupInfoSelectAllEnabled', false);
      this.controller.set('dropoffInfoSelectAllEnabled', false);
      this.controller.set('noneSelected', true);
      this.controller.set('bookingQuestionsAccordionClosed', !this.controller.get('isNew'));
      this.controller.set('bookingQuestionsAccordionComplete', false);
      if (this.controller.get('isNew') && !this.controller.get('isClone')) {
        if (this.get('featureFlags').checkEnabled('BQPassportDob')) {
          this.controller.set('bookingQuestionSet.questionDetails', this.get('initGroupedTravelerInfo'));
        } else {
          this.controller.set('bookingQuestionSet.questionDetails', this.get('initGroupedTravelerInfoWithoutPassportDob'));
        }
        if (this.controller.get('isPickupDropoffEnabled')) {
          this.controller.set('bookingQuestionSet.pickupDetails', this.get('initGroupedPickupInfo'));
          this.controller.set('bookingQuestionSet.dropoffDetails', this.get('initGroupedDropoffInfo'));
        }
      } else {
        var isClone = this.controller.get('isClone');
        var ungroupedQuestions = isClone ? this.controller.get('bookingQuestionSetToClone.bookingQuestionDetails') : this.controller.get('bookingQuestionSet.bookingQuestionDetails');
        var groupedTravelerInfo = this.getGroupedBookingQuestions(ungroupedQuestions.slice(0, 7), 'travelerInfo');
        var groupedPickupInfo = this.getGroupedBookingQuestions(ungroupedQuestions.slice(7, 13), 'pickupInfo');
        var groupedDropoffInfo = this.getGroupedBookingQuestions(ungroupedQuestions.slice(13, 19), 'dropoffInfo');
        var initBookingQuestions = isClone ? this.controller.get('bookingQuestionSetToClone.bookingQuestionDetails') : this.controller.get('bookingQuestionSet.bookingQuestionDetails');
        this.controller.set('initBookingQuestions', initBookingQuestions);
        this.controller.set('bookingQuestionSet.questionDetails', groupedTravelerInfo);
        this.controller.set('bookingQuestionSet.pickupDetails', groupedPickupInfo);
        this.controller.set('bookingQuestionSet.dropoffDetails', groupedDropoffInfo);
        this.setNoneSelected();
        this.controller.set('lastSavedName', this.controller.get('bookingQuestionSet.name'));
        this.controller.set('initName', this.controller.get('bookingQuestionSet.name'));
        this.controller.set('displayName', this.controller.get('bookingQuestionSet.name'));
      }
    },
    initProductsOptions: function initProductsOptions() {
      this.controller.set('productsAndOptionsAccordionComplete', false);
      this.controller.set('productsAndOptionsOpen', false);
      this.controller.set('productsView', false);
      this.controller.set('optionsView', false);
      this.controller.set('productsSelectAllEnabled', false);
      this.controller.set('optionsSelectAllEnabled', false);
      this.controller.set('selectedProducts', []);
      this.controller.set('selectedProductsAndOptions', []);
      this.controller.set('selectedProductsAndOptionGroups', []);
      this.controller.set('noProductsSelected', true);
      this.controller.set('noOptionsSelected', true);
      this.controller.set('isProductsOptionsOpen', false);
      this.controller.set('isProductsOptionsSectionExpanded', false);
    },
    initSetName: function initSetName() {
      this.controller.set('setNameAccordionComplete', false);
      this.controller.set('setNameAccordionClosed', true);
      this.controller.set('isSetNameOpen', false);
      this.controller.set('isNameInvalid', true);
      this.controller.set('lastSavedName', '');
    },
    getBookingQuestionsWithoutGrouping: function getBookingQuestionsWithoutGrouping(groupedQuestions) {
      var filteredQuestions = [];
      groupedQuestions.forEach(function (groupedQuestion) {
        if (!Ember.isEmpty(groupedQuestion.children)) {
          groupedQuestion.children.forEach(function (child) {
            filteredQuestions.push({
              id: child.id,
              name: child.name,
              isEnabled: child.isEnabled
            });
          });
        } else {
          filteredQuestions.push({
            id: groupedQuestion.id,
            name: groupedQuestion.name,
            isEnabled: groupedQuestion.isEnabled
          });
        }
      });
      return filteredQuestions;
    },
    getGroupedBookingQuestions: function getGroupedBookingQuestions(ungroupedQuestions, section) {
      var initGroupedQuestions = void 0;
      if (section === 'travelerInfo') {
        if (this.get('featureFlags').checkEnabled('BQPassportDob')) {
          initGroupedQuestions = this.get('initGroupedTravelerInfo');
        } else {
          initGroupedQuestions = this.get('initGroupedTravelerInfoWithoutPassportDob');
        }
      } else if (section === 'pickupInfo') {
        initGroupedQuestions = this.get('initGroupedPickupInfo');
      } else if (section === 'dropoffInfo') {
        initGroupedQuestions = this.get('initGroupedDropoffInfo');
      }

      ungroupedQuestions.forEach(function (ungroupedQuestion) {
        initGroupedQuestions.forEach(function (initQuestion) {
          if (initQuestion.id === ungroupedQuestion.get('fieldId')) {
            Ember.set(initQuestion, 'isEnabled', ungroupedQuestion.get('isEnabled'));
          } else {
            initQuestion.children.forEach(function (child) {
              if (child.id === ungroupedQuestion.get('fieldId')) {
                Ember.set(child, 'isEnabled', ungroupedQuestion.get('isEnabled'));
              }
            });
          }
        });
      });

      // set selectAll, parent
      var selectAllEnabled = true;
      initGroupedQuestions.forEach(function (iq) {
        selectAllEnabled = selectAllEnabled && iq.isEnabled;
        var parentEnabled = true;
        iq.children.forEach(function (child) {
          parentEnabled = parentEnabled && child.isEnabled;
        });
        if (iq.children.length > 0) {
          Ember.set(iq, 'isEnabled', parentEnabled);
        }
      });
      this.controller.set('selectAllEnabled', selectAllEnabled);
      return initGroupedQuestions;
    },
    refreshInitTravelerInfo: function refreshInitTravelerInfo(initList) {
      initList.forEach(function (listValue) {
        Ember.set(listValue, 'isEnabled', false);
        listValue.children.forEach(function (child) {
          Ember.set(child, 'isEnabled', false);
        });
      });
      return initList;
    },
    isSectionEdited: function isSectionEdited(section) {
      switch (section) {
        case 'bookingQuestionDetails':
          {
            var isBookingQuestionsEdited = false;
            var initQuestions = this.controller.get('initBookingQuestions');
            var currentQuestions = void 0;
            if (this.get('featureFlags').checkEnabled('BQPickupInformation')) {
              currentQuestions = this.getBookingQuestionsWithoutGrouping(this.controller.get('bookingQuestionSet.questionDetails')).concat(this.getBookingQuestionsWithoutGrouping(this.controller.get('bookingQuestionSet.pickupDetails')).concat(this.getBookingQuestionsWithoutGrouping(this.controller.get('bookingQuestionSet.dropoffDetails'))));
            } else {
              currentQuestions = this.getBookingQuestionsWithoutGrouping(this.controller.get('bookingQuestionSet.questionDetails'));
            }
            initQuestions.forEach(function (init) {
              currentQuestions.forEach(function (current) {
                if (init.get('fieldId') === current.id && init.get('isEnabled') != current.isEnabled) {
                  isBookingQuestionsEdited = isBookingQuestionsEdited || true;
                }
              });
            });
            this.setNoneSelected();
            return isBookingQuestionsEdited;
            break;
          }
        case 'productsAndOptions':
          {
            var isProductsAndOptionsEdited = false;
            var initProductsAndOptions = this.controller.get('initSelectedProductsAndOptions');
            var currentProductsAndOptions = this.controller.get('selectedProductsAndOptions');
            if (initProductsAndOptions.length != currentProductsAndOptions.length) {
              return true;
            } else {
              var matchCount = 0;
              initProductsAndOptions.forEach(function (init) {
                currentProductsAndOptions.forEach(function (current) {
                  if (init.offerId === current.offerId) {
                    matchCount++;
                  }
                });
              });
              if (matchCount != currentProductsAndOptions.length) {
                isProductsAndOptionsEdited = true;
              }
              return isProductsAndOptionsEdited;
            }
            break;
          }

        case 'productsAndOptionGroups':
          {
            return true;
            break;
          }

        case 'setName':
          {
            var initName = this.controller.get('initName');
            var currentName = this.controller.get('bookingQuestionSet.name');
            return !(initName === currentName);
            break;
          }
        default:
      }
    },
    validateName: function validateName() {
      return (0, _formValidator.validate)({
        model: this.controller.get('bookingQuestionSet'),
        attributes: ['name']
      });
    },
    resetController: function resetController() {
      this.controller.set('productList', []);
      this.refreshInitTravelerInfo(this.get('initGroupedTravelerInfo'));
      this.refreshInitTravelerInfo(this.get('initGroupedTravelerInfoWithoutPassportDob'));
      this.refreshInitTravelerInfo(this.get('initGroupedPickupInfo'));
      this.refreshInitTravelerInfo(this.get('initGroupedDropoffInfo'));
      this.controller.get('bookingQuestionSet').rollbackAttributes();
      this.controller.set('saveSuccessful', false);
      this.controller.set('bookingQuestionsAccordionModified', false);
      this.controller.set('productsAndOptionsAccordionModified', false);
      this.controller.set('setNameAccordionModified', false);
    },
    setNoneSelected: function setNoneSelected() {
      var travelerInfo = this.controller.get('bookingQuestionSet.questionDetails');
      var pickupInfo = [];
      var dropoffInfo = [];
      if (this.controller.get('isPickupDropoffEnabled')) {
        pickupInfo = this.controller.get('bookingQuestionSet.pickupDetails');
        dropoffInfo = this.controller.get('bookingQuestionSet.dropoffDetails');
      }
      var allBookingQuestions = travelerInfo.concat(pickupInfo.concat(dropoffInfo));
      var noneSelected = true;
      allBookingQuestions.forEach(function (bq) {
        bq.children.forEach(function (child) {
          noneSelected = noneSelected && !child.isEnabled;
        });
        noneSelected = noneSelected && !bq.isEnabled;
      });
      this.controller.set('noneSelected', noneSelected);
      if (noneSelected) {
        this.controller.set('bookingQuestionsAccordionComplete', false);
        this.controller.set('bookingQuestionsAccordionModified', false);
        this.controller.set('isProductsOptionsSectionExpanded', false);
      }
    },


    actions: {
      openNextSection: function openNextSection(section) {
        switch (section) {
          case 'bookingQuestionDetails':
            break;

          case 'productsAndOptions':
            if (!this.controller.get('productsAndOptionsAccordionComplete')) {
              this.controller.set('isProductsOptionsSectionExpanded', true);
            }
            break;

          case 'setName':
            if (!this.controller.get('setNameAccordionComplete')) {
              this.send('toggleSetNameSection');
            }
            break;
          default:
        }
      }
    }
  });
});