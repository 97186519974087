define('lxso/helpers/format-time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatTime = formatTime;
  function formatTime(params /*, hash*/) {
    var worldAndPeriod = params[1];
    var unformattedTime = params[0];
    var defaultValue = params[3] || ' --:-- --';
    if (!unformattedTime) {
      return defaultValue;
    }
    var minutesString = void 0;
    var hoursString = void 0;

    if (unformattedTime.includes(':')) {
      var hhmm = unformattedTime.split(':');
      minutesString = hhmm[1];
      hoursString = hhmm[0];
    } else {
      hoursString = unformattedTime.slice(0, -2);
      minutesString = unformattedTime.slice(-2);
    }

    var minutes = parseInt(minutesString);
    var hours = parseInt(hoursString);

    if (isNaN(minutes) || isNaN(hours) || minutesString.length > 2 || hoursString.length > 2) {
      return defaultValue;
    }

    var formattedHours = hoursString.length === 1 ? '0' + hoursString : hoursString;
    var formattedTime = formattedHours + ':' + minutesString;
    var isMorning = hours < 12;

    var periodTime = void 0;

    if (isMorning) {
      var morningHours = hoursString == 0 ? '12' : hoursString;
      periodTime = morningHours + ':' + minutesString + ' AM';
    } else {
      var adjustedAfternoonHours = hoursString - 12;
      var afternoonHours = adjustedAfternoonHours == 0 ? '12' : adjustedAfternoonHours;
      periodTime = afternoonHours + ':' + minutesString + ' PM';
    }
    if (worldAndPeriod) {
      return formattedTime + ' (' + periodTime + ')';
    } else {
      return unformattedTime.length > 2 ? periodTime : defaultValue;
    }
  }

  exports.default = Ember.Helper.helper(formatTime);
});