define('lxso/components/checkbox-with-tooltip/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      showToolTip: function showToolTip(e) {
        var checked = e.target.checked;

        if (checked === false) {
          Ember.$(e.path[1]).attr('class', 'checkbox-tooltip');
        }
      }
    }
  });
});