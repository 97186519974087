define('lxso/components/option-group/pricing/component', ['exports', 'lxso/config/environment', 'lxso/constants/price-types', 'lxso/utils/form-validator', 'lxso/mixins/example-modal', 'lxso/mixins/option-group/ticket-type-restrictions', 'lxso/constants/status-v4', 'lxso/utils/to-percent-without-percent-sign', 'lxso/constants/option-group', 'lxso/utils/scroll-to-element', 'lxso/mixins/paginate-source-array'], function (exports, _environment, _priceTypes, _formValidator, _exampleModal, _ticketTypeRestrictions, _statusV, _toPercentWithoutPercentSign, _optionGroup, _scrollToElement, _paginateSourceArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_exampleModal.default, _ticketTypeRestrictions.default, _paginateSourceArray.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    netPrice: Ember.inject.service(),
    responseErrors: Ember.inject.service(),
    classNames: ['option-group-price-v4'],
    editingPriceGroupIndex: null,
    lastUpdatedPriceGroupIndex: null,
    savingOptionGroupPriceAttrs: false,
    isPriceGroupDeactivateModalOpen: false,
    showPricingIngestionFlagUpdateConfirmModal: false,
    showPriceTypeSwitchModal: false,
    isSavingTicketTypeRestrictions: false,
    isEditingTicketTypeRestrictions: false,
    isDeactivatingPriceGroup: false,
    ticketTypeIdAttrsMap: Ember.computed('availableTicketTypes', function () {
      var ticketTypeIdAttrsMap = {};
      this.get('availableTicketTypes').forEach(function (tt) {
        ticketTypeIdAttrsMap[tt.get('ticketTypeId')] = {
          label: tt.get('baseLabel')
        };
      });
      return ticketTypeIdAttrsMap;
    }),

    capacityTicketTypeIdAttrsMap: Ember.computed('availableCapacityTicketTypes', function () {
      var capacityTicketTypeIdAttrsMap = {};
      this.get('availableCapacityTicketTypes').forEach(function (tt) {
        capacityTicketTypeIdAttrsMap[tt.get('ticketTypeId')] = {
          minPerGroup: tt.get('minPerGroup'),
          maxPerGroup: tt.get('maxPerGroup')
        };
      });
      return capacityTicketTypeIdAttrsMap;
    }),

    activePriceGroups: Ember.computed('priceGroups.@each.status', function () {
      return this.get('priceGroups').filter(function (pg) {
        return pg.get('status') === _statusV.default.ACTIVE;
      });
    }),

    isConnectedAndOgPiEnabled: Ember.computed('session.data.authenticated.selectedBranch.connectivity_status', 'optionGroup.attributes.pricingIngestionEnabled', function () {
      return this.get('session.data.authenticated.selectedBranch.connectivity_status') && this.get('optionGroup.attributes.pricingIngestionEnabled');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('PRICE_TYPES', _priceTypes.PRICE_TYPES);
      this.set('STATUS_V4', _statusV.default);
      this.set('SECTIONS', _optionGroup.SECTIONS);
      this.set('availableTicketTypes', this.get('ticketTypesV4.ticketTypes').filter(function (tt) {
        return tt.get('isSelected');
      }));
      this.set('availableCapacityTicketTypes', this.get('ticketTypesV4.capacityTicketTypes'));
      this.initPriceType();
      this.initTicketTypeRestrictions();
      this.initPaginateSourceArray('activePriceGroups');
    },
    initPriceType: function initPriceType() {
      var capacityTicketTypeIds = this.get('availableCapacityTicketTypes').map(function (tt) {
        return tt.get('ticketTypeId');
      });
      var hasCapacityPricing = void 0;
      try {
        hasCapacityPricing = capacityTicketTypeIds.includes(this.get('priceGroups').objectAt(0).get('prices').objectAt(0).get('ticketTypeId'));
      } catch (e) {
        hasCapacityPricing = false;
      }
      this.set('selectedPriceType', hasCapacityPricing ? _priceTypes.PRICE_TYPES.CAPACITY : _priceTypes.PRICE_TYPES.INDIVIDUAL_OR_VOLUME);
    },
    saveOptionGroupPriceAttrsConfirmAction: function saveOptionGroupPriceAttrsConfirmAction() {
      var _this = this;

      this.set('savingOptionGroupPriceAttrs', true);
      this.sendAction('setLastUpdated', _optionGroup.SECTIONS.V4_PRICE_ATTRIBUTES);
      this.get('optionGroup').save({ adapterOptions: { section: _optionGroup.SECTIONS.DETAILS } }).then(function () {
        _this.sendAction('enableOptionGroupUpdateButton', true);
      }).finally(function () {
        _this.set('savingOptionGroupPriceAttrs', false);
      });
    },
    handlePriceTypeSwitchConfirmAction: function handlePriceTypeSwitchConfirmAction(selectedPriceType) {
      this.set('selectedPriceType', selectedPriceType);
      this.get('priceGroups').forEach(function (pg) {
        pg.set('status', _statusV.default.PENDING_DELETION);
      });
      this.send('addPriceGroup');
    },


    actions: {
      openStep: function openStep() {
        if (this.get('isOptionGroupPriceV4Enabled')) {
          this.toggleProperty('isOptionGroupPriceV4Open');
        }
      },
      priceTypeChange: function priceTypeChange(priceType) {
        var _this2 = this;

        if (this.get('hasExistingPrices')) {
          this.set('showPriceTypeSwitchModal', true);
          var confirmAction = function confirmAction() {
            _this2.handlePriceTypeSwitchConfirmAction(priceType);
          };
          this.set('priceTypeSwitchConfirmAction', confirmAction);
        } else {
          this.handlePriceTypeSwitchConfirmAction(priceType);
        }
      },
      priceTypeChangeConfirm: function priceTypeChangeConfirm() {
        this.get('priceTypeSwitchConfirmAction')();
        this.set('showPriceTypeSwitchModal', false);
      },
      priceTypeChangeCancel: function priceTypeChangeCancel() {
        this.set('showPriceTypeSwitchModal', false);
        $('#price-type-individual-volume').prop('checked', this.get('selectedPriceType') === _priceTypes.PRICE_TYPES.INDIVIDUAL_OR_VOLUME);
        $('#price-type-capacity').prop('checked', this.get('selectedPriceType') === _priceTypes.PRICE_TYPES.CAPACITY);
      },
      saveOptionGroupPriceAttrs: function saveOptionGroupPriceAttrs() {
        if (this.get('optionGroup.attributes.pricingIngestionEnabled')) {
          this.set('showPricingIngestionFlagUpdateConfirmModal', true);
        } else {
          this.saveOptionGroupPriceAttrsConfirmAction();
        }
      },
      pricingIngestionFlagUpdateConfirmYes: function pricingIngestionFlagUpdateConfirmYes() {
        this.saveOptionGroupPriceAttrsConfirmAction();
        this.set('showPricingIngestionFlagUpdateConfirmModal', false);
      },
      pricingIngestionFlagUpdateConfirmNo: function pricingIngestionFlagUpdateConfirmNo() {
        this.toggleProperty('optionGroup.attributes.pricingIngestionEnabled');
        this.set('showPricingIngestionFlagUpdateConfirmModal', false);
      },
      triggerPricingIngestion: function triggerPricingIngestion() {
        var _this3 = this;

        this.sendAction('setLastUpdated', _optionGroup.SECTIONS.V4_PRICE_ATTRIBUTES);
        var offerGroupId = this.get('optionGroup.id');
        this.set('updatingPriceWithIngestion', true);
        var data = {};
        var url = _environment.default.api.hostV4 + '/offer-group/' + offerGroupId + '/triggerPricingIngestion';
        //Todo: This URL should be updated with new one supporting price group.
        this.get('ajaxWrapper').ajaxPut(url, data).then(function () {
          _this3.sendAction('enableOptionGroupUpdateButton', true);
          _this3.get('notifications').success(_this3.get('i18n').t('pages.optionGroup.optionGroupSection.priceV3.pricingIngestion.triggerSuccess'));
        }).catch(function () {
          _this3.get('notifications').error(_this3.get('i18n').t('pages.optionGroup.optionGroupSection.priceV3.pricingIngestion.triggerFailure'));
        }).finally(function () {
          _this3.set('updatingPriceWithIngestion', false);
        });
      },
      addPriceGroup: function addPriceGroup() {
        var priceGroup = this.get('store').createRecord('price-group-v4', {
          offerGroupId: this.get('optionGroup.id'),
          activityId: this.get('activity.id'),
          availabilityByDays: this.get('store').createFragment('fragments/price-group-v4/availability-by-days', {})
        });
        this.get('priceGroups').pushObject(priceGroup);
        var priceGroupIndex = this.get('activePriceGroups').length - 1;
        this.send('editPriceGroup', priceGroup, priceGroupIndex);
        this.goToPage(this.get('totalPages'));
      },
      editPriceGroup: function editPriceGroup(priceGroup, priceGroupIndex) {
        this.set('editingPriceGroupIndex', priceGroupIndex);
        if (this.get('selectedPriceType') === _priceTypes.PRICE_TYPES.CAPACITY) {
          this.addPricesForMissingCapacityTicketTypes(priceGroup);
        }
      },
      clonePriceGroup: function clonePriceGroup(priceGroupToClone, priceGroupIndexToClone) {
        var priceGroup = this.get('store').createRecord('price-group-v4', {
          offerGroupId: this.get('optionGroup.id'),
          activityId: this.get('activity.id'),
          travelStart: priceGroupToClone.get('travelStart'),
          travelEnd: priceGroupToClone.get('travelEnd'),
          bookingStart: priceGroupToClone.get('bookingStart'),
          bookingEnd: priceGroupToClone.get('bookingEnd'),
          bookingDatesDifferent: priceGroupToClone.get('bookingDatesDifferent'),
          availabilityByDays: this.get('store').createFragment('fragments/price-group-v4/availability-by-days', {
            offerGroupId: this.get('optionGroup.id'),
            availabilities: []
          }),
          status: _statusV.default.ACTIVE
        });

        priceGroupToClone.get('prices').forEach(function (price) {
          priceGroup.get('prices').createFragment({
            ticketTypeId: price.get('ticketTypeId'),
            basePrice: price.get('basePrice'),
            netPrice: price.get('netPrice'),
            margin: price.get('margin'),
            minimumTickets: price.get('minimumTickets'),
            priceCode: price.get('priceCode'),
            isSelected: price.get('isSelected')
          });
        });

        priceGroupToClone.get('availabilityByOffers').forEach(function (offerAvailability) {
          priceGroup.get('availabilityByOffers').createFragment({
            isSelected: offerAvailability.get('isSelected'),
            available: offerAvailability.get('available'),
            days: offerAvailability.get('days'),
            offerId: offerAvailability.get('offerId'),
            capacity: offerAvailability.get('capacity'),
            startTime: offerAvailability.get('startTime'),
            endTime: offerAvailability.get('endTime')
          });
        });

        if (priceGroupToClone.get('availabilityByDays.availabilities')) {
          priceGroupToClone.get('availabilityByDays.availabilities').forEach(function (availabilityDay) {
            priceGroup.get('availabilityByDays.availabilities').createFragment({
              day: availabilityDay.get('day'),
              capacity: availabilityDay.get('capacity'),
              available: availabilityDay.get('available'),
              startTime: availabilityDay.get('startTime'),
              endTime: availabilityDay.get('endTime')
            });
          });
        }

        this.get('priceGroups').pushObject(priceGroup);
        this.goToPage(this.get('totalPages'));
        var priceGroupIndex = this.get('activePriceGroups').length - 1;
        this.send('editPriceGroup', priceGroup, priceGroupIndex);
        (0, _scrollToElement.scrollToElement)('#og-price-group-list-and-add-section .option-group-price-group-v4:last-of-type');
      },
      openDeactivatePriceGroup: function openDeactivatePriceGroup(priceGroup, priceGroupIndex) {
        var _this4 = this;

        this.toggleProperty('isPriceGroupDeactivateModalOpen');
        var onPriceGroupDeactivateConfirm = function onPriceGroupDeactivateConfirm() {
          _this4.set('isDeactivatingPriceGroup', true);
          priceGroup.destroyRecord().then(function () {
            _this4.get('priceGroups').removeObject(priceGroup);
            _this4.sendAction('updateHasExistingPrices');
            _this4.set('isPriceGroupDeactivateModalOpen', false);
          }).catch(function (errors) {
            return _this4.set('errors', errors);
          }).finally(function () {
            _this4.set('isDeactivatingPriceGroup', false);
            if (_this4.get('currentPage') > _this4.get('totalPages')) {
              _this4.goToPage(1);
            }
          });
        };
        this.set('onPriceGroupDeactivateConfirm', onPriceGroupDeactivateConfirm);
      },
      handleDeactivatePriceGroupConfirm: function handleDeactivatePriceGroupConfirm() {
        this.get('onPriceGroupDeactivateConfirm')();
      },
      handleDeactivatePriceGroupCancel: function handleDeactivatePriceGroupCancel() {
        this.toggleProperty('isPriceGroupDeactivateModalOpen');
        this.set('errors', null);
        this.get('responseErrors').clearErrors();
        this.set('onPriceGroupDeactivateConfirm', null);
      },
      savePriceGroup: function savePriceGroup(priceGroup, priceGroupIndex) {
        var _this5 = this;

        this.set('errors', null);
        this.sendAction('setLastUpdated', _optionGroup.SECTIONS.V4_PRICE_GROUP);
        this.set('lastUpdatedPriceGroupIndex', priceGroupIndex);
        this.set('savingPriceGroup', true);
        var priceGroupsPendingDeletion = this.get('priceGroups').filter(function (pg) {
          return pg.get('status') === _statusV.default.PENDING_DELETION && pg.get('priceGroupId') > 0;
        });
        var deactivatePendingPromises = priceGroupsPendingDeletion.map(function (pg) {
          return pg.destroyRecord();
        });
        Ember.RSVP.all(deactivatePendingPromises).then(function () {
          return priceGroup.save(_this5.getPriceGroupSaveAdapterOptions(priceGroup));
        }).then(function (response) {
          _this5.set('editingPriceGroupIndex', null);
          _this5.sendAction('enableOptionGroupUpdateButton', true);
          _this5.sendAction('updateHasExistingPrices');
          // this.get('notifications').success(this.get('i18n').t('components.alert.saveSuccess'));
          // ToDo: uncomment and update message if we want to show any notification on saving price group.
          if (_this5.get('activity.isActivityPublished')) {
            return _this5.sendAction('publishChanges');
          }
        }).catch(function (errors) {
          _this5.set('errors', errors);
          (0, _formValidator.scrollToFirstError)();
        }).finally(function () {
          _this5.set('savingPriceGroup', false);
        });
      },
      cancelEditPriceGroup: function cancelEditPriceGroup(priceGroup, priceGroupIndex) {
        this.revertDeletionPendingPriceGroups();
        this.set('editingPriceGroupIndex', null);
        this.get('responseErrors').clearErrors();
        if (!priceGroup.get('id')) {
          this.get('priceGroups').removeObject(priceGroup);
          priceGroup.destroyRecord();
        } else {
          priceGroup.rollbackAttributes();
        }
        if (this.get('currentPage') > this.get('totalPages')) {
          this.goToPage(1);
        }
      }
    },
    addPricesForMissingCapacityTicketTypes: function addPricesForMissingCapacityTicketTypes(priceGroup) {
      var _this6 = this;

      var ticketTypeIdsOfActivePrices = priceGroup.get('prices').map(function (p) {
        return p.get('ticketTypeId');
      });
      this.get('availableCapacityTicketTypes').forEach(function (tt) {
        if (tt.get('ticketTypeId') && !ticketTypeIdsOfActivePrices.includes(tt.get('ticketTypeId'))) {
          var margin = parseFloat((0, _toPercentWithoutPercentSign.default)(_this6.get('netPrice').getMargin(tt.get('ticketTypeId'))));
          priceGroup.get('prices').createFragment({
            ticketTypeId: tt.get('ticketTypeId'),
            basePrice: null,
            netPrice: null,
            margin: margin,
            minimumTickets: 1,
            priceCode: '',
            isSelected: false
          });
        }
      });
    },
    revertDeletionPendingPriceGroups: function revertDeletionPendingPriceGroups() {
      var priceGroupsPendingDeletion = this.get('priceGroups').filter(function (pg) {
        return pg.get('status') === _statusV.default.PENDING_DELETION && pg.get('priceGroupId') > 0;
      });
      priceGroupsPendingDeletion.forEach(function (pg) {
        return pg.rollbackAttributes();
      });
      if (priceGroupsPendingDeletion.length > 0) {
        this.initPriceType();
      }
    },
    getPriceGroupSaveAdapterOptions: function getPriceGroupSaveAdapterOptions(priceGroup) {
      var scheduleType = this.get('optionGroup.attributes.scheduleType');
      var scheduleSuperType = _optionGroup.START_TIME_BASED_SCHEDULES.includes(scheduleType) ? _optionGroup.SCHEDULE_TYPES.START : _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION;
      return {
        adapterOptions: {
          offerIds: this.get('optionGroup.offers').map(function (offerDetails) {
            return offerDetails.get('offerIds');
          }).flat(),
          activityId: parseInt(this.get('activity.id')),
          availabilityType: this.get('optionGroup.attributes.availabilityType'),
          scheduleSuperType: scheduleSuperType,
          primaryId: priceGroup.id
        }
      };
    }
  });
});