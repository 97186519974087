define('lxso/services/tshop', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajaxWrapper: Ember.inject.service(),

    createTShopIdForOptions: function createTShopIdForOptions(optionIds, offerGroupId) {
      if (optionIds.length) {
        var url = _environment.default.api.hostV4 + '/offer-group/' + offerGroupId + '/sku-id';
        var requestBody = {
          offerIds: optionIds
        };
        return this.get('ajaxWrapper').ajaxPost(url, requestBody);
      } else {
        return Ember.RSVP.resolve();
      }
    },
    createTShopIdForActivity: function createTShopIdForActivity(activityId, vendorId, vendorBranchId) {
      var url = _environment.default.api.hostV4 + '/activity/' + activityId + '/offering-id';
      var requestBody = {
        vendorId: vendorId,
        vendorBranchId: vendorBranchId
      };
      return this.get('ajaxWrapper').ajaxPost(url, requestBody);
    }
  });
});