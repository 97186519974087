define('lxso/services/activity-migrate-support', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    featureFlags: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),

    activityGet: function activityGet(activityId, qParams, useV4Api) {
      var _this = this;

      var v3Url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/activities/' + activityId + '?' + qParams;
      var v4Url = _environment.default.api.hostV4 + '/supply/' + activityId;
      var isMigrateOverrideEnabled = this.get('featureFlags').checkEnabled('V4ContentAndLogisticsMigrationOverride');
      if (useV4Api || isMigrateOverrideEnabled) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('ajaxWrapper').ajaxGet(v4Url, null, {
            xhrFields: {
              withCredentials: true
            }
          }).then(function (v4Response) {
            resolve(mapV4ModelToV3(v4Response));
          }).catch(function (err) {
            reject(err);
          });
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('ajaxWrapper').ajaxGet(v3Url, null, {
            xhrFields: {
              withCredentials: true
            }
          }).then(function (v3Response) {
            var migrationStatus = v3Response.migrationStatus;
            var isMigrated = migrationStatus && migrationStatus.content && migrationStatus.logistics;
            if (isMigrated) {
              _this.get('ajaxWrapper').ajaxGet(v4Url, null, {
                xhrFields: {
                  withCredentials: true
                }
              }).then(function (v4Response) {
                return resolve(mapV4ModelToV3(v4Response));
              }).catch(function (err) {
                reject(err);
              });
            } else {
              resolve(v3Response);
            }
          }).catch(function (err) {
            reject(err);
          });
        });
      }
    },
    fetchOfferMigrateStatus: function fetchOfferMigrateStatus(branchId) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var productsMigrationStatusUrl = _environment.default.api.host + '/api/v1/supplier_branches/' + branchId + '/activities';
        var activityStatusesToFilter = ['active', 'suspended', 'suspendedexpedia', 'suspendedpaused'];
        _this2.get('ajaxWrapper').ajaxGet(productsMigrationStatusUrl).then(function (activities) {
          if (activities && activities.length) {
            var activeActivities = activities.filter(function (activity) {
              return activityStatusesToFilter.includes(activity.status);
            });
            var combinedOfferMigrationStatus = activeActivities.length > 0 && activeActivities.map(function (activity) {
              if (activity.activityDomainMigrationStatus) {
                return activity.activityDomainMigrationStatus.offer;
              } else {
                return false;
              }
            }).reduce(function (prev, curr) {
              return prev || curr;
            }, false);
            resolve(combinedOfferMigrationStatus);
          } else {
            resolve(false);
          }
        }).catch(function () {
          reject();
        });
      });
    }
  });


  function mapV4ModelToV3(v4Response) {
    return {
      activity_id: v4Response.activityId,
      last_saved_date: '',
      lastSavedBy: '',
      createdDate: '',
      createdBy: '',
      supplier_branch: getV3SupplierBranchModel(v4Response.supplierBranch),
      language: 'en-US',
      language_supported: '',
      activity_location: getV3LocationModel(v4Response.activityLocation),
      redemption_address: {
        addresses: v4Response.redemptionLocation ? v4Response.redemptionLocation.map(function (location) {
          return getV3LocationModel(location);
        }) : []
      },
      attributes: getAttributes(v4Response),
      basic: {
        internal_title: v4Response.internalTitle,
        short_title: v4Response.internalTitle,
        long_title: v4Response.internalTitle,
        suggested_for: null,
        description: v4Response.description
      },
      categories: v4Response.userExperienceGroup,
      offers: [],
      highlights: getHighlights(v4Response),
      legacy: {
        title: '',
        description: '',
        short_title: '',
        short_description: '',
        instructions: ''
      },
      things: getThings(v4Response),
      policy: {
        cancellation_addendum: null,
        terms_and_conditions: null,
        cancellation_policy: []
      },
      termsAndConditions: getTermsAndConditionsModel(v4Response),
      operatorCancellation: getOperatorCancellationModel(v4Response),
      operational: Object.assign({}, v4Response.operational, {
        status: v4Response.operational.status.toLowerCase()
      }),
      customer_support_ids: v4Response.customerSupportIds,
      ofid: v4Response.offeringId,
      availability_schedules: [],
      content_last_saved_date: null,
      system_source: null,
      isActivityPublished: v4Response.isActivityPublished,
      connectivityStatus: v4Response.connectivityStatus,
      thirdPartySupply: v4Response.thirdPartySupply,
      migrationStatus: v4Response.migrationStatus
    };
  }

  function getV3SupplierBranchModel(v4SupplierBranch) {
    return {
      'supplier_id': v4SupplierBranch.supplierId,
      'supplier_name': null,
      'supplier_branch_id': v4SupplierBranch.supplierBranchId,
      'supplier_branch_name': v4SupplierBranch.supplierBranchName,
      'status': v4SupplierBranch.status, // convert ACTIVE to Active
      'company_code': null,
      'salesforce_code': null,
      'primary_dm': null,
      'default_timezone': v4SupplierBranch.defaultTimeZone,
      'currency_type': v4SupplierBranch.currencyType,
      'supplier_branch_type': v4SupplierBranch.supplierBranchType,
      'vendor_branch_id': v4SupplierBranch.vendorBranchId,
      'vendor_id': v4SupplierBranch.vendorId,
      'invoice_required': null,
      'pay_date_basis': null,
      'affiliate_id': null, //what is affiliate id
      'supplier_support': [],
      'last_updated_by': null,
      'last_update_date': null,
      'platform_id': null,
      'notification_settings': [],
      'connectivity_status': null,
      'ingestedFlags': [],
      'margin': null,
      'thirdPartySupply': null,
      'auto_renew_rate_enabled': null,
      'is_active': null
    };
  }

  function getV3LocationModel(v4Location) {
    return {
      'address_name': v4Location ? v4Location.description : '',
      'street': v4Location ? v4Location.addressLine1 : '',
      'street_second_line': v4Location ? v4Location.addressLine2 : '',
      'city': v4Location ? v4Location.city : '',
      'state': v4Location ? v4Location.state : '',
      'postal_code': v4Location ? v4Location.zipCode : '',
      'country': v4Location ? v4Location.country : '',
      'phone_number': v4Location ? v4Location.phone : '',
      'latitude': v4Location ? v4Location.latitude : 0,
      'longitude': v4Location ? v4Location.longitude : 0,
      'timezone': v4Location ? v4Location.timezone : '',
      'formatted_address': v4Location ? v4Location.formattedAddress : ''
    };
  }

  function getTermsAndConditionsModel(v4Response) {
    if (v4Response.policy && v4Response.policy.termsAndConditions) {
      var tnc = v4Response.policy.termsAndConditions;
      return {
        age_restriction: tnc.ageRestriction ? tnc.ageRestriction : 0,
        liability_waiver: !!tnc.liabilityWaiver,
        optional_content: tnc.optionalContent ? tnc.optionalContent : ''
      };
    } else {
      return {
        age_restriction: 0,
        liability_waiver: false,
        optional_content: ''
      };
    }
  }

  function getOperatorCancellationModel(v4Response) {
    if (v4Response.policy && v4Response.policy.operatorCancellation) {
      return v4Response.policy.operatorCancellation;
    } else {
      return {
        groupSizeNotMet: false,
        inclementWeather: false,
        optionalContent: ''
      };
    }
  }

  function getAttributes(v4Response) {
    if (v4Response) {
      return {
        user_experience_group: v4Response.userExperienceGroup,
        vehicle_type: v4Response.groundTransport ? v4Response.groundTransport.vehicleType : '',
        suggested_rank: v4Response.suggestedRank,
        passenger_capacity: v4Response.groundTransport ? v4Response.groundTransport.passengerCapacity : 0,
        baggage_capacity: v4Response.groundTransport ? v4Response.groundTransport.baggageCapacity : 0,
        redemption_type: v4Response.instructions && v4Response.instructions.redemptionInstructions && v4Response.instructions.redemptionInstructions.printedVoucherRequired ? 'Print' : 'Voucherless'
      };
    } else {
      return null;
    }
  }

  function getThings(v4Response) {
    var instructions = v4Response.instructions;
    return {
      know_before_you_book: instructions && instructions.knowBeforeYouBook ? instructions.knowBeforeYouBook : [],
      know_before_you_go: instructions && instructions.knowBeforeYouGo ? instructions.knowBeforeYouGo : [],
      redemption_instructions: instructions && instructions.redemptionInstructions && instructions.redemptionInstructions.redemptionInstructions ? instructions.redemptionInstructions.redemptionInstructions : '',
      gt_instructions: instructions && instructions.gtInstructions ? instructions.gtInstructions : ''
    };
  }

  function getHighlights(v4Response) {
    var details = v4Response.details;
    return {
      highlights: details && details.highlights ? details.highlights : [],
      inclusions: details && details.inclusions ? details.inclusions : [],
      exclusions: details && details.exclusions ? details.exclusions : []
    };
  }
});