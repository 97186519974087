define('lxso/routes/products', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-supplier-branch', 'lxso/mixins/require-permission', 'lxso/mixins/sort-status-alphabetical', 'lxso/utils/omniture', 'lxso/config/environment', 'lxso/utils/array-values'], function (exports, _authenticatedRouteMixin, _requireSupplierBranch, _requirePermission, _sortStatusAlphabetical, _omniture, _environment, _arrayValues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, _requirePermission.default, _sortStatusAlphabetical.default, {
    requiredPermission: 'V3Product',
    threepSupplier: Ember.inject.service(),
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    mediavault: Ember.inject.service(),
    routeHistory: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.Products'
    },

    beforeModel: function beforeModel() {
      this.set('V3ProductListDMView', this.get('permissions').checkEnabled('V3ProductListDMView'));
    },
    model: function model(params) {
      var _this = this;

      var supplierBranch = this.store.find('supplier-branch', params.id);
      return Ember.RSVP.hash({
        branchId: params.id,
        supplierBranch: supplierBranch,
        activities: supplierBranch.then(function (supplierBranch) {
          return supplierBranch.activities();
        }),
        validationStatus: supplierBranch.then(function (supplierBranch) {
          return supplierBranch.validationStatus();
        }),
        supplier: supplierBranch.then(function (supplierBranch) {
          return _this.store.queryRecord('suppliers', {
            supplierId: supplierBranch.get('supplier_id'),
            isBasic: true,
            branchId: params.id
          });
        })
      });
    },
    afterModel: function afterModel(model) {
      'use strict';

      if (Ember.isEmpty(model.activities)) {
        this.transitionTo('product-setup');
      }
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      this._super(controller, model);
      this.controller.set('supplierBranch', model.supplierBranch);
      this.controller.set('supplier', model.supplier);
      this.controller.set('validationStatus', model.validationStatus);
      this.controller.set('mappingErrorExists', false);
      this.controller.set('deactivateReasonsSupplierOptions', [this.get('i18n').t('pages.deactivateProduct.supplierReasonOptions.noExpedia'), this.get('i18n').t('pages.deactivateProduct.commonReasonOptions.productDiscontinued'), this.get('i18n').t('pages.deactivateProduct.commonReasonOptions.other'), this.get('i18n').t('pages.deactivateProduct.commonReasonOptions.technicalCompatibilityIssues'), this.get('i18n').t('pages.deactivateProduct.commonReasonOptions.contractualDispute')].sort());
      var isExternalUser = !this.get('permissions').checkEnabled('V3InternalSettings');
      if (isExternalUser) {
        this.controller.set('suspendReasonsOptions', (0, _arrayValues.getArrayOfValues)(5).map(function (i) {
          return _this2.get('i18n').t('pages.products.productList.suspendedReasonsExternal.' + i);
        }).sort());
      } else {
        this.controller.set('suspendReasonsOptions', (0, _arrayValues.getArrayOfValues)(9).map(function (i) {
          return _this2.get('i18n').t('pages.products.productList.suspendedReasons.' + i);
        }).sort());
      }

      this.controller.set('deactivateReasonsDmOptions', [this.get('i18n').t('pages.deactivateProduct.dmReasonOptions.noLongerOperates'), this.get('i18n').t('pages.deactivateProduct.dmReasonOptions.hAndSConcerns'), this.get('i18n').t('pages.deactivateProduct.dmReasonOptions.coiRequirements'), this.get('i18n').t('pages.deactivateProduct.dmReasonOptions.taxRequirements'), this.get('i18n').t('pages.deactivateProduct.commonReasonOptions.productDiscontinued'), this.get('i18n').t('pages.deactivateProduct.dmReasonOptions.expediaTermination'), this.get('i18n').t('pages.deactivateProduct.dmReasonOptions.qualityConcerns'), this.get('i18n').t('pages.deactivateProduct.dmReasonOptions.notResponsive'), this.get('i18n').t('pages.deactivateProduct.dmReasonOptions.partnerTermination'), this.get('i18n').t('pages.deactivateProduct.commonReasonOptions.other'), this.get('i18n').t('pages.deactivateProduct.commonReasonOptions.technicalCompatibilityIssues'), this.get('i18n').t('pages.deactivateProduct.commonReasonOptions.contractualDispute')].sort());

      this.controller.set('deactivateReason', '');
      this.controller.set('isReasonEmpty', true);
      this.controller.set('isDeactivating', false);
      this.controller.set('isActivating', false);
      this.controller.set('isActivated', false);
      this.controller.set('isDeactivated', false);
      this.controller.set('isSuspended', false);
      this.controller.set('isSuspending', false);

      if (model.validationStatus !== undefined) {
        this.controller.set('scSupplierBranch', model.validationStatus.scSupplierBranch);
        this.controller.set('scActivityWithSCOffer', model.validationStatus.scActivityWithSCOffer);
        this.createMappingAlertMessage();
      }
      var numberOfProducts = Array.isArray(model.activities) ? model.length : 0;

      this.set('omniture.prop1', model.activities.length);
      this.set('omniture.eVar3', this.get('session.session.authenticated.selectedBranch.supplier_name'));

      var isGTBranch = this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
      controller.setProperties({
        compareBy: 'Name (A-Z)',
        products: this.sortByStatusAndAlphabetical(model.activities, false),
        groundTransferCategories: Ember.A(['Shared Transfer', 'Private Transfer', 'Mass Transportation']),
        deactivateProductOpen: false,
        activateProductOpen: false,
        isGTBranchAndExternalUser: isExternalUser && isGTBranch,
        isWhitelistedGTSupplierBranch: _environment.default.whiteListedGTSupplierBranch.contains(parseInt(model.branchId)),
        isGTBranch: isGTBranch
      });

      this.controller.set('V3ProductListDMView', this.get('V3ProductListDMView'));
      this.controller.set('branchId', this.get('session.data.authenticated.selectedBranch.supplier_branch_id'));
      var products = this.controller.get('products');
      if (products) {
        products.forEach(function (product) {
          _this2.setProductImageUrls(product);
        });
      }
      var manageAvailabilityEnabled = this.get('featureFlags').checkEnabled('manageAvailability');
      this.controller.set('manageAvailabilityFlag', manageAvailabilityEnabled);
      this.setStatusFiltersAndModal(products);
    },
    setStatusFiltersAndModal: function setStatusFiltersAndModal(products) {
      var defaultProperties = {
        isFilterStatusModalOpen: false,
        isFilterApplyDisabled: Ember.computed('allStatuses.@each.value', function () {
          var selectedFiltersLength = this.get('allStatuses').filterBy('value', true).length > 0;
          return !selectedFiltersLength;
        })
      };

      var is3PSupplier = this.get('threepSupplier').isSupplier3P();

      if (this.controller.get('scSupplierBranch') && !is3PSupplier) {
        defaultProperties.initStatus = ['active', 'suspended'];
        this.controller.setProperties(defaultProperties);
      } else {
        defaultProperties.initStatus = ['active'];
      }

      if (this.get('featureFlags').checkEnabled('SuspendedPausedAndExpedia') && !is3PSupplier) {
        defaultProperties.initStatus = [].concat(_toConsumableArray(defaultProperties.initStatus), ['suspendedpaused', 'suspendedexpedia']);
      }

      this.controller.setProperties(defaultProperties);
      this.setAvailableStatuses(products);
      this.initStatusFilter(this.controller.get('initStatus'));
    },
    setAvailableStatuses: function setAvailableStatuses(products) {
      var availableStatuses = products.map(function (product) {
        return product.get('status');
      }).uniq();
      this.controller.set('availableStatuses', availableStatuses);
    },
    createMappingAlertMessage: function createMappingAlertMessage() {
      var mappingErrorMsg = [];
      var scSupplierBranch = this.controller.get('scSupplierBranch');

      // only show mapping error for connectivity enabled supplier
      if (scSupplierBranch.connectivityStatus == true) {
        if (scSupplierBranch.validationStatus == 'Invalid') {
          var errorMsg1 = this.get('i18n').t('pages.products.labels.mappingErrorMsg1');
          mappingErrorMsg.push(errorMsg1 + scSupplierBranch.id);
        }
        var scActivityWithOffer = this.controller.get('scActivityWithSCOffer');

        for (var i = 0; i < scActivityWithOffer.length; i++) {
          var scActivity = scActivityWithOffer[i].scActivity;
          var scOffers = scActivityWithOffer[i].scOffer;

          if (scActivity.validationStatus == 'Invalid') {
            var errorMsg2 = this.get('i18n').t('pages.products.labels.mappingErrorMsg2');
            mappingErrorMsg.push(errorMsg2 + scActivity.activityId);
          }

          for (var j = 0; j < scOffers.length; j++) {
            if (scOffers[j].validationStatus == 'Invalid') {
              var errorMsg3 = this.get('i18n').t('pages.products.labels.mappingErrorMsg3');
              mappingErrorMsg.push(errorMsg3 + scOffers[j].offerId);
            }
          }
        }
        this.controller.set('mappingErrorMsg', mappingErrorMsg);
        if (mappingErrorMsg.length > 0) {
          this.controller.set('mappingErrorExists', true);
        }
      }
    },
    setProductImageUrls: function setProductImageUrls(product) {
      Ember.set(product, 'desktopHeroImage', product.heroImageUrl);
      Ember.set(product, 'mobileHeroImage', product.heroImageUrl);
    },
    loadImages: function loadImages() {
      Ember.run.scheduleOnce('afterRender', function () {
        window.uitk.utils.loadMedia();
      });
    },
    setFilteredProducts: function setFilteredProducts(selectedStatuses) {
      var products = this.controller.get('products');
      var filteredProducts = products;
      if (!selectedStatuses) {
        filteredProducts = products;
      } else {
        var selectedStatsLowerCase = selectedStatuses.map(function (stat) {
          return stat.toLowerCase();
        });
        filteredProducts = products.filter(function (product) {
          return selectedStatsLowerCase.indexOf(product.get('status')) >= 0;
        });
      }

      this.controller.set('filteredProducts', filteredProducts);

      var sortBy = this.controller.get('compareBy');
      var reverse = false;
      if (sortBy === 'Name (Z-A)') {
        reverse = true;
      }
      var sortedProducts = this.sortByStatusAndAlphabetical(this.controller.get('filteredProducts'), reverse);
      this.controller.set('filteredProducts', sortedProducts);

      this.setSelectedStatusMessage(selectedStatuses);
    },
    setSelectedStatusMessage: function setSelectedStatusMessage(selectedStatuses) {
      var _this3 = this;

      var statusMessage = '';
      var selectedStatusesLower = selectedStatuses.map(function (item) {
        return _this3.get('i18n').t('pages.products.productList.statusFilter.' + item).toString().toLowerCase();
      }).sort();
      if (selectedStatusesLower && selectedStatusesLower.length > 0) {
        if (selectedStatusesLower.length === 1) {
          statusMessage = selectedStatusesLower[0];
        } else {
          statusMessage = selectedStatusesLower.slice(0, selectedStatusesLower.length - 1).join(', ');
          statusMessage += ' ' + this.get('i18n').t('common.words.and');
          statusMessage += ' ' + selectedStatusesLower[selectedStatusesLower.length - 1];
        }
      }
      this.controller.set('selectedStatusMessage', statusMessage);
    },
    initStatusFilter: function initStatusFilter(filterStatuses) {
      var _this4 = this;

      var availableStatuses = this.controller.get('availableStatuses');
      var selectedStatuses = filterStatuses ? filterStatuses : availableStatuses;
      var is3PSupplier = this.get('threepSupplier').isSupplier3P();
      var allStatusList = void 0;

      if (this.controller.get('scSupplierBranch') && !is3PSupplier) {
        allStatusList = ['active', 'inactive', 'suspended'];
      } else {
        allStatusList = ['active', 'inactive', 'deactivated'];
      }

      if (this.get('featureFlags').checkEnabled('SuspendedPausedAndExpedia') && !is3PSupplier) {
        allStatusList = [].concat(_toConsumableArray(allStatusList), ['suspendedpaused', 'suspendedexpedia']);
      }

      this.controller.set('selectedStatuses', selectedStatuses);
      var helpTextAppender = is3PSupplier ? '3P' : '';
      var allStatuses = allStatusList.map(function (status) {
        return {
          name: status,
          label: _this4.get('i18n').t('pages.products.productList.statusFilter.' + status),
          helpText: _this4.get('i18n').t('pages.products.productList.statusFilter.' + status + 'HelpText' + helpTextAppender),
          value: availableStatuses && selectedStatuses.indexOf(status) >= 0
        };
      });
      this.controller.set('allStatuses', allStatuses);
      this.setFilteredProducts(selectedStatuses);
    },


    actions: {
      findCampaign: function findCampaign() {
        var branchId = this.controller.get('supplierBranch').get('id');
        this.transitionTo('campaign-list', branchId);
      },
      createPromotion: function createPromotion() {
        this.get('routeHistory').setPromotionBackURL('products');
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.create.promotion-list.homepage');
        this.transitionTo('promotion', 'new', {
          queryParams: {
            productId: null,
            optionId: null,
            clone: null,
            campaignId: null
          }
        });
      },
      viewPromotions: function viewPromotions() {
        this.get('routeHistory').setPromotionBackURL('products');
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Promotion-list.homepage');
        var branchId = this.controller.get('supplierBranch.id');
        this.transitionTo('promotion-list', branchId);
      },
      openDeactivateProduct: function openDeactivateProduct(product, status) {
        this.controller.set('productCurrentStatus', product.get('status'));
        this.controller.set('deactivateProductOpen', true);
        this.controller.set('currentProduct', product);
      },
      openActivateProduct: function openActivateProduct(product, status) {
        this.controller.set('productCurrentStatus', product.get('status'));
        this.controller.set('activateProductOpen', true);
        this.controller.set('currentProduct', product);
      },
      openSuspendExpedia: function openSuspendExpedia(product, status) {
        this.controller.set('productCurrentStatus', product.get('status'));
        this.controller.set('suspendExpediaOpen', true);
        this.controller.set('suspendedReason', '');
        this.controller.set('isReasonEmpty', true);
        this.controller.set('currentProduct', product);
      },
      openSuspendPaused: function openSuspendPaused(product, status) {
        this.controller.set('productCurrentStatus', product.get('status'));
        this.controller.set('suspendPausedOpen', true);
        this.controller.set('suspendedReason', '');
        this.controller.set('isReasonEmpty', true);
        this.controller.set('currentProduct', product);
      },
      addProduct: function addProduct() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.CreateProduct');
        this.transitionTo('product-setup');
      },
      productStatusModalClick: function productStatusModalClick() {
        this.controller.set('selectedStatusesCopy', this.controller.get('selectedStatuses'));
        this.controller.toggleProperty('isFilterStatusModalOpen');
      },
      cancelProductsFilter: function cancelProductsFilter() {
        this.initStatusFilter(this.controller.get('selectedStatusesCopy'));
        this.controller.set('isFilterStatusModalOpen', false);
      },
      applyProductsFilter: function applyProductsFilter(allStatuses) {
        var selectedStatusArray = [];
        allStatuses.forEach(function (status) {
          if (status.value === true) {
            selectedStatusArray.push(status.name);
          }
        });
        this.controller.set('selectedStatuses', selectedStatusArray);
        this.setFilteredProducts(this.controller.get('selectedStatuses'));
        this.controller.set('isFilterStatusModalOpen', false);
      },
      promoteProduct: function promoteProduct(productDetails) {
        var promotionId = 'new';
        var productId = productDetails.get('activity_id');
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Products.promotion');
        this.get('routeHistory').setPromotionBackURL('products');
        this.transitionTo('promotion', promotionId, {
          queryParams: {
            productId: productId,
            optionId: null,
            clone: null,
            campaignId: null
          }
        });
      },
      editProduct: function editProduct(product) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.EditProduct');
        var migrationStatus = product.activityDomainMigrationStatus;
        var isMigrateOverrideEnabled = this.get('featureFlags').checkEnabled('V4ContentAndLogisticsMigrationOverride');
        var isMigrated = isMigrateOverrideEnabled || migrationStatus && migrationStatus.content && migrationStatus.logistics;

        // All activity APIs are not migrated together.
        // Currently we can not use v4 APIs for all API end points even though activity is version 4
        this.transitionTo('activity.overview', product.activity_id, { queryParams: { isMigrated: isMigrated } });
      },
      gotoManageAvailability: function gotoManageAvailability(activityId) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AvailMgr');
        this.transitionTo('manage-availability', this.controller.get('branchId'), { queryParams: { activity: activityId } });
      },
      gotoProductSettings: function gotoProductSettings(activityId) {
        var isMigrated = '';
        this.transitionTo('activity.activity-settings', activityId, { queryParams: { isMigrated: isMigrated } });
      },
      updateOperational: function updateOperational(product, status) {
        var _this5 = this;

        // Disable button after click to prevent multiple clicks:
        var self = this;
        var cancelEvent = void 0;
        var reason = '';
        if (status === 'inactive') {
          $('#button-continue-modal-product-deactivate').prop('disabled', true);
          cancelEvent = 'cancelDeactivateProduct';
          this.controller.set('isDeactivating', true);
          this.controller.set('isActivated', false);
          this.controller.set('isDeactivated', true);
          reason = this.controller.get('deactivateReason');
        } else if (status === 'active') {
          $('#button-continue-modal-product-activate').prop('disabled', true);
          cancelEvent = 'cancelActivateProduct';
          this.controller.set('deactivateReason', '');
          this.controller.set('isActivating', true);
          this.controller.set('isActivated', true);
          this.controller.set('isDeactivated', false);
        } else if (status === 'suspendedexpedia') {
          $('#button-continue-modal-product-activate').prop('disabled', true);
          cancelEvent = 'cancelSuspendExpedia';
          this.controller.set('isSuspending', true);
          this.controller.set('isSuspended', true);
          reason = this.controller.get('suspendedReason');
        } else if (status === 'suspendedpaused') {
          $('#button-continue-modal-product-activate').prop('disabled', true);
          cancelEvent = 'cancelSuspendPaused';
          this.controller.set('isSuspending', true);
          this.controller.set('isSuspended', true);
          reason = this.controller.get('suspendedReason');
        }
        var productToUpdate = null;
        if (!product) {
          productToUpdate = this.controller.get('currentProduct');
        } else {
          productToUpdate = product;
        }

        var activityId = productToUpdate.activity_id;

        productToUpdate.set('status', status);
        this.store.find('activity', activityId).then(function (activity) {
          activity.set('operational.status', status);
          activity.set('operational.deactivationReason', reason);
          activity.updateOperational(activity.get('operational')).then(function () {
            _this5.get('notifications').success(_this5.get('i18n').t('components.alert.activateSuccess'));
          }).catch(function (e) {
            _this5.get('notifications').error(_this5.get('i18n').t('components.alert.activateFailed'));
            Ember.Logger.error('Changing activity status failed', e);
            reject(e);
          }).finally(function () {
            _this5.controller.set('isDeactivating', false);
            _this5.controller.set('deactivateReason', '');
            _this5.controller.set('isActivating', false);
            _this5.controller.set('isSuspending', false);
            _this5.controller.set('isReasonEmpty', true);
            setTimeout(function () {
              self.send(cancelEvent);
            }, 2000);
          });
        });
      },
      setDeactivateReason: function setDeactivateReason(reason) {
        this.controller.set('deactivateReason', reason);
        this.controller.set('isReasonEmpty', reason === '');
      },
      setSuspendedReason: function setSuspendedReason(reason) {
        this.controller.set('suspendedReason', reason);
        this.controller.set('isReasonEmpty', reason === '');
      },
      cancelDeactivateProduct: function cancelDeactivateProduct() {
        this.controller.set('deactivateProductOpen', false);
        this.controller.set('currentProduct', null);
        this.controller.set('deactivateReason', '');
      },
      cancelActivateProduct: function cancelActivateProduct() {
        this.controller.set('activateProductOpen', false);
        this.controller.set('currentProduct', null);
        this.controller.set('isActivated', false);
      },
      cancelSuspendExpedia: function cancelSuspendExpedia(product, status) {
        this.controller.set('suspendExpediaOpen', false);
        this.controller.set('currentProduct', product);
        this.controller.set('isSuspended', false);
      },
      cancelSuspendPaused: function cancelSuspendPaused(product, status) {
        this.controller.set('suspendPausedOpen', false);
        this.controller.set('currentProduct', product);
        this.controller.set('isSuspended', false);
      },
      goToActivityCreation: function goToActivityCreation() {
        this.transitionTo('createactivity');
      },
      sortProducts: function sortProducts(value) {
        var omnitureValue = 'page.LX-Supply.' + value.replace(/ /g, '');
        (0, _omniture.sendOmnitureDataOnClick)(omnitureValue);
        this.controller.set('compareBy', value);
        var reverse = false;
        if (value === 'Name (Z-A)') {
          reverse = true;
        }
        var products = this.sortByStatusAndAlphabetical(this.controller.get('filteredProducts'), reverse);
        this.controller.set('filteredProducts', products);
        this.loadImages();
      },
      willTransition: function willTransition() {
        //for tests
        delete window.pageName;
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'productList';
        return true;
      }
    }

  });
});