define('lxso/components/user-basic-info/component', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    featureFlags: Ember.inject.service(),
    isUserNameChangedByUser: false,
    optionsList: [7, 3],

    validateUser: function validateUser(isCreate) {
      var attributesA = ['firstName', 'lastName', 'email'];
      if (isCreate && !this.get('featureFlags').checkEnabled('EPCLoginExternalUser')) {
        attributesA = attributesA.concat(['password', 'confirmPassword']);
      }

      return (0, _formValidator.validate)({
        model: this.get('user'),
        attributes: attributesA
      });
    },
    keyDown: function keyDown() {
      this.set('isBasicDirty', true);
      this.set('validationUserError', undefined);
    },
    getGeneratedUserName: function getGeneratedUserName(firstName, lastName) {
      return '' + firstName.charAt(0).toLowerCase() + lastName.toLowerCase().split(' ').join('');
    },


    actions: {
      saveUserBasicInfo: function saveUserBasicInfo() {
        var _this = this;

        this.set('user.login', this.get('user.email'));
        this.set('validationUserError', undefined);
        this.validateUser(this.get('isNew')).then(function () {
          _this.sendAction('saveUserBasicInfo', 'basic');
        }).catch(function (validationErr) {
          _this.set('validationUserError', validationErr);
        });
      },
      userNameChangesByUser: function userNameChangesByUser() {
        this.set('isUserNameChangedByUser', true);
      },
      cancelChanges: function cancelChanges() {
        this.sendAction('cancelChanges', 'basic');
      },
      generateUserName: function generateUserName() {
        if (this.get('user.firstName.length') && this.get('user.lastName.length') && !this.get('isUserNameChangedByUser') && this.get('isNew')) {
          var uName = this.getGeneratedUserName(this.get('user.firstName'), this.get('user.lastName'));
          this.set('user.login', uName);
        }
      },
      changePassword: function changePassword() {
        this.sendAction('changePassword');
      },
      setUserRole: function setUserRole(roleId) {
        this.set('isBasicDirty', true);
        this.sendAction('updateUserRole', roleId);
      }
    }
  });
});