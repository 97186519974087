define('lxso/constants/status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var STATUS = {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
  };

  exports.default = STATUS;
});