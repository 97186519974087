define('lxso/components/offer-group/multi-offer-commentary/component', ['exports', 'lxso/constants/language-type-ids'], function (exports, _languageTypeIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.setLangTypeForGroundTransfer();
      this.setSelectedLanguageDisplay();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.setSelectedLanguageDisplay();
    },
    setLangTypeForGroundTransfer: function setLangTypeForGroundTransfer() {
      if (this.get('activity.isViewOnlyForGroundTransfer')) {
        this.set('languageInfo.languageIDs', []);
        this.set('languageInfo.languageTypeId', 3);
      }
    },
    setSelectedLanguageDisplay: function setSelectedLanguageDisplay() {
      var languageIDsArray = this.get('languageInfo.languageIDs');
      languageIDsArray = languageIDsArray ? languageIDsArray : Ember.A([]);
      var newLanguageArray = new Ember.A(languageIDsArray.slice());
      var languageStringArray = this.generateLanguageStringArray(newLanguageArray);
      this.set('selectedLanguageDisplay', languageStringArray.join(', '));
    },


    actions: {
      selectMultipleLanguages: function selectMultipleLanguages(languageID) {
        this._setMultipleLanguages(languageID);
        this.sendAction('validateIfInvalid');
      },
      languageTypeIdChange: function languageTypeIdChange(languageTypeId) {
        var oldLanguageTypeId = this.get('languageInfo.languageTypeId');
        var languageTypeIdInt = parseInt(languageTypeId);
        if (languageTypeIdInt === 3) {
          this.set('languageInfo.languageIDs', []);
          this.setSelectedLanguageDisplay();
        }
        this.updateOfferDetailsOnLanguageTypeIdChange(languageTypeIdInt, oldLanguageTypeId);
        this.set('languageInfo.languageTypeId', languageTypeIdInt);
      }
    },

    _setMultipleLanguages: function _setMultipleLanguages(languageID) {
      var languageIDsArray = this.get('languageInfo.languageIDs');
      languageIDsArray = languageIDsArray ? languageIDsArray : Ember.A([]);
      var newLanguageArray = new Ember.A(languageIDsArray.slice());

      if (languageID) {
        if (newLanguageArray.includes(languageID)) {
          var index = newLanguageArray.indexOf(languageID);
          newLanguageArray.splice(index, 1);
          this.updateOfferDetailsOnLanguageIdChangeToInactive(languageID, this.get('languageInfo.languageTypeId'));
        } else {
          newLanguageArray.pushObject(languageID);
        }
      }

      var languageStringArray = this.generateLanguageStringArray(newLanguageArray);
      this.set('languageInfo.languageIDs', newLanguageArray);
      this.set('selectedLanguages', newLanguageArray);
      this.set('selectedLanguageDisplay', languageStringArray.join(', '));
    },
    generateLanguageStringArray: function generateLanguageStringArray(languageIDArray) {
      var languageStringArray = [];
      var languages = this.get('languageLookupHash');

      for (var i = 0; i < languageIDArray.length; i++) {
        languageStringArray.push(languages[languageIDArray[i]]);
      }

      return languageStringArray;
    },
    updateOfferDetailsOnLanguageIdChangeToInactive: function updateOfferDetailsOnLanguageIdChangeToInactive(langId, languageTypeId) {
      var status = 'inactive';
      var offerDetails = this.get('scheduleAndLanguageInfo.offerDetails');
      var updatedOfferDetails = void 0;
      var offerIdsToRemove = [];
      switch (languageTypeId) {
        case _languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY:
          {
            //change status of each offer detail with given lang ID
            updatedOfferDetails = offerDetails.map(function (offerDetail) {
              if (offerDetail.languageInfo.languageIDs.includes(langId)) {
                offerIdsToRemove.push(offerDetail.offerId);
                return Object.assign(offerDetail, { status: status });
              } else {
                return offerDetail;
              }
            }).filter(function (offerDetail) {
              return offerDetail.status === 'active' || offerDetail.offerId;
            });
            this.set('scheduleAndLanguageInfo.offerDetails', updatedOfferDetails);
            break;
          }

        case _languageTypeIds.LANG_TYPE_IDS.AUDIO_GUIDE:
          {
            //Remove the langId from each offerDetail
            updatedOfferDetails = offerDetails.map(function (offerDetail) {
              var langIds = offerDetail.languageInfo.languageIDs;
              var updatedLangIds = langIds.filter(function (iLangId) {
                return iLangId !== langId;
              });
              return Object.assign(offerDetail, {
                languageInfo: {
                  languageTypeId: languageTypeId,
                  languageIDs: updatedLangIds
                }
              });
            });
            break;
          }

        default:
          break;
      }
      this.removeInactiveOffersFromSchedules(offerIdsToRemove);
      this.set('scheduleAndLanguageInfo.offerDetails', updatedOfferDetails);
    },
    updateOfferDetailsOnLanguageTypeIdChange: function updateOfferDetailsOnLanguageTypeIdChange(languageTypeId, oldLanguageTypeId) {
      var offerDetails = this.get('scheduleAndLanguageInfo.offerDetails');
      var inactiveOfferDetails = offerDetails.filter(function (offerDetail) {
        return offerDetail.status === 'inactive';
      });
      var activeOfferDetails = offerDetails.filter(function (offerDetail) {
        return offerDetail.status !== 'inactive';
      });
      var langTypeMappingToMultipleOffer = [_languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY];
      var langTypeMappingToSingleOffer = [_languageTypeIds.LANG_TYPE_IDS.NO_COMMENTARY, _languageTypeIds.LANG_TYPE_IDS.AUDIO_GUIDE];
      var updatedOfferDetails = [].concat(_toConsumableArray(inactiveOfferDetails));

      //Deactivate old offers on langTypeId change from LIVE_COMMENTARY to NO_COMMENTARY/AUDIO_GUIDE and vice versa
      if (langTypeMappingToSingleOffer.includes(languageTypeId) && langTypeMappingToMultipleOffer.includes(oldLanguageTypeId) || langTypeMappingToMultipleOffer.includes(languageTypeId) && langTypeMappingToSingleOffer.includes(oldLanguageTypeId)) {
        var offerDetailsToDeactivate = this.getOffersToDeactivate(activeOfferDetails);
        var offerIds = offerDetailsToDeactivate.map(function (offerDetail) {
          return offerDetail.offerId;
        });
        this.removeInactiveOffersFromSchedules(offerIds);
        updatedOfferDetails.push.apply(updatedOfferDetails, _toConsumableArray(offerDetailsToDeactivate));
      }

      this.set('scheduleAndLanguageInfo.offerDetails', updatedOfferDetails);
    },
    getOffersToDeactivate: function getOffersToDeactivate(offerDetails) {
      return offerDetails.map(function (offerDetail) {
        return Object.assign({}, offerDetail, {
          status: 'inactive',
          languageInfo: {
            languageTypeId: offerDetail.languageInfo.languageTypeId,
            languageIDs: offerDetail.languageInfo.languageIDs
          }
        });
      }).filter(function (offerDetail) {
        return offerDetail.offerId;
      });
    },
    removeInactiveOffersFromSchedules: function removeInactiveOffersFromSchedules(offerIdsToRemove) {
      this.get('scheduleAndLanguageInfo.schedules').forEach(function (schedule) {
        var activeOfferIds = schedule.get('offerIds').filter(function (offerId) {
          return !offerIdsToRemove.includes(offerId);
        });
        schedule.set('offerIds', activeOfferIds);
      });
    }
  });
});