define('lxso/adapters/option', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // findRecord is unavailable
    // Use queryRecord below to get an option by option_id and activity_id

    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.host + '/' + this.namespace + '/activities/' + snapshot.record.get('activity.id') + '/option';

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      return this.ajax(url, 'POST', { data: data });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var activityId = snapshot.record.get('activity.id');
      var url = this.host + '/' + this.namespace + '/activities/' + activityId + '/option/' + snapshot.record.get('id');

      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'PUT', { data: data });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var activityId = snapshot.record.get('activity.id');
      var url = this.host + '/' + this.namespace + '/activities/' + activityId + '/option/' + snapshot.record.get('id');
      return this.ajax(url, 'DELETE');
    },


    /**
     * Run query function to find a single option
     *
     * Usage in route:
     * ```
     * this.store.queryRecord('option', {
     *   id: 1,
     *   activity_id: 1
     * })
     * .then((option) => {
     *   console.log(option);
     * });
     * ```
     *
     * @return {object}            Option record object
     */
    queryRecord: function queryRecord(store, type, query) {
      var url = this.host + '/' + this.namespace + '/activities/' + query.activity_id + '/option/' + query.id;
      return this.ajax(url, 'GET');
    }
  });
});