define("lxso/constants/common", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var TIME_DELAY_MS_FOR_FETCH_AFTER_UPDATE = 1500;
  var MARGIN_DECIMAL_PLACES = 3;
  //This delay is used to overcome issues due to read DB replica lag

  exports.TIME_DELAY_MS_FOR_FETCH_AFTER_UPDATE = TIME_DELAY_MS_FOR_FETCH_AFTER_UPDATE;
  exports.MARGIN_DECIMAL_PLACES = MARGIN_DECIMAL_PLACES;
});