define('lxso/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'lxso/utils/add-json-api-errors-to-payload', 'lxso/config/environment', 'lxso/utils/omniture'], function (exports, _unauthenticatedRouteMixin, _addJsonApiErrorsToPayload, _environment, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    featureFlags: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    responseErrors: Ember.inject.service(),
    omniture: {
      pageName: 'page.LX-Supply.Login'
    },
    queryParams: {
      redirect: { refreshModel: true }
    },

    model: function model(params, transition) {
      if (params.redirect == 'oidc') {
        this.redirectToEPCLogin();
        transition.abort();
      }
    },
    setupController: function setupController() {
      var _this = this;

      this.controller.set('useLoginForm', !_environment.default.useSSO);
      this.controller.set('errors', undefined);
      this.controller.set('isEPCAuthEnabled', this.get('featureFlags').checkEnabled('EPCMigration'));
      this.controller.set('credentials', {
        username: null,
        password: null
      });
      this.controller.setProperties({
        showPasswordSection: false,
        isUsernameInvalid: false,
        isProcessingUsername: false,
        isUserDeprecated: false,
        hideServerErrors: false
      });
      if (_environment.default.useSSO) {
        this.controllerFor('application').set('useSSO', true);
        this.controller.set('saving', true);
        this.get('session').authenticate('authenticator:sso').catch(function (reason) {
          _this.controller.set('somethingWentWrong', true);
          if (reason.responseText) {
            _this.controller.set('errors', (0, _addJsonApiErrorsToPayload.default)(Ember.$.parseJSON(reason.responseText), reason.status));
          }
          Ember.Logger.error('Something went wrong: ', reason);
        }).finally(function () {
          _this.controller.set('saving', false);
        });
      }
      this.controller.set('autoSessionLogout', sessionStorage.getItem('autoSessionLogout'));
      sessionStorage.removeItem('autoSessionLogout');
    },


    actions: {
      willTransition: function willTransition() {
        //for tests
        delete window.pageName;
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'login';
      },
      login: function login() {
        var _this2 = this;

        //for handling enter press in username page
        if (this.get('featureFlags').checkEnabled('EPCLoginExternalUser') && !this.controller.get('showPasswordSection')) {
          this.send('handleUsernameContinue');
          return;
        }

        var route = this;
        var controller = route.controller;
        var roleId = '';
        controller.set('saving', true);

        route.get('session').authenticate('authenticator:lxpc', controller.get('credentials')).then(function () {
          return _this2.store.queryRecord('account-settings', {});
        }).then(function (userInfo) {
          roleId = userInfo.get('role.roleId');
          // Send completion data to Omniture
          (0, _omniture.default)({
            events: 'event7', //event7 tracks successful login
            prop1: roleId
          });
        }).catch(function (reason) {
          if (reason.responseText) {
            controller.set('errors', (0, _addJsonApiErrorsToPayload.default)(Ember.$.parseJSON(reason.responseText), reason.status));
          }
          Ember.Logger.error('Something went wrong: ', reason);
        }).finally(function () {
          controller.set('saving', false);
        });
      },
      redirectToEpcLoginIfInternalUsername: function redirectToEpcLoginIfInternalUsername() {
        var username = this.controller.get('credentials.username');
        var isInternalUsername = this.isInternalUser(username);
        this.controller.set('isEPCInternalUser', this.controller.get('isEPCAuthEnabled') && isInternalUsername);
        if (this.controller.get('isEPCInternalUser')) {
          this.redirectToEPCLogin('expedia.com');
          //expedia.com passed as username to support current login flow until EPC External user migration is turned ON
        }
      },
      handleUsernameContinue: function handleUsernameContinue() {
        var _this3 = this;

        this.controller.set('errors', null);
        this.get('responseErrors').clearErrors();
        var username = this.controller.get('credentials.username');
        if (!username) {
          this.controller.set('isUsernameInvalid', true);
          return;
        }

        if (this.isInternalUser(username)) {
          this.redirectToEPCLogin(username);
          this.transitionTo('loading');
          return;
        }

        this.controller.set('isProcessingUsername', true);
        var userStatusUrl = _environment.default.api.host + '/api/v3/users/isUserOnEPC';
        var data = {
          userLogin: this.controller.get('credentials.username')
        };
        this.get('ajaxWrapper').ajaxPut(userStatusUrl, data).then(function (response) {
          if (response.isOnEPC && !response.isDeprecatedLogin) {
            _this3.redirectToEPCLogin(username);
            _this3.transitionTo('loading');
          } else if (response.isOnEPC && response.isDeprecatedLogin) {
            _this3.controller.set('isUserDeprecated', true);
          } else {
            _this3.controller.set('showPasswordSection', true);
          }
          _this3.controller.set('hideServerErrors', false);
        }).catch(function (reason) {
          if (reason.responseText) {
            var errors = (0, _addJsonApiErrorsToPayload.default)(Ember.$.parseJSON(reason.responseText), reason.status);
            _this3.controller.set('errors', errors);
            var hideServerErrors = errors.details && errors.details.filter(function (err) {
              return err.name == 'text';
            }).length > 0;
            _this3.controller.set('hideServerErrors', hideServerErrors);
          } else {
            _this3.controller.set('errors', reason);
          }
        }).finally(function () {
          _this3.controller.set('isProcessingUsername', false);
        });
      }
    },

    redirectToEPCLogin: function redirectToEPCLogin(username) {
      var LXPCRedirectPage = encodeURIComponent('' + window.location.origin + window.location.pathname + _environment.default.epc.LXPCLandingPage);
      var redirectUrl = void 0;
      if (username) {
        redirectUrl = '' + _environment.default.epc.baseUrl + _environment.default.epc.authUrl + username + _environment.default.epc.redirectUrl + LXPCRedirectPage;
      } else {
        redirectUrl = '' + _environment.default.epc.baseUrl + _environment.default.epc.authUrlExternalUser + LXPCRedirectPage;
      }
      window.location.replace(redirectUrl);
    },
    isInternalUser: function isInternalUser(username) {
      var supportedDomains = ['expedia.com', 'expediagroup.com'];
      return supportedDomains.any(function (domain) {
        return username.includes(domain);
      });
    }
  });
});