define('lxso/serializers/ticket-types-v4', ['exports', 'ember-data', 'lxso/constants/base-ticket-types', 'lxso/utils/capitalize-first-letter'], function (exports, _emberData, _baseTicketTypes, _capitalizeFirstLetter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    //this specifies the correct serializer
    primaryKey: 'activityId',

    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      var formattedStandardTicketTypes = json.ticketTypes.filter(function (ticketType) {
        return ticketType.isSelected;
      }).map(function (ticketType) {
        var name = '' + (0, _capitalizeFirstLetter.default)(ticketType.baseLabel);
        name += ' ( ' + ticketType.minimumAge + ' - ' + ticketType.maximumAge + ' Years )';
        return {
          baseType: ticketType.baseType,
          baseLabel: ticketType.baseLabel,
          name: name,
          restriction: {
            minimumAge: ticketType.minimumAge,
            maximumAge: ticketType.maximumAge,
            independentlyBookable: ticketType.independentlyBookable,
            minimumTicketsPerBooking: ticketType.minimumTicketsPerBooking,
            maximumTicketsPerBooking: ticketType.maximumTicketsPerBooking
          }
        };
      });

      var formattedCapacityTicketTypes = json.capacityTicketTypes.filter(function (ticketType) {
        return ticketType.isSelected;
      }).map(function (ticketType) {
        var name = (0, _capitalizeFirstLetter.default)(ticketType.baseType);
        name += ' ( ' + ticketType.minPerGroup + ' - ' + ticketType.maxPerGroup + ' Travelers )';
        return {
          baseType: ticketType.baseType,
          name: name,
          groups: [{
            minPerGroup: ticketType.minPerGroup,
            maxPerGroup: ticketType.maxPerGroup
          }]
        };
      });

      delete json.capacityTicketTypes;
      json.ticketTypes = [].concat(_toConsumableArray(formattedStandardTicketTypes), _toConsumableArray(formattedCapacityTicketTypes));
      return json;
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      payload.capacityTicketTypes = this.getNormalizedCapacityTicketTypes(payload);
      payload.ticketTypes = this.getNormalizedStandardTicketTypes(payload);
      return this._super.apply(this, arguments);
    },
    getNormalizedStandardTicketTypes: function getNormalizedStandardTicketTypes(payload) {
      return _baseTicketTypes.BASE_TICKET_TYPES_LIST.map(function (ticketLabel) {
        var existingTicketType = payload.ticketTypes.findBy('baseLabel', ticketLabel);
        if (existingTicketType) {
          return {
            ticketTypeId: existingTicketType.ticketTypeId,
            baseLabel: ticketLabel,
            baseType: existingTicketType.baseType,
            name: existingTicketType.name,
            isSelected: true,
            minimumAge: existingTicketType.restriction ? existingTicketType.restriction.minimumAge : null,
            maximumAge: existingTicketType.restriction ? existingTicketType.restriction.maximumAge : null,
            minimumTicketsPerBooking: existingTicketType.restriction ? existingTicketType.restriction.minimumTicketsPerBooking : null,
            maximumTicketsPerBooking: existingTicketType.restriction ? existingTicketType.restriction.maximumTicketsPerBooking : null,
            independentlyBookable: existingTicketType.restriction ? existingTicketType.restriction.independentlyBookable : _baseTicketTypes.INDEPENDENTLY_BOOKABLE_TICKET_TYPES.includes(ticketLabel)
          };
        } else {
          return {
            ticketTypeId: null,
            baseLabel: ticketLabel,
            baseType: _baseTicketTypes.BASE_TYPES.STANDARD,
            name: '',
            isSelected: false,
            minimumAge: null,
            maximumAge: null,
            minimumTicketsPerBooking: _baseTicketTypes.MIN_TICKETS_PER_BOOKING_DEFAULT,
            maximumTicketsPerBooking: _baseTicketTypes.MAX_TICKETS_PER_BOOKING_DEFAULT,
            independentlyBookable: _baseTicketTypes.INDEPENDENTLY_BOOKABLE_TICKET_TYPES.includes(ticketLabel)
          };
        }
      });
    },
    getNormalizedCapacityTicketTypes: function getNormalizedCapacityTicketTypes(payload) {
      return payload.ticketTypes.filter(function (tt) {
        return tt.baseType === _baseTicketTypes.BASE_TYPES.CAPACITY;
      }).map(function (tt) {
        return {
          ticketTypeId: tt.ticketTypeId,
          baseType: tt.baseType,
          isSelected: true,
          minPerGroup: tt.groups[0].minPerGroup,
          maxPerGroup: tt.groups[0].maxPerGroup
        };
      });
    }
  });
});