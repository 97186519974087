define('lxso/components/product-list/component', ['exports', 'lxso/mixins/confirmation-modal'], function (exports, _confirmationModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_confirmationModal.default, {
    i18n: Ember.inject.service(),
    dateFormat: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    permissions: Ember.inject.service('permissions'),
    rangeDateSelected: Ember.computed.gt('selectedDays.length', 1),
    isSuperUser: Ember.computed('permissions', function () {
      return this.get('permissions').checkEnabled('V3InternalSettings');
    }),
    stopSellForConnectedFlag: Ember.computed('featureFlags', function () {
      return this.get('featureFlags').checkEnabled('StopSellForSuperAdmin');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.rerender();
    },
    message: function message(isSelectedOn) {
      'use strict';

      var apiFormat = this.get('dateFormat').DATEPICKER_FORMAT;
      var displayFormat = this.get('dateFormat').LONG_USA_FORMAT;
      var from = moment(this.get('fromValue'), apiFormat).format(displayFormat);
      var to = moment(this.get('toValue'), apiFormat).format(displayFormat);
      var scope = this.get('selectedOption') ? 'Option' : 'Product';
      var switchValue = isSelectedOn ? 'on' : 'off';
      return this.get('i18n').t('components.productList.confirm', { switchValue: switchValue, scope: scope, from: from, to: to });
    },


    actions: {
      turnSelectedDaysOff: function turnSelectedDaysOff() {
        this.set('isTurnSelectedDatesOn', false);
        this.confirm(this.message(false));
      },
      turnSelectedDaysOn: function turnSelectedDaysOn() {
        this.set('isTurnSelectedDatesOn', true);
        this.confirm(this.message(true));
      },
      cancelled: function cancelled() {},
      confirmed: function confirmed() {
        'use strict';

        var isTurnSelectedDatesOn = this.get('isTurnSelectedDatesOn');
        this.sendAction('toggleSelectedDays', isTurnSelectedDatesOn);
      }
    }
  });
});