define('lxso/routes/manage-availability/limited-inventory', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      date: ''
    },
    model: function model(params, transition) {
      var query = {
        supplierId: transition.params['manage-availability'].supplierId,
        offerId: params.optionId,
        date: params.date
      };
      return this.store.query('stopSell', query);
    },
    setupController: function setupController(ctrl, model) {
      var originalInventory = [];
      var limitedInventory = model.filterBy('offerId', parseInt(this.currentModel.query.offerId));
      limitedInventory.forEach(function (offer) {
        originalInventory.push(offer.get('quantity'));
      });
      this.controller.set('originalInventory', originalInventory);
      this.controller.set('limitedInventory', limitedInventory);
    },
    validateFields: function validateFields() {
      var stopSell = this.controller.get('limitedInventory.firstObject');
      return (0, _formValidator.validate)({ model: stopSell, attributes: ['quantity'] });
    },


    actions: {
      close: function close() {
        // Reset quantity back to original value if it has been changed prior
        // to the window being closed.
        var originalInventory = this.controller.get('originalInventory');
        this.controller.get('limitedInventory').forEach(function (li, index) {
          if (parseInt(li.get('quantity')) !== originalInventory[index]) {
            li.set('quantity', originalInventory[index]);
          }
        });
        this.replaceWith('manage-availability');
      },
      save: function save() {
        var _this = this;

        this.validateFields().then(function () {
          var promises = [];
          var originalInventory = _this.controller.get('originalInventory');
          _this.controller.get('limitedInventory').forEach(function (li, index) {
            if (parseInt(li.get('quantity')) !== originalInventory[index]) {
              promises.push(li.save());
            }

            if (li.get('quantity') === '0') {
              promises.push(_this.controller.send('toggleStopSell', li, li.get('date'), false));
            }
          });
          Ember.RSVP.allSettled(promises).then(function (resultsArray) {
            return _this.replaceWith('manage-availability');
          });
        }).catch(function (error) {
          _this.controller.set('errors', error);
        });
      },
      willTransition: function willTransition() {
        //for tests
        delete window.pageName;
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'limitedInventoryDialog';
        this.controller.set('errors', '');
      }
    }
  });
});