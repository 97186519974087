define('lxso/components/duration-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isV4OptionGroup:duration-input-v4-option-group'],
    duration: Ember.computed('durationInMinutes', function () {
      var totalDays = Math.floor(+this.get('durationInMinutes') / 60 / 24);
      var days = isNaN(totalDays) || this.get('isNoDays') ? '' : totalDays;

      var durationHours = this.get('isNoDays') ? Math.floor(+this.get('durationInMinutes') / 60) : Math.floor(+this.get('durationInMinutes') / 60 % 24);
      var hours = isNaN(durationHours) ? '' : durationHours;

      var durationMinutes = +this.get('durationInMinutes') % 60;
      var minutes = isNaN(durationMinutes) ? '' : durationMinutes;

      return { days: days, hours: hours, minutes: minutes };
    }),
    change: function change() {
      this.set('durationInMinutes', +this.get('duration.hours') * 60 + +this.get('duration.minutes'));
    }
  });
});