define('lxso/helpers/addition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var addition = function addition(params) {
    return params[0] + params[1];
  };

  exports.default = Ember.Helper.helper(addition);
});