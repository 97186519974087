define('lxso/constants/price-types', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PRICE_TYPES = {
    INDIVIDUAL_OR_VOLUME: 'INDIVIDUAL_OR_VOLUME',
    CAPACITY: 'CAPACITY'
  };

  var PRICE_TYPES_LIST = [PRICE_TYPES.INDIVIDUAL_OR_VOLUME, PRICE_TYPES.CAPACITY];

  exports.PRICE_TYPES = PRICE_TYPES;
  exports.PRICE_TYPES_LIST = PRICE_TYPES_LIST;
});