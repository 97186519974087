define('lxso/services/ajax-wrapper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function jQueryAjaxCall(method, url, data, responseTransform, ajaxParams) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var params = {
        url: url,
        data: JSON.stringify(data),
        type: method,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        success: function success(response) {
          if (typeof responseTransform === 'function') {
            resolve(responseTransform(response));
          } else {
            resolve(response);
          }
        },
        error: function error(_error) {
          reject(_error);
        }
      };

      if ((typeof ajaxParams === 'undefined' ? 'undefined' : _typeof(ajaxParams)) === 'object') {
        params = Ember.$.extend(true, params, ajaxParams);
      }

      Ember.$.ajax(params);
    });
  }

  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    sessionValidator: Ember.inject.service('session-validator'),

    ajaxCall: function ajaxCall(method, url, data, responseTransform, ajaxParams) {
      var _this = this;

      return jQueryAjaxCall(method, url, data, responseTransform, ajaxParams).catch(function (error) {
        _this.get('sessionValidator').validate(error.status, function () {}, function () {
          return _this.get('session').invalidate();
        });

        return Ember.RSVP.reject(error);
      });
    },
    ajaxGet: function ajaxGet(url, responseTransform, ajaxParams) {
      return this.ajaxCall('get', url, undefined, responseTransform, ajaxParams);
    },
    ajaxPost: function ajaxPost(url, data, responseTransform, ajaxParams) {
      return this.ajaxCall('post', url, data, responseTransform, ajaxParams);
    },
    ajaxPut: function ajaxPut(url, data, responseTransform, ajaxParams) {
      return this.ajaxCall('put', url, data, responseTransform, ajaxParams);
    },
    ajaxDelete: function ajaxDelete(url, data, responseTransform, ajaxParams) {
      return this.ajaxCall('delete', url, data, responseTransform, ajaxParams);
    }
  });
});