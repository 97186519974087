define('lxso/mixins/authentication-listener', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service('session'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var whenLoggedIn = function whenLoggedIn() {
        return _this.whenLoggedIn && _this.whenLoggedIn();
      };
      var whenLoggedOut = function whenLoggedOut() {
        return _this.whenLoggedOut && _this.whenLoggedOut();
      };

      if (this.get('session.isAuthenticated')) {
        whenLoggedIn();
      } else {
        whenLoggedOut();
      }

      var session = this.get('session');
      session.on('authenticationSucceeded', whenLoggedIn);
      session.on('invalidationSucceeded', whenLoggedOut);
    }
  });
});