define('lxso/validators/date-must-be-before-or-same', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var dateBeforeOrSame = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var dateA = moment(options.dateA, 'MM/DD/YYYY').toDate();
      var dateB = moment(options.dateB, 'MM/DD/YYYY').toDate();

      if (dateA > dateB) {
        return model.get('i18n').t('errors.customMessages.offers.' + options.messageKey).string;
      } else {
        return true;
      }
    }
  });

  exports.default = dateBeforeOrSame;
});