define('lxso/validators/cancellation-cutoff-cannot-be-greater', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CancellationCutoffCannotBeGreater = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      'use strict';

      var isInternal = model.get('permissions').checkEnabled('V3InternalSettings');
      if (value > options.previousValue && options.previousValue !== 0 && !isInternal) {
        return model.get('i18n').t('' + options.messageKey).string;
      } else {
        return true;
      }
    }
  });

  exports.default = CancellationCutoffCannotBeGreater;
});