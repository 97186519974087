define('lxso/serializers/enums/feature', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    normalize: function normalize(typeClass, hash) {
      hash.isEnabled = hash.value === 'true';
      delete hash.value;

      hash.name = hash.display;
      delete hash.display;

      hash.id = hash.name;

      return this._super.apply(this, arguments);
    }
  });
});