define('lxso/adapters/stop-sell', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    dateFormat: Ember.inject.service(),

    query: function query(store, type, _query) {
      var datePickerFormat = this.get('dateFormat.DATEPICKER_FORMAT');
      var apiDateFormat = this.get('dateFormat.API_DATE_FORMAT');
      var toIsoString = function toIsoString(dateString) {
        return moment.utc(dateString, datePickerFormat).format(apiDateFormat);
      };

      var from = toIsoString(_query.date || _query.from);
      var to = _query.to ? toIsoString(_query.to) : from;

      var apiEndpoint = this.host + '/' + this.namespace + '/supplier_branches/' + _query.supplierId + '/availabilities';
      var dateParameters = '?travel_end_date=' + to + '&travel_start_date=' + from;

      var activityIdParameter = _query.activity ? '&activity_id=' + _query.activity : '';

      return this.ajax(apiEndpoint + dateParameters + activityIdParameter, 'GET');
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this = this;

      var offerId = snapshot.record.get('offerId');
      var date = snapshot.record.get('date');
      var quantity = snapshot.record.get('quantity');
      var data = {
        date: date,
        quantity: quantity
      };

      var baseUrl = this.host + '/' + this.namespace;
      var getUrl = baseUrl + '/limited_inventory/daily/' + offerId + '/byofferid';
      var url = baseUrl + '/limited_inventory/daily/' + offerId;
      return this.ajax(getUrl, 'GET').then(function (limitedInventories) {
        var apiDateFormat = _this.get('dateFormat.API_DATE_FORMAT');

        function hasTheSameDateAsTheRecord(limitedInventory) {
          return moment.utc(date).format(apiDateFormat) === moment.utc(limitedInventory.date).format(apiDateFormat);
        }

        var limitedInventory = limitedInventories.find(hasTheSameDateAsTheRecord);

        if (limitedInventory) {
          var putUrl = baseUrl + '/limited_inventory/daily/' + limitedInventory.id;
          return _this.ajax(putUrl, 'PUT', { data: data }).then(function (data) {
            data.id = snapshot.record.get('id');
            return data;
          });
        } else {
          return _this.ajax(url, 'POST', { data: data }).then(function (data) {
            data.id = snapshot.record.get('id');
            return data;
          });
        }
      });
    }
  });
});