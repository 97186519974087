define('lxso/routes/promotion-bulk-creation', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/promotion/promotion-creation-common', 'lxso/config/environment'], function (exports, _authenticatedRouteMixin, _featureFlag, _promotionCreationCommon, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _promotionCreationCommon.default, {
    permissions: Ember.inject.service(),
    responseErrors: Ember.inject.service(),
    fileSaver: Ember.inject.service(),

    model: function model(params) {
      var supplierBranchId = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
      var promotion = this.store.createRecord('promotion', {
        supplierBranchId: supplierBranchId,
        promotionDetails: this.store.createFragment('fragments/promotion/promotion-details', {
          activities: []
        })
      });

      return Ember.RSVP.hash({
        promotion: promotion
      });
    },
    setupController: function setupController(controller, model) {
      var isNew = model.promotion.get('isNew');
      var isInternalUser = this.get('permissions').checkEnabled('V3InternalSettings');
      controller.setProperties({
        isInternalUser: isInternalUser,
        promotion: model.promotion,
        isNew: isNew,
        anySectionOpen: Ember.computed.or('isPromoAudienceOpen', 'isPromoShoppingOpen', 'isPromoDateOpen', 'isPromoNameOpen', 'isPromoDetailsOpen'),
        promotionFile: ''
      });

      this.initPromotionAudience(controller, isNew);
      this.initPromotionShoppingPath(controller, isNew);
      this.initPromotionDate(controller, isNew);
      this.initPromotionDetailsBulkUpload(controller, isNew);
      this.initPromotionName(controller, isNew);
    },
    resetController: function resetController(controller, isExiting) {
      controller.get('promotion').rollbackAttributes();
    },
    getPromotionData: function getPromotionData() {
      var promotionData = JSON.parse(JSON.stringify(this.controller.get('promotion')));

      var promotionRequest = Object.assign({}, promotionData);
      promotionRequest.expediaFundType = promotionData.discountTypeUI;
      promotionRequest.supplierFundType = promotionData.discountTypeUI;
      delete promotionRequest.funders;
      delete promotionRequest.products;
      delete promotionRequest.bulkUploadFileName;
      delete promotionRequest.discountTypeUI;
      delete promotionRequest.normalizedAudiences;
      delete promotionRequest.normalizedShoppingPath;
      delete promotionRequest.promotionDetails;
      delete promotionRequest.supplierBranchId;

      return promotionRequest;
    },
    generateReportsBulkUpload: function generateReportsBulkUpload(failedMessage) {
      var csvData = 'Supplier Branch ID, Activity ID, Offer ID, Ticket Type, Message\r\n';

      if (Object.keys(failedMessage).length) {
        for (var key in failedMessage) {
          if (failedMessage.hasOwnProperty(key)) {
            var dataIds = key.split('#');
            csvData = csvData + ' ' + (dataIds[0] || '') + ', ' + (dataIds[1] || '') + ',' + (dataIds[2] || '') + ', ' + (dataIds[3] || '');
            csvData = csvData + ', ' + failedMessage[key].join('|').replace(',', ' & ') + '\r\n';
          }
        }
      }

      this.createCsvAndDownload(csvData);
    },
    createCsvAndDownload: function createCsvAndDownload(data) {
      var blob = new Blob([data], { type: 'text/csv' });
      var fileName = 'failed-promotion-reports.csv';
      this.get('fileSaver').saveAs(blob, fileName);
    },


    actions: {
      bulkPromotionSave: function bulkPromotionSave() {
        var _this = this;

        var formData = new FormData();
        var selectedFile = this.controller.get('promotionFile');
        var promotionData = this.getPromotionData();
        this.get('responseErrors').clearErrors();

        if (selectedFile) {
          formData.append('file', selectedFile);
          formData.append('promo', JSON.stringify(promotionData));

          this.controller.set('savingBulkPromotion', true);
          $.ajax({
            url: _environment.default.api.host + '/api/v3/promotion/upload/supplierIds',
            type: 'POST',
            data: formData,
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            },
            dataType: 'json',
            processData: false,
            contentType: false,
            headers: {
              Accept: 'application/json'
            }
          }).done(function (successResponse) {
            _this.controller.set('showBulkUploadResModal', true);
            _this.controller.set('bulkUploadSuccessResponse', successResponse);
            _this.controller.set('isNew', false);
          }).fail(function (errorResponse) {
            _this.get('responseErrors').addErrors(errorResponse.responseJSON, errorResponse.status);
          }).always(function () {
            _this.controller.set('savingBulkPromotion', false);
          });
        }
      },
      closeBulkUploadSuccessResModal: function closeBulkUploadSuccessResModal() {
        var bulkUploadRes = this.controller.get('bulkUploadSuccessResponse');
        if (bulkUploadRes.failed > 0) {
          this.generateReportsBulkUpload(bulkUploadRes.failMessage);
        }

        this.controller.set('showBulkUploadResModal', false);
        this.controller.set('bulkUploadSuccessResponse', undefined);
      }
    }
  });
});