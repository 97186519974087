define('lxso/models/supplier-branch/supplier-support/phone', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var Validations = (0, _validation.build)({
    phone_area_code: (0, _validation.validator)('number', {
      allowBlank: true,
      allowString: true
    }),
    phone_country_code: [(0, _validation.validator)('number', {
      gte: 1,
      integer: true,
      allowString: true,
      messageKey: 'errors.customMessages.phone.countryCode'
    })],
    phone: [(0, _validation.validator)('format', {
      regex: /[\d-]$/,
      messageKey: 'errors.customMessages.phone.phoneFormat'
    }), (0, _validation.validator)('length', {
      min: 5,
      max: 15,
      messageKey: 'errors.customMessages.phone.phoneLength'
    })],
    phone_ext: [(0, _validation.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _validation.validator)('length', {
      allowBlank: true
    })],
    phone_usage: (0, _validation.validator)('presence', true)
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    phone: _emberData.default.attr('string', { defaultValue: '' }),
    phone_country_code: _emberData.default.attr('string', { defaultValue: '' }),
    phone_area_code: _emberData.default.attr('string', { defaultValue: '' }),
    phone_ext: _emberData.default.attr('string', { defaultValue: '' }),
    phone_language: _emberData.default.attr('string', { defaultValue: '' }),
    phone_toll: _emberData.default.attr('string', { defaultValue: '' }),
    phone_usage: _emberData.default.attr('string', { defaultValue: '' }),

    fullPhoneNumber: Ember.computed('phone_country_code', 'phone_area_code', 'phone', {
      get: function get() {
        var countryCode = '';
        var areaCode = '';
        //in absence of a saved country code, the server will put in a 1 for you
        //to avoid a situation where a contact point without a phone number
        // displays +1 and nothing else,
        //this code won't show +1
        if (_typeof(this.get('phone_country_code') === 'string') && this.get('phone_country_code') && this.get('phone')) {
          countryCode = '+' + this.get('phone_country_code');
        }

        //if area code exists, format it in parens
        if (_typeof(this.get('phone_area_code') === 'string') && this.get('phone_area_code')) {
          areaCode = '(' + this.get('phone_area_code') + ')';
        }

        return countryCode + ' ' + areaCode + ' ' + this.get('phone');
      }
    })
  });
});