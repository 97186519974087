define('lxso/models/fragments/booking-questions/booking-question-offer-group-details-map', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({});

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    activityId: _emberData.default.attr('number'),
    activityTitle: _emberData.default.attr('string'),
    offerGroups: (0, _attributes.fragmentArray)('fragments/booking-questions/offer-group', { defaultValue: [] })
  });
});