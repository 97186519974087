define('lxso/models/timing-capacity', ['exports', 'ember-data', 'lxso/utils/validation'], function (exports, _emberData, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  //
  var variableCapacityValidator = (0, _validation.validator)('number', {
    integer: true,
    gt: -1,
    allowBlank: true,
    volatile: true,
    disabled: Ember.computed.not('model.isActuallyVariableCapacity'),
    messageKey: 'errors.customMessages.timingCapacity.capacitiesPositiveInteger'
  });

  var variableCapacityNonZeroValidator = (0, _validation.validator)(function (value, options, model) {
    var aCapacityGreaterThanZero = value.some(function (cap) {
      return cap > 0;
    });
    if (aCapacityGreaterThanZero) {
      return true;
    } else {
      return model.get('i18n').t('pages.timingCapacity.errors.at_least_one_greater_than_zero').string;
    }
  }, { disabled: Ember.computed.not('model.isActuallyVariableCapacity'), volatile: true });

  var Validations = (0, _validation.build)({
    capacity: [(0, _validation.validator)('number', {
      integer: true,
      gt: 0,
      dependantKeys: ['isActuallyVariableCapacity'],
      disabled: Ember.computed.readOnly('model.isActuallyVariableCapacity'),
      messageKey: 'errors.customMessages.timingCapacity.capacityPositiveInteger'
    })],
    monday: variableCapacityValidator,
    tuesday: variableCapacityValidator,
    wednesday: variableCapacityValidator,
    thursday: variableCapacityValidator,
    friday: variableCapacityValidator,
    saturday: variableCapacityValidator,
    sunday: variableCapacityValidator,
    variableCapacityList: variableCapacityNonZeroValidator,
    start_time: [(0, _validation.validator)('presence', true), (0, _validation.validator)('time-formt', {
      showSuggestions: true
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    i18n: Ember.inject.service('i18n'),
    // activity: DS.belongsTo('offer-price-availability', {async: true}),
    start_time: _emberData.default.attr('string'),
    end_time: _emberData.default.attr('string'),
    capacity: _emberData.default.attr('capacity'),
    monday: _emberData.default.attr('capacity'),
    tuesday: _emberData.default.attr('capacity'),
    wednesday: _emberData.default.attr('capacity'),
    thursday: _emberData.default.attr('capacity'),
    friday: _emberData.default.attr('capacity'),
    saturday: _emberData.default.attr('capacity'),
    sunday: _emberData.default.attr('capacity'),
    variableCapacityList: Ember.computed('monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', function () {
      var _this = this;

      var daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      return daysOfWeek.map(function (day) {
        return _this.get(day);
      });
    }),
    isActuallyVariableCapacity: _emberData.default.attr('boolean', { defaultValue: false }), //used only by app
    variableCapacity: _emberData.default.attr('boolean', { defaultValue: true }) //api expects this always to be true
  });
});