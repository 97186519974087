define('lxso/adapters/supplier-branch/margin-overrides', ['exports', 'lxso/adapters/application', 'lxso/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    featureFlags: Ember.inject.service(),
    urlForQuery: function urlForQuery(query, modelName) {
      var baseUrl = this.buildURL();
      var branchId = query.supplierBranchId;
      query.supplierBranchId = undefined;
      // const url = `${baseUrl}/activities/${activityId}/margin_overrides`;
      var url = _environment.default.api.host + '/api/v3/supplierBranch/' + branchId + '/activity_margin_overrides';
      return url;
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);
      if (this.get('featureFlags').checkEnabled('MultipleOptionsGroup') && data.offer_group_margins != undefined) {
        data.offer_group_margins.forEach(function (offerGroup) {
          return delete offerGroup.offers;
        });
      }

      var baseUrl = this.buildURL();
      var url = baseUrl + '/activities/' + snapshot._attributes.activity_id + '/margin_overrides';

      return this.ajax(url, 'PUT', { data: data });
    }
  });
});