define('lxso/components/supplier-platform-section/component', ['exports', 'lxso/utils/validation', 'lxso/config/environment'], function (exports, _validation, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isPlatformSettingsOpen: true,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!this.get('editMode')) {
        this.setPlatformDirty(false);
      }
    },
    setPlatformDirty: function setPlatformDirty(mode) {
      this.set('isPlatformDirty', mode);
    },


    actions: {
      openStep: function openStep(step) {
        var link = document.getElementsByClassName('title-main toggle-trigger ' + step);
        this.toggleProperty('isPlatformSettingsOpen');
        $(link).toggleClass('open');
      },
      savePlatformData: function savePlatformData() {
        var referencesData = {
          salesforce_code: this.get('supplierBranch.salesforce_code'),
          vendor_branch_id: parseInt(this.get('supplierBranch.vendor_branch_id')),
          connectivity_status: this.get('supplierBranch.connectivity_status'),
          partner_id: parseInt(this.get('partnerSupplierDetails.partner_id')),
          partner_supplier_branch_id: this.get('partnerSupplierDetails.partner_supplier_branch_id')
        };

        this.sendAction('saveExternalPlatform', referencesData);
      },
      editPlatformSettings: function editPlatformSettings() {
        this.sendAction('editPlatformSettings', true);
      },
      cancelPlatFormEdit: function cancelPlatFormEdit() {
        this.sendAction('cancelPlatformEdit');
      },
      selectPlatform: function selectPlatform(value) {
        this.setPlatformDirty(true);
        this.set('partnerSupplierDetails.partner_id', value);
      },
      connectivityChange: function connectivityChange() {
        this.setPlatformDirty(true);
        this.toggleProperty('supplierBranch.connectivity_status');
      },
      exportSupplierBranch: function exportSupplierBranch() {
        var supplierBranchId = this.get('supplierBranch.id');
        var link = _environment.default.api.host + '/api/v1/supplier_connectivity/' + supplierBranchId + '/export';
        window.location.replace(link);
      }
    }

  });
});