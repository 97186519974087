define('lxso/utils/change-history-details-display-modes/location', ['exports', 'lxso/utils/change-history-details-display-modes/list', 'lxso/utils/concatenate'], function (exports, _list, _concatenate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function toSingleLineAddress(address) {
    var stateAndPostalCode = (0, _concatenate.default)(' ', address.state, address.postalCode);
    var addressParts = [address.addressName, address.street, address.streetSecondLine, address.city, stateAndPostalCode, address.country];
    return _concatenate.default.apply(undefined, [', '].concat(addressParts));
  }

  exports.default = {
    partialName: 'list-with-label',
    diff: function diff(oldValue, newValue) {
      var oldAddresses = JSON.parse(oldValue);
      var newAddresses = JSON.parse(newValue);

      return {
        displayMode: _list.default,
        oldValue: oldAddresses.map(toSingleLineAddress),
        newValue: newAddresses.map(toSingleLineAddress)
      };
    }
  };
});