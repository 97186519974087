define('lxso/validators/offer-margin', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /*
   The base price must be a number.
   */

  var offerMargin = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      'use strict';

      return true;
    }
  });

  exports.default = offerMargin;
});