define('lxso/components/settings-menu/component', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      sendAction: function sendAction() {
        this.sendAction('action');
      },
      sendOmnitureDataForMenuItem: function sendOmnitureDataForMenuItem(value) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.' + value);
      },
      menuTransition: function menuTransition(params, queryParams) {
        this.sendAction('menuTransition', params, queryParams);
      }
    }
  });
});