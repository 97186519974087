define('lxso/models/change-history/product', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    productId: _emberData.default.attr('number'),
    title: _emberData.default.attr('string')
  });
});