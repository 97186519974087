define('lxso/helpers/format-currency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCurrency = formatCurrency;
  function formatCurrency(params /*, hash*/) {
    switch (params[1]) {
      case 'XPF':
      case 'JPY':
      case 'KRW':
      case 'VUV':
        return parseFloat(params[0]) + ' ' + params[1];
        break;
      default:
        return parseFloat(params[0]).toFixed(2) + ' ' + params[1];
    }
  }

  exports.default = Ember.Helper.helper(formatCurrency);
});