define('lxso/helpers/is-user-editable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isUserEditable = isUserEditable;
  function isUserEditable(user) {
    var userRoles = user[0] && user[0].get('roles') ? user[0].get('roles').split(', ') : [];
    if ((userRoles.indexOf('V3 Supply Manager') >= 0 || userRoles.indexOf('Report Reader') >= 0) && user[0].get('status').toUpperCase() !== 'INACTIVE' && userRoles.indexOf('Super Admin') < 0 && userRoles.indexOf('SuperSystemAdmin') < 0) {
      return true;
    }
  }

  exports.default = Ember.Helper.helper(isUserEditable);
});