define("lxso/utils/is-mobile", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isMobile;
  function isMobile(args) {
    // 725 is the media query break point for Small Tablets as per uitoolkit
    return window.innerWidth <= 725;
  }
});