define('lxso/components/promotions/promotion-modal/promotion-select-options/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    checkModalSaveEnable: function checkModalSaveEnable() {
      var enable = false;
      this.get('productList').forEach(function (product) {
        product.offers.forEach(function (offer) {
          if (offer.get('isOfferSelected')) {
            offer.ticketTypes.forEach(function (ticket) {
              if (ticket.get('isTicketSelected')) {
                enable = true;
              }
            });
          }
        });
      });
      this.sendAction('setOptionModalSave', enable);
    },

    actions: {
      openStep: function openStep(index) {
        var link = document.getElementsByClassName('toggle-trigger promotion-details-activity-' + index);
        $(link).toggleClass('open');

        var dataTable = document.getElementsByClassName('modal-activity-details promotion-details-view-' + index);
        $(dataTable).toggleClass('close');
      },
      onOptionSelect: function onOptionSelect(option) {
        option.toggleProperty('isOfferSelected');
        if (!option.get('isOfferSelected')) {
          option.ticketTypes.forEach(function (ticket) {
            ticket.set('isTicketSelected', false);
          });
        }
      },
      onTicketSelect: function onTicketSelect(ticket) {
        ticket.toggleProperty('isTicketSelected');
        this.checkModalSaveEnable();
      },
      selectAllModalItems: function selectAllModalItems(productList) {
        productList.filter(function (product) {
          return product.get('isProductSelected');
        }).forEach(function (product) {
          product.offers.forEach(function (offer) {
            offer.set('isOfferSelected', true);
            offer.ticketTypes.forEach(function (ticket) {
              ticket.set('isTicketSelected', true);
            });
          });
        });
        this.sendAction('setOptionModalSave', true);
      },
      deselectAllModalItems: function deselectAllModalItems(productList) {
        productList.filter(function (product) {
          return product.get('isProductSelected');
        }).forEach(function (product) {
          product.offers.forEach(function (offer) {
            offer.set('isOfferSelected', false);
            offer.ticketTypes.forEach(function (ticket) {
              ticket.set('isTicketSelected', false);
            });
          });
        });
        this.sendAction('setOptionModalSave', false);
      },
      selectAllProductItems: function selectAllProductItems(product) {
        product.offers.forEach(function (offer) {
          offer.set('isOfferSelected', true);
          offer.ticketTypes.forEach(function (ticket) {
            ticket.set('isTicketSelected', true);
          });
        });
        this.checkModalSaveEnable();
      },
      deselectAllProductItems: function deselectAllProductItems(product) {
        product.offers.forEach(function (offer) {
          offer.set('isOfferSelected', false);
          offer.ticketTypes.forEach(function (ticket) {
            ticket.set('isTicketSelected', false);
          });
        });
        this.checkModalSaveEnable();
      }
    }
  });
});