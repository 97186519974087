define('lxso/serializers/ticket-type', ['exports', 'ember-data', 'lxso/constants/base-ticket-types'], function (exports, _emberData, _baseTicketTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    normalizeFindHasManyResponse: function normalizeFindHasManyResponse(store, primaryModelClass, payload, id, requestType) {
      var v3TicketTypeModel = mapResponseToV3TicketTypeModel(payload);
      return this._super(store, primaryModelClass, v3TicketTypeModel, id, requestType);
    }
  });


  function mapResponseToV3TicketTypeModel(v4Response) {
    var GUEST_TYPE_ID_MAP = getGuestTypeIdMap();
    return v4Response.ticketTypes.filter(function (ticketType) {
      return ticketType.baseType === _baseTicketTypes.BASE_TYPES.STANDARD;
    }).map(function (ticketType) {
      return {
        id: ticketType.ticketTypeId,
        guest_type_id: GUEST_TYPE_ID_MAP[ticketType.baseLabel],
        ticket_type_name: ticketType.name,
        restrictions: [{
          restriction_type_id: 1,
          min: ticketType.restriction.minimumAge || 0,
          max: ticketType.restriction.maximumAge || 100,
          is_primary: true
        }]
      };
    });
  }

  function getGuestTypeIdMap() {
    var GUEST_TYPE_ID_MAP = {};
    GUEST_TYPE_ID_MAP[_baseTicketTypes.BASE_TICKET_TYPES.INFANT] = 3;
    GUEST_TYPE_ID_MAP[_baseTicketTypes.BASE_TICKET_TYPES.CHILD] = 4;
    GUEST_TYPE_ID_MAP[_baseTicketTypes.BASE_TICKET_TYPES.YOUTH] = 5;
    GUEST_TYPE_ID_MAP[_baseTicketTypes.BASE_TICKET_TYPES.ADULT] = 2;
    GUEST_TYPE_ID_MAP[_baseTicketTypes.BASE_TICKET_TYPES.SENIOR] = 6;
    GUEST_TYPE_ID_MAP[_baseTicketTypes.BASE_TICKET_TYPES.STUDENT] = 10;
    GUEST_TYPE_ID_MAP[_baseTicketTypes.BASE_TICKET_TYPES.TRAVELER] = 1;
    return GUEST_TYPE_ID_MAP;
  }
});