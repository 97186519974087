define('lxso/components/promotions/promotion-modal/promotion-details-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      openStep: function openStep(index) {
        var link = document.getElementsByClassName('toggle-trigger promotion-details-activity-' + index);
        $(link).toggleClass('open');

        var dataTable = document.getElementsByClassName('modal-activity-details promotion-details-view-' + index);
        $(dataTable).toggleClass('close');
      }
    }
  });
});