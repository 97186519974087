define('lxso/models/campaign', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    header: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    groupIndex: _emberData.default.attr('number'),
    categoryType: _emberData.default.attr('string'),
    duration: _emberData.default.attr('string'),
    campaignList: (0, _attributes.fragmentArray)('fragments/campaign/campaign-details')
  });
});