define('lxso/helpers/array-contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arrayContains = arrayContains;
  function arrayContains(params /*, hash*/) {
    if (!params[0] || !params[1]) {
      return false;
    } else {
      return params[0].indexOf(params[1]) !== -1;
    }
  }

  exports.default = Ember.Helper.helper(arrayContains);
});