define('lxso/services/user-migration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    featureFlags: Ember.inject.service(),

    isNeeded: function isNeeded() {
      var isAuthenticated = this.get('session.isAuthenticated');
      var isMigrated = this.get('session.data.authenticated.is_migrated');

      return this.get('featureFlags').checkEnabled('KeyCloakEnabledInChangePassword') && isAuthenticated && !isMigrated;
    },
    isNewUser: function isNewUser() {
      var isAuthenticated = this.get('session.isAuthenticated');
      var isPasswordChangeRequired = this.get('session.data.authenticated.isPasswordChangeRequired');

      return isAuthenticated && isPasswordChangeRequired;
    }
  });
});