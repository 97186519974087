define('lxso/components/tour-language-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    languageEnum: Ember.inject.service('language-enum'),

    tourLanguageOptions: [],
    tourLanguageValues: [],

    init: function init() {
      'use strict';

      var _this = this;

      this._super.apply(this, arguments);
      this.get('languageEnum').getLanguages().then(function (results) {
        var tourLanguageOptions = [];
        var tourLanguageValues = [];
        results.forEach(function (result) {
          tourLanguageOptions.push(result.label);
          tourLanguageValues.push(result.id);
        });
        _this.set('tourLanguageOptions', tourLanguageOptions);
        _this.set('tourLanguageValues', tourLanguageValues);
      });
    },


    actions: {
      onChange: function onChange() {
        this.get('index') ? this.sendAction.apply(this, ['on-change', this.get('index')].concat(Array.prototype.slice.call(arguments))) : this.sendAction.apply(this, ['on-change'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});