define('lxso/validators/unique-in-array', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UniqueInArray = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var array = model.get(options.array);
      return array.uniqBy(options.field).length === array.get('length') ? true : model.get('i18n').t('errors.customMessages.offers.uniqueTicketTypes').string;
    }
  });

  UniqueInArray.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return ['model.availability.[]'];
    }
  });

  exports.default = UniqueInArray;
});