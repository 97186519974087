define('lxso/adapters/user', ['exports', 'lxso/adapters/application', 'lxso/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    updateQueryStringParameter: function updateQueryStringParameter(uri, key, value) {
      var separator = uri.indexOf('?') !== -1 ? '&' : '?';
      return '' + separator + key + '=' + value;
    },
    query: function query(store, type, _query) {
      var searchStrings = _query.searchStrings;
      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;

      var userSearchUrl = domain + '/userManagement/search';
      if (searchStrings.firstName) {
        userSearchUrl += this.updateQueryStringParameter(userSearchUrl, 'firstName', searchStrings.firstName);
      }

      if (searchStrings.lastName) {
        userSearchUrl += this.updateQueryStringParameter(userSearchUrl, 'lastName', searchStrings.lastName);
      }

      if (searchStrings.email) {
        userSearchUrl += this.updateQueryStringParameter(userSearchUrl, 'email', searchStrings.email);
      }

      if (searchStrings.supplier) {
        userSearchUrl += this.updateQueryStringParameter(userSearchUrl, 'supplierId', searchStrings.supplier);
      }

      if (searchStrings.supplierBranch) {
        userSearchUrl += this.updateQueryStringParameter(userSearchUrl, 'supplierBranchId', searchStrings.supplierBranch);
      }

      var ajaxWrapper = this.get('ajaxWrapper');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        ajaxWrapper.ajaxGet(userSearchUrl, null, {
          xhrFields: {
            withCredentials: true
          }
        }).then(function (data) {
          resolve(data.items);
        }, function (err) {
          reject(err);
        });
      });
    },
    findRecord: function findRecord(store, type, id) {
      var url = this.host + '/' + this.namespace + '/userManagement/' + id;
      return this.ajax(url, 'GET');
    },
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = _environment.default.api.host + '/api/v3/users';
      var action = snapshot.adapterOptions && snapshot.adapterOptions.action ? snapshot.adapterOptions.action : false;

      serializer.serializeIntoHash(data, type, snapshot);

      if (action === 'saveUser') {
        delete data.attributes;
        delete data.accessibleSuppliersWithBranches;
        delete data.friendlyName;
        delete data.confirmPassword;
        delete data.isUserEditable;
        delete data.isUserInactive;
        delete data.status;
        delete data.roles;
      }

      return this.ajax(url, 'POST', { data: data });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var userId = snapshot.id;
      var action = snapshot.adapterOptions && snapshot.adapterOptions.action ? snapshot.adapterOptions.action : false;
      serializer.serializeIntoHash(data, type, snapshot);

      var url = _environment.default.api.host + '/api/v3/users/' + userId + '/basic';

      delete data.attributes;
      delete data.accessibleSuppliersWithBranches;
      delete data.isUserEditable;
      delete data.isUserInactive;

      if (action === 'saveUser') {
        if (!data.role) {
          data.role = 7;
        }

        delete data.friendlyName;
        delete data.confirmPassword;
        delete data.status;
        delete data.login;
      } else if (action === 'managePassword') {
        if (snapshot.adapterOptions && snapshot.adapterOptions.section === 'basic') {
          url = _environment.default.api.host + '/api/v3/users/' + userId + '/password';

          delete data.accessibleSuppliersWithBranches;
          delete data.email;
          delete data.firstName;
          delete data.lastName;
          delete data.role;
          delete data.roles;
        }
      } else if (action === 'statusChange') {
        url = _environment.default.api.host + '/api/v3/users/' + userId + '/status';
        delete data.friendlyName;
        delete data.confirmPassword;
        delete data.login;
      }

      return this.ajax(url, 'PUT', { data: data });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var userId = snapshot.id;
      var url = this.host + '/api/v3/users/' + userId;
      return this.ajax(url, 'DELETE');
    }
  });
});