define('lxso/models/fragments/option-group/ticket-type-restriction', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({});

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    ticketTypeId: _emberData.default.attr('number'),
    label: _emberData.default.attr('string'),
    minimumTicketsPerBooking: _emberData.default.attr('number'),
    maximumTicketsPerBooking: _emberData.default.attr('number'),
    isSelected: _emberData.default.attr('boolean')
  });
});