define('lxso/models/hotel-zone', ['exports', 'ember-data', 'lxso/utils/validation', 'ember-api-actions', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _validation, _emberApiActions, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    zoneName: {
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('length', {
        min: 4,
        max: 75,
        messageKey: 'errors.customMessages.basic.tooShort'
      }), (0, _validation.validator)('input-regex-validation', {
        messageKey: 'errors.customMessages.hotelZone.invalidCharacters'
      })]
    },

    zoneDesc: {
      validators: [(0, _validation.validator)('length', {
        max: 75,
        messageKey: 'errors.customMessages.hotelZone.tooLong'
      }), (0, _validation.validator)('input-regex-validation', {
        messageKey: 'errors.customMessages.hotelZone.invalidCharacters'
      })]
    },

    postalCodes: {
      validators: [(0, _validation.validator)('valid-postal-codes', {
        array: 'postalCodes',
        volatile: true
      }), (0, _validation.validator)('find-duplicate-postal-codes', {
        array: 'postalCodes',
        volatile: true
      })]
    }
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    supplierBranch: _emberData.default.belongsTo('supplier-branch'),
    zoneName: _emberData.default.attr('string'),
    zoneDesc: _emberData.default.attr('string', { defaultValue: '' }),
    createDate: _emberData.default.attr('string'),
    updateDate: _emberData.default.attr('string'),
    lastUpdatedBy: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    supplierBranchId: _emberData.default.attr('number'),
    hotels: (0, _attributes.fragmentArray)('fragments/hotel-zone/hotel'),
    postalCodes: _emberData.default.attr('array'),
    zoneAssociations: _emberData.default.hasMany('zone-association', { async: true })
  });
});