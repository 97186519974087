define('lxso/adapters/product-margin', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      return this.host + '/api/v3/offer/' + snapshot.adapterOptions.recordId + '/margins';
    }
  });
});