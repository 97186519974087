define('lxso/models/fragments/ticket-types-v4/ticket-type', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    maximumAge: [(0, _validation.validator)('number', {
      lte: 100,
      allowString: true
    })]
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    ticketTypeId: _emberData.default.attr('number'),
    baseType: _emberData.default.attr('string'),
    baseLabel: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    minimumAge: _emberData.default.attr('number'),
    maximumAge: _emberData.default.attr('number'),
    minimumTicketsPerBooking: _emberData.default.attr('number'),
    maximumTicketsPerBooking: _emberData.default.attr('number'),
    independentlyBookable: _emberData.default.attr('boolean'),
    isSelected: _emberData.default.attr('boolean')
  });
});