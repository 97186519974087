define('lxso/components/textarea-with-counter/component', ['exports', 'lodash/string', 'lxso/mixins/component-validator', 'lxso/mixins/example-modal'], function (exports, _string2, _componentValidator, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_exampleModal.default, _componentValidator.default, {
    classNames: 'textarea-with-counter',

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setCountProperty();
    },
    setCountProperty: function setCountProperty() {
      var _this = this;

      var valueTrimmed = _string2.default.trimStart(this.get('value').toString());
      var maxCount = parseInt(this.get('max-count'));

      Ember.run.schedule('afterRender', function () {
        _this.set('value', valueTrimmed);
        var value = _this.get('value');
        var length = value === undefined ? 0 : value.length;
        var countRemaining = maxCount - length;
        _this.set('count', countRemaining);
      });
    },


    actions: {
      valChange: function valChange() {
        if (this.change) {
          this.sendAction('change');
        }
      }
    }
  });
});