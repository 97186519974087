define('lxso/mixins/check-enabled', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    permissions: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    checkEnabled: function checkEnabled(needed, model, target) {
      'use strict';

      if (this.get(needed)) {
        this.set(target, this.get(model).checkEnabled(this.get(needed)));
      } else {
        this.set(target, true);
      }
    }
  });
});