define('lxso/services/net-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    load: function load(recordId) {
      return this.get('store').findAll('product-margin', { adapterOptions: { recordId: recordId }, reload: true });
    },
    getMargin: function getMargin(ticketTypeId) {
      //assumes ticket margins have been loaded
      var productMargin = this.get('store').peekRecord('product-margin', ticketTypeId);
      return productMargin ? productMargin.get('marginOverride') : null;
    }
  });
});