define('lxso/routes/activity', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-supplier-branch', 'lxso/mixins/require-permission', 'lxso/config/environment'], function (exports, _authenticatedRouteMixin, _requireSupplierBranch, _requirePermission, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, _requirePermission.default, {
    requiredPermission: 'V3Product',
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    nameInHeader: Ember.inject.service(),
    supplierBranch: Ember.inject.service(),
    staticData: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    activityPublishFlag: Ember.inject.service(),
    queryParams: {
      direct: { refreshModel: false },
      isMigrated: { refreshModel: true }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var activity = controller.get('activity');
        if (activity.data.initial_basic) {
          activity.set('activity_location', activity.data.initial_activity_location);
          activity.set('basic', activity.data.initial_basic);
          activity.set('highlights', activity.data.initial_highlights);
          activity.set('policy', activity.data.initial_policy);
          activity.set('things', activity.data.initial_things);
          activity.set('redemption_address', activity.data.initial_redemption_address);
        }
      }
    },
    model: function model(params) {
      var route = this;
      var store = route.store;

      var isMigrated = params.isMigrated;
      // If the ID parameter is not a number, assume it's a type
      if (isNaN(params.id)) {
        // Create the activity using fragments for the parts we'll need to create
        // on this page
        var activity = store.createRecord('activity', {
          basic: store.createFragment('activity/basic'),
          operational: store.createFragment('activity/operational'),
          supplier_branch: store.createFragment('activity/supplier_branch')
        });

        // Set the type from the ID param
        activity.set('activity_type', params.id);

        return activity;
      } else {
        return store.findRecord('activity', params.id, {
          adapterOptions: {
            isMigrated: isMigrated
          } });
      }
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var supplierBranchId = model.get('supplier_branch.supplier_branch_id');
      var supplierBranchIdInSession = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
      if (supplierBranchIdInSession !== supplierBranchId) {
        return this.get('supplierBranch').loadActiveSupplierBranches([supplierBranchId]).then(function (activeSupplierBranches) {
          return _this.get('supplierBranch').updateSelectedBranch(activeSupplierBranches[0]);
        });
      }
    },
    setupController: function setupController() {
      this.controller.set('activity', this.modelFor('activity'));
      var isGTBranch = this.modelFor('activity').get('supplier_branch.supplier_branch_type') === 'GT';
      var isExternalUser = !this.get('permissions').checkEnabled('V3InternalSettings');
      var isGTBranchAndExternalUser = isGTBranch && isExternalUser;
      this.controller.set('activity.isGTBranchAndExternalUser', isGTBranchAndExternalUser);
      this.controller.set('supplierBranchId', this.get('session.data.authenticated.selectedBranch').supplier_branch_id);
      this.controller.set('activity.supplierBranchId', this.controller.get('supplierBranchId'));
      var isHealthAndSafetyEnabled = this.get('featureFlags').checkEnabled('HealthAndSafety');
      this.controller.set('activity.isHealthAndSafetyEnabled', isHealthAndSafetyEnabled);
      var activityCategory = this.controller.get('activity.operational.category');
      this.controller.set('activityStatus', this.controller.get('activity.operational.status'));
      this.controller.set('activityId', this.modelFor('activity').id);
      this.configureInternalCategory(activityCategory);
      this.setViewOnly();
      this.setPlatformName();
      var publishFlag = this.modelFor('activity').get('isActivityPublished');
      if (publishFlag != undefined) {
        this.get('activityPublishFlag').setPublishFlag(publishFlag);
      }
    },
    setPlatformName: function setPlatformName() {
      var _this2 = this;

      var activityID = this.controller.get('activity.id');
      if (activityID) {
        var host = _environment.default.api.host;
        var url = host + '/' + _environment.default.api.namespace + '/activities/' + activityID + '/external_references';
        this.get('ajaxWrapper').ajaxGet(url).then(function (externalRefResponse) {
          _this2.get('nameInHeader').setPartnerActivityOrOfferId(externalRefResponse, externalRefResponse.partnerActivityId);
          _this2.controller.set('activityExternalRefRes', externalRefResponse);
          _this2.controller.set('activity.isConnectivityEnabled', externalRefResponse.connectivityStatus);
        });
      }
    },
    setViewOnly: function setViewOnly() {
      if (this.controller.get('activity.isGTBranchAndExternalUser')) {
        this.controller.set('activity.viewOnly', true);
        var supplierBranchId = this.controller.get('supplierBranchId');
        var checkWhitelistGTEnabled = this.get('featureFlags').checkEnabled('GTSupplierBranchWhiteList');
        if (checkWhitelistGTEnabled) {
          var isSupplierBranchWhitelisted = !_environment.default.whiteListedGTSupplierBranch.contains(supplierBranchId);
          this.controller.set('activity.nonWhitelistGTSupplierBranch', isSupplierBranchWhitelisted);
        } else {
          this.controller.set('activity.nonWhitelistGTSupplierBranch', true);
        }

        if (CKEDITOR.instances['lx-ckeditor textarea-overview-description']) {
          CKEDITOR.instances['lx-ckeditor textarea-overview-description'].setReadOnly(true);
        }
      } else {
        this.controller.set('activity.viewOnly', false);
        this.controller.set('activity.nonWhitelistGTSupplierBranch', false);
        if (CKEDITOR.instances['lx-ckeditor textarea-overview-description']) {
          CKEDITOR.instances['lx-ckeditor textarea-overview-description'].setReadOnly(false);
        }
      }
    },
    configureInternalCategory: function configureInternalCategory(category) {
      this.controller.set('activity.activityCategory', category);
      var groundTransferCategories = this.get('staticData').getGroundTransferCategories();
      var isGrounsTransfer = groundTransferCategories.indexOf(category) >= 0;
      this.controller.set('activity.isViewOnlyForGroundTransfer', isGrounsTransfer);
    },
    unescapeHTML: function unescapeHTML(str) {
      var htmlEntities = {
        nbsp: ' ',
        cent: '¢',
        pound: '£',
        yen: '¥',
        euro: '€',
        copy: '©',
        reg: '®',
        lt: '<',
        gt: '>',
        quot: '"',
        amp: '&',
        apos: '\''
      };

      return str.replace(/&([^;]+);/g, function (entity, entityCode) {
        var match = void 0;

        if (entityCode in htmlEntities) {
          return htmlEntities[entityCode];
        } else if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
          return String.fromCharCode(parseInt(match[1], 16));
        } else if (match = entityCode.match(/^#(\d+)$/)) {
          return String.fromCharCode(~~match[1]);
        } else {
          return entity;
        }
      });
    },


    actions: {
      closeModal: function closeModal() {
        $('#preview-src').empty();
        this.controller.set('previewModalOpen', false);
      },
      openPreview: function openPreview() {
        var _this3 = this;

        this.controller.set('previewModalOpen', true);
        this.controller.set('isContentLoading', true);
        var url = window.location.origin + '/infosite-' + this.controller.get('activity.id') + '?lab=true';
        $.ajax({
          url: url,
          type: 'GET'
        }).done(function (successResponse) {
          _this3.controller.set('isContentLoading', false);
          var unescapedHtml = String(_this3.unescapeHTML(successResponse));

          setTimeout(function () {
            var iFrame = document.createElement('iframe');
            iFrame.setAttribute('width', '100%');
            iFrame.setAttribute('height', '700px');
            iFrame.setAttribute('id', 'infosite-iframe');
            iFrame.setAttribute('src', url);
            $('#preview-src').append(iFrame);

            var doc = document.getElementById('infosite-iframe').contentWindow.document;
            doc.open();
            doc.write(unescapedHtml);

            doc.close();
          }, 100);
        }).fail(function (errorResponse) {
          _this3.controller.set('isContentLoading', false);
        });
      },
      configureInternalCategoryAction: function configureInternalCategoryAction(category) {
        this.configureInternalCategory(category);
      }
    }
  });
});