define('lxso/components/activity-translations/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.set('selectAllChecked', this.computeSelectAllStatus());
    },


    classNameBindings: ['editingTranslations:editing-translations'],

    sortedTranslations: Ember.computed('translations', function () {
      return this.get('translations').sortBy('description');
    }),

    computeSelectAllStatus: function computeSelectAllStatus() {
      var translations = this.get('translations');
      return translations.reduce(function (previuos, current) {
        if (current.code == 'en-US') {
          return true;
        } else {
          return previuos && current.selected_for_translate;
        }
      }, true);
    },


    actions: {
      editTranslations: function editTranslations() {
        var table = $('.activity-translations-table');
        $(table).addClass('editing');
        this.sendAction('editTranslations');
      },
      cancelEditTranslations: function cancelEditTranslations() {
        var table = $('.activity-translations-table');
        $(table).removeClass('editing');
        this.sendAction('cancelEditTranslations');
      },
      updateCheckbox: function updateCheckbox(translation) {
        var toggleValue = !translation.selected_for_translate;
        Ember.set(translation, 'selected_for_translate', toggleValue);
        this.sendAction('setTranslationFormDirty', true);
      },
      selectAllTranslation: function selectAllTranslation(event) {
        var isChecked = event.target.checked;
        var translations = this.get('translations');
        this.set('selectAllChecked', event.target.checked);
        translations.forEach(function (value, index) {
          if (value.code !== 'en-US') {
            Ember.set(translations.objectAt(index), 'selected_for_translate', isChecked);
          }
        });
        this.set('translations', translations);
        this.sendAction('setTranslationFormDirty', true);
      },
      saveTranslations: function saveTranslations() {
        this.sendAction('saveTranslations');
      }
    }
  });
});