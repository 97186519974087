define('lxso/services/review-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ASCENDING = 'asc';
  var DESCENDING = 'desc';

  var VALID_PARAM_ARRAYS = {
    order: [ASCENDING, DESCENDING],
    sortBy: ['arrivalDate', 'receivedDate'],
    includeUncommented: ['true', 'false'],
    ratingFilter: ['5', '4', '3', '2', '1']
  };

  var DEFAULT_PARAMS = {
    page: 1,
    size: 3,
    order: DESCENDING,
    sortBy: VALID_PARAM_ARRAYS.sortBy[1],
    includeUncommented: true,
    ratingFilter: VALID_PARAM_ARRAYS.ratingFilter[2]
  };

  var DEFAULT_PAGE = DEFAULT_PARAMS.page;

  var RATING_FIELD_BY_QUESTION_MAP = new Map([['How was your activity?', 'overallRating'], ['Was your activity what you expected?', 'expectedRating'], ['Would you recommend this to others?', 'recommendRating']]);

  var VERY_SHORT_DATE_FORMAT = 'MMM DD, YYYY';

  function queryChange(route, newQueryParams) {
    'use strict';

    newQueryParams.page = DEFAULT_PAGE;
    var controller = route.get('controller');
    for (var key in newQueryParams) {
      controller.set(key, newQueryParams[key]);
    }
  }

  exports.default = Ember.Service.extend({

    queryChange: queryChange,

    setup: {
      updateParamsWithDefaults: function updateParamsWithDefaults(route, _params) {
        'use strict';

        var params = Ember.copy(_params);
        var isChange = false;

        var setParam = function setParam(key) {
          var originalValue = params[key];
          var hasValidValue = originalValue && VALID_PARAM_ARRAYS[key].includes(originalValue);
          params[key] = hasValidValue ? params[key] : DEFAULT_PARAMS[key];

          if ('' + params[key] !== '' + originalValue) {
            isChange = true;
          }
        };

        var hasValidSize = +params.size;
        params.size = hasValidSize ? params.size : DEFAULT_PARAMS.size;

        setParam('order');
        setParam('sortBy');
        setParam('ratingFilter');
        setParam('includeUncommented');

        if (isChange) {
          queryChange(route, params);
        }

        return params;
      },
      updateParamPage: function updateParamPage(route, _params, isNextPage) {
        'use strict';

        var params = Ember.copy(_params);

        var originalParamPage = +params.page;
        if (!isNextPage) {
          params.page = DEFAULT_PAGE;

          if (+params.page !== originalParamPage) {
            queryChange(route, params);
          }
        } else if (!originalParamPage) {
          params.page = DEFAULT_PAGE;
        }

        return params;
      },
      computeReview: function computeReview(review) {
        'use strict';

        var computedReview = Ember.copy(review);
        computedReview.receivedDateFormatted = moment(computedReview.createdDate).format(VERY_SHORT_DATE_FORMAT);
        computedReview.arrivalDateFormatted = moment(computedReview.offerStartTime).format(VERY_SHORT_DATE_FORMAT);

        computedReview.questions.forEach(function (structuredAnswer) {
          var ratingField = RATING_FIELD_BY_QUESTION_MAP.get(structuredAnswer.text);
          computedReview[ratingField] = structuredAnswer.response.toLowerCase();
        });

        return computedReview;
      }
    },

    actions: {
      commentFilterChange: function commentFilterChange(route, event) {
        'use strict';

        queryChange(route, { includeUncommented: !event.target.checked });
      },

      ratingFilterChange: function ratingFilterChange(route, event) {
        'use strict';

        queryChange(route, { ratingFilter: event.target.value });
      },

      sortReviews: function sortReviews(route, sortType) {
        'use strict';

        var type = sortType.split('Formatted:');
        var sortBy = type[0];
        var order = type[1];
        queryChange(route, { sortBy: sortBy, order: order });
      },

      reviewActivitySelect: function reviewActivitySelect(route, selectedActivity) {
        'use strict';

        var activityId = selectedActivity;
        queryChange(route, { activityId: activityId });
      }
    }

  });
});