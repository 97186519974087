define('lxso/adapters/price-template', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    //The api route is misleadingly named /activities/{id}/price_template
    // because it actually returns an array of price_templates.
    // In Ember, the models/activities.js model uses the plural form
    // for better clarity within the app's code. This adapter tells
    // Ember to hit the singluar-named route to get price templates plural.

    findAll: function findAll(store, type, snapshot) {
      //pass id in as a function
      //grabs the activity id route param
      var currentUrl = window.location.href;
      var activityId = currentUrl.split('/')[5];

      var url = this.host + '/' + this.namespace + '/activities/' + activityId + '/price_template';
      return this.ajax(url, 'GET');
    },
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var activityId = snapshot.record.activity.id;
      var url = this.host + '/' + this.namespace + '/activities/' + activityId + '/price_template';

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      return this.ajax(url, 'POST', { data: data });
    },


    //used when editing a single price group
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.host + '/' + this.namespace + '/activities/price_template/' + snapshot.record.get('id');
      serializer.serializeIntoHash(data, type, snapshot);
      delete data.price_template;
      return this.ajax(url, 'PUT', { data: data });
    },


    //use queryRecord to get a single price template and deal with the object/array difference
    queryRecord: function queryRecord(store, type, query) {
      var url = this.host + '/' + this.namespace + '/activities/price_template/' + query;
      return this.ajax(url, 'GET');
    },
    findRecord: function findRecord(store, type, id) {
      var url = this.host + '/' + this.namespace + '/activities/price_template/' + id;
      return this.ajax(url, 'GET');
    }
  });
});