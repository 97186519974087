define('lxso/mixins/report-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var STATUS_BOOKED = 'Purchase';
  var STATUS_CANCELLED = 'Cancellation';
  var STATUS_FULFILLMENT = 'Withhold Fulfillment';
  var STATUS_ANY = 'any';
  var DEFAULT_STATUS = STATUS_ANY;
  var TICKET_TYPE = 'Tickettype';
  var DEFAULT_FILTER = {
    statuses: new Set([STATUS_BOOKED, STATUS_CANCELLED, STATUS_FULFILLMENT]),
    textFilters: new Map([[TICKET_TYPE, ''], ['Supplierbranchid', '']])
  };

  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('filterModel', this.copyFilter(DEFAULT_FILTER));
    },
    copyFilter: function copyFilter(filter) {
      return {
        statuses: new Set(filter.statuses),
        textFilters: new Map(filter.textFilters)
      };
    },
    getStatusFilter: function getStatusFilter() {
      var statuses = this.get('filterModel').statuses;
      return function (reportItem) {
        return statuses.size === 0 || statuses.has(reportItem.get('Salestatus'));
      };
    },
    getTextFilters: function getTextFilters() {
      var textFilters = this.get('filterModel').textFilters;
      var filters = [];
      textFilters.forEach(function (value, key) {
        filters.push(function (reportItem) {
          var item = reportItem.get(key);
          var cleanValue = item ? item.trim().toLowerCase() : '';
          var cleanFilterValue = value.trim().toLowerCase();
          if (key === TICKET_TYPE) {
            return cleanValue.startsWith(cleanFilterValue);
          }
          return cleanValue.includes(cleanFilterValue);
        });
      });

      return filters;
    },
    setTextFilter: function setTextFilter(column, value) {
      var textFilters = this.get('filterModel').textFilters;
      var updateParams = {};

      if (value) {
        textFilters.set(column, value);
      } else {
        textFilters.delete(column);
      }

      updateParams[column] = value;
      this.triggerTransitionTo(updateParams);
      this.notifyPropertyChange('filterModel');
    },
    setFilterablePropertyFromRoute: function setFilterablePropertyFromRoute() {
      var _this = this;

      var model = this.get('model');
      this.get('route.FILTERABLE_REPORT_PROPERTIES').forEach(function (property) {
        _this.setTextFilter(property, model[property]);
      });
    },
    setFilterModelStatus: function setFilterModelStatus(statusValue) {
      var status = statusValue;
      if (status === STATUS_ANY) {
        status = [STATUS_BOOKED, STATUS_CANCELLED, STATUS_FULFILLMENT];
      } else {
        status = [status];
      }

      this.set('filterModel.statuses', new Set(status));
      this.notifyPropertyChange('filterModel');
    },
    setStatusRadioButton: function setStatusRadioButton() {
      var statusValue = this.getOrSetDefault('status', DEFAULT_STATUS);
      var elementId = '#filterStatus' + statusValue.capitalize();
      Ember.$(elementId).prop('checked', true);
      this.setFilterModelStatus(statusValue);
    },


    filters: Ember.computed('filterModel', function () {
      return this.getTextFilters().concat(this.getStatusFilter());
    }),

    filterText: Ember.computed('filterModel', function () {
      var result = {};
      this.get('filterModel').textFilters.forEach(function (value, key) {
        result[key] = value;
      });
      return result;
    }),

    ticketTypeFilterOptions: Ember.computed('reports', function () {
      var reports = this.get('reports');
      var ticketTypeFilterOptions = new Map();

      if (reports && reports.toArray) {
        var reportArray = this.get('reports').toArray();
        reportArray.forEach(function (report) {
          var ticketType = report.get(TICKET_TYPE).trim();
          var rootType = ticketType.split('(')[0].trim();

          ticketTypeFilterOptions.set(rootType.toLowerCase(), rootType);
          ticketTypeFilterOptions.set(ticketType.toLowerCase(), ticketType);
        });
      }

      var ticketTypeFilterOptionArray = Array.from(ticketTypeFilterOptions.values()).sort(function (a, b) {
        return a.localeCompare(b);
      });

      return {
        options: Ember.copy(ticketTypeFilterOptionArray),
        values: Ember.copy(ticketTypeFilterOptionArray)
      };
    }),

    checkTicketTypeFilterOptions: Ember.observer('ticketTypeFilterOptions', function () {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var ticketType = _this2.get('filterModel').textFilters.get(TICKET_TYPE);
        var values = _this2.get('ticketTypeFilterOptions').values;
        if (ticketType && values.length > 1 && !values.find(function (x) {
          return x === ticketType;
        })) {
          _this2.setTextFilter(TICKET_TYPE, '');
          Ember.$('#ticketTypeInfo').fadeIn(2000).delay(2000).fadeOut(2000);
        }
      });
    }),

    actions: {
      clear: function clear() {
        var _this3 = this;

        this.get('route.FILTERABLE_REPORT_PROPERTIES').forEach(function (property) {
          _this3.setTextFilter(property, '');
        });
        this.set('status', DEFAULT_STATUS);
        this.setStatusRadioButton();
      },
      filterOnStatus: function filterOnStatus(event) {
        var statusValue = event.target.value.trim();
        this.setFilterModelStatus(statusValue);
        this.triggerTransitionTo({ status: statusValue });
      },
      filterOnText: function filterOnText(event) {
        var column = event.target.name.replace('filter.', '');
        var value = event.target.value.trim();
        this.setTextFilter(column, value);
      },
      filterOnTicketType: function filterOnTicketType(value) {
        this.setTextFilter(TICKET_TYPE, value);
      },
      filterOnBranch: function filterOnBranch(selected) {
        this.setTextFilter('Supplierbranchid', selected);
      }
    }
  });
});