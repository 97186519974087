define('lxso/components/ticket-details-input/component', ['exports', 'lxso/mixins/component-validator'], function (exports, _componentValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentValidator.default, {
    classNameBindings: ['componentClasses'],
    actions: {
      change: function change(value, index) {
        this.sendAction('change', value, index);
      }
    }
  });
});