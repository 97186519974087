define('lxso/utils/concatenate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = concatenate;


  /**
   * Concatenates parts by joining them using the separator
   * Leaves out undefined or empty string parts
   * @param {string} separator
   * @param {...*} parts
   * @return {string}
   */
  function concatenate(separator) {
    for (var _len = arguments.length, parts = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      parts[_key - 1] = arguments[_key];
    }

    return parts.filter(nonEmpty).join(separator);
  }

  function nonEmpty(part) {
    return !Ember.isEmpty(part);
  }
});