define('lxso/models/option', ['exports', 'ember-data', 'lxso/utils/validation'], function (exports, _emberData, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    title: [(0, _validation.validator)('presence', true), (0, _validation.validator)('length', { min: 4 })],
    description: [(0, _validation.validator)('length', {
      min: 4,
      allowBlank: true
    })],
    duration: [(0, _validation.validator)('presence', true), (0, _validation.validator)('number', {
      gt: 0,
      allowString: true
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    activity: _emberData.default.belongsTo('activity', { async: true }),
    title: _emberData.default.attr('string', { defaultValue: '' }),
    description: _emberData.default.attr('string', { defaultValue: '' }),
    language_id: _emberData.default.attr('number', { defaultValue: 5 }),
    language: _emberData.default.attr('string'),
    duration: _emberData.default.attr('number', { defaultValue: 0 }),
    duration_type_id: _emberData.default.attr('number', { defaultValue: 1 }),
    duration_type: _emberData.default.attr('string', { defaultValue: 'minutes' }),
    status: _emberData.default.attr('string'),

    calculatedDuration: Ember.computed('duration', 'duration_multiplier', {
      get: function get(key) {
        return this.get('duration') / this.get('duration_multiplier');
      },
      set: function set(key, value) {
        this.set('duration', value * this.get('duration_multiplier'));
        return value;
      }
    })
  });
});