define('lxso/models/fragments/campaign/promotion-meta-data', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    campaignNameReadonly: _emberData.default.attr('boolean'),
    memberOnlyDealsReadonly: _emberData.default.attr('boolean'),
    multiItemPurchaseReadonly: _emberData.default.attr('boolean'),
    guestReadonly: _emberData.default.attr('boolean'),
    discountLowerBound: _emberData.default.attr('number'),
    discountReadonly: _emberData.default.attr('boolean'),
    bookingStartLowerBound: _emberData.default.attr('date-string'),
    bookingStartUpperBound: _emberData.default.attr('date-string'),
    bookingStartReadonly: _emberData.default.attr('boolean'),
    bookingEndLowerBound: _emberData.default.attr('date-string'),
    bookingEndUpperBound: _emberData.default.attr('date-string'),
    bookingEndReadonly: _emberData.default.attr('boolean'),
    travelStartLowerBound: _emberData.default.attr('date-string'),
    travelStartUpperBound: _emberData.default.attr('date-string'),
    travelStartReadonly: _emberData.default.attr('boolean'),
    travelEndLowerBound: _emberData.default.attr('date-string'),
    travelEndUpperBound: _emberData.default.attr('date-string'),
    travelEndReadonly: _emberData.default.attr('boolean')
  });
});