define('lxso/services/activity-publish-flag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    setPublishFlag: function setPublishFlag(flag) {
      this.set('publishFlag', flag);
    },
    getPublishFlag: function getPublishFlag() {
      return this.get('publishFlag');
    }
  });
});