define('lxso/models/fragments/language-info', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    languageTypeId: {
      descriptionKey: 'pages.offer.labels.languageType',
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('number', {
        gte: 1,
        allowString: true,
        messageKey: 'errors.customMessages.offers.noSelectionMade'
      })]
    },
    languageIDs: {
      descriptionKey: 'pages.offer.labels.languageType',
      validators: [(0, _validation.validator)('array-length', {
        dependantKeys: ['model.languageTypeId'],
        gte: 1,
        disabled: Ember.computed('model.languageTypeId', 'model.languageIDs', function () {
          return !(this.get('model.languageTypeId') === 1 || this.get('model.languageTypeId') === 2);
        }),
        messageKey: 'errors.customMessages.offerGroup.minOneLanguage',
        volatile: true
      })]
    }
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    languageIDs: _emberData.default.attr('array'),
    languageTypeId: _emberData.default.attr('number')
  });
});