define('lxso/utils/add-json-api-errors-to-payload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = addJsonApiErrorsToPayload;


  /**
   * Adds JSON API formated errors to a server payload object
   * @param  {object} payload - Server payload object
   * @param  {integer} status - Server status response
   * @return {array}          - An array of JSON API error objects
   *
   * Inputs:
   * - status: 400
   * - payload:
   * 		{
   *     "type":"/validation-error",
   *     "title":"could not complete due to domain validation rules not being met",
   *     "details":[
   *     {
   *       "name":"Internal Title",
   *       "message":"Internal Title is required"
   *     }
   *     ],
   *     "instance":"/api/v1/activities/373472/basic"
   *   }
   *
   * Returns:
   * [{
   *   status: 400,
   *   code: "/validation-error",
   *   id: "internal_title",
   *   title: "could not complete due to domain validation rules not being met",
   *   detail: "Internal Title is required",
   *   meta: {
   *     instance: "/api/v1/activities/373472/basic",
   *     name: "Internal Title"
   *   }
   * }]
   */
  function addJsonApiErrorsToPayload(payload, status) {
    // If payload is a string, format it into an object so we can use it
    var localPayload = payload;
    if (typeof localPayload === 'string') {
      localPayload = {
        details: [{
          message: payload
        }]
      };
    }

    if (status >= 400) {
      localPayload.errors = [];

      // JSON API error object created from payload and status
      var errorHash = { status: status };

      if (localPayload.type) {
        errorHash.code = localPayload.type;
      }
      if (localPayload.title) {
        errorHash.title = localPayload.title;
      }
      if (localPayload.instance) {
        errorHash.meta = {};
        errorHash.meta.instance = localPayload.instance;
      }

      // If there is a details array, create an object for each based off the errorHash
      if (Array.isArray(localPayload.details)) {
        localPayload.details.forEach(function (detail) {
          var error = Ember.copy(errorHash, true);
          if (detail.message) {
            error.detail = detail.message;
          }
          if (detail.name) {
            error.id = Ember.String.underscore(detail.name);
            if (!error.meta) {
              error.meta = {};
            }
            error.meta.name = detail.name;
          }
          localPayload.errors.push(error);
        });
      } else {
        // If there is not a details array, just add the errorHash to the errors array
        localPayload.errors.push(errorHash);
      }
    }

    return localPayload;
  }
});