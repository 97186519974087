define('lxso/mixins/supplier-settings/supplier-settings', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    initSupplierSettings: function initSupplierSettings(controller, model) {
      controller.set('supplierDetails', model.supplierDetails);
      controller.set('isSupplierSettingsOpen', true);
      controller.set('editingSupplierSectionSettings', false);
      this.setIsSupplierSuspended();
    },
    setIsSupplierSuspended: function setIsSupplierSuspended() {
      var status = this.controller.get('supplierDetails.status');
      this.controller.set('isSupplierSuspendedExpedia', status === 'SuspendedExpedia');
      this.controller.set('isSupplierSuspendedPaused', status === 'SuspendedPaused');
    },

    actions: {
      retryVendorId: function retryVendorId(supplierDetails) {
        var _this = this;

        this.controller.set('lastSaved', 'supplier-settings');
        supplierDetails.set('processingVendorIdRetry', true);
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/suppliers/tshopvendor';
        var postData = {
          name: supplierDetails.get('name'),
          supplierId: supplierDetails.get('supplier_id')
        };
        this.get('ajaxWrapper').ajaxPost(url, postData).then(function (response) {
          supplierDetails.set('processingVendorIdRetry', false);
          supplierDetails.set('vendor_id', response);
          _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (errors) {
          supplierDetails.set('processingOptionTshopRetry', false);
          Ember.Logger.error('error creating supplier Vendor ID', errors);
          _this.controller.set('errors', errors);
          _this.get('notifications').error(_this.get('i18n').t('components.alert.saveFailure'));
        });
      },
      editSupplierSection: function editSupplierSection() {
        if (this.isEditAllowed('supplierSettings')) {
          this.controller.set('editingSupplierSectionSettings', true);
        } else {
          this.controller.set('sectionToBeEdited', 'supplierSettings');
        }
      },
      supplierStatusSelected: function supplierStatusSelected(status) {
        this.controller.set('supplierDetails.status', status);
      },
      supplierAutoRenewRateSelected: function supplierAutoRenewRateSelected(isAutoRenewRateEnabled) {
        this.controller.set('supplierDetails.auto_renew_rate_enabled', isAutoRenewRateEnabled);
      },
      saveSupplierSettings: function saveSupplierSettings() {
        var _this2 = this;

        this.setIsSupplierSuspended();
        this.controller.set('isUpdatingSupplierDetails', true);
        this.controller.set('lastSaved', 'supplier-settings');
        this.controller.get('supplierDetails').save().then(function () {
          _this2.controller.set('supplierServerError', undefined);
          _this2.controller.set('editingSupplierSectionSettings', false);
          _this2.get('notifications').success(_this2.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (supplierUpdateErr) {
          Ember.Logger.error('error creating supplier details', supplierUpdateErr);
          var serverErr = {};
          serverErr.errors = supplierUpdateErr.details;
          _this2.controller.set('supplierServerError', serverErr);
        }).finally(function () {
          _this2.controller.set('isUpdatingSupplierDetails', false);
        });
      },
      cancelSupplierSettingsUpdate: function cancelSupplierSettingsUpdate() {
        this.controller.set('supplierServerError', undefined);
        this.controller.get('supplierDetails').rollbackAttributes();
        this.controller.set('editingSupplierSectionSettings', false);
      }
    }
  });
});