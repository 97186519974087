define('lxso/models/offer-group/schedule', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    startTime: {
      descriptionKey: 'pages.offer.labels.start',
      validators: [(0, _validation.validator)('presence', {
        presence: true
      }), (0, _validation.validator)('time-format', {
        showSuggestions: true
      })]
    },
    endTime: {
      descriptionKey: 'pages.offer.labels.end',
      validators: [(0, _validation.validator)('presence', {
        presence: true
      }), (0, _validation.validator)('time-format', {
        showSuggestions: true
      })]
    }
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    startTime: _emberData.default.attr('time'),
    endTime: _emberData.default.attr('time'),
    offerId: _emberData.default.attr('number'),
    i18n: Ember.inject.service(),
    offerIds: _emberData.default.attr('array', { defaultValue: [] }),
    durationInMinutes: _emberData.default.attr('number'),
    status: _emberData.default.attr('string', { defaultValue: 'active' }),
    tagId: _emberData.default.attr('string', { defaultValue: new Date().getTime().toString() })
  });
});