define('lxso/helpers/convert-to-utc', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convertToUTC = convertToUTC;
  function convertToUTC(params) {
    return moment.utc(params[0]).format(params[1]);
  }

  exports.default = Ember.Helper.helper(convertToUTC);
});