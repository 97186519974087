define('lxso/utils/filter-factory', ['exports', 'lxso/utils/date-validators'], function (exports, _dateValidators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sameOrAfterDate = exports.sameOrBeforeDate = exports.includesNumber = exports.includesText = undefined;


  function whenPresent(inputProperty, predicate) {
    return Ember.computed(inputProperty, function () {
      return Ember.isPresent(this.get(inputProperty)) ? predicate.bind(this) : function () {
        return true;
      };
    });
  }

  function includesText(filteredProperty, inputProperty) {
    return whenPresent(inputProperty, function (record) {
      var fieldValue = record.get(filteredProperty);
      return Ember.isPresent(fieldValue) && fieldValue.toLowerCase() === this.get(inputProperty).toLowerCase();
    });
  }

  function includesNumber(filteredProperty, inputProperty) {
    return whenPresent(inputProperty, function (record) {
      var fieldValue = record.get(filteredProperty);
      return Ember.isPresent(fieldValue) && (fieldValue === parseInt(this.get(inputProperty)) || isNaN(this.get(inputProperty)));
    });
  }

  function whenDatePresent(filteredProperty, inputProperty, predicate) {
    return whenPresent(inputProperty, function (record) {
      return predicate.call(this, record.get(filteredProperty));
    });
  }

  function sameOrBeforeDate(filteredProperty, inputProperty, precision) {
    return whenDatePresent(filteredProperty, inputProperty, function (date) {
      return (0, _dateValidators.isSameOrBefore)(date, this.get(inputProperty), precision);
    });
  }

  function sameOrAfterDate(filteredProperty, inputProperty, precision) {
    return whenDatePresent(filteredProperty, inputProperty, function (date) {
      return (0, _dateValidators.isSameOrAfter)(date, this.get(inputProperty), precision);
    });
  }

  exports.includesText = includesText;
  exports.includesNumber = includesNumber;
  exports.sameOrBeforeDate = sameOrBeforeDate;
  exports.sameOrAfterDate = sameOrAfterDate;
});