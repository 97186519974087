define('lxso/models/fragments/hotel-zone/hotel', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({});

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    hotelId: _emberData.default.attr('number'),
    address: (0, _attributes.fragment)('fragments/hotel-zone/hotel-address'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    statusCode: _emberData.default.attr('string'),
    updatedBy: _emberData.default.attr('string'),
    updatedDate: _emberData.default.attr('string')
  });
});