define('lxso/components/lxpc-footer/component', ['exports', 'lxso/mixins/example-modal'], function (exports, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_exampleModal.default, {
    currentYear: new Date().getFullYear(),

    actions: {
      openOsanoCMDrawer: function openOsanoCMDrawer() {
        Osano.cm.showDrawer();
      }
    }

  });
});