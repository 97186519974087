define('lxso/models/price-template', ['exports', 'ember-data', 'lxso/utils/validation'], function (exports, _emberData, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    name: [(0, _validation.validator)('presence', true), (0, _validation.validator)('length', { min: 4 })],
    price_template_details: [(0, _validation.validator)('array-content-presence', true), (0, _validation.validator)('reference-price-is-number'), (0, _validation.validator)('base-price-is-number'), (0, _validation.validator)('price-compare-base-reference'), (0, _validation.validator)('diff-base-reference')]
  });

  // price-templates is an array on activity
  // each object in price-templates is a single price-template object
  // each price-template object has an array of n price-template-detail objects


  // the quantity of elements in the price-template-details array varies by
  // the number of ticket types assigned to a particular activity.
  // Ie: if an activity has 3 ticket types then there will be 3 objects
  // in the price-template-details array.


  exports.default = _emberData.default.Model.extend(Validations, {
    price_template: _emberData.default.belongsTo('activity', { async: true }),
    //price_template_id: DS.attr('number'),
    name: _emberData.default.attr('string'),
    status: _emberData.default.attr('string', { defaultValue: 'Active' }),
    activity_id: _emberData.default.attr('number'),
    last_updated_date: _emberData.default.attr('string'),
    create_date: _emberData.default.attr('string'),
    price_template_details: _emberData.default.attr('array', { defaultValue: function defaultValue() {
        return Ember.A();
      } })

    //price-template-details is an array objects that look like this:

    /*
     [
       {
         "price_template_detail_id": 103719,
         "price_template_id": 68595,
         "price_type": {
           "id": 1,
           "name": "Standard",
           "description": "Standard Price Type",
           "status": "ACTIVE"
           },
         "base": 0,
         "net": 0,
         "min": 0,
         "max": 0,
         "suggested": 0,
         "reference": 0,
         "vendor_sku": "test",
         "status": "ACTIVE",
         "ticket_type": {
           "id": 53913,
           "guest_type_id": 2,
           "ticket_type_name": "Adult ( 12 + Years )",
           "internal_note": "",
           "referenced": false,
           "restrictions": [
             {
               "restriction_type_id": 1,
               "min": 12,
               "max": 255,
               "is_primary": true
             }
           ]
         },
       "last_updated_date": "2016-03-21T22:04:42Z",
       "create_date": "2016-03-21T22:04:42Z"
       }
     ]
     */

  });
});