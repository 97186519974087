define('lxso/helpers/add-leading-zero', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.addLeadingZero = addLeadingZero;
  function addLeadingZero(args) {
    return args.toString().length === 1 ? '0' + args : args;
  }

  exports.default = Ember.Helper.helper(addLeadingZero);
});