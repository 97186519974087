define('lxso/mixins/platform-settings/operational', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    ajaxWrapper: Ember.inject.service(),

    initPlatformOperational: function initPlatformOperational() {
      this.controller.set('saving', false);
      this.controller.set('isOperationalDirty', false);
      this.controller.set('editingPlatformSettingsOperational', false);
      this.controller.set('isSupplierOperationalOpen', true);
      var lastDate = this.controller.get('platform.last_saved_date');
      this.controller.set('platform.formatDate', moment(lastDate).format('MMM Do YYYY, h:mm:ss A'));
    },
    getText: function getText(file) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
          return resolve(reader.result);
        };
        reader.onerror = function (error) {
          return reject(error);
        };
      });
    },
    toggleEdit: function toggleEdit() {
      this.controller.set('editingPlatformSettingsOperational', !this.controller.get('editingPlatformSettingsOperational'));
    },
    savePlatformOperationSection: function savePlatformOperationSection(platform) {
      var _this = this;

      var isPlatformDirty = platform.get('hasDirtyAttributes');

      if (isPlatformDirty) {
        var platformData = JSON.parse(JSON.stringify(platform));
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/platforms/' + platform.get('id');
        platformData.email = platformData.supportDistributionList;
        delete platformData.supportDistributionList;

        this.get('ajaxWrapper').ajaxPut(url, platformData).then(function () {
          _this.toggleEdit();
          _this.controller.set('isOperationalDirty', false);
        }).catch(function (error) {
          if (error.responseJSON && error.responseJSON.details) {
            _this.controller.set('errors', error.responseJSON.details);
          }
        }).finally(function () {
          platform.reload().finally(function () {
            _this.controller.set('saving', false);
          });
        });
      } else {
        this.toggleEdit();
        this.controller.set('isOperationalDirty', false);
        this.controller.set('saving', false);
      }
    },


    actions: {
      savePlatformOperational: function savePlatformOperational() {
        var _this2 = this;

        var errorList = [];
        this.controller.set('errors', []);
        this.controller.set('mappingErrors', []);
        this.controller.set('mappingErrorExists', false);
        this.controller.set('saving', true);
        this.controller.set('lastSaved', 'operational');
        var platform = this.controller.get('platform');
        var supplierMappingCsv = document.getElementById('platform-supplier-mapping').files[0];

        if (supplierMappingCsv) {
          var mappingErrors = [];
          var formatErrors = [];
          this.getText(supplierMappingCsv).then(function (data) {
            var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/supplier_connectivity/import?platformId=' + platform.get('id');

            var params = {
              url: url,
              data: data,
              dataType: 'application/json, text/plain, */*',
              contentType: 'text/plain'
            };

            _this2.get('ajaxWrapper').ajaxPost(url, data, '', params).catch(function (error) {
              if (error) {
                var jsonError = JSON.parse(error.responseText);
                if (jsonError.errors && jsonError.errors[0] !== undefined) {
                  jsonError.errors.forEach(function (error) {
                    return errorList.push(error.publicErrorMessage);
                  });
                } else {
                  errorList.push(jsonError.title);
                }

                for (var i = 0; i < errorList.length; i++) {
                  if (errorList[i].includes('Mapping ID is missing')) {
                    mappingErrors.push(errorList[i]);
                    _this2.controller.set('mappingErrorExists', true);
                  } else {
                    formatErrors.push(errorList[i]);
                  }
                }
                _this2.controller.set('errors', { errors: formatErrors });
                _this2.controller.set('mappingErrors', mappingErrors);
              }
            }).finally(function () {
              if (formatErrors.length > 0 && formatErrors[0] !== undefined) {
                _this2.controller.set('saving', false);
                Ember.Logger.error('Errors while saving supplier platform operational');
              } else {
                _this2.savePlatformOperationSection(platform);
              }
            });
          });
        } else {
          this.savePlatformOperationSection(platform);
        }
      },
      editPlatformOperational: function editPlatformOperational() {
        this.controller.set('mappingErrorExists', false);
        if (this.controller.get('isAnySectionEditing')) {
          this.controller.set('sectionToBeEdited', 'operational');
        } else {
          this.toggleEdit();
        }
      },
      cancelPlatformOperational: function cancelPlatformOperational() {
        this.controller.get('platform').rollbackAttributes();
        document.getElementById('platform-supplier-mapping').value = '';
        this.toggleEdit();
        this.controller.set('isOperationalDirty', false);
        this.controller.set('errors', []);
        this.controller.set('mappingErrorExists', false);
      },
      apiVersionSelected: function apiVersionSelected(version) {
        this.controller.set('platform.apiVersion', version);
        this.controller.set('isOperationalDirty', true);
      },
      setDirtyAttribute: function setDirtyAttribute() {
        this.controller.set('isOperationalDirty', true);
      }
    }
  });
});