define('lxso/models/activity/translation', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'lxso/models/utils/validation'], function (exports, _emberData, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({});

  exports.default = _emberData.default.Model.extend(Validations, {
    code: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    last_export_date: _emberData.default.attr('string'),
    last_import_date: _emberData.default.attr('string'),
    selected_for_translate: _emberData.default.attr('boolean', { defaultValue: false })
  });
});