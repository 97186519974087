define('lxso/serializers/option-group', ['exports', 'ember-data', 'lodash', 'lxso/constants/language-type-ids', 'lxso/constants/option-group'], function (exports, _emberData, _lodash, _languageTypeIds, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var START_TYPE_SCHEDULES = [_optionGroup.SCHEDULE_TYPES.START, _optionGroup.SCHEDULE_TYPES.MULTI_DAY];

  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    //this specifies the correct serializer
    primaryKey: 'offerGroupId',
    languageEnum: Ember.inject.service('language-enum'),
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      convertBookingCutoffInHoursToMinutes(json);
      processOffers(json);

      if (!json.offerGroupId) {
        delete json.offerGroupId;
      }
      if (!json.attributes.validityDays) {
        delete json.attributes.validityDays;
      }
      delete json.attributes.durationDaysPart;
      delete json.attributes.durationHoursPart;
      delete json.attributes.durationMinutesPart;
      return json;
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      payload.forEach(function (optionGroup) {
        return _this.normalizeSingleOptionGroup(optionGroup);
      });
      return this._super.apply(this, arguments);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var optionStatusesToFilter = [_optionGroup.STATUS.ACTIVE, _optionGroup.STATUS.SUSPENDED];
      this.normalizeSingleOptionGroup(payload, optionStatusesToFilter);
      return this._super.apply(this, arguments);
    },
    normalizeSingleOptionGroup: function normalizeSingleOptionGroup(payload, optionStatusesToFilter) {
      if (payload.bookingCutoff && payload.bookingCutoff.cutoffInMinutes >= 0) {
        payload.bookingCutoff.bookingCutoffInHours = payload.bookingCutoff.cutoffInMinutes / 60;
      }
      if (payload.attributes.durationInMinutes) {
        if (payload.attributes.scheduleType === _optionGroup.SCHEDULE_TYPES.START || payload.attributes.scheduleType === _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION) {
          payload.attributes.durationDaysPart = 0;
          payload.attributes.durationHoursPart = Math.floor(payload.attributes.durationInMinutes / 60);
          payload.attributes.durationMinutesPart = payload.attributes.durationInMinutes % 60;
        } else if (payload.attributes.scheduleType === _optionGroup.SCHEDULE_TYPES.VALID_HOURS) {
          payload.attributes.durationDaysPart = 0;
          payload.attributes.durationHoursPart = Math.floor(payload.attributes.durationInMinutes / 60);
          payload.attributes.durationMinutesPart = 0;
        } else if (payload.attributes.scheduleType === _optionGroup.SCHEDULE_TYPES.VALID_DAYS || payload.attributes.scheduleType === _optionGroup.SCHEDULE_TYPES.MULTI_DAY) {
          payload.attributes.durationDaysPart = Math.floor(payload.attributes.durationInMinutes / (60 * 24));
          payload.attributes.durationHoursPart = 0;
          payload.attributes.durationMinutesPart = 0;
        }
      }
      if (payload.offers) {
        payload.offers = this.getFilteredOptions(payload.offers, optionStatusesToFilter).map(function (offer) {
          return Object.assign(offer, {
            startTime: offer.startTime ? offer.startTime.slice(0, 5) : '', //convert hh:mm:ss to hh:mm
            endTime: offer.endTime ? offer.endTime.slice(0, 5) : ''
          });
        });
        var groupedOffers = this.groupOffersOfSameScheduleAndLanguageTypeId(payload.offers);
        payload.offers = groupedOffers.sort(function (a, b) {
          return moment(a.startTime, 'HH:mm').unix() - moment(b.startTime, 'HH:mm').unix();
        });
      }
      if (!payload.attributes.mealType) {
        payload.attributes.mealType = _optionGroup.MEAL_TYPES.NOT_INCLUDED;
      }
    },
    getFilteredOptions: function getFilteredOptions(offers, optionStatusesToFilter) {
      if (optionStatusesToFilter && optionStatusesToFilter.length) {
        return offers.filter(function (offer) {
          return optionStatusesToFilter.includes(offer.status);
        });
      } else {
        return offers;
      }
    },
    groupOffersOfSameScheduleAndLanguageTypeId: function groupOffersOfSameScheduleAndLanguageTypeId(offers) {
      //offers with different languages, but same languageTypeId and schedules are grouped into single record.
      //individual offers in each offerRow is stored in subOffers
      var offersGroupedByLangInfoAndSchedule = Object.values(_lodash.default.groupBy(offers, function (offer) {
        return offer.languageInfo.languageTypeId + '#' + offer.startTime;
      }));
      var sortedLanguages = this.get('languageEnum').getCachedLanguages();
      return offersGroupedByLangInfoAndSchedule.map(function (subOffers) {
        var optionIds = void 0;
        if (sortedLanguages && sortedLanguages.length) {
          var sortedLanguageIds = sortedLanguages.map(function (language) {
            return language.id;
          });
          var sortedSubOffers = subOffers.sort(function (offerA, offerB) {
            return sortedLanguageIds.indexOf(offerA.languageInfo.languageIds[0]) - sortedLanguageIds.indexOf(offerB.languageInfo.languageIds[0]);
          });
          optionIds = sortedSubOffers.map(function (subOffer) {
            return subOffer.offerId;
          });
        } else {
          optionIds = subOffers.map(function (subOffer) {
            return subOffer.offerId;
          });
        }

        var languageIds = subOffers.map(function (subOffer) {
          return subOffer.languageInfo.languageIds;
        }).flat();
        return Object.assign({}, subOffers[0], {
          languageInfo: Object.assign({}, subOffers[0].languageInfo, {
            languageIds: languageIds
          }),
          offerIds: optionIds,
          subOffers: subOffers,
          status: _optionGroup.STATUS.ACTIVE
        });
      });
    }
  });


  function convertBookingCutoffInHoursToMinutes(json) {
    json.bookingCutoff.cutoffInMinutes = json.bookingCutoff.bookingCutoffInHours * 60;
    delete json.bookingCutoff.cutoffInHours;
  }

  function processOffers(json) {
    var offersToUpdate = [];
    var offersToCreate = [];
    var offersToDelete = [];

    var _$partition = _lodash.default.partition(json.offers, function (offer) {
      return offer.status === _optionGroup.STATUS.INACTIVE;
    }),
        _$partition2 = _slicedToArray(_$partition, 2),
        inactiveOffers = _$partition2[0],
        activeAndSuspendedOffers = _$partition2[1];

    offersToDelete.push.apply(offersToDelete, _toConsumableArray(inactiveOffers.map(function (offerRow) {
      return offerRow.subOffers;
    }).flat()));

    activeAndSuspendedOffers.forEach(function (offerRow) {
      if (offerRow.subOffers.length) {
        //Some offers were already existing. Check if they were updated, deleted or new ones were added.
        if (offerRow.languageInfo.languageTypeId != offerRow.subOffers[0].languageInfo.languageTypeId || offerRow.startTime != offerRow.subOffers[0].startTime) {
          //When languageTypeId is changed, we delete existing offers and create new offers with updated languageTypeId
          offersToDelete.push.apply(offersToDelete, _toConsumableArray(offerRow.subOffers));
          offersToCreate.push.apply(offersToCreate, _toConsumableArray(getNewOffersToCreateFromOfferRow(offerRow)));
        } else {
          var offersToUpdateFromOfferRow = getOffersToUpdateFromOfferRow(offerRow); //when existing offer changes
          offersToUpdate.push.apply(offersToUpdate, _toConsumableArray(offersToUpdateFromOfferRow));
          var offerIdsToUpdate = offersToUpdateFromOfferRow.map(function (offer) {
            return offer.offerId;
          });
          var languageIdsToUpdate = offersToUpdateFromOfferRow.map(function (offer) {
            return offer.languageInfo.languageIds;
          }).flat();
          var offersToDeleteFromOfferRow = offerRow.subOffers.filter(function (offer) {
            return !offerIdsToUpdate.includes(offer.offerId);
          }); //existing language removed for live commentary
          offersToDelete.push.apply(offersToDelete, _toConsumableArray(offersToDeleteFromOfferRow));
          var offersToCreateFromOfferRow = offerRow.languageInfo.languageIds.filter(function (languageId) {
            return !languageIdsToUpdate.includes(languageId);
          }).map(function (languageId) {
            return Object.assign({}, offerRow, {
              languageInfo: {
                languageIds: [languageId],
                languageTypeId: offerRow.languageInfo.languageTypeId
              },
              status: _optionGroup.STATUS.ACTIVE
            });
          });
          offersToCreate.push.apply(offersToCreate, _toConsumableArray(offersToCreateFromOfferRow));
        }
      } else {
        //all are new offers as subOffers is empty
        offersToCreate.push.apply(offersToCreate, _toConsumableArray(getNewOffersToCreateFromOfferRow(offerRow)));
      }
    });

    offersToDelete.forEach(function (offer) {
      offer.status = _optionGroup.STATUS.INACTIVE;
    });

    json.offers = [].concat(offersToCreate, offersToUpdate, offersToDelete);
    json.offers.forEach(function (offer) {
      delete offer.uuid;
      delete offer.offerIds;
      delete offer.subOffers;
      delete offer.isSelected;

      if (!START_TYPE_SCHEDULES.includes(json.attributes.scheduleType)) {
        delete offer.startTime;
        delete offer.endTime;
      }
    });
  }

  function getNewOffersToCreateFromOfferRow(offerRow) {
    if (offerRow.languageInfo.languageTypeId === _languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY) {
      return getLiveCommentaryOffersToCreateFromOfferRow(offerRow);
    } else {
      return [offerRow];
    }
  }

  function getLiveCommentaryOffersToCreateFromOfferRow(offerRow) {
    return offerRow.languageInfo.languageIds.map(function (languageId) {
      return Object.assign({}, offerRow, {
        languageInfo: {
          languageIds: [languageId],
          languageTypeId: _languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY
        },
        status: _optionGroup.STATUS.ACTIVE
      });
    });
  }

  function getOffersToUpdateFromOfferRow(offerRow) {
    if (offerRow.languageInfo.languageTypeId === _languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY) {
      return getLiveCommentaryOffersToUpdate(offerRow);
    } else {
      return getNonLiveCommentaryOffersToUpdate(offerRow);
    }
  }

  function getLiveCommentaryOffersToUpdate(offerRow) {
    return offerRow.subOffers.filter(function (offer) {
      return offerRow.languageInfo.languageIds.includes(offer.languageInfo.languageIds[0]);
    }).map(function (offer) {
      return Object.assign({}, offer, {
        startTime: offerRow.startTime,
        endTime: offerRow.endTime
      });
    });
  }

  function getNonLiveCommentaryOffersToUpdate(offerRow) {
    return [Object.assign({}, offerRow, {
      offerId: offerRow.subOffers[0].offerId
    })];
  }
});