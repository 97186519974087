define('lxso/helpers/is-stop-sell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isStopSell = isStopSell;
  function isStopSell(params /*, hash*/) {
    var model = params[0];
    var isStopSell = true;
    if (model.get('activities')) {
      model.get('activities').forEach(function (activity) {
        return activity.get('offers').forEach(function (offer) {
          if (!offer.data.isStopSellOnDate) {
            isStopSell = false;
          }
        });
      });
    } else if (model.get('offers')) {
      model.get('offers').forEach(function (offer) {
        if (!offer.data.isStopSellOnDate) {
          isStopSell = false;
        }
      });
    } else {
      // for limited inventory view page
      if (!model.get('isStopSellOnDate')) {
        isStopSell = false;
      }
    }
    return isStopSell;
  }

  exports.default = Ember.Helper.helper(isStopSell);
});