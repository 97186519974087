define('lxso/components/lx-input/component', ['exports', 'lodash/string', 'lxso/mixins/example-modal'], function (exports, _string2, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_exampleModal.default, {
    classNames: 'lx-input',

    count: Ember.computed('value', {
      get: function get() {
        var value = this.get('value');
        var valueTrimmed = _string2.default.trimStart(value);
        var length = valueTrimmed === undefined ? 0 : valueTrimmed.length;
        return this.get('max-count') - length;
      }
    }),

    actions: {
      /* removeAction is called in this component's template like so:
         {{action "removeAction" index on="click"}}
        list-inputs/template.hbs uses this component, like so:
         {{lx-input
          index=index
          removeNew="removeNew"
          ...
       list-inputs/component.js has the actual "removeNew" action logic
       When 'removeNew' is sent from this "child", it is recieved by the handlebars component in the "parent"
      */

      change: function change(value, index) {
        'use strict';

        this.sendAction('change', value, index);
      },
      removeAction: function removeAction(index) {
        this.sendAction('removeNew', index);
      }
    }

  });
});