define('lxso/components/server-error/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    responseErrors: Ember.inject.service('response-errors'),

    // Add new payload errors to response errors service
    init: function init() {
      this._super.apply(this, arguments);
      this.get('responseErrors').addErrors(this.get('payload'), this.get('status'));
    },


    // Add new payload errors to response errors service
    observesPayload: Ember.observer('payload.errors.[]', function () {
      this.get('responseErrors').addErrors(this.get('payload'), this.get('status'));
    }),

    // Indicates if there are errors in the response errors service
    hasErrors: Ember.computed('responseErrors.errors.[]', function () {
      return !!this.get('responseErrors.errors').length;
    })

  });
});