define('lxso/components/platform-settings-authentication/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      editSupplierBranch: function editSupplierBranch(supplierBranch) {
        this.sendAction('editSupplierBranch', supplierBranch);
      },
      cancelSupplierBranch: function cancelSupplierBranch(supplierBranch) {
        this.sendAction('cancelSupplierBranch', supplierBranch);
      },
      saveSupplierBranch: function saveSupplierBranch(supplierBranch) {
        this.sendAction('saveSupplierBranch', supplierBranch);
      }
    }
  });
});