define('lxso/adapters/asset', ['exports', 'ember-data', 'lxso/adapters/application', 'lxso/config/environment'], function (exports, _emberData, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var assetsUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/assets';

  exports.default = _application.default.extend(_emberData.default.BuildURLMixin, {
    host: _environment.default.mvapi.host,
    namespace: _environment.default.mvapi.namespace,
    queryNamespace: _environment.default.mvapi.queryNamespace,

    mediavault: Ember.inject.service('mediavault'),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    // Return headers stored in the mediavault service
    headers: Ember.computed(function () {
      return this.get('mediavault.headers');
    }).volatile(),

    // Media Vault asset paths are singular (aside from query paths)
    pathForType: function pathForType() {
      return 'asset';
    },


    /**
     * Find a single asset
     *
     * Usage within route:
     * ```
     * this.store.findRecord('asset', id)
     * .then((asset) => {
     *   console.log(asset);
     * });
     * ```
     *
     * @return {object}          Asset object
     */
    findRecord: function findRecord(store, type, id, snapshot) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('mediavault').getCredentials().then(function () {
          var url = _this.buildURL(type.modelName, id, snapshot, 'findRecord');
          resolve(_this.ajax(url, 'GET'));
        }).catch(function (reason) {
          _this.get('mediavault').expireCredentials();
          resolve(reason);
        });
      });
    },


    /**
     * Create an asset
     *
     * Usage within route:
     * ```
     * this.store.createRecord('asset', {
     *   caption: 'This is the caption',
     *   tags: ['tag', 'another-tag']
     * })
     * .save()
     * .then((result) => {
     *   console.log(result);
     * });
     * ```
     *
     * @return {object}          Object containing assetId
     */
    createRecord: function createRecord(store, type, snapshot) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var mediavault = _this2.get('mediavault');
        mediavault.getCredentials().then(function () {
          var batch = mediavault.createBatch();
          return batch;
        }).then(function (batchId) {
          var data = batchId;
          var serializer = store.serializerFor(type.modelName);
          var url = _this2.buildURL(type.modelName, null, snapshot, 'createRecord');
          serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
          resolve(_this2.get('ajaxWrapper').ajaxPost(assetsUrl + '/asset', data, null, {
            headers: _this2.get('headers'),
            xhrFields: {
              withCredentials: true
            }
          }));
        }).catch(function (reason) {
          _this2.get('mediavault').expireCredentials();
          reject(reason);
        });
      });
    },


    /**
     * Update an asset
     *
     * Usage within route:
     * ```
     * asset.set('caption', 'New Caption');
     * asset.save()
     * .then((asset) => {
     *   console.log(asset)
     * });
     * ```
     *
     * @return {object}          Asset object
     */
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.get('mediavault').getCredentials().then(function () {
          var data = {};
          var serializer = store.serializerFor(type.modelName);
          var url = _this3.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');

          serializer.serializeIntoHash(data, type, snapshot);
          resolve(_this3.ajax(url, 'PUT', { data: data }));
        }).catch(function (reason) {
          _this3.get('mediavault').expireCredentials();
          reject(reason);
        });
      });
    },


    /**
     * Delete an asset
     *
     * Usage in route:
     * ```
     * result.destroyRecord()
     * .then((result) => {
     *   console.log('success', result);
     * });
     ```
     *
     * @return {object}          Object containing file (asset) id
     */
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this4.get('mediavault').getCredentials().then(function () {
          resolve(_this4.get('ajaxWrapper').ajaxDelete(assetsUrl + '/delete/' + snapshot.id, '', null, {
            headers: _this4.get('headers'),
            xhrFields: {
              withCredentials: true
            }
          }));
        }).catch(function (reason) {
          _this4.get('mediavault').expireCredentials();
          reject(reason);
        });
      });
    },


    /**
     * Find all assets
     *
     * Usage in route:
     * ```
     * this.store.findAll('asset')
     * .then((assets) => {
     *   assets.forEach(function(asset) {
     *     console.log(asset);
     *   });
     * });
     * ```
     *
     * @return {recordArray}            Array of asset record objects
     */
    findAll: function findAll(store, type) {
      var _this5 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this5.get('mediavault').getCredentials().then(function () {
          var url = _this5.buildURL(type.modelName, null, null, 'findAll');
          resolve(_this5.ajax(url + '/list', 'GET'));
        }).catch(function (reason) {
          _this5.get('mediavault').expireCredentials();
          reject(reason);
        });
      });
    },


    /**
     * Run query function to find assets
     *
     * Usage in route:
     * ```
     * this.store.query('asset', {
     *   'filter[mandatory][exact][tags]': ['ar50730', 'another-tag'],
     *   'filter[mandatory][exact][approval]': 'approved'
     * })
     * .then((assets) => {
     *   assets.forEach(function(asset) {
     *     console.log(asset);
     *   });
     * });
     * ```
     *
     * @return {recordArray}            Array of asset record objects
     */
    query: function query(store, type, _query) {
      var _this6 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this6.get('mediavault').getCredentials().then(function () {
          // Don't use the buildURL method because the namespace is different and
          // the endpoint is pluralized
          resolve(_this6.ajax(_this6.host + '/' + _this6.queryNamespace + '/assets/', 'GET', { data: _query }));
        }).catch(function (reason) {
          _this6.get('mediavault').expireCredentials();
          reject(reason);
        });
      });
    },


    /**
     * Run query function to find a single asset
     *
     * Usage in route:
     * ```
     * this.store.queryRecord('asset', {
     *   'filter[mandatory][exact][tags]': ['ar50730', 'lxheromedia'],
     *   'filter[mandatory][exact][approval]': 'approved'
     * })
     * .then((asset) => {
     *   console.log(asset);
     * });
     * ```
     *
     * @return {object}            Asset record object
     */
    queryRecord: function queryRecord(store, type, query) {
      var _this7 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this7.get('mediavault').getCredentials().then(function () {
          // Don't use the buildURL method because the namespace is different and
          // the endpoint is pluralized
          resolve(_this7.ajax(_this7.host + '/' + _this7.queryNamespace + '/assets/', 'GET', { data: query }));
        }).catch(function (reason) {
          _this7.get('mediavault').expireCredentials();
          reject(reason);
        });
      });
    }
  });
});