define('lxso/serializers/product-margin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    primaryKey: 'ticketTypeId',
    normalize: function normalize(typeClass, hash) {
      hash.ticketTypeId = hash.ticket_type_id;
      hash.ticketTypeName = hash.ticket_type_name;
      hash.marginOverride = hash.margin;
      return this._super.apply(this, arguments);
    }
  });
});