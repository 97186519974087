define('lxso/mixins/platform-settings/authentication', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    initAuthentication: function initAuthentication(model) {
      this.controller.set('platformSupplierBranches', model.platformSupplierBranches);
      this.controller.set('isAuthenticationOpen', true);
      this.controller.set('editingAuthentication', false);
    },

    actions: {
      editAuthenticationSupplierBranch: function editAuthenticationSupplierBranch(supplierBranch) {
        if (this.controller.get('isAnySectionEditing')) {
          this.controller.set('sectionToBeEdited', 'authentication');
          this.controller.set('authSuppBranchToBeEdited', supplierBranch);
        } else {
          supplierBranch.toggleProperty('isEditing');
          this.controller.set('authentSuppBranchUnderEdit', supplierBranch);
          this.controller.set('editingAuthentication', true);
        }
      },
      cancelAuthenticationSupplierBranch: function cancelAuthenticationSupplierBranch() {
        this.controller.get('authentSuppBranchUnderEdit').rollbackAttributes();
        this.controller.get('authentSuppBranchUnderEdit').set('isEditing', false);
        this.controller.set('editingAuthentication', false);
      },
      saveAuthenticationSupplierBranch: function saveAuthenticationSupplierBranch(supplierBranch) {
        supplierBranch.set('saving', true);
        var route = this;
        route.controller.set('lastSaved', 'authentication');
        supplierBranch.save({
          adapterOptions: {
            isConnectivityData: true
          }
        }).then(function () {
          supplierBranch.set('isEditing', false);
          route.controller.set('editingAuthentication', false);
          route.get('notifications').success(route.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (errors) {
          route.controller.set('errors', errors);
        }).finally(function () {
          supplierBranch.set('saving', false);
        });
      }
    }
  });
});