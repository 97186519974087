define('lxso/routes/change-password', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lxso/utils/form-validator'], function (exports, _authenticatedRouteMixin, _featureFlag, _requirePermission, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, {
    requiredPermission: 'V3InternalSettings',
    i18n: Ember.inject.service(),

    model: function model(params) {
      if (params.userId) {
        return this.store.findRecord('user', params.userId, { reload: true });
      }
    },
    setupController: function setupController(ctrl, model) {
      ctrl.set('user', model);
      this.resetUserPassword();
    },
    validateUser: function validateUser() {
      return (0, _formValidator.validate)({
        model: this.controller.get('user'),
        attributes: ['password', 'confirmPassword']
      });
    },
    resetUserPassword: function resetUserPassword() {
      this.controller.set('user.password', '');
      this.controller.set('user.confirmPassword', '');
    },
    resetController: function resetController() {
      this.controller.set('userValidationError', undefined);
      this.controller.set('userSaveServerError', undefined);
    },


    actions: {
      updateUserPasswordInfo: function updateUserPasswordInfo() {
        var _this = this;

        this.controller.set('userSaveServerError', undefined);
        this.validateUser().then(function () {
          _this.controller.set('userValidationError', undefined);
          _this.controller.set('isUpdatingUserData', true);
          _this.controller.user.save({ adapterOptions: { 'action': 'managePassword', 'section': 'basic' } }).then(function () {
            _this.get('notifications').success(_this.get('i18n').t('pages.userManagement.userInfo.saveInfo'));
            setTimeout(function () {
              _this.transitionTo('user', _this.controller.get('user.id'));
            }, 500);
          }).catch(function (saveError) {
            _this.controller.set('userSaveServerError', Object.assign({}, saveError));
          }).finally(function () {
            _this.controller.set('isUpdatingUserData', false);
          });
        }).catch(function (validationError) {
          _this.controller.set('userValidationError', validationError);
        });
      },
      cancelPasswordChange: function cancelPasswordChange() {
        this.transitionTo('user', this.controller.get('user.id'));
      }
    }
  });
});