define('lxso/components/platform-details/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    validateIfInvalid: function validateIfInvalid() {
      var errors = this.get('errors');
      if (errors && (errors.name || errors.supportDistributionList)) {
        this.sendAction('validatePlatformDetails');
      }
    },

    actions: {
      edit: function edit() {
        this.sendAction('edit');
      },
      savePlatformDetails: function savePlatformDetails() {
        this.sendAction('savePlatform', this.get('platform'));
      },
      cancel: function cancel() {
        if (this.get('platform.isNew')) {
          this.sendAction('transitionToPlatformList');
          this.get('platform').rollbackAttributes();
        } else {
          this.sendAction('cancelPlatformDetails');
        }
      },
      inputChanged: function inputChanged() {
        this.sendAction('setFormDirty');
        this.validateIfInvalid();
      },
      openStep: function openStep() {
        this.sendAction('openStep', 'isPlatformDetailsOpen');
      }
    }
  });
});