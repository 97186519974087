define('lxso/routes/manage-users', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lxso/config/environment', 'lxso/mixins/enum-getter'], function (exports, _authenticatedRouteMixin, _featureFlag, _requirePermission, _environment, _enumGetter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, _enumGetter.default, {
    requiredPermission: 'V3InternalSettings',
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    featureFlags: Ember.inject.service(),

    queryParams: {
      firstName: { refreshModel: false },
      lastName: { refreshModel: false },
      email: { refreshModel: false },
      supplier: { refreshModel: false },
      supplierBranch: { refreshModel: false }
    },

    model: function model(params) {
      var promiseHash = {};
      if (params.supplierBranch) {
        promiseHash.supplierBranch = this.store.findRecord('supplier-branch', params.supplierBranch);
      } else if (params.supplier) {
        promiseHash.supplier = this.store.findRecord('supplier', params.supplier);
      }
      return new Ember.RSVP.hash(promiseHash);
    },
    setupController: function setupController(controller, model) {
      var supplierBranchName = '';
      var supplierName = '';
      var searchBySupplierOrBranch = 'supplierBranch';
      if (model.supplierBranch) {
        supplierBranchName = model.supplierBranch.get('supplier_branch_name');
      } else if (model.supplier) {
        supplierName = model.supplier.get('name');
        searchBySupplierOrBranch = 'supplier';
      }

      var userTableHeaders = Ember.A([this.get('i18n').t('pages.manageUsers.userTable.firstName'), this.get('i18n').t('pages.manageUsers.userTable.lastName'), this.get('i18n').t('pages.manageUsers.userTable.emailAddress'), 'Role', 'Status', this.get('i18n').t('pages.manageUsers.userTable.action')]);

      controller.setProperties({
        userTableHeaders: userTableHeaders,
        userSearchResults: null,
        userTableKeys: Ember.A(['firstName', 'lastName', 'email', 'roles', 'status']),
        editingDetails: false,
        noResuls: '',
        isSearchEnabled: Ember.computed.or('firstName', 'lastName', 'email', 'supplier', 'supplierBranch'),
        paginationOptions: Ember.A(['20', '50', '100', 'Show All']),
        paginationValues: Ember.A([20, 50, 100, 'Show All']),
        itemsPerPage: 20,
        filter: {
          'status': 'ALL STATUS',
          'role': 'ALL ROLES'
        },
        searchBySupplierOrBranch: searchBySupplierOrBranch,
        supplierBranchName: supplierBranchName,
        supplierName: supplierName
      });
      this.initSupplierSearchTypeAhead(controller);
      this.initSupplierBranchSearchTypeAhead(controller);
      if (controller.get('firstName') || controller.get('lastName') || controller.get('email') || controller.get('supplier') || controller.get('supplierBranch')) {
        this._searchUsers();
      }
    },
    reloadUsers: function reloadUsers() {
      return this.refresh();
    },
    transformSupplierData: function transformSupplierData(inputData) {
      var outputData = {
        data: []
      };
      inputData.forEach(function (item) {
        if (item.status.toUpperCase() === 'ACTIVE' || item.status.toUpperCase() === 'INACTIVE') {
          outputData.data.push(item);
        }
      });
      return outputData;
    },
    transformSupplierBranchData: function transformSupplierBranchData(inputData) {
      var outputData = {
        data: []
      };

      outputData.data = inputData;
      return inputData;
    },
    initSupplierSearchTypeAhead: function initSupplierSearchTypeAhead(ctrl) {
      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
      var url = domain + '/suppliers/search?name=';
      ctrl.set('supplierSource', this.getEnumFunctionWithTransform(url, this.transformSupplierData));
    },
    initSupplierBranchSearchTypeAhead: function initSupplierBranchSearchTypeAhead(ctrl) {
      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
      var state = 'active,suspendedexpedia,suspendedpaused';
      var url = domain + '/supplier_branches/search_typeahead?state=' + state + '&name=';
      ctrl.set('supplierBranchSource', this.getEnumFunction(url));
    },
    setFilterStatusAndUserType: function setFilterStatusAndUserType(userList) {
      var status = ['ALL STATUS'];
      var roleTypes = ['ALL ROLES'];

      userList.forEach(function (userItem) {
        status.push(userItem.get('status').toUpperCase());
        var rolesList = userItem.get('roles').split(', ');
        if (rolesList.length) {
          rolesList.forEach(function (role) {
            roleTypes.push(role.toUpperCase());
          });
        }
      });

      this.controller.set('statusOptions', status.filter(function (value) {
        return value !== '';
      }).uniq());
      this.controller.set('roleTypeOptions', roleTypes.filter(function (value) {
        return value !== '';
      }).uniq());
    },
    _searchUsers: function _searchUsers() {
      var _this = this;

      var searchStrings = {
        firstName: this.controller.get('firstName'),
        lastName: this.controller.get('lastName'),
        email: this.controller.get('email'),
        supplier: this.controller.get('supplier'),
        supplierBranch: this.controller.get('supplierBranch')
      };
      this.controller.set('isSearching', true);
      this.store.query('user', { searchStrings: searchStrings }).then(function (results) {
        _this.setFilterStatusAndUserType(results);
        _this.resetUISearchFilter();
        _this.controller.set('userSearchResults', results);
        _this.controller.set('userSearchResultsCopy', results);
        if (results.get('length') === 0) {
          _this.controller.set('noResuls', [_this.get('i18n').t('pages.manageUsers.noResults')]);
        }
        _this.controller.set('errors', '');
      }).catch(function (err) {
        _this.controller.set('errors', [err]);
      }).finally(function () {
        _this.controller.set('isSearching', false);
      });
    },
    _filterData: function _filterData() {
      var statusFilter = this.controller.get('filter.status');
      var userTypeFilter = this.controller.get('filter.role');
      var userSearchResults = this.controller.get('userSearchResultsCopy');
      var filteredData = userSearchResults;

      if (statusFilter.toUpperCase() !== 'ALL STATUS' && userTypeFilter.toUpperCase() !== 'ALL ROLES') {
        filteredData = userSearchResults.filter(function (userData) {
          return userData.get('status').toUpperCase() === statusFilter.toUpperCase() && userData.get('roles').split(', ').map(function (roleName) {
            return roleName.toUpperCase();
          }).indexOf(userTypeFilter) >= 0;
        });
      } else if (statusFilter.toUpperCase() !== 'ALL STATUS') {
        filteredData = userSearchResults.filter(function (userData) {
          return userData.get('status').toLowerCase() === statusFilter.toLowerCase();
        });
      } else if (userTypeFilter.toUpperCase() !== 'ALL ROLES') {
        filteredData = userSearchResults.filter(function (userData) {
          return userData.get('roles').split(', ').map(function (roleName) {
            return roleName.toUpperCase();
          }).indexOf(userTypeFilter) >= 0;
        });
      }

      if (filteredData.length) {
        this.controller.set('userSearchResults', filteredData);
      } else {
        this.controller.set('userSearchResults', Ember.A([Ember.Object.create({ firstName: '--', lastName: '--', email: '--' })]));
      }
    },
    resetUISearchFilter: function resetUISearchFilter() {
      this.controller.set('filter', {
        'status': 'ALL STATUS',
        'role': 'ALL ROLES'
      });
    },


    actions: {
      searchUsers: function searchUsers() {
        this._searchUsers();
      },
      editUser: function editUser(id) {
        this.transitionTo('user', id);
      },
      filterAction: function filterAction(filterType, value) {
        switch (filterType.toUpperCase()) {
          case 'STATUS':
            this.controller.set('filter.status', value.toUpperCase());
            break;
          case 'ROLE':
            this.controller.set('filter.role', value.toUpperCase());
            break;
        }

        this._filterData();
      },
      deactivateUser: function deactivateUser(user) {
        this.controller.set('currentDeactivateUser', user);
        this.controller.set('deactivateUserModelOpen', true);
      },
      deactivateUserConfirmed: function deactivateUserConfirmed() {
        var _this2 = this;

        this.controller.set('isProcessingConfirmAction', true);
        var userToBeDeactivated = this.controller.get('currentDeactivateUser');
        userToBeDeactivated.set('status', 'INACTIVE');
        userToBeDeactivated.save({ adapterOptions: { 'action': 'statusChange' } }).then(function () {
          return _this2.reloadUsers();
        }).then(function () {
          _this2.send('closeModal');
        }).catch(function (deleteFailed) {
          _this2.controller.set('serverDeactivateError', deleteFailed);
          _this2.controller.get('currentDeactivateUser').rollbackAttributes();
        }).finally(function () {
          _this2.controller.set('isProcessingConfirmAction', false);
        });
      },
      closeModal: function closeModal() {
        this.controller.set('currentDeactivateUser', undefined);
        this.controller.set('userToBeActivated', undefined);
        this.controller.set('deactivateUserModelOpen', false);
        this.controller.set('activateUserModelOpen', false);
      },
      activateUser: function activateUser(user) {
        this.controller.set('userToBeActivated', user);
        this.controller.set('activateUserModelOpen', true);
      },
      reactivateUserConfirmed: function reactivateUserConfirmed() {
        var _this3 = this;

        this.controller.set('isProcessingReactivation', true);
        var userData = this.controller.get('userToBeActivated');
        userData.set('status', 'ACTIVE');
        userData.save({ adapterOptions: { 'action': 'statusChange' } }).then(function () {
          return _this3.reloadUsers();
        }).then(function () {
          _this3.send('closeModal');
        }).catch(function (deleteFailed) {
          _this3.controller.set('serverReactivateError', deleteFailed);
          _this3.controller.get('userToBeActivated').rollbackAttributes();
        }).finally(function () {
          _this3.controller.set('isProcessingReactivation', false);
        });
      },
      setSupplierSearch: function setSupplierSearch(data) {
        if (data && data.supplier_id) {
          this.controller.set('supplier', data.supplier_id);
        } else {
          this.controller.set('supplier', '');
        }
      },
      setSupplierBranchSearch: function setSupplierBranchSearch(data) {
        if (data && data.value) {
          this.controller.set('supplierBranch', data.value);
        } else {
          this.controller.set('supplierBranch', '');
        }
      },
      searchFilter: function searchFilter(filter) {
        this.controller.set('searchBy', filter);

        if (filter === 'supplier') {
          this.controller.set('supplierBranch', '');
          this.controller.set('supplierBranchName', '');
        } else {
          this.controller.set('supplier', '');
          this.controller.set('supplierName', '');
        }
      }
    }
  });
});