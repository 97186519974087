define('lxso/components/radio-button-group/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    radios: function () {
      // Breaks the comma-separated list of radio button labels into an array
      var inputs = this.get('radio-buttons').split(',');

      // Strips off any whitespace around labels
      for (var i = 0; i < inputs.length; i++) {
        inputs[i] = inputs[i].trim();
      }
      return inputs;
    }.property()
  });
});