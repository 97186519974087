define('lxso/utils/change-history-details-display-modes/price', ['exports', 'lxso/utils/change-history-details-display-modes/date', 'lxso/utils/change-history-details-display-modes/generic', 'lxso/utils/change-history-details-display-modes/price-with-currency', 'lxso/utils/change-history-details-display-modes/schedule-details', 'lodash'], function (exports, _date, _generic, _priceWithCurrency, _scheduleDetails, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var priceTypeTranslations = new Map([['PerTraveler', 'Per Person'], ['PerGroup', 'Total']]);

  exports.default = {
    partialName: 'price',
    diff: function diff(oldValue, newValue) {
      var noValue = { prices: {} };

      var oldData = oldValue ? JSON.parse(oldValue) : noValue;
      var newData = newValue ? JSON.parse(newValue) : noValue;

      function difference(displayMode, areDisplayable, value) {
        var newValue = value(newData);
        var oldValue = value(oldData);

        return areDisplayable(oldValue, newValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }

      function dateRangeDifference(dateRange) {
        return {
          start: difference(_date.default, present, function (data) {
            return dateRange(data) && dateRange(data).start;
          }),
          end: difference(_date.default, present, function (data) {
            return dateRange(data) && dateRange(data).end;
          })
        };
      }

      return {
        travel: dateRangeDifference(function (data) {
          return data.travel;
        }),
        booking: dateRangeDifference(function (data) {
          return data.booking;
        }),
        ticketType: difference(_generic.default, present, function (data) {
          return data.ticket_type;
        }),
        basePrice: difference(_priceWithCurrency.default, notEqual, function (data) {
          return data.prices.base;
        }),
        netPrice: difference(_priceWithCurrency.default, notEqual, function (data) {
          return data.prices.net;
        }),
        marketingPrice: difference(_priceWithCurrency.default, notEqual, function (data) {
          return data.prices.marketing;
        }),
        priceType: difference(_generic.default, present, function (data) {
          if (priceTypeTranslations.get(data.prices.priceType)) {
            return priceTypeTranslations.get(data.prices.priceType);
          } else {
            return data.prices.priceType;
          }
        }),
        minimumTravelers: difference(_generic.default, present, function (data) {
          return data.prices.minimumTravelers;
        }),
        maximumTravelers: difference(_generic.default, present, function (data) {
          return data.prices.maximumTravelers;
        }),
        priceCode: difference(_generic.default, notEqual, function (data) {
          return data.price_code;
        }),
        schedule: difference(_scheduleDetails.default, notEqual, function (data) {
          return data.schedule;
        })
      };
    }
  };


  function present(oldValue, newValue) {
    return Ember.isPresent(oldValue) || Ember.isPresent(newValue);
  }

  function notEqual(oldValue, newValue) {
    return present(oldValue, newValue) && !_lodash.default.isEqual(oldValue, newValue);
  }
});