define('lxso/routes/bulk-update', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission'], function (exports, _authenticatedRouteMixin, _featureFlag, _requirePermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _featureFlag.default, _requirePermission.default, {
    featureName: 'BulkPriceExtension',
    requiredPermission: 'V3InternalSettings',
    i18n: Ember.inject.service(),
    responseErrors: Ember.inject.service(),
    featureFlags: Ember.inject.service(),

    setupController: function setupController(controller) {
      var self = this;
      var i18n = self.get('i18n');
      var priceExtensionTabValueStr = i18n.t('pages.bulkUpdate.priceExtension.heading');
      var thirdPartyTabValueStr = i18n.t('pages.bulkUpdate.thirdParty.heading');
      var directProductStatusTabValueStr = i18n.t('pages.bulkUpdate.directProductStatus.heading');
      var bulkCancellationMultipleSupplierTabValueStr = i18n.t('pages.bulkUpdate.bulkCancellationMultipleSupplier.heading');
      controller.setProperties({
        tabs: Ember.computed(function () {
          var isBulkUpdateForDirectProductsEnabled = self.get('featureFlags').checkEnabled('BulkStatusUpdateDirectProducts');

          var isMultipleSupplierBulkCancellationEnabled = self.get('featureFlags').checkEnabled('MultipleSupplierBulkCancellation');
          var tabs = Ember.A([priceExtensionTabValueStr, thirdPartyTabValueStr]);
          if (isBulkUpdateForDirectProductsEnabled) {
            tabs.push(directProductStatusTabValueStr);
          }

          if (isMultipleSupplierBulkCancellationEnabled) {
            tabs.push(bulkCancellationMultipleSupplierTabValueStr);
          }
          return tabs;
        }),
        selectedTabValue: priceExtensionTabValueStr,
        priceExtensionTabValue: priceExtensionTabValueStr,
        thirdPartyTabValue: thirdPartyTabValueStr,
        directProductStatusTabValue: directProductStatusTabValueStr,
        bulkCancellationMultipleSupplierTabValue: bulkCancellationMultipleSupplierTabValueStr
      });
    },


    actions: {
      selectTab: function selectTab(selectedTab) {
        this.get('responseErrors').clearErrors();
        this.controller.set('selectedTabValue', selectedTab);
      },
      setSortParams: function setSortParams(sortParam, sortAscending) {
        this.set('sortParam', sortParam);
        this.set('sortAscending', sortAscending);
      }
    }

  });
});