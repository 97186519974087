define('lxso/components/schedule-template/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dateFormat: Ember.inject.service('date-format'),

    checkIfErrorsAndValidate: function checkIfErrorsAndValidate() {
      this.sendAction('scheduleTemplateChange');
      if (this.get('errors') && !this.get('schedule.isNew')) {
        this.sendAction('validateSchedule', this.get('schedule'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('schedule.isNew')) {
        this.set('previouslySaved', true);
      }
      this._super.apply(this, arguments);
    },
    getApiFormat: function getApiFormat(dateValue) {
      var pickerFormat = this.get('dateFormat.DATEPICKER_FORMAT');
      var apiFormat = this.get('dateFormat.API_DATE_FORMAT');
      return moment(dateValue, [apiFormat, pickerFormat]).format(apiFormat);
    },
    getDatePickerFormat: function getDatePickerFormat(dateValue) {
      var pickerFormat = this.get('dateFormat.DATEPICKER_FORMAT');
      var apiFormat = this.get('dateFormat.API_DATE_FORMAT');
      return moment(dateValue, [apiFormat, pickerFormat]).format(pickerFormat);
    },


    startDate: Ember.computed('schedule.travel_start_date', {
      get: function get(key) {
        var travelStartDate = this.get('schedule.travel_start_date');
        return travelStartDate ? this.getDatePickerFormat(travelStartDate) : '';
      },
      set: function set(key, value) {
        if (value) {
          this.set('schedule.travel_start_date', this.getApiFormat(value));
        }
        return value;
      }
    }),

    endDate: Ember.computed('schedule.travel_end_date', {
      get: function get(key) {
        var travelEndDate = this.get('schedule.travel_end_date');
        return travelEndDate ? this.getDatePickerFormat(travelEndDate) : '';
      },
      set: function set(key, value) {
        if (value) {
          this.set('schedule.travel_end_date', this.getApiFormat(value));
        }
        return value;
      }
    }),

    actions: {
      saveSchedule: function saveSchedule() {
        this.sendAction('saveSchedule', this.get('schedule'));
      },
      updateFromDate: function updateFromDate(date) {
        this.set('startDate', date);
        if (date && !this.get('endDate')) {
          this.set('endDate', date);
        }
        this.checkIfErrorsAndValidate();
      },
      updateToDate: function updateToDate(date) {
        this.set('endDate', date);
        if (date && !this.get('startDate')) {
          this.set('startDate', date);
        }
        this.checkIfErrorsAndValidate();
      },
      updateDaysOfWeek: function updateDaysOfWeek(checked, index) {
        var currentValue = Ember.copy(this.get('schedule.day_of_week'));

        if (this.get('errors')) {
          this.set('errors.day_of_week', []);
        }

        currentValue[index] = checked === 0 ? 1 : 0;
        this.get('schedule').set('day_of_week', currentValue);
        this.checkIfErrorsAndValidate();
      },
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      },
      openSelectScheduleModal: function openSelectScheduleModal(scheduleTemplateId) {
        this.sendAction('openSelectScheduleModal', scheduleTemplateId);
      }
    }
  });
});