define('lxso/services/last-updates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var changeBy = function changeBy(attribute) {
    return function (change) {
      return change.get('changeDetail.attribute') === attribute;
    };
  };

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    find: function find(_ref) {
      var activity = _ref.activity,
          offer = _ref.offer,
          supplierBranch = _ref.supplierBranch,
          attribute = _ref.attribute;

      var query = this.query(activity, offer, supplierBranch);

      if (query) {
        return this.get('store').query('change-history', query).then(function (changes) {
          return changes.find(changeBy(attribute));
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    findInventories: function findInventories(_ref2) {
      var activity = _ref2.activity,
          offer = _ref2.offer,
          supplierBranch = _ref2.supplierBranch,
          attribute = _ref2.attribute;

      var query = this.query(activity, offer, supplierBranch);
      if (query) {
        return this.get('store').query('change-history', query).then(function (changes) {
          var changedItemDaily = changes.find(changeBy('Offer.LimitedInventoryDaily'));
          var changedNegativeAvailability = changes.find(changeBy('Offer.NegativeAvailability'));
          if (changedItemDaily && changedNegativeAvailability) {
            return changedItemDaily.get('occurredAt') > changedNegativeAvailability.get('occurredAt') ? changedItemDaily : changedNegativeAvailability;
          } else if (changedItemDaily) {
            return changedItemDaily;
          } else if (changedNegativeAvailability) {
            return changedNegativeAvailability;
          }
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    query: function query(activity, offer, supplierBranch) {
      var supplierBranchIdFromSession = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
      if (!(activity || supplierBranch || supplierBranchIdFromSession)) {
        return;
      }

      var query = void 0;
      if (activity && (typeof activity === 'undefined' ? 'undefined' : _typeof(activity)) == 'object') {
        query = {
          id: activity.get('supplier_branch.supplier_branch_id'),
          onlyLastChanges: true
        };
      } else if (supplierBranch) {
        query = {
          id: supplierBranch.get('id'),
          onlyLastChanges: true
        };
      } else {
        query = {
          id: supplierBranchIdFromSession,
          onlyLastChanges: true
        };
      }

      if (offer) {
        if (Ember.isPresent(offer.id)) {
          return Object.assign(query, { offerId: offer.id });
        } else if (Ember.isPresent(offer.offerId)) {
          return Object.assign(query, { offerId: offer.offerId });
        } else if (typeof offer == 'string') {
          return Object.assign(query, { offerId: offer });
        }
      } else if (activity) {
        if (Ember.isPresent(activity.id)) {
          return Object.assign(query, { activityId: activity.id });
        } else if (typeof activity == 'string') {
          return Object.assign(query, { activityId: activity });
        }
      } else {
        return query;
      }
    }
  });
});