define('lxso/helpers/ss-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ssDate = ssDate;
  function ssDate(params) {
    return params;
  }

  exports.default = Ember.Helper.helper(ssDate);
});