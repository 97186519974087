define('lxso/components/last-updated/component', ['exports', 'lxso/components/last-updated/link-data'], function (exports, _linkData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'last-updated',

    lastUpdates: Ember.inject.service(),
    dateFormat: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var criteria = this.getProperties('activity', 'offer', 'supplierBranch', 'attribute');
      if (this.get('isForLimitedInventories')) {
        this.get('lastUpdates').findInventories(criteria).then(function (lastUpdate) {
          _this.set('lastUpdate', lastUpdate);
        });
      } else {
        this.get('lastUpdates').find(criteria).then(function (lastUpdate) {
          _this.set('lastUpdate', lastUpdate);
        });
      }
    },


    link: Ember.computed('lastUpdate', function () {
      var changeItem = this.get('lastUpdate');
      var dateFormat = this.get('dateFormat.URL_DATE_FORMAT');
      return (0, _linkData.default)(changeItem, dateFormat);
    }),

    username: Ember.computed.alias('lastUpdate.madeBy'),

    timestamp: Ember.computed('lastUpdate.occurredAt', function () {
      var occurredAt = this.get('lastUpdate.occurredAt');
      var dateFormat = this.get('dateFormat');
      return dateFormat.asLongDateWithTimezone(occurredAt);
    }),

    visibleActivity: Ember.computed.not('activity.viewOnly'),
    visible: Ember.computed.or('visibleActivity', 'supplierBranch'),
    exist: Ember.computed.bool('lastUpdate'),
    enabled: Ember.computed.and('visible', 'exist')
  });
});