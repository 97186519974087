define('lxso/models/price-template-detail', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    price_template_detail: _emberData.default.belongsTo('price-template'),
    price_template_detail_id: _emberData.default.attr('number'),
    price_template_id: _emberData.default.attr('number'),
    price_type: (0, _attributes.fragment)('price-template/price-template-detail/price-type'),
    //convert to number before putting into model
    base: _emberData.default.attr('number'),
    min: _emberData.default.attr('number'),
    max: _emberData.default.attr('number'),
    net: _emberData.default.attr('number', { defaultValue: 0 }),
    suggested: _emberData.default.attr('number'),
    reference: _emberData.default.attr('number'),
    vendor_sku: _emberData.default.attr('string', { defaultValue: ' -- ' }),
    status: _emberData.default.attr('string'),
    ticket_type: (0, _attributes.fragment)('price-template/price-template-detail/ticket-type'),
    last_updated_date: _emberData.default.attr('string'),
    create_date: _emberData.default.attr('string')
  });
});