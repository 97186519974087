define('lxso/components/supplier-settings/component', ['exports', 'lxso/utils/form-validator', 'lxso/utils/omniture', 'lxso/utils/auto-renew-rate'], function (exports, _formValidator, _omniture, _autoRenewRate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    featureFlags: Ember.inject.service(),

    validateSupplierSettings: function validateSupplierSettings() {
      return (0, _formValidator.validate)({
        model: this.get('supplierDetails'),
        attributes: ['name', 'status']
      });
    },
    validateSupplierSettingsIfInvalid: function validateSupplierSettingsIfInvalid() {
      var _this = this;

      if (this.get('errors')) {
        this.validateSupplierSettings().then(function () {
          _this.set('errors', []);
        }).catch(function (errors) {
          _this.set('errors', errors);
        });
      }
    },


    isSuspendedPausedAndExpediaFlagEnabled: Ember.computed('featureFlags', function () {
      return this.get('featureFlags').checkEnabled('SuspendedPausedAndExpedia');
    }),

    statusOptions: Ember.computed('isSuspendedPausedAndExpediaFlagEnabled', function () {
      return this.get('isSuspendedPausedAndExpediaFlagEnabled') ? Ember.A(['Active', 'Inactive', 'Suspended - Expedia', 'Suspended - Paused']) : Ember.A(['Active', 'Inactive']);
    }),

    statusValues: Ember.computed('isSuspendedPausedAndExpediaFlagEnabled', function () {
      return this.get('isSuspendedPausedAndExpediaFlagEnabled') ? Ember.A(['Active', 'Inactive', 'SuspendedExpedia', 'SuspendedPaused']) : Ember.A(['Active', 'Inactive']);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      (0, _autoRenewRate.setupAutoRenewRateDropdown)(this);
    },


    actions: {
      retryVendorId: function retryVendorId(supplierDetails) {
        this.sendAction('retryVendorId', supplierDetails);
      },
      editSupplierSettings: function editSupplierSettings() {
        this.sendAction('editSupplierSection');
      },
      supplierAutoRenewRateSelected: function supplierAutoRenewRateSelected(isAutoRenewRateEnabled) {
        var booleanAutoRenewRate = isAutoRenewRateEnabled == 'true';
        this.sendAction('supplierAutoRenewRateSelected', booleanAutoRenewRate);
        this.validateSupplierSettingsIfInvalid();
      },
      supplierStatusSelected: function supplierStatusSelected(status) {
        this.sendAction('supplierStatusSelected', status);
        this.validateSupplierSettingsIfInvalid();
      },
      saveSupplierSettings: function saveSupplierSettings() {
        var _this2 = this;

        this.validateSupplierSettings().then(function () {
          _this2.set('validationError', undefined);
          _this2.sendAction('saveSupplierSettings');
        }).catch(function (err) {
          _this2.set('validationError', err);
        });
      },
      cancelEditSupplierSettings: function cancelEditSupplierSettings() {
        this.set('validationError', undefined);
        this.sendAction('cancelSupplierSettingsUpdate');
      }
    }
  });
});