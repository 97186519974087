define('lxso/router', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,

    userMigration: Ember.inject.service(),
    nameInHeader: Ember.inject.service(),

    didTransition: function didTransition(route) {
      var superResult = this._super.apply(this, arguments);
      this.get('nameInHeader').setNeededFor(route);

      if (this.get('userMigration').isNeeded() || this.get('userMigration').isNewUser()) {
        this.transitionTo('account-settings.change-password');
      } else {
        return superResult;
      }
    }
  });

  Router.reopen({
    location: 'hash'
  });

  Router.map(function () {
    this.route('account-settings', function () {
      this.route('change-password');
    });
    this.route('login');
    this.route('oidc-auth', {});
    this.route('logout');
    // PLACEHOLDER: referenced on products.jsp
    this.route('createactivity');
    // PLACEHOLDER: referenced on products.jsp
    this.route('edit_activity', { path: 'edit_activity/:id' });
    this.route('show_live_activity', { path: 'show_live_activity/:id' });
    this.route('activity', { path: 'activity/:id' }, function () {
      this.route('overview');
      this.route('details');
      this.route('activity-settings');
      this.route('option-settings');
      this.route('option-group-settings');
      this.route('gt-settings');
      this.route('location');
      this.route('photos', function () {
        this.route('staging');
      });
      this.route('customer-service', function () {
        this.route('select-contact');
        this.route('customer-contact-point');
      });
      this.route('tour-options', { path: 'availability-and-pricing' });
      this.route('options-example'); // REMOVE
      this.route('publish');
      this.route('offer', { path: 'offer/:offerId' });
      this.route('offer-list', function () {
        this.route('deactivate', { path: 'deactivate/:optionId' });
      });
      this.route('loading');
      this.route('health-and-safety');
      this.route('option-group', function () {
        this.route('loading');
        this.route('group', { path: '/:optionGroupId' });
      });
    });
    this.route('product-setup');
    this.route('products', { path: 'products/:id' });
    this.route('manage-availability', { path: 'manage-availability/:supplierId' }, function () {
      this.route('limited-inventory', { path: 'limited-inventory/:optionId' });
    });
    this.route('booking-questions', { path: 'booking-questions/:id' });
    this.route('booking-questions-v4', { path: 'booking-question/:id' });
    this.route('booking-questions-list', { path: 'booking-questions-list/:branchId' });
    this.route('report', { path: 'report/:supplierId' });
    this.route('cancellation', { path: 'cancellation/:supplierId' });
    this.route('photo-audit', { path: 'audit/:id' });
    this.route('reviews', { path: 'reviews/:feature/:id' });
    this.route('loading');
    this.route('select-contact', {});
    this.route('branch-selection', {});
    this.route('change-history', { path: 'change-history/:supplierBranchId' });
    this.route('route-not-found', { path: '/*path' }); // Fallback route if no routes found client-side
    this.route('redirect');
    this.route('tagging-upload');
    this.route('supplier-branch-settings', { path: 'supplier-branch-settings/:id' });
    this.route('confirmation', { path: 'confirmation/:id' });
    this.route('platform-list');
    this.route('platform', { path: 'platform/:platformId' });
    this.route('new-supplier-branch');
    this.route('supplier', { path: 'supplier/:id' });
    this.route('hotel-zone-list', { path: 'hotel-zone-list/:supplierBranchId' });
    this.route('hotel-zone', { path: 'hotel-zone/:zoneId' });
    this.route('user', { path: 'user/:userId' });
    this.route('manage-users');
    this.route('change-password', { path: 'change-password/:userId' });
    this.route('promotion-list', { path: 'promotion-list/:id' });
    this.route('promotion', { path: 'promotion/:promotionId' });
    this.route('promotion-bulk-creation', { path: 'bulkpromotion' });
    this.route('campaign-list', { path: 'campaign-list/:branchId' });
    this.route('bulk-update');
    return this;
  });

  exports.default = Router;
});