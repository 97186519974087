define('lxso/components/price-group-template/component', ['exports', 'lxso/mixins/error-list-indexes-helpers', 'lxso/utils/validation'], function (exports, _errorListIndexesHelpers, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorListIndexesHelpers.default, {

    store: Ember.inject.service(),

    detailsWithMargins: Ember.computed('margins', 'priceTemplateDetails', function () {
      var margins = new Map(this.get('margins').map(function (margin) {
        return [margin.get('ticketTypeId'), margin.get('marginOverride')];
      }));

      return this.get('priceTemplateDetails').map(function (detail) {
        return {
          priceTemplateDetail: detail,
          margin: margins.get(+detail.ticket_type.id)
        };
      });
    }),

    priceTemplateDetails: Ember.computed('previouslySaved', 'newPriceTemplateDetails', 'existingPriceTemplateDetails', function () {
      var newPriceTemplateDetails = this.get('newPriceTemplateDetails');
      var existingPriceTemplateDetails = this.get('existingPriceTemplateDetails');

      return this.get('previouslySaved') ? existingPriceTemplateDetails : newPriceTemplateDetails;
    }),

    previouslySaved: Ember.computed.not('priceTemplate.isNew'),

    newPriceTemplateDetails: Ember.computed('ticketTypes', function () {
      return this.get('ticketTypes').map(function (ticketType) {
        return {
          price_type_id: 1, //standard price type (look in SELECT * FROM PriceType;)
          vendor_sku: ' -- ',
          base: '',
          net: 0,
          ticket_type: ticketType,
          ticket_type_id: parseInt(ticketType.id),
          min: 0,
          max: 0,
          suggested: 0,
          reference: ''
        };
      });
    }),

    existingPriceTemplateDetails: Ember.computed.alias('priceTemplate.price_template_details'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('previouslySaved') && this.get('validatePriceTrigger')) {
        Ember.run.later(this, function () {
          return _this.sendAction('validatePrice', _this.buildPriceTemplate());
        });
      }
    },
    focusOut: function focusOut() {
      if (this.get('errors')) {
        this.sendAction('validatePrice', this.buildPriceTemplate());
      }
      this._super.apply(this, arguments);
    },
    validateZero: function validateZero(input) {
      if (input === '') {
        return 0;
      } else if (isNaN(input)) {
        return input;
      } else {
        return parseFloat(input);
      }
    },
    buildPriceTemplate: function buildPriceTemplate() {
      var _this2 = this;

      //Make a copy of what we already have and use it to build a new version
      //If we don't change the array in a specific way, Ember won't realize it changed
      //and won't run validation on the model
      //Seems to need map instead of forEach with setProperties
      var existingPriceTemplateDetails = this.get('priceTemplateDetails');

      //build a new array using what's already there (in the form)
      var newPriceTemplateDetails = existingPriceTemplateDetails.map(function (detail) {
        var _Ember$getProperties = Ember.getProperties(detail, 'base', 'net', 'min', 'max', 'reference'),
            base = _Ember$getProperties.base,
            net = _Ember$getProperties.net,
            min = _Ember$getProperties.min,
            max = _Ember$getProperties.max,
            reference = _Ember$getProperties.reference;

        Ember.setProperties(detail, {
          base: _this2.validateZero(base),
          net: parseFloat(net),
          min: parseFloat(min),
          max: parseFloat(max),
          reference: _this2.validateZero(reference)
        });

        return detail;
      });

      var priceTemplate = this.get('priceTemplate');
      priceTemplate.setProperties({
        name: 'PriceName',
        price_template_details: newPriceTemplateDetails
      });

      return priceTemplate;
    },


    actions: {
      sendPriceTemplate: function sendPriceTemplate() {
        //savePrice is in mixins/pricing-group.js
        this.sendAction('savePrice', this.buildPriceTemplate());
      },
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      },
      openSelectPriceModal: function openSelectPriceModal(priceTemplateId) {
        this.sendAction('openSelectPriceModal', priceTemplateId);
      }
    }
  });
});