define('lxso/transforms/date-string', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({

    // 2017-08-23T00:00:00.000Z -> 08/23/2017
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return moment.utc(new Date(serialized)).format('MM/DD/YYYY');
      }
    },


    // 06/22/2017 -> 2017-06-22T00:00:00-07:00
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return moment(new Date(deserialized)).format();
      }
    }
  });
});