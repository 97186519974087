define('lxso/components/multi-select-dropdown-v2/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      'use strict';

      this._super.apply(this, arguments);
      this.setProperties({
        selectedItems: Ember.computed('selectedIDs.[]', function () {
          var selectedIDs = this.get('selectedIDs');
          return this.get('items').filter(function (item) {
            return selectedIDs.includes(item.id);
          });
        })
      });
    },


    actions: {
      handleItemSelect: function handleItemSelect(id) {
        this.sendAction('onItemSelect', id);
      }
    }
  });
});