define('lxso/models/fragments/option-group/booking-cutoff', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    bookingCutoffInHours: {
      descriptionKey: 'pages.optionGroup.optionGroupSection.details.bookingCutoff.cutoffHours',
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('number', {
        gte: 0,
        allowString: true,
        messageKey: 'errors.customMessages.offers.mustBeNumber'
      }), (0, _validation.validator)('option-group-booking-cutoff', {
        volatile: true,
        messageKey: 'pages.optionGroup.optionGroupSection.details.validationMessages.bookingCutoff'
      })]
    },

    bookingCutoffInHoursNew: {
      descriptionKey: 'pages.optionGroup.optionGroupSection.details.bookingCutoff.cutoffHours',
      validators: [(0, _validation.validator)('new-option-group-booking-cutoff', {
        volatile: true
      })]
    }
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    cutoffType: _emberData.default.attr('string'),
    bookingCutoffInHours: _emberData.default.attr('number')
  });
});