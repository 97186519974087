define('lxso/components/option-group/price-group/component', ['exports', 'lxso/utils/form-validator', 'lxso/utils/array-values', 'lxso/mixins/example-modal', 'lxso/constants/price-types', 'lxso/constants/option-group', 'lxso/utils/to-percent-without-percent-sign', 'lxso/utils/offer-group'], function (exports, _formValidator, _arrayValues, _exampleModal, _priceTypes, _optionGroup, _toPercentWithoutPercentSign, _offerGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var MAX_TICKETS_NUM = 8;
  var DEC_PLACES = 3;

  exports.default = Ember.Component.extend(_exampleModal.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    netPrice: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['option-group-price-group-v4'],
    isAllCapacityTicketTypesSelected: false,
    editingPriceGroup: Ember.computed('priceGroupIndex', 'editingPriceGroupIndex', function () {
      return this.get('priceGroupIndex') === this.get('editingPriceGroupIndex');
    }),
    currency: Ember.computed(function () {
      return this.get('session.data.authenticated.selectedBranch.currency_type');
    }),
    selectedTicketTypesForPrice: Ember.computed('ticketTypesForPrice.@each.isSelected', function () {
      return this.get('ticketTypesForPrice').filter(function (tt) {
        return tt.get('isSelected');
      });
    }),
    firstValidFromDate: Ember.computed(function () {
      var firstValidDate = moment().add(1, 'days').format('MM/DD/YYYY');
      return firstValidDate;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('PRICE_TYPES', _priceTypes.PRICE_TYPES);
      var minTicketOptions = Ember.A((0, _arrayValues.getArrayOfValues)(MAX_TICKETS_NUM));
      this.set('minTicketOptions', minTicketOptions);
      this.set('MAX_TICKETS_NUM', MAX_TICKETS_NUM);
      this.initTicketTypesForPricing();
      this.initAvailability();
    },
    initAvailability: function initAvailability() {
      this.set('isAvailabilityByDays', !(this.get('priceGroup.availabilityByOffers') && this.get('priceGroup.availabilityByOffers.length') > 0));
    },
    initTicketTypesForPricing: function initTicketTypesForPricing() {
      var _this = this;

      var ticketTypesForPrice = this.get('availableTicketTypes').map(function (tt) {
        return Ember.Object.create({
          baseLabel: tt.get('baseLabel'),
          name: tt.get('name'),
          ticketTypeId: tt.get('ticketTypeId'),
          isSelected: !!_this.get('priceGroup.prices').find(function (p) {
            return p.get('ticketTypeId') === tt.get('ticketTypeId');
          })
        });
      });
      this.set('ticketTypesForPrice', ticketTypesForPrice);
    },
    setIsAllCapacityTicketTypesSelectedFlag: function setIsAllCapacityTicketTypesSelectedFlag() {
      var numberOfCapacityTTWithPrice = this.get('priceGroup.prices').filter(function (p) {
        return p.get('isSelected');
      }).map(function (p) {
        return p.get('ticketTypeId');
      }).uniq().length;
      var numberOfAvailableCapacityTTs = this.get('availableCapacityTicketTypes').filter(function (tt) {
        return tt.get('ticketTypeId') > 0;
      }).length;
      this.set('isAllCapacityTicketTypesSelected', numberOfCapacityTTWithPrice === numberOfAvailableCapacityTTs);
    },
    validatePriceGroups: function validatePriceGroups(priceGroup) {
      var priceGroupValidationAttrs = ['travelStart', 'travelEnd', 'bookingStart', 'bookingEnd', 'prices'];
      if (priceGroup.get('availabilityByDays.availabilities.length')) {
        priceGroupValidationAttrs.push('availabilityByDays');
      } else {
        priceGroupValidationAttrs.push('availabilityByOffers');
      }
      var priceGroupValidations = (0, _formValidator.validate)({ model: priceGroup,
        attributes: priceGroupValidationAttrs });
      var pricesValidations = priceGroup.get('prices').map(function (price) {
        return (0, _formValidator.validate)({ model: price,
          attributes: ['basePrice', 'netPrice', 'margin', 'minimumTickets', 'priceCode'] });
      });

      var availabilityByOffersAttrs = ['days'];
      var availabilityByDaysAttrs = [];
      if (this.get('optionGroup.attributes.availabilityType') === _optionGroup.AVAILABILITY_TYPES.LIMITED) {
        if (this.get('isAvailabilityByDays')) {
          availabilityByDaysAttrs.push('capacity');
        } else {
          availabilityByOffersAttrs.push('capacity');
        }
      }
      if (_optionGroup.OPERATING_HOURS_BASED_SCHEDULES.includes(this.get('optionGroup.attributes.scheduleType'))) {
        if (this.get('isAvailabilityByDays')) {
          availabilityByDaysAttrs.push('startTime', 'endTime', 'timeRange');
        } else {
          availabilityByOffersAttrs.push('startTime', 'endTime', 'timeRange');
        }
      }

      var availabilityByDaysValidations = priceGroup.get('availabilityByDays.availabilities.length') ? priceGroup.get('availabilityByDays.availabilities').map(function (availabilityDay) {
        return (0, _formValidator.validate)({ model: availabilityDay, attributes: availabilityByDaysAttrs });
      }) : [];

      var availabilityByOffersValidations = priceGroup.get('availabilityByOffers.length') ? priceGroup.get('availabilityByOffers').map(function (availabilityByOffer) {
        return (0, _formValidator.validate)({ model: availabilityByOffer, attributes: availabilityByOffersAttrs });
      }) : [];

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.RSVP.allSettled([priceGroupValidations]).then(function (priceGroupDatesValidationResponse) {
          Ember.RSVP.allSettled(pricesValidations).then(function (pricesErrors) {
            Ember.RSVP.allSettled(availabilityByDaysValidations).then(function (availabilityByDaysErrors) {
              Ember.RSVP.allSettled(availabilityByOffersValidations).then(function (availabilityByOffersErrors) {
                var priceGroupErrors = (0, _offerGroup.buildValidationArray)(priceGroupDatesValidationResponse);
                var allValidationErrors = {
                  priceGroupErrors: priceGroupErrors,
                  pricesErrors: pricesErrors,
                  availabilityByDaysErrors: availabilityByDaysErrors,
                  availabilityByOffersErrors: availabilityByOffersErrors
                };

                if ((0, _offerGroup.anyRejects)(priceGroupDatesValidationResponse) || (0, _offerGroup.anyRejects)(pricesErrors) || (0, _offerGroup.anyRejects)(availabilityByDaysErrors) || (0, _offerGroup.anyRejects)(availabilityByOffersErrors)) {
                  reject(allValidationErrors);
                } else {
                  resolve();
                }
              });
            });
          });
        });
      });
    },
    _validateIfInvalid: function _validateIfInvalid() {
      var _this2 = this;

      if (this.get('errors')) {
        this.validatePriceGroups(this.get('priceGroup')).then(function () {
          _this2.set('errors', null);
        }).catch(function (errors) {
          _this2.set('errors', errors);
        });
      }
    },
    addOfferAvailabilitiesWithoutCapacity: function addOfferAvailabilitiesWithoutCapacity() {
      var _this3 = this;

      var offerIdsWithCapacity = this.get('priceGroup.availabilityByOffers').map(function (availRow) {
        return availRow.get('offerId');
      }).uniq();
      var missingOfferIds = this.get('allOffers').filter(function (offer) {
        return !offerIdsWithCapacity.includes(offer.get('offerId'));
      }).map(function (offer) {
        return offer.get('offerId');
      });
      missingOfferIds.forEach(function (offerId) {
        _this3.get('priceGroup.availabilityByOffers').createFragment({
          isSelected: false,
          available: false,
          days: [],
          offerId: offerId,
          capacity: 0,
          startTime: '6:00',
          endTime: '18:00'
        });
      });
      this.set('isAllAvailabilityByOffersChecked', this.get('priceGroup.availabilityByOffers').every(function (availabilityByOffer) {
        return availabilityByOffer.get('isSelected');
      }));
    },

    actions: {
      validateIfInvalid: function validateIfInvalid() {
        this._validateIfInvalid();
      },
      editPriceGroup: function editPriceGroup() {
        this.sendAction('editPriceGroup');
        if (!this.get('isAvailabilityByDays')) {
          this.addOfferAvailabilitiesWithoutCapacity();
        }
        if (this.get('priceType') === _priceTypes.PRICE_TYPES.CAPACITY) {
          this.setIsAllCapacityTicketTypesSelectedFlag();
        }
      },
      clonePriceGroup: function clonePriceGroup() {
        this.sendAction('clonePriceGroup');
      },
      openDeactivatePriceGroup: function openDeactivatePriceGroup() {
        this.sendAction('openDeactivatePriceGroup');
      },
      updateTravelFromDate: function updateTravelFromDate(priceGroup, date) {
        priceGroup.set('travelStart', date);
        this._validateIfInvalid();
      },
      updateTravelToDate: function updateTravelToDate(priceGroup, date) {
        priceGroup.set('travelEnd', date);
        this._validateIfInvalid();
      },
      priceVaryByBookingDate: function priceVaryByBookingDate(priceGroup) {
        var isBookingDatesDifferent = priceGroup.get('bookingDatesDifferent');
        if (!isBookingDatesDifferent) {
          priceGroup.set('bookingStart', null);
          priceGroup.set('bookingEnd', null);
        }
      },
      updateBookingFromDate: function updateBookingFromDate(priceGroup, date) {
        priceGroup.set('bookingStart', date);
        this._validateIfInvalid();
      },
      updateBookingToDate: function updateBookingToDate(priceGroup, date) {
        priceGroup.set('bookingEnd', date);
        this._validateIfInvalid();
      },
      handleSelectedTicketTypeChange: function handleSelectedTicketTypeChange(ticketType) {
        var _this4 = this;

        if (ticketType.get('isSelected')) {
          this.send('handleAddPrice', ticketType);
        } else {
          this.get('priceGroup.prices').filter(function (price) {
            return price.get('ticketTypeId') === ticketType.get('ticketTypeId');
          }).forEach(function (p) {
            return _this4.get('priceGroup.prices').removeFragment(p);
          });
          this.get('priceGroup').notifyPropertyChange('prices');
        }
      },
      handleAddPrice: function handleAddPrice(ticketType) {
        var margin = (0, _toPercentWithoutPercentSign.default)(this.get('netPrice').getMargin(ticketType.get('ticketTypeId')));
        this.get('priceGroup.prices').createFragment({
          ticketTypeId: ticketType.get('ticketTypeId'),
          basePrice: '',
          netPrice: '',
          margin: margin,
          minimumTickets: this.get('priceType') === _priceTypes.PRICE_TYPES.CAPACITY ? 1 : null,
          priceCode: null
        });
        this.get('priceGroup').notifyPropertyChange('prices'); //make sure that Add Volume price is shown/hidden correctly
      },
      handleDeletePrice: function handleDeletePrice(price) {
        price.toggleProperty('isSelected');
        this.get('priceGroup').notifyPropertyChange('prices'); //make sure that Add Volume price is shown/hidden correctly
        var pricesLeftForTicketType = this.get('priceGroup.prices').filter(function (p) {
          return p.get('isSelected') && p.get('ticketTypeId') === price.get('ticketTypeId');
        });
        if (!pricesLeftForTicketType.length) {
          //If there are no other prices with this ticket type, uncheck this TT from the Ticket types list.
          this.get('ticketTypesForPrice').find(function (tt) {
            return tt.get('ticketTypeId') === price.get('ticketTypeId');
          }).toggleProperty('isSelected');
        }
      },
      handleSelectAllCapacityTicketTypes: function handleSelectAllCapacityTicketTypes() {
        var isAllCapacityTicketTypesSelected = this.get('isAllCapacityTicketTypesSelected');
        this.get('priceGroup.prices').forEach(function (p) {
          return p.set('isSelected', isAllCapacityTicketTypesSelected);
        });
      },
      handleCapacityTicketTypeSelectChange: function handleCapacityTicketTypeSelectChange() {
        this.setIsAllCapacityTicketTypesSelectedFlag();
        this.get('priceGroup').notifyPropertyChange('prices'); //To force rerun has-overlapping-capacity-tt helper
        this._validateIfInvalid();
      },
      handleMinTicketsChange: function handleMinTicketsChange(price, event) {
        if (isFinite(parseInt(event.target.value))) {
          price.set('minimumTickets', parseInt(event.target.value));
        } else {
          price.set('minimumTickets', event.target.value);
        }
        this.get('priceGroup').notifyPropertyChange('prices'); //to update min tickets dropdown options
        this._validateIfInvalid();
      },
      handleRetailPriceChange: function handleRetailPriceChange(price) {
        this.clearInvalidValue(price, 'basePrice');
        this.updateNetPrice(price);
      },
      handleNetPriceChange: function handleNetPriceChange(price) {
        this.clearInvalidValue(price, 'netPrice');
        if (price.get('netPrice') !== '') {
          this.updateMargin(price);
        }
      },
      handleMarginChange: function handleMarginChange(price) {
        this.clearInvalidValue(price, 'margin');
        this.updateNetPrice(price);
      },
      handlePriceCodeChange: function handlePriceCodeChange(price, event) {
        price.set('priceCode', event.target.value);
      },
      handleAvailabilityConfigTypeChange: function handleAvailabilityConfigTypeChange(priceGroup, availabilityConfigType) {
        this.set('isAvailabilityByDays', availabilityConfigType === 'byDays');
        if (this.get('isAvailabilityByDays')) {
          if (!priceGroup.get('availabilityByDays') || !priceGroup.get('availabilityByDays.availabilities.length')) {
            priceGroup.set('availabilityByDays', this.get('store').createFragment('fragments/price-group-v4/availability-by-days', {}));
          }
        } else {
          this.addOfferAvailabilitiesWithoutCapacity();
        }
      },
      handlePriceGroupCancelClick: function handlePriceGroupCancelClick() {
        this.sendAction('cancelEditPriceGroup');
        this.set('errors', null);
        this.initTicketTypesForPricing();
        this.initAvailability();
      },
      handlePriceGroupSaveClick: function handlePriceGroupSaveClick() {
        var _this5 = this;

        var priceGroup = this.get('priceGroup');
        if (this.get('isAvailabilityByDays')) {
          priceGroup.set('availabilityByOffers', []);
        } else {
          priceGroup.set('availabilityByDays', null);
        }
        this.validatePriceGroups(priceGroup).then(function () {
          _this5.set('errors', null);
          _this5.sendAction('savePriceGroup');
        }).catch(function (errors) {
          _this5.set('errors', errors);
          (0, _formValidator.scrollToFirstError)();
        });
      }
    },
    updateNetPrice: function updateNetPrice(price) {
      if (price.get('basePrice') === '' || price.get('margin') === '') {
        price.set('netPrice', '');
      } else {
        var netPrice = (price.get('basePrice') * (1 - price.get('margin') / 100)).toFixed(DEC_PLACES);
        price.set('netPrice', parseFloat(netPrice));
      }
    },
    updateMargin: function updateMargin(price) {
      if (price.get('basePrice') === '' || price.get('netPrice') === '') {
        price.set('margin', '');
      } else {
        var margin = ((price.get('basePrice') - price.get('netPrice')) / price.get('basePrice') * 100).toFixed(DEC_PLACES);
        price.set('margin', parseFloat(margin));
      }
    },
    clearInvalidValue: function clearInvalidValue(price, field) {
      if (price.get(field) !== '' && isNaN(price.get(field))) {
        price.set(field, 0);
      }
    }
  });
});