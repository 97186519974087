define('lxso/helpers/product-list-status-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.productListStatusDisplay = productListStatusDisplay;
  function productListStatusDisplay(params) {
    var statusValue = params[0];

    var statusMap = new Map([['active', 'active'], ['inactive', 'inactive'], ['deactivated', 'deactivated'], ['suspended', 'suspended - mapping'], ['suspendedexpedia', 'suspended - expedia'], ['suspendedpaused', 'suspended - paused']]);

    var statusToDisplay = statusMap.get(statusValue);
    return statusToDisplay;
  }

  exports.default = Ember.Helper.helper(productListStatusDisplay);
});