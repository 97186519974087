define('lxso/mixins/promotion/promotion-creation-common', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    initPromotionAudience: function initPromotionAudience(controller, isNew) {
      controller.set('isPromoAudienceOpen', isNew);
      controller.set('isPromoAudienceCompleted', !isNew);
    },
    initPromotionShoppingPath: function initPromotionShoppingPath(controller, isNew) {
      controller.set('isPromoShoppingOpen', false);
      controller.set('isPromoShoppingCompleted', !isNew);
    },
    initPromotionDate: function initPromotionDate(controller, isNew) {
      controller.set('isPromoDateOpen', false);
      controller.set('isPromoDateCompleted', !isNew);
    },
    initPromotionDetails: function initPromotionDetails(controller, isNew, transition) {
      var selectedProducts = [];
      var selectedOptions = [];
      var selectedOptionTicketMap = new Map();
      var productList = this.controller.get('productList');
      var isClone = !!transition.queryParams.clone;
      if (isClone) {
        var promotionActivities = this.controller.get('promotion.promotionDetails.activities');
        promotionActivities.forEach(function (activity) {
          selectedProducts.push(activity.aId);
          activity.offers.forEach(function (offer) {
            selectedOptions.push(offer.oId);
            var selectedTicketsUnderOffer = [];
            offer.ticketTypes.forEach(function (ticketType) {
              selectedTicketsUnderOffer.push(ticketType.ttId);
            });
            selectedOptionTicketMap.set(offer.oId, selectedTicketsUnderOffer);
          });
        });
      } else if (isNew) {
        if (transition.queryParams && transition.queryParams.productId) {
          var promoteProductId = parseInt(transition.queryParams.productId);
          if (this.controller.get('avaliableProductsAndOptions.products').indexOf(promoteProductId) >= 0) {
            selectedProducts.push(promoteProductId);
          }
        }
        if (transition.queryParams && transition.queryParams.optionId) {
          var promoteOptionId = parseInt(transition.queryParams.optionId);
          if (this.controller.get('avaliableProductsAndOptions.options').indexOf(promoteOptionId) >= 0) {
            selectedOptions.push(promoteOptionId);
          }
        }
        if (selectedProducts.length > 0 && selectedOptions.length === 0) {
          productList.forEach(function (product) {
            if (selectedProducts.indexOf(product.id) >= 0) {
              product.offers.forEach(function (offer) {
                selectedOptions.push(offer.id);
                var ticketsUnderOffer = offer.ticketTypes.map(function (ticketType) {
                  return ticketType.id;
                });
                selectedOptionTicketMap.set(offer.id, ticketsUnderOffer);
              });
            }
          });
        } else if (selectedProducts.length > 0 && selectedOptions.length > 0) {
          productList.forEach(function (product) {
            if (selectedProducts.indexOf(product.id) >= 0) {
              product.offers.forEach(function (offer) {
                if (selectedOptions.indexOf(offer.id) >= 0) {
                  var ticketsUnderOffer = offer.ticketTypes.map(function (ticketType) {
                    return ticketType.id;
                  });
                  selectedOptionTicketMap.set(offer.id, ticketsUnderOffer);
                }
              });
            }
          });
        }
      }

      controller.set('selectedProducts', selectedProducts);
      controller.set('selectedOptions', selectedOptions);
      controller.set('selectedOptionTicketMap', selectedOptionTicketMap);
      controller.set('isPromoDetailsOpen', false);
      controller.set('isPromoDetailsCompleted', !isNew);
    },
    initPromotionDetailsBulkUpload: function initPromotionDetailsBulkUpload(controller, isNew) {
      controller.set('isPromoDetailsOpen', false);
      controller.set('isPromoDetailsCompleted', !isNew);
    },
    initPromotionName: function initPromotionName(controller, isNew) {
      controller.set('isPromoNameOpen', false);
      controller.set('isPromoNameCompleted', !isNew);
    },
    continueAudience: function continueAudience() {
      var _this = this;

      (0, _formValidator.validate)({
        model: this.controller.get('promotion'),
        attributes: ['audiences']
      }).then(function () {
        _this.controller.set('isPromoAudienceOpen', false);
        _this.controller.set('isPromoAudienceCompleted', true);
        if (_this.controller.get('isInternalUser')) {
          _this.openNextSection('promoShopping');
        } else {
          _this.openNextSection('promoDate');
        }
        _this.controller.set('errors', '');
      }).catch(function (errors) {
        _this.controller.set('errors', errors);
      });
    },
    continueShoppingPath: function continueShoppingPath() {
      var _this2 = this;

      (0, _formValidator.validate)({
        model: this.controller.get('promotion'),
        attributes: ['shoppingPaths']
      }).then(function () {
        _this2.controller.set('isPromoShoppingOpen', false);
        _this2.controller.set('isPromoShoppingCompleted', true);
        _this2.openNextSection('promoDate');
        _this2.controller.set('shoppingPathError', '');
      }).catch(function (errors) {
        _this2.controller.set('shoppingPathError', errors);
      });
    },
    openNextSection: function openNextSection(section) {
      switch (section) {
        case 'promoAudience':
          break;

        case 'promoShopping':
          if (!this.controller.get('isPromoShoppingCompleted')) {
            this.controller.set('isPromoShoppingOpen', true);
          }
          break;

        case 'promoDate':
          if (!this.controller.get('isPromoDateCompleted')) {
            this.controller.set('isPromoDateOpen', true);
          }
          break;

        case 'promoDetails':
          if (!this.controller.get('isPromoDetailsCompleted')) {
            this.controller.set('isPromoDetailsOpen', true);
          }
          break;

        case 'promoName':
          if (!this.controller.get('isPromoNameCompleted')) {
            this.controller.set('isPromoNameOpen', true);
          }
          break;

        default:
      }
    },


    actions: {
      editSection: function editSection(section) {
        switch (section) {
          case 'promoName':
            this.controller.toggleProperty('isPromoNameOpen');
            break;

          case 'promoDate':
            this.controller.toggleProperty('isPromoDateOpen');
            break;

          case 'promoAudience':
            this.controller.toggleProperty('isPromoAudienceOpen');
            break;

          case 'promoShopping':
            this.controller.toggleProperty('isPromoShoppingOpen');
            break;

          case 'promoDetails':
            this.controller.toggleProperty('isPromoDetailsOpen');
            break;

          default:
        }
      },
      continueSection: function continueSection(section) {
        if (this.controller.get('isNew')) {
          switch (section) {
            case 'promoAudience':
              this.continueAudience();
              break;

            case 'promoShopping':
              this.continueShoppingPath();
              break;

            case 'promoDate':
              this.controller.set('isPromoDateOpen', false);
              this.controller.set('isPromoDateCompleted', true);
              this.openNextSection('promoDetails');
              break;

            case 'promoDetails':
              this.controller.set('isPromoDetailsOpen', false);
              this.controller.set('isPromoDetailsCompleted', true);
              this.openNextSection('promoName');
              break;

            case 'promoName':
              this.controller.set('isPromoNameOpen', false);
              this.controller.set('isPromoNameCompleted', true);
              this.controller.set('readyToSave', true);
              break;

            default:
          }
        } else {
          this.send('editSection', section);
        }
      },
      setDateField: function setDateField(field, date) {
        switch (field) {
          case 'travelStartDate':
            this.controller.set('promotion.travelStartDate', date);
            break;

          case 'travelEndDate':
            this.controller.set('promotion.travelEndDate', date);
            break;

          case 'bookingStartDate':
            this.controller.set('promotion.bookingStartDate', date);
            break;

          case 'bookingEndDate':
            this.controller.set('promotion.bookingEndDate', date);
            break;
        }
      }
    }
  });
});