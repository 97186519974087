define('lxso/helpers/is-less-than', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isLessThan = isLessThan;
  function isLessThan(params) {
    return params[0] < params[1];
  }

  exports.default = Ember.Helper.helper(isLessThan);
});