define('lxso/serializers/change-history', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    normalize: function normalize(typeClass, hash) {
      hash.id = hash.changeId;

      hash.product = {
        productId: hash.happenedWith.productId,
        title: '' + hash.happenedWith.productTitle
      };
      hash.offer = {
        offerId: hash.happenedWith.offerId,
        title: hash.happenedWith.offerTitle
      };

      hash.changeDetail = hash.happenedWith;

      hash.supplierBranchId = hash.changeDetail.supplierBranchId;
      hash.offerGroupId = hash.changeDetail.offerGroupId;

      return this._super.apply(this, arguments);
    }
  });
});