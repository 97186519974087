define('lxso/validators/no-group-overlap', ['exports', 'ember-cp-validations/validators/base', 'lxso/utils/overlap-failure-reducer'], function (exports, _base, _overlapFailureReducer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NoGroupOverlap = _base.default.extend({
    featureFlags: Ember.inject.service(),
    validate: function validate(value, options, model, attribute) {
      'use strict';

      var priceArray = model.get('prices');
      var pricesLength = priceArray.get('length');
      var minMaxes = [];
      var failures = [];
      var errorMessage = model.get('i18n').t('errors.customMessages.offers.groupRangeOverlap').string;

      for (var i = 0; i < pricesLength; i++) {
        if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
          if (priceArray.objectAt(i).get('operation') !== 'delete') {
            var min = priceArray.objectAt(i).get('minimumTravelers');
            var max = priceArray.objectAt(i).get('maximumTravelers');
            minMaxes.push([min, max, i]);
          }
        } else {
          if (!priceArray.objectAt(i).get('id')) {
            var _min = priceArray.objectAt(i).get('minimumTravelers');
            var _max = priceArray.objectAt(i).get('maximumTravelers');
            minMaxes.push([_min, _max, i]);
          }
        }
      }

      minMaxes.sort(function (a, b) {
        var minMaxA = a[0];
        var minMaxB = b[0];
        if (minMaxA < minMaxB) {
          return -1;
        } else {
          return minMaxA > minMaxB ? 1 : 0;
        }
      });

      var minMaxLength = minMaxes.length;

      for (var _i = 0; _i < minMaxLength; _i++) {
        var minMax = minMaxes.shift();
        var checkingMin = minMax[0];
        var checkingMax = minMax[1];
        var checkingTicketIndex = minMax[2];

        for (var j = 0; j < minMaxes.length; j++) {
          var _min2 = minMaxes[j][0];
          var _max2 = minMaxes[j][1];
          var failureIndex = minMaxes[j][2];

          if (checkingMin === _min2) {
            failures.push('min' + checkingTicketIndex, 'min' + failureIndex);
          }
          if (checkingMin === _max2) {
            failures.push('min' + checkingTicketIndex, 'max' + failureIndex);
          }
          if (checkingMax === _min2) {
            failures.push('max' + checkingTicketIndex, 'min' + failureIndex);
          }
          if (checkingMax === _max2) {
            failures.push('max' + checkingTicketIndex, 'max' + failureIndex);
          }

          if (checkingMax > _min2) {
            failures.push('max' + checkingTicketIndex, 'min' + failureIndex);
          }
          if (checkingMax > _max2) {
            failures.push('max' + checkingTicketIndex, 'max' + failureIndex);
          }
          if (checkingMin > _max2) {
            failures.push('min' + checkingTicketIndex, 'max' + failureIndex);
          }
          if (checkingMin > _min2) {
            failures.push('min' + checkingTicketIndex, 'min' + failureIndex);
          }
        }
      }

      failures.sort(function (a, b) {
        var ticketA = a[3];
        var ticketB = b[3];
        if (ticketA < ticketB) {
          return -1;
        } else {
          return ticketA > ticketB ? 1 : 0;
        }
      });

      if (failures.length > 0) {
        return (0, _overlapFailureReducer.overlapFailureReducer)(failures, errorMessage);
      } else {
        return true;
      }
    }
  });

  exports.default = NoGroupOverlap;
});