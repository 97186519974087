define('lxso/validators/custom-health-and-safety-covid-status', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomHealthAndSafetyCovidStatusValidator = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var errorMessage = model.get('i18n').t('' + options.messageKey).string;
      var covidStatusQuestionsOptionMessage = model.get('i18n').t('' + options.covidStatusQuestionsOptionMessage).string;
      var covidStatusTimePriorToTest = model.get('i18n').t('' + options.covidStatusTimePriorToTest).string;
      var covidStatusAcceptedTestTypes = model.get('i18n').t('' + options.covidStatusAcceptedTestTypes).string;
      var covidStatusTimePriorToTestAndAcceptedTestTypes = model.get('i18n').t('' + options.covidStatusTimePriorToTestAndAcceptedTestTypes).string;
      if (value == undefined) {
        return errorMessage;
      }
      var proofOfCovid19Vaccine = value.proofOfCovid19Vaccine;
      var proofOfCovidInfection = value.proofOfCovidInfection;
      var proofOfCovid19Negative = value.proofOfCovid19Negative;
      if (model.covidStatusOption == undefined || !(proofOfCovid19Vaccine || proofOfCovidInfection || proofOfCovid19Negative)) {
        return covidStatusQuestionsOptionMessage;
      }
      if (proofOfCovid19Negative) {
        var acceptedTestTypesVal = Object.keys(proofOfCovid19Negative.acceptedTestTypes).filter(function (key) {
          return proofOfCovid19Negative.acceptedTestTypes[key];
        });
        var timePriorToTestVal = proofOfCovid19Negative.timePriorToTest;
        if (acceptedTestTypesVal.length == 0 && !timePriorToTestVal) {
          return covidStatusTimePriorToTestAndAcceptedTestTypes;
        }
        if (acceptedTestTypesVal.length == 0) {
          return covidStatusAcceptedTestTypes;
        }
        if (!timePriorToTestVal) {
          return covidStatusTimePriorToTest;
        }
      }
      return true;
    }
  });

  /*CustomHealthAndSafetyValidator.reopenClass({
    getDependentsFor(attribute, options) {
      return [];
    }
  });*/

  exports.default = CustomHealthAndSafetyCovidStatusValidator;
});