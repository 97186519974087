define('lxso/serializers/platform', ['exports', 'ember-data', 'lxso/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    primaryKey: 'id',
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
      payload.links = {
        integrationUrlConfiguration: domain + '/platforms/' + payload.id + '/configurations/Integration',
        productionUrlConfiguration: domain + '/platforms/' + payload.id + '/configurations/Production',
        platformSupplierBranches: domain + '/platforms/' + payload.id + '/supplierbranches'
      };
      var integrationConfig = payload.configurations.filter(function (config) {
        return config.environment === 'Integration';
      });
      payload.hasIntegrationUrlConfiguration = !!integrationConfig.length;

      var productionConfig = payload.configurations.filter(function (config) {
        return config.environment === 'Production';
      });
      payload.hasProductionUrlConfiguration = !!productionConfig.length;

      return this._super.apply(this, arguments);
    }
  });
});