define('lxso/serializers/option-group-ticket-type-restrictions', ['exports', 'ember-data', 'lxso/constants/base-ticket-types', 'lxso/utils/capitalize-first-letter'], function (exports, _emberData, _baseTicketTypes, _capitalizeFirstLetter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    //this specifies the correct serializer
    primaryKey: 'offerGroupId',

    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      var selectedTicketTypeRestrictions = json.restrictions.filter(function (ticketType) {
        return ticketType.ticketTypeId;
      });
      selectedTicketTypeRestrictions.forEach(function (ticketType) {
        delete ticketType.isSelected;
      });
      json.restrictions = selectedTicketTypeRestrictions;
      return json;
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      payload.restrictions = this.getNormalizedStandardTicketTypes(payload);
      return this._super.apply(this, arguments);
    },
    getNormalizedStandardTicketTypes: function getNormalizedStandardTicketTypes(payload) {
      return _baseTicketTypes.BASE_TICKET_TYPES_LIST.map(function (ticketLabel) {
        var existingTicketType = payload.restrictions.findBy('label', ticketLabel);
        if (existingTicketType) {
          return Object.assign({}, existingTicketType, {
            isSelected: existingTicketType.minimumTicketsPerBooking > 0 || existingTicketType.maximumTicketsPerBooking > 0
          });
        } else {
          return {
            ticketTypeId: null,
            label: ticketLabel,
            isSelected: false,
            minimumTicketsPerBooking: _baseTicketTypes.MIN_TICKETS_PER_BOOKING_DEFAULT,
            maximumTicketsPerBooking: _baseTicketTypes.MAX_TICKETS_PER_BOOKING_DEFAULT
          };
        }
      });
    }
  });
});