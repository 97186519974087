define('lxso/utils/omniture', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = sendOmnitureData;
  exports.sendOmnitureDataOnClick = sendOmnitureDataOnClick;
  exports.sendOmnitureErrorsOnClick = sendOmnitureErrorsOnClick;


  /**
   * Combines passed in data with the omniture data in environment.js and sends it to Omniture
   * Only makes an Omniture call if data is present in both locations
   */
  function sendOmnitureData(data) {
    s_exp.prop16 = '';
    s_exp.eVar28 = '';
    if (_environment.default.omniture && data) {
      Ember.$.extend(s_exp, _environment.default.omniture, data);
      s_exp.t();
    }
  }

  function sendOmnitureDataOnClick(key, var1, var2, var3, var4) {
    Ember.$.extend(s_exp, _environment.default.omniture);
    s_exp.trackClick(this, 'a', key, var1, var2, var3, var4);
  }

  function sendOmnitureErrorsOnClick(key) {
    Ember.$.extend(s_exp, _environment.default.omniture);
    s_exp.trackClick(this, 'errors', key);
  }
});