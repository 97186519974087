define('lxso/helpers/is-option-edit-enabled', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),

    compute: function compute() {
      var isGTBranch = this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
      var branchId = this.get('session.session.authenticated.selectedBranch.supplier_branch_id');
      var isInternalUser = this.get('permissions').checkEnabled('V3InternalSettings');
      var isWhitelistedGTBranch = _environment.default.whiteListedGTSupplierBranch.contains(branchId);
      return isWhitelistedGTBranch || isInternalUser || !isGTBranch;
    }
  });
});