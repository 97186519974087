define('lxso/utils/change-history-details-display-modes/email-notification-settings', ['exports', 'lxso/utils/change-history-details-display-modes/generic'], function (exports, _generic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'email-notification-settings',
    diff: function diff(oldValue, newValue, _ref) {
      var i18n = _ref.i18n;

      var oldValueArray = oldValue ? oldValue.split(', ') : [];
      var newValueArray = newValue ? newValue.split(', ') : [];
      var oldObject = {};
      var newObject = {};

      if (oldValueArray.length > 0) {
        for (var i = 0; i < oldValueArray.length; i++) {
          var keyValueSplit = oldValueArray[i].split(': ');

          if (keyValueSplit[0] === 'Email') {
            oldObject[keyValueSplit[0]] = keyValueSplit[1].split(',');
          } else {
            oldObject[keyValueSplit[0]] = keyValueSplit[1];
          }
        }
      }

      var instantText = i18n.t('components.notificationSettings.labels.instant');
      if (oldObject.Instant === 'true') {
        oldObject.NotificationType = instantText;
      }

      if (newValueArray.length > 0) {
        for (var _i = 0; _i < newValueArray.length; _i++) {
          var _keyValueSplit = newValueArray[_i].split(': ');

          if (_keyValueSplit[0] === 'Email') {
            newObject[_keyValueSplit[0]] = _keyValueSplit[1].split(',');
          } else {
            newObject[_keyValueSplit[0]] = _keyValueSplit[1];
          }
        }
      }

      if (newObject.Instant === 'true') {
        newObject.NotificationType = instantText;
      }

      return {
        email: {
          oldValue: oldObject.Email,
          newValue: newObject.Email,
          displayMode: _generic.default
        },
        instant: {
          oldValue: oldObject.Instant,
          newValue: newObject.Instant,
          displayMode: _generic.default
        },
        instantBookingCutOffHours: {
          oldValue: oldObject.InstantBookingCutOffHours,
          newValue: newObject.InstantBookingCutOffHours,
          displayMode: _generic.default
        },
        notificationType: {
          oldValue: oldObject.NotificationType,
          newValue: newObject.NotificationType,
          displayMode: _generic.default
        },
        remindBeforeDays: {
          oldValue: oldObject.RemindBeforeDays,
          newValue: newObject.RemindBeforeDays,
          displayMode: _generic.default
        }
      };
    }
  };


  function format(dateString) {
    return dateString ? moment(dateString).format('YYYY-MM-DD') : '';
  }
});