define('lxso/models/fragments/prices/day-availability', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    capacity: [(0, _validation.validator)('number', {
      gte: 0,
      allowString: true,
      allowBlank: true,
      integer: true,
      descriptionKey: 'errors.descriptionKeys.capacity'
    })],
    open: (0, _validation.validator)('time-format', {
      disabled: Ember.computed.not('model.available')
    }),
    close: (0, _validation.validator)('time-format', {
      disabled: Ember.computed.not('model.available')
    }),
    timeRange: (0, _validation.validator)('time-range', {
      start: Ember.computed.readOnly('model.open'),
      end: Ember.computed.readOnly('model.close'),
      disabled: Ember.computed.not('model.available')
    })
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    i18n: Ember.inject.service(),
    day: _emberData.default.attr('string'),
    open: _emberData.default.attr('string'),
    close: _emberData.default.attr('string'),
    available: _emberData.default.attr('boolean'),
    capacity: _emberData.default.attr('number')
  });
});