define('lxso/validators/custom-health-and-safety-validation', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomHealthAndSafetyValidator = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var errorMessage = model.get('i18n').t('' + options.messageKey).string;
      if (value.filter(function (r) {
        return r.get('answer') == '';
      }).length > 0) {
        return errorMessage;
      } else {
        return true;
      }
    }
  });

  CustomHealthAndSafetyValidator.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = CustomHealthAndSafetyValidator;
});