define('lxso/helpers/is-zero', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isZero = isZero;
  function isZero(params) {
    return params[0] === 0 ? true : false;
  }

  exports.default = Ember.Helper.helper(isZero);
});