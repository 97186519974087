define("lxso/utils/random-key-generator", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = generateKey;
  function generateKey() {
    var part1 = Math.random().toString().substring(2, 10);
    var part2 = Math.random().toString().substring(2, 10);
    return "" + part1 + part2;
  }
});