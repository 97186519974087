define('lxso/components/existing-price-template-details-display/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      selectExistingPriceGroup: function selectExistingPriceGroup(thisSchedule) {
        //tell the parent controller (select-availability-schedule.js) to execute its action
        this.sendAction('link-action', thisSchedule);
      }
    }
  });
});