define('lxso/serializers/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    normalize: function normalize(typeClass, hash) {
      if (hash.roles.length > 0) {
        hash.roles = hash.roles.join(', ');
      }

      if ((hash.roles.indexOf('V3 Supply Manager') >= 0 || hash.roles.indexOf('Report Reader') >= 0) && hash.status.toUpperCase() !== 'INACTIVE' && hash.roles.indexOf('Super Admin') < 0 && hash.roles.indexOf('SuperSystemAdmin') < 0) {
        hash.isUserEditable = true;
      }

      if (hash.status.toUpperCase() === 'INACTIVE') {
        hash.isUserInactive = true;
      }

      hash.status = '' + hash.status[0] + hash.status.substring(1).toLowerCase();
      return this._super.apply(this, arguments);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      if (!('role' in payload)) {
        if (payload.roles.indexOf('V3 Supply Manager') >= 0) {
          payload.role = 7;
        } else {
          payload.role = 3;
        }
      }

      if (payload.accessibleSuppliersWithBranches && payload.accessibleSuppliersWithBranches.length) {
        payload.accessibleSuppliersWithBranches.forEach(function (supplierInfo) {
          var totalNumberOfBranches = supplierInfo.branches.length;
          var totalSelectedBranches = supplierInfo.branches.filter(function (x) {
            return x.hasPermission === true;
          }).get('length');

          supplierInfo.selectAll = totalNumberOfBranches === totalSelectedBranches;
          supplierInfo.isBranchesLoading = true;
          var allBranches = [];
          supplierInfo.branches.forEach(function (branches) {
            var branchObj = {};
            branchObj.isSelected = branches.hasPermission;
            branchObj.supplier_branch_name = branches.name;
            branchObj.supplier_branch_id = branches.id;
            branchObj.status = branches.status;
            allBranches.push(branchObj);
          });

          supplierInfo.allBranches = allBranches;
          supplierInfo.isBranchesLoading = false;
        });
      } else {
        payload.accessibleSuppliersWithBranches = [];
      }

      return this._super(store, primaryModelClass, payload);
    }
  });
});