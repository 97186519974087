define('lxso/adapters/schedule', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    /**
     * Create a schedule
     *
     * Usage within route:
     * ```
     * this.store.createRecord('schedule', {
     *   name: 'This is the name',
     *   supplier_branch_Id: 1234567
     * })
     * .save()
     * .then((schedule) => {
     *   console.log(schedule);
     * });
     * ```
     *
     * @return {promise}          promise object
     */
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var sId = snapshot.record.supplier_branch_id;
      var url = this.host + '/' + this.namespace + '/supplier_branches/' + sId + '/schedule_template';

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      return this.ajax(url, 'POST', { data: data });
    },


    /**
     * Update a schedule
     *
     * Usage within route:
     * ```
     * schedule.set('name', 'New Name');
     * schedule.save()
     * .then((schedule) => {
     *   console.log(schedule)
     * });
     * ```
     *
     * @return {promise}          Schedule promise
     */
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var sId = snapshot.record.supplier_branch_id;
      var url = this.host + '/' + this.namespace + '/supplier_branches/' + sId + '/schedule_template/' + snapshot.id;

      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'PUT', { data: data });
    },


    /**
     * Delete a schedule
     *
     * Usage in route:
     * ```
     * schedule.destroyRecord()
     * .then((schedule) => {
     *   console.log('success', schedule);
     * });
     ```
     *
     * @return {promise}          Promise object
     */
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var sId = snapshot.record.supplier_branch_id;
      var url = this.host + '/' + this.namespace + '/supplier_branches/' + sId + '/schedule_template/' + snapshot.id;
      return this.ajax(url, 'DELETE');
    }
  });
});