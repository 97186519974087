define('lxso/utils/change-history-details-display-modes/table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'table',
    diff: function diff(oldValues, newValues) {
      return new Array(Math.max(oldValues.length, newValues.length)).fill(undefined).map(function (row, index) {
        var oldValue = oldValues[index];
        var newValue = newValues[index];
        return {
          oldValue: oldValue,
          newValue: newValue,
          changed: oldValue !== newValue
        };
      });
    }
  };
});