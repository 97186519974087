define("lxso/utils/to-percent", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = toPercent;
  function toPercent(args /*, hash*/) {
    return args.length === 2 ? ((1 - args[0] / args[1]) * 100).toFixed(2) + "%" : (args[0] * 100).toFixed(2) + "%";
  }
});