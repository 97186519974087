define('lxso/components/change-history/differences/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    staticData: Ember.inject.service(),
    classNames: ['change-history-differences'],
    displayMode: Ember.computed.alias('changeDetail.displayMode'),
    partialName: Ember.computed.alias('displayMode.partialName'),
    oldValue: Ember.computed.alias('changeDetail.oldValue'),
    newValue: Ember.computed.alias('changeDetail.newValue'),

    differences: Ember.computed('displayMode', 'oldValue', 'newValue', function () {
      var services = this.getProperties('i18n', 'session', 'staticData');
      try {
        return this.get('displayMode').diff(this.get('oldValue'), this.get('newValue'), services);
      } catch (e) {
        Ember.Logger.error(e);
      }
    }),

    error: Ember.computed.none('differences')
  });
});