define('lxso/models/fragments/option-group/sub-offer-detail', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _emberDataModelFragments, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    offerId: _emberData.default.attr('number'),
    startTime: _emberData.default.attr('string'),
    endTime: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    languageInfo: (0, _attributes.fragment)('fragments/option-group/language-info')
  });
});