define('lxso/services/duration-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    get: function get() {
      return [{ display: 'Not Applicable', value: 'not applicable' }, { display: 'Typical', value: 'typical' }, { display: 'Recommended', value: 'recommended' }, { display: 'Actual', value: 'actual' }];
    }
  });
});