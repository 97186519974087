define('lxso/initializers/pl-uploader-manager', ['exports', 'ember-plupload/system/make-file-filter'], function (exports, _makeFileFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  var keys = Object.keys; /*global requirejs */
  function initialize(app) {
    if (arguments[1]) {
      // Ember < 2.1
      app = arguments[1];
    }

    var entries = requirejs.entries;
    var fileFilterPrefix = app.modulePrefix + '/file-filters';
    var fileFilters = {};
    keys(entries).forEach(function (key) {

      if (key.indexOf(fileFilterPrefix) === 0) {
        var filterName = key.split('/').slice(-1);
        var module = require(key, null, null, true);
        if (module) {
          fileFilters[filterName] = module.default;
          (0, _makeFileFilter.default)(filterName, module.default);
          app.register('file-filter:' + filterName, module.default, { instantiate: false });
        }
      }
    });

    app.register('app:file-filters', fileFilters, { instantiate: false });
    app.inject('component:pl-uploader', 'fileFilters', 'app:file-filters');
  }

  exports.default = {
    name: 'pl-uploader',
    initialize: initialize
  };
});