define('lxso/components/optgroup-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      onSelect: function onSelect(target) {
        var selectedOptGroup = this.$(target).find('option:selected').closest('optgroup').attr('label');
        this.sendAction('action', target.value, selectedOptGroup);
      }
    }
  });
});