define('lxso/components/product-net-price/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['componentClasses'],
    session: Ember.inject.service('session'),

    currencyType: Ember.computed(function () {
      var currencyType = this.get('session.data.authenticated.selectedBranch.currency_type');
      if (currencyType.length > 0) {
        return currencyType;
      }
    }),

    netCalculated: Ember.computed('basePrice', function () {
      var localBase = this.get('basePrice');
      var margin = this.get('margin');
      var localNet = localBase * (1 - margin);
      return isNaN(localNet) ? 0 : localNet;
    }),

    actualNetPrice: Ember.computed('netPrice', 'netCalculated', function () {
      if (this.get('netPrice') === 0) {
        return 0;
      }
      return this.get('netPrice') || this.get('netCalculated');
    }),

    displayedNetPrice: Ember.computed('actualNetPrice', function () {
      var currencyType = this.get('currencyType');
      switch (currencyType) {
        case 'XPF':
        case 'JPY':
        case 'KRW':
        case 'VUV':
          return Math.round(this.get('actualNetPrice')).toString();
          break;
        default:
          return this.get('actualNetPrice').toFixed(2);
      }
    })
  });
});