define('lxso/components/promotions/promotion-details/component', ['exports', 'lxso/utils/form-validator', 'lxso/utils/omniture'], function (exports, _formValidator, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    session: Ember.inject.service('session'),
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('isInternalUser', this.get('permissions').checkEnabled('V3InternalSettings'));
      var selectedProducts = this.get('selectedProducts');
      var selectedOptionTicketMap = this.get('selectedOptionTicketMap');
      this.setPromotionProductViewModal(selectedProducts.length > 0);
      this.setPromotionOptionViewModal(selectedOptionTicketMap.size > 0);
      this.resetProductList();
      if (selectedProducts.length > 0) {
        this.sendAction('updatePromoActivities', this.get('productList'));
      }
    },
    setPromotionProductViewModal: function setPromotionProductViewModal(isSaveEnabled) {
      this.set('productModal', {
        isOpen: false,
        isSaveEnabled: isSaveEnabled || false
      });
    },
    setPromotionOptionViewModal: function setPromotionOptionViewModal(isSaveEnabled) {
      this.set('optionModal', {
        isOpen: false,
        isSaveEnabled: isSaveEnabled || false
      });
    },
    validatePromotionDetails: function validatePromotionDetails() {
      var attributeArr = ['totalDiscount'];
      if (this.get('isInternalUser')) {
        attributeArr = ['totalDiscount', 'expediaFund', 'supplierFund'];
      }
      return (0, _formValidator.validate)({ model: this.get('promotion'), attributes: attributeArr }, { model: this.get('promotion.promotionDetails'), attributes: ['activities'] });
    },


    promoDetailPlayback: Ember.computed('isNew', 'promotion', 'selectedProducts', 'recalculatePlayBack', function () {
      var isNew = this.get('isNew');
      var promotion = this.get('promotion');
      var selectedProducts = this.get('selectedProducts');
      var currency = this.get('currency');
      var promotionDiscount = promotion.get('totalDiscount');
      var promoUnit = promotion.get('discountTypeUI') === 'Absolute' ? ' ' + currency : '%';
      var discount = this.get('i18n').t('pages.promotion.promotionDetails.discount');
      var products = this.get('i18n').t('pages.promotion.promotionDetails.products');

      var playbackMessage = '';
      if (isNew) {
        playbackMessage = '' + promotionDiscount + promoUnit + ' ' + discount + '; ' + selectedProducts.length + ' ' + products;
      } else {
        playbackMessage = '' + promotionDiscount + promoUnit + ' ' + discount + ';';
        playbackMessage += ' ' + promotion.get('promotionDetails.activities.length') + ' ' + products;
      }

      if (this.get('isInternalUser')) {
        playbackMessage += '; ' + promotion.get('fundType');
      }

      return playbackMessage;
    }),

    promoPlayBackWarningForMoreThan75PercentDiscount: Ember.computed('isNew', 'promotion', 'selectedProducts', 'recalculatePlayBack', function () {
      var promotion = this.get('promotion');
      var promotionDiscount = promotion.get('totalDiscount');
      var isExpediaFunded = promotion.get('fundType') == 'Expedia Funded';
      var isPercentage = promotion.get('discountTypeUI') == 'Percentage';
      if (isExpediaFunded && isPercentage && promotionDiscount > 75) {
        return this.get('i18n').t('pages.promotion.promotionDetails.negativeMarginForExpediaFundedPromotion');
      }
      return '';
    }),

    validateIfInvalid: function validateIfInvalid() {
      var _this = this;

      if (this.get('promotionDetailsValidationErrors')) {
        this.validatePromotionDetails().then(function () {
          _this.set('promotionDetailsValidationErrors', undefined);
        }).catch(function (detailsValidationError) {
          _this.set('promotionDetailsValidationErrors', detailsValidationError);
        });
      }
    },
    resetProductList: function resetProductList() {
      var selectedProducts = this.get('selectedProducts');
      var selectedOptions = this.get('selectedOptions');
      var selectedOptionTicketMap = this.get('selectedOptionTicketMap');
      var productList = this.get('productList');
      var specificOptionOrTicketSelected = false;
      productList.forEach(function (product) {
        var isProductSelected = selectedProducts.indexOf(product.id) >= 0;
        product.set('isProductSelected', isProductSelected);
        if (isProductSelected) {
          product.offers.forEach(function (offer) {
            var isOfferSelected = selectedOptions.indexOf(offer.id) >= 0;
            offer.set('isOfferSelected', isOfferSelected);
            if (isOfferSelected) {
              offer.ticketTypes.forEach(function (ticketType) {
                var isTicketSelected = selectedOptionTicketMap.get(offer.id).indexOf(ticketType.id) >= 0;
                ticketType.set('isTicketSelected', isTicketSelected);
                if (!isTicketSelected) {
                  specificOptionOrTicketSelected = true;
                }
              });
            } else {
              specificOptionOrTicketSelected = true;
            }
          });
        }
      });
      this.set('specificOptionOrTicketSelected', specificOptionOrTicketSelected);
    },


    actions: {
      editSection: function editSection() {
        var isNew = this.get('isNew');
        if (isNew) {
          this.set('recalculatePlayBack', !this.get('recalculatePlayBack'));
        }

        this.sendAction('edit');
      },
      setDiscountType: function setDiscountType(type) {
        this.set('promotion.discountTypeUI', type);
        this.set('promotion.totalDiscount', 0);
        this.set('promotion.expediaFund', undefined);
        this.set('promotion.supplierFund', undefined);
      },
      setFundType: function setFundType(type) {
        this.set('promotion.fundType', type);
        this.set('recalculatePlayBack', !this.get('recalculatePlayBack'));
        this.send('setExpediaSupplierFund');
      },
      setExpediaSupplierFund: function setExpediaSupplierFund() {
        var fundType = this.get('promotion.fundType');
        var totalDiscount = this.get('promotion.totalDiscount') || 0;

        switch (fundType) {
          case 'Expedia Funded':
            this.set('promotion.expediaFund', totalDiscount);
            this.set('promotion.supplierFund', 0);
            break;

          case 'Supplier Funded':
            this.set('promotion.expediaFund', 0);
            this.set('promotion.supplierFund', totalDiscount);
            break;

          default:
            this.set('promotion.expediaFund', undefined);
            this.set('promotion.supplierFund', undefined);
            break;
        }

        this.get('promotion').notifyPropertyChange('expediaFund');
        this.get('promotion').notifyPropertyChange('supplierFund');
      },
      setValuesForSplitFunded: function setValuesForSplitFunded(changedInput) {
        var discountType = this.get('promotion.discountTypeUI');
        var totalDiscount = this.get('promotion.totalDiscount') || 0;
        var expediaFund = this.get('promotion.expediaFund') || 0;
        var supplierFund = this.get('promotion.supplierFund') || 0;

        if (discountType === 'Absolute') {
          if (totalDiscount) {
            var valueToSet = 0;
            if (changedInput === 'expediaFund') {
              valueToSet = (totalDiscount - this.get('promotion.expediaFund')).toFixed(2);
              this.set('promotion.supplierFund', parseFloat(valueToSet));
            } else {
              valueToSet = (totalDiscount - this.get('promotion.supplierFund')).toFixed(2);
              this.set('promotion.expediaFund', parseFloat(valueToSet));
            }
          }
        } else {
          var percentageToSet = 0;
          if (changedInput === 'expediaFund') {
            percentageToSet = 100 - this.get('promotion.expediaFund');
            this.set('promotion.supplierFund', parseInt(percentageToSet * 100) / 100);
          } else {
            percentageToSet = 100 - this.get('promotion.supplierFund');
            this.set('promotion.expediaFund', parseInt(percentageToSet * 100) / 100);
          }
        }
      },
      continueSection: function continueSection() {
        var _this2 = this;

        var promotionDiscount = this.get('promotion.totalDiscount');
        var promoUnit = this.get('promotion.discountTypeUI');
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.promotion-new.continue.details', promotionDiscount, promoUnit);
        this.validatePromotionDetails().then(function () {
          _this2.set('promotionDetailsValidationErrors', undefined);
          _this2.sendAction('continue');
        }).catch(function (detailsValidationError) {
          _this2.set('promotionDetailsValidationErrors', detailsValidationError);
          var i18n = _this2.get('i18n');
          if (detailsValidationError.activities && detailsValidationError.activities.length > 0) {
            (0, _omniture.sendOmnitureErrorsOnClick)('error.create.product');
          }
          if (detailsValidationError.totalDiscount && detailsValidationError.totalDiscount.length > 0) {
            var discountErrors = detailsValidationError.totalDiscount;
            var minFivePercError = i18n.t('pages.promotion.promotionDetails.validationMsg.discountGTFive').string;
            var moreThan75Perc = i18n.t('pages.promotion.promotionDetails.validationMsg.percentageMoreThan75').string;
            if (discountErrors.indexOf(minFivePercError) >= 0) {
              (0, _omniture.sendOmnitureErrorsOnClick)('error.create.mindiscount');
            } else if (discountErrors.indexOf(moreThan75Perc) >= 0) {
              (0, _omniture.sendOmnitureErrorsOnClick)('error.create.maxdiscount');
            }
          }
        });
      },
      openPromotionProductSelection: function openPromotionProductSelection() {
        this.set('productModal.isOpen', true);
      },
      cancelPromotionProductSelection: function cancelPromotionProductSelection() {
        this.set('productModal.isOpen', false);
        this.resetProductList();
      },
      promotionProductSave: function promotionProductSave() {
        this.set('productModal.isOpen', false);
        this.send('openPromotionOptionSelection');
      },
      openPromotionOptionSelection: function openPromotionOptionSelection() {
        this.set('optionModal.isOpen', true);
      },
      cancelPromotionOptionSelection: function cancelPromotionOptionSelection() {
        this.set('optionModal.isOpen', false);
        this.set('optionModal.isSaveEnabled', false);
        this.resetProductList();
      },
      savePromotionOptionSelection: function savePromotionOptionSelection() {
        var supplierBranchId = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
        var productList = this.get('productList');
        var selectedProducts = [];
        var selectedOptions = [];
        var selectedOptionTicketMap = new Map();
        var specificOptionOrTicketSelected = false;
        productList.forEach(function (product) {
          if (product.isProductSelected) {
            var selectedOffersUnderProduct = [];
            product.offers.forEach(function (offer) {
              if (offer.isOfferSelected) {
                var selectedTicketsUnderOffer = [];
                offer.ticketTypes.forEach(function (ticketType) {
                  if (ticketType.isTicketSelected) {
                    selectedTicketsUnderOffer.push(ticketType.id);
                  }
                });
                selectedOptionTicketMap.set(offer.id, selectedTicketsUnderOffer);
                offer.set('isOfferSelected', selectedTicketsUnderOffer.length > 0);
                if (selectedTicketsUnderOffer.length < offer.ticketTypes.length) {
                  specificOptionOrTicketSelected = true;
                }
                if (offer.isOfferSelected) {
                  selectedOffersUnderProduct.push(offer.id);
                }
              }
            });
            selectedOptions.push.apply(selectedOptions, selectedOffersUnderProduct);
            product.set('isProductSelected', selectedOffersUnderProduct.length > 0);
            if (selectedOffersUnderProduct.length < product.offers.length) {
              specificOptionOrTicketSelected = true;
            }
            if (product.isProductSelected) {
              selectedProducts.push(product.id);
            }
          }
        });
        this.set('specificOptionOrTicketSelected', specificOptionOrTicketSelected);
        this.set('selectedProducts', selectedProducts);
        this.set('selectedOptions', selectedOptions);
        this.set('selectedOptionTicketMap', selectedOptionTicketMap);
        this.sendAction('updatePromoActivities', productList);

        this.set('optionModal.isOpen', false);
        this.validateIfInvalid();
      },
      setProductModalSave: function setProductModalSave(state) {
        this.set('productModal.isSaveEnabled', state);
      },
      setOptionModalSave: function setOptionModalSave(state) {
        this.set('optionModal.isSaveEnabled', state);
      }
    }
  });
});