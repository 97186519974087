define('lxso/validators/address-results', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AddressResults = _base.default.extend({
    validate: function validate(value, options, model) {
      return model.get('addressStatus') === google.maps.GeocoderStatus.OK ? true : model.get('i18n').t(options.messageKey).string;
    }
  });

  exports.default = AddressResults;
});