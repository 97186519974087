define('lxso/instance-initializers/input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    Ember.TextField.reopen({
      attributeBindings: ['aria-describedby']
    });
  }

  exports.default = {
    name: 'input',
    initialize: initialize
  };
});