define('lxso/utils/scroll-to-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function scrollToElement(selector) {
    Ember.run.later(function () {
      var anchorElement = Ember.$(selector).offset();
      if (anchorElement) {
        Ember.$('html, body').animate({
          scrollTop: anchorElement.top - 200
        }, 250);
      }
    });
  }

  exports.scrollToElement = scrollToElement;
});