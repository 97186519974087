define('lxso/components/change-history/when-by/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['change-history-when-by'],

    dateFormat: Ember.inject.service('date-format'),

    displayTimestamp: Ember.computed('change-item', function () {
      var format = this.get('dateFormat').LONG_USA_FORMAT_WITH_TIME_AND_TZ;
      return this.get('change-item.occurredAt').format(format);
    })
  });
});