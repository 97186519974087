define('lxso/services/uitk', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    reinitResponsiveTable: function reinitResponsiveTable(selector) {
      Ember.run.scheduleOnce('afterRender', function () {
        var table = window.$(selector);
        table.data('tableInit', false);
        window.uitk.initResponsiveTables(table);
      });
    },


    modal: {
      create: function create(options) {
        return new Ember.RSVP.Promise(function (resolve) {
          Ember.run.schedule('afterRender', function () {
            options.content = Ember.$(options.content).html();
            resolve(window.uitk.modal.create(options));
          });
        });
      },
      close: function close() {
        'use strict';

        window.uitk.modal.close();
      }
    },

    datepicker: {
      subscribe: function subscribe(callback) {
        var topic = 'datepicker.selectDate';
        return window.uitk.subscribe(topic, function (returnedTopic, data) {
          if (returnedTopic === topic) {
            callback(moment(data.date), data.element);
            var endDateSelector = data.element.parent().parent().data('endDate');
            if (endDateSelector) {
              Ember.run.next(function () {
                var endField = Ember.$(endDateSelector).find('input');
                callback(moment(endField.val(), 'L', true), endField);
              });
            }
          }
        });
      },
      unsubscribe: function unsubscribe(token) {
        window.uitk.unsubscribe('datepicker.selectDate', token);
      }
    }
  });
});