define("lxso/routes/activity/categories/utils", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toCategory = toCategory;
  function isSelected(categoryName, selectedCategories) {
    return {
      name: categoryName,
      selected: selectedCategories.includes(categoryName)
    };
  }

  function toCategory(selectedCategories) {
    return function (category) {
      return isSelected(category, selectedCategories);
    };
  }
});