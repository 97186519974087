define('lxso/components/side-nav-menu/component', ['exports', 'lxso/utils/omniture', 'lxso/utils/is-mobile'], function (exports, _omniture, _isMobile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    manageUsersQueryParams: {
      firstName: null,
      lastName: null,
      email: null,
      supplier: null,
      supplierBranch: null
    },
    productsAccordionRoutes: ['product-setup', 'products', 'createactivity'],
    marketingAccordionRoutes: ['promotion', 'campaign'],
    closeMobileMenuOnClick: function closeMobileMenuOnClick() {
      if ((0, _isMobile.default)()) {
        this.sendAction('toggleSideNavMenu');
      }
    },

    actions: {
      sendAction: function sendAction() {
        this.sendAction('action');
      },
      sendOmnitureDataForMenuItem: function sendOmnitureDataForMenuItem(value) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.' + value);
      },
      menuTransition: function menuTransition(params, queryParams) {
        this.closeMobileMenuOnClick();
        this.sendAction('menuTransition', params, queryParams);
      },
      nonTransitionMenuItemClick: function nonTransitionMenuItemClick(item) {
        this.closeMobileMenuOnClick();
      }
    }
  });
});