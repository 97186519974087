define('lxso/helpers/bq-questions-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    staticData: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          questionsList = _ref2[0];

      var i18n = this.get('i18n');
      var travelInfo = [];
      var pickupInfo = [];
      var dropoffInfo = [];
      var message = [];
      var singleItemMessage = [];
      var travelInfoLength = this.get('staticData').getTravelerInfoFieldIds().length;
      var pickupInfoLength = this.get('staticData').getPickupInfoFieldIds().length;
      var dropoffInfoLength = this.get('staticData').getDropoffInfoFieldIds().length;
      var passportFieldIds = this.get('staticData').getAllPassportInfoFieldIds();
      var safetyFieldIds = this.get('staticData').getAllSafetyInfoFieldIds();
      var allArrivalLocationFieldIds = this.get('staticData').getAllArrivalLocationsInfoFieldIds();
      var allDepartureLocationFieldIds = this.get('staticData').getAllDropoffLocationsInfoFieldIds();

      questionsList.filter(function (item) {
        return item.get('isEnabled');
      }).forEach(function (question) {
        var fieldId = question.get('fieldId');
        if (fieldId <= 7) {
          travelInfo.push(question);
        } else if (fieldId > 7 && fieldId <= 13) {
          pickupInfo.push(question);
        } else {
          dropoffInfo.push(question);
        }
      });

      if (travelInfo.length == travelInfoLength && pickupInfo.length == pickupInfoLength && dropoffInfo.length == dropoffInfoLength) {
        return i18n.t('pages.bookingQuestions.list.bqCellMessages.allTravelPickupDropoffInfo');
      } else if (travelInfo.length > 0 && pickupInfo.length > 0 && dropoffInfo.length > 0) {
        return i18n.t('pages.bookingQuestions.list.bqCellMessages.fewTravelPickupDropoffInfo');
      } else {
        if (travelInfo.length > 0) {
          if (travelInfo.length == travelInfoLength) {
            message.push(i18n.t('pages.bookingQuestions.list.bqCellMessages.allTravelInfo'));
          } else {
            if (travelInfo.length == 1) {
              var fieldId = travelInfo[0].get('fieldId');
              var singleTravel = i18n.t('pages.bookingQuestions.list.bqCellMessages.singleTravel');
              var singleTravelName = i18n.t('pages.bookingQuestions.list.bqCellMessages.bqInfo.' + fieldId);
              singleItemMessage.push(singleTravel + ': ' + singleTravelName);
            } else {
              var allPassportFlag = 0;
              var allSafetyFlag = 0;
              var otherFlag = 0;
              travelInfo.forEach(function (question) {
                var questionId = question.get('fieldId');
                if (passportFieldIds.includes(questionId)) {
                  allPassportFlag++;
                } else if (safetyFieldIds.includes(questionId)) {
                  allSafetyFlag++;
                } else {
                  otherFlag++;
                }
              });

              if ((allPassportFlag == passportFieldIds.length || allSafetyFlag == safetyFieldIds.length) && otherFlag == 0) {
                var _singleTravel = i18n.t('pages.bookingQuestions.list.bqCellMessages.singleTravel');
                var sendText = _singleTravel + ': ';
                var allPassportText = '';
                var allSafetyText = '';
                if (allPassportFlag == passportFieldIds.length) {
                  allPassportText = i18n.t('pages.bookingQuestions.list.bqCellMessages.allPassportInformation');
                }
                if (allSafetyFlag == safetyFieldIds.length) {
                  if (allPassportFlag) {
                    allSafetyText = ' and ' + i18n.t('pages.bookingQuestions.list.bqCellMessages.allSafetyInformation2');
                  } else {
                    allSafetyText = i18n.t('pages.bookingQuestions.list.bqCellMessages.allSafetyInformation');
                  }
                }
                message.push(sendText + allPassportText + allSafetyText);
              } else {
                message.push(i18n.t('pages.bookingQuestions.list.bqCellMessages.fewTravelInfo'));
              }
            }
          }
        }
        var messageResult = [];

        if (pickupInfo.length > 0) {
          messageResult = getPickupDropoffText('pickup', pickupInfo, message, i18n, pickupInfoLength, allArrivalLocationFieldIds, allDepartureLocationFieldIds, singleItemMessage);
        }

        if (dropoffInfo.length > 0) {
          messageResult = getPickupDropoffText('dropoff', dropoffInfo, message, i18n, dropoffInfoLength, allArrivalLocationFieldIds, allDepartureLocationFieldIds, singleItemMessage);
        }

        if (messageResult.length == 2) {
          message = messageResult[0];
          singleItemMessage = messageResult[1];
        }

        var multiInfoString = void 0;
        var singleInfoString = void 0;
        var finalMessage = [];

        if (message.length > 0) {
          multiInfoString = message.join(', ').concat(i18n.t('pages.bookingQuestions.list.bqCellMessages.informationText'));
          finalMessage.push(multiInfoString);
        }

        if (singleItemMessage.length > 0) {
          singleInfoString = singleItemMessage.join(', ');
          finalMessage.push(singleInfoString);
        }

        return finalMessage.join(', ');
      }
    }
  });


  function getPickupDropoffText(type, pdInfo, message, i18n, length, arrivalInfo, departureInfo, singleItemMessage) {
    if (pdInfo.length == 1) {
      var singleInfo = void 0;
      if (type == 'pickup') {
        singleInfo = i18n.t('pages.bookingQuestions.list.bqCellMessages.singlePickup');
      } else {
        singleInfo = i18n.t('pages.bookingQuestions.list.bqCellMessages.singleDropoff');
      }
      var fieldId = pdInfo[0].get('fieldId');
      var singleInfoName = i18n.t('pages.bookingQuestions.list.bqCellMessages.bqInfo.' + fieldId);
      singleItemMessage.push(singleInfo + ': ' + singleInfoName);
    } else if (pdInfo.length == length) {
      var allInfo = void 0;
      if (type == 'pickup') {
        allInfo = i18n.t('pages.bookingQuestions.list.bqCellMessages.allPickupInfo');
      } else {
        allInfo = i18n.t('pages.bookingQuestions.list.bqCellMessages.allDropoffInfo');
      }
      message.push(allInfo);
    } else {
      var allLocationFlag = false;
      var allHotelFlag = false;
      pdInfo.forEach(function (question) {
        if (!allLocationFlag || !allHotelFlag) {
          var _fieldId = question.get('fieldId');
          if (arrivalInfo.includes(_fieldId) || departureInfo.includes(_fieldId)) {
            allLocationFlag = true;
          } else {
            allHotelFlag = true;
          }
        }
      });
      if (allLocationFlag || allHotelFlag) {
        var singlePickupDropoff = void 0;
        if (type == 'pickup') {
          singlePickupDropoff = i18n.t('pages.bookingQuestions.list.bqCellMessages.singlePickup');
        } else {
          singlePickupDropoff = i18n.t('pages.bookingQuestions.list.bqCellMessages.singleDropoff');
        }
        var sendText = singlePickupDropoff + ': ';
        var text = '';
        if (allLocationFlag) {
          if (type == 'pickup') {
            text = i18n.t('pages.bookingQuestions.list.bqCellMessages.allArrivalLocationInformation');
          } else {
            text = i18n.t('pages.bookingQuestions.list.bqCellMessages.allDepartureLocationInformation');
          }
        }
        if (allHotelFlag) {
          text = i18n.t('pages.bookingQuestions.list.bqCellMessages.allHotelInformation');
        }
        singleItemMessage.push(sendText + text);
      }
    }

    return [message, singleItemMessage];
  }
});