define('lxso/adapters/operator-cancellation', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      var url = this.host + '/' + this.namespace + '/activities/' + query + '/operator_cancellation';
      return this.ajax(url, 'GET');
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);

      var url = this.host + '/' + this.namespace + '/activities/' + snapshot.id + '/operator_cancellation';

      return this.ajax(url, 'PUT', { data: data });
    }
  });
});