define('lxso/services/current-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    applicationController: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),

    name: Ember.computed.alias('applicationController.currentRouteName')
  });
});