define('lxso/helpers/logical-or', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.logicalOr = logicalOr;
  function logicalOr(params) {
    var result = false;
    params.forEach(function (item, index) {
      var item1 = item;
      if (item1 === 0) {
        item1 = true;
      }
      result = result || item1;
    });
    return !!result;
  }

  exports.default = Ember.Helper.helper(logicalOr);
});