define('lxso/components/activity-ux-grouping/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentGroupValue: null,

    optionsCategories: Ember.computed('categories', 'selectedCategories', function () {
      return this.get('categories').filter(function (category) {
        return !Ember.get(category, 'selected');
      }).map(function (category) {
        return Ember.get(category, 'name');
      });
    }),

    disableAdd: Ember.computed('selectedCategories.length', 'currentGroupValue', function () {
      var currentCategoryValue = this.get('currentGroupValue');
      return this.get('selectedCategories.length') > 3 || !currentCategoryValue;
    }),

    actions: {
      internalCategoryChange: function internalCategoryChange(category) {
        this.sendAction('internalCategoryChange', category);
      },
      saveCategories: function saveCategories() {
        this.sendAction('saveCategories');
      },
      optionListSelected: function optionListSelected(name) {
        this.set('currentGroupValue', name);
      },
      removeFromSelected: function removeFromSelected(categoryName) {
        this.sendAction('setUxOption', categoryName, false);
      },
      addSelectedOption: function addSelectedOption(categoryName) {
        if (this.get('selectedCategories.length') < 4) {
          this.sendAction('setUxOption', categoryName, true);
        }
        this.set('currentGroupValue', undefined);
      },
      cancelEditUxGrouping: function cancelEditUxGrouping() {
        this.sendAction('cancelEditUxGrouping');
      },
      editActivityUxGrouping: function editActivityUxGrouping() {
        this.sendAction('toggleEditMode', 'uxGrouping');
      }
    }
  });
});