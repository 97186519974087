define('lxso/models/activity/v2offer/offer-attributes', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    airport: _emberData.default.attr('string'),
    airportLabel: _emberData.default.attr('string'),
    direction: _emberData.default.attr('string'),
    zones: _emberData.default.attr('array'),
    duration_type: _emberData.default.attr('string'),
    voucher_type: _emberData.default.attr('string'),
    hotel: _emberData.default.attr('string'),
    airline: _emberData.default.attr('string'),
    duration: _emberData.default.attr('number'),
    geoids: _emberData.default.attr('array')
  });
});