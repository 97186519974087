define('lxso/validators/time-range', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TimeRange = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      function getMinutes(time) {
        var timePieces = time.split(':');
        return +timePieces[0] * 60 + +timePieces[1];
      }

      if (options.start && options.end) {
        var startMinutes = getMinutes(options.start);
        var endMinutes = getMinutes(options.end);
        // const isMidnight = (options.end === '00:00' ||
        //                     options.end === '0:00' ||
        //                     options.end === '00:0' ||
        //                     options.end === '0:0');

        var totalMinutes = void 0;
        if (startMinutes > endMinutes) {
          var duration1 = 1440 - startMinutes;
          totalMinutes = duration1 + endMinutes;
        } else if (endMinutes > startMinutes) {
          totalMinutes = endMinutes - startMinutes;
        } else {
          totalMinutes = 0;
        }

        // If there is no duration, range is invalid
        // If range is greater than 24 hours, range is invalid
        if (totalMinutes === 0 || totalMinutes > 1440) {
          var errorDescriptionKey = options.descriptionKey ? options.descriptionKey : 'errors.customMessages.offers.timeRange';
          return model.get('i18n').t(errorDescriptionKey).string;
        } else {
          return true;
        }
      }
    }
  });

  TimeRange.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = TimeRange;
});