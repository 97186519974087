define('lxso/components/option-group/tickets/component', ['exports', 'lxso/constants/base-ticket-types', 'lxso/constants/gt-categories', 'lxso/utils/form-validator', 'lxso/mixins/example-modal'], function (exports, _baseTicketTypes, _gtCategories, _formValidator, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var BY_AGE_TICKET_TYPES = _baseTicketTypes.BASE_TICKET_TYPES_LIST.filter(function (tt) {
    return tt != _baseTicketTypes.BASE_TICKET_TYPES.TRAVELER;
  });
  var GT_CATEGORIES_WITH_TRAVELER_TT = [_gtCategories.GT_CATEGORIES.PRIVATE_TRANSFER];
  var GT_CATEGORIES_WITH_AGE_BASED_TT = [_gtCategories.GT_CATEGORIES.SHARED_TRANSFER, _gtCategories.GT_CATEGORIES.MASS_TRANSPORTATION];

  exports.default = Ember.Component.extend(_exampleModal.default, {
    i18n: Ember.inject.service(),
    isTicketTypeByAge: true,
    isSelectAllTicketTypesChecked: false,
    showTicketTypeSaveConfirmModal: false,
    BASE_TICKET_TYPES: _baseTicketTypes.BASE_TICKET_TYPES,
    ticketTypes: Ember.computed('ticketTypesV4', function () {
      return this.get('ticketTypesV4.ticketTypes');
    }),
    capacityTicketTypes: Ember.computed('ticketTypesV4', function () {
      return this.get('ticketTypesV4.capacityTicketTypes');
    }),

    ticketTypesToDisplay: Ember.computed('isTicketTypeByAge', 'ticketTypes', function () {
      var isTicketTypeByAge = this.get('isTicketTypeByAge');
      var activityCategory = this.get('activity.operational.category');
      var ticketTypesForActivityCategory = this.getTicketTypesAvailableForActivityCategory(activityCategory);
      var filteredTicketTypes = void 0;
      if (isTicketTypeByAge) {
        filteredTicketTypes = ticketTypesForActivityCategory.filter(function (ticket) {
          return ticket.get('baseLabel') !== _baseTicketTypes.BASE_TICKET_TYPES.TRAVELER;
        });
      } else {
        filteredTicketTypes = ticketTypesForActivityCategory.filter(function (ticket) {
          return ticket.get('baseLabel') === _baseTicketTypes.BASE_TICKET_TYPES.TRAVELER || ticket.get('baseLabel') === _baseTicketTypes.BASE_TICKET_TYPES.STUDENT;
        });
      }
      return filteredTicketTypes;
    }),

    getTicketTypesAvailableForActivityCategory: function getTicketTypesAvailableForActivityCategory(category) {
      return this.get('ticketTypes').filter(function (ticketType) {
        if (GT_CATEGORIES_WITH_TRAVELER_TT.includes(category)) {
          return ticketType.get('baseLabel') === _baseTicketTypes.BASE_TICKET_TYPES.TRAVELER;
        } else if (GT_CATEGORIES_WITH_AGE_BASED_TT.includes(category)) {
          return _baseTicketTypes.BASE_TICKET_TYPES_SHARED_OR_MASS_GT_LIST.includes(ticketType.get('baseLabel'));
        } else {
          return true; //non-GT products or activities
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.initMinMaxTicketsDropdownOptions();
      this.initCapacityTicketsDropdownOptions();
      this.setGTFlag();
      this.setTicketTypeByAgeFlag();
      this.setSelectAllTicketTypesFlag();
      this.set('deletedCapacityTickets', Ember.A([]));
    },
    initMinMaxTicketsDropdownOptions: function initMinMaxTicketsDropdownOptions() {
      var options = [].concat(_toConsumableArray(Array(_baseTicketTypes.TICKETS_PER_BOOKING_UPPER_LIMIT).keys())).map(function (i) {
        return i + 1;
      });
      this.set('minTicketOptions', options);
      this.set('maxTicketOptions', options);
    },
    initCapacityTicketsDropdownOptions: function initCapacityTicketsDropdownOptions() {
      var options = [].concat(_toConsumableArray(Array(_baseTicketTypes.PERSONS_PER_CAPACITY_TICKET_UPPER_LIMIT).keys())).map(function (i) {
        return i + 1;
      });
      this.set('capacityTicketPersonCountOptions', options);
    },
    setGTFlag: function setGTFlag() {
      var activityCategory = this.get('activity.operational.category');
      this.set('isGTProduct', _gtCategories.GT_CATEGORY_LIST.includes(activityCategory));
    },
    setTicketTypeByAgeFlag: function setTicketTypeByAgeFlag() {
      var activityCategory = this.get('activity.operational.category');
      if (this.get('isGTProduct')) {
        this.set('isTicketTypeByAge', GT_CATEGORIES_WITH_AGE_BASED_TT.includes(activityCategory));
        this.set('isTicketTypeAgeNonAgeSwitchAllowed', false);
      } else {
        var hasTravelerTicketType = this.get('ticketTypes').findBy('baseLabel', _baseTicketTypes.BASE_TICKET_TYPES.TRAVELER).get('isSelected');
        this.set('isTicketTypeByAge', !hasTravelerTicketType);
        this.set('isTicketTypeAgeNonAgeSwitchAllowed', true);
      }
    },
    setSelectAllTicketTypesFlag: function setSelectAllTicketTypesFlag() {
      var displayedTicketTypesCount = this.get('ticketTypesToDisplay').length;
      var selectedTicketTypesCount = this.get('ticketTypesToDisplay').filter(function (tt) {
        return tt.get('isSelected');
      }).length;
      this.set('isSelectAllTicketTypesChecked', displayedTicketTypesCount === selectedTicketTypesCount);
    },
    validateTicketTypes: function validateTicketTypes() {
      return (0, _formValidator.validate)({ model: this.get('ticketTypesV4'),
        attributes: ['ticketTypesOverlap', 'capacityTicketTypesOverlap'] });
    },
    _validateIfInvalid: function _validateIfInvalid() {
      var _this = this;

      if (this.get('errors')) {
        this.validateTicketTypes().then(function () {
          _this.set('errors', null);
        }).catch(function (errors) {
          _this.set('errors', errors);
        });
      }
    },
    clearTicketInfo: function clearTicketInfo(ticketType) {
      ticketType.set('minimumAge', null);
      ticketType.set('maximumAge', null);
      ticketType.set('minimumTicketsPerBooking', _baseTicketTypes.MIN_TICKETS_PER_BOOKING_DEFAULT);
      ticketType.set('maximumTicketsPerBooking', _baseTicketTypes.MAX_TICKETS_PER_BOOKING_DEFAULT);
    },
    clearNonDisplayedTickets: function clearNonDisplayedTickets() {
      var ticketTypesLabelsDisplayed = this.get('ticketTypesToDisplay').map(function (tt) {
        return tt.get('baseLabel');
      });
      this.get('ticketTypes').filter(function (tt) {
        return !ticketTypesLabelsDisplayed.includes(tt.get('baseLabel'));
      }).forEach(function (tt) {
        tt.set('isSelected', false);
        tt.set('minimumAge', null);
        tt.set('maximumAge', null);
      });
    },


    actions: {
      validateIfInvalid: function validateIfInvalid() {
        this._validateIfInvalid();
      },
      editTicketTypes: function editTicketTypes() {
        this.sendAction('editTicketTypes');
      },
      ticketTypeAgeNonAgeChange: function ticketTypeAgeNonAgeChange(selectedOption) {
        this.set('isTicketTypeByAge', selectedOption === 'byAge');
        var selectedTicketTypesForMinMaxAgeCalc = this.get('ticketTypes').filter(function (tt) {
          return tt.get('isSelected') && BY_AGE_TICKET_TYPES.includes(tt.get('baseLabel'));
        });
        if (selectedTicketTypesForMinMaxAgeCalc.length > 0 && !this.get('isTicketTypeByAge')) {
          var minAge = Math.min.apply(Math, _toConsumableArray(selectedTicketTypesForMinMaxAgeCalc.map(function (tt) {
            return parseInt(tt.get('minimumAge')) || 0;
          })));
          var maxAge = Math.max.apply(Math, _toConsumableArray(selectedTicketTypesForMinMaxAgeCalc.map(function (tt) {
            return parseInt(tt.get('maximumAge')) || 0;
          })));
          var travelerTicketType = this.get('ticketTypes').find(function (tt) {
            return tt.get('baseLabel') === _baseTicketTypes.BASE_TICKET_TYPES.TRAVELER;
          });
          travelerTicketType.set('isSelected', true);
          travelerTicketType.set('minimumAge', minAge);
          travelerTicketType.set('maximumAge', maxAge);
          travelerTicketType.set('minimumTicketsPerBooking', _baseTicketTypes.MIN_TICKETS_PER_BOOKING_DEFAULT);
          travelerTicketType.set('maximumTicketsPerBooking', _baseTicketTypes.MAX_TICKETS_PER_BOOKING_DEFAULT);
        }
        this.setSelectAllTicketTypesFlag();
        this._validateIfInvalid();
      },
      handleSelectAllTicketTypesCheckboxChange: function handleSelectAllTicketTypesCheckboxChange() {
        var _this2 = this;

        var isAllTicketsSelected = this.get('isSelectAllTicketTypesChecked');
        this.get('ticketTypesToDisplay').forEach(function (tt) {
          tt.set('isSelected', isAllTicketsSelected);
          if (!isAllTicketsSelected) {
            _this2.clearTicketInfo(tt);
          }
        });
      },
      handleTicketTypeSelect: function handleTicketTypeSelect(tt) {
        if (!tt.get('isSelected')) {
          this.clearTicketInfo(tt);
        }
        if (!tt.get('minimumAge')) {
          tt.set('minimumAge', 0);
        }
        if (!tt.get('maximumAge')) {
          tt.set('maximumAge', 0);
        }
        this.setSelectAllTicketTypesFlag();
        this._validateIfInvalid();
      },
      handleMinimumAgeChange: function handleMinimumAgeChange(ticket, event) {
        var age = parseInt(event.target.value);
        ticket.set('minimumAge', age ? age : 0);
      },
      handleMaximumAgeChange: function handleMaximumAgeChange(ticket, event) {
        var age = parseInt(event.target.value);
        ticket.set('maximumAge', age ? age : 0);
      },
      handleMinTicketsChange: function handleMinTicketsChange(ticket, event) {
        ticket.set('minimumTicketsPerBooking', parseInt(event.target.value) || null);
        this._validateIfInvalid();
      },
      handleMaxTicketsChange: function handleMaxTicketsChange(ticket, event) {
        ticket.set('maximumTicketsPerBooking', parseInt(event.target.value) || null);
        this._validateIfInvalid();
      },
      addCapacityTicketType: function addCapacityTicketType() {
        this.get('capacityTicketTypes').pushObject({
          minPerGroup: 1,
          maxPerGroup: 1
        });
      },
      handleMinimumTravelerChange: function handleMinimumTravelerChange(ticket, event) {
        ticket.set('minPerGroup', parseInt(event.target.value));
      },
      handleMaximumTravelerChange: function handleMaximumTravelerChange(ticket, event) {
        ticket.set('maxPerGroup', parseInt(event.target.value));
      },
      handleIndependentlyBookableSelect: function handleIndependentlyBookableSelect(ticket, event) {},
      handleDeleteCapacityTicket: function handleDeleteCapacityTicket(ticket) {
        this.get('capacityTicketTypes').removeObject(ticket);
        if (ticket.get('ticketTypeId') > 0) {
          this.get('deletedCapacityTickets').pushObject(ticket);
        }
      },
      cancelTicketTypeEdit: function cancelTicketTypeEdit() {
        this.sendAction('cancelTicketTypes', this.get('ticketTypesV4'));
        this.set('deletedCapacityTickets', Ember.A([]));
        this.setTicketTypeByAgeFlag();
        this.set('errors', null);
      },
      saveAndContinue: function saveAndContinue() {
        var _this3 = this;

        this.clearNonDisplayedTickets();
        this.set('errors', null);
        this.validateTicketTypes().then(function () {
          _this3.set('errors', null);
          var deletedTickets = [];
          _this3.get('ticketTypesV4.ticketTypes').map(function (tt) {
            var changedAttributes = tt.changedAttributes();
            if (changedAttributes && changedAttributes.isSelected && changedAttributes.isSelected[0]) {
              deletedTickets.push(tt.get('baseLabel'));
            }
          });
          if (deletedTickets.length > 0 || _this3.get('deletedCapacityTickets.length') > 0) {
            var confirmAction = function confirmAction() {
              _this3.set('showTicketTypeSaveConfirmModal', false);
              _this3.sendAction('saveTickets', _this3.get('ticketTypesV4'));
              _this3.set('deletedCapacityTickets', Ember.A([]));
            };
            _this3.set('ticketAgeTypeSaveConfirmAction', confirmAction);
            _this3.set('deletedTickets', deletedTickets);
            _this3.set('showTicketTypeSaveConfirmModal', true);
          } else {
            _this3.sendAction('saveTickets', _this3.get('ticketTypesV4'));
          }
        }).catch(function (errors) {
          _this3.set('errors', errors);
          (0, _formValidator.scrollToFirstError)();
        });
      },
      ticketTypeDeleteConfirmYes: function ticketTypeDeleteConfirmYes() {
        this.get('ticketAgeTypeSaveConfirmAction')();
      },
      ticketTypeDeleteConfirmNo: function ticketTypeDeleteConfirmNo() {
        this.set('ticketAgeTypeSaveConfirmAction', null);
        this.set('showTicketTypeSaveConfirmModal', false);
        this.set('deletedCapacityTickets', Ember.A([]));
      }
    }
  });
});