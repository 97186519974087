define('lxso/helpers/to-percent', ['exports', 'lxso/utils/to-percent'], function (exports, _toPercent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toPercent = toPercent;
  function toPercent(args /*, hash*/) {
    return (0, _toPercent.default)(args);
  }

  exports.default = Ember.Helper.helper(toPercent);
});