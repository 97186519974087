define('lxso/components/uitk-box/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['componentClasses'],
    actions: {
      openStep: function openStep() {
        this.sendAction('openStep', this.get('headerClass'));
      }
    }
  });
});