define('lxso/serializers/campaign', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    normalize: function normalize(typeClass, hash) {
      hash.id = hash.groupIndex;
      return this._super.apply(this, arguments);
    }
  });
});