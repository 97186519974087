define('lxso/helpers/if-else', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ifElse = ifElse;
  function ifElse(params /*, hash*/) {
    return params[0] ? params[1] : params[2];
  }

  exports.default = Ember.Helper.helper(ifElse);
});