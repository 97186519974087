define('lxso/components/list-inputs/component', ['exports', 'lxso/mixins/error-list-indexes-helpers', 'lxso/utils/omniture'], function (exports, _errorListIndexesHelpers, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend(_errorListIndexesHelpers.default, {
    classNames: 'list-inputs',

    willRender: function willRender() {
      this.setup();
    },


    //back button causes a phantom re-render for no apparent reason
    //this makes sure the minimum boxes exist
    setup: function setup() {
      if (_typeof(this.get('placeholders')) !== 'object') {
        this.set('placeholders', ['']);
      }

      var values = this.get('values');
      var minLength = this.get('min-inputs');

      // Fill the values array up to the min-values amount
      if (values.length < minLength) {
        var n = minLength - values.length;
        for (var i = 0; i < n; i++) {
          values.pushObject('');
        }
      }
    },


    canRemove: Ember.computed('values', {
      get: function get() {
        return this.get('values').length > this.get('min-inputs');
      }
    }),

    canAdd: Ember.computed('values', {
      get: function get() {
        return this.get('values').length < this.get('max-inputs');
      }
    }),

    actions: {

      /**
       * Action to update the values array on the controller with the values from
       * the inputs in the template. Unsure why this doesn't happen automatically.
       * Loop through the inputs, grab their values via jQuery, and update the
       * array at each index. This is done without using Ember's MutableArray
       * `replace` method to avoid rerendering the template and losing focus to
       * the input field.
       *
       * This action must be passed to the underlying input helper in the
       * lx-input template via the `change` attribute.
       * e.g. change=(action 'updateValue')
       */
      updateValue: function updateValue(value, index) {
        var values = this.get('values');
        values[index] = value;
      },
      addNew: function addNew(omnitureValue) {
        if (omnitureValue) {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.' + omnitureValue);
        }
        var values = this.get('values');

        if (values.length < this.get('max-inputs')) {
          values.pushObject('');
          this.notifyPropertyChange('values');
        }
      },
      removeNew: function removeNew(index) {
        var values = this.get('values');

        if (values.length > this.get('min-inputs')) {
          values.removeAt(index);
          this.notifyPropertyChange('values');
        }

        this.sendAction('removeNew', index);
      }
    }

  });
});