define('lxso/components/timing-capacity-display/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    daysOfWeek: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
    didReceiveAttrs: function didReceiveAttrs() {
      var timingCapacity = this.get('timingCapacity');
      var monday = timingCapacity.monday;
      this.set('displayVariableCapacity', this.get('daysOfWeek').some(function (day) {
        return timingCapacity[day] != monday;
      }));
    },

    actions: {
      removeItem: function removeItem() {
        this.sendAction('deleteItem', this.get('index'));
      }
    }
  });
});