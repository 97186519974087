define('lxso/helpers/is-empty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEmpty = isEmpty;
  function isEmpty(params /*, hash*/) {
    return params[0].length > 0;
  }

  exports.default = Ember.Helper.helper(isEmpty);
});