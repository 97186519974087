define('lxso/adapters/campaign', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'api/v3',

    query: function query(store, type, _query) {
      var branchId = _query.branchId;
      var url = this.host + '/' + this.namespace + '/campaign/supplierBranch/' + branchId;
      return this.ajax(url, 'GET');
    }
  });
});