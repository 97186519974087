define('lxso/routes/account-settings/index', ['exports', 'lxso/mixins/feature-flag', 'lxso/config/environment', 'lxso/utils/form-validator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _featureFlag, _environment, _formValidator, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    supplierBranch: Ember.inject.service('supplier-branch'),
    permissions: Ember.inject.service(),

    model: function model() {
      var supplierId = this.get('session.data.authenticated.selectedBranch.supplier_id');

      if (supplierId) {
        return Ember.RSVP.hash({
          accountSettings: this.store.queryRecord('account-settings', {}),
          supplierBranches: this.get('supplierBranch').getAllBranchesBySupplierID(supplierId)
        });
      } else {
        return Ember.RSVP.hash({
          accountSettings: this.store.queryRecord('account-settings', {})
        });
      }
    },
    sortSupplierBranch: function sortSupplierBranch(supplierBranches) {
      var activeSupplierBranches = [];
      var inactiveSupplierBranches = [];
      var suspendedExpediaBranches = [];
      var suspendedPausedBranches = [];
      var suspendedMappingBranches = [];
      supplierBranches.forEach(function (sb) {
        if (sb.status.toLowerCase() === 'active') {
          activeSupplierBranches.push(sb);
        } else if (sb.status.toLowerCase() === 'inactive') {
          inactiveSupplierBranches.push(sb);
        } else if (sb.status.toLowerCase() === 'suspendedexpedia') {
          suspendedExpediaBranches.push(sb);
        } else if (sb.status.toLowerCase() === 'suspendedpaused') {
          suspendedPausedBranches.push(sb);
        } else if (sb.status.toLowerCase() === 'suspended') {
          suspendedMappingBranches.push(sb);
        }
      });
      /*supplier-branch-flag-Inactive
      * Not show inactive sb to external user
      * */
      var isInternalUser = this.get('permissions').checkEnabled('V3InternalSettings');
      var nonInactiveSupplierBranches = activeSupplierBranches.concat(suspendedPausedBranches).concat(suspendedExpediaBranches).concat(suspendedMappingBranches).concat(inactiveSupplierBranches);

      var allSupplierBranches = nonInactiveSupplierBranches.concat(inactiveSupplierBranches);

      return isInternalUser ? allSupplierBranches : nonInactiveSupplierBranches;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      try {
        var newSupplierBranch = this.controllerFor('new-supplier-branch');
        this.controller.set('lastSaved', newSupplierBranch.get('lastSaved'));
      } catch (e) {}

      controller.set('accountSettings', model.accountSettings);
      if (this.get('session.data.authenticated.selectedBranch.supplier_id')) {
        controller.set('supplierBranches', this.sortSupplierBranch(model.supplierBranches));
        controller.set('supplierBranchId', this.get('session.data.authenticated.selectedBranch.supplier_branch_id'));
        controller.set('supplierId', this.get('session.data.authenticated.selectedBranch.supplier_id'));
      }
      controller.set('editingAccountDetails', false);
    },
    resetController: function resetController(controller) {
      var accountSettings = controller.get('accountSettings');

      if (accountSettings && accountSettings.get('hasDirtyAttributes')) {
        accountSettings.rollbackAttributes();
      }
    },
    switchSupplierBranch: function switchSupplierBranch(branchId) {
      var _this = this;

      return this.get('supplierBranch').getSupplierBranchData([branchId]).then(function (supplierBranchData) {
        _this.get('supplierBranch').updateSelectedBranch(supplierBranchData[0]);
      });
    },


    actions: {
      editAccountDetails: function editAccountDetails() {
        this.controller.set('editingAccountDetails', true);
      },
      cancelEditAccountDetails: function cancelEditAccountDetails() {
        this.controller.set('editingAccountDetails', false);
      },
      save: function save() {
        'use strict';

        var _this2 = this;

        this.controller.set('errors', null);
        this.controller.set('lastSaved', 'accountSetting');

        var accountSettings = this.controller.get('accountSettings');

        (0, _formValidator.validate)({ model: accountSettings, attributes: ['firstName', 'lastName'] }).then(function () {
          var data = {
            'first_name': accountSettings.get('firstName'),
            'last_name': accountSettings.get('lastName')
          };
          var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/account_settings';
          return _this2.get('ajaxWrapper').ajaxPut(url, data);
        }).then(function () {
          _this2.controller.set('editingAccountDetails', false);
          _this2.get('notifications').success(_this2.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (errors) {
          _this2.controller.set('errors', errors);
        });
      },
      viewSupplierBranch: function viewSupplierBranch(branchId) {
        var _this3 = this;

        this.switchSupplierBranch(branchId).then(function () {
          return _this3.transitionTo('products', branchId);
        });
      },
      editSupplierBranch: function editSupplierBranch(branchId) {
        var _this4 = this;

        this.switchSupplierBranch(branchId).then(function () {
          return _this4.transitionTo('supplier-branch-settings', branchId);
        });
      }
    }
  });
});