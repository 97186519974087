define('lxso/constants/cancellation-option', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var SELECT_REASON_ARRAY = new Map([['Reason for Cancellation', ''], ['Natural disaster', 'NATURAL_DISASTER'], ['Product no longer available', 'PRODUCT_UNAVAILABLE'], ['Bookings conditional in nature', 'BOOKING_CONDITIONAL_IN_NATURE'], ['Business closure', 'BUSINESS_CLOSURE'], ['No instant confirmation', 'BOOKING_NOT_CONFIRMED'], ['Customer requested', 'CUSTOMER_REQUESTED'], ['Add your reason', 'OTHER']]);

  var SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL = new Map([['Reason for Cancellation', ''], ['Bookings conditional in nature', 'BOOKING_CONDITIONAL_IN_NATURE'], ['Business closure', 'BUSINESS_CLOSURE'], ['Customer requested', 'CUSTOMER_REQUESTED'], ['Incorrect Service Booked', 'INCORRECT_SERVICE_BOOKED'], ['Late booking confirmation', 'BOOKING_NOT_CONFIRMED'], ['Minimum Capacity Not Met', 'MINIMUM_CAPACITY_NOT_MET'], ['Product no longer available', 'PRODUCT_UNAVAILABLE'], ['Service Partially Provided', 'SERVICE_PARTIALLY_PROVIDED'], ['Session Fully Booked', 'SESSION_FULLY_BOOKED'], ['Unforeseen Circumstances', 'NATURAL_DISASTER'], ['Weather Conditions', 'WEATHER_CONDITIONS'], ['Add your reason', 'OTHER']]);
  var SELECT_REASON_ARRAY_FOR_FILE_UPLOAD_AND_CANCEL = new Map([['Reason for Cancellation', ''], ['Bulk Cancel Upload', 'BULK_CANCEL_UPLOAD']].concat(_toConsumableArray(SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL)));
  exports.SELECT_REASON_ARRAY = SELECT_REASON_ARRAY;
  exports.SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL = SELECT_REASON_ARRAY_FOR_BULK_SEARCH_AND_CANCEL;
  exports.SELECT_REASON_ARRAY_FOR_FILE_UPLOAD_AND_CANCEL = SELECT_REASON_ARRAY_FOR_FILE_UPLOAD_AND_CANCEL;
});