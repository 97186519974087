define('lxso/routes/activity/location', ['exports', 'lxso/config/environment', 'lxso/mixins/publish-api', 'lxso/utils/omniture', 'lxso/utils/form-validator'], function (exports, _environment, _publishApi, _omniture, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_publishApi.default, {
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    session: Ember.inject.service('session'),
    featureFlags: Ember.inject.service(),
    loadingTime: Ember.inject.service(),
    v4LogisticsService: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.Location'
    },

    model: function model() {
      this._super.apply(this, arguments);
      var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/enums/countries';
      return this.get('ajaxWrapper').ajaxGet(url);
    },
    setupController: function setupController(controller) {
      var _this = this;

      this.controller.set('dirty', false);
      controller.set('activity', this.modelFor('activity'));
      controller.set('validity', []);
      controller.set('addressDirty', []);
      controller.set('validateComponentTrigger', 0);

      this.controller.set('redemptionLocationModel', controller.get('activity.redemption_address'));
      if (controller.get('redemptionLocationModel.addresses').get('length') === 0) {
        this.controller.get('activity.redemption_address.addresses').createFragment();
      }

      controller.set('countrySource', this.modelFor('activity/location').data);
      controller.set('activityRoute', this.controllerFor('activity'));
      controller.set('setValidity', function (index, isValid) {
        return _this.send('setValidity', index, isValid);
      });
      controller.set('setAddressDirty', function (index, isAddressDirty) {
        return _this.send('setAddressDirty', index, isAddressDirty);
      });
      controller.set('isSaveDisabled', true);
      var activity = this.modelFor('activity');
      this.controller.set('activityAddress', activity.get('activity_location'));
      controller.set('activityCountrySource', this.modelFor('activity/location').data);
      this.setupRollback();
      Ember.run.next(function () {
        var timeTaken = _this.get('loadingTime').getLoadTime();
        console.info('%c Activity location page load time is ' + timeTaken + ' seconds', 'font-weight: bold');
      });
    },
    setupRollback: function setupRollback() {
      this.controller.set('redemptionAddressesCopy', this.controller.get('activity.redemption_address').toJSON());
    },
    rollback: function rollback() {
      this.controller.set('activity.redemption_address', this.controller.get('redemptionAddressesCopy'));
    },
    updateSaveDisabled: function updateSaveDisabled() {
      // called when a map component is made dirty or validity is changed
      // commented portions will be used in publish work flow
      var isSaveDisabledValidity = this.controller.get('validity').filter(function (item) {
        return !item;
      }).length > 0;
      var isSaveDisabledDirty = this.controller.get('addressDirty').any(function (isDirty) {
        return isDirty;
      });
      this.controller.set('dirty', isSaveDisabledDirty);
      //trick to tell tests that populate has occured
      this.controller.set('continueEnabled', !isSaveDisabledValidity);
    },
    getInvalidComponents: function getInvalidComponents() {
      return this.controller.get('validity').filter(function (v) {
        return v === false;
      });
    },
    validateComponents: function validateComponents() {
      this.controller.set('validateComponentTrigger', this.controller.get('validateComponentTrigger') + 1);
    },
    saveProductLocation: function saveProductLocation(activity) {
      var activityLocation = activity.get('activity_location');
      if (activity.get('isContentAndLogisticsMigratedToV4')) {
        return this.get('v4LogisticsService').updateActivityAddress(activity.get('id'), activityLocation.toJSON());
      } else {
        return activity.updateLocation(activityLocation);
      }
    },

    actions: {
      saveLocation: function saveLocation() {
        var _this2 = this;

        var route = this;
        var controller = this.controller;
        var activity = route.controller.get('activity');
        var activityAddress = controller.get('activityAddress');
        if (activity.get('isActivityPublished')) {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Publish');
        } else {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Save');
        }

        controller.set('saving', true);

        //force validate components so errors show
        //if invalid components exist

        if (this.getInvalidComponents().length > 0) {
          this.validateComponents();
          this.controller.set('errors', this.getInvalidComponents());
          (0, _formValidator.scrollToFirstError)();
          controller.set('saving', false);
        } else {
          var validatedAttributes = ['city', 'country', 'formatted_address'];
          var errorClassArray = Ember.$('.invalid-anchor');
          if (errorClassArray.length === 0) {
            var validationPromiseArray = activity.get('redemption_address.addresses').map(function (address) {
              return (0, _formValidator.validate)({ model: address, attributes: validatedAttributes });
            });
            validationPromiseArray.push((0, _formValidator.validate)({ model: activityAddress, attributes: validatedAttributes }));
            Ember.RSVP.all(validationPromiseArray).then(function () {
              if (activity.get('isContentAndLogisticsMigratedToV4')) {
                return _this2.get('v4LogisticsService').updateRedemptionAddresses(activity.get('id'), activity.get('redemption_address').toJSON());
              } else {
                return activity.updateRedemptionAddresses(activity.get('redemption_address').toJSON());
              }
            }).then(function () {
              if (_this2.controller.get('addressDirty')[999] === true) {
                return _this2.saveProductLocation(activity);
              }
            }).then(function () {
              return activity.reload();
            }).then(function (activity) {
              _this2.setupRollback();
              // Send page completion data to Omniture
              (0, _omniture.default)({
                events: 'event21' //event21 tracks successful completion of "Location" page
              });

              _this2.controller.set('saving', false);
              if (activity.get('isActivityPublished')) {
                _this2.get('notifications').success(_this2.get('i18n').t('components.alert.saveSuccess'));
                _this2.controller.set('dirty', false);
              } else {
                if (_this2.get('session.data.fromPublish')) {
                  _this2.get('session').set('data.fromPublish', false);
                  route.transitionTo('activity.publish');
                } else if (_this2.controller.get('activity.isHealthAndSafetyEnabled')) {
                  route.transitionTo('activity.health-and-safety', activity.get('id'));
                } else {
                  route.transitionTo('activity.customer-service', activity.get('id'));
                }
              }
            }).catch(function (errors) {
              _this2.validateComponents();
              controller.set('saving', false);
              (0, _formValidator.scrollToFirstError)();
              controller.set('errors', _this2.getInvalidComponents() || errors.errors || errors);
              if (errors.errors) {
                _this2.get('notifications').error(_this2.get('i18n').t('components.alert.saveFailure'));
                // Show error notification only for server error
              }
            });
          } else {
            this.validateComponents();
            (0, _formValidator.scrollToFirstError)();
            controller.set('saving', false);
            controller.set('errors', errorClassArray);
          }
        }
      },
      addNewRedemptionAddress: function addNewRedemptionAddress() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AddAnotherMeetingPoint');
        this.controller.get('activity.redemption_address.addresses').createFragment();
      },
      removeRedemptionAddress: function removeRedemptionAddress(address, index) {
        this.controller.get('activity.redemption_address.addresses').removeObject(address);
        this.controller.get('validity').splice(index, 1);
        this.controller.get('addressDirty').splice(index, 1);
        this.controller.set('dirty', true);
      },
      mapLocationValidityChange: function mapLocationValidityChange(errors) {
        this.controller.set('errors', errors);
      },
      willTransition: function willTransition() {
        this.rollback();
        //for tests
        window.pageName = undefined;
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'location';

        this.controller.set('errors', '');
        this.controller.set('saving', false);
      },
      setValidity: function setValidity(index, isValid) {
        'use strict';

        this.controller.get('validity')[index] = isValid;
        this.updateSaveDisabled();
      },
      setAddressDirty: function setAddressDirty(index, isDirty) {
        this.controller.get('addressDirty')[index] = isDirty;
        this.updateSaveDisabled();
      }
    }
  });
});