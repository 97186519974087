define('lxso/adapters/supplier-branch', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var supplierId = snapshot.adapterOptions.supplierId;
      var url = this.host + '/' + this.namespace + '/suppliers/' + supplierId + '/branch';

      serializer.serializeIntoHash(data, type, snapshot);

      data.status = 'Active';
      data.supplier_branch_id = 1;
      data.supplier_id = 0;
      data.supplier_name = '';
      data.vendor_id = 0;

      return this.ajax(url, 'POST', { data: data });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var supplierBranchId = snapshot.id;

      serializer.serializeIntoHash(data, type, snapshot);
      var putData = void 0;
      var newApiKey = void 0;
      var updatedDateAttribute = 'updatedDate';

      if (snapshot.changedAttributes()[updatedDateAttribute]) {
        newApiKey = snapshot.changedAttributes()[updatedDateAttribute][1];
      } else {
        newApiKey = '';
      }

      if (snapshot.adapterOptions.isConnectivityData) {
        putData = {
          api_key: newApiKey,
          connectivityStatus: data.connectivity_status,
          externalSupplierBranchId: data.externalSupplierBranchId,
          platformId: data.platformId,
          supplierBranchName: data.supplier_branch_name
        };
        var url = this.host + '/' + this.namespace + '/supplier_connectivity/' + supplierBranchId;
        return this.ajax(url, 'PUT', { data: putData });
      }
    }
  });
});