define('lxso/components/offer-group/multi-offer-hours-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var durationInMinutes = this.get('schedule.durationInMinutes');
      var durationInHours = Math.floor(durationInMinutes / 60);
      this.set('durationInHours', durationInHours);
    },


    actions: {
      validateIfInvalid: function validateIfInvalid() {
        this.sendAction('validateIfInvalid');
      },
      hoursChange: function hoursChange(hours) {
        var durationInMinutes = hours * 60;
        this.set('schedule.durationInMinutes', durationInMinutes);
        this.set('durationInHours', parseInt(hours));
      }
    }
  });
});