define('lxso/routes/supplier', ['exports', 'lxso/config/environment', 'lxso/utils/form-validator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-permission', 'lxso/mixins/feature-flag'], function (exports, _environment, _formValidator, _authenticatedRouteMixin, _requirePermission, _featureFlag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, {
    requiredPermission: 'V3InternalSettings',

    i18n: Ember.inject.service(),

    model: function model(params) {
      if (params.id === 'new') {
        return this.store.createRecord('supplier');
      } else {
        return this.store.findRecord('supplier', params.id);
      }
    },
    setupController: function setupController(controller, model) {
      this.controller.set('errors', []);
      this.controller.set('serverError', []);
      this.controller.set('saving', false);
      this.controller.set('supplier', model);
      this.controller.set('isNew', model.get('isNew'));
    },


    actions: {
      saveNewSupplier: function saveNewSupplier() {
        var _this = this;

        var supplier = this.controller.get('supplier');
        this.controller.set('saving', true);
        (0, _formValidator.validate)({
          model: supplier,
          attributes: ['name']
        }).then(function () {
          _this.controller.set('errors', []);
          return supplier.save();
        }).then(function (response) {
          _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
          return _this.transitionTo('supplier', response.get('id'));
        }).catch(function (errors) {
          if (errors.details) {
            _this.controller.set('serverError', errors);
          } else {
            _this.controller.set('errors', errors);
          }
        }).finally(function () {
          return _this.controller.set('saving', false);
        });
      }
    }
  });
});