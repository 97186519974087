define('lxso/routes/product-setup', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-supplier-branch', 'lxso/mixins/require-permission', 'lxso/utils/omniture', 'lxso/mixins/feature-flag'], function (exports, _authenticatedRouteMixin, _requireSupplierBranch, _requirePermission, _omniture, _featureFlag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, _requirePermission.default, {
    requiredPermission: 'V3CreateProduct',

    omniture: {
      pageName: 'page.LX-Supply.ProductSetup'
    },

    setupController: function setupController() {
      this.setPageLoadTime();
    },
    setPageLoadTime: function setPageLoadTime() {
      var loadTime = moment();
      this.controller.set('pageLoadedAt', loadTime);
    },
    getButtonClickTime: function getButtonClickTime() {
      var initTime = this.controller.get('pageLoadedAt');
      var actionTime = moment();
      var duration = moment.duration(actionTime.diff(initTime));
      var asDays = duration.asDays();
      if (asDays > 1) {
        return asDays + ' days';
      } else {
        var seconds = duration.seconds();
        var minutes = duration.minutes();
        var hours = duration.hours();
        var durationInHrMinSec = hours + ' hrs, ' + minutes + ' mins, ' + seconds + ' secs';
        return durationInHrMinSec;
      }
    },

    actions: {
      getStarted: function getStarted() {
        var buttonClickTime = this.getButtonClickTime();
        // sendOmnitureDataOnClick('LXPCV3.PRODSET.GETSTARTED', buttonClickTime);
        // use the above code if we need to log time taken to click get started button
        (0, _omniture.sendOmnitureDataOnClick)('LXPCV3.PRODSET.GETSTARTED');
        this.transitionTo('createactivity');
      },
      willTransition: function willTransition() {
        delete window.pageName;
      },
      didTransition: function didTransition() {
        window.pageName = 'productSetUp';
        return true;
      }
    }
  });
});