define('lxso/routes/reviews', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lxso/config/environment'], function (exports, _authenticatedRouteMixin, _featureFlag, _requirePermission, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SUPPORTED_REVIEWS = ['supplier', 'supplier-branch', 'offer'];

  var route = void 0;

  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, {
    requiredPermission: 'ReviewsView',
    featureFlags: Ember.inject.service(),
    endlessScrollService: Ember.inject.service('endless-scroll'),
    reviewHelper: Ember.inject.service('review-helper'),
    reviewVault: Ember.inject.service('reviewvault'),
    ajaxWrapper: Ember.inject.service(),

    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true },
      sortBy: { refreshModel: true },
      order: { refreshModel: true },
      includeUncommented: { refreshModel: true },
      ratingFilter: { refreshModel: true },
      activityId: { refreshModel: true },
      from: { refreshModel: false },
      to: { refreshModel: false }
    },

    omniture: {
      pageName: 'page.LX-Supply.Review-list'
    },

    deactivate: function deactivate() {
      'use strict';

      this.get('endlessScrollService').deactivateEndlessScrolling();
    },
    checkParams: function checkParams(newParams) {
      'use strict';

      var oldParams = this.get('oldParams') ? Ember.$.extend({}, this.get('oldParams')) : undefined;
      var isNextQuery = this.isNextQuery(oldParams, newParams);
      this.set('isNextPage', isNextQuery);
    },
    isNextQuery: function isNextQuery(_oldParams, _newParams) {
      'use strict';

      if (!_oldParams) {
        return !_newParams || +_newParams.page === 1;
      }

      if (+_newParams.page !== +_oldParams.page + 1) {
        return false;
      }

      var stripPage = function stripPage(_params) {
        var params = Ember.$.extend({}, _params);
        delete params.page;
        return params;
      };

      return this.deepEquals(stripPage(_newParams), stripPage(_oldParams));
    },
    deepEquals: function deepEquals(firstParams, secondParams) {
      'use strict';

      return JSON.stringify(firstParams) === JSON.stringify(secondParams);
    },
    model: function model(params, transition) {
      'use strict';

      var feature = params.feature;
      var productListUrl = _environment.default.api.host + '/api/v1/supplier_branches/' + params.id + '/activities';
      var productList = this.get('ajaxWrapper').ajaxGet(productListUrl, null, { xhrFields: { withCredentials: true } });

      if (!SUPPORTED_REVIEWS.includes(feature)) {
        transition.abort();
        this.intermediateTransitionTo('errors/404', new Ember.Error('Feature "' + feature + '" is not supported.'));
      } else {
        var reviewVault = this.get('reviewVault');
        var url = reviewVault.buildFeatureReviewUrl(params, true);
        var reviewsData = this.get('ajaxWrapper').ajaxGet(url, null, { xhrFields: { withCredentials: true } });
        return Ember.RSVP.hash({
          params: params,
          reviewsData: reviewsData,
          activities: productList
        });
      }
    },

    actions: {
      didTransition: function didTransition() {
        'use strict';

        window.pageName = 'reviews';
        this.controller.resetLoadedReviews(this.get('isNextPage'));
        this.controller.loadReviews();
        this.controller.aggregatedReviewRating();
      },
      willTransition: function willTransition() {
        delete window.pageName;
      }
    },
    setupController: function setupController(controller, model) {
      'use strict';

      route = this;
      route._super(controller, model);
      controller.set('route', route);

      this.controller.set('reviewSelectedActivity', controller.activityId);
      this.reviewSetupDropdown(model.activities);

      var isGTSupplierBranch = this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
      this.controller.set('isGTSupplierBranch', isGTSupplierBranch);

      if (model.reviewsData && model.reviewsData.length) {
        this.controller.set('isReviewsAvailable', true);
      } else {
        this.controller.set('isReviewsAvailable', false);
      }

      var reviewHelper = route.get('reviewHelper');
      var originalParams = Ember.copy(model.params);
      controller.set('isReviewDownloading', false);

      var adjustedParams = Ember.copy(model.params);
      adjustedParams = reviewHelper.setup.updateParamsWithDefaults(route, adjustedParams);
      route.checkParams(adjustedParams);
      adjustedParams = reviewHelper.setup.updateParamPage(route, adjustedParams, route.get('isNextPage'));

      if (!route.deepEquals(originalParams, adjustedParams)) {
        route.transitionTo({ queryParams: Ember.copy(adjustedParams) });
      }

      route.set('oldParams', adjustedParams);
      controller.set('params', adjustedParams);

      Ember.run.schedule('afterRender', function () {
        controller.updateSidePanel();

        var endlessScrollService = route.get('endlessScrollService');
        var continueCallback = function continueCallback() {
          controller.set('loadingMoreReviews', true);
          controller.getNextPage();
        };
        endlessScrollService.setScrollHandler(!controller.get('noMoreReviews'), continueCallback);
      });
    },
    reviewSetupDropdown: function reviewSetupDropdown(reviewActivities) {
      var options = new Ember.A([]);
      var values = new Ember.A([]);
      if (reviewActivities) {
        reviewActivities.forEach(function (activity) {
          if ((activity.version === '3' || activity.version === '4') && activity.status === 'active') {
            var id = activity.activity_id;
            options.push(activity.long_title);
            values.push(id);
          }
        });
      }
      this.controller.set('productOptions', options);
      this.controller.set('productValues', values);
    },
    reviewActivitySelect: function reviewActivitySelect() {
      this.replaceWith('reviews', { queryParams: { activityId: activityId } });
      this.controller.set('reviewActivitySelect', activityId);
    }
  });
});