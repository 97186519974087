define('lxso/utils/change-history-details-display-modes/contact-information', ['exports', 'lxso/utils/change-history-details-display-modes/list', 'lxso/utils/concatenate', 'lxso/utils/string-tags'], function (exports, _list, _concatenate, _stringTags) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _templateObject = _taggedTemplateLiteral(['', ':'], ['', ':']),
      _templateObject2 = _taggedTemplateLiteral(['+', ''], ['+', '']),
      _templateObject3 = _taggedTemplateLiteral(['(', ')'], ['(', ')']),
      _templateObject4 = _taggedTemplateLiteral(['X ', ''], ['X ', '']);

  function _taggedTemplateLiteral(strings, raw) {
    return Object.freeze(Object.defineProperties(strings, {
      raw: {
        value: Object.freeze(raw)
      }
    }));
  }

  function toSingleLine(contact) {
    var phone = contact.phone;
    var phoneNumber = (0, _concatenate.default)(' ', (0, _stringTags.presentable)(_templateObject, phone.use && phone.use.name), (0, _stringTags.presentable)(_templateObject2, phone.countryCode), (0, _stringTags.presentable)(_templateObject3, phone.areaCode), phone.phoneNumber, (0, _stringTags.presentable)(_templateObject4, phone.extension));

    return (0, _concatenate.default)(', ', phoneNumber, contact.email);
  }

  exports.default = {
    partialName: 'list-with-label',
    diff: function diff(oldValue, newValue) {
      return {
        displayMode: _list.default,
        oldValue: JSON.parse(oldValue).map(toSingleLine),
        newValue: JSON.parse(newValue).map(toSingleLine)
      };
    }
  };
});