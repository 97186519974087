define('lxso/validators/ticket-types-v4-overlap', ['exports', 'ember-cp-validations/validators/base', 'lxso/constants/base-ticket-types', 'lxso/utils/overlap-failure-reducer'], function (exports, _base, _baseTicketTypes, _overlapFailureReducer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OVERLAP_CHECK_TT = [_baseTicketTypes.BASE_TICKET_TYPES.INFANT, _baseTicketTypes.BASE_TICKET_TYPES.CHILD, _baseTicketTypes.BASE_TICKET_TYPES.YOUTH, _baseTicketTypes.BASE_TICKET_TYPES.ADULT, _baseTicketTypes.BASE_TICKET_TYPES.SENIOR];

  var TicketTypesV4Overlap = _base.default.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    validate: function validate(ticketTypes, options, model, attribute) {
      var ticketTypesToValidateAgeOverlap = model.get('ticketTypes').filter(function (tt) {
        return tt.get('isSelected') && OVERLAP_CHECK_TT.includes(tt.get('baseLabel'));
      }).sort(function (ttA, ttB) {
        return OVERLAP_CHECK_TT.indexOf(ttA.get('baseLabel')) - OVERLAP_CHECK_TT.indexOf(ttB.get('baseLabel'));
      });

      var selectedTicketTypes = model.get('ticketTypes').filter(function (tt) {
        return tt.get('isSelected');
      });

      var errorMessage = void 0;
      var failures = [];

      //minimum one ticket
      if (selectedTicketTypes.length === 0) {
        errorMessage = this.get('i18n').t('pages.optionGroup.tickets.errorMessages.minOne');
        failures.push('ticketTypeAge');
      }

      //valid ages
      if (!failures.length) {
        errorMessage = this.get('i18n').t('pages.optionGroup.tickets.errorMessages.ageEmpty');
        for (var i = 0; i < selectedTicketTypes.length; i++) {
          var ttA = selectedTicketTypes[i];
          var min = parseInt(ttA.get('minimumAge'));
          var max = parseInt(ttA.get('maximumAge'));
          if (isNaN(min) || min < 0 || isNaN(max) || max <= 0 || max > 100) {
            failures.push('ticketTypeAge' + ttA.get('baseLabel'));
          }
        }
      }

      //min age >  max
      if (!failures.length) {
        errorMessage = this.get('i18n').t('pages.optionGroup.tickets.errorMessages.maxAgeLessThanMin');
        for (var _i = 0; _i < selectedTicketTypes.length; _i++) {
          var _ttA = selectedTicketTypes[_i];
          var _min = parseInt(_ttA.get('minimumAge'));
          var _max = parseInt(_ttA.get('maximumAge'));
          if (_min >= _max) {
            failures.push('ticketTypeAge' + _ttA.get('baseLabel'));
          }
        }
      }

      //age group order
      if (!failures.length) {
        ageGroupLoop: for (var _i2 = 0; _i2 < ticketTypesToValidateAgeOverlap.length; _i2++) {
          for (var j = _i2 + 1; j < ticketTypesToValidateAgeOverlap.length; j++) {
            var _ttA2 = ticketTypesToValidateAgeOverlap[_i2];
            var ttB = ticketTypesToValidateAgeOverlap[j];
            if (parseInt(_ttA2.get('minimumAge')) > parseInt(ttB.get('minimumAge'))) {
              failures.push('ticketTypeAge' + _ttA2.get('baseLabel'), 'ticketTypeAge' + ttB.get('baseLabel'));
              errorMessage = 'Age range of ' + _ttA2.get('baseLabel') + ' should be less than that of ' + ttB.get('baseLabel');
              break ageGroupLoop;
            }
          }
        }
      }

      //age overlap
      if (!failures.length) {
        errorMessage = this.get('i18n').t('pages.optionGroup.tickets.errorMessages.ageOverlap');
        for (var _i3 = 0; _i3 < ticketTypesToValidateAgeOverlap.length; _i3++) {
          for (var _j = _i3 + 1; _j < ticketTypesToValidateAgeOverlap.length; _j++) {
            var _ttA3 = ticketTypesToValidateAgeOverlap[_i3];
            var _ttB = ticketTypesToValidateAgeOverlap[_j];
            if (parseInt(_ttA3.get('minimumAge')) <= parseInt(_ttB.get('maximumAge')) && parseInt(_ttA3.get('maximumAge')) >= parseInt(_ttB.get('minimumAge'))) {
              failures.push('ticketTypeAge' + _ttA3.get('baseLabel'), 'ticketTypeAge' + _ttB.get('baseLabel'));
            }
          }
        }
      }

      //age gap
      if (!failures.length) {
        errorMessage = this.get('i18n').t('pages.optionGroup.tickets.errorMessages.ageGap');
        for (var _i4 = 0; _i4 < ticketTypesToValidateAgeOverlap.length - 1; _i4++) {
          var _ttA4 = ticketTypesToValidateAgeOverlap[_i4];
          var _ttB2 = ticketTypesToValidateAgeOverlap[_i4 + 1];
          if (parseInt(_ttA4.get('maximumAge')) + 1 != parseInt(_ttB2.get('minimumAge'))) {
            failures.push('ticketTypeAge' + _ttA4.get('baseLabel'), 'ticketTypeAge' + _ttB2.get('baseLabel'));
          }
        }
      }

      if (failures.length > 0) {
        return (0, _overlapFailureReducer.overlapFailureReducer)(failures, errorMessage);
      } else {
        return true;
      }
    }
  });

  exports.default = TicketTypesV4Overlap;
});