define('lxso/utils/form-validator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * @interface ModelAttributes
   * Associates a model and its attribute names
   *
   * @property {DS.Model} model - The model object
   * @property {string[]} attributes - The attribute names of the model
   */

  /**
   * Validates model attributes
   *
   * @param {...ModelAttributes} modelAttributes - A list of ModelAttributes objects
   * @returns {Ember.RSVP.Promise} A promise rejected with the validation errors if there are any, resolved otherwise
   */
  function validate() {
    for (var _len = arguments.length, modelAttributes = Array(_len), _key = 0; _key < _len; _key++) {
      modelAttributes[_key] = arguments[_key];
    }

    var errors = modelAttributes.map(toValidations).reduce(toFlatArray).filter(function (validation) {
      return validation.get('isInvalid');
    });

    return new Ember.RSVP.Promise(function (resolve, reject) {
      if (errors.length === 0) {
        resolve();
      } else {
        reject(toErrorObject(errors));
      }
    });
  }

  function toValidations(option) {
    return option.attributes.map(function (property) {
      return option.model.get('validations.attrs').get(property);
    });
  }

  function toFlatArray(a, b) {
    return a.concat(b);
  }

  function toErrorObject(errors) {
    return errors.reduce(function (errorObject, error) {
      errorObject[error.get('attribute')] = error.get('messages');
      return errorObject;
    }, {});
  }

  function scrollToFirstError() {
    Ember.run.later(function () {
      var anchorElement = Ember.$('.invalid-anchor').offset();
      if (anchorElement) {
        Ember.$('html, body').animate({
          scrollTop: anchorElement.top - 200
        }, 250);
      }
    });
  }

  exports.validate = validate;
  exports.scrollToFirstError = scrollToFirstError;
});