define('lxso/adapters/promotion', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    namespace: 'api/v3',

    query: function query(store, type, _query) {
      var branchId = _query.branchId;
      var url = this.host + '/' + this.namespace + '/promotion/supplierBranch/' + branchId;
      return this.ajax(url, 'GET');
    },
    findRecord: function findRecord(store, type, id) {
      var url = this.host + '/' + this.namespace + '/promotion/' + id;
      return this.ajax(url, 'GET');
    },
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.host + '/' + this.namespace + '/promotion';

      serializer.serializeIntoHash(data, type, snapshot);
      var activities = data.promotionDetails.activities;
      var supplierBranchId = data.promotionDetails.supplierBranchId;

      data.promotionDetails = this.createPromoProductsArray(activities, supplierBranchId);
      data.expediaFundType = data.discountTypeUI;
      data.supplierFundType = data.discountTypeUI;

      if (data.fundType === 'Expedia Funded') {
        if (data.discountTypeUI === 'Absolute') {
          data.expediaFund = data.totalDiscount;
        } else {
          data.expediaFund = 100;
        }
        data.supplierFund = 0;
      } else if (data.fundType === 'Supplier Funded') {
        if (data.discountTypeUI === 'Absolute') {
          data.supplierFund = data.totalDiscount;
        } else {
          data.supplierFund = 100;
        }
        data.expediaFund = 0;
      }

      delete data.funders;
      delete data.products;
      delete data.normalizedAudiences;
      delete data.normalizedShoppingPath;
      delete data.discountTypeUI;
      delete data.bulkUploadFileName;
      return this.ajax(url, 'POST', { data: data });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var promotionId = parseInt(snapshot.id);

      data.promotionId = promotionId;

      var supplierBranchId = data.promotionDetails.supplierBranchId;
      var productList = data.promotionDetails.activities;

      data.promotionDetails = this.createPromoProductsArray(productList, supplierBranchId);

      delete data.funders;
      delete data.products;
      delete data.discountTypeUI;
      delete data.normalizedAudiences;
      delete data.normalizedShoppingPath;
      delete data.bulkUploadFileName;
      var url = this.host + '/' + this.namespace + '/promotion/' + promotionId;

      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.operation === 'deactivatePromotions') {
        return this.ajax(url, 'DELETE', { data: data });
      } else {
        return this.ajax(url, 'PUT', { data: data });
      }
    },
    createPromoProductsArray: function createPromoProductsArray(productList, supplierBranchId) {
      var promoDetails = [];
      productList.forEach(function (product) {
        product.offers.forEach(function (offer) {
          offer.ticketTypes.forEach(function (ticketType) {
            promoDetails.push({
              aId: product.aId,
              oId: offer.oId,
              ttId: ticketType.ttId,
              status: 'Active',
              pDetailId: ticketType.pDetailId
            });
          });
        });
      });
      return promoDetails;
    }
  });
});