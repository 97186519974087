define('lxso/mixins/sort-status-alphabetical', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sortByStatusAndAlphabetical: function sortByStatusAndAlphabetical(model, reverse, type) {
      if (model) {
        //this part sorts active, inactive, and disabled into their own arrays
        var active = Ember.A([]);
        var inactive = Ember.A([]);
        var deleted = Ember.A([]);
        var suspended = Ember.A([]);
        var suspendedexpedia = Ember.A([]);
        var suspendedpaused = Ember.A([]);
        var deactivated = Ember.A([]);

        for (var i = 0; i < model.length; i++) {
          model[i] = Ember.Object.create(model[i]);
          if (model[i].status == 'active') {
            active.push(model[i]);
          } else if (model[i].status == 'inactive') {
            inactive.push(model[i]);
          } else if (model[i].status == 'deleted') {
            deleted.push(model[i]);
          } else if (model[i].status == 'suspended') {
            suspended.push(model[i]);
          } else if (model[i].status == 'suspendedexpedia') {
            suspendedexpedia.push(model[i]);
          } else if (model[i].status == 'suspendedpaused') {
            suspendedpaused.push(model[i]);
          } else if (model[i].status == 'deactivated') {
            deactivated.push(model[i]);
          }
        }

        // Sets sortBy based on type
        var sortByField = 'internal_title';
        if (type === 'offer') {
          sortByField = 'title';
        } else if (type === 'optionsQuickView') {
          sortByField = 'shortTitle';
        }

        //this part puts them in abc or reverse abc order
        if (reverse === true) {
          active = active.sortBy(sortByField).reverse();
          inactive = inactive.sortBy(sortByField).reverse();
          deleted = deleted.sortBy(sortByField).reverse();
          suspended = suspended.sortBy(sortByField).reverse();
          suspendedexpedia = suspendedexpedia.sortBy(sortByField).reverse();
          suspendedpaused = suspendedpaused.sortBy(sortByField).reverse();
          deactivated = deactivated.sortBy(sortByField);
        } else {
          active = active.sortBy(sortByField);
          inactive = inactive.sortBy(sortByField);
          deleted = deleted.sortBy(sortByField);
          suspended = suspended.sortBy(sortByField);
          suspendedexpedia = suspendedexpedia.sortBy(sortByField);
          deactivated = deactivated.sortBy(sortByField);
        }

        if (this.get('V3ProductListDMView')) {
          return active.concat(suspendedexpedia).concat(suspendedpaused).concat(inactive).concat(deleted).concat(suspended).concat(deactivated);
        } else {
          return active.concat(suspendedexpedia).concat(suspendedpaused).concat(inactive).concat(suspended).concat(deactivated);
        }
      }
    }
  });
});