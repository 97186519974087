define('lxso/models/limited-inventory', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    offer_id: _emberData.default.attr('number'),
    start_date: _emberData.default.attr('string'),
    end_date: _emberData.default.attr('string'),
    monday: _emberData.default.attr('number'),
    tuesday: _emberData.default.attr('number'),
    wednesday: _emberData.default.attr('number'),
    thursday: _emberData.default.attr('number'),
    friday: _emberData.default.attr('number'),
    saturday: _emberData.default.attr('number'),
    sunday: _emberData.default.attr('number'),
    status_code: _emberData.default.attr('number'),
    start_time: _emberData.default.attr('string'),
    end_time: _emberData.default.attr('string'),
    variableCapacity: _emberData.default.attr('boolean', { defaultValue: true }),
    triplet_group: _emberData.default.belongsTo('triplet-group')
  });
});