define('lxso/models/activity/policy', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    cancellation_addendum: {},
    terms_and_conditions: {},
    cancellation_policy: {}
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    cancellation_addendum: _emberData.default.attr('string', { defaultValue: '' }),
    terms_and_conditions: _emberData.default.attr('string'),
    cancellation_policy: (0, _attributes.fragmentArray)('activity/cancellation-policy-model')
  });
});