define('lxso/serializers/system/permission', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberData.default.JSONSerializer.extend({
    normalize: function normalize(typeClass, hash) {
      hash.id = hash.name;
      hash.isGranted = hash.value === 'true';
      return this._super.apply(this, arguments);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      for (var _len = arguments.length, rest = Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        rest[_key - 3] = arguments[_key];
      }

      return this._super.apply(this, [store, primaryModelClass, payload.data].concat(_toConsumableArray(rest)));
    }
  });
});