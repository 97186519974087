define('lxso/helpers/get-icon-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getIconName = getIconName;

  //we persist ticket type names like this: Adult (12-55)
  //and our icons are named things like pricing_icon_adult
  //this method finds the first ( and removes it and everything after it up to the closing )
  //then lowercases everything that's left so we can use the results in a filepath
  //over in existing-price-template-details-display/template.hbs
  function getIconName(params /*, hash*/) {
    return params[0].replace(/ \(.*\)/, '').toLowerCase();
  }

  exports.default = Ember.Helper.helper(getIconName);
});