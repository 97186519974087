define('lxso/components/date-picker/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['componentClasses'],
    actions: {
      onChange: function onChange() {
        this.get('closureIndex') != undefined ? this.sendAction.apply(this, ['on-change', this.get('closureIndex')].concat(Array.prototype.slice.call(arguments))) : this.sendAction.apply(this, ['on-change'].concat(Array.prototype.slice.call(arguments)));
      },
      updateActionProxy: function updateActionProxy() {
        this.get('closureIndex') != undefined ? this.sendAction.apply(this, ['updateAction', this.get('closureIndex')].concat(Array.prototype.slice.call(arguments))) : this.sendAction.apply(this, ['updateAction'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});