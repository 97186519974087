define('lxso/models/supplier-branch/supplier-support/hours-of-operation', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _emberDataModelFragments, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    day: _emberData.default.attr('string', { defaultValue: '' }),
    from: (0, _attributes.fragment)('supplier-branch/supplier-support/time'),
    to: (0, _attributes.fragment)('supplier-branch/supplier-support/time'),

    timeFromDisplayText: Ember.computed('from', {
      set: function set(title, text) {
        var time = this.formatForDisplay(this.get('from.hours'), this.get('from.minutes'));
        return text ? text : time;
      }
    }),

    timeToDisplayText: Ember.computed('to', {
      set: function set(title, text) {
        return text ? text : this.formatForDisplay(this.get('to.hours'), this.get('to.minutes'));
      }
    }),

    formatForDisplay: function formatForDisplay(hours, minutes) {
      var input = hours + ':' + minutes;
      if (/\d{2}:\d{2}/.test(input)) {
        //perfectly formatted data, such as: 00:00
        return input;
      } else if (/0:0/.test(input)) {
        //default data: 0:0
        return '00:00';
      } else if (/\d{4}/.test(input)) {
        //4 digits but no colon, such as: 0400 or 1200
        return input.slice(0, 2) + ':' + input.slice(2, 4);
      } else if (/\d:\d{2}/.test(input)) {
        //missing leading 0 but trailing digits present, such as: 5:00
        return '0' + input;
      } else {
        //this data is too poorly formatted to format for you, try again
        return input;
      }
      //should handle a case for single digit minutes
    },


    openThisDay: Ember.computed('from', 'to', {
      get: function get() {
        //return true (checked) if activity is OPEN (found something other than 0 in 'from' or 'to')
        //return false (unchecked) if activity is CLOSED ('from' and 'to' are all 0s)
        return this.get('from.hours') !== 0 || this.get('from.minutes') !== 0 || this.get('to.hours') !== 0 || this.get('to.minutes') !== 0;
      },
      set: function set(title, checked) {
        if (checked) {
          //if it's openThisDay, set the display text to 00:00
          this.set('timeFromDisplayText');
          this.set('timeToDisplayText', '23:59');
          this.get('to').setProperties({ hours: 23, minutes: 59 });
        } else {
          this.get('from').setProperties({ hours: 0, minutes: 0 });
          this.get('to').setProperties({ hours: 0, minutes: 0 });
          //if it's not openThisDay, send "Closed" to the display text
          this.set('timeFromDisplayText', 'Closed');
          this.set('timeToDisplayText', 'Closed');
        }

        return checked ? checked : !this.get('openThisDay');
      }
    }),

    disableInput: Ember.computed('openThisDay', {
      get: function get() {
        return !this.get('openThisDay');
      }
    })
  });
});