define('lxso/validators/booking-cutoff-not-greater-than-24', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BookingCutoffNotGreaterThan24 = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      'use strict';

      var isInternal = model.get('permissions').checkEnabled('V3InternalSettings');
      if (!isInternal) {
        if (options.isNew && value > 24) {
          return model.get('i18n').t('' + options.messageKey).string;
        } else if (!options.isNew && value > options.previousValue) {
          return model.get('i18n').t('errors.customMessages.offers.bookingCutoffNotGreaterThanPreviousValue').string;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  });

  BookingCutoffNotGreaterThan24.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = BookingCutoffNotGreaterThan24;
});