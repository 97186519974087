define('lxso/helpers/errors-for-postal-codes', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.errorsForPostalCodes = errorsForPostalCodes;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function errorsForPostalCodes(params) {
    if (params[1]) {
      var _ret = function () {
        var errorsToReturn = [];
        var errorFound = false;

        var _loop = function _loop(i) {
          var errorString = params[1][i].split(':;:')[1];
          var errorValues = params[1][i].split(':;:')[0].split(',');
          var elementValue = '' + params[2];

          errorValues.forEach(function (error) {
            if (error === elementValue) {
              errorFound = true;
              errorsToReturn.push(elementValue + ' ' + errorString);
            }
          });
        };

        for (var i = 0; i < params[1].length; i++) {
          _loop(i);
        }

        return {
          v: errorFound ? errorsToReturn : null
        };
      }();

      if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
    }
  }

  exports.default = Ember.Helper.helper(errorsForPostalCodes);
});