define("lxso/utils/to-percent-without-percent-sign", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = toPercentWithoutPercentSign;
  function toPercentWithoutPercentSign(args /*, hash*/) {
    return (args * 100).toFixed(2);
  }
});