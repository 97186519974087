define('lxso/components/option-group/actions/component', ['exports', 'lxso/constants/option-group'], function (exports, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var translationBaseKey = 'pages.optionGroup.optionGroupSection.actions';

  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    classNames: ['option-group-footer-actions'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('CRUD_MODES', _optionGroup.CRUD_MODES);
    },


    actions: {
      updateAndSaveOptionGroup: function updateAndSaveOptionGroup() {
        this.sendAction('updateAndSaveOptionGroup', this.get('optionGroup'));
      },
      cancelOptionGroup: function cancelOptionGroup() {
        this.sendAction('cancelOptionGroup', this.get('optionGroup'));
      }
    }
  });
});