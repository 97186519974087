define('lxso/models/fragments/prices/ticket-detail', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    ticketTypeId: [(0, _validation.validator)('presence', {
      presence: true
    })],
    retailPrice: [(0, _validation.validator)('number', {
      gte: 0,
      allowString: true,
      descriptionKey: 'errors.descriptionKeys.retailPrice'
    })],
    marketingPrice: [(0, _validation.validator)('number', {
      gte: 0,
      allowString: true,
      descriptionKey: 'errors.descriptionKeys.marketingPrice'
    })],
    minimumTravelers: [(0, _validation.validator)('presence', {
      presence: true,
      descriptionKey: 'errors.descriptionKeys.minPax'
    })],
    maximumTravelers: [(0, _validation.validator)('presence', {
      presence: true,
      descriptionKey: 'errors.descriptionKeys.maxPax'
    })],
    validMinMaxRange: (0, _validation.validator)('valid-min-max-range', {
      min: Ember.computed.readOnly('model.minimumTravelers'),
      max: Ember.computed.readOnly('model.maximumTravelers')
    })
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    i18n: Ember.inject.service(),
    ticketTypeId: _emberData.default.attr('number'),
    netPrice: _emberData.default.attr('number'),
    retailPrice: _emberData.default.attr('number'),
    marketingPrice: _emberData.default.attr('number'),
    priceCodeSKU: _emberData.default.attr('string'),
    groupTicketTypeId: _emberData.default.attr('number'),
    minimumTravelers: _emberData.default.attr('number'),
    maximumTravelers: _emberData.default.attr('number'),
    groupPricePlanType: _emberData.default.attr('string'),
    partnerTicketTypeId: _emberData.default.attr('string')
  });
});