define('lxso/initializers/ember-i18n-cp-validations', ['exports', 'lxso/config/environment', 'lxso/validators/messages'], function (exports, _environment, _messages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var Handlebars = Ember.Handlebars,
      warn = Ember.warn,
      computed = Ember.computed,
      isPresent = Ember.isPresent,
      isEmpty = Ember.isEmpty,
      isNone = Ember.isNone,
      inject = Ember.inject,
      get = Ember.get,
      isHTMLSafe = Ember.String.isHTMLSafe;


  function isSafeString(input) {
    return typeof isHTMLSafe === 'function' ? isHTMLSafe(input) : input instanceof Handlebars.SafeString;
  }

  function unwrap(input) {
    if (isSafeString(input)) {
      return input.toString();
    }

    return input;
  }

  function emitWarning(msg, meta) {
    if (!get(_environment.default, 'i18n.suppressWarnings')) {
      warn(msg, meta);
    }
  }

  function initialize() {
    _messages.default.reopen({
      i18n: inject.service(),
      _regex: /\{\{(\w+)\}\}|\{(\w+)\}/g,

      _prefix: computed('prefix', function () {
        var prefix = get(this, 'prefix');

        if (typeof prefix === 'string') {
          if (prefix.length) {
            return prefix + '.';
          }

          return prefix;
        }

        return 'errors.';
      }),

      getDescriptionFor: function getDescriptionFor(attribute) {
        var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var prefix = get(this, '_prefix');
        var key = prefix + 'description';
        var setDescriptionKey = void 0;

        if (!isEmpty(context.descriptionKey)) {
          key = context.descriptionKey;
          setDescriptionKey = true;
        } else if (!isEmpty(context.description)) {
          return context.description;
        }

        var i18n = get(this, 'i18n');

        if (i18n && i18n.exists(key)) {
          return unwrap(i18n.t(key, context));
        }

        if (setDescriptionKey) {
          emitWarning('Custom descriptionKey ' + key + ' provided but does not exist in i18n translations.', {
            id: 'ember-i18n-cp-validations-missing-description-key'
          });
        }

        return this._super.apply(this, arguments);
      },
      getMessageFor: function getMessageFor(type) {
        var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var i18n = get(this, 'i18n');
        var prefix = get(this, '_prefix');
        var key = isPresent(context.messageKey) ? context.messageKey : '' + prefix + type;

        if (i18n && i18n.exists(key)) {
          return unwrap(i18n.t(key, context));
        }

        emitWarning('[ember-i18n-cp-validations] Missing translation for validation key: ' + key + '\nhttp://offirgolan.github.io/ember-cp-validations/docs/messages/index.html', {
          id: 'ember-i18n-cp-validations-missing-translation'
        });

        return this._super.apply(this, arguments);
      },
      formatMessage: function formatMessage(message, context) {
        var m = message;

        if (isNone(m) || typeof m !== 'string') {
          m = get(this, 'invalid');
        }

        return m.replace(get(this, '_regex'), function (s, p1, p2) {
          return get(context, p1 || p2);
        });
      }
    });
  }

  exports.default = {
    name: 'ember-i18n-cp-validations',
    initialize: initialize
  };
});