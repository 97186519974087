define('lxso/routes/activity/activity-settings', ['exports', 'lxso/mixins/publish-api', 'lxso/mixins/require-permission', 'lxso/utils/form-validator', 'lxso/utils/arrays', 'lxso/routes/activity/categories/utils', 'lxso/mixins/feature-flag', 'lxso/config/environment'], function (exports, _publishApi, _requirePermission, _formValidator, _arrays, _utils, _featureFlag, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_requirePermission.default, _publishApi.default, {
    // omniture: {
    //   //TODO: Verify with product what should be here.
    //   pageName: 'page.LX-Supply.Categories'
    // },
    requiredPermission: 'V3InternalSettings',
    session: Ember.inject.service(),
    internalCategories: Ember.inject.service(),
    loadingTime: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    omniture: {
      pageName: 'page.LX-Supply.AddLocation'
    },

    categoryGroups: [{
      name: 'Activities',
      members: ['Adventures', 'Attractions', 'Cirque', 'Disney', 'Food & Drink', 'Nightlife', 'Show & Sport Tickets', 'Sightseeing Passes', 'Spa', 'Theme Parks', 'Water Activities', 'Wedding Ceremonies', 'Winter Activities']
    }, {
      name: 'Tours',
      members: ['Air, Balloon & Helicopter Tours', 'Cruises & Water Tours', 'Day Trips & Excursions', 'Hop-on Hop-off', 'Multi-Day & Extended Tours', 'Tours & Sightseeing', 'Walking & Bike Tours', 'Private Tours', 'Private Transfers', 'Shared Transfers']
    }],

    model: function model() {
      var countriesUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/enums/countries';
      var activity = this.modelFor('activity');
      var tagsUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/activityTags/' + activity.id;
      var offerUrl = _environment.default.api.host + '/api/v3/activity/' + activity.get('id') + '/offers';
      var attributesUrl = _environment.default.api.host + '/api/v3/activity/' + activity.get('id') + '/offer_attributes';
      var optionGroupCatalogDetailsUrl = _environment.default.api.hostV4 + '/activity/' + activity.id + '/offer-group-catalog-details';
      var optionGroupCatalogDetails = void 0;
      if (activity.get('migrationStatus.price')) {
        optionGroupCatalogDetails = this.get('ajaxWrapper').ajaxGet(optionGroupCatalogDetailsUrl);
      } else {
        optionGroupCatalogDetails = {
          catalogDetails: []
        };
      }
      return new Ember.RSVP.hash({
        countries: this.get('ajaxWrapper').ajaxGet(countriesUrl),
        tags: this.get('ajaxWrapper').ajaxGet(tagsUrl),
        activity: this.store.findRecord('activity', activity.get('id')),
        translations: this.modelFor('activity').getTranslations(),
        offerList: this.get('ajaxWrapper').ajaxGet(offerUrl),
        attributesList: this.get('ajaxWrapper').ajaxGet(attributesUrl),
        optionGroupCatalogDetails: optionGroupCatalogDetails
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      var categoryGroups = this.categoryGroups;
      controller.setProperties({
        activity: model.activity,
        offerList: model.offerList,
        address: model.activity.get('activity_location'),
        validateLocationTrigger: 0,
        countrySource: model.countries.data,
        availableCategories: Ember.computed('activity.attributes.user_experience_group', function () {
          var categoriesOfActivity = this.get('activity.attributes.user_experience_group');
          return categoryGroups.map(function (group) {
            return {
              name: group.name,
              members: group.members.map((0, _utils.toCategory)(categoriesOfActivity))
            };
          });
        }),

        categories: Ember.computed('availableCategories', function () {
          return this.get('availableCategories').map(function (group) {
            return group.members;
          }).reduce(function (xs, ys) {
            return xs.concat(ys);
          });
        }),

        selectedCategories: Ember.computed('categories.@each.selected', function () {
          return this.get('categories').filter(function (category) {
            return Ember.get(category, 'selected');
          }).map(function (category) {
            return Ember.get(category, 'name');
          });
        }),

        internalCategoryOptions: this.get('internalCategories').get(),

        categoriesDirty: Ember.computed('activity.attributes.user_experience_group', 'selectedCategories', function () {
          var categoriesOfActivity = this.get('activity.attributes.user_experience_group');
          var categoriesOnPage = this.get('selectedCategories');
          return !(0, _arrays.containSameElements)(categoriesOfActivity, categoriesOnPage);
        }),

        categoryBackUp: this.modelFor('activity').get('operational.category'),
        options: Ember.computed('offerList', function () {
          return this.get('offerList').map(function (option, index) {
            var optionData = model.attributesList.find(function (attributes) {
              return attributes.offerId.toString() === option.id.toString();
            });

            if (typeof optionData !== 'undefined') {
              option.geoIds = optionData.geos;
              option.offerCatalogs = optionData.catalogs;
              option.oiid = optionData.oiid;
            }
            return option;
          });
        }),
        optionGroups: model.optionGroupCatalogDetails.catalogDetails.map(function (ogDetails) {
          return Object.assign({}, ogDetails.offerGroup, {
            catalogs: ogDetails.catalogs
          });
        }),
        translations: model.translations,
        tags: model.tags,
        savingTranslations: false,
        editingTranslations: false,
        isTranslationsOpen: true,
        isUxOpen: true,
        isOptionsOpen: true,
        isOptionGroupsOpen: true,
        isLocationOpen: true,
        isActivitySetUpOpen: true,
        isTagsOpen: true,
        editingActivitySetUp: false,
        editingUxGrouping: false,
        isTranslationFormDirty: false,
        tagsAvailable: model.tags.geoTags.length || model.tags.tags.length,
        connectedActivity: true,
        connectedSupplier: true
      });
      var supplierConnectivityStatus = this.get('session').get('data.authenticated.selectedBranch.connectivity_status');
      if (supplierConnectivityStatus === undefined) {
        this.controller.set('connectedSupplier', false);
      }
      if (this.controllerFor('activity').get('activityExternalRefRes.connectivityStatus') === undefined) {
        this.controller.set('connectedActivity', false);
      }
      this.setupRollback();
      Ember.run.next(function () {
        var timeTaken = _this.get('loadingTime').getLoadTime();
        console.info('%c Activity settings page load time is ' + timeTaken + ' seconds', 'font-weight: bold');
        _this.setupStep();
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var address = controller.get('address');
        var activity = controller.get('activity');
        var savedConnectivityStatus = controller.get('activityCopy.isConnectivityEnabled');

        if (address && address.get('hasDirtyAttributes')) {
          address.rollbackAttributes();
        }

        if (activity && activity.get('hasDirtyAttributes')) {
          activity.rollbackAttributes();
          activity.set('isConnectivityEnabled', savedConnectivityStatus);
        }
      }
    },
    setupRollback: function setupRollback() {
      this.controller.set('locationCopy', this.controller.get('activity.activity_location').toJSON());
      var activityCopy = this.controller.get('activity').toJSON();
      this.controller.set('activityCopy', activityCopy);
    },
    rollback: function rollback() {
      this.controller.set('activity.activity_location', this.controller.get('locationCopy'));
    },
    setupStep: function setupStep() {
      var link = document.getElementsByClassName('title-main toggle-trigger');
      $(link).addClass('open');
    },


    actions: {
      setValidity: function setValidity() {},
      validateIfInvalid: function validateIfInvalid() {},
      internalCategoryChange: function internalCategoryChange(category) {
        this.controller.set('categoriesDirty', true);
        this.controller.set('activity.operational.category', category);
      },
      setAddressDirty: function setAddressDirty() {
        this.controller.set('locationDirty', true);
      },
      setUxOption: function setUxOption(categoryName, selected) {
        this.controller.set('categoriesDirty', true);
        this.controller.get('categories').forEach(function (item) {
          if (Ember.get(item, 'name') === categoryName) {
            Ember.set(item, 'selected', selected);
          }
        });
      },
      saveLocation: function saveLocation() {
        var _this2 = this;

        this.controller.set('savingLocation', true);
        var validatedAttributes = ['city', 'country', 'formatted_address'];
        var address = this.controller.get('activity.activity_location');
        var activity = this.controller.get('activity');
        this.controller.set('lastSaved', 'location');
        this.controller.set('errors', []);
        this.validate({ model: address, attributes: validatedAttributes }).then(function () {
          return _this2.saveLocation(activity);
        }).then(function () {
          _this2.controller.set('locationDirty', false);
          _this2.get('notifications').success(_this2.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (errors) {
          _this2.controller.set('errors', errors);
          _this2.controller.set('validateLocationTrigger', _this2.controller.get('validateLocationTrigger') + 1);
          _this2.scrollToFirstError();
          Ember.Logger.error('Something went wrong: ', errors);
          if (errors.errors) {
            _this2.get('notifications').error(_this2.get('i18n').t('components.alert.saveFailure'));
            // Show error notification only for server error
          }
        }).finally(function () {
          _this2.controller.set('savingLocation', false);
        });
      },
      saveCategories: function saveCategories() {
        var _this3 = this;

        var activity = this.controller.get('activity');
        var attributes = activity.get('attributes');
        var operational = activity.get('operational');
        attributes.set('user_experience_group', this.controller.get('selectedCategories'));
        this.controller.set('savingCategories', true);

        this.validate({ model: attributes, attributes: ['user_experience_group'] }).then(function () {
          _this3.controller.set('errors', []);
          return _this3.updateInternalCategory(operational);
        }).then(function () {
          _this3.send('toggleEditMode', 'uxGrouping');
          _this3.controller.set('errors', []);
          return activity.updateAttributes(attributes);
        }).then(function (activity) {
          _this3.controller.set('categoryBackUp', operational.get('category'));
          _this3.send('configureInternalCategoryAction', operational.get('category'));
          _this3.controller.set('lastSaved', 'categories');
          _this3.get('notifications').success(_this3.get('i18n').t('components.alert.saveSuccess'));
          _this3.controller.set('categoriesDirty', false);
        }).catch(function (errors) {
          Ember.Logger.error('error saving categories', errors);
          _this3.controller.set('errors', errors);
          _this3.scrollToFirstError();
        }).finally(function () {
          return _this3.controller.set('savingCategories', false);
        });
      },
      saveActivitySetUpData: function saveActivitySetUpData(supplierNameOverride, activityAttributes) {
        var _this4 = this;

        var activity = this.controller.get('activity');
        this.controller.set('savingActivitySetUp', true);

        this.validate({ model: activityAttributes, attributes: ['suggested_rank'] }).then(function () {
          _this4.controller.set('errors', []);
          return activity.updateAttributes(activityAttributes.toJSON());
        }).then(function () {
          return activity.updateOperational(supplierNameOverride.toJSON());
        }).then(function (activity) {
          _this4.controller.set('lastSaved', 'activitySetup');
          _this4.saveConnectivityStatus().then(function () {
            _this4.reloadActivity().then(function (activityData) {
              _this4.controller.set('activity', activityData);
              _this4.get('notifications').success(_this4.get('i18n').t('components.alert.saveSuccess'));
              _this4.send('toggleEditMode', 'activitySetup');
            });
          });
        }).catch(function (errors) {
          _this4.controller.set('errors', errors);
          _this4.scrollToFirstError();
        }).finally(function () {
          _this4.controller.set('savingActivitySetUp', false);
        });
      },
      cancelEditUxGrouping: function cancelEditUxGrouping() {
        var activity = this.modelFor('activity');
        var uxValue = activity.get('attributes.user_experience_group');
        var category = this.controller.get('categoryBackUp');

        this.controller.set('activity.operational.category', category);
        this.controller.set('activity.attributes.user_experience_group', uxValue);
        this.controller.notifyPropertyChange('availableCategories');
        this.controller.set('categoriesDirty', false);
        this.controller.set('errors', []);
        this.send('toggleEditMode', 'uxGrouping');
      },
      editTranslations: function editTranslations() {
        var translationsCopy = JSON.parse(JSON.stringify(this.controller.get('translations')));
        this.controller.set('translationsCopy', translationsCopy);
        this.controller.set('editingTranslations', true);
      },
      cancelEditTranslations: function cancelEditTranslations() {
        this.controller.set('translations', this.controller.get('translationsCopy'));
        this.controller.set('editingTranslations', false);
        this.send('setTranslationFormDirty', false);
      },
      saveTranslations: function saveTranslations() {
        var _this5 = this;

        var translations = this.controller.get('translations');
        this.controller.set('savingTranslations', true);
        var data = {
          translation_languages: translations
        };
        var activity = this.controller.get('activity');
        activity.updateTranslations(data).then(function () {
          _this5.controller.set('lastSaved', 'translations');
          _this5.controller.set('editingTranslations', false);
          _this5.get('notifications').success(_this5.get('i18n').t('components.alert.saveSuccess'));
          _this5.send('setTranslationFormDirty', false);
        }).catch(function (errors) {
          Ember.Logger.error('error saving translations', errors);
          _this5.controller.set('errors', errors);
          _this5.get('notifications').error(_this5.get('i18n').t('components.alert.saveFailure'));
        }).finally(function () {
          return _this5.controller.set('savingTranslations', false);
        });
      },
      toggleEditMode: function toggleEditMode(section) {
        var translationEditing = this.controller.get('editingTranslations');
        var activitySetUpEditing = this.controller.get('editingActivitySetUp');
        var uxGroupingEditing = this.controller.get('editingUxGrouping');
        var activityCopy = this.controller.get('activity').toJSON();
        this.controller.set('activityCopy', activityCopy);

        switch (section) {
          case 'uxGrouping':
            if (!translationEditing && !activitySetUpEditing) {
              this.controller.toggleProperty('editingUxGrouping');
            } else {
              this.controller.set('lastEditAttemptedFailure', 'uxGrouping');
            }
            break;

          case 'activitySetup':
            if (!translationEditing && !uxGroupingEditing) {
              this.controller.toggleProperty('editingActivitySetUp');
            } else {
              this.controller.set('lastEditAttemptedFailure', 'activitySetup');
            }
            break;

          case 'translation':
            if (!activitySetUpEditing && !uxGroupingEditing) {
              this.send('editTranslations');
            } else {
              this.controller.set('lastEditAttemptedFailure', 'translation');
            }
            break;
        }
      },
      retryOptionTshopId: function retryOptionTshopId(option) {
        var _this6 = this;

        var attributesUrl = _environment.default.api.host + '/api/v1/offers/' + option.id + '/oiid';
        var data = {
          activity_id: parseInt(this.controller.get('activity.id'))
        };

        this.controller.set('lastSaved', 'options');
        Ember.set(option, 'processingOptionTshopRetry', true);
        this.get('ajaxWrapper').ajaxPost(attributesUrl, data, '').then(function (response) {
          Ember.set(option, 'oiid', response.oiid);
          _this6.get('notifications').success(_this6.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (errors) {
          Ember.Logger.error('error creating option tshopId', errors);
          _this6.controller.set('errors', errors);
          _this6.get('notifications').error(_this6.get('i18n').t('components.alert.saveFailure'));
        }).finally(function () {
          Ember.set(option, 'processingOptionTshopRetry', false);
        });
      },
      resetCurrentSection: function resetCurrentSection() {
        var translationEditing = this.controller.get('editingTranslations');
        var activitySetUpEditing = this.controller.get('editingActivitySetUp');
        var uxGroupingEditing = this.controller.get('editingUxGrouping');

        switch (this.controller.get('lastEditAttemptedFailure')) {
          case 'uxGrouping':
            if (translationEditing) {
              this.send('cancelEditTranslations');
            } else if (activitySetUpEditing) {
              this.send('cancelEditActivitySetup');
            }
            this.send('toggleEditMode', 'uxGrouping');
            this.controller.set('lastEditAttemptedFailure', false);
            break;

          case 'activitySetup':
            if (translationEditing) {
              this.send('cancelEditTranslations');
            } else if (uxGroupingEditing) {
              this.send('cancelEditUxGrouping');
            }
            this.send('toggleEditMode', 'activitySetup');
            this.controller.set('lastEditAttemptedFailure', false);
            break;

          case 'translation':
            if (activitySetUpEditing) {
              this.send('cancelEditActivitySetup');
            } else if (uxGroupingEditing) {
              this.send('cancelEditUxGrouping');
            }
            this.send('editTranslations');
            this.controller.set('lastEditAttemptedFailure', false);
            break;
        }
      },
      cancelEdit: function cancelEdit() {
        this.controller.set('lastEditAttemptedFailure', false);
      },
      openStep: function openStep(step) {
        var link = document.getElementsByClassName('title-main toggle-trigger ' + step);
        if (step === 'translations') {
          this.controller.toggleProperty('isTranslationsOpen');
        } else if (step === 'activity-setup') {
          this.controller.toggleProperty('isActivitySetUpOpen');
        } else if (step === 'uxgrouping') {
          this.controller.toggleProperty('isUxOpen');
        } else if (step === 'options') {
          this.controller.toggleProperty('isOptionsOpen');
        } else if (step == 'location') {
          this.controller.toggleProperty('isLocationOpen');
        } else if (step == 'activity-tags') {
          this.controller.toggleProperty('isTagsOpen');
        } else if (step === 'option-groups') {
          this.controller.toggleProperty('isOptionGroupsOpen');
        }
        $(link).toggleClass('open');
      },
      willTransition: function willTransition() {
        //todo
        // this.rollback();
        //for tests
        window.pageName = undefined;
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'internal-settings';

        this.controller.set('errors', '');
        this.controller.set('savingLocation', false);
        this.controller.set('savingCategories', false);
      },
      cancelEditActivitySetup: function cancelEditActivitySetup() {
        this.controller.set('activity.attributes', this.controller.get('activityCopy.attributes'));
        this.controller.set('activity.isConnectivityEnabled', this.controller.get('activityCopy.isConnectivityEnabled'));
        this.controller.set('activity.operational', this.controller.get('activityCopy.operational'));
        this.controller.set('editingActivitySetUp', false);
        this.controller.set('errors', []);
      },
      setTranslationFormDirty: function setTranslationFormDirty(value) {
        this.controller.set('isTranslationFormDirty', value);
      }
    },

    // These functions exist here to allow dependency injection.
    validate: function validate() {
      return _formValidator.validate.apply(undefined, arguments);
    },
    scrollToFirstError: function scrollToFirstError() {
      return (0, _formValidator.scrollToFirstError)();
    },
    //instance ops are horrible to mock
    saveLocation: function saveLocation(activity) {
      return activity.updateLocation(activity.get('activity_location').toJSON());
    },
    updateInternalCategory: function updateInternalCategory(operational) {
      return this.controller.get('activity').updateOperational(operational);
    },
    reloadActivity: function reloadActivity() {
      return this.controller.get('activity').reload();
    },
    saveConnectivityStatus: function saveConnectivityStatus() {
      var _this7 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var connectionStatus = _this7.controller.get('activity.isConnectivityEnabled');
        _this7.controllerFor('activity').set('activityExternalRefRes.connectivityStatus', connectionStatus);
        var data = _this7.controllerFor('activity').get('activityExternalRefRes');
        var activityID = _this7.controller.get('activity.id');
        if (activityID) {
          var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/activities/' + activityID + '/external_references';
          _this7.get('ajaxWrapper').ajaxPut(url, data).then(function (results) {
            return resolve(results);
          }).catch(function (reason) {
            return reject(reason);
          });
        } else {
          reject('Activity ID not found');
        }
      });
    }
  });
});