define('lxso/components/days-of-week-display/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    abbreviatedDaysOfWeek: Ember.computed('daysOfWeek', function () {
      var _this = this;

      var daysOfWeek = this.get('daysOfWeek');
      if (daysOfWeek) {
        return daysOfWeek.map(function (day, index) {
          if (day === 1) {
            return _this.get('i18n').t('common.daysOfWeek.abbreviated.' + index);
          } else {
            return null; //required to pass eslint
          }
        }).compact().join(', ');
      }
    })
  });
});