define('lxso/models/fragments/option-group/pickup-info', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    pickupType: _emberData.default.attr('string'),
    isCustomPickupLocationAllowed: _emberData.default.attr('boolean', { defaultValue: false })
  });
});