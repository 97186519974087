define('lxso/validators/date-range', ['exports', 'ember-cp-validations/validators/base', 'lxso/utils/date-validators'], function (exports, _base, _dateValidators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DateRange = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      'use strict';

      var from = model.get(options.from);
      var to = model.get(options.to);
      var dateFormat = options.dateFormat;

      if (from && to && dateFormat) {
        var startDate = moment(from, dateFormat);
        var endDate = moment(to, dateFormat);

        if (!(0, _dateValidators.isSameOrBefore)(startDate, endDate, 'day')) {
          return this.createErrorMessage('dateRange', value, options);
        }
      }

      return true;
    }
  });

  DateRange.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return ['model.' + options.from, 'model.' + options.to];
    }
  });

  exports.default = DateRange;
});