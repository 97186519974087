define('lxso/routes/supplier-branch-settings', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/supplier-settings/margin', 'lxso/mixins/supplier-settings/email-notification', 'lxso/mixins/supplier-settings/supplier-settings', 'lxso/mixins/supplier-settings/supplier-branch-settings', 'lxso/mixins/supplier-settings/supplier-platform', 'lxso/mixins/require-permission', 'lxso/config/environment'], function (exports, _authenticatedRouteMixin, _featureFlag, _margin, _emailNotification, _supplierSettings, _supplierBranchSettings, _supplierPlatform, _requirePermission, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, _margin.default, _supplierSettings.default, _emailNotification.default, _supplierBranchSettings.default, _supplierPlatform.default, {
    supplierBranchService: Ember.inject.service('supplier-branch'),
    requiredPermission: 'V3Product',
    ajaxWrapper: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    loadingTime: Ember.inject.service(),
    i18n: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var supplierBranch = this.store.findRecord('supplier-branch', params.id, { reload: true });
      var allPlatforms = this.store.findAll('platform', { reload: true });
      var acts = supplierBranch.then(function (supplierBranch) {
        return supplierBranch.activities();
      });
      return new Ember.RSVP.hash({
        supplierBranch: supplierBranch,
        allPlatforms: allPlatforms,
        activities: acts,
        supplierDetails: supplierBranch.then(function (supplierBranch) {
          return _this.store.queryRecord('suppliers', {
            supplierId: supplierBranch.get('supplier_id'),
            isBasic: true,
            branchId: params.id
          });
        }),
        platformDetails: supplierBranch.then(function (supplierBranch) {
          return supplierBranch.get('platform');
        }),
        marginOverrideList: acts.then(function (supplierActivities) {
          var productsValue = [];
          if (supplierActivities) {
            supplierActivities.filter(function (activity) {
              return activity.status === 'active' && Ember.isPresent(activity.long_title) && (activity.version == '3' || activity.version == '4');
            }).sort(function (a, b) {
              return a.long_title.localeCompare(b.long_title);
            }).forEach(function (activity) {
              productsValue.push(activity.activity_id);
            });
          }

          var supplierBranchId = params.id;
          var attributesPromiseList = _this.get('store').query('supplier-branch/margin-overrides', { supplierBranchId: supplierBranchId });
          return Ember.RSVP.resolve(attributesPromiseList);
        })
      });
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      var supplierBranchId = model.supplierBranch.id;
      var supplierBranchIdInSession = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
      if (supplierBranchIdInSession !== supplierBranchId) {
        return this.get('supplierBranchService').getSupplierBranchData([supplierBranchId]).then(function (supplierBranches) {
          return _this2.get('supplierBranchService').updateSelectedBranch(supplierBranches[0]);
        });
      }
    },
    setProductNameInMarginOverrideList: function setProductNameInMarginOverrideList(controller, model) {
      var activities = model.activities;
      model.marginOverrideList.forEach(function (marginData) {
        var aId = marginData.get('activity_id');
        var activityDetails = activities.find(function (el) {
          return el.activity_id == aId;
        });
        Ember.set(marginData, 'nameId', activityDetails.internal_title + ' (' + activityDetails.activity_id + ')');
      });
      controller.set('marginOverrideList', model.marginOverrideList);
    },
    setupController: function setupController(controller, model) {
      var _this3 = this;

      controller.set('supplierBranch', model.supplierBranch);
      controller.set('supplierActivities', model.activities);
      controller.set('supplierDetails', model.supplierDetails);
      controller.set('platformDetails', model.platformDetails);
      controller.set('allPlatforms', model.allPlatforms);
      controller.set('errors', '');
      controller.set('hasActivity', !Ember.isEmpty(controller.get('supplierActivities')));

      this.setProductNameInMarginOverrideList(controller, model);

      this.initSupplierMarginSettings();
      this.initSupplierSettings(controller, model);
      this.initSupplierBranchSettings();
      this.initNotificationSettings();
      this.initPlatformSettings();
      this.initAllPlatformDropdown();
      Ember.run.next(function () {
        var timeTaken = _this3.get('loadingTime').getLoadTime();
        console.info('%c Supplier branch settings page load time is ' + timeTaken + ' seconds', 'font-weight: bold');
        _this3.setupStep();
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.rollbackAllDirtyAttributes([controller.get('supplierBranch'), controller.get('supplierActivities'), controller.get('supplierDetails'), controller.get('platformDetails'), controller.get('allPlatforms')]);

        this.resetSections();
      }
    },
    rollbackAllDirtyAttributes: function rollbackAllDirtyAttributes(attrList) {
      attrList.forEach(function (controllerItem) {
        if (controllerItem && controllerItem.get('hasDirtyAttributes')) {
          controllerItem.rollbackAttributes();
        }
      });
    },
    resetSections: function resetSections() {
      this.controller.set('isEditPlatformSection', false);
      this.controller.set('editingSupplierBranchSettings', false);
      this.controller.set('editNotificationSettings', false);
      this.controller.set('editingSupplierSectionSettings', false);
      this.controller.set('supplierMarginEdit', false);
    },
    setupStep: function setupStep() {
      var link = document.getElementsByClassName('title-main toggle-trigger');
      $(link).addClass('open');
    },
    isEditAllowed: function isEditAllowed(section) {
      switch (section) {
        case 'supplierBranchSettings':
          return !this.controller.get('editNotificationSettings') && !this.controller.get('supplierMarginEdit') && !this.controller.get('editingSupplierSectionSettings') && !this.controller.get('isEditPlatformSection');
          break;
        case 'notificationSettings':
          return !this.controller.get('editingSupplierBranchSettings') && !this.controller.get('supplierMarginEdit') && !this.controller.get('editingSupplierSectionSettings') && !this.controller.get('isEditPlatformSection');
          break;
        case 'supplierMarginSetting':
          return !this.controller.get('editNotificationSettings') && !this.controller.get('editingSupplierBranchSettings') && !this.controller.get('editingSupplierSectionSettings') && !this.controller.get('isEditPlatformSection');
        case 'platformSection':
          return !this.controller.get('editNotificationSettings') && !this.controller.get('editingSupplierBranchSettings') && !this.controller.get('editingSupplierSectionSettings') && !this.controller.get('supplierMarginEdit');

        case 'supplierSettings':
          return !this.controller.get('editNotificationSettings') && !this.controller.get('editingSupplierBranchSettings') && !this.controller.get('isEditPlatformSection') && !this.controller.get('supplierMarginEdit');

      }
    },


    actions: {
      openStep: function openStep(step) {
        var link = document.getElementsByClassName('title-main toggle-trigger ' + step);
        if (step === 'supplier-settings') {
          this.controller.toggleProperty('isSupplierSettingsOpen');
        } else if (step === 'supplier-branch-settings') {
          this.controller.toggleProperty('isSupplierBranchSettingsOpen');
        } else if (step === 'supplier-margin-settings') {
          this.controller.toggleProperty('isSupplierBranchMarginOpen');
        }
        $(link).toggleClass('open');
      },
      resetCurrentSection: function resetCurrentSection() {
        var isNotificationSettingEdit = this.controller.get('editNotificationSettings');
        var isSupplierBranchSettings = this.controller.get('editingSupplierBranchSettings');
        var isSupplierMarginEdit = this.controller.get('supplierMarginEdit');
        var isPlatformEdit = this.controller.get('isEditPlatformSection');
        var isSupplierSectionEdit = this.controller.get('editingSupplierSectionSettings');
        var sectionToBeEdited = this.controller.get('sectionToBeEdited');

        switch (sectionToBeEdited) {
          case 'supplierBranchSettings':
            if (isNotificationSettingEdit) {
              this.send('cancelNotificationSettings');
            } else if (isSupplierMarginEdit) {
              this.send('cancelSupplierMarginEdit');
            } else if (isPlatformEdit) {
              this.send('cancelPlatformEdit');
            } else if (isSupplierSectionEdit) {
              this.send('cancelSupplierSettingsUpdate');
            }

            this.send('editSupplierBranchSettings');
            break;

          case 'notificationSettings':
            if (isSupplierBranchSettings) {
              this.send('cancelEditSupplierBranchSettings');
            } else if (isSupplierMarginEdit) {
              this.send('cancelSupplierMarginEdit');
            } else if (isPlatformEdit) {
              this.send('cancelPlatformEdit');
            } else if (isSupplierSectionEdit) {
              this.send('cancelSupplierSettingsUpdate');
            }

            this.send('editNotificationSettings', true);
            break;

          case 'marginSettingsSection':
            if (isSupplierBranchSettings) {
              this.send('cancelEditSupplierBranchSettings');
            } else if (isNotificationSettingEdit) {
              this.send('cancelNotificationSettings');
            } else if (isPlatformEdit) {
              this.send('cancelPlatformEdit');
            } else if (isSupplierSectionEdit) {
              this.send('cancelSupplierSettingsUpdate');
            }

            this.send('setSupplierMarginEdit', true);
            break;

          case 'platformSection':
            if (isSupplierBranchSettings) {
              this.send('cancelEditSupplierBranchSettings');
            } else if (isNotificationSettingEdit) {
              this.send('cancelNotificationSettings');
            } else if (isSupplierMarginEdit) {
              this.send('cancelSupplierMarginEdit');
            } else if (isSupplierSectionEdit) {
              this.send('cancelSupplierSettingsUpdate');
            }

            this.send('editPlatformSettings', true);
            break;

          case 'supplierSettings':
            if (isSupplierBranchSettings) {
              this.send('cancelEditSupplierBranchSettings');
            } else if (isNotificationSettingEdit) {
              this.send('cancelNotificationSettings');
            } else if (isSupplierMarginEdit) {
              this.send('cancelSupplierMarginEdit');
            } else if (isPlatformEdit) {
              this.send('cancelPlatformEdit');
            }

            this.send('editSupplierSection');
            break;

        }

        this.send('cancelSectionEdit');
      },
      cancelSectionEdit: function cancelSectionEdit() {
        this.controller.set('sectionToBeEdited', false);
      }
    }
  });
});