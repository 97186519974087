define('lxso/components/booking-questions/bq-products-options/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    uitk: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('finalSelectedProductsCount', this.get('selectedProductsAndOptions').uniqBy('activityId').length);
    },


    actions: {
      completeProductsAndOptionsSection: function completeProductsAndOptionsSection() {
        this.sendAction('updateBookingQuestionOfferDetails');
        this.set('productsAndOptionsAccordionComplete', true);
        this.set('optionsView', false);
        this.set('productsView', true);
        this.set('productsAndOptionsOpen', false);
        this.set('isProductsOptionsSectionExpanded', false);
        if (!this.get('isNew')) {
          this.isSectionEdited('productsAndOptions');
        } else {
          this.sendAction('openNextSection');
        }
      },
      toggleProductsAndOptions: function toggleProductsAndOptions() {
        this.set('isProductsOptionsSectionExpanded', !this.get('isProductsOptionsSectionExpanded'));
        var prodLink = document.getElementsByClassName('toggle-trigger products-and-options-accordion');
        $(prodLink).toggleClass('open');
      },
      openProductsAndOptionsModal: function openProductsAndOptionsModal() {
        this.set('productsView', true);
        this.set('optionsView', false);
        this.set('productsAndOptionsOpen', !this.get('productsAndOptionsOpen'));
      },
      closeProductsOptionsModal: function closeProductsOptionsModal() {
        this.send('resetSelectedProductsList');
        this.send('resetSelectedOptionsList');
        this.set('productsAndOptionsOpen', false);
        this.set('isProductsOptionsSectionExpanded', false);
        this.set('productsView', false);
        this.set('optionsView', false);
        if (this.get('noProductsSelected') || this.get('noOptionsSelected')) {
          this.set('productsAndOptionsAccordionComplete', false);
        }
        var prodLink = document.getElementsByClassName('toggle-trigger products-and-options-accordion');
        $(prodLink).toggleClass('open');
        var prodSection = document.getElementsByClassName('products-and-options-section');
        $(prodSection).toggleClass('close');
      },
      resetSelectedProductsList: function resetSelectedProductsList() {
        var selectedProducts = this.get('selectedProducts');
        var selectedProductsIdMap = selectedProducts.map(function (product) {
          return product.id;
        });
        this.get('productList').forEach(function (product) {
          var isProductSelected = selectedProductsIdMap.indexOf(product.id) >= 0;
          product.set('isProductSelected', isProductSelected);
        });
        this.set('productsSelectAllEnabled', selectedProducts.length > 0 && selectedProducts.length == this.get('productList').length);
        this.set('noProductsSelected', selectedProducts.length <= 0);
      },
      resetSelectedOptionsList: function resetSelectedOptionsList() {
        var totalOptionsCount = 0;
        var selectedProductsAndOptions = this.get('selectedProductsAndOptions');
        var selectedOptionsIdMap = selectedProductsAndOptions.map(function (product) {
          return product.offerId;
        });
        this.get('selectedProducts').forEach(function (selectedProduct) {
          selectedProduct.offers.forEach(function (option) {
            var isOptionSelected = selectedOptionsIdMap.indexOf(option.id) >= 0;
            option.set('isOptionSelected', isOptionSelected);
            totalOptionsCount++;
          });
        });
        this.set('optionsSelectAllEnabled', selectedProductsAndOptions.length > 0 && selectedProductsAndOptions.length == totalOptionsCount);
        this.set('noOptionsSelected', selectedProductsAndOptions.length <= 0);
      }
    }
  });
});