define('lxso/utils/change-history-details-display-modes/v4-offer-group-offer', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lodash', 'lxso/utils/change-history-details-display-modes/list'], function (exports, _generic, _lodash, _list) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'v4-offer-group-offer',
    diff: function diff(oldValue, newValue, _ref) {
      var i18n = _ref.i18n;

      var oldData = oldValue ? JSON.parse(oldValue) : {};
      var newData = newValue ? JSON.parse(newValue) : {};

      function difference(displayMode, value) {
        var newValue = value(newData);
        var oldValue = value(oldData);

        return !(_lodash.default.isEqual(oldValue, newValue) || !newValue && !oldValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }

      function translate(path, key) {
        return key && i18n.t(path + '.' + key).string;
      }

      return {
        name: difference(_generic.default, function (data) {
          return data.offerTitle;
        }),
        status: difference(_generic.default, function (data) {
          if (data.status) {
            return data.status.toLowerCase().capitalize();
          } else {
            return null;
          }
        }),
        startTime: difference(_generic.default, function (data) {
          return formatTime(parseTime(data.startTime));
        }),
        languageType: difference(_generic.default, function (data) {
          return data.languageInfo && translate('pages.offer.languageTypes', data.languageInfo.languageTypeId);
        }),
        languages: difference(_list.default, function (data) {
          return data.languageInfo && data.languageInfo.languages || [];
        })
      };
    }
  };


  var timeFormat = 'HH:mm';

  function parseTime(time) {
    return time && moment(time, timeFormat);
  }

  function formatTime(time) {
    return time && time.format(timeFormat);
  }
});