define('lxso/components/change-history/details/component', ['exports', 'lxso/mixins/change-type-modes'], function (exports, _changeTypeModes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_changeTypeModes.default, {
    changeDetail: Ember.computed('selectedChange.changeDetail', function () {
      var changeDetail = this.get('selectedChange.changeDetail');
      var attribute = changeDetail.get('attribute');
      return {
        contentName: attribute,
        displayMode: this.getChangeTypeDisplayMode(attribute),
        oldValue: changeDetail.get('oldValue'),
        newValue: changeDetail.get('newValue')
      };
    })
  });
});