define('lxso/validators/some-availability', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SomeAvailability = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var availabilities = model.get('availabilities.firstObject.availability');
      var someCapacity = void 0;
      if (model.get('offer').get('isLimitedInventory')) {
        if (model.get('offer').get('offerAttributes.scheduleType') === 'Hours of Operation') {
          someCapacity = availabilities.any(function (day) {
            return day.get('available') > 0 && day.get('capacity') > 0;
          });
        } else {
          someCapacity = availabilities.any(function (day) {
            return day.get('capacity') > 0;
          });
        }
      } else {
        someCapacity = availabilities.any(function (day) {
          return day.get('available') > 0;
        });
      }
      return someCapacity ? true : model.get('i18n').t('errors.customMessages.offers.someCapacity').string;
    }
  });

  SomeAvailability.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return ['model.availabilities.[]'];
    }
  });

  exports.default = SomeAvailability;
});