define('lxso/routes/activity/option-group', ['exports', 'lxso/mixins/example-modal', 'lxso/mixins/feature-flag', 'lxso/constants/option-group'], function (exports, _exampleModal, _featureFlag, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_featureFlag.default, _exampleModal.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),
    featureFlags: Ember.inject.service(),
    languageEnum: Ember.inject.service('language-enum'),
    optionGroupService: Ember.inject.service(),
    responseErrors: Ember.inject.service(),
    featureName: 'OptionGroupV4',
    omniture: {
      pageName: 'page.LX-Supply.OptionGroupList'
    },

    init: function init() {
      this._super.apply(this, arguments);
    },
    beforeModel: function beforeModel(transition) {
      var activityId = transition.params.activity.id;
      var activity = this.modelFor('activity');
      if (!activity.get('migrationStatus.offer')) {
        this.transitionTo('activity.offer-list', activityId);
      }
    },
    model: function model(params, transition) {
      var activityId = transition.params.activity.id;
      var activity = this.modelFor('activity');
      var platformId = this.get('session.session.authenticated.selectedBranch.platform_id');
      var platform = platformId ? this.store.findRecord('platform', platformId, { reload: true }) : null;
      var ticketTypesV4 = void 0;
      if (activity.get('migrationStatus.ticketType')) {
        ticketTypesV4 = this.store.findRecord('ticket-types-v4', activityId, { reload: true });
      }
      var optionGroupList = this.getOptionGroupList(activityId);
      return Ember.RSVP.hash({
        optionGroupList: optionGroupList,
        languageEnum: this.get('languageEnum').getLanguages(),
        activity: activity,
        platform: platform,
        ticketTypesV4: ticketTypesV4
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        activity: model.activity,
        optionGroupList: model.optionGroupList,
        languageEnum: model.languageEnum,
        ticketTypesV4: model.ticketTypesV4,
        platform: model.platform,
        SECTIONS: _optionGroup.SECTIONS,
        isOptionGroupOpen: true,
        isTicketsOpen: true,
        refreshSideNav: false,
        editingTicketType: model.ticketTypesV4 ? model.ticketTypesV4.get('ticketTypes').filter(function (tt) {
          return tt.get('isSelected');
        }).length === 0 : false
      });
      this.get('optionGroupService').setCrudMode(null);
      this.get('optionGroupService').setSectionToEdit(null);
    },
    getOptionGroupList: function getOptionGroupList(activityId) {
      return this.store.query('option-group', { activityId: activityId });
    },
    scrollToElement: function scrollToElement(elementSelector) {
      Ember.run.later(function () {
        var anchorElement = Ember.$(elementSelector).offset();
        if (anchorElement) {
          Ember.$('html, body').animate({
            scrollTop: anchorElement.top - 200
          }, 250);
        }
      });
    },


    actions: {
      openStep: function openStep(step) {
        switch (step) {
          case 'setup-option-group':
            {
              this.controller.toggleProperty('isOptionGroupOpen');
              break;
            }
          case 'setup-tickets':
            {
              this.controller.toggleProperty('isTicketsOpen');
              break;
            }
        }
      },
      editOptionGroupFromList: function editOptionGroupFromList(optionGroupId) {
        this.get('optionGroupService').setCrudMode(_optionGroup.CRUD_MODES.UPDATE);
        this.controller.set('isTicketsOpen', false);
        this.transitionTo('activity.option-group.group', optionGroupId);
      },
      cloneOptionGroupFromList: function cloneOptionGroupFromList(optionGroupId) {
        this.transitionTo('activity.option-group.group', optionGroupId, { queryParams: { clone: 'true' } });
      },
      deactivateOptionGroupFromList: function deactivateOptionGroupFromList(optionGroupId) {
        var _this = this;

        this.controller.set('lastUpdated', _optionGroup.SECTIONS.OPTION_GROUP);
        var optionGroup = this.store.peekRecord('option-group', optionGroupId);
        optionGroup.get('offers').forEach(function (option) {
          return option.set('status', _optionGroup.STATUS.INACTIVE);
        });
        optionGroup.set('status', _optionGroup.STATUS.INACTIVE);
        this.controller.set('isDeactivatingOptionGroup', true);
        optionGroup.save({ adapterOptions: { section: _optionGroup.SECTIONS.DETAILS } }).then(function (optionGroup) {
          _this.get('notifications').success(_this.get('i18n').t('pages.optionGroup.optionGroupSection.list.deactivateSuccess'));
        }).catch(function (error) {
          _this.get('notifications').error(_this.get('i18n').t('pages.optionGroup.optionGroupSection.list.deactivateFailure'));
        }).finally(function () {
          _this.controller.set('isDeactivatingOptionGroup', false);
          _this.scrollToElement('#option-group-box');
        });
      },
      addOptionGroup: function addOptionGroup() {
        this.transitionTo('activity.option-group.group', 'new');
      },
      editTicketTypes: function editTicketTypes() {
        this.controller.set('editingTicketType', true);
      },
      cancelTicketTypes: function cancelTicketTypes(ticketTypesV4) {
        ticketTypesV4.rollbackAttributes();
        this.get('responseErrors').clearErrors();
        this.controller.set('editingTicketType', false);
      },
      saveTickets: function saveTickets(ticketTypesV4) {
        var _this2 = this;

        this.controller.set('lastUpdated', _optionGroup.SECTIONS.TICKETS);
        this.controller.set('isSavingTicketTypes', true);
        this.controller.get('ticketTypesV4').save().then(function () {
          _this2.controller.set('editingTicketType', false);
          _this2.get('notifications').success(_this2.get('i18n').t('components.alert.saveSuccess'));
        }).finally(function () {
          _this2.controller.set('isSavingTicketTypes', false);
        });
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var ticketTypesV4 = controller.get('ticketTypesV4');
        if (ticketTypesV4 && ticketTypesV4.get('hasDirtyAttributes')) {
          ticketTypesV4.rollbackAttributes();
        }
      }
    }
  });
});