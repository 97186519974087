define('lxso/services/language-enum', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    getCachedLanguages: function getCachedLanguages() {
      return this.get('languageEnum');
    },
    removeDuplicates: function removeDuplicates(array, key) {
      var uniqueLabels = new Set();
      return array.filter(function (obj) {
        if (!uniqueLabels.has(obj[key])) {
          uniqueLabels.add(obj[key]);
          return true;
        }
        return false;
      });
    },
    getLanguages: function getLanguages() {
      var _this = this;

      if (this.get('languageEnum') && this.get('languageEnum').length) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          resolve(_this.get('languageEnum'));
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/enums/tour_languages';
          _this.get('ajaxWrapper').ajaxGet(url, null, {
            cache: false,
            xhrFields: {
              withCredentials: true
            }
          }).then(function (results) {
            var uniqueResults = _this.removeDuplicates(results.sortBy('id'), 'label').sortBy('label');
            _this.set('languageEnum', uniqueResults);
            resolve(uniqueResults);
          }).catch(function (reason) {
            return reject(reason);
          });
        });
      }
    }
  });
});