define('lxso/routes/activity/photos', ['exports', 'lxso/config/environment', 'lxso/mixins/example-modal', 'lxso/utils/omniture', 'lxso/services/mediavault', 'lxso/utils/form-validator'], function (exports, _environment, _exampleModal, _omniture, _mediavault, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var extendedDuration = { clearDuration: 6400 };

  exports.default = Ember.Route.extend(_exampleModal.default, {

    mediavault: Ember.inject.service('mediavault'),
    uploader: Ember.inject.service('uploader'),
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),
    featureFlags: Ember.inject.service(),
    loadingTime: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.Photos'
    },
    model: function model(params) {
      var activityId = this.modelFor('activity').get('id');
      return Ember.RSVP.hash({
        videos: this.store.query('video', { activityId: activityId })
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      // Schedule an update of the display images in case we need new ones
      this.set('numberOfUpdateDisplayImagesChecks', 0);
      this.scheduleUpdateOfDisplayImages();

      this.controller.set('activity', this.modelFor('activity'));
      this.controller.set('videos', model.videos);
      this.createHeroVideoIfEmpty();
      if (!this.controller.get('initialized')) {
        this.controller.setProperties({
          initialized: true,
          approvedAssets: Ember.computed.filter('activity.assets.[]', function (asset) {
            return asset.get('approval') !== 'rejected';
          }),
          maxImagesReached: Ember.computed.gte('approvedAssets.length', _environment.default.mvapi.maxImages),
          tooFewImages: Ember.computed.lt('activity.assets.length', _environment.default.mvapi.minImages),
          savingVideo: false,
          maxPhotosCountToLoad: 10
        });
      }

      Ember.run.next(function () {
        var timeTaken = _this.get('loadingTime').getLoadTime();
        console.info('%c Activity photos and videos page load time is ' + timeTaken + ' seconds', 'font-weight: bold');
      });
    },
    resetController: function resetController() {
      this.controller.setProperties({
        maxPhotosCountToLoad: 10
      });
    },


    rerenderPlupload: Ember.observer('activity.assets.[]', function () {
      var _this2 = this;

      // Trick the plupload button component into rerendering.
      // In the template, disable render of the component if showPluploadComponent
      // is not set to true. Setting showPluploadComponent to true after a millisecond
      // causes the component to be removed from the page and then rerendered.
      // This occurs on every visit to the photos parent route.
      this.set('controller.showPluploadComponent', undefined);

      Ember.run.later(function () {
        _this2.set('controller.showPluploadComponent', true);
      });
    }),

    scheduleUpdateOfDisplayImages: function scheduleUpdateOfDisplayImages() {
      var _this3 = this;

      this.incrementProperty('numberOfUpdateDisplayImagesChecks');

      // Only check for updated images 5 times before giving up
      if (this.get('numberOfUpdateDisplayImagesChecks') <= 5) {
        // Run this check in 5 seconds
        Ember.run.later(function () {
          _this3.controller.get('activity.assets').then(function (assets) {
            // If the display images need a refresh, reload the assets and
            // schedule another udpate check
            if (_this3.get('missingDisplayImages') === true) {
              assets.reload().then(function () {
                _this3.scheduleUpdateOfDisplayImages();
              });
            }
          });
        }, 5000);
      }
    },


    missingDisplayImages: Ember.computed('controller.activity.assets.available.[]', {
      get: function get() {
        // Only do this on the activity.photos route. The
        // scheduleUpdateOfDisplayImages method will continue to execute otherwise.
        if (this.router.currentRouteName === 'activity.photos') {
          var needsImages = false;

          // Loop through each asset and see if it has any images available
          this.controller.get('activity.assets').forEach(function (asset) {
            if (asset.get('displayImage') === undefined) {
              needsImages = true;
            }
          });

          return needsImages;
        } else {
          return false;
        }
      }
    }),

    onlyApproved: function onlyApproved(asset) {
      'use strict';

      function isApproved(asset) {
        return asset.get('approval') === 'approved';
      }

      var approvedAssets = this.controller.get('activity.assets').filter(isApproved);
      return isApproved(asset) && approvedAssets.length === 1;
    },
    createHeroVideoIfEmpty: function createHeroVideoIfEmpty() {
      var videos = this.controller.get('videos');
      var heroVideo = videos.filter(function (video) {
        return video.get('hero') === true;
      });
      if (heroVideo.length === 0) {
        heroVideo.push(this.store.createRecord('video', {
          activity_id: this.controller.get('activity.id'),
          link: '',
          hero: true
        }));
      }
      this.controller.set('heroVideo', heroVideo[0]);
    },
    getValidations: function getValidations() {
      var validations = {
        model: this.controller.get('heroVideo'),
        attributes: ['link']
      };
      return validations;
    },


    actions: {
      loadNext10Photos: function loadNext10Photos() {
        var maxPhotosCountToLoad = this.controller.get('maxPhotosCountToLoad');
        this.controller.set('maxPhotosCountToLoad', maxPhotosCountToLoad + 10);
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'photos';

        if (window.MXI_DEBUG) {
          window.MXI_DEBUG = false;
        }

        // Reset the staged files and the saving variables every time we visit the photos parent route
        this.set('controller.stagedFiles', 0);
        this.set('controller.saving', undefined);
        this.rerenderPlupload();
      },
      willTransition: function willTransition() {
        this.controller.set('validationErrors', null);
        //for tests
        delete window.pageName;
      },
      sendOmnitureDataOnAdd: function sendOmnitureDataOnAdd() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AddPhotos');
      },
      openDeleteModal: function openDeleteModal(asset, index) {
        this.controller.set('assetToDelete', asset);
        this.controller.set('indexToDelete', index);
        // do not open modal if user is trying to delete the hero image
        if (asset.get('heroImage')) {
          this.send('deleteAsset');
        } else {
          this.controller.set('isDeleteConfirmationModalOpen', true);
        }
      },
      deleteAsset: function deleteAsset() {
        var _this4 = this;

        var asset = this.controller.get('assetToDelete');
        var index = this.controller.get('indexToDelete');
        var mediaVault = this.get('mediavault');
        if (asset.get('heroImage')) {
          this.get('notifications').info(this.get('i18n').t('pages.photos.delete.notAllowed'), extendedDuration);
          this.controller.set('isDeleteConfirmationModalOpen', false);
        } else if (this.controller.get('activity.isActivityPublished') && this.onlyApproved(asset)) {
          var toFewToDeleteError = this.get('i18n').t('pages.photos.errors.toFewToDelete');
          this.controller.set('validationErrors', [toFewToDeleteError]);
        } else {
          this.controller.set('deleting-' + index, true);
          var res = '';
          res = mediaVault.deleteImage(asset.id, this.controller.get('activity.id'));
          res.then(function (result) {
            _this4.controller.get('approvedAssets').removeObject(asset);
            _this4.rerenderPlupload();
            _this4.get('notifications').success(_this4.get('i18n').t('pages.photos.delete.deleted'), extendedDuration);
            Ember.Logger.log('Removed asset: ', result);
          }).catch(function (reason) {
            asset.rollback();
            Ember.Logger.log('Error removing asset: ', reason);
          }).finally(function () {
            _this4.controller.set('deleting-' + index, false);
            _this4.controller.set('isDeleteConfirmationModalOpen', false);
          });
        }
      },
      cancelDelete: function cancelDelete() {
        this.controller.set('isDeleteConfirmationModalOpen', false);
        this.controller.set('assetToDelete', null);
        this.controller.set('indexToDelete', null);
      },
      continue: function _continue() {
        //quick fix for uat, should follow established validation
        //patterns
        if (!this.controller.get('tooFewImages')) {
          // Send page completion data to Omniture
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Save');
          (0, _omniture.default)({
            events: 'event20' //event20 tracks successful completion of "Photos" page
          });

          if (this.get('session.data.fromPublish')) {
            this.get('session').set('data.fromPublish', false);
            this.transitionTo('activity.publish');
          } else {
            this.transitionTo('activity.location', this.controller.get('activity.id'));
          }
        }
      },


      // stageImage is called for each file selected in the file uploader
      stageImage: function stageImage(file, plupload) {
        var _this5 = this;

        this.controller.set('lastSaved', 'photos');
        this.controller.set('validationErrors', null);
        this.controller.set('saving', true);

        file.read().then(function (base64) {
          file.set('base64', base64);
          return Ember.RSVP.resolve();
        }).then(function () {
          var valid = _this5.get('mediavault').validateFile(file);
          return valid;
        }).catch(function (reason) {
          file.set('errors', reason);
          // Ember.Logger.log('File read failed', reason, file);
        }).finally(function () {
          _this5.controller.incrementProperty('stagedFiles');

          // See if we can move to the next page
          // if (this.controller.get('stagedFiles') === this.get('uploader.files.length')) {
          if (_this5.controller.get('stagedFiles') === plupload.uploader.files.length) {
            _this5.controller.set('saving', undefined);
            _this5.replaceWith('activity.photos.staging', _this5.controller.get('activity.id'));
          }
        });
      },
      setHeroImage: function setHeroImage(fileId, asset) {
        var _this6 = this;

        this.controller.set('lastSaved', 'photos');
        var assets = this.controller.get('activity.assets');
        var prevHeroAsset = assets.find(function (asset) {
          return asset.get('heroImage');
        });
        var activityId = this.controller.get('activity.id');
        var mediaVault = this.get('mediavault');

        assets.forEach(function (asset) {
          return asset.set('heroImage', false);
        });

        return mediaVault.updateHeroImage(fileId, activityId, asset).then(function () {
          var newHeroAsset = assets.find(function (asset) {
            return asset.get('fileId') === fileId;
          });
          newHeroAsset.set('heroImage', true);
          _this6.get('notifications').success(_this6.get('i18n').t('pages.photos.heroImage.updated'));
        }).catch(function (error) {
          prevHeroAsset.set('heroImage', true);
          mediaVault.expireCredentials();
          _this6.get('notifications').error(_this6.get('i18n').t('pages.photos.heroImage.error'));
        });
      },
      saveVideo: function saveVideo(video) {
        var _this7 = this;

        this.controller.set('lastSaved', 'videos');
        this.controller.set('savingVideo', true);
        (0, _formValidator.validate)(this.getValidations()).then(function () {
          return video.save();
        }).then(function () {
          _this7.get('notifications').success(_this7.get('i18n').t('pages.videos.videoSaved'));
        }).catch(function (err) {
          _this7.controller.set('errors', err);
        }).finally(function () {
          _this7.controller.set('savingVideo', false);
        });
      },
      deleteVideo: function deleteVideo(video) {
        var _this8 = this;

        this.controller.set('lastSaved', 'videos');
        this.controller.set('savingVideo', true);
        var videoId = video.id;
        video.deleteRecord();
        video.save().then(function () {
          _this8.createHeroVideoIfEmpty();
          _this8.get('notifications').success(_this8.get('i18n').t('pages.videos.videoDeleted'));
        }).catch(function (err) {
          _this8.controller.set('errors', err);
        }).finally(function () {
          _this8.controller.set('savingVideo', false);
        });
      },
      approveVideo: function approveVideo(video) {
        var _this9 = this;

        this.controller.set('lastSaved', 'videos');
        this.controller.set('savingVideo', true);
        video.set('status', 'ACCEPTED');
        video.save({ adapterOptions: { 'action': 'statusChange' } }).then(function () {
          _this9.get('notifications').success(_this9.get('i18n').t('pages.videos.videoApproved'));
        }).catch(function (err) {
          _this9.controller.set('errors', err);
        }).finally(function () {
          _this9.controller.set('savingVideo', false);
        });
      },
      rejectVideo: function rejectVideo(video) {
        var _this10 = this;

        this.controller.set('lastSaved', 'videos');
        this.controller.set('savingVideo', true);
        video.set('status', 'REJECTED');
        video.save({ adapterOptions: { 'action': 'statusChange' } }).then(function () {
          _this10.get('notifications').success(_this10.get('i18n').t('pages.videos.videoRejected'));
        }).catch(function (err) {
          _this10.controller.set('errors', err);
        }).finally(function () {
          _this10.controller.set('savingVideo', false);
        });
      },
      rejectReasonChange: function rejectReasonChange(reason) {
        this.controller.get('heroVideo').set('rejectReason', reason);
      }
    }
  });
});