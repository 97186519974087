define('lxso/components/platform-settings-operational/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    staticData: Ember.inject.service(),

    apiVersionOptions: Ember.computed(function () {
      return this.get('staticData').getApiVersion().map(function (api) {
        return '' + api.label;
      });
    }),

    apiVersionValues: Ember.computed(function () {
      return this.get('staticData').getApiVersion().map(function (api) {
        return '' + api.v;
      });
    }),

    actions: {
      save: function save() {
        this.sendAction('save');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      edit: function edit() {
        this.sendAction('edit');
      },
      uploadCsv: function uploadCsv() {
        this.sendAction('uploadCsv');
      },
      connectivityStatusChange: function connectivityStatusChange() {
        this.toggleProperty('platform.isConnectivityEnabled');
        if (!this.get('platform.isConnectivityEnabled')) {
          this.set('platform.isPricingIngestionEnabled', false);
        }
        this.sendAction('connectivityStatusChange');
      },
      redemptionStatusChange: function redemptionStatusChange() {
        this.toggleProperty('platform.isRedemptionEnabled');
        this.sendAction('redemptionStatusChange');
      },
      bookAndHoldStatusChange: function bookAndHoldStatusChange() {
        this.toggleProperty('platform.isBookAndHoldEnabled');
        this.sendAction('bookAndHoldStatusChange');
      },
      hotelPickupStatusChange: function hotelPickupStatusChange() {
        this.toggleProperty('platform.isHotelPickupEnabled');
        this.sendAction('hotelPickupStatusChange');
      },
      pricingIngestionFlagChange: function pricingIngestionFlagChange() {
        this.toggleProperty('platform.isPricingIngestionEnabled');
        this.sendAction('pricingIngestionFlagChange');
      },
      apiVersionSelected: function apiVersionSelected(version) {
        this.sendAction('apiVersionSelected', version);
      }
    }
  });
});