define('lxso/components/booking-questions/bq-selected-playback/component', ['exports', 'lodash/array'], function (exports, _array2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    staticData: Ember.inject.service(),
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var travelerInfo = this.get('bookingQuestionSet.questionDetails');
      var allTravelInfoSelected = false;
      var allSafetyInfoSelected = false;
      var allPassportInfoSelected = false;
      var selectedTravelInfoIds = [];

      if (travelerInfo) {
        travelerInfo.forEach(function (parent) {
          if (parent.id && parent.isEnabled) {
            selectedTravelInfoIds.push(parent.id);
          } else if (parent.children && parent.children.length > 0) {
            if (parent.name === 'Passport information' && parent.isEnabled) {
              allPassportInfoSelected = true;
            } else if (parent.name === 'Safety information' && parent.isEnabled) {
              allSafetyInfoSelected = true;
            }
            parent.children.forEach(function (child) {
              if (child.id && child.isEnabled) {
                selectedTravelInfoIds.push(child.id);
              }
            });
          }
        });
      }
      allTravelInfoSelected = selectedTravelInfoIds.length === this.get('staticData').getTravelerInfoFieldIds().length;
      this.set('allTravelInfoSelected', allTravelInfoSelected);
      this.set('selectedTravelInfoIds', selectedTravelInfoIds);
      var singleTravelLabelFieldIds = selectedTravelInfoIds;
      if (allTravelInfoSelected) {
        singleTravelLabelFieldIds = [];
      } else {
        if (allSafetyInfoSelected) {
          singleTravelLabelFieldIds = _array2.default.difference(singleTravelLabelFieldIds, this.get('staticData').getAllSafetyInfoFieldIds());
        }
        if (allPassportInfoSelected) {
          singleTravelLabelFieldIds = _array2.default.difference(singleTravelLabelFieldIds, this.get('staticData').getAllPassportInfoFieldIds());
        }
      }
      var travelerMessage = this.getTravelerMessage(allTravelInfoSelected, allSafetyInfoSelected, allPassportInfoSelected, singleTravelLabelFieldIds);
      this.set('travelerMessage', travelerMessage);

      var pickupInfo = this.get('bookingQuestionSet.pickupDetails');
      var pickupInfoDetails = this.getPickUpAndDropOffSelections(pickupInfo, true);
      pickupInfoDetails.message = this.getPickupDropoffMessage(pickupInfoDetails, true);
      this.set('pickupInfoDetails', pickupInfoDetails);

      var dropoffInfo = this.get('bookingQuestionSet.dropoffDetails');
      var dropoffInfoDetails = this.getPickUpAndDropOffSelections(dropoffInfo, false);
      dropoffInfoDetails.message = this.getPickupDropoffMessage(dropoffInfoDetails, false);
      this.set('dropoffInfoDetails', dropoffInfoDetails);
    },
    getPickupDropoffMessage: function getPickupDropoffMessage(pickupDropoffInfoSelections, isPickup) {
      var i18n = this.get('i18n');
      var singleFields = pickupDropoffInfoSelections.selectedInfoIds.map(function (fieldId) {
        return i18n.t('pages.bookingQuestions.questionsPlayBack.' + fieldId).string;
      });
      var message = '';
      if (pickupDropoffInfoSelections.allInfoSelected) {
        message = isPickup ? i18n.t('pages.bookingQuestions.questionsPlayBack.allPickup') : i18n.t('pages.bookingQuestions.questionsPlayBack.allDropoff');
      } else if (pickupDropoffInfoSelections.singleLabelFieldIds.length === 0) {
        if (pickupDropoffInfoSelections.allLocationSelected) {
          message = isPickup ? i18n.t('pages.bookingQuestions.questionsPlayBack.allArrivalLoc') : i18n.t('pages.bookingQuestions.questionsPlayBack.allDropoffLoc');
        }
      } else {
        message = this.getMessageFromItemsList(singleFields);
      }
      return message;
    },
    getPickUpAndDropOffSelections: function getPickUpAndDropOffSelections(pickUpDropOffInfo, isPickup) {
      var allLocationSelected = false;
      var allInfoSelected = false;

      var selectedInfoIds = [];
      if (pickUpDropOffInfo) {
        pickUpDropOffInfo.forEach(function (parent) {
          if (parent.id && parent.isEnabled) {
            selectedInfoIds.push(parent.id);
          } else if (parent.children && parent.children.length > 0) {
            if ((parent.name === 'Arrival locations' || parent.name === 'Dropoff locations') && parent.isEnabled) {
              allLocationSelected = true;
            }
            parent.children.forEach(function (child) {
              if (child.id && child.isEnabled) {
                selectedInfoIds.push(child.id);
              }
            });
          }
        });
        allInfoSelected = selectedInfoIds.length === this.get('staticData').getPickupInfoFieldIds().length;
      }
      var singleLabelFieldIds = selectedInfoIds;
      if (allInfoSelected) {
        singleLabelFieldIds = [];
      } else if (allLocationSelected && isPickup) {
        singleLabelFieldIds = _array2.default.difference(singleLabelFieldIds, this.get('staticData').getPickupInfoFieldIds());
      } else if (allLocationSelected && !isPickup) {
        singleLabelFieldIds = _array2.default.difference(singleLabelFieldIds, this.get('staticData').getDropoffInfoFieldIds());
      }

      return {
        allLocationSelected: allLocationSelected,
        allInfoSelected: allInfoSelected,
        selectedInfoIds: selectedInfoIds,
        singleLabelFieldIds: singleLabelFieldIds
      };
    },
    getTravelerMessage: function getTravelerMessage(allTravelInfoSelected, allSafetyInfoSelected, allPassportInfoSelected, singleTravelLabelFieldIds) {
      var i18n = this.get('i18n');
      var travelerInfoSingleFields = singleTravelLabelFieldIds.map(function (fieldId) {
        return i18n.t('pages.bookingQuestions.questionsPlayBack.' + fieldId).string;
      });
      var travelMessageItemsList = [];
      var travelerMessage = '';
      if (allTravelInfoSelected) {
        travelerMessage = i18n.t('pages.bookingQuestions.questionsPlayBack.allTravel');
      } else if (singleTravelLabelFieldIds.length === 0) {
        if (allSafetyInfoSelected && allPassportInfoSelected) {
          travelerMessage = i18n.t('pages.bookingQuestions.questionsPlayBack.allPassportAndSafety');
        } else if (allPassportInfoSelected) {
          travelerMessage = i18n.t('pages.bookingQuestions.questionsPlayBack.allPassport');
        } else if (allSafetyInfoSelected) {
          travelerMessage = i18n.t('pages.bookingQuestions.questionsPlayBack.allSafety');
        }
      } else {
        if (allPassportInfoSelected) {
          travelMessageItemsList = [].concat(_toConsumableArray(travelMessageItemsList), [i18n.t('pages.bookingQuestions.questionsPlayBack.allPassport')]);
        }
        if (allSafetyInfoSelected) {
          travelMessageItemsList = [].concat(_toConsumableArray(travelMessageItemsList), [i18n.t('pages.bookingQuestions.questionsPlayBack.allSafety')]);
        }
        travelMessageItemsList = [].concat(_toConsumableArray(travelMessageItemsList), _toConsumableArray(travelerInfoSingleFields));
        travelerMessage = this.getMessageFromItemsList(travelMessageItemsList);
      }
      return travelerMessage;
    },
    getMessageFromItemsList: function getMessageFromItemsList(travelMessageItemsList) {
      if (travelMessageItemsList.length === 0) {
        return '';
      } else if (travelMessageItemsList.length === 1) {
        return travelMessageItemsList[0];
      } else {
        var lastItem = travelMessageItemsList[travelMessageItemsList.length - 1];
        var remainingItems = travelMessageItemsList.slice(0, travelMessageItemsList.length - 1);
        var and = this.get('i18n').t('common.words.and');
        return remainingItems.join('; ') + ' ' + and + ' ' + lastItem;
      }
    },


    actions: {}
  });
});