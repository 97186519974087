define('lxso/components/multi-select-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    languageEnum: Ember.inject.service('language-enum'),

    init: function init() {
      'use strict';

      var _this = this;

      this._super.apply(this, arguments);
      this.get('languageEnum').getLanguages().then(function (results) {
        _this.set('languageList', results);
      });
    },


    actions: {
      onItemSelect: function onItemSelect(id) {
        this.sendAction('onItemSelect', id);
      }
    }
  });
});