define('lxso/validators/array-content-length', ['exports', 'ember-cp-validations/validators/length', 'lxso/mixins/array-validation-model'], function (exports, _length, _arrayValidationModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArrayContentLength = _length.default.extend(_arrayValidationModel.default);

  ArrayContentLength.reopenClass({
    getDependentsFor: function getDependentsFor(attribute) {
      return ['model.' + attribute + '.[]'];
    }
  });

  exports.default = ArrayContentLength;
});