define('lxso/routes/activity/overview', ['exports', 'lxso/mixins/publish-api', 'lxso/utils/omniture', 'lxso/utils/form-validator'], function (exports, _publishApi, _omniture, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Route.extend(_publishApi.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),
    loadingTime: Ember.inject.service(),
    v4ContentService: Ember.inject.service(),
    v4CatalogService: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.BasicInformation'
    },

    setupController: function setupController() {
      var _this = this;

      this.controller.set('activity', this.modelFor('activity'));
      this.controller.set('dirty', false);
      this.controller.set('errors', []);
      this.setRollbacks();

      Ember.run.next(function () {
        var timeTaken = _this.get('loadingTime').getLoadTime();
        console.info('%c Activity overview page load time is ' + timeTaken + ' seconds', 'font-weight: bold');
      });
    },
    setRollbacks: function setRollbacks() {
      this.copyAndStoreHighlights();
      this.copyAndStoreBasics();
    },
    copyAndStoreHighlights: function copyAndStoreHighlights() {
      this.controller.set('highlightsCopy', this.controller.get('activity.highlights.highlights').map(function (item) {
        return item;
      }));
    },
    copyAndStoreBasics: function copyAndStoreBasics() {
      this.controller.set('tourNameCopy', this.controller.get('activity.basic.long_title'));
      this.controller.set('tourDescriptionCopy', this.controller.get('activity.basic.description'));
    },
    resetToInitialState: function resetToInitialState() {
      if (this.controller.get('dirty')) {
        this.controller.set('activity.highlights.highlights', this.controller.get('highlightsCopy'));
        this.controller.set('activity.basic.long_title', this.controller.get('tourNameCopy'));
        this.controller.set('activity.basic.internal_title', this.controller.get('tourNameCopy'));
        this.controller.set('activity.basic.short_title', this.controller.get('tourNameCopy'));
        this.controller.set('activity.basic.description', this.controller.get('tourDescriptionCopy'));
      }
    },
    getValidationList: function getValidationList(activity) {
      var validations = [{ model: activity.get('basic'), attributes: ['description'] }, { model: activity.get('basic'), attributes: ['internal_title'] }];
      if (!this.controller.get('activity.isViewOnlyForGroundTransfer')) {
        validations.push({ model: activity.get('highlights'), attributes: ['highlights'] });
      }
      return validations;
    },


    actions: {
      dirty: function dirty() {
        this.setDirtyAttribute();
      },
      validateIfInvalid: function validateIfInvalid() {
        var _this2 = this;

        this.setDirtyAttribute();
        if (!Ember.isEmpty(this.controller.get('errors'))) {
          var activity = this.controller.get('activity');
          activity.get('highlights.highlights').arrayContentDidChange();
          activity.set('basic.internal_title', activity.get('basic.long_title'));
          var validations = this.getValidationList(activity);
          _formValidator.validate.apply(undefined, _toConsumableArray(validations)).then(function () {
            return _this2.controller.set('errors', undefined);
          }).catch(function (err) {
            return _this2.controller.set('errors', err);
          });
        }
      },
      highlightsChanged: function highlightsChanged() {
        'use strict';

        this.setDirtyAttribute();
        this.controller.set('highlightsChanged', true);
        this.controller.send('validateIfInvalid');
      },
      updateBasics: function updateBasics() {
        var _this3 = this;

        var route = this;
        var controller = this.controller;
        var activity = route.controller.get('activity');
        controller.set('saving', true);

        if (activity.get('isActivityPublished')) {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Publish');
        } else {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Save');
        }

        activity.get('highlights.highlights').arrayContentDidChange();
        var validations = this.getValidationList(activity);
        _formValidator.validate.apply(undefined, _toConsumableArray(validations)).then(function () {
          _this3.controller.set('activity.basic.internal_title', _this3.controller.get('activity.basic.long_title'));
          _this3.controller.set('activity.basic.short_title', _this3.controller.get('activity.basic.long_title'));
        }).then(function () {
          if (activity.get('isContentAndLogisticsMigratedToV4')) {
            return _this3.get('v4ContentService').updateHighlights(activity.get('id'), activity.get('highlights'));
          } else {
            return activity.updateHighlights(activity.get('highlights'));
          }
        }).then(function () {
          if (activity.get('isContentAndLogisticsMigratedToV4')) {
            return _this3.get('v4ContentService').updateBasic(activity.get('id'), activity.get('basic'));
          } else {
            return activity.updateBasic(activity.get('basic'));
          }
        }).then(function () {
          if (activity.get('isContentAndLogisticsMigratedToV4')) {
            return _this3.get('v4CatalogService').updateActivityTitle(activity.get('id'), activity.get('basic'));
          } else {
            return;
          }
        }).then(function () {
          _this3.setRollbacks();
          // Send page completion data to Omniture
          (0, _omniture.default)({
            events: 'event18' //event18 tracks successful completion of "Overview" page
          });

          _this3.controller.set('dirty', false);
          var activityId = activity.get('id');
          if (activity.get('isActivityPublished')) {
            _this3.controller.set('saving', false);
            _this3.publishChanges(activity);
          } else {
            if (_this3.get('session.data.fromPublish')) {
              _this3.get('session').set('data.fromPublish', false);
              route.transitionTo('activity.publish');
            } else {
              route.transitionTo('activity.details', activityId);
            }
          }
        }).catch(function (reason) {
          controller.set('saving', false);
          controller.set('errors', reason);
          (0, _formValidator.scrollToFirstError)();
          Ember.Logger.error('Something went wrong: ', reason);
        });
      },
      willTransition: function willTransition() {
        //for tests
        delete window.pageName;
        this.resetToInitialState();
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'overview';
        this.controller.set('errors', '');
        this.controller.set('saving', false);
        return true;
      }
    }

  });
});