define('lxso/instance-initializers/omniture', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    Ember.Route.reopen({
      render: function render() {
        this._super.apply(this, arguments);

        var omnitureData = this.get('omniture');

        if (omnitureData) {
          (0, _omniture.default)(omnitureData);
        }
      }
    });
  }

  exports.default = {
    name: 'omniture',
    initialize: initialize
  };
});