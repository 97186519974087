define('lxso/validators/unique-ticket-types', ['exports', 'ember-cp-validations/validators/base', 'lxso/utils/overlap-failure-reducer'], function (exports, _base, _overlapFailureReducer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UniqueTicketTypes = _base.default.extend({
    store: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    validate: function validate(value, options, model, attribute) {
      'use strict';

      var _this = this;

      var priceArray = model.get('prices');
      var errorMessage = model.get('i18n').t('errors.customMessages.offers.uniqueTicketTypes').string;
      var failures = [];
      var vehicleTicketTypeID = 51;

      var guestTypeArray = priceArray.map(function (price, index) {
        var ticketTypeId = price.get('ticketTypeId');
        var ticketType = _this.get('store').peekRecord('ticket-type', ticketTypeId);
        var guestTypeId = ticketType.get('guest_type_id');
        return guestTypeId;
      });

      //relax check for delete and create of same ticket during PG edit
      var isPriceUpdateEnabled = this.get('featureFlags').checkEnabled('PriceUpdate');
      var guestTypeOper = void 0;
      if (isPriceUpdateEnabled) {
        guestTypeOper = priceArray.map(function (price) {
          var oper = price.get('operation') === '' ? 'create' : price.get('operation');
          return oper;
        });
      }
      var ifSameOperation = true;
      for (var i = 0; i < guestTypeArray.get('length'); i++) {
        for (var j = i + 1; j < guestTypeArray.get('length'); j++) {
          if (isPriceUpdateEnabled) {
            ifSameOperation = guestTypeOper[i] === guestTypeOper[j];
          }
          if (i !== j && guestTypeArray[i] !== vehicleTicketTypeID && guestTypeArray[i] === guestTypeArray[j] && ifSameOperation) {
            failures.push('ticketType' + i, 'ticketType' + j);
          }
        }
      }

      if (failures.length > 0) {
        return (0, _overlapFailureReducer.overlapFailureReducer)(failures, errorMessage);
      } else {
        return true;
      }
    }
  });

  exports.default = UniqueTicketTypes;
});