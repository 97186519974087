define('lxso/mixins/component-validator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    invalid: Ember.computed('errors', function () {
      'use strict';

      var errors = this.get('errors');
      return Ember.isArray(errors) && Ember.isPresent(errors);
    })
  });
});