define('lxso/models/fragments/option-group/offer-detail', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    startTime: {
      descriptionKey: 'pages.optionGroup.optionGroupSection.options.commentaryAndSchedule.startTime',
      validators: [(0, _validation.validator)('presence', {
        presence: true
      }), (0, _validation.validator)('time-format', {
        showSuggestions: true,
        disabled: Ember.computed.not('model.startTime')
      })]
    },
    endTime: {
      descriptionKey: 'pages.optionGroup.optionGroupSection.options.commentaryAndSchedule.endTime',
      validators: [(0, _validation.validator)('presence', {
        presence: true
      }), (0, _validation.validator)('time-format', {
        showSuggestions: true,
        disabled: Ember.computed.not('model.endTime')
      })]
    }
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    offerIds: _emberData.default.attr('array'),
    startTime: _emberData.default.attr('string'),
    endTime: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    languageInfo: (0, _attributes.fragment)('fragments/option-group/language-info'),
    isSelected: _emberData.default.attr('boolean', { defaultValue: false }),
    uuid: _emberData.default.attr('number', { defaultValue: Ember.uuid() }),
    subOffers: (0, _attributes.fragmentArray)('fragments/option-group/sub-offer-detail'),
    i18n: Ember.inject.service()
  });
});