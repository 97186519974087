define('lxso/components/price-group/component', ['exports', 'lxso/utils/array-values', 'lxso/utils/omniture'], function (exports, _arrayValues, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var TREAT_AS_ADULT_RESTRICTION_TYPE_ID = 9;

  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    responseErrors: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    permissions: Ember.inject.service(),
    classNameBindings: ['componentClasses'],

    init: function init() {
      this.set('isPriceSectionDirty', false);
      this._super.apply(this, arguments);
      var pg = this.get('priceGroup');
      if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
        this.set('ticketAddedToPg', false);
        if (this.get('permissions').checkEnabled('V3InternalSettings')) {
          this.set('isMarginOverride', true);
        } else {
          this.set('isMarginOverride', false);
        }
        pg.get('bookingStart') || pg.get('bookingEnd') ? this.set('priceGroup.bookingDatesDifferent', true) : this.set('priceGroup.bookingDatesDifferent', false);
      } else {
        this.set('priceGroup.bookingDatesDifferent', pg.get('bookingStart') || pg.get('bookingEnd'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setupTickets();
      this.setupVolumePricing();
    },


    ticketTypesObserver: Ember.observer('ticketTypesUpdated', function () {
      this.setupTickets();
      this.send('validateIfInvalid');
    }),

    bookingDatesCheckboxObserver: Ember.observer('priceGroup.bookingDatesDifferent', function () {
      if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
        if (!this.get('priceGroup.bookingDatesDifferent')) {
          this.set('priceGroup.bookingStart', null);
          this.set('priceGroup.bookingEnd', null);
        }
      } else {
        if (!this.get('priceGroup.bookingDatesDifferent') && !this.get('bookingStartSet')) {
          this.set('priceGroup.bookingStart', null);
          this.set('priceGroup.bookingEnd', null);
        }
      }
    }),

    bookingStartSet: Ember.computed('priceGroup.isNew', 'priceGroup.hasDirtyAttributes', function () {
      if (this.get('_bookingStartSet')) {
        return true;
      } else {
        var isNew = this.get('priceGroup.isNew');
        var dirty = this.get('priceGroup.hasDirtyAttributes');
        var bookingStartSet = !!this.get('priceGroup.bookingStart');
        if (bookingStartSet && (!isNew || !dirty)) {
          this.set('_bookingStartSet', true);
          return true;
        } else {
          return false;
        }
      }
    }),

    notNew: Ember.computed('priceGroup.isNew', function () {
      return !this.get('priceGroup.isNew');
    }),

    ticketsAdded: Ember.computed('ticketTypesUpdated', 'priceGroup.prices.length', 'priceGroup.prices.@each.retailPrice', 'isPriceSectionDirty', function () {
      var hasRetailValue = this.get('priceGroup.prices').filter(function (prices) {
        return prices.get('ticketTypeId') !== undefined && prices.get('ticketTypeId') !== null;
      });

      return hasRetailValue.length > 0;
    }),

    notNewOrEditingOld: Ember.computed('priceGroup.isNew', 'editingPgIndex', 'pgIndex', function () {
      return !this.get('priceGroup.isNew') && this.get('editingPgIndex') != this.get('pgIndex');
    }),

    showInventoryDates: Ember.computed('priceGroup.availabilities', function () {
      if (this.get('priceGroup.availabilities.length') > 1) {
        return true;
      } else {
        var availability = this.get('priceGroup.availabilities.firstObject');
        if (availability.get('startDate') && availability.get('endDate')) {
          var priceGroup = this.get('priceGroup');
          return !!(priceGroup.get('travelStart') != availability.get('startDate') || priceGroup.get('travelEnd') != availability.get('endDate'));
        } else {
          return false;
        }
      }
    }),

    hasMultipleAvailabilities: Ember.computed('priceGroup.availabilities.length', function () {
      return this.get('priceGroup.availabilities.length') > 1;
    }),

    availabilityHelptext: Ember.computed('offer', function () {
      var scheduleType = this.get('offer.offerAttributes.scheduleType');
      var capacityTypeLimited = this.get('offer.isLimitedInventory');
      if (scheduleType === 'Start') {
        if (capacityTypeLimited) {
          return 'pages.offer.helptext.availabilityLimitedStartTime';
        } else {
          return 'pages.offer.helptext.availabilityUnlimitedStartTime';
        }
      } else {
        if (capacityTypeLimited) {
          return 'pages.offer.helptext.availabilityLimitedHours';
        } else {
          return 'pages.offer.helptext.availabilityUnlimitedHours';
        }
      }
    }),

    firstValidFromDate: Ember.computed('firstValidFromDate', function () {
      var firstValidDate = moment().add(1, 'days').format('MM/DD/YYYY');
      return firstValidDate;
    }),

    setupTickets: function setupTickets() {
      var _this = this;

      var setupLabels = function setupLabels(ticketTypes) {
        var ticketTypeTranslationKeys = ticketTypes.map(function (ticketType) {
          return ticketType.get('guest_type_id');
        });

        var ticketTypeOptions = _this.setupDropdownOptionLabels('ticket', ticketTypeTranslationKeys);
        var ticketTypeOptionsWithRange = ticketTypeOptions.map(function (label, idx) {
          if (idx !== ticketTypes.get('length')) {
            var ticketType = ticketTypes.objectAt(idx);
            var ticketDetails = ticketType.get('restrictions').filter(function (r) {
              return r.get('restriction_type_id') !== TREAT_AS_ADULT_RESTRICTION_TYPE_ID;
            })[0];
            var ticketId = ticketType.get('id');

            var maxOrNoLimit = ticketDetails.get('max') >= 100 ? 'no limit' : ticketDetails.get('max');
            return { label: label + ' (' + ticketDetails.get('min') + ' - ' + maxOrNoLimit + ')', ticketId: ticketId };
          } else {
            return { label: label, ticketId: 'newTicket' };
          }
        });

        return ticketTypeOptionsWithRange;
      };

      var volumePricing = this.get('isPriceByVolume');
      var ticketTypes = this.get('ticketTypes');

      if (volumePricing && !this.get('activity.migrationStatus.ticketType')) {
        ticketTypes = ticketTypes.filter(function (ticketType) {
          return ticketType.get('guest_type_id') === 1;
        });
      }

      var ticketTypeOptionsWithRange = setupLabels(ticketTypes);

      this.set('ticketTypeOptions', ticketTypeOptionsWithRange.map(function (ticket) {
        return ticket.label;
      }));
      this.set('ticketTypeValues', ticketTypeOptionsWithRange.map(function (ticket) {
        return ticket.ticketId;
      }));
    },
    setupVolumePricing: function setupVolumePricing() {
      var priceTypeValues = ['PerTraveler', 'PerGroup'];
      this.set('priceTypeOptions', this.setupDropdownOptionLabels('price', priceTypeValues));
      this.set('priceTypeValues', priceTypeValues);

      var minMaxArray = (0, _arrayValues.getArrayOfValues)(8);
      this.set('groupSizeOptions', minMaxArray);
      this.set('groupSizeValues', minMaxArray);

      if (this.get('isPriceByVolume')) {
        var firstTicket = this.get('priceGroup').get('prices').objectAt(0);
        if (firstTicket.get('ticketTypeId') && firstTicket.get('groupPricePlanType')) {
          this.set('ticketTypeSet', true);
        } else {
          this.set('ticketTypeSet', false);
        }
      }
    },

    filteredArrayLength: Ember.computed('priceGroup.prices.@each.operation', function () {
      return this.get('priceGroup.prices').filter(function (price) {
        return price.get('operation') !== 'delete';
      }).length;
    }),

    actions: {
      addTicket: function addTicket() {
        var volumeTicketTypeId = void 0;
        var groupPricePlanType = void 0;
        var groupTicketTypeId = void 0;
        if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
          this.set('ticketAddedToPg', true);
        }
        if (this.get('isPriceByVolume')) {
          var priceGroup = this.get('priceGroup');
          volumeTicketTypeId = priceGroup.get('prices').objectAt(0).get('ticketTypeId');

          groupPricePlanType = priceGroup.get('prices').objectAt(0).get('groupPricePlanType');

          groupTicketTypeId = priceGroup.get('prices').objectAt(0).get('groupTicketTypeId');
        }
        this.sendAction('addPrices', this.get('priceGroup'), volumeTicketTypeId, groupPricePlanType, groupTicketTypeId);
      },
      updateVolumeBasedTicketIds: function updateVolumeBasedTicketIds(volumeTicketTypeId) {
        if (this.get('isPriceByVolume')) {
          var priceGroup = this.get('priceGroup');
          priceGroup.get('prices').forEach(function (price) {
            price.set('ticketTypeId', volumeTicketTypeId);
          });
        }
      },
      priceVaryByBookingDate: function priceVaryByBookingDate() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.VaryBookDateCheckbox');
        if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
          this.sendAction('validationFunction', this.get('priceGroup'), 'setDirty');
        }
      },
      validateIfInvalid: function validateIfInvalid() {
        this.sendAction('validationFunction', this.get('priceGroup'), 'setDirty');
      },
      updateTravelToDate: function updateTravelToDate(date) {
        this.get('priceGroup').set('travelEnd', date);
        this.sendAction('validationFunction', this.get('priceGroup'), 'setDirty');
      },
      updateTravelFromDate: function updateTravelFromDate(date) {
        this.get('priceGroup').set('travelStart', date);
        this.sendAction('validationFunction', this.get('priceGroup'), 'setDirty');
      },
      updateBookingToDate: function updateBookingToDate(date) {
        this.get('priceGroup').set('bookingEnd', date);
        this.sendAction('validationFunction', this.get('priceGroup'), 'setDirty');
      },
      updateBookingFromDate: function updateBookingFromDate(date) {
        this.get('priceGroup').set('bookingStart', date);
        this.sendAction('validationFunction', this.get('priceGroup'), 'setDirty');
      },
      savePriceGroup: function savePriceGroup() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.SavePriceGroupButton');
        var priceGroup = this.get('priceGroup');

        if (!this.get('priceGroup.bookingDatesDifferent')) {
          priceGroup.set('bookingStart', null);
          priceGroup.set('bookingEnd', null);
        }
        this.sendAction('savePriceGroup', priceGroup, this.get('pgIndex'));
      },
      editPriceGroup: function editPriceGroup(pgId) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.EditPriceGroup');
        if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
          this.set('ticketAddedToPg', false);
          this.set('priceGroup.priceUpdateAlertVisible', false);
        }
        this.sendAction('editPriceGroup', pgId, this.get('priceGroup'));
      },
      cancelEditing: function cancelEditing() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.CancelPriceGroup');
        this.get('responseErrors').clearErrors();
        var isNew = this.get('priceGroup.isNew');

        if (isNew) {
          this.get('priceGroup').destroyRecord();
        } else {
          this.get('priceGroup').rollbackAttributes();

          //to rollback change in Prices
          if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
            var priceArr = this.get('priceGroup').get('prices');
            priceArr.forEach(function (item) {
              if (item !== null) {
                item.rollbackAttributes();
              }
            });
          }
        }
        this.sendAction('cancelEditingPg');
      },
      deactivate: function deactivate() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.DeactivatePriceGroup');
        var pg = this.get('priceGroup');
        var pgPriceIds = pg.get('prices').map(function (price) {
          var id = parseInt(price.get('id'));
          if (id) {
            return id;
          }
        });
        this.sendAction('deactivate', pg, pgPriceIds.compact());
      },
      clonePg: function clonePg() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.ClonePriceGroup');
        this.sendAction('clonePg', this.get('priceGroup'));
      },
      setTicketTypeSet: function setTicketTypeSet(val) {
        this.set('ticketTypeSet', val);
      },
      priceUpdateAlertClose: function priceUpdateAlertClose() {
        this.set('ticketAddedToPg', false);
        this.set('priceGroup.priceUpdateAlertVisible', false);
      }
    },

    setupDropdownOptionLabels: function setupDropdownOptionLabels(labelsFor, labels) {
      var _this2 = this;

      return labels.map(function (type) {
        return _this2.get('i18n').t('pages.offer.' + labelsFor + 'Types.' + type).string;
      });
    }
  });
});