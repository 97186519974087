define('lxso/adapters/ticket-types-v4', ['exports', 'lxso/adapters/application', 'lxso/constants/common'], function (exports, _application, _common) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajaxWrapper: Ember.inject.service(),

    findRecord: function findRecord(store, type, id) {
      var url = this.hostV4 + '/activity/' + id + '/ticket-types';
      return this.ajax(url, 'GET');
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this = this;

      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var updateUrl = this.hostV4 + '/ticket-types';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.ajax(updateUrl, 'PUT', { data: data }).then(function (ticketTypeUpdateResponse) {
          setTimeout(function () {
            var getUrl = _this.hostV4 + '/activity/' + data.activityId + '/ticket-types';
            _this.ajax(getUrl, 'GET').then(function (ticketTypeGetResponse) {
              resolve(ticketTypeGetResponse);
            }).catch(function (ticketTypeGetError) {
              reject(ticketTypeGetError);
            });
          }, _common.TIME_DELAY_MS_FOR_FETCH_AFTER_UPDATE);
        }).catch(function (ticketTypeUpdateError) {
          return reject(ticketTypeUpdateError);
        });
      });
    }
  });
});