define('lxso/routes/booking-questions-list', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-supplier-branch', 'lxso/mixins/feature-flag', 'lxso/utils/omniture', 'lxso/mixins/example-modal'], function (exports, _authenticatedRouteMixin, _requireSupplierBranch, _featureFlag, _omniture, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, _featureFlag.default, _exampleModal.default, {
    featureName: 'BookingQuestions',
    requiredPermission: 'BookingQuestionTemplateRW',
    i18n: Ember.inject.service(),
    permissions: Ember.inject.service(),
    activityMigrateSupport: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.Booking-Questions-Set'
    },

    model: function model(params) {
      var _this = this;

      var branchId = params.branchId;
      var isAllOffersMigratedToV4 = this.get('activityMigrateSupport').fetchOfferMigrateStatus(branchId);
      return Ember.RSVP.hash({
        isAllOffersMigratedToV4: isAllOffersMigratedToV4,
        bookingQuestionsList: isAllOffersMigratedToV4.then(function (status) {
          if (status) {
            return _this.store.query('booking-questions-v4', { branchId: branchId }, { reload: true });
          } else {
            return _this.store.query('booking-questions', { branchId: branchId });
          }
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        isAllOffersMigratedToV4: model.isAllOffersMigratedToV4,
        bookingQuestionsList: model.bookingQuestionsList,
        productsAndOptionGroupList: model.productsAndOptionGroupList,
        bookingQuestionSet: '',
        hideServerErrors: true,
        bookingQuestionsTableHeaderbaseKey: 'pages.bookingQuestions.list.table.',
        itemsPerPage: 10
      });

      if (model.isAllOffersMigratedToV4) {
        controller.setProperties({
          bookingQuestionsTableHeaderKeys: Ember.A(['bookingQuestionTemplateId', 'name', 'bookingQuestionDetails', 'bookingQuestionOfferGroupDetailsMap', 'update']),
          bookingQuestionsTableKeys: Ember.A(['bookingQuestionTemplateId', 'name', 'bookingQuestionDetails', 'bookingQuestionOfferGroupDetailsMap']),
          bqTableActionMap: Ember.A({
            bookingQuestionDetails: 'onBookingQuestionsClick',
            bookingQuestionOfferGroupDetailsMap: 'onBqOfferDetailsClick'
          })
        });
      } else {
        controller.setProperties({
          bookingQuestionsTableHeaderKeys: Ember.A(['bookingQuestionTemplateId', 'name', 'bookingQuestionDetails', 'bookingQuestionOfferDetailsMap', 'update']),
          bookingQuestionsTableKeys: Ember.A(['bookingQuestionTemplateId', 'name', 'bookingQuestionDetails', 'bookingQuestionOfferDetailsMap']),
          bqTableActionMap: Ember.A({
            bookingQuestionDetails: 'onBookingQuestionsClick',
            bookingQuestionOfferDetailsMap: 'onBqOfferDetailsClick'
          })
        });
      }
    },
    resetController: function resetController() {
      this.controller.set('bookingQuestionSet', '');
      this.controller.set('bookingQuestionsList', []);
    },


    actions: {
      addQuestions: function addQuestions() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.create.Booking-Questions-Set');
        if (this.controller.get('isAllOffersMigratedToV4')) {
          this.transitionTo('booking-questions-v4', 'new');
        } else {
          this.transitionTo('booking-questions', 'new');
        }
      },
      onBookingQuestionsClick: function onBookingQuestionsClick(item) {
        this.controller.set('bookingQuestionsDetailModal', item);
      },
      cancelSelectedBqModal: function cancelSelectedBqModal() {
        this.controller.set('bookingQuestionsDetailModal', false);
      },
      onBqOfferDetailsClick: function onBqOfferDetailsClick(item) {
        this.controller.set('bqOfferDetailsModal', item);
      },
      cancelOfferDetailsBqModal: function cancelOfferDetailsBqModal() {
        this.controller.set('bqOfferDetailsModal', false);
      },
      editRow: function editRow(item) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.edit.Booking-Questions-Set');
        if (this.controller.get('isAllOffersMigratedToV4')) {
          this.transitionTo('booking-questions-v4', item.id, { queryParams: { clone: null } });
        } else {
          this.transitionTo('booking-questions', item.id, { queryParams: { clone: null } });
        }
      },
      deactivateRow: function deactivateRow(item) {
        this.controller.set('bookingQuestionSet', item);
        this.controller.set('isDeactivateModalOpen', true);
      },
      cancelDeactivate: function cancelDeactivate() {
        this.controller.set('isDeactivateModalOpen', false);
        this.controller.set('bookingQuestionSet', '');
        this.controller.set('hideServerErrors', true);
      },
      deactivateSubmit: function deactivateSubmit() {
        var _this2 = this;

        this.controller.set('hideServerErrors', true);
        var bookingQuestionSet = this.controller.get('bookingQuestionSet');
        bookingQuestionSet.destroyRecord().then(function (saveResponse) {
          _this2.send('cancelDeactivate');
          var BQList = _this2.controller.get('bookingQuestionsList');
          var updatedBQList = BQList.filter(function (bqset) {
            return bqset.id != bookingQuestionSet.id;
          });
          _this2.controller.set('bookingQuestionsList', updatedBQList);
          _this2.controller.set('bookingQuestionSet', '');
          _this2.controller.set('hideServerErrors', true);
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.delete.Booking-Questions-Set');
        }).catch(function (err) {
          _this2.controller.set('hideServerErrors', false);
          bookingQuestionSet.rollbackAttributes();
          _this2.get('notifications').error(_this2.get('i18n').t('components.alert.saveFailure'));
        });
      },
      cloneRow: function cloneRow(item) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.clone.Booking-Questions-Set');
        if (this.controller.get('isAllOffersMigratedToV4')) {
          this.transitionTo('booking-questions-v4', item.id, { queryParams: { clone: true } });
        } else {
          this.transitionTo('booking-questions', item.id, { queryParams: { clone: true } });
        }
      }
    }
  });
});