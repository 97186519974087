define('lxso/helpers/is-gt-supplier-branch-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    permissions: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    session: Ember.inject.service(),
    compute: function compute() {
      var isExternalUser = !this.get('permissions').checkEnabled('V3InternalSettings');
      var isGTBranch = this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
      return isExternalUser && isGTBranch;
    }
  });
});