define('lxso/adapters/health-and-safety', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _application.default.extend({

    namespace: 'api/v3',

    findRecord: function findRecord(store, type, id) {
      var url = this.host + '/' + this.namespace + '/activity/' + id + '/healthAndSafety';
      return this.ajax(url, 'GET');
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);
      var activityID = parseInt(snapshot.id);
      var url = this.host + '/' + this.namespace + '/activity/' + activityID + '/healthAndSafety';

      var healthAndSafetySelected = {
        qid: 0,
        answer: data.healthAndSafetySelected,
        additionalOption: []
      };
      var healthAndSafetyQuestions = [];
      if (data.healthAndSafetySelected == 'Yes') {
        healthAndSafetyQuestions = [].concat(_toConsumableArray(data.enhancedCleaningQuestions), _toConsumableArray(data.travelerSafetyQuestions), _toConsumableArray(data.covidStatusQuestions));
      }
      healthAndSafetyQuestions = this.adaptAdditionalOptions(healthAndSafetyQuestions);

      var healthAndSafetyInfo = {
        activityID: activityID,
        healthAndSafetySelected: healthAndSafetySelected,
        healthAndSafetyQuestions: healthAndSafetyQuestions
      };
      if (data.isNewHealthAndSafetyInfo) {
        return this.ajax(url, 'POST', { data: healthAndSafetyInfo });
      } else {
        return this.ajax(url, 'PUT', { data: healthAndSafetyInfo });
      }
    },
    adaptAdditionalOptions: function adaptAdditionalOptions(healthAndSafetyQuestions) {
      var _this = this;

      var newHealthAndSafetyQuestions = healthAndSafetyQuestions.map(function (q) {
        var additionalOption = {};
        if (q.answer === 'Yes' && q.additionalOption && q.additionalOption.length) {
          if (q.qid == 18) {
            return _this.buildCovidStatusAdditionOption(q);
          } else if (q.qid == 12) {
            var vacantTimeArray = q.additionalOption[0].split(',');
            additionalOption = {
              vacantTimeRangeStart: parseInt(vacantTimeArray[0]),
              vacantTimeRangeEnd: parseInt(vacantTimeArray[1])
            };
          } else {
            additionalOption = {
              gloves: q.additionalOption.includes('Gloves'),
              masks: q.additionalOption.includes('Masks')
            };
          }
        }
        q.additionalOption = additionalOption;
        return q;
      });
      return newHealthAndSafetyQuestions;
    },
    buildCovidStatusAdditionOption: function buildCovidStatusAdditionOption(question) {
      return { qid: question.qid,
        answer: question.answer,
        additionalOption: question.covidStatusOption };
    }
  });
});