define('lxso/validators/base-price-is-number', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /*
   The base price must be a number.
  */

  var basePriceIsNumber = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      'use strict';

      var newPrices = value;
      var errorIndexes = [];
      var errorString = void 0;

      for (var i = 0; i < newPrices.length; i++) {
        if (typeof newPrices[i].base != 'number' || newPrices[i].base < 0) {
          var result = this.createErrorMessage('basePriceNotNumber', value, options);
          errorIndexes.push(i);
          errorString = result;
        }
      }

      return errorString ? errorIndexes + ':;:' + errorString : true;
    }
  });

  exports.default = basePriceIsNumber;
});