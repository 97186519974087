define('lxso/components/validation-list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    click: function click(e) {
      e.preventDefault();
      var keyId = '#li-' + this.get('key') + '-error-0';
      Ember.$('html, body').animate({
        scrollTop: Ember.$(keyId).offset().top
      }, 500);
    }
  });
});