define('lxso/utils/change-history-details-display-modes/list-content', ['exports', 'lxso/utils/change-history-details-display-modes/list'], function (exports, _list) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'list-with-label',
    diff: function diff(oldValue, newValue) {
      'use strict';

      var delimiter = '||';
      return {
        displayMode: _list.default,
        oldValue: oldValue.split(delimiter),
        newValue: newValue.split(delimiter)
      };
    }
  };
});