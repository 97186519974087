define('lxso/helpers/is-not-last-index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isNotLastIndex = isNotLastIndex;
  function isNotLastIndex(params) {
    return params[0] < params[1].length - 1;
  }

  exports.default = Ember.Helper.helper(isNotLastIndex);
});