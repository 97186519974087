define('lxso/validators/option-group-validity-days', ['exports', 'ember-cp-validations/validators/base', 'lxso/constants/option-group'], function (exports, _base, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OptionGroupValidityDaysValidation = _base.default.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    staticData: Ember.inject.service(),

    validate: function validate(value, options, model, attribute) {
      var durationDaysPart = parseInt(model.get('durationDaysPart'));
      var validityDays = parseInt(model.get('validityDays'));
      var scheduleType = model.get('scheduleType');
      var selectedBranchPlatformId = this.get('session.session.authenticated.selectedBranch.platform_id');
      var usageDaysSupportedPlatformIds = this.get('staticData').getUsageDaysSupportedPlatformIds();
      var isUsageDaysSupported = isFinite(selectedBranchPlatformId) && usageDaysSupportedPlatformIds.includes(selectedBranchPlatformId);
      if (isUsageDaysSupported && scheduleType === _optionGroup.SCHEDULE_TYPES.VALID_DAYS) {
        if (isNaN(validityDays)) {
          return this.get('i18n').t('pages.optionGroup.errorMessages.invalidDaysInput').string;
        } else if (isFinite(durationDaysPart) && durationDaysPart > validityDays) {
          return this.get('i18n').t('pages.optionGroup.errorMessages.validityDays').string;
        } else if (validityDays > 90) {
          return this.get('i18n').t('pages.optionGroup.errorMessages.validityDaysLessThan90').string;
        }
      }
      return true;
    }
  });

  exports.default = OptionGroupValidityDaysValidation;
});