define('lxso/mixins/publish-api', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    i18n: Ember.inject.service(),
    featureFlags: Ember.inject.service(),

    publishChanges: function publishChanges(activity, hideNotification) {
      var _this = this;

      var activityId = activity.id;
      var v3Url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/activities/' + activityId + '/publish?isContentCertified=true';
      var v3Publish = this.get('ajaxWrapper').ajaxPut(v3Url);
      var migrationStatus = activity.get('migrationStatus');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        v3Publish.then(function () {
          var isMigrateOverrideEnabled = _this.get('featureFlags').checkEnabled('V4ContentAndLogisticsMigrationOverride');
          if (migrationStatus && (migrationStatus.get('content') && migrationStatus.get('logistics') || migrationStatus.get('offer')) || isMigrateOverrideEnabled) {
            var v4Url = _environment.default.api.hostV4 + '/supply/' + activityId + '/publish';
            return _this.get('ajaxWrapper').ajaxPut(v4Url);
          } else {
            return;
          }
        }).then(function () {
          _this.controller.notifyPropertyChange('activity');
          if (!hideNotification) {
            _this.get('notifications').success(_this.get('i18n').t('components.alert.publishSuccess'));
          }
          resolve();
        }).catch(function () {
          if (!hideNotification) {
            _this.get('notifications').error(_this.get('i18n').t('components.alert.publishFailed'));
          }
          _this.setDirtyAttribute();
          reject();
        });
      });
    },
    setDirtyAttribute: function setDirtyAttribute() {
      this.controller.set('dirty', true);
    }
  });
});