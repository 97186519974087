define('lxso/adapters/option-group-ticket-type-restrictions', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajaxWrapper: Ember.inject.service(),

    findRecord: function findRecord(store, type, id) {
      var _this = this;

      var url = this.hostV4 + '/offer-group/' + id + '/ticket-type-restrictions';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.ajax(url, 'GET').then(function (response) {
          if (response) {
            resolve(response);
          } else {
            resolve({
              offerGroupId: id,
              restrictions: []
            });
          }
        });
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var updateUrl = this.hostV4 + '/offer-group/' + data.offerGroupId + '/ticket-type-restrictions';
      return this.ajax(updateUrl, 'PUT', { data: data });
    }
  });
});