define('lxso/controllers/photo-audit', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /*The audit page has a lot in common with the Reports page, in terms of functionality*/
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    store: Ember.inject.service('store'),
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      loadAssetsBySupplierBranchOrActivityId: function loadAssetsBySupplierBranchOrActivityId() {
        var _this = this;

        this.set('imagesLoading', true);
        this.set('searchButtonClicked', true);
        this.set('userTag', '');
        this.set('userStatus', '');
        var branchAssets = [];
        var url = void 0;
        var data = null;
        if (this.get('activityId')) {
          // call activity api
          var activityId = this.get('activityId');
          var domain = _environment.default.api.host + '/api/v3/media/' + activityId + '/images';
          var param = 'activeStatus=true';
          url = domain + '?' + param;
        } else if (this.get('supplierBranchId')) {
          // call get by supplier branch api
          var supplierBranchId = this.get('supplierBranchId');
          var _domain = _environment.default.api.host + '/api/v3/media/' + supplierBranchId + '/imagesBySupplierBranch';
          var _param = 'activeStatus=true';
          url = _domain + '?' + _param;
        } else {
          this.set('searchButtonClicked', false);
          return;
        }
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('ajaxWrapper').ajaxGet(url, data).then(function (results) {
            branchAssets = _this.get('activityId') ? results.assets : results;
            _this.set('branchAssets', branchAssets);
            _this.set('filteredAssets', branchAssets);
            _this.send('setTagsDropdown');
            _this.set('imagesLoading', false);
          }).catch(function (reason) {
            return reject(reason);
          });
        });
      },
      setTagsDropdown: function setTagsDropdown() {
        var assets = this.get('filteredAssets');
        var tags = [];
        assets.forEach(function (asset) {
          tags = tags.concat(asset.tags);
        });
        var uniqueTags = Array.from(new Set(tags));
        this.set('tagsDropdownOptions', [this.get('i18n').t('pages.audit.tagsDropdownOptions.all')].concat(uniqueTags));
        this.set('tagsDropdownValues', [''].concat(uniqueTags));
      }
    }
  });
});