define('lxso/components/nested-side-menu-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isNestedContentsDisplayed: Ember.computed('currentRoute', function () {
      var currentRoute = this.get('currentRoute');
      var innerRoutes = this.get('innerRoutes');
      if (innerRoutes != undefined) {
        return innerRoutes.contains(currentRoute);
      }
    }),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentRoute', this.get('router.currentRouteName'));
    },

    routeObserver: Ember.observer('router.currentRouteName', function () {
      this.set('currentRoute', this.get('router.currentRouteName'));
    }),
    actions: {
      toggleNestedMenu: function toggleNestedMenu() {
        this.toggleProperty('isNestedContentsDisplayed');
      }
    }
  });
});