define('lxso/models/change-history', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    i18n: Ember.inject.service('i18n'),

    supplierBranchId: _emberData.default.attr('number'),
    changeType: _emberData.default.attr('string'),
    product: (0, _attributes.fragment)('changeHistory/product'),
    offer: (0, _attributes.fragment)('changeHistory/offer'),
    offerGroupId: _emberData.default.attr('number'),
    changeDetail: (0, _attributes.fragment)('changeHistory/changeDetail'),
    occurredAt: _emberData.default.attr('moment-datetime'),
    madeBy: _emberData.default.attr('string'),

    descriptionTranslationKey: Ember.computed('changeType', 'changeDetail.attribute', function () {
      var changeType = this.get('changeType').toLowerCase();
      var attribute = this.get('changeDetail.attribute');
      return 'pages.changeHistory.text.description.' + attribute + '.' + changeType;
    }),

    description: Ember.computed('i18n', 'descriptionTranslationKey', 'changeDetail.title', function () {
      var changeTitle = Ember.String.htmlSafe(this.get('changeDetail.title'));
      var translatedDescription = this.get('i18n').t(this.get('descriptionTranslationKey'), { changeTitle: changeTitle });
      return translatedDescription.toString();
    }),

    dropdownDescriptionTranslationKey: Ember.computed('changeDetail.attribute', function () {
      var attribute = this.get('changeDetail.attribute');
      return 'pages.changeHistory.text.dropdownDescription.' + attribute;
    }),

    dropdownDescription: Ember.computed('i18n', 'dropdownDescriptionTranslationKey', function () {
      var translatedDescription = this.get('i18n').t(this.get('dropdownDescriptionTranslationKey'));
      return translatedDescription.toString();
    })
  });
});