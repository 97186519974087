define('lxso/components/option-group/days-and-availability/component', ['exports', 'lxso/mixins/example-modal', 'lxso/constants/option-group'], function (exports, _exampleModal, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_exampleModal.default, {
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    showAvailabilityTypeSwitchModal: false,
    isAllAvailabilityByOffersChecked: false,
    SCHEDULE_TYPES: _optionGroup.SCHEDULE_TYPES,
    START_TIME_BASED_SCHEDULES: _optionGroup.START_TIME_BASED_SCHEDULES,
    OPERATING_HOURS_BASED_SCHEDULES: _optionGroup.OPERATING_HOURS_BASED_SCHEDULES,
    AVAILABILITY_TYPES: _optionGroup.AVAILABILITY_TYPES,

    init: function init() {
      this._super.apply(this, arguments);
      this.initAvailabilityTable();
    },
    initAvailabilityTable: function initAvailabilityTable() {
      var daysEnum = _optionGroup.AVAILABILITY_DAYS_ARRAY.map(function (day) {
        return {
          id: day,
          label: _optionGroup.AVAILABILITY_DAYS_ABBR[day]
        };
      });
      this.set('availabilityByDaysEnum', daysEnum);
    },
    setIsAllAvailabilityByOffersCheckedFlag: function setIsAllAvailabilityByOffersCheckedFlag() {
      this.set('isAllAvailabilityByOffersChecked', this.get('priceGroup.availabilityByOffers').every(function (availabilityByOffer) {
        return availabilityByOffer.get('isSelected');
      }));
    },

    actions: {
      validateIfInvalid: function validateIfInvalid() {
        this.sendAction('validateIfInvalid');
      },
      handleAvailabilityConfigTypeChange: function handleAvailabilityConfigTypeChange(availabilityConfigType) {
        this.sendAction('handleAvailabilityConfigTypeChange', this.get('priceGroup'), availabilityConfigType);
      },
      handleCapacityChange: function handleCapacityChange(availabilityDay, event) {
        availabilityDay.set('available', availabilityDay.get('capacity') > 0);
        availabilityDay.set('capacity', parseInt(event.target.value) || 0);
        this.sendAction('validateIfInvalid');
      },
      handleAvailableFlagChange: function handleAvailableFlagChange(availabilityDay, event) {
        this.sendAction('validateIfInvalid');
      },
      handleOpeningTimeUpdate: function handleOpeningTimeUpdate(availabilityDay, formattedTime) {
        this.sendAction('validateIfInvalid');
      },
      handleClosingTimeUpdate: function handleClosingTimeUpdate(availabilityDay, formattedTime) {
        this.sendAction('validateIfInvalid');
      },
      handleSelectAllAvailabilityByOffer: function handleSelectAllAvailabilityByOffer(isAllAvailabilityByOffersChecked) {
        this.get('priceGroup.availabilityByOffers').forEach(function (availabilityByOffer) {
          availabilityByOffer.set('isSelected', isAllAvailabilityByOffersChecked);
        });
      },
      handleSelectAvailabilityByOffer: function handleSelectAvailabilityByOffer(availabilityByOfferRow) {
        this.setIsAllAvailabilityByOffersCheckedFlag();
        if (!this.get('isAvailabilityByDays')) {
          availabilityByOfferRow.set('available', availabilityByOfferRow.get('isSelected'));
        }
      },
      selectMultipleAvailabilityDaysForOfferRow: function selectMultipleAvailabilityDaysForOfferRow(availabilityByOfferRow, selectedDay) {
        var existingDays = availabilityByOfferRow.get('days');
        var newDaysArray = new Ember.A(existingDays.slice());
        var index = newDaysArray.indexOf(selectedDay);
        if (index >= 0) {
          newDaysArray.splice(index, 1);
        } else {
          newDaysArray.pushObject(selectedDay);
        }
        availabilityByOfferRow.set('days', newDaysArray);
        this.get('priceGroup').notifyPropertyChange('availabilityByOffers');
        this.sendAction('validateIfInvalid');
      },
      handleCapacityByOfferRowChange: function handleCapacityByOfferRowChange(availabilityDay, event) {
        availabilityDay.set('capacity', parseInt(event.target.value) || 0);
      },
      handleAvailabilityFlagByOfferRowChange: function handleAvailabilityFlagByOfferRowChange() {},
      handleCopyAvailabilityByOfferRow: function handleCopyAvailabilityByOfferRow(availabilityRow) {
        var insertPosition = this.get('priceGroup.availabilityByOffers').indexOf(availabilityRow) + 1;
        this.get('priceGroup.availabilityByOffers').insertAt(insertPosition, this.getAvailabilityByOfferRowCopy(availabilityRow));
        this.get('priceGroup').notifyPropertyChange('availabilityByOffers'); //to update delete buttons in avail rows.
      },
      handleDeleteAvailabilityByOfferRow: function handleDeleteAvailabilityByOfferRow(availabilityRow) {
        this.get('priceGroup.availabilityByOffers').removeFragment(availabilityRow);
        this.get('priceGroup').notifyPropertyChange('availabilityByOffers'); //to update delete buttons in avail rows.
      }
    },
    getAvailabilityByOfferRowCopy: function getAvailabilityByOfferRowCopy(availabilityRow) {
      return {
        isSelected: availabilityRow.get('isSelected'),
        available: availabilityRow.get('available'),
        days: Ember.A([]),
        offerId: availabilityRow.get('offerId'),
        capacity: availabilityRow.get('capacity'),
        startTime: availabilityRow.get('startTime'),
        endTime: availabilityRow.get('endTime')
      };
    }
  });
});