define('lxso/models/supplier-branch/margin-overrides', ['exports', 'ember-data', 'lxso/utils/validation'], function (exports, _emberData, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    product_margin: [(0, _validation.validator)('number', {
      allowBlank: true,
      allowString: true,
      gte: 0,
      messageKey: 'errors.customMessages.supplierBranch.marginTooSmall'
    }), (0, _validation.validator)('number', {
      allowBlank: true,
      allowString: true,
      gt: 0,
      messageKey: 'errors.customMessages.supplierBranch.marginIsZero'
    }), (0, _validation.validator)('number', {
      allowBlank: true,
      allowString: true,
      lte: 100,
      messageKey: 'errors.customMessages.supplierBranch.marginTooBig'
    }), (0, _validation.validator)('format', {
      allowBlank: true,
      regex: /^\d+(\.\d{1,2})?$/,
      messageKey: 'errors.customMessages.supplierBranch.marginWrongFormat'
    })],

    ticket_type_margins: [(0, _validation.validator)('ticket-type-margin'), (0, _validation.validator)('has-many')],
    offer_margins: [(0, _validation.validator)('offer-margin'), (0, _validation.validator)('has-many')],
    offer_group_margins: [(0, _validation.validator)('offer-group-margin'), (0, _validation.validator)('has-many')]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    featureFlags: Ember.inject.service(),
    i18n: Ember.inject.service(),
    activityDetails: _emberData.default.belongsTo('activity', { async: true }),
    activity_id: _emberData.default.attr('number'),
    product_margin: _emberData.default.attr('string'),
    ticket_type_margins: _emberData.default.hasMany('supplier-branch/ticket-type-margin', { async: false }),
    offer_margins: _emberData.default.hasMany('supplier-branch/offer-margin', { async: false }),
    update_existing_prices: _emberData.default.attr('boolean', { defaultValue: false }),
    offer_group_margins: _emberData.default.hasMany('supplier-branch/offer-group-margin', { async: false }, { defaultValue: [] }),
    offerRowsCount: Ember.computed('offer_margins', 'offer_group_margins', function () {
      return new Array(this.get('offer_margins').length + (this.get('featureFlags').checkEnabled('MultipleOptionsGroup') ? this.get('offer_group_margins').length : 0));
    })
  });
});