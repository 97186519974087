define('lxso/helpers/strip-parens-from-ticket-type-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.stripParensFromTicketTypeName = stripParensFromTicketTypeName;

  //we persist ticket type names like this: Adult (12-55)
  //but sometimes want just want to display Adult
  //this method finds the first ( and removes it and everything after it up to the closing )
  function stripParensFromTicketTypeName(params /*, hash*/) {
    return params[0].replace(/\(.*\)/, '');
  }

  exports.default = Ember.Helper.helper(stripParensFromTicketTypeName);
});