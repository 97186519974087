define('lxso/models/fragments/option-group/language-info', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation', 'lxso/constants/language-type-ids'], function (exports, _emberData, _emberDataModelFragments, _validation, _languageTypeIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    languageTypeId: {
      descriptionKey: 'pages.offer.labels.languageType',
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('number', {
        gte: 1,
        allowString: true,
        messageKey: 'errors.customMessages.offers.noSelectionMade'
      })]
    },
    languageIds: {
      descriptionKey: 'pages.offer.labels.languageType',
      validators: [(0, _validation.validator)('array-length', {
        dependantKeys: ['model.languageTypeId'],
        gte: 1,
        disabled: Ember.computed('model.languageTypeId', 'model.languageIds', function () {
          return this.get('model.languageTypeId') === _languageTypeIds.LANG_TYPE_IDS.NO_COMMENTARY;
        }),
        messageKey: 'errors.customMessages.offerGroup.minOneLanguage',
        volatile: true
      })]
    }
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    languageTypeId: _emberData.default.attr('number'),
    languageIds: _emberData.default.attr('array')
  });
});