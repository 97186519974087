define('lxso/routes/report', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lxso/config/environment'], function (exports, _authenticatedRouteMixin, _featureFlag, _requirePermission, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FILTERABLE_REPORT_PROPERTIES = ['SupplierReferenceNumber', 'Activitytitle', 'LeadTraveler', 'Offertitle', 'PriceCode', 'Tickettype', 'Vouchernumber', 'Supplierbranchid'];

  exports.default = Ember.Route.extend(_requirePermission.default, _authenticatedRouteMixin.default, {
    omniture: { pageName: 'page.LX-Supply.Reports' },
    requiredPermission: 'ReportsTabView',
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    init: function init() {
      this._super.apply(this, arguments);
      var queryParams = {
        //Selected search
        selectedSearchType: { refreshModel: false },

        // Search by supplierReferenceNumber
        supplierReferenceNumber: { refreshModel: false },

        // Search by dateRange
        searchDateType: { refreshModel: false },
        from: { refreshModel: false },
        to: { refreshModel: false },

        // Sort
        sortBy: { refreshModel: false },
        order: { refreshModel: false },

        // Filters
        status: { refreshModel: false }
      };
      FILTERABLE_REPORT_PROPERTIES.forEach(function (property) {
        queryParams[property] = { refreshModel: false };
      });
      this.set('queryParams', queryParams);
      this.set('FILTERABLE_REPORT_PROPERTIES', FILTERABLE_REPORT_PROPERTIES);
    },
    model: function model(params) {
      var selectedSupplierBranch = this.get('session.session.authenticated.selectedBranch');
      var branchesHavingUserAccess = void 0;
      var supplierBranches = void 0;
      if (selectedSupplierBranch.thirdPartySupply) {
        branchesHavingUserAccess = { supplier_branch_id_list: [-1] };
        supplierBranches = [];
      } else {
        var branchesHavingAccessURL = _environment.default.api.host + '/' + _environment.default.api.namespace + '/userManagement/supplier_branch_ids';
        supplierBranches = this.store.findRecord('suppliers', params.supplierId);
        branchesHavingUserAccess = this.get('ajaxWrapper').ajaxGet(branchesHavingAccessURL, null, {
          cache: false,
          xhrFields: {
            withCredentials: true
          }
        }).then(function (res) {
          return res;
        }).catch(function (err) {
          if (err.status === 300) {
            return { supplier_branch_id_list: [-1] };
          }
          return err;
        });
      }

      return new Ember.RSVP.hash({
        supplierBranches: supplierBranches,
        params: params,
        branchesHavingUserAccess: branchesHavingUserAccess
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('route', this);
      this._super(controller, model.params);
      var isThirdParty = this.get('session.session.authenticated.selectedBranch.thirdPartySupply');
      controller.set('isThirdParty', isThirdParty ? true : false);
      var supplierBranches = this.getSupplierBranches(model, isThirdParty);

      controller.set('supplierBranchNames', supplierBranches.map(function (branch) {
        return branch.supplier_branch_name;
      }));
      controller.set('supplierBranchValues', supplierBranches.map(function (branch) {
        return branch.supplier_branch_id;
      }));
      Ember.run.scheduleOnce('afterRender', function () {
        controller.setSearchDateType();
        controller.setSearchType();
        controller.setSortBy();
        controller.setStatusRadioButton();
        controller.setFilterablePropertyFromRoute();
        controller.setup();
      });
    },
    getSupplierBranches: function getSupplierBranches(model, isThirdParty) {
      var selectedBranch = this.get('session.session.authenticated.selectedBranch');
      var supplierBranches = void 0;
      if (isThirdParty) {
        supplierBranches = [selectedBranch];
      } else {
        var branchesHavingUserAccess = model.branchesHavingUserAccess && model.branchesHavingUserAccess.supplier_branch_id_list ? model.branchesHavingUserAccess.supplier_branch_id_list : [];

        supplierBranches = model.supplierBranches.get('branches').filter(function (item) {
          /*Meaning super user having access to all branches*/
          if (branchesHavingUserAccess.indexOf(-1) >= 0) {
            return item.is_active;
          } else {
            var branchesList = branchesHavingUserAccess && branchesHavingUserAccess.length ? branchesHavingUserAccess : [];
            return item.is_active && branchesList.indexOf(item.supplier_branch_id) >= 0;
          }
        }).sortBy('supplier_branch_name');
      }
      return supplierBranches;
    },


    actions: {
      resendNotificationMail: function resendNotificationMail() {
        var _this = this;

        var resendItem = this.controller.get('reportDetails');
        this.controller.set('errors', []);
        var itineraryNumber = resendItem.get('SupplierReferenceNumber');
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/reports/resend/itin/' + itineraryNumber;
        var resendData = {
          SaleId: resendItem.get('Saleid'),
          ActivityId: resendItem.get('Activityid'),
          OfferId: resendItem.get('Offerid')
        };

        this.get('ajaxWrapper').ajaxPost(url, resendData).then(function (response) {
          _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (errors) {
          // the api response is not in JSON format, so ajax call goes to catch function even on success
          if (errors.status == 200) {
            _this.controller.set('errors', []);
            _this.get('notifications').success(_this.get('i18n').t('components.alert.resendSuccess'));
          } else {
            _this.controller.set('errors', errors);
            Ember.Logger.error('resend notification error', errors);
            _this.get('notifications').error(_this.get('i18n').t('components.alert.resendFailure'));
          }
        });
      },
      goToPromotionsPage: function goToPromotionsPage(promotionId) {
        if (promotionId) {
          this.controller.get('uitk').modal.close();
          this.transitionTo('promotion', promotionId, {
            queryParams: {
              productId: null,
              optionId: null,
              clone: null,
              campaignId: null
            }
          });
        }
      }
    }
  });
});