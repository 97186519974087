define('lxso/helpers/promo-funders', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.promoFunders = promoFunders;
  function promoFunders(params) {
    var isAbsolute = params[0].get('expediaFundType') === 'Absolute';
    var fundUnit = isAbsolute ? ' ' + params[1] : '%';
    var fundType = params[0].get('fundType');
    var funders = [];
    if (fundType === 'Expedia and Supplier Funded') {
      funders.push('' + params[0].get('expediaFund') + fundUnit + ' Expedia');
      funders.push('' + params[0].get('supplierFund') + fundUnit + ' Supplier');
    } else if (fundType === 'Expedia Funded') {
      funders.push('Expedia');
    } else if (fundType === 'Supplier Funded') {
      funders.push('Supplier');
    }
    return funders;
  }

  exports.default = Ember.Helper.helper(promoFunders);
});