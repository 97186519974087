define('lxso/models/account-settings/change-password', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 6,
      messageKey: 'errors.customMessages.changePassword.passwordLength'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*[a-z].*/,
      messageKey: 'errors.customMessages.changePassword.requiresOneLowerCase'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*[A-Z].*/,
      messageKey: 'errors.customMessages.changePassword.requiresOneUpperCase'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*[^a-zA-Z0-9].*/,
      messageKey: 'errors.customMessages.changePassword.requiresOneSpecialCharacter'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*\d.*/,
      messageKey: 'errors.customMessages.changePassword.requiresNumber'
    })],
    confirmPassword: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      messageKey: 'errors.customMessages.changePassword.passwordMatch'
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    password: _emberData.default.attr('string'),
    confirmPassword: _emberData.default.attr('string'),
    i18n: Ember.inject.service()
  });
});