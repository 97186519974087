define('lxso/components/option-group/ticket-type-restrictions/component', ['exports', 'lxso/constants/base-ticket-types', 'lxso/utils/form-validator', 'lxso/mixins/example-modal'], function (exports, _baseTicketTypes, _formValidator, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_exampleModal.default, {
    i18n: Ember.inject.service(),
    classNames: ['option-group-price-group-v4'],
    BASE_TICKET_TYPES: _baseTicketTypes.BASE_TICKET_TYPES,
    ticketTypesRestrictionsToDisplay: Ember.computed('ticketTypeRestrictions', function () {
      var ticketTypeRestrictions = this.get('ticketTypeRestrictions.restrictions');
      var availableTicketTypeLabels = this.get('availableTicketTypes').filter(function (tt) {
        return tt.get('isSelected');
      }).map(function (tt) {
        return tt.get('baseLabel');
      });
      return ticketTypeRestrictions.filter(function (ticketTypeRestriction) {
        return availableTicketTypeLabels.includes(ticketTypeRestriction.get('label'));
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.initMinMaxTicketsDropdownOptions();
      this.setSelectAllTicketTypesFlag();
    },
    initMinMaxTicketsDropdownOptions: function initMinMaxTicketsDropdownOptions() {
      var options = [].concat(_toConsumableArray(Array(_baseTicketTypes.TICKETS_PER_BOOKING_UPPER_LIMIT).keys())).map(function (i) {
        return i + 1;
      });
      this.set('minTicketOptions', options);
      this.set('maxTicketOptions', options);
    },
    validateTicketTypeRestrictions: function validateTicketTypeRestrictions() {
      return (0, _formValidator.validate)({ model: this.get('ticketTypeRestrictions'),
        attributes: ['ticketTypeRestrictionOverlap'] });
    },
    _validateIfInvalid: function _validateIfInvalid() {
      var _this = this;

      if (this.get('errors')) {
        this.validateTicketTypeRestrictions().then(function () {
          _this.set('errors', null);
        }).catch(function (errors) {
          _this.set('errors', errors);
        });
      }
    },
    clearTicketInfo: function clearTicketInfo(ticketType) {
      ticketType.set('minimumTicketsPerBooking', _baseTicketTypes.MIN_TICKETS_PER_BOOKING_DEFAULT);
      ticketType.set('maximumTicketsPerBooking', _baseTicketTypes.MAX_TICKETS_PER_BOOKING_DEFAULT);
    },
    setSelectAllTicketTypesFlag: function setSelectAllTicketTypesFlag() {
      var displayedTicketTypesCount = this.get('ticketTypesRestrictionsToDisplay').length;
      var selectedTicketTypesCount = this.get('ticketTypesRestrictionsToDisplay').filter(function (tt) {
        return tt.get('isSelected');
      }).length;
      this.set('isSelectAllTicketTypesChecked', displayedTicketTypesCount === selectedTicketTypesCount);
    },


    actions: {
      validateIfInvalid: function validateIfInvalid() {
        this._validateIfInvalid();
      },
      editTicketTypeRestrictions: function editTicketTypeRestrictions() {
        this.sendAction('editTicketTypeRestrictions');
      },
      handleSelectAllTicketTypesCheckboxChange: function handleSelectAllTicketTypesCheckboxChange() {
        var _this2 = this;

        var isAllTicketsSelected = this.get('isSelectAllTicketTypesChecked');
        this.get('ticketTypesRestrictionsToDisplay').forEach(function (tt) {
          tt.set('isSelected', isAllTicketsSelected);
          if (!isAllTicketsSelected) {
            _this2.clearTicketInfo(tt);
          }
        });
      },
      handleTicketTypeSelect: function handleTicketTypeSelect(tt) {
        if (!tt.get('isSelected')) {
          this.clearTicketInfo(tt);
        }
        this.setSelectAllTicketTypesFlag();
        this._validateIfInvalid();
      },
      handleMinTicketsChange: function handleMinTicketsChange(ticket, event) {
        ticket.set('minimumTicketsPerBooking', parseInt(event.target.value) || null);
        this._validateIfInvalid();
      },
      handleMaxTicketsChange: function handleMaxTicketsChange(ticket, event) {
        ticket.set('maximumTicketsPerBooking', parseInt(event.target.value) || null);
        this._validateIfInvalid();
      },
      cancelTicketTypeRestrictions: function cancelTicketTypeRestrictions() {
        this.sendAction('cancelTicketTypeRestrictions', this.get('ticketTypeRestrictions'));
        this.set('errors', null);
      },
      saveAndContinue: function saveAndContinue() {
        var _this3 = this;

        this.set('errors', null);
        this.validateTicketTypeRestrictions().then(function () {
          _this3.set('errors', null);
          _this3.sendAction('saveTicketTypeRestrictions', _this3.get('ticketTypeRestrictions'));
        }).catch(function (errors) {
          _this3.set('errors', errors);
          (0, _formValidator.scrollToFirstError)();
        });
      }
    }
  });
});