define('lxso/helpers/generate-id', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.generateId = generateId;


  // creates IDs for things that are already inside component curly braces
  function generateId(params) {
    return params.filter(function (item) {
      return Ember.isPresent(item);
    }).join('-');
  }

  exports.default = Ember.Helper.helper(generateId);
});