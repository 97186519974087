define('lxso/adapters/activity', ['exports', 'lxso/adapters/application', 'lxso/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    mediavault: Ember.inject.service('mediavault'),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    featureFlags: Ember.inject.service(),
    activityMigrateSupport: Ember.inject.service(),

    findHasMany: function findHasMany(store, snapshot, url, relationship) {
      /**
       * Relationship handler for hasMany assets
       *
       * Usage on an activity retrieved from the store:
       * ```
       * activity.get('assets').then((assets) => {
       *   assets.forEach((asset) => {
       *     console.log('asset', asset);
       *   });
       * });
       * ```
       *
       * Usage within a template:
       * ```
       * {{#each activity.assets as |asset|}}
       *   {{#if asset.displayImage.url}}
       *     <img src="{{asset.displayImage.url}}">
       *   {{/if}}
       * {{/each}}
       * ```
       */

      // If this is a hasMany request for the assets key, find the api key
      // and query Media Vault for the images
      var api = _environment.default.api;
      if (relationship.key === 'assets' && relationship.kind === 'hasMany') {
        var host = '';
        var domain = api.host + '/api/v3/media/' + snapshot.id + '/images';
        var param = 'activeStatus=true';
        host = domain + '?' + param;
        return this.get('ajaxWrapper').ajaxGet(host, null, {
          xhrFields: {
            withCredentials: true
          }
        });
      } else if (relationship.key === 'ticketTypes' && relationship.kind === 'hasMany') {
        var _host = api.hostV4 + '/activity/' + snapshot.id + '/ticket-types';
        return this.get('ajaxWrapper').ajaxGet(_host, null, {
          xhrFields: {
            withCredentials: true
          }
        });
      } else {
        return this._super.apply(this, arguments);
      }
    },
    findRecord: function findRecord(store, type, param, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var qParams = 'fields=basic,content';
      var isOfferNeeded = snapshot.adapterOptions && snapshot.adapterOptions.isOfferNeeded;

      if (isOfferNeeded) {
        qParams = 'fields=basic,content,offers';
      }

      var useV4Api = snapshot.adapterOptions && snapshot.adapterOptions.isMigrated && snapshot.adapterOptions.isMigrated === 'true';
      serializer.serializeIntoHash(data, type, snapshot);
      return this.get('activityMigrateSupport').activityGet(param, qParams, useV4Api);
    },
    queryRecord: function queryRecord(store, type, query) {
      var data = [];
      var url = this.host + '/' + this.namespace + '/activities/' + query.id + '?fields=' + query.fields;
      return this.ajax(url, 'GET', { data: data });
    }
  });
});