define('lxso/components/bulk-update-direct-status/component', ['exports', 'lxso/config/environment', 'lxso/utils/array-values'], function (exports, _environment, _arrayValues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SUPPLIER_BRANCH_COL_NAME = 'SupplierBranchId';
  var PRODUCTS_COL_NAME = 'ActivityId';
  var SAMPLE_FILE_NAME = 'bulk-update-direct-sample.csv';
  var FAILED_REPORT_FILE_NAME = 'bulk-update-direct-failure-report.csv';
  var INACTIVE_STATUS = 'INACTIVE';
  var SUSPEND_EXPEDIA_STATUS = 'SUSPENDED_EXPEDIA';
  var SUSPEND_PAUSED_STATUS = 'SUSPENDED_PAUSED';

  exports.default = Ember.Component.extend({
    responseErrors: Ember.inject.service(),
    fileSaver: Ember.inject.service(),
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var i18n = this.get('i18n');
      this.setProperties({
        bulkUploadFile: null,
        processingRequest: false,
        selectedStatus: INACTIVE_STATUS,
        activityDeactivateReason: null,
        activitySuspendReason: null,
        hasFailures: false,
        activityDeactivationReasons: [i18n.t('pages.deactivateProduct.dmReasonOptions.noLongerOperates'), i18n.t('pages.deactivateProduct.dmReasonOptions.hAndSConcerns'), i18n.t('pages.deactivateProduct.dmReasonOptions.coiRequirements'), i18n.t('pages.deactivateProduct.dmReasonOptions.taxRequirements'), i18n.t('pages.deactivateProduct.commonReasonOptions.productDiscontinued'), i18n.t('pages.deactivateProduct.dmReasonOptions.expediaTermination'), i18n.t('pages.deactivateProduct.dmReasonOptions.qualityConcerns'), i18n.t('pages.deactivateProduct.dmReasonOptions.notResponsive'), i18n.t('pages.deactivateProduct.dmReasonOptions.partnerTermination'), i18n.t('pages.deactivateProduct.commonReasonOptions.other'), i18n.t('pages.deactivateProduct.commonReasonOptions.technicalCompatibilityIssues'), i18n.t('pages.deactivateProduct.commonReasonOptions.contractualDispute'), i18n.t('pages.deactivateSupplier.dmReasonOptions.internalFactors')].sort(),
        activitySuspendReasons: (0, _arrayValues.getArrayOfValues)(9).map(function (i) {
          return i18n.t('pages.bulkUpdate.directProductStatus.suspendedReasons.' + i);
        }).sort()
      });
    },
    getReason: function getReason() {
      var selectedStatus = this.get('selectedStatus');
      if (selectedStatus === INACTIVE_STATUS) {
        return this.get('activityDeactivateReason');
      } else if (selectedStatus === SUSPEND_EXPEDIA_STATUS || selectedStatus === SUSPEND_PAUSED_STATUS) {
        return this.get('activitySuspendReason');
      } else {
        return null;
      }
    },


    actions: {
      onDeactivateReasonSelect: function onDeactivateReasonSelect(reason) {
        this.set('activityDeactivateReason', reason);
      },
      onSuspendReasonSelect: function onSuspendReasonSelect(reason) {
        this.set('activitySuspendReason', reason);
      },
      uploadFile: function uploadFile(file) {
        if (file && file.target.files[0] && file.target.files[0].name) {
          this.set('bulkUploadFile', file.target.files[0]);
        } else {
          this.set('bulkUploadFile', null);
        }
      },
      triggerFileSelect: function triggerFileSelect() {
        $('#bulk-upload-file').trigger('click');
      },
      downloadSampleCSV: function downloadSampleCSV() {
        var sampleData = SUPPLIER_BRANCH_COL_NAME + ',' + PRODUCTS_COL_NAME + '\n101,100001\n102';
        this.createCsvAndDownload(sampleData, SAMPLE_FILE_NAME);
      },
      resetFile: function resetFile() {
        $('#bulk-upload-file').val('');
        this.set('bulkUploadFile', null);
      },
      submitForm: function submitForm() {
        var _this = this;

        this.get('responseErrors').clearErrors();
        this.set('hasFailures', false);
        var formData = new FormData();

        var url = _environment.default.api.host + '/api/v3/bulkUpdate/statusUpdate';

        formData.append('status', this.get('selectedStatus'));
        var reason = this.getReason();
        if (reason) {
          formData.append('reason', reason);
        }
        formData.append('file', this.get('bulkUploadFile'));

        this.set('processingRequest', true);
        $.ajax({
          url: url,
          type: 'PUT',
          data: formData,
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          dataType: 'json',
          processData: false,
          contentType: false,
          headers: {
            Accept: 'application/json'
          }
        }).done(function (successResponse) {
          _this.set('showBulkUploadResModal', true);
          _this.set('bulkUploadSuccessResponse', successResponse);
          if (successResponse.failedActivityIds || successResponse.failedSupplierBranchIds) {
            _this.set('hasFailures', true);
          }
        }).fail(function (errorResponse) {
          _this.get('responseErrors').addErrors(errorResponse.responseJSON, errorResponse.status);
        }).always(function () {
          _this.set('processingRequest', false);
          _this.send('resetFile');
        });
      },
      changeStatus: function changeStatus(status) {
        this.resetForm();
        this.set('selectedStatus', status);
      },
      closeBulkUploadSuccessResModal: function closeBulkUploadSuccessResModal() {
        var bulkUploadRes = this.get('bulkUploadSuccessResponse');
        if (bulkUploadRes.failedActivityIds || bulkUploadRes.failedSupplierBranchIds) {
          this.generateReportsBulkUpload(bulkUploadRes);
        }
        this.set('showBulkUploadResModal', false);
        this.set('bulkUploadSuccessResponse', null);
      }
    },

    resetForm: function resetForm() {
      this.set('activityDeactivateReason', null);
      this.get('responseErrors').clearErrors();
      this.send('resetFile');
    },
    generateReportsBulkUpload: function generateReportsBulkUpload(response) {
      var csvData = SUPPLIER_BRANCH_COL_NAME + ',' + PRODUCTS_COL_NAME;
      var failedSupplierBranchIds = response.failedSupplierBranchIds,
          failedActivityIds = response.failedActivityIds;

      var failedSupplierBranchIdsCount = !!failedSupplierBranchIds ? failedSupplierBranchIds.length : 0;
      var failedActivityIdsCount = !!failedActivityIds ? failedActivityIds.length : 0;
      var rowCount = Math.max(failedActivityIdsCount, failedSupplierBranchIdsCount);
      for (var i = 0; i < rowCount; i++) {
        csvData = csvData + '\r\n' + this.getValueFromArray(failedSupplierBranchIds, i) + ',';
        csvData = '' + csvData + this.getValueFromArray(failedActivityIds, i);
      }
      this.createCsvAndDownload(csvData, FAILED_REPORT_FILE_NAME);
    },
    getValueFromArray: function getValueFromArray(array, index) {
      return array && array[index] ? array[index] : '';
    },
    createCsvAndDownload: function createCsvAndDownload(data, fileName) {
      var blob = new Blob(['\uFEFF', data], { type: 'text/csv' });
      this.get('fileSaver').saveAs(blob, fileName);
    }
  });
});