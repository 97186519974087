define('lxso/models/activity/migration-status', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    itinerary: _emberData.default.attr('boolean', { defaultValue: false }),
    content: _emberData.default.attr('boolean', { defaultValue: false }),
    logistics: _emberData.default.attr('boolean', { defaultValue: false }),
    offer: _emberData.default.attr('boolean', { defaultValue: false }),
    price: _emberData.default.attr('boolean', { defaultValue: false }),
    ticketType: _emberData.default.attr('boolean', { defaultValue: false })
  });
});