define('lxso/routes/photo-audit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lxso/config/environment'], function (exports, _authenticatedRouteMixin, _featureFlag, _requirePermission, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_requirePermission.default, _authenticatedRouteMixin.default, {
    omniture: { pageName: 'page.LX-Supply.PhotoAudit' },
    requiredPermission: 'V3InternalSettings',
    mediavault: Ember.inject.service('mediavault'),
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),

    model: function model(params) {
      return new Ember.RSVP.hash({
        supplierBranches: this.store.findRecord('suppliers', params.id),
        params: params
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.params);
      var supplierBranches = model.supplierBranches.get('branches').filter(function (item) {
        return item.is_active;
      }).sortBy('supplier_branch_name');
      controller.set('supplierName', this.get('session.session.authenticated.selectedBranch.supplier_name'));
      controller.set('supplierBranchNames', supplierBranches.map(function (branch) {
        return branch.supplier_branch_name;
      }));
      controller.set('supplierBranchIDs', supplierBranches.map(function (branch) {
        return branch.supplier_branch_id;
      }));
      controller.set('filterByApprovalStatusOptions', [this.get('i18n').t('pages.audit.statusDropdownOptions.all'), this.get('i18n').t('pages.audit.statusDropdownOptions.published'), this.get('i18n').t('pages.audit.statusDropdownOptions.rejected'), this.get('i18n').t('pages.audit.statusDropdownOptions.pending')]);
      controller.set('filterByApprovalStatusValues', ['', 'published', 'rejected', 'received']);
      controller.set('userTag', '');
      controller.set('userStatus', '');
      controller.set('tagsDropdownOptions', []);
      var supplierBranchObjects = [];
      supplierBranches.forEach(function (branch) {
        supplierBranchObjects.push({
          id: branch.supplier_branch_id,
          name: branch.supplier_branch_name
        });
      });
      controller.set('supplierBranches', supplierBranchObjects);
      this.set('numberOfUpdateDisplayImagesChecks', 0);

      this.controller.set('rejectReasonsOptions', [this.get('i18n').t('pages.audit.rejectReasonsOptions.inappropriateContent'), this.get('i18n').t('pages.audit.rejectReasonsOptions.poorQuality'), this.get('i18n').t('pages.audit.rejectReasonsOptions.smallSize'), this.get('i18n').t('pages.audit.rejectReasonsOptions.visibleTimestamp'), this.get('i18n').t('pages.audit.rejectReasonsOptions.resizeFailure')]);
      this.controller.set('rejectReasonsValues', ['rejected for inappropriate content', 'rejected for poor quality', 'rejected for small image size', 'rejected for visible time stamp', 'rejected for resize failure']);
      this.controller.set('filteredAssets', this.controller.get('branchAssets'));
    },


    actions: {
      openDeleteModal: function openDeleteModal(asset, index) {
        this.controller.set('assetToDelete', asset);
        this.controller.set('indexToDelete', index);
        this.controller.set('isDeleteConfirmationModalOpen', true);
      },
      deleteAsset: function deleteAsset() {
        var asset = this.controller.get('assetToDelete');
        var index = this.controller.get('indexToDelete');
        var mediaVault = this.get('mediavault');
        mediaVault.deleteImage(asset.fileId, this.controller.get('activityId'));
        var filteredAssets = this.controller.get('filteredAssets').filter(function (filteredAsset) {
          return filteredAsset.fileId != asset.fileId;
        });
        this.controller.set('filteredAssets', filteredAssets);
        this.controller.set('isDeleteConfirmationModalOpen', false);
      },
      cancelDelete: function cancelDelete() {
        this.controller.set('isDeleteConfirmationModalOpen', false);
        this.controller.set('assetToDelete', null);
        this.controller.set('indexToDelete', null);
      },
      rejectImage: function rejectImage(asset) {
        var mediaVault = this.get('mediavault');
        if (!this.controller.get('rejectReason')) {
          this.controller.set('rejectReason', 'rejected for inappropriate content');
        }
        var rejectReason = this.controller.get('rejectReason');
        mediaVault.rejectImage(asset, rejectReason);
        var filteredAssets = this.controller.get('filteredAssets').filter(function (filteredAsset) {
          return filteredAsset.fileId != asset.fileId;
        });
        this.controller.set('filteredAssets', filteredAssets);
      },
      updateRejectReason: function updateRejectReason(asset, reason) {
        this.controller.set('rejectReason', reason);
      },
      filterByTag: function filterByTag(userTag) {
        this.controller.set('userTag', userTag);
        this.send('filterByStatusAndTag');
      },
      filterByStatus: function filterByStatus(userStatus) {
        this.controller.set('userStatus', userStatus);
        this.send('filterByStatusAndTag');
      },
      filterByStatusAndTag: function filterByStatusAndTag() {
        var userTag = this.controller.get('userTag');
        var userStatus = this.controller.get('userStatus');
        var allFilteredAssets = this.controller.get('branchAssets').filter(function (asset) {
          return (Ember.isEmpty(userTag) || asset.tags.contains(userTag)) && (Ember.isEmpty(userStatus) || asset.approval.toString() === userStatus.toLowerCase());
        });
        this.controller.set('filteredAssets', allFilteredAssets);
      },
      saveCaption: function saveCaption(asset, caption, tagsArray) {
        var mediaVault = this.get('mediavault');
        mediaVault.updateImageDetails(asset, caption, tagsArray);
      }
    }
  });
});