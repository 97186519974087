define('lxso/routes/booking-questions', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/utils/form-validator', 'lxso/mixins/require-permission', 'lxso/mixins/booking-questions/booking-questions-common', 'lxso/config/environment', 'lxso/utils/omniture'], function (exports, _authenticatedRouteMixin, _featureFlag, _formValidator, _requirePermission, _bookingQuestionsCommon, _environment, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_featureFlag.default, _requirePermission.default, _authenticatedRouteMixin.default, _bookingQuestionsCommon.default, {
    featureName: 'BookingQuestions',
    requiredPermission: 'BookingQuestionTemplateRW',
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    session: Ember.inject.service('session'),
    featureFlags: Ember.inject.service(),

    queryParams: {
      clone: { refreshModel: true }
    },

    cloneBQSet: function cloneBQSet(existingBQSet) {
      return this.store.createRecord('booking-questions', {
        supplierBranchId: existingBQSet.get('supplierBranchId'),
        name: existingBQSet.get('name'),
        questionDetails: this.store.createFragment('fragments/booking-questions/booking-question-details', {
          questionDetails: []
        }),
        offerDetails: this.store.createFragment('fragments/booking-questions/booking-question-offer-details', {
          offerDetails: []
        })
      });
    },
    model: function model(params) {
      var _this = this;

      var supplierBranchId = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
      var productListUrl = _environment.default.api.host + '/api/v3/supplierBranch/' + supplierBranchId + '/activities';
      var productList = this.get('ajaxWrapper').ajaxGet(productListUrl);
      var isPickupDropoffEnabled = this.get('featureFlags').checkEnabled('BQPickupInformation');
      var bookingQuestionSetToClone = void 0;
      var filteredInitTravelerInfo = [];
      if (this.get('featureFlags').checkEnabled('BQPassportDob')) {
        filteredInitTravelerInfo = this.getBookingQuestionsWithoutGrouping(this.get('initGroupedTravelerInfo'));
      } else {
        filteredInitTravelerInfo = this.getBookingQuestionsWithoutGrouping(this.get('initGroupedTravelerInfoWithoutPassportDob'));
      }

      if (isPickupDropoffEnabled) {
        var filteredInitPickupInfo = this.getBookingQuestionsWithoutGrouping(this.get('initGroupedPickupInfo'));
        var filteredInitDropoffInfo = this.getBookingQuestionsWithoutGrouping(this.get('initGroupedDropoffInfo'));
      }
      var bookingQuestionSet = void 0;

      if (params.id === 'new') {
        bookingQuestionSet = this.store.createRecord('booking-questions', {
          supplierBranchId: supplierBranchId,
          name: name,
          questionDetails: this.store.createFragment('fragments/booking-questions/booking-question-details', {
            questionDetails: filteredInitTravelerInfo
          }),
          offerDetails: this.store.createFragment('fragments/booking-questions/booking-question-offer-details', {
            offerDetails: []
          })
        });
      } else if (params.clone) {
        bookingQuestionSetToClone = this.store.findRecord('booking-questions', params.id);
        bookingQuestionSet = bookingQuestionSetToClone.then(function (existingBQSet) {
          return _this.cloneBQSet(existingBQSet);
        });
      } else {
        bookingQuestionSet = this.store.findRecord('booking-questions', params.id, { reload: true });
      }

      return new Ember.RSVP.hash({
        supplierBranchId: supplierBranchId,
        bookingQuestionSet: bookingQuestionSet,
        params: params,
        productList: productList,
        bookingQuestionSetToClone: bookingQuestionSetToClone,
        isPickupDropoffEnabled: isPickupDropoffEnabled
      });
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      this._super(controller, model.params);
      this.controller.set('bookingQuestionSet', model.bookingQuestionSet);
      this.controller.set('isNew', model.params.id === 'new');
      this.controller.set('showSaveCancelButtons', true);
      this.controller.set('saveSuccessful', false);
      this.controller.set('isClone', !!model.params.clone);
      this.controller.set('bookingQuestionSetToClone', model.bookingQuestionSetToClone);
      this.controller.set('isPickupDropoffEnabled', model.isPickupDropoffEnabled);

      this.initProductsOptions();
      this.initSetName();
      this.initBookingQuestionDetails();

      controller.setProperties({
        errors: '',
        supplierBranchId: model.supplierBranchId,
        productList: model.productList.map(function (product) {
          return Ember.Object.create(Object.assign(product, {
            offers: product.offers.map(function (offer) {
              return Ember.Object.create(Object.assign(offer, {
                ticketTypes: offer.ticketTypes.map(function (ticket) {
                  return Ember.Object.create(ticket);
                })
              }));
            })
          }));
        })
      });

      this.controller.get('productList').forEach(function (product) {
        Ember.set(product, 'isProductSelected', false);
        product.offers.forEach(function (option) {
          Ember.set(option, 'isOptionSelected', false);
        });
      });

      if (!this.controller.get('isNew') || model.params.clone) {
        this.initProductsOptionsForEditPage();
      }
      Ember.run.next(function () {
        if (model.params.clone) {
          _this2.send('updateBookingQuestionOfferDetails');
        }
      });
    },
    initProductsOptionsForEditPage: function initProductsOptionsForEditPage() {
      var isClone = this.controller.get('isClone');
      var selectedProducts = this.controller.get('selectedProducts');
      var selectedProductsAndOptions = this.controller.get('selectedProductsAndOptions');
      var existingProductAndOptions = isClone ? this.controller.get('bookingQuestionSetToClone.bookingQuestionOfferDetailsMap') : this.controller.get('bookingQuestionSet.bookingQuestionOfferDetailsMap');
      this.controller.get('productList').forEach(function (listProduct) {
        existingProductAndOptions.forEach(function (existingProduct) {
          if (listProduct.id === existingProduct.get('activityId')) {
            Ember.set(listProduct, 'isProductSelected', true);
            selectedProducts.push(listProduct);
          }
          listProduct.offers.forEach(function (listOffer) {
            existingProduct.get('offers').forEach(function (existingOffer) {
              if (listOffer.id === existingOffer.get('offerId')) {
                Ember.set(listOffer, 'isOptionSelected', true);
                var tempProduct = {};
                tempProduct.activityId = listProduct.id;
                tempProduct.offerId = listOffer.id;
                selectedProductsAndOptions.push(tempProduct);
              }
            });
          });
        });
      });
      this.controller.set('selectedProducts', selectedProducts);
      this.controller.set('selectedProductsAndOptions', selectedProductsAndOptions);
      this.controller.set('initSelectedProductsAndOptions', selectedProductsAndOptions);
    },
    getOfferDetailsGroupedByActivity: function getOfferDetailsGroupedByActivity(offerDetails) {
      var groupedOfferDetails = [];
      var selectedProducts = this.controller.get('selectedProducts');
      selectedProducts.forEach(function (selectedProduct) {
        var tempProduct = {};
        tempProduct.activityId = selectedProduct.id;
        tempProduct.activityTitle = selectedProduct.title;
        tempProduct.offers = [];
        selectedProduct.offers.forEach(function (option) {
          if (option.get('isOptionSelected')) {
            var tempOption = {};
            tempOption.offerId = option.id;
            tempOption.offerTitle = option.title;
            tempProduct.offers.push(tempOption);
          }
        });
        groupedOfferDetails.push(tempProduct);
      });
      return groupedOfferDetails;
    },


    actions: {

      // BOOKING QUESTIONS SECTION
      //-----------------------------------------------------------

      openStep: function openStep() {
        if (this.controller.get('bookingQuestionsAccordionComplete') || !this.controller.get('isNew')) {
          var link = document.getElementsByClassName('toggle-trigger booking-questions-accordion');
          $(link).toggleClass('open');

          var travelerInformation = document.getElementsByClassName('booking-questions-section-view');
          $(travelerInformation).toggleClass('close');

          var travelerAccrodianLink = document.getElementsByClassName('toggle-trigger traveler-info-accordion');
          $(travelerAccrodianLink).removeClass('open');

          var travelerInfoSectionLink = document.getElementsByClassName('traveler-info-section-view');
          $(travelerInfoSectionLink).removeClass('close');

          var pickupAccordianLink = document.getElementsByClassName('toggle-trigger pickup-info-accordion');
          $(pickupAccordianLink).removeClass('open');

          var pickupInfoSectionLink = document.getElementsByClassName('pickup-info-section-view');
          $(pickupInfoSectionLink).removeClass('close');

          var dropoffAccrodianLink = document.getElementsByClassName('toggle-trigger dropoff-info-accordion');
          $(dropoffAccrodianLink).removeClass('open');

          var dropoffInfoSectionLink = document.getElementsByClassName('dropoff-info-section-view');
          $(dropoffInfoSectionLink).removeClass('close');

          this.controller.set('bookingQuestionsAccordionClosed', !this.controller.get('bookingQuestionsAccordionClosed'));
        }
      },
      openQuestionSection: function openQuestionSection(section) {
        switch (section) {
          case 'travelerInfo':
            {
              var link = document.getElementsByClassName('toggle-trigger traveler-info-accordion');
              $(link).toggleClass('open');

              var travelerInfoSection = document.getElementsByClassName('traveler-info-section-view');
              $(travelerInfoSection).toggleClass('close');
            }
            break;

          case 'pickupInfo':
            {
              var _link = document.getElementsByClassName('toggle-trigger pickup-info-accordion');
              $(_link).toggleClass('open');

              var pickupInfoSection = document.getElementsByClassName('pickup-info-section-view');
              $(pickupInfoSection).toggleClass('close');
            }
            break;

          case 'dropoffInfo':
            {
              var _link2 = document.getElementsByClassName('toggle-trigger dropoff-info-accordion');
              $(_link2).toggleClass('open');

              var dropoffInfoSection = document.getElementsByClassName('dropoff-info-section-view');
              $(dropoffInfoSection).toggleClass('close');
            }
            break;
          default:
        }
      },
      toggleQuestionSelection: function toggleQuestionSelection(section, selectedQuestion, parent) {
        var questionDetails = void 0;
        if (section === 'travelerInfo') {
          questionDetails = this.controller.get('bookingQuestionSet.questionDetails');
        } else if (section === 'pickupInfo') {
          questionDetails = this.controller.get('bookingQuestionSet.pickupDetails');
        } else if (section == 'dropoffInfo') {
          questionDetails = this.controller.get('bookingQuestionSet.dropoffDetails');
        }
        questionDetails.forEach(function (travelerQuestion) {
          if (travelerQuestion.name == selectedQuestion.name) {
            // toggle self and all children
            Ember.set(travelerQuestion, 'isEnabled', !travelerQuestion.isEnabled);
            travelerQuestion.children.forEach(function (child) {
              Ember.set(child, 'isEnabled', travelerQuestion.isEnabled);
            });
            var selectedQuestionName = selectedQuestion.name;
            var link = document.getElementsByClassName('toggle-trigger booking-question-' + selectedQuestionName);
            $(link).toggleClass('open', true);

            var data = document.getElementsByClassName('children-of-' + selectedQuestionName);
            $(data).toggleClass('close', false);
          } else {
            // check if selected question is child, then toggle
            travelerQuestion.children.forEach(function (child) {
              if (child.name == selectedQuestion.name) {
                Ember.set(child, 'isEnabled', !child.isEnabled);
              }
            });
          }
        });
        var parentEnabled = true;
        var selectAllEnabled = true;
        questionDetails.forEach(function (travelerQuestion) {
          travelerQuestion.children.forEach(function (child) {
            if (travelerQuestion.name == parent.name) {
              parentEnabled = parentEnabled && child.isEnabled;
            }
          });
          Ember.set(parent, 'isEnabled', parentEnabled);
          selectAllEnabled = selectAllEnabled && travelerQuestion.isEnabled;
        });

        if (section === 'travelerInfo') {
          this.controller.set('bookingQuestionSet.questionDetails', questionDetails);
          this.controller.set('travelerInfoSelectAllEnabled', selectAllEnabled);
        } else if (section === 'pickupInfo') {
          this.controller.set('bookingQuestionSet.pickupInfo', questionDetails);
          this.controller.set('pickupInfoSelectAllEnabled', selectAllEnabled);
        } else if (section === 'dropoffInfo') {
          this.controller.set('bookingQuestionSet.dropoffInfo', questionDetails);
          this.controller.set('dropoffInfoSelectAllEnabled', selectAllEnabled);
        }
        this.setNoneSelected();
      },
      toggleSelectAll: function toggleSelectAll(section) {
        var questions = void 0;
        var selectAllEnabled = void 0;
        if (section === 'travelerInfo') {
          this.controller.set('travelerInfoSelectAllEnabled', !this.controller.get('travelerInfoSelectAllEnabled'));
          selectAllEnabled = this.controller.get('travelerInfoSelectAllEnabled');
          questions = this.controller.get('bookingQuestionSet.questionDetails');
        } else if (section === 'pickupInfo') {
          this.controller.set('pickupInfoSelectAllEnabled', !this.controller.get('pickupInfoSelectAllEnabled'));
          selectAllEnabled = this.controller.get('pickupInfoSelectAllEnabled');
          questions = this.controller.get('bookingQuestionSet.pickupDetails');
        } else if (section === 'dropoffInfo') {
          this.controller.set('dropoffInfoSelectAllEnabled', !this.controller.get('dropoffInfoSelectAllEnabled'));
          selectAllEnabled = this.controller.get('dropoffInfoSelectAllEnabled');
          questions = this.controller.get('bookingQuestionSet.dropoffDetails');
        }
        questions.forEach(function (travelerQuestion) {
          Ember.set(travelerQuestion, 'isEnabled', selectAllEnabled);
          travelerQuestion.children.forEach(function (child) {
            Ember.set(child, 'isEnabled', selectAllEnabled);
          });
          var selectedQuestionName = travelerQuestion.name;
          var link = document.getElementsByClassName('toggle-trigger booking-question-' + selectedQuestionName);
          $(link).toggleClass('open', true);

          var data = document.getElementsByClassName('children-of-' + selectedQuestionName);
          $(data).toggleClass('close', false);
        });
        this.setNoneSelected();
      },
      saveTravelerInfoQuestions: function saveTravelerInfoQuestions() {
        var bqLink = document.getElementsByClassName('toggle-trigger booking-questions-accordion');
        $(bqLink).toggleClass('open');
        var bqSection = document.getElementsByClassName('booking-questions-section-view');
        $(bqSection).toggleClass('close');
        this.controller.set('bookingQuestionsAccordionClosed', true);
        this.controller.set('bookingQuestionsAccordionComplete', true);
        if (!this.controller.get('isNew')) {
          this.controller.set('bookingQuestionsAccordionModified', this.isSectionEdited('bookingQuestionDetails'));
        } else {
          this.controller.set('isProductsOptionsOpen', true);
          this.send('openNextSection', 'productsAndOptions');
        }
      },


      // SET NAME
      //------------------------------------------------------------------

      toggleSetNameSection: function toggleSetNameSection() {
        this.controller.set('isSetNameOpen', !this.controller.get('isSetNameOpen'));
        this.controller.set('setNameAccordionClosed', !this.controller.get('setNameAccordionClosed'));
        var setNameLink = document.getElementsByClassName('toggle-trigger set-name-accordion');
        $(setNameLink).toggleClass('open');
        var setNameSection = document.getElementsByClassName('set-name-section');
        $(setNameSection).toggleClass('close');
        this.controller.set('bookingQuestionSet.name', this.controller.get('lastSavedName'));
        this.controller.set('isNameInvalid', !this.controller.get('bookingQuestionSet.name').length > 0);
        if (!this.controller.get('isNew')) {
          this.controller.set('setNameAccordionModified', this.isSectionEdited('setName'));
        }
      },
      validateBQSetNameIfInvalid: function validateBQSetNameIfInvalid() {
        var _this3 = this;

        if (this.controller.get('validationErrors')) {
          this.validateName().then(function () {
            _this3.controller.set('validationErrors', '');
          }).catch(function (errors) {
            _this3.controller.set('validationErrors', errors);
          });
        }

        this.controller.set('isNameInvalid', !this.controller.get('bookingQuestionSet.name').length > 0);
      },
      saveSetNameSection: function saveSetNameSection() {
        this.controller.set('setNameAccordionComplete', this.controller.get('bookingQuestionSet.name').length > 0);
        this.controller.set('lastSavedName', this.controller.get('bookingQuestionSet.name'));
        this.send('toggleSetNameSection');
      },
      updateBookingQuestionOfferDetails: function updateBookingQuestionOfferDetails() {
        this.controller.set('bookingQuestionSet.offerDetails', this.controller.get('selectedProductsAndOptions'));
      },
      saveBookingQuestionSet: function saveBookingQuestionSet() {
        var _this4 = this;

        var str = '';
        this.controller.set('errors', []);
        this.controller.set('bookingQuestionSet.name', this.controller.get('lastSavedName'));
        var bookingQuestionSet = this.controller.get('bookingQuestionSet');
        var ungroupedTravelerInfo = this.getBookingQuestionsWithoutGrouping(bookingQuestionSet.questionDetails);
        var ungroupedPickupInfo = [];
        var ungroupedDropoffInfo = [];
        if (this.controller.get('isPickupDropoffEnabled')) {
          ungroupedPickupInfo = this.getBookingQuestionsWithoutGrouping(bookingQuestionSet.pickupDetails);
          ungroupedDropoffInfo = this.getBookingQuestionsWithoutGrouping(bookingQuestionSet.dropoffDetails);
        }
        var filteredQuestions = ungroupedTravelerInfo.concat(ungroupedPickupInfo.concat(ungroupedDropoffInfo));
        this.controller.set('savingBookingQuestionSet', true);
        bookingQuestionSet.save({ adapterOptions: { ungroupedQuestions: filteredQuestions } }).then(function (saveResponse) {
          _this4.controller.set('saveSuccessful', true);
          _this4.get('notifications').success(str.concat("'", saveResponse.data.name, "'", _this4.get('i18n').t('pages.bookingQuestions.save.successNotification')));
          _this4.controller.set('showSaveCancelButtons', false);
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Submit.Booking-Questions-Set');
        }).catch(function (error) {
          _this4.controller.set('errors', error);
          _this4.controller.set('showSaveCancelButtons', true);
        }).finally(function () {
          _this4.controller.set('savingBookingQuestionSet', false);
        });
      },
      updateBookingQuestionSet: function updateBookingQuestionSet() {
        var _this5 = this;

        var str = '';
        this.controller.set('errors', []);
        var bookingQuestionSet = this.controller.get('bookingQuestionSet');
        var ungroupedTravelerInfo = this.getBookingQuestionsWithoutGrouping(bookingQuestionSet.questionDetails);
        var ungroupedPickupInfo = [];
        var ungroupedDropoffInfo = [];
        if (this.controller.get('isPickupDropoffEnabled')) {
          ungroupedPickupInfo = this.getBookingQuestionsWithoutGrouping(bookingQuestionSet.pickupDetails);
          ungroupedDropoffInfo = this.getBookingQuestionsWithoutGrouping(bookingQuestionSet.dropoffDetails);
        }
        var filteredQuestions = ungroupedTravelerInfo.concat(ungroupedPickupInfo.concat(ungroupedDropoffInfo));

        var modifiedOfferDetails = this.getOfferDetailsGroupedByActivity(bookingQuestionSet.offerDetails);
        this.controller.set('savingBookingQuestionSet', true);
        bookingQuestionSet.save({ adapterOptions: { ungroupedQuestions: filteredQuestions, groupedOfferDetails: modifiedOfferDetails } }).then(function (saveResponse) {
          _this5.controller.set('saveSuccessful', true);
          _this5.get('notifications').success(str.concat("'", saveResponse.data.name, "'", _this5.get('i18n').t('pages.bookingQuestions.save.updateSuccessNotification')));
          _this5.controller.set('showSaveCancelButtons', false);
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Update.Booking-Questions-Set');
        }).catch(function (error) {
          _this5.controller.set('errors', error);
          _this5.controller.set('showSaveCancelButtons', true);
        }).finally(function () {
          _this5.controller.set('savingBookingQuestionSet', false);
        });
      },
      cancelBookingQuestionSet: function cancelBookingQuestionSet() {
        var supplierBranchId = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
        this.transitionTo('booking-questions-list', supplierBranchId);
      },
      doneSaveBookingQuestionSet: function doneSaveBookingQuestionSet() {
        this.transitionTo('booking-questions-list', this.get('session.data.authenticated.selectedBranch.supplier_branch_id'));
      },
      isSectionEdited: function isSectionEdited(section) {
        this.controller.set('productsAndOptionsAccordionModified', this.isSectionEdited(section));
      }
    }
  });
});