define('lxso/helpers/to-capitalize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toCapitalize = toCapitalize;
  function toCapitalize(args) {
    if (args[0].length && /^[a-z]/.test(args[0][0]) && typeof args[0] == 'string') {
      return args[0][0].toUpperCase() + args[0].slice(1);
    } else {
      return args[0];
    }
  }

  exports.default = Ember.Helper.helper(toCapitalize);
});