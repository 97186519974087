define('lxso/mixins/option-group/option-group-v4', ['exports', 'lxso/constants/option-group', 'lxso/constants/language-type-ids'], function (exports, _optionGroup, _languageTypeIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service('session'),

    getDefaultOptionGroupRecord: function getDefaultOptionGroupRecord(activityId, activity) {
      var isGTBranch = this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
      return {
        activityId: activityId,
        shortTitle: activity.get('basic.long_title'),
        description: '',
        status: _optionGroup.STATUS.ACTIVE,
        attributes: {
          availabilityType: isGTBranch ? _optionGroup.AVAILABILITY_TYPES.UNLIMITED : _optionGroup.AVAILABILITY_TYPES.LIMITED,
          durationInMinutes: null,
          autoRenewRateEnabled: true,
          pricesByVolume: false,
          pricingIngestionEnabled: false,
          mealType: isGTBranch ? _optionGroup.MEAL_TYPES.NOT_INCLUDED : null,
          scheduleType: _optionGroup.SCHEDULE_TYPES.START,
          validityDays: null,
          durationDaysPart: null,
          durationHoursPart: null,
          durationMinutesPart: null
        },
        bookingCutoff: {
          cutoffType: isGTBranch ? _optionGroup.BOOKING_CUTOFF_TYPES.PICKUP_TIME : _optionGroup.BOOKING_CUTOFF_TYPES.START_TIME,
          bookingCutoffInHours: 0
        },
        cancellationPolicy: {
          policyType: _optionGroup.CANCELLATION_POLICY_TYPES.REFUNDABLE,
          cutoffInHours: 0
        },
        pickupEnabled: false,
        pickupInfo: {
          pickupType: _optionGroup.PICKUP_TYPES.OPTIONAL
        },
        offers: Ember.A([this.getDefaultOfferDetailRecord()])
      };
    },
    getDefaultOfferDetailRecord: function getDefaultOfferDetailRecord() {
      var isGTBranch = this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
      var LANG_ID_ENGLISH = 5;
      return {
        startTime: '',
        endTime: '',
        status: _optionGroup.STATUS.ACTIVE,
        languageInfo: {
          languageIds: isGTBranch ? Ember.A([]) : Ember.A([LANG_ID_ENGLISH]),
          languageTypeId: isGTBranch ? _languageTypeIds.LANG_TYPE_IDS.NO_COMMENTARY : _languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY
        },
        isSelected: false,
        uuid: Ember.uuid()
      };
    },
    getOfferDetailRecordCopy: function getOfferDetailRecordCopy(option) {
      return {
        startTime: option.get('startTime'),
        endTime: option.get('endTime'),
        status: _optionGroup.STATUS.ACTIVE,
        languageInfo: {
          languageIds: option.get('languageInfo.languageIds'),
          languageTypeId: option.get('languageInfo.languageTypeId')
        },
        isSelected: option.get('isSelected'),
        uuid: Ember.uuid()
      };
    }
  });
});