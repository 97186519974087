define('lxso/services/publish-validation', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Service.extend({
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    featureFlags: Ember.inject.service(),

    getPublishValidation: function getPublishValidation(activity) {
      var _this = this;

      var activityId = activity.id;
      var isMigrateOverrideEnabled = this.get('featureFlags').checkEnabled('V4ContentAndLogisticsMigrationOverride');
      var migrationStatus = activity.get('migrationStatus');
      var checkV4PublishValidations = isMigrateOverrideEnabled || !!migrationStatus && (migrationStatus.get('content') && migrationStatus.get('logistics') || migrationStatus.get('price'));
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var v3Url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/activities/' + activityId + '/publish_validation';
        var v3PublishValidation = _this.ajaxGetWithoutCache(v3Url);
        var promises = [v3PublishValidation];
        if (checkV4PublishValidations) {
          var v4Url = _environment.default.api.hostV4 + '/supply/' + activityId + '/publish-validation';
          var v4PublishValidation = _this.ajaxGetWithoutCache(v4Url);
          promises.push(v4PublishValidation);
        }
        Ember.RSVP.all(promises).then(function (result) {
          var _result = _slicedToArray(result, 2),
              v3Response = _result[0],
              v4Response = _result[1];

          var response = Object.assign({}, v3Response);
          if (v4Response) {
            if (migrationStatus.get('content') && migrationStatus.get('logistics')) {
              Object.assign(response, {
                hasDescription: v4Response.hasDescription,
                hasHighlights: v4Response.hasHighlights,
                hasInclusions: v4Response.hasInclusions,
                hasKnowBeforeYouGo: v4Response.hasKnowBeforeYouGo,
                hasKnowBeforeYouBook: v4Response.hasKnowBeforeYouBook,
                hasPhoto: v4Response.hasPhoto,
                hasLocation: v4Response.hasLocation,
                hasContactInformation: v4Response.hasContactInformation,
                hasRedemptionInstructions: v4Response.hasRedemptionInstructions,
                hasCategories: v4Response.hasCategories,
                hasHealthAndSafetyInfo: v4Response.hasHealthAndSafetyInfo
              });
            }
            if (migrationStatus.get('price')) {
              Object.assign(response, {
                hasPricing: v4Response.hasPriceGroup
              });
            }
          }
          resolve(response);
        }).catch(function (reason) {
          reject(reason);
        });
      });
    },
    ajaxGetWithoutCache: function ajaxGetWithoutCache(url) {
      return this.get('ajaxWrapper').ajaxGet(url, null, {
        cache: false,
        xhrFields: {
          withCredentials: true
        }
      });
    }
  });
});