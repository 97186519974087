define('lxso/helpers/contains-whitelist-gt-supplierbranch', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.containsWhitelistGtSupplierBranch = containsWhitelistGtSupplierBranch;
  function containsWhitelistGtSupplierBranch(args) {
    return _environment.default.whiteListedGTSupplierBranch.contains(args[0]);
  }

  exports.default = Ember.Helper.helper(containsWhitelistGtSupplierBranch);
});