define('lxso/utils/change-history-details-display-modes/v4-offer-group', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lodash', 'lxso/constants/option-group'], function (exports, _generic, _lodash, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'v4-offer-group',
    diff: function diff(oldValue, newValue, _ref) {
      var i18n = _ref.i18n;

      var oldData = oldValue ? JSON.parse(oldValue) : {};
      var newData = newValue ? JSON.parse(newValue) : {};

      function difference(displayMode, value) {
        var newValue = value(newData);
        var oldValue = value(oldData);

        return !(_lodash.default.isEqual(oldValue, newValue) || !newValue && !oldValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }

      function translate(path, key) {
        return key && i18n.t(path + '.' + key).string;
      }

      function convertBoolean(data) {
        if (data === true) {
          return translate('common.words', 'enabled');
        } else if (data === false) {
          return translate('common.words', 'disabled');
        } else {
          return null;
        }
      }

      function convertMinutesToHours(data) {
        if (data && parseInt(data) > 0) {
          return parseInt(data) / 60;
        } else {
          return null;
        }
      }

      function getDuration(durationInMinutes) {
        var scheduleType = newData ? newData.scheduleType : null;
        if (!durationInMinutes || !scheduleType) {
          return null;
        } else if (scheduleType === _optionGroup.SCHEDULE_TYPES.START || scheduleType === _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION) {
          var durationHoursPart = Math.floor(durationInMinutes / 60);
          var durationMinutesPart = durationInMinutes % 60;
          return durationHoursPart + ' Hours ' + durationMinutesPart + ' Minutes';
        } else if (scheduleType === _optionGroup.SCHEDULE_TYPES.VALID_HOURS) {
          var _durationHoursPart = Math.floor(durationInMinutes / 60);
          return _durationHoursPart + ' Hours';
        } else if (scheduleType === _optionGroup.SCHEDULE_TYPES.VALID_DAYS || scheduleType === _optionGroup.SCHEDULE_TYPES.MULTI_DAY) {
          var durationDaysPart = Math.floor(durationInMinutes / (60 * 24));
          return durationDaysPart + ' Hours';
        }
      }

      return {
        name: difference(_generic.default, function (data) {
          return data.offerGroupName;
        }),
        status: difference(_generic.default, function (data) {
          if (data.status) {
            return data.status.toLowerCase().capitalize();
          } else {
            return null;
          }
        }),
        description: difference(_generic.default, function (data) {
          return data.description;
        }),
        scheduleTypeKey: difference(_generic.default, function (data) {
          return data.scheduleTypeKey;
        }),
        scheduleType: difference(_generic.default, function (data) {
          return translate('pages.optionGroup.optionGroupSection.details.scheduleType.label', data.scheduleType);
        }),
        validityDays: difference(_generic.default, function (data) {
          return data.validityDays;
        }),
        availabilityType: difference(_generic.default, function (data) {
          return translate('pages.optionGroup.optionGroupSection.details.ticketAvailabilityType.label', data.availabilityType);
        }),
        mealType: difference(_generic.default, function (data) {
          return translate('pages.optionGroup.optionGroupSection.details.addOns.label', data.mealType);
        }),
        pricingIngestionEnabled: difference(_generic.default, function (data) {
          return convertBoolean(data.pricingIngestionEnabled);
        }),
        pickupEnabled: difference(_generic.default, function (data) {
          return convertBoolean(data.pickupEnabled);
        }),
        pricesByVolume: difference(_generic.default, function (data) {
          return convertBoolean(data.pricesByVolume);
        }),
        duration: difference(_generic.default, function (data) {
          return getDuration(data.durationInMinutes);
        }),
        cancellationCutoffInHours: difference(_generic.default, function (data) {
          return data.cancellationCutoffInHours;
        }),
        bookingCutoffInHours: difference(_generic.default, function (data) {
          return convertMinutesToHours(data.bookingCutoffInMinutes);
        })
      };
    }
  };
});