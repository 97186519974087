define('lxso/routes/new-supplier-branch', ['exports', 'lxso/config/environment', 'lxso/utils/form-validator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-supplier-branch', 'lxso/mixins/require-permission', 'lxso/mixins/feature-flag', 'lxso/mixins/enum-getter'], function (exports, _environment, _formValidator, _authenticatedRouteMixin, _requireSupplierBranch, _requirePermission, _featureFlag, _enumGetter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, _requirePermission.default, _enumGetter.default, {
    requiredPermission: 'V3AccountSettings',
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    session: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    permissions: Ember.inject.service(),
    staticData: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('permissions').checkEnabled('V3NewSupplierBranch')) {
        var supplierBranchId = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
        this.replaceWith('products', supplierBranchId);
      }
    },
    model: function model() {
      var supplierId = this.get('session.data.authenticated.selectedBranch.supplier_id');
      var url = _environment.default.api.host + '/api/v1/enums/supplier_currencies';
      var promiseHash = {
        newSupplierBranch: this.store.createRecord('supplier-branch', {
          supplier_id: supplierId
        }),
        currencies: this.get('ajaxWrapper').ajaxGet(url).then(function (result) {
          return result.data;
        })
      };
      if (supplierId) {
        promiseHash.supplier = this.store.findRecord('suppliers', supplierId);
      } else {
        promiseHash.supplier = {
          name: ''
        };
      }
      return new Ember.RSVP.hash(promiseHash);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      this.setupTimezone();
      this.setupCurrency(model.currencies);
      this.controller.set('errors', []);
      this.controller.set('saving', false);
      this.initSupplierSearchTypeAhead(controller);
      this.controller.set('supplier', model.supplier);
      this.controller.set('newSupplierBranch', model.newSupplierBranch);
      this.controller.set('newSupplierBranch.supplier_branch_type', 'Activity');
    },
    setupTimezone: function setupTimezone() {
      var timezoneOptions = [];
      var timezoneValues = [];

      this.get('staticData').getTimeZones().forEach(function (time) {
        timezoneOptions.push(time.tz + ' ' + time.label);
        timezoneValues.push(time.tz);
      });

      this.controller.set('timezoneOptions', timezoneOptions);
      this.controller.set('timezoneValues', timezoneValues);
    },
    setupCurrency: function setupCurrency(currencies) {
      var currencyOptions = [];
      var currencyValues = [];

      currencies.forEach(function (currency) {
        currencyOptions.push(currency.value + ' (' + currency.display + ')');
        currencyValues.push(currency.value);
      });

      this.controller.set('currencyOptions', currencyOptions);
      this.controller.set('currencyValues', currencyValues);
    },
    transformSupplierData: function transformSupplierData(inputData) {
      var outputData = {
        data: []
      };
      inputData.forEach(function (item) {
        if (item.status.toUpperCase() === 'ACTIVE' || item.status.toUpperCase() === 'INACTIVE') {
          outputData.data.push(item);
        }
      });
      return outputData;
    },
    initSupplierSearchTypeAhead: function initSupplierSearchTypeAhead(controller) {
      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
      var url = domain + '/suppliers/search?name=';
      controller.set('supplierSource', this.getEnumFunctionWithTransform(url, this.transformSupplierData));
    },

    actions: {
      supplierSelected: function supplierSelected(supplier) {
        if (supplier && supplier.supplier_id) {
          this.controller.set('supplier', supplier);
          this.controller.set('newSupplierBranch.supplier_id', supplier.supplier_id);
        } else {
          this.controller.set('supplier', {
            name: ''
          });
          this.controller.set('newSupplierBranch.supplier_id', null);
        }
      },
      supplierNameChange: function supplierNameChange(supplierSearch) {
        this.controller.set('supplier.supplier_id', null);
        this.controller.set('supplier.status', null);
        this.controller.set('supplier.vendor_id', null);
        this.controller.set('newSupplierBranch.supplier_id', null);
      },
      selectTimezone: function selectTimezone(value) {
        this.controller.set('newSupplierBranch.default_timezone', value);
      },
      selectCurrency: function selectCurrency(value) {
        this.controller.set('newSupplierBranch.currency_type', value);
      },
      setSupplierBranchType: function setSupplierBranchType(type) {
        this.controller.set('newSupplierBranch.supplier_branch_type', type);
      },
      saveNewSupplierBranch: function saveNewSupplierBranch() {
        var _this = this;

        var newSupplierBranch = this.controller.get('newSupplierBranch');
        this.controller.set('saving', true);
        (0, _formValidator.validate)({ model: newSupplierBranch, attributes: ['supplier_branch_name', 'default_timezone', 'currency_type', 'supplier_branch_type', 'affiliate_id', 'salesforce_code', 'supplier_id'] }).then(function () {
          var supplierId = _this.controller.get('supplier.supplier_id');
          return newSupplierBranch.save({ adapterOptions: { supplierId: supplierId } });
        }).then(function () {
          _this.controller.set('lastSaved', 'newSupplierBranch');
          _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
          _this.transitionTo('account-settings');
        }).catch(function (errors) {
          _this.controller.set('errors', errors);
        }).finally(function () {
          return _this.controller.set('saving', false);
        });
      }
    }
  });
});