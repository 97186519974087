define('lxso/routes/redirect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      url: { refreshModel: false }
    },

    model: function model(params, transition) {
      window.location.href = '/#/' + transition.queryParams.url;
    }
  });
});