define('lxso/models/operator-cancellation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    groupSizeNotMet: _emberData.default.attr('boolean', { defaultValue: false }),
    inclementWeather: _emberData.default.attr('boolean', { defaultValue: false }),
    optionalContent: _emberData.default.attr('string', { defaultValue: '' }),

    isOptionalContent: Ember.computed('optionalContent', function () {
      return this.get('optionalContent').length > 0;
    })
  });
});