define('lxso/services/auth-util', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    epcLogout: function epcLogout() {
      var LXPCLogoutPage = '' + window.location.origin + window.location.pathname + _environment.default.epc.LXPCLogoutPage;
      var redirectUrl = '' + _environment.default.epc.baseUrl + _environment.default.epc.endSessionUrl + LXPCLogoutPage;
      window.location.replace(redirectUrl);
    }
  });
});