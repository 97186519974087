define('lxso/models/activity/attributes', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation', 'lxso/config/environment'], function (exports, _emberData, _emberDataModelFragments, _validation, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    user_experience_group: (0, _validation.validator)('array-length', {
      gte: 0,
      lte: _environment.default.activities.category.limit,
      descriptionKey: 'fieldNames.activity.attributes.numberOfSelectedCategories'
    }),
    suggested_rank: (0, _validation.validator)('number', {
      allowString: true,
      gte: 0,
      integer: true,
      disabled: Ember.computed.not('model.suggested_rank'),
      descriptionKey: 'errors.descriptionKeys.suggestedRank',
      messageKey: 'errors.greaterThanOrEqualTo'
    }),
    vehicle_type: {
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        messageKey: 'errors.customMessages.gtSettings.vehicleType.selectOne'
      })]
    },
    baggage_capacity: (0, _validation.validator)('number', {
      allowString: true,
      gte: 0,
      integer: true,
      messageKey: 'errors.blank'
    }),
    passenger_capacity: (0, _validation.validator)('number', {
      allowString: true,
      gte: 0,
      integer: true,
      messageKey: 'errors.blank'
    })
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    suggested_rank: _emberData.default.attr('string', { defaultValue: '99' }),
    passenger_capacity: _emberData.default.attr('number', { defaultValue: 0 }),
    user_experience_group: _emberData.default.attr('array', { defaultValue: function defaultValue() {
        return ['Tours & Sightseeing'];
      } }),
    baggage_capacity: _emberData.default.attr('number', { defaultValue: 0 }),
    vehicle_type: _emberData.default.attr('string', { defaultValue: '' }),
    redemption_type: _emberData.default.attr('string', { defaultValue: 'Voucherless' })
  });
});