define('lxso/services/v4-catalog-service', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajaxWrapper: Ember.inject.service(),

    updateOperatorCancellation: function updateOperatorCancellation(activityId, requestBody) {
      var url = _environment.default.api.hostV4 + '/activity/' + activityId + '/operator-cancellation';
      var updateActivityContent = getActivityContent(requestBody);
      var body = Object.assign({}, requestBody, {
        updateActivityContent: updateActivityContent
      });
      return this.get('ajaxWrapper').ajaxPut(url, body);
    },
    updateActivityTitle: function updateActivityTitle(activityId, requestBody) {
      var url = _environment.default.api.hostV4 + '/activity/' + activityId + '/activity-title';
      var body = {
        activityTitle: requestBody.get('long_title')
      };
      return this.get('ajaxWrapper').ajaxPut(url, body);
    },
    updateRedemptionType: function updateRedemptionType(activityId, requestBody) {
      var url = _environment.default.api.hostV4 + '/activity/' + activityId + '/redemption-type';
      return this.get('ajaxWrapper').ajaxPut(url, requestBody);
    }
  });


  function getActivityContent(requestBody) {
    var groupSizeAndWeatherText = void 0;
    if (requestBody.groupSizeNotMet && requestBody.inclementWeather) {
      groupSizeAndWeatherText = 'Product may be cancelled or rescheduled due to inclement weather or ' + 'if the minimum group size is not met.';
    } else if (requestBody.groupSizeNotMet) {
      groupSizeAndWeatherText = 'Product may be cancelled or rescheduled if the minimum group size is not met.';
    } else if (requestBody.inclementWeather) {
      groupSizeAndWeatherText = 'Product may be cancelled or rescheduled due to inclement weather.';
    }

    if (requestBody.optionalContent) {
      return groupSizeAndWeatherText + ' ' + requestBody.optionalContent;
    } else {
      return groupSizeAndWeatherText;
    }
  }
});