define('lxso/mixins/underscored-json-attributes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    keyForAttribute: function keyForAttribute(attr, method) {
      return Ember.String.underscore(attr);
    }
  });
});