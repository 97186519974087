define('lxso/mixins/hotel-zone/deactivate-hotel-zone', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    showConfirmationPopUp: function showConfirmationPopUp() {
      return this.controller.get('supplierBranch').reload();
    },


    actions: {
      setDeactivateZone: function setDeactivateZone(zoneId) {
        this.controller.set('zoneIdToDeactivate', zoneId);
        this.controller.set('previewModalOpen', true);
      },
      closeModal: function closeModal() {
        this.controller.set('zoneIdToDeactivate', undefined);
        this.controller.set('previewModalOpen', false);
      },
      deactivateZoneConfirmed: function deactivateZoneConfirmed() {
        var _this = this;

        if (this.controller.get('zoneIdToDeactivate')) {
          var editingZone = this.controller.get('hotelZones').findBy('id', this.controller.get('zoneIdToDeactivate'));
          editingZone.set('status', 'INACTIVE');

          this.controller.set('isProcessingConfirmAction', true);
          editingZone.save({ adapterOptions: { dataChange: 'status' } }).then(function () {
            _this.send('closeModal');
            _this.controller.set('deactivationErrors', undefined);
          }).finally(function () {
            _this.controller.set('isProcessingConfirmAction', false);
          }).catch(function (errorDeactivate) {
            _this.controller.set('deactivationErrors', errorDeactivate);
          });
        }
      }
    }
  });
});