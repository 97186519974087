define('lxso/adapters/offer-price-availability', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var activityId = snapshot.record.activityId;
      var url = this.host + '/' + this.namespace + '/offerpriceavailability/activities/' + activityId;

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      return this.ajax(url, 'POST', { data: data });
    },
    query: function query(store, type, _query) {
      var data = [];
      var url = this.host + '/' + this.namespace + '/offerpriceavailability/option/' + _query;
      return this.ajax(url, 'GET', { data: data });
    },
    queryRecord: function queryRecord(store, type, query) {
      var data = [];
      var url = this.host + '/' + this.namespace + '/offerpriceavailability/option/' + query;
      return this.ajax(url, 'GET', { data: data });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      // const data = {};
      // const serializer = store.serializerFor(type.modelName);
      var url = this.host + '/' + this.namespace + '/offerpriceavailability/' + snapshot.id;

      // serializer.serializeIntoHash(data, type, snapshot, {includeId: true});
      return this.ajax(url, 'DELETE');
    }
  });
});