define('lxso/helpers/get-status-to-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getStatusToDisplay = getStatusToDisplay;
  function getStatusToDisplay(params) {
    var statusValue = params[0];

    var statusMap = new Map([['active', 'Active'], ['inactive', 'Inactive'], ['deactivated', 'Deactivated'], ['suspended', 'Suspended - Mapping'], ['suspendedexpedia', 'Suspended - Expedia'], ['suspendedpaused', 'Suspended - Paused']]);

    var statusToDisplay = statusMap.get(statusValue.toLowerCase());
    return statusToDisplay ? statusToDisplay : statusValue;
  }

  exports.default = Ember.Helper.helper(getStatusToDisplay);
});