define('lxso/serializers/hotel-zone', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.hotels.length > 0) {
        for (var i = 0; i < payload.hotels.length; i++) {
          payload.hotels[i].hotelId = payload.hotels[i].id;
        }
      }
      return this._super.apply(this, arguments);
    },
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var hotelZone = store.peekRecord('hotel-zone', id).toJSON();
      hotelZone.id = id;

      return this._super(store, primaryModelClass, hotelZone, id, requestType);
    }
  });
});