define('lxso/models/activity', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'ember-api-actions', 'lxso/utils/validation'], function (exports, _emberData, _attributes, _emberApiActions, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    bookingCutOff: (0, _validation.validator)('number', {
      integer: true,
      allowString: true,
      gte: 0
    }),
    customer_support_ids: (0, _validation.validator)('array-length', {
      gte: 1
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    basic: (0, _attributes.fragment)('activity/basic'),
    highlights: (0, _attributes.fragment)('activity/highlights'),
    operational: (0, _attributes.fragment)('activity/operational'),
    policy: (0, _attributes.fragment)('activity/policy'),
    supplier_branch: (0, _attributes.fragment)('activity/supplier-branch'),
    things: (0, _attributes.fragment)('activity/things'),
    activity_location: (0, _attributes.fragment)('activity/location'),
    redemption_address: (0, _attributes.fragment)('activity/redemption-address'),
    assets: _emberData.default.hasMany('asset', { async: true }),
    supplier_branch_full: _emberData.default.belongsTo('supplier-branch', { async: true }),
    attributes: (0, _attributes.fragment)('activity/attributes'),
    translations: (0, _attributes.fragmentArray)('activity/translation'),
    customer_support_ids: _emberData.default.attr('array', { defaultValue: function defaultValue() {
        return [];
      } }),
    offers: _emberData.default.hasMany('activity/v2offer'),
    version: _emberData.default.attr(),
    language: _emberData.default.attr(),
    ofid: _emberData.default.attr(),
    ticketTypes: _emberData.default.hasMany('ticket-type'),
    //offer_price_availabilities: DS.hasMany('offer-price-availability', {async:true}),
    ticket_types: (0, _attributes.fragmentArray)('ticket-types'),
    price_templates: _emberData.default.hasMany('price-template', { async: true }),
    bookingCutOff: _emberData.default.attr('number', { defaultValue: 0 }),
    isActivityPublished: _emberData.default.attr('boolean'),
    margin_overrides: _emberData.default.belongsTo('supplier-branch/margin-overrides'),
    isConnectivityEnabled: _emberData.default.attr('boolean'),
    thirdPartySupply: _emberData.default.attr('boolean'),
    migrationStatus: (0, _attributes.fragment)('activity/migration-status'),
    termsAndConditions: (0, _attributes.fragment)('activity/terms-and-conditions'),
    operatorCancellation: (0, _attributes.fragment)('activity/operator-cancellation'),
    isContentAndLogisticsMigratedToV4: _emberData.default.attr('boolean'),

    /**
     * activity_type maps to operational.internal_category
     * A type of "Tours" will save an operational.internal_category of "Other Tours"
     * All other values will save an operational.internal_category of "Other Active and Adventure"
     */
    activity_type: Ember.computed('operational.internal_category', {
      get: function get() {
        switch (this.get('operational.internal_category')) {
          case undefined:
            return undefined;
          case 'Other Tours':
            return 'Tours';
          default:
            return 'Other Activities';
        }
      },
      set: function set(key, value) {
        var category = void 0;

        switch (value) {
          case undefined:
            category = undefined;
            break;
          case 'Tours':
            category = 'Other Tours';
            break;
          default:
            category = 'Other Active and Adventure';
        }

        this.set('operational.internal_category', category);
        return category;
      }
    }),

    createNew: (0, _emberApiActions.instanceOp)({
      path: '#',
      type: 'post'
    }),

    updateBasic: (0, _emberApiActions.instanceOp)({
      path: 'basic',
      type: 'put'
    }),

    updateHighlights: (0, _emberApiActions.instanceOp)({
      path: 'highlights',
      type: 'put'
    }),

    updateThings: (0, _emberApiActions.instanceOp)({
      path: 'things',
      type: 'put'
    }),

    updatePolicy: (0, _emberApiActions.instanceOp)({
      path: 'policy',
      type: 'put'
    }),

    updateLocation: (0, _emberApiActions.instanceOp)({
      path: 'address',
      type: 'put'
    }),

    updateRedemptionAddresses: (0, _emberApiActions.instanceOp)({
      path: 'redemption',
      type: 'put'
    }),

    updateAttributes: (0, _emberApiActions.instanceOp)({
      path: 'attributes',
      type: 'put'
    }),

    updateCustomerService: (0, _emberApiActions.instanceOp)({
      path: 'support',
      type: 'put'
    }),

    updateBookingCutOff: (0, _emberApiActions.instanceOp)({
      path: 'bookingcutoff',
      type: 'put'
    }),

    updateOperational: (0, _emberApiActions.instanceOp)({
      path: 'operational',
      type: 'put'
    }),

    getTranslations: (0, _emberApiActions.instanceOp)({
      path: 'translation_languages',
      type: 'get'
    }),

    updateTranslations: (0, _emberApiActions.instanceOp)({
      path: 'translation_languages',
      type: 'put'
    }),

    createTeaShopId: (0, _emberApiActions.instanceOp)({
      path: 'ofid',
      type: 'post'
    })

    /* commented out because somehow Option gets by without this
    getTicketTypes: instanceOp({
      path: 'ticket_types', //this should be an underscore because it's api path
      type: 'get'
    })
    */

  });
});