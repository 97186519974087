define('lxso/utils/markdown-stripper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = stripMarkdown;
  /* globals s_exp */

  function stripMarkdown(string) {
    var markdownRegex = /(\*\*)|(\*\s\s\s+)|_/g;

    var strippedString = string.replace(markdownRegex, '');

    return strippedString;
  }
});