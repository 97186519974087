define('lxso/models/platform-url-configuration', ['exports', 'ember-data', 'lxso/utils/validation'], function (exports, _emberData, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    baseUrl: {
      descriptionKey: 'pages.platformSettings.baseUrl',
      validators: [(0, _validation.validator)('format', {
        type: 'url'
      }), (0, _validation.validator)('presence', true)]
    },
    checkOfferDateAvailabilityRelativePath: (0, _validation.validator)('platform-url-format', {
      descriptionKey: 'pages.platformSettings.checkOfferDate'
    }),
    commitSaleRelativePath: (0, _validation.validator)('platform-url-format', {
      descriptionKey: 'pages.platformSettings.commitSale'
    }),
    commitSaleCancellationRelativePath: (0, _validation.validator)('platform-url-format', {
      descriptionKey: 'pages.platformSettings.commitSaleCancellation'
    }),
    createSaleRelativePath: (0, _validation.validator)('platform-url-format', {
      descriptionKey: 'pages.platformSettings.createSale'
    }),
    createSaleCancellationRelativePath: (0, _validation.validator)('platform-url-format', {
      descriptionKey: 'pages.platformSettings.createSaleCancellation'
    }),
    releaseSaleRelativePath: (0, _validation.validator)('platform-url-format', {
      descriptionKey: 'pages.platformSettings.releaseSale'
    }),
    releaseSaleCancellationRelativePath: (0, _validation.validator)('platform-url-format', {
      descriptionKey: 'pages.platformSettings.releaseSaleCancellation'
    }),
    getSaleRelativePath: (0, _validation.validator)('platform-url-format', {
      descriptionKey: 'pages.platformSettings.getSale'
    }),
    releaseFulfillmentRelativePath: (0, _validation.validator)('platform-url-format', {
      descriptionKey: 'pages.platformSettings.releaseFulfillment'
    }),
    pricingIngestionRelativePath: (0, _validation.validator)('platform-url-format', {
      descriptionKey: 'pages.platformSettings.ingestPricing'
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    platform: _emberData.default.belongsTo('platform', {
      inverse: null
    }),
    environment: _emberData.default.attr('string'),
    baseUrl: _emberData.default.attr('string', { defaultValue: '' }),
    checkOfferDateAvailabilityRelativePath: _emberData.default.attr('string', { defaultValue: '/' }),
    createSaleRelativePath: _emberData.default.attr('string', { defaultValue: '/' }),
    commitSaleRelativePath: _emberData.default.attr('string', { defaultValue: '/' }),
    releaseSaleRelativePath: _emberData.default.attr('string', { defaultValue: '/' }),
    createSaleCancellationRelativePath: _emberData.default.attr('string', { defaultValue: '/' }),
    commitSaleCancellationRelativePath: _emberData.default.attr('string', { defaultValue: '/' }),
    releaseSaleCancellationRelativePath: _emberData.default.attr('string', { defaultValue: '/' }),
    getSaleRelativePath: _emberData.default.attr('string', { defaultValue: '/' }),
    releaseFulfillmentRelativePath: _emberData.default.attr('string', { defaultValue: '/' }),
    pricingIngestionRelativePath: _emberData.default.attr('string', { defaultValue: '/' })
  });
});