define('lxso/utils/change-history-details-display-modes/video', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lodash'], function (exports, _generic, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'video',
    diff: function diff(oldValue, newValue) {
      'use strict';

      var oldVideo = oldValue ? JSON.parse(oldValue) : {};
      var newVideo = newValue ? JSON.parse(newValue) : {};

      function difference(displayMode, value) {
        var oldValue = value(oldVideo);
        var newValue = value(newVideo);

        return !_lodash.default.isEqual(oldValue, newValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }
      var differences = {
        videoId: difference(_generic.default, function (data) {
          return data.videoId;
        }),
        url: difference(_generic.default, function (data) {
          return data.url;
        }),
        status: difference(_generic.default, function (data) {
          return data.status;
        }),
        rejectReason: difference(_generic.default, function (data) {
          return data.rejectReason;
        })
      };
      return differences;
    }
  };
});