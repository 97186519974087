define('lxso/components/platform-settings-url/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    removeCustomUrls: function removeCustomUrls() {
      this.set('urls.checkOfferDateAvailabilityRelativePath', '');
      this.set('urls.commitSaleRelativePath', '');
      this.set('urls.commitSaleCancellationRelativePath', '');
      this.set('urls.createSaleRelativePath', '');
      this.set('urls.getSaleRelativePath', '');
      this.set('urls.createSaleCancellationRelativePath', '');
      this.set('urls.releaseSaleRelativePath', '');
      this.set('urls.releaseSaleCancellationRelativePath', '');
      this.set('urls.releaseFulfillmentRelativePath', '');
      this.set('urls.pricingIngestionRelativePath', '');
    },
    validateIfInvalid: function validateIfInvalid() {
      if (this.get('errors')) {
        this.sendAction('validatePlatformUrl');
      }
    },

    actions: {
      editSection: function editSection() {
        if (!this.get('isRedemptionEnabled')) {
          this.set('urls.getSaleRelativePath', '');
        }
        this.sendAction('editSection');
      },
      cancelEditUrlForm: function cancelEditUrlForm() {
        this.sendAction('cancelEditUrlForm');
      },
      customUrlCheckboxValueChange: function customUrlCheckboxValueChange() {
        this.toggleProperty('isCustomUrlChecked');
        this.send('setFormDirty', true);
      },
      setFormDirty: function setFormDirty(isDirty) {
        this.set('isFormDirty', isDirty);
      },
      onRelativePathChange: function onRelativePathChange() {
        this.send('setFormDirty', true);
        this.validateIfInvalid();
      },
      saveUrlForm: function saveUrlForm() {
        var checked = this.get('isCustomUrlChecked');
        if (!checked) {
          this.removeCustomUrls();
        }
        this.sendAction('save', this.get('urls'));
      }
    }
  });
});