define('lxso/components/option-settings-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    sortedOptions: Ember.computed('options', 'sortParam', 'sortAscending', function () {
      var sortedOptions = this.get('options').sortBy(this.get('sortParam'));
      if (this.get('sortAscending')) {
        return sortedOptions;
      } else {
        return sortedOptions.reverse();
      }
    }),
    sortParam: 'title',
    sortAscending: true,
    sortType: 1,
    actions: {
      retryTshopId: function retryTshopId(option) {
        this.sendAction('retryOptionTshopId', option);
      },
      setSortParams: function setSortParams(sortParam, sortAscending) {
        this.set('sortParam', sortParam);
        this.set('sortAscending', sortAscending);
        if (sortParam === 'title') {
          if (sortAscending) {
            this.set('sortType', 1);
          } else {
            this.set('sortType', 2);
          }
        } else {
          if (sortAscending) {
            this.set('sortType', 3);
          } else {
            this.set('sortType', 4);
          }
        }
      }
    }
  });
});