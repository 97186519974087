define('lxso/utils/change-history-details-display-modes/partner-ticket-type-id', ['exports', 'lxso/utils/change-history-details-display-modes/generic'], function (exports, _generic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'partner-ticket-type-id',
    diff: function diff(oldValue, newValue) {
      var oldData = oldValue ? JSON.parse(oldValue) : {};
      var newData = newValue ? JSON.parse(newValue) : {};

      function difference(displayMode, areDisplayable, value) {
        var newValue = value(newData);
        var oldValue = value(oldData);

        return areDisplayable(oldValue, newValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }

      return {
        travelStartDate: difference(_generic.default, present, function (data) {
          return data.travelStartDate;
        }),
        travelEndDate: difference(_generic.default, present, function (data) {
          return data.travelEndDate;
        }),
        minimumTravelers: difference(_generic.default, present, function (data) {
          return data.minimumTravelers;
        }),
        maximumTravelers: difference(_generic.default, present, function (data) {
          return data.maximumTravelers;
        }),
        PartnerTicketTypeID: difference(_generic.default, present, function (data) {
          return data.PartnerTicketTypeID;
        })
      };
    }
  };


  function present(oldValue, newValue) {
    return Ember.isPresent(oldValue) || Ember.isPresent(newValue);
  }
});