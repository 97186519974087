define('lxso/mixins/require-permission', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    permissions: Ember.inject.service('permissions'),

    beforeModel: function beforeModel(transition) {
      if (Ember.isNone(this.requiredPermission)) {
        throw new Ember.Error('The requiredPermission property must be set');
      }

      var superResult = this._super.apply(this, arguments);
      var permissions = this.get('permissions');

      var hasPermission = permissions.checkEnabled(this.requiredPermission);
      if (!hasPermission && !transition.isAborted) {
        transition.abort();
        var isAuthenticated = permissions.checkEnabled('isAuthenticated');
        if (isAuthenticated) {
          this.intermediateTransitionTo('error', new Ember.Error('Permission is denied'));
        } else {
          this.get('session').invalidate();
        }
      }
      return new Ember.RSVP.resolve(superResult);
    }
  });
});