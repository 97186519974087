define('lxso/serializers/asset', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    isNewSerializerAPI: true,
    primaryKey: 'fileId',

    // Normalize array responses by using the assets property in the returned object
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, payload.assets, id, requestType);
    },


    // This is for when we query for a single record; the hero image, for example
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, payload.assets[0], id, requestType);
    }
  });
});