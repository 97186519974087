define('lxso/utils/auto-renew-rate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var autoRenewRateTranslations = new Map([[true, 'Enabled'], [false, 'Disabled']]);

  function setupAutoRenewRateDropdown(context) {
    context.set('autoRenewRateTypeOptions', Array.from(autoRenewRateTranslations.values()));
    context.set('autoRenewRateTypeValues', Array.from(autoRenewRateTranslations.keys()));
  }

  function getAutoRateRenewLabel(isAutoRenewRateEnabled) {
    return autoRenewRateTranslations.get(isAutoRenewRateEnabled);
  }

  exports.setupAutoRenewRateDropdown = setupAutoRenewRateDropdown;
  exports.getAutoRateRenewLabel = getAutoRateRenewLabel;
});