define('lxso/models/fragments/offer-attributes', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var disableTimeValidations = Ember.computed('model.scheduleType', function () {
    return this.get('model.scheduleType') === 'Hours of Operation' || this.get('model.scheduleType') === 'Valid Days' || this.get('model.scheduleType') === 'Valid Hours';
  });

  var Validations = (0, _validation.build)({
    scheduleType: {
      descriptionKey: 'pages.offer.labels.schedule',
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        messageKey: 'errors.customMessages.offers.noSelectionMade'
      })]
    },
    startTime: {
      descriptionKey: 'pages.offer.labels.start',
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        disabled: disableTimeValidations
      }), (0, _validation.validator)('time-format', {
        showSuggestions: true,
        disabled: disableTimeValidations
      })]
    },
    endTime: {
      descriptionKey: 'pages.offer.labels.end',
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        disabled: disableTimeValidations
      }), (0, _validation.validator)('time-format', {
        showSuggestions: true,
        disabled: disableTimeValidations
      })]
    },
    mealType: {
      descriptionKey: 'pages.offer.labels.meal',
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        messageKey: 'errors.customMessages.offers.noSelectionMade'
      })]
    }
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    i18n: Ember.inject.service(),
    scheduleType: _emberData.default.attr('string', { defaultValue: 'Start' }),
    startTime: _emberData.default.attr('time'),
    endTime: _emberData.default.attr('time'),
    mealType: _emberData.default.attr('string'),
    isPriceByVolume: _emberData.default.attr('boolean', { defaultValue: false }),
    isHotelPickupEnabled: _emberData.default.attr('boolean', { defaultValue: false }),
    isEligibleInPrebookPaths: _emberData.default.attr('boolean', { defaultValue: true }),
    isPricingIngestion: _emberData.default.attr('boolean', { defaultValue: false }),
    languageInfo: (0, _attributes.fragment)('fragments/languageInfo'),
    usageDays: _emberData.default.attr('number')
  });
});