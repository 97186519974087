define('lxso/models/enums/language-domain', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    language_id: _emberData.default.attr('number'),
    label: _emberData.default.attr('string')
  });
});