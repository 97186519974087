define('lxso/components/on-off-switch/component', ['exports', 'lxso/mixins/confirmation-modal'], function (exports, _confirmationModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_confirmationModal.default, {
    i18n: Ember.inject.service(),
    dateFormat: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var inputId = ['switch', this.get('idx'), this.get('date')].filter(function (item) {
        return Ember.isPresent(item);
      }).join('-');
      this.set('inputId', inputId);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var isStopSell = this.updateIsStopSell(this.get('model'));
      this.set('toggleValue', !isStopSell);
    },
    updateIsStopSell: function updateIsStopSell(model) {
      var isStopSell = true;
      if (model.get('activities')) {
        model.get('activities').forEach(function (activity) {
          return activity.get('offers').forEach(function (offer) {
            if (!offer.data.isStopSellOnDate) {
              isStopSell = false;
            }
          });
        });
      } else if (model.get('offers')) {
        model.get('offers').forEach(function (offer) {
          if (!offer.data.isStopSellOnDate) {
            isStopSell = false;
          }
        });
      } else {
        if (!model.data.isStopSellOnDate) {
          isStopSell = false;
        }
      }
      return isStopSell;
    },
    message: function message() {
      'use strict';

      var apiFormat = this.get('dateFormat').API_DATE_FORMAT;
      var displayFormat = this.get('dateFormat').LONG_USA_FORMAT;
      var dateTime = this.get('model.date') || this.get('date');
      var date = moment(dateTime, apiFormat).format(displayFormat);
      var scope = this.get('scope') === 'Offer' ? 'Option' : 'Product';
      return this.get('i18n').t('components.onOffSwitch.confirm', { date: date, scope: scope });
    },


    actions: {
      updateToggle: function updateToggle() {
        if (this.get('toggleValue')) {
          return this.confirm(this.message());
        }
        this.send('confirmed');
      },
      cancelled: function cancelled() {
        'use strict';

        Ember.$('#' + this.get('elementId') + ' input').prop('checked', true);
      },
      confirmed: function confirmed() {
        'use strict';

        var toggleValue = this.toggleProperty('toggleValue');
        this.sendAction('toggleFunction', this.get('model'), this.get('date'), toggleValue);
      }
    }
  });
});