define('lxso/constants/gt-categories', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var GT_CATEGORIES = {
    PRIVATE_TRANSFER: 'Private Transfer',
    SHARED_TRANSFER: 'Shared Transfer',
    MASS_TRANSPORTATION: 'Mass Transportation'
  };

  var GT_CATEGORY_LIST = [GT_CATEGORIES.PRIVATE_TRANSFER, GT_CATEGORIES.SHARED_TRANSFER, GT_CATEGORIES.MASS_TRANSPORTATION];

  exports.GT_CATEGORIES = GT_CATEGORIES;
  exports.GT_CATEGORY_LIST = GT_CATEGORY_LIST;
});