define('lxso/helpers/is-gt-branch', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    session: Ember.inject.service(),

    compute: function compute() {
      var isGTBranch = this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
      return isGTBranch;
    }
  });
});