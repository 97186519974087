define('lxso/services/mediavault', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    featureFlags: Ember.inject.service(),

    /**
     * Return saved credentials or retrieve new ones
     *
     * @return {object}    Object containing apiKey and authToken
     */
    getCredentials: function getCredentials() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var headers = _this.get('headers');

        // If there's already Media Vault header credentials stored on the
        // adapter, return them
        if (headers && headers.authToken) {
          resolve(headers);
        } else {
          // Otherwise, call the auth_token endpoint to retrieve and set them
          _this.get('ajaxWrapper').ajaxGet(_environment.default.api.host + '/' + _environment.default.api.namespace + '/system/auth_token', null, {
            xhrFields: {
              withCredentials: true
            }
          }).then(function (result) {
            // Set the headers object and return it
            var headers = {
              authToken: result.authToken
            };
            _this.set('headers', headers);
            resolve(headers);
          }).catch(function (reason) {
            reject(reason);
          });
        }
      });
    },


    /**
     * Destroy stored headers
     */
    expireCredentials: function expireCredentials() {
      this.set('headers', undefined);
    },


    /**
     * Create a batch to upload photos to or return the stored batch
     *
     * @return {object} Batch ID object { batchId: hash }
     */
    createBatch: function createBatch() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var batch = _this2.get('batch');

        _this2.get('ajaxWrapper').ajaxPost(_environment.default.api.host + '/' + _environment.default.api.namespace + '/assets/batch', null, null, {
          headers: _this2.get('headers')
        }).then(function (batch) {
          // Set the batch object and return it
          _this2.set('batch', batch);
          resolve(batch);
        }).catch(function (reason) {
          reject(reason);
        });
      });
    },


    /**
     * Upload a file to an asset in Media Vault
     * File must be a Plupload file instance
     * @param  {class} file - Plupload file instance
     * @param  {model} file - Asset model instance
     * @return {promise}    - File upload promise
     */
    uploadFileToAsset: function uploadFileToAsset(file, asset, productId) {
      var fileRoute = _environment.default.api.host + '/api/v3/media/' + productId + '/image';
      file.uploader.bind('BeforeUpload', function (up, file) {
        up.settings.multipart_params = { 'tags': asset.get('tags').toString() };
      });
      return file.upload(fileRoute);
    },


    /**
     * Validate a file to be uploaded to Media Vault
     * File must be a Plupload file instance
     * @param  {class} file - Plupload file instance
     * @return {promise}    - Resolve or reject with errors
     */
    validateFile: function validateFile(file) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var errors = [];
        var fileSizeInMb = file.get('file.size') / 1000000;
        var regExpImageName = /^[A-Za-z0-9()!_.*' -]+$/;
        // If the file is too large, just return that error so we don't spend
        // time loading it
        if (fileSizeInMb > _environment.default.mvapi.maxImageSizeInMb) {
          var fileSize = Math.round(fileSizeInMb) + ' Mb';
          errors.pushObject(_this3.get('i18n').t('pages.photos.errors.fileSize', { fileSize: fileSize }));
          reject(errors);
        } else {
          var deferred = Ember.RSVP.defer();
          var image = new Image();

          image.onload = deferred.resolve;
          image.onerror = deferred.reject;
          image.src = file.get('base64');

          deferred.promise.then(function () {
            var minDimension = _environment.default.mvapi.minDimension;
            var aspectRatio = _environment.default.mvapi.imageAspectRatio.split(':');
            var currentImageRatio = image.width / image.height;
            var landscapeAspectRatio = aspectRatio[0] / aspectRatio[1];
            var portraitAspectRatio = aspectRatio[1] / aspectRatio[0];
            var isDimensionTooSmall = image.width < minDimension && image.height < minDimension;
            var invalidAspectRatio = currentImageRatio >= 1 && currentImageRatio > landscapeAspectRatio || currentImageRatio < 1 && currentImageRatio < portraitAspectRatio;
            var fileName = file.get('file.name');
            var isInvalidFileName = !regExpImageName.test(fileName);

            if (isDimensionTooSmall) {
              errors.pushObject(_this3.get('i18n').t('pages.photos.errors.dimension'));
            }

            if (invalidAspectRatio) {
              errors.pushObject(_this3.get('i18n').t('pages.photos.errors.aspectRatio'));
            }

            if (isInvalidFileName) {
              errors.pushObject(_this3.get('i18n').t('pages.photos.errors.fileName'));
            }

            image = null;

            if (errors.length) {
              reject(errors);
            } else {
              resolve();
            }
          }).catch(function (reason) {
            reject(reason);
          });
        }
      });
    },


    resolutions: {
      // Array indexes for urls for different image sizes
      W0072_X_H0041: 0,
      W0080_X_H0045: 1,
      W0160_X_H0090: 2,
      W0206_X_H0116: 3, // desktop should be 197_X_111
      W0295_X_H0166: 4, // mobile  should be 296_X_111
      W0350_X_H0197: 5,
      W0500_X_H0281: 6,
      W1000_X_H0563: 7,
      W1005_X_H0565: 8,
      W2560_X_H1440: 9
    },

    getProperImageUrl: function getProperImageUrl(asset, resolutionIndex) {
      // If not enough image urls are available, the last will be used.
      function selectUrlIndex() {
        if (asset.available) {
          var res = asset.available[resolutionIndex] ? resolutionIndex : asset.available.length - 1;
          return res;
        }
      }

      var urlIndex = selectUrlIndex();
      return asset && asset.available && asset.available[urlIndex] ? asset.available[urlIndex].url : null;
    },
    loadAssets: function loadAssets(productId, callback) {
      var getApprovedUrl = '';
      var domain = _environment.default.api.host + '/api/v3/media/' + productId + '/images';
      var param = 'activeStatus=true&imageFilter=lxheromedia';
      getApprovedUrl = domain + '?' + param;
      this.get('ajaxWrapper').ajaxGet(getApprovedUrl, null, {
        xhrFields: {
          withCredentials: true
        }
      }).then(callback);
    },
    updateHeroImage: function updateHeroImage(fileId, activityId, asset) {
      var data = {
        'heroImage': 'true',
        'active': 'true',
        'tags': asset.get('tags'),
        'caption': asset.get('caption'),
        'activityId': parseInt(activityId)
      };
      // PUT to update a single image
      var url = _environment.default.api.host + '/api/v3/media/image/' + fileId;
      return this.get('ajaxWrapper').ajaxPut(url, data);
    },
    setApprovalStatus: function setApprovalStatus(asset) {
      var _this4 = this;

      var body = {
        'approval': asset.get('approval'),
        'rejectReason': asset.get('rejectReason')
      };
      var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/assets/' + asset.get('id');
      return this.getCredentials().then(function () {
        _this4.get('ajaxWrapper').ajaxPut(url, body, null, {
          headers: _this4.get('headers')
        });
      });
    },
    rejectImage: function rejectImage(asset, rejectReason) {
      var _this5 = this;

      var fileId = asset.fileId;
      var data = {
        'heroImage': 'false',
        'active': 'false',
        'comment': rejectReason
      };
      var url = _environment.default.api.host + '/api/v3/media/image/' + fileId;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this5.get('ajaxWrapper').ajaxPut(url, data).then(function (results) {
          return resolve(results);
        }).catch(function (reason) {
          return reject(reason);
        });
      });
    },
    updateImageDetails: function updateImageDetails(asset, imageCaption, tagsArray) {
      var _this6 = this;

      var fileId = asset.fileId;
      var isHeroImage = tagsArray.includes('lxheromedia');

      var data = {
        'heroImage': isHeroImage.toString(),
        'active': 'true',
        'caption': imageCaption.toString(),
        'tags': tagsArray,
        'activityId': asset.activityId
      };

      var url = _environment.default.api.host + '/api/v3/media/image/' + fileId;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this6.get('ajaxWrapper').ajaxPut(url, data).then(function (results) {
          return resolve(results);
        }).catch(function (reason) {
          return reject(reason);
        });
      });
    },
    deleteImage: function deleteImage(fileId, activityId) {
      var _this7 = this;

      var data = {
        'heroImage': 'false',
        'active': 'false'
      };
      // PUT to update a single image
      var url = _environment.default.api.host + '/api/v3/media/image/' + fileId;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this7.get('ajaxWrapper').ajaxPut(url, data).then(function (results) {
          return resolve(results);
        }).catch(function (reason) {
          return reject(reason);
        });
      });
    }
  });
});