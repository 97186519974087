define("lxso/utils/array-values", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getArrayOfValues = getArrayOfValues;
  function getArrayOfValues(valuesToGet) {
    var returnArray = [];
    for (var i = 1; i <= valuesToGet; i++) {
      returnArray.push(i);
    }
    return returnArray;
  }
});