define('lxso/helpers/build-preview-src', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.buildPreviewSrc = buildPreviewSrc;
  function buildPreviewSrc(params /*, hash*/) {
    if (window.location.origin === 'https://selfservice.localexpertpartnercentral.com') {
      return window.location.origin + '/infosite-' + params[0] + '?lab=false';
    } else {
      return window.location.origin + '/infosite-' + params[0] + '?lab=true';
    }
  }

  exports.default = Ember.Helper.helper(buildPreviewSrc);
});