define('lxso/helpers/includes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.includes = includes;


  //usage:
  // {{#if (includes index array)}}

  function includes(params /*, hash*/) {
    return params[1].includes(params[0]);
  }

  exports.default = Ember.Helper.helper(includes);
});