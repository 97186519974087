define('lxso/components/photo-preview/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    uitk: Ember.inject.service(),

    actions: {
      delete: function _delete() {
        'use strict';

        this.sendAction('x-action');
      },
      selectHeroImage: function selectHeroImage() {
        'use strict';

        this.sendAction('selectHeroImage');
      },
      showMore: function showMore(titleText) {
        this.get('uitk').modal.create({
          modalId: 'modal-name-photos-hero-image',
          content: '#modalHeroImage',
          animation: 'fade-in-none-out',
          title: titleText
        });
      }
    }
  });
});