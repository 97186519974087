define('lxso/components/simple-time-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    change: function change() {
      this.timeMagic();
    },
    timeMagic: function timeMagic() {
      var label = this.$('#'.concat(this.get('label-id')));
      var input = this.get('value');
      if (/\d?\d[:.]?\d\d/.test(input)) {
        var results = input.match(/(\d?\d)[:.]?(\d\d)/);
        var hours = results[1];
        var minutes = results[2];
        if (hours > 23 || hours < 0 || minutes > 59 || minutes < 0) {
          label.addClass('invalid');
        } else {
          label.removeClass('invalid');
          var finalTime = hours.concat(':').concat(minutes);
          this.set('value', finalTime);
        }
      } else {
        label.addClass('invalid');
      }
    }
  });
});