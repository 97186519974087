define('lxso/validators/array-content-format', ['exports', 'ember-cp-validations/validators/format', 'lxso/mixins/array-validation-model'], function (exports, _format, _arrayValidationModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArrayContentFormat = _format.default.extend(_arrayValidationModel.default);

  ArrayContentFormat.reopenClass({
    getDependentsFor: function getDependentsFor(attribute) {
      return ['model.' + attribute + '.[]'];
    }
  });

  exports.default = ArrayContentFormat;
});