define('lxso/helpers/filter-empty-ember-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.filterEmptyEmberObject = filterEmptyEmberObject;
  function filterEmptyEmberObject(params /*, hash*/) {
    var emberObj = params[0];
    if (emberObj) {
      delete emberObj.__ember_meta__;
      if (Object.keys(emberObj).length != 0) {
        return params[0];
      }
    }
    return null;
  }

  exports.default = Ember.Helper.helper(filterEmptyEmberObject);
});