define('lxso/controllers/offer-price-availability', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // priceTemplateId: "68595",
    // optionId: "26",
    // scheduleTemplateId: "29842"
  });
});