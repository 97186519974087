define('lxso/components/user-supplier-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      branchSelected: function branchSelected(item) {
        this.set('isSupplierInfoDirty', true);
        this.sendAction('branchSelected', item);
        this.sendAction('resetServerErrors');
      },
      removeSelectedSupplier: function removeSelectedSupplier(supplierId) {
        this.set('isSupplierInfoDirty', true);
        this.sendAction('removeSelectedSupplier', supplierId);
        this.sendAction('resetServerErrors');
      },
      saveUserSupplierInfo: function saveUserSupplierInfo() {
        this.sendAction('saveUserSupplierInfo', 'supplierInfo');
      },
      cancelChanges: function cancelChanges() {
        this.sendAction('cancelChanges', 'supplierInfo');
      },
      supplierBranchMapped: function supplierBranchMapped(supplierId, state) {
        this.set('isSupplierInfoDirty', true);
        state.set('isSelected', !state.get('isSelected'));
        this.sendAction('supplierBranchCheckAction', supplierId, state.get('isSelected'));
      },
      selectAllBranches: function selectAllBranches(supplierId, state) {
        this.set('isSupplierInfoDirty', true);
        state.set('selectAll', !state.get('selectAll'));
        this.sendAction('selectAllBranches', supplierId, state.get('selectAll'));
      },
      openStep: function openStep(index) {
        this.sendAction('openStep', index);
      }
    }
  });
});