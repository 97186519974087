define('lxso/mixins/option-group/ticket-type-restrictions', ['exports', 'lxso/constants/option-group'], function (exports, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    responseErrors: Ember.inject.service(),

    initTicketTypeRestrictions: function initTicketTypeRestrictions() {
      var _this = this;

      if (this.get('ticketTypeRestrictions.restrictions')) {
        this.get('ticketTypeRestrictions.restrictions').forEach(function (restriction) {
          var linkedTicketType = _this.get('availableTicketTypes').find(function (tt) {
            return tt.get('baseLabel') === restriction.get('label');
          });
          if (linkedTicketType) {
            restriction.set('ticketTypeId', linkedTicketType.get('ticketTypeId'));
          }
        });
      }
    },

    actions: {
      editTicketTypeRestrictions: function editTicketTypeRestrictions() {
        this.set('isEditingTicketTypeRestrictions', true);
      },
      cancelTicketTypeRestrictions: function cancelTicketTypeRestrictions(ticketTypesRestrictions) {
        ticketTypesRestrictions.rollbackAttributes();
        this.initTicketTypeRestrictions();
        this.set('isEditingTicketTypeRestrictions', false);
        this.set('errors', null);
        this.get('responseErrors').clearErrors();
      },
      saveTicketTypeRestrictions: function saveTicketTypeRestrictions(ticketTypeRestrictions) {
        var _this2 = this;

        this.sendAction('setLastUpdated', _optionGroup.SECTIONS.OG_TTR);
        this.set('isSavingTicketTypeRestrictions', true);
        ticketTypeRestrictions.save().then(function () {
          _this2.set('isEditingTicketTypeRestrictions', false);
          _this2.sendAction('enableOptionGroupUpdateButton', true);
          _this2.get('notifications').success(_this2.get('i18n').t('components.alert.saveSuccess'));
        }).finally(function () {
          _this2.set('isSavingTicketTypeRestrictions', false);
        });
      }
    }
  });
});