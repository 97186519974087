define('lxso/models/user', ['exports', 'ember-data', 'ember-cp-validations', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _emberCpValidations, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      messageKey: 'errors.customMessages.basic.tooShort'
    })],
    lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      messageKey: 'errors.customMessages.basic.tooShort'
    })],
    login: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      messageKey: 'errors.customMessages.basic.tooShort'
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'email',
      messageKey: 'errors.customMessages.supplierSupport.emailFormat'
    })],
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 6,
      messageKey: 'errors.customMessages.changePassword.passwordLength'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*[a-z].*/,
      messageKey: 'errors.customMessages.changePassword.requiresOneLowerCase'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*[A-Z].*/,
      messageKey: 'errors.customMessages.changePassword.requiresOneUpperCase'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*[^a-zA-Z0-9].*/,
      messageKey: 'errors.customMessages.changePassword.requiresOneSpecialCharacter'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*\d.*/,
      messageKey: 'errors.customMessages.changePassword.requiresNumber'
    })],
    confirmPassword: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      messageKey: 'errors.customMessages.changePassword.passwordMatch'
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    login: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    role: _emberData.default.attr('number', { defaultValue: 7 }),
    attributes: (0, _attributes.fragmentArray)('fragments/user-management/attribute'),
    accessibleSuppliersWithBranches: (0, _attributes.fragmentArray)('fragments/user-management/accessible-supplier-branch'),
    roles: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    isUserOnEPC: _emberData.default.attr('boolean', { defaultValue: false }),
    friendlyName: _emberData.default.attr('string'),
    isUserEditable: _emberData.default.attr('boolean', { defaultValue: false }),
    isUserInactive: _emberData.default.attr('boolean', { defaultValue: false }),
    password: _emberData.default.attr('string'),
    confirmPassword: _emberData.default.attr('string')
  });
});