define('lxso/helpers/array-find', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arrayFind = arrayFind;
  function arrayFind(params) {
    return params[0].find(function (obj) {
      return obj.get(params[1]).toString() === params[2];
    });
  }

  exports.default = Ember.Helper.helper(arrayFind);
});