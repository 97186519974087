define('lxso/serializers/account-settings/role', ['exports', 'ember-data', 'lxso/mixins/underscored-json-attributes'], function (exports, _emberData, _underscoredJsonAttributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_underscoredJsonAttributes.default, {
    normalize: function normalize(model, hash) {
      hash.role_id = this.extractId(model, hash);
      return this._super.apply(this, arguments);
    }
  });
});