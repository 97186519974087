define('lxso/models/supplier-branch/supplier-support/time', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    hours: _emberData.default.attr('number', { defaultValue: 0 }),
    minutes: _emberData.default.attr('number', { defaultValue: 0 })
  });
});