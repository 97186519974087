define('lxso/helpers/fuzzy-equal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fuzzyEqual = fuzzyEqual;
  function fuzzyEqual(params /*, hash*/) {
    return params.length !== 2 ? false : params[0] == params[1];
  }

  exports.default = Ember.Helper.helper(fuzzyEqual);
});