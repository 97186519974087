define('lxso/components/offer-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function _require(condition, message) {
    if (!condition) {
      throw message;
    }
  }

  exports.default = Ember.Component.extend({
    getOptions: Ember.computed('options', 'values', 'fromEnum', 'selected-value', function () {
      var _this = this;

      if (!this.get('subcategories')) {
        var fromEnum = this.get('fromEnum');
        var options = void 0;
        var values = void 0;
        if (fromEnum) {
          options = fromEnum.map(function (opt) {
            return opt.display;
          });
          values = fromEnum.map(function (opt) {
            return opt.value;
          });
        } else {
          options = this.get('options');
          values = this.get('values') ? this.get('values') : options;
        }

        _require(Array.isArray(options), 'Options must be an array');
        _require(Array.isArray(values), 'Values must be an array');
        _require(options.length === values.length, 'Options and values must have the same length');

        var selectedValue = this.get('valueType') === 'number' ? parseInt(this.get('selected-value')) : this.get('selected-value');

        if (selectedValue !== undefined && values.indexOf(selectedValue) != -1) {
          var adjustedValue = typeof selectedValue === 'boolean' || typeof selectedValue === 'number' ? selectedValue.toString() : selectedValue;

          Ember.run.schedule('afterRender', function () {
            Ember.$(_this.element).find('select').val(adjustedValue);
          });
        }
        return options.map(function (label, index) {
          return { label: label, value: values[index] };
        });
      } else {
        return false;
      }
    }),
    formattedCategoryOptions: Ember.computed('categoryOptions', 'selected-value', function () {
      var _this2 = this;

      if (this.get('subcategories')) {
        var categoryOptions = this.get('categoryOptions');
        var selectedValue = this.get('selected-value');

        _require(Array.isArray(categoryOptions), 'Options must be an array');

        var categories = categoryOptions.map(function (category) {
          return category.category;
        }).uniq();

        Ember.run.schedule('afterRender', function () {
          Ember.$(_this2.element).find('select').val(selectedValue);
        });

        return categories.map(function (category) {
          return { category: category, options: categoryOptions.filterBy('category', category).map(function (category) {
              return category.subCategory;
            }) };
        });
      } else {
        return false;
      }
    }),

    actions: {
      onSelect: function onSelect(target) {
        var text = this.$(target).find('option:selected').text();
        this.sendAction('action', target.value, text);
      }
    }
  });
});