define('lxso/utils/change-history-details-display-modes/booking-questions', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lxso/utils/change-history-details-display-modes/list', 'lodash'], function (exports, _generic, _list, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = {
    partialName: 'booking-questions',
    diff: function diff(oldValue, newValue, services) {
      var oldData = oldValue ? JSON.parse(oldValue) : {};
      var newData = newValue ? JSON.parse(newValue) : {};

      function difference(displayMode, value) {
        var newValue = value(newData);
        var oldValue = value(oldData);

        return !_lodash.default.isEqual(oldValue, newValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }

      return {
        name: difference(_generic.default, function (data) {
          return data.name;
        }),
        status: difference(_generic.default, function (data) {
          return data.status;
        }),
        bookingQuestionIds: difference(_list.default, function (data) {
          return bookingQuestionsSelected(data.bookingQuestionIds, services);
        }),
        bookingQuestionProducts: difference(_list.default, function (data) {
          return bookingQuestionProductsSelected(data.bookingQuestionDetails, services);
        })
      };
    }
  };


  function bookingQuestionsSelected(questionsArray, services) {
    var selectedQuestions = [];
    if (questionsArray) {
      var travelerQns = services.staticData.getTravelerInfoFieldIdsInDB();
      var pickupQns = services.staticData.getPickupInfoFieldIdsInDB();
      var dropOffQns = services.staticData.getDropoffInfoFieldIdsinDB();

      var selectedTravelerQns = questionsArray.filter(function (question) {
        return travelerQns.contains(question.id);
      }).map(function (question) {
        return services.i18n.t('pages.bookingQuestions.changeHistory.travelQuestions.' + question.id).string;
      });

      var selectedPickUpQns = questionsArray.filter(function (question) {
        return pickupQns.contains(question.id);
      }).uniqBy('restrictionValue').map(function (question) {
        return services.i18n.t('pages.bookingQuestions.changeHistory.pickUpQns.' + question.restrictionValue).string;
      });

      var selectedDropOffQns = questionsArray.filter(function (question) {
        return dropOffQns.contains(question.id);
      }).uniqBy('restrictionValue').map(function (question) {
        return services.i18n.t('pages.bookingQuestions.changeHistory.dropOffQns.' + question.restrictionValue).string;
      });

      selectedQuestions = [].concat(_toConsumableArray(selectedTravelerQns), _toConsumableArray(selectedPickUpQns), _toConsumableArray(selectedDropOffQns));
    }
    return selectedQuestions;
  }

  function bookingQuestionDetails(bqDetailsArray, services) {
    var message = '';
    if (bqDetailsArray) {
      var uniqueOffersLength = bqDetailsArray.map(function (item) {
        return item.offerId;
      }).uniq().length;
      var uniqueProductsLength = bqDetailsArray.map(function (item) {
        return item.activityId;
      }).uniq().length;
      var productsAnd = services.i18n.t('pages.bookingQuestions.changeHistory.productAnd').string;
      var optionsSelected = services.i18n.t('pages.bookingQuestions.changeHistory.optionsSelected').string;
      message = uniqueProductsLength + ' ' + productsAnd + ' ' + uniqueOffersLength + ' ' + optionsSelected;
    }
    return message;
  }

  function bookingQuestionProductsSelected(bqDetailsArray, services) {
    var uniqueProducts = [];
    if (bqDetailsArray) {
      uniqueProducts = bqDetailsArray.map(function (item) {
        return item.activityId;
      }).uniq();
    }
    var offersGroupedByProduct = _lodash.default.groupBy(bqDetailsArray, 'activityId');
    var offersGroupedByProductMessages = [];
    for (var activity in offersGroupedByProduct) {
      var offers = offersGroupedByProduct[activity].map(function (item) {
        return item.offerId;
      }).uniq();
      offersGroupedByProductMessages.push('Product: ' + activity + '; Options: ' + offers.join(', '));
    }
    return offersGroupedByProductMessages;
  }
});