define('lxso/utils/load-template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = loadTemplate;
  function loadTemplate(url) {
    var name = url.replace('templates/', '').replace('/template', '');

    return Ember.$.ajax({
      url: url + '.hbs',
      success: function success(template) {
        Ember.TEMPLATES[name] = Ember.HTMLBars.compile(template);
      }
    });
  }
});