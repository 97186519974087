define('lxso/models/account-settings', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    firstName: (0, _validation.validator)('presence', true),
    lastName: (0, _validation.validator)('presence', true)
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    userEmail: _emberData.default.attr('string'),
    role: (0, _attributes.fragment)('account-settings/role'),
    branches: (0, _attributes.fragmentArray)('account-settings/supplier-branch')
  });
});