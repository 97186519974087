define('lxso/validators/custom-campaign-promotion-discount', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomCampaignPromotionDiscount = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var errorMessage = model.get('i18n').t('' + options.messageKey).string;
      if (model.get('campaignId') != undefined) {
        options.gte = model.get('discountLowerBound');
        errorMessage = model.get('i18n').t('errors.customMessages.promotion.discount').string.concat(model.get('discountLowerBound'), '%');
        if (value < options.gte) {
          return errorMessage;
        }
      }
      return true;
    }
  });

  CustomCampaignPromotionDiscount.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = CustomCampaignPromotionDiscount;
});