define('lxso/routes/activity/details', ['exports', 'lxso/mixins/publish-api', 'lxso/utils/omniture', 'lxso/utils/form-validator'], function (exports, _publishApi, _omniture, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Route.extend(_publishApi.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),
    loadingTime: Ember.inject.service(),
    v4ContentService: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.Details'
    },

    setupController: function setupController() {
      var _this = this;

      this.controller.set('dirty', false);
      this.controller.set('activity', this.modelFor('activity'));
      this.controller.set('errors', []);
      this.controller.set('activityRoute', this.controllerFor('activity'));

      var route = this;

      this.controller.set('inclusionsPlaceholders', [route.get('i18n').t('pages.details.placeholders.inclusions.0'), route.get('i18n').t('pages.details.placeholders.inclusions.1'), route.get('i18n').t('pages.details.placeholders.inclusions.2'), route.get('i18n').t('pages.details.placeholders.inclusions.3')]);

      this.controller.set('exclusionsPlaceholders', [route.get('i18n').t('pages.details.placeholders.exclusions.0')]);

      this.controller.set('KBYBPlaceholders', [route.get('i18n').t('pages.details.placeholders.kbyb.0'), route.get('i18n').t('pages.details.placeholders.kbyb.1'), route.get('i18n').t('pages.details.placeholders.kbyb.2')]);

      this.controller.set('KBYGPlaceholders', [route.get('i18n').t('pages.details.placeholders.kbyg.0'), route.get('i18n').t('pages.details.placeholders.kbyg.1'), route.get('i18n').t('pages.details.placeholders.kbyg.2')]);

      this.controller.set('removeNew', function () {
        this.send('removeNew');
      });
      this.setAndStoreArrayContentForRollback();
      Ember.run.next(function () {
        var timeTaken = _this.get('loadingTime').getLoadTime();
        console.info('%c Activity details page load time is ' + timeTaken + ' seconds', 'font-weight: bold');
      });
    },
    setAndStoreArrayContentForRollback: function setAndStoreArrayContentForRollback() {
      var activity = this.controller.get('activity');
      this.controller.set('inclusionsCopy', activity.get('highlights.inclusions').map(function (item) {
        return item;
      }));
      this.controller.set('exclusionsCopy', activity.get('highlights.exclusions').map(function (item) {
        return item;
      }));
      this.controller.set('kbybCopy', activity.get('things.know_before_you_book').map(function (item) {
        return item;
      }));
      this.controller.set('kbygCopy', activity.get('things.know_before_you_go').map(function (item) {
        return item;
      }));
    },
    resetArraysToLastSaved: function resetArraysToLastSaved() {
      var activity = this.controller.get('activity');
      this.controller.set('activity.highlights.inclusions', this.controller.get('inclusionsCopy'));
      this.controller.set('activity.highlights.exclusions', this.controller.get('exclusionsCopy'));
      this.controller.set('activity.things.know_before_you_book', this.controller.get('kbybCopy'));
      this.controller.set('activity.things.know_before_you_go', this.controller.get('kbygCopy'));
    },
    triggerContentChange: function triggerContentChange() {
      var activity = this.controller.get('activity');
      activity.get('highlights.highlights').arrayContentDidChange();
      activity.get('highlights.inclusions').arrayContentDidChange();
      activity.get('highlights.exclusions').arrayContentDidChange();
      activity.get('things.know_before_you_book').arrayContentDidChange();
      activity.get('things.know_before_you_go').arrayContentDidChange();
    },


    /* Fix for ON-840 - Delay setting errors so click on +Add link has time to register */
    setErrorsALittleLater: function setErrorsALittleLater(err) {
      Ember.run.later(this, function () {
        this.controller.set('errors', err);
      }, 100);
    },
    getValidationList: function getValidationList(activity) {
      var validations = [{ model: activity.get('things'), attributes: ['know_before_you_book', 'know_before_you_go'] }];
      if (!this.controller.get('activity.isViewOnlyForGroundTransfer')) {
        validations.push({ model: activity.get('highlights'), attributes: ['inclusions', 'exclusions'] });
      }
      return validations;
    },


    actions: {
      dirty: function dirty() {
        this.setDirtyAttribute();
      },
      removeNew: function removeNew() {
        this.setDirtyAttribute();
      },
      validateIfInvalid: function validateIfInvalid() {
        var _this2 = this;

        if (this.controller.get('errors')) {
          var activity = this.controller.get('activity');
          this.triggerContentChange();
          var validations = this.getValidationList(activity);
          _formValidator.validate.apply(undefined, _toConsumableArray(validations)).then(function () {
            return _this2.setErrorsALittleLater();
          }).catch(function (err) {
            return _this2.setErrorsALittleLater(err);
          });
        }
      },
      updateDetails: function updateDetails() {
        var _this3 = this;

        var route = this;
        var controller = this.controller;
        var activity = route.controller.get('activity');

        if (activity.get('isActivityPublished')) {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Publish');
        } else {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Save');
        }
        this.triggerContentChange();
        controller.set('saving', true);
        // activity.get('things').set('gt_instructions', '');

        //will gt instructions is required by the api.
        var validations = this.getValidationList(activity);
        _formValidator.validate.apply(undefined, _toConsumableArray(validations)).then(function () {
          if (activity.get('isContentAndLogisticsMigratedToV4')) {
            return _this3.get('v4ContentService').updateHighlights(activity.get('id'), activity.get('highlights'));
          } else {
            return activity.updateHighlights(activity.get('highlights'));
          }
        }).then(function () {
          if (activity.get('isContentAndLogisticsMigratedToV4')) {
            return _this3.get('v4ContentService').updateThings(activity.get('id'), activity.get('things'));
          } else {
            return activity.updateThings(activity.get('things'));
          }
        }).then(function () {
          _this3.setAndStoreArrayContentForRollback();
          // Send page completion data to Omniture
          (0, _omniture.default)({
            events: 'event19' //event19 tracks successful completion of "Details" page
          });

          _this3.controller.set('errors', undefined);

          _this3.controller.set('dirty', false);
          var activityId = activity.get('id');
          if (activity.get('isActivityPublished')) {
            _this3.controller.set('saving', false);
            _this3.publishChanges(activity);
          } else {
            if (_this3.get('session.data.fromPublish')) {
              _this3.get('session').set('data.fromPublish', false);
              route.transitionTo('activity.publish');
            } else {
              route.transitionTo('activity.photos', activityId);
            }
          }
        }).catch(function (reason) {
          controller.set('saving', false);
          controller.set('errors', reason);
          (0, _formValidator.scrollToFirstError)();
          Ember.Logger.error('Something went wrong: ', reason);
        });
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'selectScheduleGroup';
        this.controller.set('errors', '');
        this.controller.set('saving', false);
      },
      willTransition: function willTransition() {
        if (this.controller.get('dirty')) {
          this.resetArraysToLastSaved();
        }
        //for tests
        delete window.pageName;
      }
    }

  });
});