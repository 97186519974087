define('lxso/components/promotions/promotion-shopping-path/component', ['exports', 'lxso/utils/form-validator', 'lxso/utils/omniture'], function (exports, _formValidator, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('isInternalUser', this.get('permissions').checkEnabled('V3InternalSettings'));
      this.set('shoppingPathRule', {});
      this.setShoppingPathSwitches();
    },
    setShoppingPathSwitches: function setShoppingPathSwitches() {
      var shoppingPathArray = this.get('promotionShoppingPaths');
      if (shoppingPathArray && shoppingPathArray.get('length')) {
        this.set('shoppingPathRule.standalone', shoppingPathArray.indexOf(1) >= 0);
        this.set('shoppingPathRule.package', shoppingPathArray.indexOf(2) >= 0);
      } else {
        this.initShoppingPathRule();
      }
    },
    initShoppingPathRule: function initShoppingPathRule() {
      this.set('shoppingPathRule.package', false);
      this.set('shoppingPathRule.standalone', false);
    },


    actions: {
      editSection: function editSection() {
        this.sendAction('edit');
      },
      continueSection: function continueSection() {
        this.set('promotionShoppingPaths', []);
        var shopPathsToOmniture = [];
        for (var property in this.get('shoppingPathRule')) {
          if (this.get('shoppingPathRule').hasOwnProperty(property)) {
            switch (property) {
              case 'standalone':
                if (this.get('shoppingPathRule.standalone')) {
                  this.get('promotionShoppingPaths').pushObject(1);
                  shopPathsToOmniture.push('standalone');
                }
                break;
              case 'package':
                if (this.get('shoppingPathRule.package')) {
                  this.get('promotionShoppingPaths').pushObject(2);
                  shopPathsToOmniture.push('package');
                }
                break;
            }
          }
        }
        (0, _omniture.sendOmnitureDataOnClick)('page.create.promotion-new.continue.shoppingpath', shopPathsToOmniture.join(','));
        this.sendAction('continue');
      }
    }
  });
});