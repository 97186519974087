define('lxso/adapters/language-domain', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findAll: function findAll(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.host + '/' + this.namespace + '/enums/tour_languages';

      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'GET', { data: data });
    }
  });
});