define('lxso/adapters/offer', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    namespace: 'api/v3',
    staticData: Ember.inject.service(),
    findRecord: function findRecord(store, type, param, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var isOfferGroup = snapshot.adapterOptions ? snapshot.adapterOptions.isOfferGroup : false;
      // const url = `${this.host}/${this.namespace}/activity/${activityId}/offer/${param}`;
      var url = void 0;
      if (isOfferGroup) {
        url = this.host + '/' + this.namespace + '/offerGroup/' + param;
      } else {
        url = this.host + '/' + this.namespace + '/offer/' + param;
      }

      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'GET');
    },
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var activityId = snapshot.adapterOptions.activityId;
      var usageDaysSupportedPlatformIds = this.get('staticData').getUsageDaysSupportedPlatformIds();
      serializer.serializeIntoHash(data, type, snapshot);

      delete data.schedulesAndLanguageInfo;
      if (data.isMultipleOptionGroup) {
        var url = this.host + '/' + this.namespace + '/activity/' + activityId + '/offerGroup';
        data.scheduleType = data.offerAttributes.scheduleType;
        data.isEligibleInPrebookPaths = data.offerAttributes.isEligibleInPrebookPaths;
        data.isHotelPickupEnabled = data.offerAttributes.isHotelPickupEnabled;
        data.isPriceByVolume = data.offerAttributes.isPriceByVolume;
        data.isPricingIngestion = data.offerAttributes.isPricingIngestion;
        data.mealType = data.offerAttributes.mealType;
        data.offerDetails = data.offerDetails.map(function (offer) {
          delete offer.offerId;
          return offer;
        });
        delete data.durationInMinutes;
        delete data.offerAttributes;
        delete data.status;
        delete data.prices;
        delete data.title;
        return this.ajax(url, 'POST', { data: data });
      } else {
        var _url = this.host + '/' + this.namespace + '/activity/' + activityId + '/offer';
        delete data.status;
        delete data.prices;
        if (!usageDaysSupportedPlatformIds.includes(parseInt(data.platformId))) {
          delete data.offerAttributes.usageDays;
        }
        return this.ajax(_url, 'POST', { data: data });
      }
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var offerId = snapshot.adapterOptions.offerId;
      var activityId = parseInt(snapshot.adapterOptions.activityId);
      var usageDaysSupportedPlatformIds = this.get('staticData').getUsageDaysSupportedPlatformIds();
      serializer.serializeIntoHash(data, type, snapshot);
      delete data.schedulesAndLanguageInfo;

      if (data.isMultipleOptionGroup) {
        var url = this.host + '/' + this.namespace + '/offerGroup/' + offerId;
        data.offerGroupId = parseInt(offerId);
        data.activityId = activityId;
        data.scheduleType = data.offerAttributes.scheduleType;
        data.isEligibleInPrebookPaths = data.offerAttributes.isEligibleInPrebookPaths;
        data.isHotelPickupEnabled = data.offerAttributes.isHotelPickupEnabled;
        data.isPriceByVolume = data.offerAttributes.isPriceByVolume;
        data.isPricingIngestion = data.offerAttributes.isPricingIngestion;
        data.mealType = data.offerAttributes.mealType;
        delete data.durationInMinutes;
        delete data.offerAttributes;
        delete data.status;
        delete data.prices;
        delete data.title;
        return this.ajax(url, 'PUT', { data: data });
      } else {
        var _url2 = this.host + '/' + this.namespace + '/offer/' + offerId;
        if (!usageDaysSupportedPlatformIds.includes(parseInt(data.platformId))) {
          delete data.offerAttributes.usageDays;
        }
        delete data.status;
        delete data.prices;
        data.offerId = parseInt(offerId);
        data.mealType = data.offerAttributes.mealType;
        data.languageInfo = data.offerAttributes.languageInfo;
        return this.ajax(_url2, 'PUT', { data: data });
      }
    }
  });
});