define('lxso/models/fragments/health-and-safety/health-and-safety-questions', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    additionalOption: [(0, _validation.validator)('array-length', {
      gte: 1,
      messageKey: Ember.computed('model.qid', function () {
        if (this.get('model.qid') == 12) {
          return 'pages.healthAndSafety.labels.errors.additionalOptionsDropdown';
        } else if (this.get('model.qid') == 18) {
          return 'pages.healthAndSafety.labels.errors.covidStatusQuestions';
        } else {
          return 'pages.healthAndSafety.labels.errors.additionalOptions';
        }
      }),
      volatile: true,
      disabled: Ember.computed('model.answer', function () {
        if (this.get('model.answer') == 'Yes' && this.get('model.qid') != 18) {
          return false;
        } else {
          return true;
        }
      })
    })],
    covidStatusOption: [(0, _validation.validator)('custom-health-and-safety-covid-status', {
      messageKey: 'pages.healthAndSafety.labels.errors.covidStatusQuestions',
      covidStatusQuestionsOptionMessage: 'pages.healthAndSafety.labels.errors.covidStatusQuestionsOption',
      covidStatusTimePriorToTest: 'pages.healthAndSafety.labels.errors.covidStatusTimePriorToTest',
      covidStatusAcceptedTestTypes: 'pages.healthAndSafety.labels.errors.covidStatusAcceptedTestTypes',
      covidStatusTimePriorToTestAndAcceptedTestTypes: 'pages.healthAndSafety.labels.errors' + '.covidStatusTimePriorToTestAndAcceptedTestTypes',
      volatile: true,
      disabled: Ember.computed('model.answer', function () {
        if (this.get('model.answer') == 'Yes' && this.get('model.qid') == 18) {
          return false;
        } else {
          return true;
        }
      })
    })]
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    qid: _emberData.default.attr('number'),
    answer: _emberData.default.attr('string'),
    additionalOption: _emberData.default.attr('array', { defaultValue: Ember.A([]) }),
    covidStatusOption: _emberData.default.attr(),
    i18n: Ember.inject.service()
  });
});