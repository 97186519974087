define('lxso/components/navigation-list-item/component', ['exports', 'lxso/mixins/authentication-listener', 'lxso/mixins/check-enabled'], function (exports, _authenticationListener, _checkEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_authenticationListener.default, _checkEnabled.default, {
    tagName: 'li',
    classNames: ['no-padding'],
    router: Ember.inject.service(),
    activityPublishFlag: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.checkRoute(this.get('router.currentRouteName'));
    },

    routeObserver: Ember.observer('router.currentRouteName', function () {
      this.checkRoute(this.get('router.currentRouteName'));
    }),
    checkRoute: function checkRoute(route) {
      var currentRoute = route;
      if (route != null && route.includes('.index')) {
        currentRoute = route.substring(0, route.length - 6);
      } else if (route.includes('activity')) {
        this.set('isPublished', this.get('activityPublishFlag').getPublishFlag());
      }
      this.set('currentRoute', currentRoute);
    },
    whenLoggedIn: function whenLoggedIn() {
      if (!this.get('isDestroyed') || !this.get('isDestroying')) {
        this.checkEnabled('neededPermission', 'permissions', 'hasPermission');
        this.checkEnabled('neededFeatureFlag', 'featureFlags', 'hasFeature');
      }
    },

    hrefParts: Ember.computed.collect('route', 'index'),
    href: Ember.computed('hrefParts', function () {
      return this.get('hrefParts').filter(Ember.isPresent).join('/');
    }),
    actions: {
      linkAction: function linkAction(route, index, extraRoute) {
        var queryParams = this.get('queryParams');
        if (route && index && extraRoute) {
          this.menuTransition([route, extraRoute, index], queryParams);
        } else if (route && index) {
          this.menuTransition([route, index], queryParams);
        } else {
          this.menuTransition([route], queryParams);
        }
      }
    }
  });
});