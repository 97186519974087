define('lxso/models/schedule', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    offer: _emberData.default.belongsTo('offer'),
    scheduleType: _emberData.default.attr('string', { defaultValue: 'start' }),
    startTime: _emberData.default.attr('string'),
    endTime: _emberData.default.attr('string')

    // monday: fragment('fragments/hours-of-operation', {defaultValue: {open: '', close: '', active: false}}),
    // tuesday: fragment('fragments/hours-of-operation', {defaultValue: {open: '', close: '', active: false}}),
    // wednesday: fragment('fragments/hours-of-operation', {defaultValue: {open: '', close: '', active: false}}),
    // thursday: fragment('fragments/hours-of-operation', {defaultValue: {open: '', close: '', active: false}}),
    // friday: fragment('fragments/hours-of-operation', {defaultValue: {open: '', close: '', active: false}}),
    // saturday: fragment('fragments/hours-of-operation', {defaultValue: {open: '', close: '', active: false}})
  });
});