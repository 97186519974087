define('lxso/components/lx-logo/component', ['exports', 'lxso/mixins/authentication-listener'], function (exports, _authenticationListener) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_authenticationListener.default, {
    permissions: Ember.inject.service('permissions'),
    session: Ember.inject.service('session'),

    whenLoggedIn: function whenLoggedIn() {
      this.set('isClickable', this.get('permissions').checkEnabled('V3Product'));
    },
    whenLoggedOut: function whenLoggedOut() {
      this.set('isClickable', true);
    }
  });
});