define('lxso/services/reviewvault', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COMMON_REVIEW_FIELDNAME_BY_SORT_VALUE_MAP = new Map([['receivedDate', 'createTime'], ['arrivalDate', 'offerStartTime']]);

  var API_FEATURE_BY_EMBER_FEATURE_MAP = new Map([['offer', 'offers'], ['supplier', 'suppliers'], ['supplier-branch', 'supplier_branches']]);

  exports.default = Ember.Service.extend({
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    convertQueryToApiRequest: function convertQueryToApiRequest(params, forAvailabilityCheck) {
      'use strict';

      var queryParams = {
        page: params.page,
        size: params.size,
        sortBy: COMMON_REVIEW_FIELDNAME_BY_SORT_VALUE_MAP.get(params.sortBy),
        order: params.order,
        includeUncommented: params.includeUncommented,
        minimumRating: parseFloat(params.ratingFilter),
        maximumRating: parseFloat(params.ratingFilter),
        includeUsernames: true,
        activityId: params.activityId
      };

      if (forAvailabilityCheck) {
        delete queryParams.maximumRating;
        queryParams.minimumRating = 1;
      }

      return queryParams;
    },
    buildFeatureReviewUrl: function buildFeatureReviewUrl(params, forAvailabilityCheck) {
      'use strict';

      var apiFeature = API_FEATURE_BY_EMBER_FEATURE_MAP.get(params.feature);
      var id = params.id;
      var urlRoot = _environment.default.api.host + '/' + _environment.default.api.namespace + '/' + apiFeature + '/' + id + '/reviews?';

      var queryParams = this.convertQueryToApiRequest(params, forAvailabilityCheck);
      var urlParams = Object.keys(queryParams).filter(function (property) {
        return Ember.isPresent(queryParams[property]);
      }).map(function (property) {
        return property + '=' + queryParams[property];
      });

      return urlRoot + urlParams.join('&');
    },
    loadReviews: function loadReviews(route, controller, params) {
      'use strict';

      if (params) {
        var url = this.buildFeatureReviewUrl(params);
        var loadedReviewsPromise = this.get('ajaxWrapper').ajaxGet(url, null, { xhrFields: { withCredentials: true } });

        var handleSuccess = function handleSuccess(loadedReviews) {
          controller.set('loadingMoreReviews', false);
          var reviewes = loadedReviews;
          if (reviewes.length) {
            var oldLoadedReviews = controller.get('loadedReviews');
            reviewes = oldLoadedReviews && oldLoadedReviews.length ? oldLoadedReviews.concat(loadedReviews) : loadedReviews;
            controller.set('loadedReviews', reviewes);
            controller.notifyPropertyChange('loadedReviews');
          } else {
            controller.set('noMoreReviews', true);
            // This prevents a build-up of extra whitespace once there are no more reviews.
            Ember.$('html').height('101%');
          }
        };

        var handleError = function handleError(reason) {
          route.intermediateTransitionTo('error', new Ember.Error(reason));
        };

        return loadedReviewsPromise.then(handleSuccess, handleError);
      }
    },
    buildAggregatedReviewFeatureUrl: function buildAggregatedReviewFeatureUrl(params) {
      'use strict';

      var apiFeature = API_FEATURE_BY_EMBER_FEATURE_MAP.get(params.feature);
      var id = params.id;

      var reviewRatingUrlSuffix = '/reviews/rating/aggregation?activityId=';
      var urlRoot = _environment.default.api.host + '/' + _environment.default.api.namespace + '/' + apiFeature + '/' + id;

      return urlRoot + reviewRatingUrlSuffix + params.activityId;
    },
    aggregatedReviewRating: function aggregatedReviewRating(route, controller, params) {
      'use strict';

      if (params) {
        var url = this.buildAggregatedReviewFeatureUrl(params);
        var xhrFields = { xhrFields: { withCredentials: true } };
        var aggregatedReviewRatingPromise = this.get('ajaxWrapper').ajaxGet(url, null, xhrFields);

        var handleSuccess = function handleSuccess(aggregatedRating) {
          controller.set('aggregateRatingData', aggregatedRating.get(0));
        };

        var handleError = function handleError(reason) {
          route.intermediateTransitionTo('error', new Ember.Error(reason));
        };

        return aggregatedReviewRatingPromise.then(handleSuccess, handleError);
      }
    },
    convertQueryToExportApiRequest: function convertQueryToExportApiRequest(params, startDateParam, endDateParam) {
      'use strict';

      var queryParams = {
        page: 1,
        size: 5000,
        sortBy: COMMON_REVIEW_FIELDNAME_BY_SORT_VALUE_MAP.get(params.sortBy),
        order: params.order,
        includeUncommented: true,
        minimumRating: 1,
        maximumRating: 5,
        includeUsernames: true,
        activityId: '',
        startDate: startDateParam,
        endDate: endDateParam
      };
      return queryParams;
    },
    buildReviewExportUrl: function buildReviewExportUrl(params, startDate, endDate) {
      'use strict';

      var apiFeature = API_FEATURE_BY_EMBER_FEATURE_MAP.get(params.feature);
      var id = params.id;
      var urlRoot = _environment.default.api.host + '/' + _environment.default.api.namespace + '/' + apiFeature + '/' + id + '/exportReviews?';

      var queryParams = this.convertQueryToExportApiRequest(params, startDate, endDate);
      var urlParams = Object.keys(queryParams).filter(function (property) {
        return Ember.isPresent(queryParams[property]);
      }).map(function (property) {
        return property + '=' + queryParams[property];
      });

      return urlRoot + urlParams.join('&');
    },
    runExportReviewQuery: function runExportReviewQuery(route, params, startDate, endDate) {
      var _this = this;

      var url = this.buildReviewExportUrl(params, startDate, endDate);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('ajaxWrapper').ajaxGet(url, null, { dataType: '' }).then(function (results) {
          return resolve(results);
        }).catch(function (reason) {
          return reject(reason);
        });
      });
    }
  });
});