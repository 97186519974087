define('lxso/models/fragments/booking-questions/offer-group', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({});

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    offerGroupId: _emberData.default.attr('number'),
    offerGroupTitle: _emberData.default.attr('string')
  });
});