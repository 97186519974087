define("lxso/utils/regexPatterns", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /*eslint-disable */
  var validForm = exports.validForm = {
    validFormChar: /^[—a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ&(\)\+~®\]\[,\.@#*‘’“”'"–™©®\-\/;:! \d\s]+$/,
    validFormCharWithUnderscore: /^[—a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ&\\(\)\+~®\]\[,\.@#*‘’“”'"_–™©®\-\/;:! \d\s]+$/
  };
  /*eslint-enable */
});