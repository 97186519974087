define('lxso/adapters/option-group', ['exports', 'lxso/adapters/application', 'lxso/constants/option-group'], function (exports, _application, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajaxWrapper: Ember.inject.service(),
    tshop: Ember.inject.service(),
    responseErrors: Ember.inject.service(),
    optionGroupService: Ember.inject.service(),
    findRecord: function findRecord(store, type, id) {
      var url = this.hostV4 + '/offer-group/' + id;
      return this.ajax(url, 'GET');
    },
    query: function query(store, type, _query) {
      var url = this.hostV4 + '/activity/' + _query.activityId + '/offer-groups';
      return this.ajax(url, 'GET');
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var url = this.hostV4 + '/offer-group/' + data.activityId;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('ajaxWrapper').ajaxPost(url, data, {
          xhrFields: {
            withCredentials: true
          }
        }).then(_this.handleCreateOfferGroupSuccessResponse(resolve, reject, data), _this.handleErrorResponse(reject));
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this2 = this;

      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var offerGroupId = data.offerGroupId,
          activityId = data.activityId;

      if (snapshot.adapterOptions.section === _optionGroup.SECTIONS.DETAILS) {
        var url = this.hostV4 + '/offer-group/' + offerGroupId;
        delete data.offers;
        // returns 204 No Content on successful update
        if (data.status === _optionGroup.STATUS.INACTIVE) {
          return this.ajax(url, 'DELETE', { data: data });
        } else {
          return this.ajax(url, 'PUT', { data: data });
        }
      } else {
        var _url = this.hostV4 + '/offer-group/' + offerGroupId + '/update-offers';
        var ajaxWrapper = this.get('ajaxWrapper');
        data.offers.forEach(function (offer, index) {
          offer.sequenceId = index;
        });
        var requestBody = {
          offers: data.offers
        };
        return new Ember.RSVP.Promise(function (resolve, reject) {
          ajaxWrapper.ajaxPut(_url, requestBody, {
            xhrFields: {
              withCredentials: true
            }
          }).then(_this2.handleUpdateOffersSuccessResponse(resolve, reject, data, offerGroupId), _this2.handleErrorResponse(reject));
        });
      }
    },
    handleCreateOfferGroupSuccessResponse: function handleCreateOfferGroupSuccessResponse(resolve, reject, offerGroup) {
      return function (response) {
        var offerGroupCreateResponse = JSON.parse(JSON.stringify(offerGroup));
        offerGroupCreateResponse.offerGroupId = response.offerGroupId;
        delete offerGroupCreateResponse.offers;
        resolve(offerGroupCreateResponse);
      };
    },
    handleUpdateOffersSuccessResponse: function handleUpdateOffersSuccessResponse(resolve, reject, data, offerGroupId) {
      var _this3 = this;

      return function (response) {
        var newOfferIds = response.offerMappings.filter(function (offer) {
          return offer.newOffer;
        }).map(function (offer) {
          return offer.offerId;
        });
        //create TShop IDs and back fill prices for new offers.
        _this3.get('tshop').createTShopIdForOptions(newOfferIds, offerGroupId).then(function () {
          return _this3.get('optionGroupService').triggerPriceCreateForNewOptions(newOfferIds, offerGroupId);
        });
        data.offers.forEach(function (offer) {
          var updatedOffer = response.offerMappings.find(function (mappedOffer) {
            return mappedOffer.sequenceId == offer.sequenceId;
          });
          if (updatedOffer) {
            offer.offerId = updatedOffer.offerId;
            offer.newOffer = updatedOffer.newOffer;
          }
        });
        resolve(data);
      };
    },
    handleErrorResponse: function handleErrorResponse(reject) {
      var _this4 = this;

      return function (error) {
        _this4.get('responseErrors').clearErrors();
        _this4.get('responseErrors').addErrors(error.responseJSON, error.status);
        reject(error.responseJSON);
      };
    }
  });
});