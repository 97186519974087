define('lxso/components/faq-link/component', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['no-padding'],
    actions: {
      faqLinkAction: function faqLinkAction() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.FAQ');
        if (this.menuItemClick) {
          this.menuItemClick('faq');
        }
        window.open('https://support.localexpertpartnercentral.com/');
      }
    }
  });
});