define('lxso/mixins/option-group/price-v4', ['exports', 'lxso/constants/status-v4'], function (exports, _statusV) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    permissions: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    netPrice: Ember.inject.service(),
    optionGroupService: Ember.inject.service(),

    setUpPriceV4Section: function setUpPriceV4Section() {
      this.controller.set('isOptionGroupPriceV4Open', false); // ToDo: reset to false once changes are ready to go live
      this.controller.set('isOptionGroupPriceV4Enabled', this.controller.get('optionGroup.offers').filter(function (o) {
        return o.get('offerIds') && o.get('offerIds').length > 0;
      }).length > 0);
      this.controller.set('hasExistingPrices', this.controller.get('v4PriceGroups.length') > 0);
      var offerStatusesForPricing = [_statusV.default.ACTIVE, _statusV.default.SUSPENDED];
      var allOffers = this.controller.get('optionGroup.offers').map(function (offerRow) {
        return offerRow.get('subOffers').map(function (offer) {
          return offer;
        });
      }).flat().filter(function (o) {
        return offerStatusesForPricing.includes(o.get('status'));
      });
      this.controller.set('allOffers', allOffers);
    },


    actions: {
      updateHasExistingPrices: function updateHasExistingPrices() {
        var hasExistingPrices = this.controller.get('v4PriceGroups').filter(function (pg) {
          return pg.get('status') === _statusV.default.ACTIVE && pg.get('id') > 0;
        }).length > 0;
        this.controller.set('hasExistingPrices', hasExistingPrices);
        this.controllerFor('activity.option-group').toggleProperty('refreshSideNav');
      }
    }
  });
});