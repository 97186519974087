define('lxso/utils/csv-download', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function createCsvAndDownload(data, filenamePrefix, fileSaver) {
    var blob = new Blob(['\uFEFF', data], { type: 'text/csv' });
    var fileName = createCsvFileName(filenamePrefix);
    fileSaver.saveAs(blob, fileName);
  }

  function createCsvFileName(filenamePrefix) {
    var currentDate = new Date();
    var filename = '_reviews_' + currentDate.toISOString();
    return filenamePrefix + filename.replace(/\W/g, '') + '.csv';
  }

  exports.createCsvAndDownload = createCsvAndDownload;
});