define("lxso/constants/language-type-ids", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LANG_TYPE_IDS = {
    WRITTEN_GUIDE: 5,
    NO_COMMENTARY: 3,
    AUDIO_GUIDE: 2,
    LIVE_COMMENTARY: 1
  };

  var SPOKEN_CHINESE_LANG_IDS = Ember.A([24, 34]); //Mandarin and Cantonese

  var WRITTEN_CHINESE_LANG_IDS = Ember.A([23, 33]); //Chinese and Chinese(Traditional)

  var SPOKEN_LANG_TYPE_IDS = Ember.A([LANG_TYPE_IDS.LIVE_COMMENTARY, LANG_TYPE_IDS.AUDIO_GUIDE]);

  exports.LANG_TYPE_IDS = LANG_TYPE_IDS;
  exports.SPOKEN_CHINESE_LANG_IDS = SPOKEN_CHINESE_LANG_IDS;
  exports.WRITTEN_CHINESE_LANG_IDS = WRITTEN_CHINESE_LANG_IDS;
  exports.SPOKEN_LANG_TYPE_IDS = SPOKEN_LANG_TYPE_IDS;
});