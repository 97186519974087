define('lxso/components/promotions/promotion-date/component', ['exports', 'lxso/utils/form-validator', 'lxso/utils/omniture'], function (exports, _formValidator, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    validateDate: function validateDate() {
      return (0, _formValidator.validate)({
        model: this.get('promotion'),
        attributes: ['travelStartDate', 'travelEndDate', 'bookingStartDate', 'bookingEndDate']
      });
    },

    bookingStartLowerBound: Ember.computed('promotion.bookingStartLowerBound', function () {
      if (this.get('promotion.bookingStartLowerBound')) {
        return moment(this.get('promotion.bookingStartLowerBound')).format('MM/DD/YYYY');
      } else {
        return moment().format('MM/DD/YYYY');
      }
    }),
    bookingStartUpperBound: Ember.computed('promotion.bookingStartUpperBound', function () {
      if (this.get('promotion.bookingStartUpperBound')) {
        return moment(this.get('promotion.bookingStartUpperBound')).format('MM/DD/YYYY');
      }
    }),
    bookingEndLowerBound: Ember.computed('promotion.bookingEndLowerBound', function () {
      if (this.get('promotion.bookingEndLowerBound')) {
        return moment(this.get('promotion.bookingEndLowerBound')).format('MM/DD/YYYY');
      } else {
        return moment().format('MM/DD/YYYY');
      }
    }),
    bookingEndUpperBound: Ember.computed('promotion.bookingEndUpperBound', function () {
      if (this.get('promotion.bookingEndUpperBound')) {
        return moment(this.get('promotion.bookingEndUpperBound')).format('MM/DD/YYYY');
      }
    }),
    travelStartLowerBound: Ember.computed('promotion.travelStartLowerBound', function () {
      if (this.get('promotion.travelStartLowerBound')) {
        return moment(this.get('promotion.travelStartLowerBound')).format('MM/DD/YYYY');
      } else {
        return moment().format('MM/DD/YYYY');
      }
    }),
    travelStartUpperBound: Ember.computed('promotion.travelStartUpperBound', function () {
      if (this.get('promotion.travelStartUpperBound')) {
        return moment(this.get('promotion.travelStartUpperBound')).format('MM/DD/YYYY');
      }
    }),
    travelEndLowerBound: Ember.computed('promotion.travelEndLowerBound', function () {
      if (this.get('promotion.travelEndLowerBound')) {
        return moment(this.get('promotion.travelEndLowerBound')).format('MM/DD/YYYY');
      } else {
        return moment().format('MM/DD/YYYY');
      }
    }),
    travelEndUpperBound: Ember.computed('promotion.travelEndUpperBound', function () {
      if (this.get('promotion.travelEndUpperBound')) {
        return moment(this.get('promotion.travelEndUpperBound')).format('MM/DD/YYYY');
      }
    }),

    actions: {
      saveDatesSection: function saveDatesSection() {
        var _this = this;

        var omnitureData = [];
        omnitureData.push(this.get('promotion.travelStartDate'));
        omnitureData.push(this.get('promotion.travelEndDate'));
        omnitureData.push(this.get('promotion.bookingStartDate'));
        omnitureData.push(this.get('promotion.bookingEndDate'));
        if (this.get('promotion.isNew')) {
          _omniture.sendOmnitureDataOnClick.apply(undefined, ['page.LX-Supply.promotion-new.continue.date'].concat(omnitureData));
        } else {
          _omniture.sendOmnitureDataOnClick.apply(undefined, ['page.LX-Supply.promotion-edit.continue.date'].concat(omnitureData));
        }
        this.validateDate().then(function () {
          _this.set('validationErrors', '');
          _this.sendAction('continue');
        }).catch(function (datesErr) {
          _this.set('validationErrors', datesErr);
          (0, _omniture.sendOmnitureErrorsOnClick)('error.create.incorrectdates');
        });
      },
      setDateField: function setDateField(date, field) {
        this.sendAction('setDateField', date, field);
      },
      editSection: function editSection() {
        this.sendAction('edit');
      }
    }
  });
});