define('lxso/components/option-group/list/component', ['exports', 'lodash', 'lxso/constants/option-group', 'lxso/constants/language-type-ids'], function (exports, _lodash, _optionGroup, _languageTypeIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var translationBaseKey = 'pages.optionGroup.optionGroupSection.list';

  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    isOptionGroupDeactivateModalOpen: false,
    isOptionGroupFilterModalOpen: false,
    classNames: ['option-group-list-container'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('STATUS', _optionGroup.STATUS);
      this.initStatusFilter();
    },


    optionGroupDetailsList: Ember.computed('selectedStatuses', 'optionGroupList.@each.{shortTitle,attributes,pickupEnabled,offers,status}', function () {
      var _this = this;

      var optionGroupList = this.get('optionGroupList');
      var selectedStatuses = this.get('selectedStatuses');
      var optionGroupDetailsList = optionGroupList.filter(function (optionGroup) {
        return selectedStatuses.includes(optionGroup.get('status'));
      }).map(function (optionGroup) {
        return _this.getOptionGroupDetail(optionGroup, selectedStatuses);
      });
      return optionGroupDetailsList;
    }),

    isFilterSubmitEnabled: Ember.computed('allStatuses.@each.value', function () {
      return this.get('allStatuses').some(function (status) {
        return status.value;
      });
    }),

    initStatusFilter: function initStatusFilter() {
      var _this2 = this;

      this.set('selectedStatuses', Ember.A([_optionGroup.STATUS.ACTIVE]));
      var allStatusesSupportedForOptionGroup = [_optionGroup.STATUS.ACTIVE, _optionGroup.STATUS.INACTIVE];
      var allStatuses = allStatusesSupportedForOptionGroup.map(function (status) {
        return Ember.Object.create({
          name: status,
          label: _this2.get('i18n').t('pages.optionGroup.optionGroupSection.list.filter.' + status),
          helpText: _this2.get('i18n').t('pages.optionGroup.optionGroupSection.list.filter.' + status + '_HelpText'),
          value: _this2.get('selectedStatuses').indexOf(status) >= 0
        });
      });
      this.set('allStatuses', allStatuses);
    },
    getOptionGroupDetail: function getOptionGroupDetail(optionGroup, selectedStatuses) {
      var optionGroupOptions = optionGroup.get('offers').map(function (optionGroupRow) {
        return optionGroupRow.get('subOffers').get('content');
      }).flat().filter(function (option) {
        return optionGroup.get('status') === _optionGroup.STATUS.INACTIVE || option.get('status') === _optionGroup.STATUS.ACTIVE || option.get('status') === _optionGroup.STATUS.SUSPENDED;
      });
      var optionListDetails = this.getOptionListDetails(optionGroupOptions, optionGroup.get('attributes'));
      var optionGroupDetails = Object.assign({}, {
        offerGroupId: optionGroup.get('offerGroupId'),
        status: optionGroup.get('status'),
        shortTitle: optionGroup.get('shortTitle'),
        foodAndBeverage: this.getFoodAndBeverage(optionGroup.get('attributes.mealType')),
        hotelPickup: this.getHotelPickup(optionGroup.get('pickupEnabled')),
        durationInfo: this.getDurationInfo(optionGroup.get('attributes')),
        cancellationPolicy: this.getCancellationPolicy(optionGroup.get('cancellationPolicy')),
        bookingCutoff: this.getBookingCutoff(optionGroup.get('bookingCutoff'))
      }, optionListDetails);
      return optionGroupDetails;
    },
    getFoodAndBeverage: function getFoodAndBeverage(mealType) {
      var supportedMealTypes = [_optionGroup.MEAL_TYPES.FOOD_ONLY, _optionGroup.MEAL_TYPES.DRINKS_ONLY, _optionGroup.MEAL_TYPES.FOOD_AND_DRINKS];
      if (supportedMealTypes.includes(mealType)) {
        return this.get('i18n').t(translationBaseKey + '.mealTypes.' + mealType).string;
      }
    },
    getHotelPickup: function getHotelPickup(pickupEnabled) {
      if (pickupEnabled) {
        return this.get('i18n').t(translationBaseKey + '.hotelPickup').string;
      }
    },
    getDurationInfo: function getDurationInfo(optionGroupAttributes) {
      switch (optionGroupAttributes.get('scheduleType')) {
        case _optionGroup.SCHEDULE_TYPES.MULTI_DAY:
          return this.getMultiDaysDuration(optionGroupAttributes);
        case _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION:
          return this.getOperationHoursDuration(optionGroupAttributes);
        case _optionGroup.SCHEDULE_TYPES.VALID_HOURS:
          return this.getValidHoursDuration(optionGroupAttributes);
        case _optionGroup.SCHEDULE_TYPES.VALID_DAYS:
          return this.getValidDaysDuration(optionGroupAttributes);
        default:
          break;
      }
    },
    getCancellationPolicy: function getCancellationPolicy(cancellationPolicy) {
      var policyType = cancellationPolicy.get('policyType');
      var hrs = this.get('i18n').t('common.words.hrs');
      var cancellationPolicyLabel = this.get('i18n').t('pages.optionGroup.optionGroupSection.list.cancellationPolicy');
      var policyHrs = policyType === _optionGroup.CANCELLATION_POLICY_TYPES.REFUNDABLE ? '(' + cancellationPolicy.get('cutoffInHours') + ' ' + hrs + ')' : '';
      return cancellationPolicyLabel + ': ' + policyType + ' ' + policyHrs;
    },
    getBookingCutoff: function getBookingCutoff(bookingCutoff) {
      var bookingCutoffInHours = bookingCutoff.get('bookingCutoffInHours');
      var hrs = this.get('i18n').t('common.words.hrs');
      var bcoLabel = this.get('i18n').t('pages.optionGroup.optionGroupSection.list.bookingCutoff');
      return bcoLabel + ': ' + bookingCutoffInHours + ' ' + hrs;
    },
    getOptionListDetails: function getOptionListDetails(optionList, optionGroupAttributes) {
      var _this3 = this;

      var langInfoList = Ember.A([]);
      var startTimesSet = new Set();
      var activeOptionIds = Ember.A([]);
      var suspendedOptionIds = Ember.A([]);
      var inactiveOptionIds = Ember.A([]);
      var scheduleType = optionGroupAttributes.get('scheduleType');
      var optionsGroupedByLangTypeId = _lodash.default.groupBy(optionList, function (option) {
        return option.get('languageInfo.languageTypeId');
      });
      _lodash.default.forEach(optionsGroupedByLangTypeId, function (optionsWithSameLangTypeId, langTypeId) {
        var langIdsWithSameLangTypeIdSet = new Set();
        optionsWithSameLangTypeId.forEach(function (option) {
          option.get('languageInfo.languageIds').forEach(function (langId) {
            return langIdsWithSameLangTypeIdSet.add(langId);
          });
          if (option.get('status') === _optionGroup.STATUS.SUSPENDED) {
            suspendedOptionIds.push(option.get('offerId'));
          } else if (option.get('status') === _optionGroup.STATUS.INACTIVE) {
            inactiveOptionIds.push(option.get('offerId'));
          } else {
            activeOptionIds.push(option.get('offerId'));
          }
          if (scheduleType === _optionGroup.SCHEDULE_TYPES.START || scheduleType === _optionGroup.SCHEDULE_TYPES.MULTI_DAY) {
            startTimesSet.add(option.get('startTime'));
          }
        });
        _this3.addLanguageInfo(langIdsWithSameLangTypeIdSet, langTypeId, langInfoList);
      });
      var optionListDetails = {
        langInfoList: langInfoList,
        scheduleInfo: this.getScheduleInfo(scheduleType, optionGroupAttributes, startTimesSet),
        optionIdsStatusGroups: Ember.A([{
          status: _optionGroup.STATUS.ACTIVE,
          optionIds: activeOptionIds.join(', ')
        }, {
          status: _optionGroup.STATUS.SUSPENDED,
          optionIds: suspendedOptionIds.join(', ')
        }, {
          status: _optionGroup.STATUS.INACTIVE,
          optionIds: inactiveOptionIds.join(', ')
        }])
      };
      return optionListDetails;
    },
    getScheduleInfo: function getScheduleInfo(scheduleType, optionGroupAttributes, startTimesSet) {
      switch (scheduleType) {
        case _optionGroup.SCHEDULE_TYPES.START:
        case _optionGroup.SCHEDULE_TYPES.MULTI_DAY:
          return Array.from(startTimesSet).map(function (item) {
            return moment(item, 'HH:mm').format('h:mm A');
          }).join(', ');
        default:
          break;
      }
    },
    getOperationHoursDuration: function getOperationHoursDuration(optionGroupAttributes) {
      var validFor = this.get('i18n').t(translationBaseKey + '.duration.validFor').string;
      var hoursTxt = this.get('i18n').t(translationBaseKey + '.duration.hours').string;
      var minutesTxt = this.get('i18n').t(translationBaseKey + '.duration.minutes').string;
      var hours = optionGroupAttributes.get('durationHoursPart');
      var minutes = optionGroupAttributes.get('durationMinutesPart');
      if (optionGroupAttributes.get('durationMinutesPart') > 0) {
        return validFor + ' ' + hours + ' ' + hoursTxt + ', ' + minutes + ' ' + minutesTxt;
      } else {
        return validFor + ' ' + hours + ' ' + hoursTxt;
      }
    },
    getValidHoursDuration: function getValidHoursDuration(optionGroupAttributes) {
      var validFor = this.get('i18n').t(translationBaseKey + '.duration.validFor').string;
      var hoursTxt = this.get('i18n').t(translationBaseKey + '.duration.hours').string;
      var hours = optionGroupAttributes.get('durationHoursPart');
      return validFor + ' ' + hours + ' ' + hoursTxt;
    },
    getValidDaysDuration: function getValidDaysDuration(optionGroupAttributes) {
      var validFor = this.get('i18n').t(translationBaseKey + '.duration.validFor').string;
      var days = this.get('i18n').t(translationBaseKey + '.duration.days').string;
      return validFor + ' ' + optionGroupAttributes.get('durationDaysPart') + ' ' + days;
    },
    getMultiDaysDuration: function getMultiDaysDuration(optionGroupAttributes) {
      var daysTour = this.get('i18n').t(translationBaseKey + '.duration.daysTour').string;
      return optionGroupAttributes.get('durationDaysPart') + ' ' + daysTour;
    },
    addLanguageInfo: function addLanguageInfo(langIdsWithSameLangTypeIdSet, langTypeId, langInfoList) {
      var langTypeIdsToDisplay = [_languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY, _languageTypeIds.LANG_TYPE_IDS.AUDIO_GUIDE, _languageTypeIds.LANG_TYPE_IDS.WRITTEN_GUIDE];
      if (langTypeIdsToDisplay.includes(parseInt(langTypeId))) {
        var langList = this.getLangList(Array.from(langIdsWithSameLangTypeIdSet));
        var langTypeString = this.get('i18n').t(translationBaseKey + '.language.' + langTypeId);
        langInfoList.push(langTypeString + ' ' + langList);
      }
    },
    getLangList: function getLangList(langIds) {
      var _this4 = this;

      var supportedLangIds = this.get('languageEnum').map(function (lang) {
        return lang.id;
      });
      var langNames = langIds.filter(function (langId) {
        return supportedLangIds.includes(langId);
      }).map(function (langId) {
        return _this4.get('languageEnum').find(function (langEnum) {
          return langEnum.id === langId;
        }).label;
      });
      var and = this.get('i18n').t('common.words.and').string;
      if (langNames.length > 1) {
        return langNames.slice(0, langNames.length - 1).join(', ') + ' ' + and + ' ' + langNames.slice(-1);
      } else {
        return langNames[0];
      }
    },


    actions: {
      editOptionGroup: function editOptionGroup(optionGroupId) {
        this.sendAction('editOptionGroupFromList', optionGroupId);
      },
      cloneOptionGroup: function cloneOptionGroup(optionGroupId) {
        this.sendAction('cloneOptionGroupFromList', optionGroupId);
      },
      handleCancelDeactivate: function handleCancelDeactivate() {
        this.toggleProperty('isOptionGroupDeactivateModalOpen');
        this.set('onOptionGroupDeleteConfirm', null);
      },
      handleDeactivateConfirm: function handleDeactivateConfirm() {
        this.get('onOptionGroupDeleteConfirm')();
        this.send('handleCancelDeactivate');
      },
      deactivateOptionGroup: function deactivateOptionGroup(optionGroup) {
        var _this5 = this;

        this.toggleProperty('isOptionGroupDeactivateModalOpen');
        var deactivateConfirmTitle = this.get('i18n').t('pages.optionGroup.optionGroupSection.list.deactivateConfirmTitle', { optionGroup: optionGroup.shortTitle });
        this.set('deactivateConfirmTitle', deactivateConfirmTitle);
        var onOptionGroupDeleteConfirm = function onOptionGroupDeleteConfirm() {
          _this5.sendAction('deactivateOptionGroupFromList', optionGroup.offerGroupId);
        };
        this.set('onOptionGroupDeleteConfirm', onOptionGroupDeleteConfirm);
      },
      handleOptionGroupFilterModalClick: function handleOptionGroupFilterModalClick() {
        this.toggleProperty('isOptionGroupFilterModalOpen');
      },
      cancelOptionGroupFilter: function cancelOptionGroupFilter() {
        var allStatuses = this.get('allStatuses');
        var selectedStatuses = this.get('selectedStatuses');
        allStatuses.forEach(function (status) {
          status.set('value', selectedStatuses.indexOf(status.get('name')) >= 0);
        });
        this.toggleProperty('isOptionGroupFilterModalOpen');
      },
      applyOptionGroupFilter: function applyOptionGroupFilter(allStatuses) {
        var selectedStatuses = allStatuses.filter(function (status) {
          return status.get('value');
        }).map(function (status) {
          return status.get('name');
        });
        this.set('selectedStatuses', selectedStatuses);
        this.toggleProperty('isOptionGroupFilterModalOpen');
      }
    }
  });
});