define('lxso/mixins/supplier-settings/supplier-platform', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    initPlatformSettings: function initPlatformSettings() {
      this.controller.set('isSavingPlatform', false);
      this.setPartnerSupplierBranchId();
    },
    initAllPlatformDropdown: function initAllPlatformDropdown() {
      var platforms = this.controller.get('allPlatforms');
      var platformDropdownDisplay = [];
      var platformDropdownValues = [];

      platforms.forEach(function (platform) {
        platformDropdownDisplay.push(platform.get('name'));
        platformDropdownValues.push(parseInt(platform.id));
      });
      this.controller.set('platformDropdownValues', platformDropdownValues);
      this.controller.set('platformDropdownDisplay', platformDropdownDisplay);
    },
    reloadSupplier: function reloadSupplier() {
      return this.controller.get('supplierBranch').reload();
    },
    setPartnerSupplierBranchId: function setPartnerSupplierBranchId() {
      /*
      * Details like partner_id, partner_supplier_branch_id is only available
      * in the supplier branch details api
      * */
      var supplierDetails = this.controller.get('supplierDetails').get('branches');
      var supplierBranchId = this.controller.get('supplierBranch.id');

      var partnerSupplierDetails = supplierDetails.findBy('supplier_branch_id', parseInt(supplierBranchId));

      this.controller.set('partnerSupplierDetails', partnerSupplierDetails);
      var isAdminPartnerIdsFlagEnabled = this.get('featureFlags').checkEnabled('AdminPartnerIDS');
      this.controller.set('isAdminPartnerIdsFlagEnabled', isAdminPartnerIdsFlagEnabled);
      if (partnerSupplierDetails && partnerSupplierDetails.partner_id) {
        this.controller.set('connectedSupplier', true);
        this.controller.set('partnerIdCopy', partnerSupplierDetails.partner_id);
      } else {
        this.controller.set('connectedSupplier', false);
      }
    },


    actions: {
      saveExternalPlatform: function saveExternalPlatform(referencesData) {
        var _this = this;

        //updating connectivity_status in localstorage
        var sessionData = this.get('session.data');
        Ember.set(sessionData.authenticated.selectedBranch, 'platform_id', referencesData.partner_id);
        Ember.set(sessionData.authenticated.selectedBranch, 'connectivity_status', referencesData.connectivity_status);
        this.get('session.store').persist(sessionData);

        this.controller.set('isSavingPlatform', true);
        this.controller.get('supplierBranch').updateReferences(referencesData).then(function (response) {
          _this.controller.set('partnerIdCopy', response.partner_id);
          _this.store.peekRecord('platform', response.platform_id).hasMany('platformSupplierBranches').reload();
        })
        //relaod to update connectivity_status of referencesData in platform/supplier-branch model as well
        .then(function () {
          return _this.reloadSupplier();
        }).then(function () {
          _this.controller.set('isEditPlatformSection', false);
          _this.controller.set('lastSaved', 'platformSettings');
          _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (errors) {
          _this.controller.set('errors', errors);
        }).finally(function () {
          _this.controller.set('isSavingPlatform', false);
        });
      },
      editPlatformSettings: function editPlatformSettings(state) {
        if (!state || this.isEditAllowed('platformSection')) {
          this.controller.set('isEditPlatformSection', state);
        } else {
          this.controller.set('sectionToBeEdited', 'platformSection');
        }
      },
      cancelPlatformEdit: function cancelPlatformEdit() {
        this.controller.get('supplierBranch').rollbackAttributes();
        this.controller.set('partnerSupplierDetails.partner_id', this.controller.get('partnerIdCopy'));
        this.send('editPlatformSettings', false);
      }
    }
  });
});