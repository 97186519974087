define('lxso/helpers/format-24-to-12hrs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var format24HrTo12Hr = function format24HrTo12Hr(params) {
    if (params[0] && params[0].split(':').length === 2) {
      var hr = parseInt(params[0].split(':')[0]);
      var min = params[0].split(':')[1];
      if (hr > 12) {
        return hr - 12 + ':' + min;
      } else {
        return params[0];
      }
    } else {
      return params[0];
    }
  };

  exports.default = Ember.Helper.helper(format24HrTo12Hr);
});