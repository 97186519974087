define('lxso/mixins/enum-getter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getEnumFunction: function getEnumFunction(url, query, minQueryLength) {
      var _this = this;

      return function (query) {
        if (query) {
          var transform = function transform(data) {
            return { data: data };
          };

          return _this.get('ajaxWrapper').ajaxGet('' + url + query, transform);
        } else {
          return new Ember.RSVP.resolve();
        }
      };
    },
    getEnumFunctionWithTransform: function getEnumFunctionWithTransform(url, transform) {
      var _this2 = this;

      return function (query) {
        if (query) {
          return _this2.get('ajaxWrapper').ajaxGet('' + url + query, transform);
        } else {
          return new Ember.RSVP.resolve();
        }
      };
    }
  });
});