define('lxso/routes/logout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),

    beforeModel: function beforeModel(transition) {
      var logoutSource = window.location.hash.split('source=')[1];
      if (this.get('session.isAuthenticated')) {
        transition.send('applicationLogout', logoutSource === 'epc');
      } else {
        this.transitionTo('/');
      }
    },

    actions: {
      willTransition: function willTransition() {
        //for tests
        delete window.pageName;
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'logoutRoute';
      }
    }

  });
});