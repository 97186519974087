define('lxso/validators/option-group-overlap', ['exports', 'ember-cp-validations/validators/base', 'lodash/array', 'lxso/utils/overlap-failure-reducer', 'lxso/constants/option-group'], function (exports, _base, _array2, _overlapFailureReducer, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OptionGroupOverlapValidation = _base.default.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),

    validate: function validate(value, options, model, attribute) {
      var offers = model.get('offers').filter(function (offer) {
        return offer.get('status') === _optionGroup.STATUS.ACTIVE;
      });
      var errorMessage = this.get('i18n').t('pages.optionGroup.errorMessages.duplicateOptions').string;
      var failures = [];
      offers.forEach(function (iOffer, i) {
        offers.forEach(function (jOffer, j) {
          var hasCommonLanguages = _array2.default.intersection(iOffer.get('languageInfo.languageIds'), jOffer.get('languageInfo.languageIds')).length > 0;
          if (i != j && iOffer.get('startTime') === jOffer.get('startTime') && iOffer.get('languageInfo.languageTypeId') === jOffer.get('languageInfo.languageTypeId') && hasCommonLanguages) {
            failures.push('optionGroupOverlap' + i, 'optionGroupOverlap' + j);
          }
        });
      });
      if (failures.length > 0) {
        return (0, _overlapFailureReducer.overlapFailureReducer)(failures, errorMessage);
      } else {
        return true;
      }
    }
  });

  exports.default = OptionGroupOverlapValidation;
});