define('lxso/routes/campaign-list', ['exports', 'lxso/mixins/require-permission', 'lxso/mixins/feature-flag', 'lxso/utils/omniture'], function (exports, _requirePermission, _featureFlag, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_featureFlag.default, _requirePermission.default, {
    featureName: 'ManageCampaign',
    requiredPermission: 'CampaignView',
    routeHistory: Ember.inject.service(),

    model: function model(params) {
      var branchId = params.branchId;
      return Ember.RSVP.hash({
        campaigns: this.store.query('campaign', { branchId: branchId }),
        branchId: branchId
      });
    },

    omniture: {
      pageName: 'page.LX-Supply.Campaigns'
    },

    setupController: function setupController(controller, model) {
      controller.setProperties({
        campaigns: model.campaigns,
        branchId: model.branchId
      });
    },


    actions: {
      joinCampaign: function joinCampaign(campaignId, isJoinCampaignButton) {
        if (isJoinCampaignButton) {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Campaigns.Join-Campaign');
        } else {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Campaigns.Add-Another-Promotion');
        }
        this.get('routeHistory').setPromotionBackURL('campaign-list');
        this.transitionTo('promotion', 'new', {
          queryParams: {
            productId: null,
            optionId: null,
            clone: null,
            campaignId: campaignId
          }
        });
      },
      manageCampaign: function manageCampaign(campaignId) {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.Campaigns.View-Joined-Promotions');
        this.transitionTo('promotion-list', this.controller.get('branchId'), {
          queryParams: {
            productId: null,
            optionId: null,
            clone: null,
            campaignId: campaignId
          }
        });
      }
    }
  });
});