define('lxso/authenticators/lxpc', ['exports', 'ember-simple-auth/authenticators/base', 'lxso/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var loginUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/system/login';

  exports.default = _base.default.extend({
    restore: function restore(sessionData) {
      return new Ember.RSVP.resolve(sessionData);
    },
    authenticate: function authenticate(options) {
      var _this = this;

      /**
       * The API login route will return a user object if the user has an
       * authenticated session even if invalid credentials are entered into the
       * login form. This can create confusion if the user logs in with invalid
       * credentials and is allowed to proceed. To combat this, we explicitly log
       * the user out before attempting any login to ensure there is not an
       * existing session which will allow pass through with invalid credentials.
       */
      var getFeaturesAndPermissions = function getFeaturesAndPermissions(response, resolve, reject) {
        var store = Ember.getOwner(_this).lookup('service:store');

        Ember.RSVP.all([store.findAll('enums/feature', { reload: true }), store.findAll('system/permission', { reload: true })]).then(function () {
          return resolve(response);
        }).catch(function (err) {
          return reject(err);
        });
      };

      return new Ember.RSVP.Promise(function (resolve, reject) {
        // Logout
        Ember.$.ajax({
          url: loginUrl,
          type: 'delete', // Changed to `method` in jQuery 1.9
          crossDomain: true,
          cache: false,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8',
          xhrFields: {
            withCredentials: true
          }
        })

        // Login
        .always(function () {
          Ember.$.ajax({
            url: loginUrl,
            type: 'post', // Changed to `method` in jQuery 1.9
            crossDomain: true,
            cache: false,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            xhrFields: {
              withCredentials: true
            },
            data: JSON.stringify({
              login: options.username,
              password: options.password
            })
          })

          // Authentication success
          .done(function (response) {
            return getFeaturesAndPermissions(response, resolve, reject);
          })
          // Authentication failure
          .fail(function (reason) {
            return getFeaturesAndPermissions(reason, resolve, reject);
          });
        });
      });
    },
    invalidate: function invalidate() {
      var _this2 = this;

      return Ember.$.ajax({
        url: loginUrl,
        type: 'delete', // Changed to `method` in jQuery 1.9
        crossDomain: true,
        cache: false,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        xhrFields: {
          withCredentials: true
        }
      }).then(function () {
        return Ember.getOwner(_this2).lookup('service:store').findAll('system/permission');
      }, { reload: true });
    }
  });
});