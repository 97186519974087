define('lxso/models/fragments/hotel-zone/hotel-address', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({});

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    city: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    streetSecondLine: _emberData.default.attr('string')
  });
});