define('lxso/components/promotions/promotion-errors/component', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    responseErrors: Ember.inject.service('response-errors'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      var errors = this.get('responseErrors.errors');
      var formattedErrors = [];
      var errorMessageKeys = this.get('errorMessageKeys');
      errors.forEach(function (error) {
        var formattedError = {};
        formattedError.key = error.key;
        if (errorMessageKeys.indexOf(error.key) >= 0) {
          formattedErrors.push({
            key: error.key,
            details: _this.formatTableData(error.message)
          });
          _this.sendOmnitureDataForError(error.key);
        } else {
          formattedErrors.push({
            key: error.key,
            message: error.message
          });
        }
      });
      this.set('formattedErrors', formattedErrors);
    },
    formatTableData: function formatTableData(message) {
      var tableRows = [];
      var products = JSON.parse(message);
      products.forEach(function (product, prodIdx) {
        var rowsUnderProduct = 0;
        product.offers.forEach(function (offer) {
          rowsUnderProduct += offer.tickets.length;
        });
        product.offers.forEach(function (offer, optionIdx) {
          offer.tickets.forEach(function (ticket, tktIdx) {
            var tableRow = {};
            tableRow.ticketName = ticket.name;
            if (tktIdx === 0) {
              tableRow.optionName = offer.name;
              tableRow.optionRowSpan = offer.tickets.length;
              if (optionIdx === 0) {
                tableRow.productName = product.name;
                tableRow.productRowSpan = rowsUnderProduct;
              }
            }
            tableRow.ticket = ticket.name;
            tableRows.push(tableRow);
          });
        });
      });
      return tableRows;
    },
    sendOmnitureDataForError: function sendOmnitureDataForError(errorKey) {
      var omnitureErrorMap = new Map([['1001', 'error.create.promooverlap'], ['1003', 'error.create.maxdiscount'], ['1004', 'error.create.mindiscount'], ['1005', 'error.create.negativemargin']]);
      if (omnitureErrorMap.has(errorKey)) {
        (0, _omniture.sendOmnitureErrorsOnClick)(omnitureErrorMap.get(errorKey));
      }
    },


    errorMessageKeys: Ember.A(['1000', '1001', '1002', '1003', '1004', '1005', '1006', '1007', '1008']),

    // Indicates if there are errors in the response errors service
    hasErrors: Ember.computed('responseErrors.errors.[]', function () {
      return !!this.get('responseErrors.errors').length;
    })

  });
});