define('lxso/helpers/is-usage-days-supported', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    session: Ember.inject.service(),
    staticData: Ember.inject.service(),

    compute: function compute() {
      var selectedBranchPlatformId = this.get('session.session.authenticated.selectedBranch.platform_id');
      var usageDaysSupportedPlatformIds = this.get('staticData').getUsageDaysSupportedPlatformIds();
      return isFinite(selectedBranchPlatformId) && usageDaysSupportedPlatformIds.includes(selectedBranchPlatformId);
    }
  });
});