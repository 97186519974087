define('lxso/models/activity/highlights', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation', 'lxso/utils/regexPatterns'], function (exports, _emberData, _emberDataModelFragments, _validation, _regexPatterns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    highlights: {
      descriptionKey: 'fieldNames.activity.highlights',
      validators: [(0, _validation.validator)('array-length', {
        is: 5
      }), (0, _validation.validator)('array-content-presence', {
        presence: true,
        messageKey: 'errors.customMessages.highlights.requiresOne'
      }), (0, _validation.validator)('array-content-length', {
        min: 4,
        max: 60,
        messageKey: 'errors.customMessages.highlights.tooShort'
      }), (0, _validation.validator)('array-input-regex-validation', {
        messageKey: 'errors.customMessages.highlights.invalidCharacters',
        composableErrorMessage: true
      })]
    },
    inclusions: {
      description: 'Inclusions',
      validators: [(0, _validation.validator)('array-length', {
        gte: 1
      }), (0, _validation.validator)('array-content-presence', {
        presence: true,
        messageKey: 'errors.customMessages.inclusions.requiresOne'
      }), (0, _validation.validator)('array-content-length', {
        min: 4,
        max: 100,
        messageKey: 'errors.customMessages.inclusions.tooShort'
      }), (0, _validation.validator)('array-content-format', {
        regex: _regexPatterns.validForm.validFormChar,
        messageKey: 'errors.customMessages.inclusions.format'
      })]
    },
    exclusions: {
      description: 'Exclusions', //exclusions are optional, but if one is present validate its content
      validators: [(0, _validation.validator)('array-content-length', {
        allowBlank: true,
        min: 4,
        max: 100,
        messageKey: 'errors.customMessages.exclusions.tooShort'
      }), (0, _validation.validator)('array-content-format', {
        allowBlank: true,
        regex: _regexPatterns.validForm.validFormChar
      })]
    }
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    exclusions: _emberData.default.attr('array'),
    highlights: _emberData.default.attr('array'),
    inclusions: _emberData.default.attr('array')
  });
});