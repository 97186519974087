define('lxso/routes/activity/option-settings', ['exports', 'lxso/mixins/require-permission', 'lxso/utils/form-validator', 'lxso/mixins/feature-flag', 'lxso/config/environment', 'lxso/mixins/enum-getter', 'lxso/utils/arrays'], function (exports, _requirePermission, _formValidator, _featureFlag, _environment, _enumGetter, _arrays) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_requirePermission.default, _enumGetter.default, {
    requiredPermission: 'V3InternalSettings',
    session: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    store: Ember.inject.service(),
    loadingTime: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.OptionSettings'
      //TODO: Verify with product what should be here.
    },
    queryParams: {
      optionId: { refreshModel: false }
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return this.store.queryRecord('activity', { 'id': this.modelFor('activity').get('id'),
        'fields': 'optionSettings' });
    },
    model: function model(params) {
      var allCatalogsUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/catalogs';
      var offers = this.modelFor('activity').get('offers');
      if (parseInt(params.optionId)) {
        var selectedOption = offers.findBy('offer_id', parseInt(params.optionId));
        return new Ember.RSVP.hash({
          params: params,
          offers: offers,
          selectedOption: selectedOption,
          offerAttributes: selectedOption.getAttributes(),
          allOfferAttributes: [],
          offerCatalogs: selectedOption.getCatalogs(),
          allCatalogs: this.get('ajaxWrapper').ajaxGet(allCatalogsUrl)
        });
      } else {
        return { params: params };
      }
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
      var geoIdUrl = domain + '/enums/geoids?q=';
      var geoIdTransform = function geoIdTransform(inputData) {
        var outputData = {
          data: []
        };
        var selectedGeoIds = controller.get('offerAttributeGeoIds');
        inputData.items.forEach(function (item) {
          if (selectedGeoIds.indexOf(item.id) < 0) {
            outputData.data.push({
              display: item.name,
              value: item.id
            });
          }
        });
        return outputData;
      };

      var route = this;

      controller.setProperties({
        params: model.params,
        activity: this.modelFor('activity'),
        selectedOption: model.selectedOption,
        unSortedOptions: model.offers.map(function (option, index) {
          option.set('combinedTitle', option.get('internal_title') + ' (' + option.get('offer_id') + ')');
          option.set('isOpen', parseInt(model.params.optionId) === option.get('offer_id'));
          option.set('offerAttributes', model.allOfferAttributes[index]);
          return option;
        }),
        options: Ember.computed('unSortedOptions', function () {
          return this.get('unSortedOptions').sortBy('status', 'internal_title');
        }),
        offerAttributes: model.offerAttributes,
        offerCatalogs: model.offerCatalogs,
        offerCatalogsIds: Ember.computed('offerCatalogs', function () {
          return this.get('offerCatalogs').getEach('id');
        }),
        allCatalogs: model.allCatalogs,
        allCatalogsArray: Ember.computed('offerCatalogsIds', 'offerCatalogs', function () {
          return this.get('allCatalogs').map(function (item) {
            return Ember.Object.create(item);
          });
        }),
        allCatalogsWithIsSelected: Ember.computed('allCatalogsArray', function () {
          var _this = this;

          var allCatalogsArray = this.get('allCatalogsArray');
          return allCatalogsArray.map(function (item) {
            Ember.set(item, 'isSelected', _this.get('offerCatalogsIds').includes(item.id));
            return item;
          });
        }),
        offerAttributeGeoIds: Ember.computed('offerAttributes.geoids', function () {
          return this.get('offerAttributes.geoids').getEach('id');
        }),
        isGeoIdsDirty: Ember.computed('offerAttributeGeoIds', 'offerAttributesCopy', 'editing', function () {
          var editing = this.get('editing');
          var offerAttributesGeoIds = this.get('offerAttributeGeoIds');
          var offerAttributesCopyGeoIds = this.get('offerAttributesCopy.geoids').map(function (geoid) {
            return geoid.id;
          });
          if (editing) {
            return !(0, _arrays.containSameElements)(offerAttributesGeoIds, offerAttributesCopyGeoIds);
          } else {
            return false;
          }
        }),
        isCatalogsDirty: Ember.computed('offerCatalogsCopy', 'offerCatalogs', 'editing', function () {
          var editing = this.get('editing');
          var offerCatalogsCopyIds = this.get('offerCatalogsCopy').map(function (catalog) {
            return catalog.id;
          });
          var offerCatalogsIds = this.get('offerCatalogs').map(function (catalog) {
            return catalog.id;
          });;
          if (editing) {
            return !(0, _arrays.containSameElements)(offerCatalogsCopyIds, offerCatalogsIds);
          } else {
            return false;
          }
        }),
        isSaveEnabled: Ember.computed('isGeoIdsDirty', 'isCatalogsDirty', 'isConnectivityStatusDirty', 'editing', 'isApplyGeoIdsToActiveOptions', 'isApplyGeoIdsToInactiveOptions', 'isApplyCatalogsToActiveOptions', 'isApplyCatalogsToInactiveOptions', function () {
          var isGeoIdsDirty = this.get('isGeoIdsDirty');
          var isCatalogsDirty = this.get('isCatalogsDirty');
          var isConnectivityStatusDirty = this.get('isConnectivityStatusDirty');
          var isApplyCatalogsToInactive = this.get('isApplyCatalogsToInactiveOptions');
          var isApplyCatalogsToActive = this.get('isApplyCatalogsToActiveOptions');
          var isApplyGeoidsToActive = this.get('isApplyGeoIdsToActiveOptions');
          var isApplyGeoidsToInactive = this.get('isApplyGeoIdsToInactiveOptions');
          return isGeoIdsDirty || isCatalogsDirty || isConnectivityStatusDirty || isApplyGeoidsToActive || isApplyGeoidsToInactive || isApplyCatalogsToActive || isApplyCatalogsToInactive;
        }),
        hasSupplierAffliateId: Ember.computed('selectedOption', function () {
          return route.get('session.session.authenticated.selectedBranch.affiliate_id') > 0;
        }),
        hideCatalogsEdit: Ember.computed('hasSupplierAffliateId', 'selectedOption', function () {
          var hasSupplierAffliateId = this.get('hasSupplierAffliateId');
          var hasTshopID = this.get('selectedOption.oiid') > 0;
          return !hasSupplierAffliateId || !hasTshopID;
        }),
        selectedCatalog: null,
        editing: false,
        isConnectivityStatusDirty: false,
        connectedOffer: true,
        connectedSupplier: true,
        saving: false,
        isApplyCatalogsToActiveOptions: false,
        isApplyCatalogsToInactiveOptions: false,
        isApplyGeoIdsToActiveOptions: false,
        isApplyGeoIdsToInactiveOptions: false,
        geoIdSource: this.getEnumFunctionWithTransform(geoIdUrl, geoIdTransform)
      });
      var supplierConnectivityStatus = this.get('session').get('data.authenticated.selectedBranch.connectivity_status');
      if (supplierConnectivityStatus === undefined) {
        this.controller.set('connectedSupplier', false);
      }
      this.setOfferExternalRefRes();
      Ember.run.next(function () {
        var timeTaken = _this2.get('loadingTime').getLoadTime();
        console.info('%c Option settings page load time is ' + timeTaken + ' seconds', 'font-weight: bold');
        _this2.setupStep();
      });
    },
    setupStep: function setupStep() {
      var optionId = this.controller.get('optionId');
      var optionClassName = 'option-' + optionId;
      var links = $('.title-main.toggle-trigger.' + optionClassName);
      $(links).addClass('open');
    },
    setOfferExternalRefRes: function setOfferExternalRefRes() {
      var _this3 = this;

      var offerID = this.controller.get('optionId');
      if (offerID) {
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/offers/' + offerID + '/external_references';
        this.get('ajaxWrapper').ajaxGet(url).then(function (externalRefResponse) {
          _this3.controller.set('offerExternalRefRes', externalRefResponse);
          _this3.controller.set('activity.offers.isConnectivityEnabled', externalRefResponse.connectivityStatus);
          if (externalRefResponse.connectivityStatus === undefined) {
            _this3.controller.set('connectedOffer', false);
          }
        });
      }
    },
    setCatalogUpdateData: function setCatalogUpdateData(newCatalogs, offerDetails) {
      var newlyAddedCatalogPlainObj = JSON.parse(JSON.stringify(newCatalogs));

      var catalogInfo = {};
      catalogInfo.offerId = parseInt(offerDetails.get('id'));
      catalogInfo.catalogs = newlyAddedCatalogPlainObj;

      return catalogInfo;
    },
    saveCatalogInformation: function saveCatalogInformation(catalogData) {
      var data = {
        data: catalogData
      };
      var allCatalogsUrl = _environment.default.api.host + '/api/v3/offer/catalogs';
      return this.get('ajaxWrapper').ajaxPut(allCatalogsUrl, data);
    },
    formatGeoIdData: function formatGeoIdData(data) {
      return data.map(function (geoid) {
        var res = {};
        res.id = parseInt(geoid.id);
        res.name = geoid.name;

        return res;
      });
    },
    setGeoIDUpdateData: function setGeoIDUpdateData(newGeoIds, offerDetails) {
      var newlyAddedGeoPlainObj = JSON.parse(JSON.stringify(newGeoIds));

      var geoInfo = {};
      geoInfo.offerId = parseInt(offerDetails.get('id'));
      geoInfo.geoIds = this.formatGeoIdData(newlyAddedGeoPlainObj);

      return geoInfo;
    },
    saveGeoIdsInformation: function saveGeoIdsInformation(geoIdsData) {
      var data = {
        data: geoIdsData
      };
      var allGeoIdsUrl = _environment.default.api.host + '/api/v3/offer/geoids';
      return this.get('ajaxWrapper').ajaxPut(allGeoIdsUrl, data);
    },


    actions: {
      loading: function loading(transition, optionSettingsRoute) {
        if (optionSettingsRoute.controller) {
          optionSettingsRoute.controller.set('isOptionLoading', true);
          transition.promise.finally(function () {
            optionSettingsRoute.controller.set('isOptionLoading', false);
          });
        }
      },
      retryOptionTshopId: function retryOptionTshopId(option) {
        var _this4 = this;

        this.controller.set('processingOptionTshopRetry', true);
        option.createTshopId({
          activity_id: parseInt(this.controller.get('activity.id'))
        }).then(function (response) {
          _this4.controller.set('processingOptionTshopRetry', false);
          return _this4.controller.get('activity').reload();
        }).then(function () {
          _this4.get('notifications').success(_this4.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (errors) {
          Ember.Logger.error('error creating option tshopId', errors);
          _this4.controller.set('processingOptionTshopRetry', false);
          _this4.controller.set('errors', errors);
          _this4.get('notifications').error(_this4.get('i18n').t('components.alert.saveFailure'));
        });
      },
      openStep: function openStep(option, stepClass) {
        var allOptionsLink = $('.option-settings-page .title-main.toggle-trigger');
        $(allOptionsLink).removeClass('open');
        if (option.isOpen) {
          option.set('isOpen', false);
          this.controller.set('optionId', null);
        } else {
          var prevSelectedOption = this.controller.get('selectedOption');
          if (prevSelectedOption) {
            prevSelectedOption.set('isOpen', false);
          }
          this.controller.set('optionId', option.id);
          option.set('isOpen', true);
          this.refresh();
        }
      },
      goToActivitySettings: function goToActivitySettings() {
        this.transitionTo('activity.activity-settings');
      },
      connectivityStatusChange: function connectivityStatusChange() {
        this.controller.set('isConnectivityStatusDirty', true);
        this.controller.toggleProperty('activity.offers.isConnectivityEnabled');
      },
      catalogSelected: function catalogSelected(catalog) {
        this.controller.set('selectedCatalog', catalog);
      },
      addCatalog: function addCatalog(catalog) {
        var offerCatalogs = this.controller.get('offerCatalogs');
        if (catalog.id && offerCatalogs.indexOf(catalog) < 0) {
          this.controller.get('offerCatalogs').pushObject(catalog);
          this.controller.notifyPropertyChange('offerCatalogs');
        }
      },
      removeCatalog: function removeCatalog(catalog) {
        var offerCatalogs = this.controller.get('offerCatalogs');
        if (catalog.id) {
          var catalogsToremove = this.controller.get('offerCatalogs').filter(function (item) {
            return item.id === catalog.id;
          });
          if (catalogsToremove.length) {
            this.controller.get('offerCatalogs').removeObjects(catalogsToremove);
          }
          this.controller.notifyPropertyChange('offerCatalogs');
        }
      },
      addCatalogFromTypeAhead: function addCatalogFromTypeAhead(catalog) {
        if (!catalog || !catalog.id) {
          return;
        }
        var isCatalogSelected = catalog.toggleProperty('isSelected');
        if (isCatalogSelected) {
          this.send('addCatalog', catalog);
        } else {
          this.send('removeCatalog', catalog);
        }
      },
      selectOrDeselectAllCatalogs: function selectOrDeselectAllCatalogs(isSelectAll, items) {
        var _this5 = this;

        if (isSelectAll) {
          items.forEach(function (item, index) {
            item.original.set('isSelected', true);
            _this5.send('addCatalog', item.original);
          });
        } else {
          items.forEach(function (item, index) {
            item.original.set('isSelected', false);
            _this5.send('removeCatalog', item.original);
          });
        }
      },
      selectGeoId: function selectGeoId(geoId) {
        if (geoId.value) {
          var newGeoId = {
            id: geoId.value,
            name: geoId.display
          };
          this.controller.get('offerAttributes.geoids').pushObject(newGeoId);
          this.controller.notifyPropertyChange('offerAttributes.geoids');
        }
      },
      removeGeoId: function removeGeoId(geoId) {
        this.controller.get('offerAttributes.geoids').removeObject(geoId);
        this.controller.notifyPropertyChange('offerAttributes.geoids');
      },
      editOption: function editOption() {
        var offerCatalogsCopy = JSON.parse(JSON.stringify(this.controller.get('offerCatalogs')));
        this.controller.set('offerCatalogsCopy', offerCatalogsCopy);
        var offerAttributesCopy = JSON.parse(JSON.stringify(this.controller.get('offerAttributes')));
        this.controller.set('offerAttributesCopy', offerAttributesCopy);
        var connectivityStatusCopy = this.controller.get('activity.offers.isConnectivityEnabled');
        this.controller.set('connectivityStatusCopy', connectivityStatusCopy);
        this.controller.set('editing', true);
      },
      saveOptionSettings: function saveOptionSettings(isApplyGeoIdsToActiveOptions, isApplyGeoIdsToInactiveOptions, isApplyCatalogsToActiveOptions, isApplyCatalogsToInactiveOptions) {
        var _this6 = this;

        var selectedOption = this.controller.get('selectedOption');
        this.controller.set('saving', true);

        var currentConnectionStatus = this.controller.get('activity.offers.isConnectivityEnabled');
        this.controller.set('offerExternalRefRes.connectivityStatus', currentConnectionStatus);
        var externalRefData = this.controller.get('offerExternalRefRes');
        var attributes = this.controller.get('offerAttributes');
        var isGeoIdsDirty = this.controller.get('isGeoIdsDirty');
        var isCatalogsDirty = this.controller.get('isCatalogsDirty');
        var isConnectivityStatusDirty = this.controller.get('isConnectivityStatusDirty');
        var promises = [];
        var catalogData = [];
        var geoData = [];

        if (isCatalogsDirty) {
          catalogData.push(this.setCatalogUpdateData(this.controller.get('offerCatalogs'), selectedOption));
        }

        if (isApplyCatalogsToActiveOptions || isApplyCatalogsToInactiveOptions) {
          this.controller.get('options').forEach(function (option) {
            if (isApplyCatalogsToActiveOptions && option.get('status') == 'active' && option.id !== selectedOption.id && option.get('oiid') > 0) {
              catalogData.push(_this6.setCatalogUpdateData(_this6.controller.get('offerCatalogs'), option));
            }

            if (isApplyCatalogsToInactiveOptions && option.get('status') == 'inactive' && option.id !== selectedOption.id && option.get('oiid') > 0) {
              catalogData.push(_this6.setCatalogUpdateData(_this6.controller.get('offerCatalogs'), option));
            }
          });
        }

        if (isCatalogsDirty || isApplyCatalogsToActiveOptions || isApplyCatalogsToInactiveOptions) {
          promises.push(this.saveCatalogInformation(catalogData));
        }

        if (isGeoIdsDirty) {
          geoData.push(this.setGeoIDUpdateData(attributes.geoids, selectedOption));
        }

        if (isApplyGeoIdsToActiveOptions || isApplyGeoIdsToInactiveOptions) {
          this.controller.get('options').forEach(function (option) {
            if (isApplyGeoIdsToActiveOptions && option.get('status') == 'active' && option.id !== selectedOption.id) {
              geoData.push(_this6.setGeoIDUpdateData(attributes.geoids, option));
            }

            if (isApplyGeoIdsToInactiveOptions && option.get('status') == 'inactive' && option.id !== selectedOption.id) {
              geoData.push(_this6.setGeoIDUpdateData(attributes.geoids, option));
            }
          });
        }

        if (isConnectivityStatusDirty) {
          promises.push(selectedOption.updateConnectivityStatus(externalRefData));
        }

        if (isGeoIdsDirty || isApplyGeoIdsToActiveOptions || isApplyGeoIdsToInactiveOptions) {
          promises.push(this.saveGeoIdsInformation(geoData));
        }

        Ember.RSVP.all(promises).then(function () {
          _this6.controller.set('errors', []);
          _this6.get('notifications').success(_this6.get('i18n').t('components.alert.saveSuccess'));
          _this6.controller.set('editing', false);
          _this6.controller.set('isApplyCatalogsToActiveOptions', false);
          _this6.controller.set('isApplyCatalogsToInactiveOptions', false);
          _this6.controller.set('isApplyGeoIdsToActiveOptions', false);
          _this6.controller.set('isApplyGeoIdsToInactiveOptions', false);
        }).then(function () {
          return _this6.controller.get('activity').reload();
        }).catch(function (errors) {
          Ember.Logger.error('error saving option settings', errors);
          _this6.controller.set('errors', errors);
          _this6.get('notifications').error(_this6.get('i18n').t('components.alert.saveFailure'));
        }).finally(function () {
          _this6.controller.set('saving', false);
        });
      },
      cancelEditOptions: function cancelEditOptions() {
        this.controller.set('offerCatalogs', this.controller.get('offerCatalogsCopy'));
        this.controller.set('offerAttributes', this.controller.get('offerAttributesCopy'));
        this.controller.set('activity.offers.isConnectivityEnabled', this.controller.get('connectivityStatusCopy'));
        this.controller.set('editing', false);
        this.controller.set('isApplyCatalogsToActiveOptions', false);
        this.controller.set('isApplyCatalogsToInactiveOptions', false);
        this.controller.set('isApplyGeoIdsToActiveOptions', false);
        this.controller.set('isApplyGeoIdsToInactiveOptions', false);
      }
    }

  });
});