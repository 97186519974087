define('lxso/validators/duplicate-offer-details', ['exports', 'ember-cp-validations/validators/base', 'lodash'], function (exports, _base, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DuplicateOfferDetails = _base.default.extend({
    i18n: Ember.inject.service(),
    validate: function validate(offerDetails, options, model, attribute) {
      'use strict';

      var _this = this;

      var offerDetailsToValidate = offerDetails.filter(function (offerDetail) {
        return offerDetail.status != 'inactive';
      });
      var groupedDuplicateOfferDetails = _lodash.default.groupBy(offerDetailsToValidate, function (offer) {
        var startEndAttrs = offer.startTime + ':;:' + offer.endTime + ':;:' + offer.languageInfo.languageIDs.toString();
        if (model.get('offerAttributes.scheduleType') == 'Valid Days') {
          startEndAttrs = offer.durationInMinutes + ':;:' + offer.languageInfo.languageIDs.toString();
        }
        var commentaryAttrs = offer.languageInfo.languageIDs.toString() + ':;:' + offer.languageInfo.languageTypeId;
        return startEndAttrs + ':;:' + commentaryAttrs;
      });

      var validLangTypes = [1, 2, 3];
      var failures = [];
      for (var duplicateOfferDetailListKey in groupedDuplicateOfferDetails) {
        var offers = groupedDuplicateOfferDetails[duplicateOfferDetailListKey];
        if (offers.length > 1) {
          (function () {
            var offer = offers[0];
            var allLanguagesList = model.languageLookupHash;
            if (validLangTypes.includes(offer.languageInfo.languageTypeId)) {
              var commentary = _this.get('i18n').t('errors.customMessages.offerGroup.duplicateOfferGroup.langType.' + offer.languageInfo.languageTypeId);
              var languages = offer.languageInfo.languageTypeId !== 3 ? offer.languageInfo.languageIDs.map(function (langId) {
                return allLanguagesList[langId];
              }).join(', ') : '';
              languages = languages.length ? ' in ' + languages : '' + languages;
              failures.push('Duplicate offers with Start: ' + offer.startTime + ', Finish:\n            ' + offer.endTime + ', ' + commentary + ' ' + languages);
            }
          })();
        }
      }
      if (failures.length > 0) {
        return failures.join(':;:');
      } else {
        return true;
      }
    }
  });

  DuplicateOfferDetails.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = DuplicateOfferDetails;
});