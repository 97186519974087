define('lxso/components/customer-service-information/component', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    uitk: Ember.inject.service(),
    featureFlags: Ember.inject.service(),

    actions: {
      editContact: function editContact() {
        this.sendAction('edit-contact-action');
        this.set('editingContactInfo', true);
        this.set('disableSave', false);
        var countryCode = this.get('editedSupport.phone.phone_country_code');
        var countryCodeInputText = countryCode ? countryCode : null;
        this.set('countryCodeInputText', countryCodeInputText);
      },
      saveContactInfo: function saveContactInfo() {
        this.set('saveErrors', undefined);
        this.set('editingContactInfo', true);
        this.send('validateIfInvalid');
        this.sendAction('save-contact-action', this.get('editedSupport'));
        this.set('editingContactInfo', this.get('disableSave'));
      },
      cancelEditContactInfo: function cancelEditContactInfo() {
        this.sendAction('cancel-edit-contact-info');
        this.get('support').rollbackAttributes();
        this.set('editingContactInfo', false);
        this.set('saveErrors', undefined);
        this.set('support.hasDirtyAttributes', false);
      },
      validateIfInvalid: function validateIfInvalid() {
        var _this = this;

        var editedSupport = this.get('editedSupport');
        this.set('disableSave', false);
        (0, _formValidator.validate)({
          model: editedSupport.get('phone'),
          attributes: ['phone_country_code', 'phone_area_code', 'phone', 'phone_ext', 'phone_usage']
        }, {
          model: editedSupport,
          attributes: ['email']
        }).then(function () {
          return _this.set('saveErrors', undefined);
        }).catch(function (errors) {
          _this.set('saveErrors', errors);
          _this.set('disableSave', true);
          Ember.Logger.error('Something went wrong: ', errors);
        });
      },
      updatedTypeahead: function updatedTypeahead(item) {
        this.set('editedSupport.phone.phone_country_code', item.phoneCode);
      },
      setDirty: function setDirty() {
        this.set('support.hasDirtyAttributes', true);
      },
      setPhoneUsage: function setPhoneUsage(value) {
        this.set('editedSupport.phone.phone_usage', value);
      },
      removeContact: function removeContact(support) {
        this.sendAction('removeContact', support);
      }
    }

  });
});