define('lxso/controllers/change-history', ['exports', 'lxso/mixins/date-range', 'lodash/collection', 'lodash/math', 'lxso/utils/filter-factory'], function (exports, _dateRange, _collection2, _math2, _filterFactory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var today = moment();
  var twoWeeksAgo = moment().subtract(2, 'weeks');

  exports.default = Ember.Controller.extend(_dateRange.default, {
    uitk: Ember.inject.service(),

    changeHistory: Ember.computed.alias('model'),

    dateRange: {
      from: twoWeeksAgo,
      to: today
    },

    fromDateFilter: (0, _filterFactory.sameOrAfterDate)('occurredAt', 'dateRange.from', 'day'),
    toDateFilter: (0, _filterFactory.sameOrBeforeDate)('occurredAt', 'dateRange.to', 'day'),
    productFilter: (0, _filterFactory.includesNumber)('product.productId', 'productId'),
    offerFilter: (0, _filterFactory.includesNumber)('offer.offerId', 'offerId'),
    fieldFilter: (0, _filterFactory.includesText)('dropdownDescription', 'selectedField'),

    selectedField: Ember.computed.alias('changeDetail'),

    filters: Ember.computed.collect('fromDateFilter', 'toDateFilter', 'productFilter', 'offerFilter', 'fieldFilter'),

    productFilters: Ember.computed.collect('fromDateFilter', 'toDateFilter'),
    offerFilters: Ember.computed.collect('fromDateFilter', 'toDateFilter', 'productFilter'),
    fieldFilters: Ember.computed.collect('fromDateFilter', 'toDateFilter', 'productFilter', 'offerFilter'),

    filteredChanges: Ember.computed('changeHistory', 'filters', function () {
      var filters = this.get('filters');
      return this.getFilteredChangeHistory(filters);
    }),

    latestProductNames: Ember.computed('changeHistory', function () {
      var changeHistory = this.get('changeHistory');
      var allProductsList = changeHistory.map(function (change) {
        var product = {
          productId: change.get('product.productId'),
          title: change.get('product.title'),
          changeId: parseInt(change.get('id'))
        };
        return product;
      });

      var allProductsGroupedById = _collection2.default.groupBy(allProductsList, 'productId');
      var allProductsIdNameMap = {};
      _collection2.default.forEach(allProductsGroupedById, function (set, key) {
        var latestProduct = _math2.default.maxBy(set, function (object) {
          return object.changeId;
        });
        allProductsIdNameMap[key] = latestProduct.title;
      });
      return allProductsIdNameMap;
    }),

    latestOfferNames: Ember.computed('changeHistory', function () {
      var changeHistory = this.get('changeHistory');
      var allOffersList = changeHistory.map(function (change) {
        var offer = {
          offerId: change.get('offer.offerId'),
          title: change.get('offer.title'),
          changeId: parseInt(change.get('id'))
        };
        return offer;
      });

      var allOffersGroupedById = _collection2.default.groupBy(allOffersList, 'offerId');
      var allOffersIdNameMap = {};
      _collection2.default.forEach(allOffersGroupedById, function (set, key) {
        var latestOffer = _math2.default.maxBy(set, function (object) {
          return object.changeId;
        });
        allOffersIdNameMap[key] = latestOffer.title;
      });
      return allOffersIdNameMap;
    }),

    dropdowns: Ember.computed('changeHistory', 'productFilters', 'offerFilters', 'fieldFilters', function () {
      var filteredProducts = this.getFilteredChangeHistory(this.get('productFilters'));
      var filteredOffers = this.getFilteredChangeHistory(this.get('offerFilters'));
      var filteredFields = this.getFilteredChangeHistory(this.get('fieldFilters'));

      var choicesFor = function choicesFor(field, choices) {
        return choices.filter(function (change) {
          return Ember.isPresent(change.get(field));
        }).map(function (change) {
          return change.get(field);
        }).uniq();
      };

      var latestProductNames = this.get('latestProductNames');
      var productIdList = choicesFor('product.productId', filteredProducts);
      var productNameWithIdList = productIdList.map(function (id) {
        return latestProductNames[id] + ' (' + id + ')';
      });
      var products = {
        productIdList: productIdList,
        productNameWithIdList: productNameWithIdList
      };

      var latestOfferNames = this.get('latestOfferNames');
      var offerIdList = choicesFor('offer.offerId', filteredOffers);
      var offerNameWithIdList = offerIdList.map(function (id) {
        return latestOfferNames[id] + ' (' + id + ')';
      });
      var offers = {
        offerIdList: offerIdList,
        offerNameWithIdList: offerNameWithIdList
      };
      var fields = choicesFor('dropdownDescription', filteredFields);
      return { products: products, offers: offers, fields: fields };
    }),

    getFilteredChangeHistory: function getFilteredChangeHistory(filters) {
      return this.get('changeHistory').filter(function (change) {
        return filters.every(function (filter) {
          return filter(change);
        });
      });
    },
    resetFilterIfNoMatchesFound: function resetFilterIfNoMatchesFound(dropdown, filter) {
      'use strict';

      if (!dropdown.includes(this.get(filter))) {
        this.set(filter, undefined);
      }
    },


    actions: {
      clearFilters: function clearFilters() {
        this.set('dateRangeFrom', twoWeeksAgo);
        this.set('dateRangeTo', today);
        this.set('productId', undefined);
        this.set('offerId', undefined);
        this.set('selectedField', undefined);
      },
      productSelected: function productSelected(selected) {
        if (selected) {
          this.set('productId', parseInt(selected));
        } else {
          this.set('productId', '');
        }
        this.resetFilterIfNoMatchesFound(this.get('dropdowns.offers.offerIdList'), 'offerId');
        this.resetFilterIfNoMatchesFound(this.get('dropdowns.fields'), 'selectedField');
      },
      offerSelected: function offerSelected(selected) {
        this.set('offerId', selected);
        this.resetFilterIfNoMatchesFound(this.get('dropdowns.fields'), 'selectedField');
      },
      fieldSelected: function fieldSelected(selected) {
        this.set('selectedField', selected);
      },
      details: function details(selectedChange) {
        this.set('selectedChange', selectedChange);
        this.get('uitk').modal.create({
          modalId: 'change-history-details',
          content: '#detailsModal'
        });
      }
    }
  });
});