define('lxso/adapters/zone-association', ['exports', 'lxso/adapters/application', 'lxso/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    query: function query(store, type, _query) {
      var zoneId = _query.zoneID;
      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
      var zoneAssociationsUrl = domain + '/location_lists/zones/' + zoneId + '/offers';
      var ajaxWrapper = this.get('ajaxWrapper');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        ajaxWrapper.ajaxGet(zoneAssociationsUrl, null, {
          xhrFields: {
            withCredentials: true
          }
        }).then(function (data) {
          if (data) {
            resolve(data);
          } else {
            resolve([]);
          }
        }, function (err) {
          reject(err);
        });
      });
    }
  });
});