define('lxso/helpers/errors-for', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.errorsFor = errorsFor;


  /**
   * Returns an array given errors in the following two formats:
   *
   * Client validation errors
   * {
   * 	attribute_name: [ 'Error detail', 'Error detail' ]
   * }
   *
   * Server validation errors
   * {
   * 	 errors: [
   * 	   {
   * 			 status: 400,
   * 		 	 id: 'attribute_name',
   * 		   detail: 'Error detail'
   *     }
   *   ]
   * }
   *
   * Usage:
   *
   * (errors-for 'attribute_name' errors)
   */
  function errorsFor(params /*, hash*/) {
    if (params[1]) {
      // If errors seem to be server validation errors
      if (params[1].errors && Array.isArray(params[1].errors) && params[1].errors.length) {
        var serverErrors = params[1].errors.map(function (error) {
          if (error.status === 400 && error.id === params[0]) {
            return error.detail;
          }
          return false;
        }).filter(function (error) {
          var err = error;
          return err;
        });

        return serverErrors;
      } else {
        // Else, the errors seem to be client validation errors
        return params[1][params[0]];
      }
    }
  }

  exports.default = Ember.Helper.helper(errorsFor);
});