define('lxso/routes/activity/gt-settings', ['exports', 'lxso/mixins/publish-api', 'lxso/mixins/enum-getter', 'lxso/mixins/require-gt-permission', 'lxso/utils/form-validator', 'lxso/utils/arrays', 'lxso/mixins/feature-flag', 'lxso/utils/array-values', 'lxso/config/environment'], function (exports, _publishApi, _enumGetter, _requireGtPermission, _formValidator, _arrays, _featureFlag, _arrayValues, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_requireGtPermission.default, _publishApi.default, _enumGetter.default, {
    // omniture: {
    //   //TODO: Verify with product what should be here.
    //   pageName: 'page.LX-Supply.Categories'
    // },
    requiredPermission: 'V3InternalSettings',
    session: Ember.inject.service(),
    internalCategories: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    durationType: Ember.inject.service(),
    loadingTime: Ember.inject.service(),
    v4ContentService: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.AddLocation'
    },
    queryParams: {
      selectedOffer: ''
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return this.reloadActivity();
    },
    model: function model() {
      var vehicleEnumUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/enums/ground_transport/vehicle_types';
      var directionEnumUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/enums/ground_transport/directions';
      return Ember.RSVP.hash({
        vehicleEnum: this.get('ajaxWrapper').ajaxGet(vehicleEnumUrl),
        directionEnum: this.get('ajaxWrapper').ajaxGet(directionEnumUrl)

      });
    },
    setupController: function setupController(controller, enums) {
      var _this = this;

      this.controller.set('activity', this.modelFor('activity'));
      var supplierBranchId = this.controller.set('supplierBranchId', this.get('session.data.authenticated.selectedBranch').supplier_branch_id);
      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
      var airportEnumUrl = domain + '/enums/ground_transport/airports?q=';
      var zoneEnumUrl = domain + '/location_lists/search/zones?supplierBranchId=' + supplierBranchId + '&nameDetails=';

      var arrayOf20 = (0, _arrayValues.getArrayOfValues)(20);
      var notApplicable = this.get('i18n').t('pages.gtSettings.labels.notApplicable');
      var baxEnum = [{ display: notApplicable, value: 0 }].concat(arrayOf20.map(function (val) {
        return { display: val, value: val };
      }));

      var zoneTransform = function zoneTransform(inputData) {
        var outputData = {
          data: []
        };
        inputData.forEach(function (item) {
          if (item.status === 'ACTIVE') {
            outputData.data.push(item);
          }
        });
        return outputData;
      };

      controller.setProperties({
        vehicleEnum: enums.vehicleEnum.data.filter(function (vehicle) {
          return vehicle.value != '';
        }),
        directionEnum: enums.directionEnum.data,
        zoneSource: this.getEnumFunctionWithTransform(zoneEnumUrl, zoneTransform),
        airportSource: this.getEnumFunction(airportEnumUrl),
        durationTypeEnum: this.get('durationType').get(),
        baxEnum: baxEnum,
        paxEnum: baxEnum,
        offerDropdownOptions: [{ display: '', value: '' }],
        editingProduct: false,
        editingOffer: false,
        activityDirty: false,
        offerDirty: false,
        isAnySectionEditing: Ember.computed.or('editingProduct', 'editingOffer'),
        offerAttributes: null
      });

      this._setupOfferDropdown();
      var selectedOffer = this.controller.get('selectedOffer');
      this.setUpPublishFieldsCopy();
      if (selectedOffer) {
        this._offerChange(selectedOffer);
      }

      Ember.run.next(function () {
        var timeTaken = _this.get('loadingTime').getLoadTime();
        console.info('%c GT settings page load time is ' + timeTaken + ' seconds', 'font-weight: bold');
      });
    },
    rollbackActivity: function rollbackActivity() {
      this.controller.get('activity.attributes').rollbackAttributes();
      this.controller.get('activity.things').rollbackAttributes();
    },
    rollbackOffer: function rollbackOffer() {
      if (this.controller.get('offer')) {
        this.controller.get('offer.attributes').rollbackAttributes();
        this.controller.set('zoneName', null);
        this.controller.set('airportLabel', null);
        this.controller.set('offer', null);
      }
    },
    reloadActivity: function reloadActivity() {
      return this.store.queryRecord('activity', { 'id': this.modelFor('activity').get('id'),
        'fields': 'basic,content,offers' });
    },
    setUpPublishFieldsCopy: function setUpPublishFieldsCopy() {
      this.controller.set('gtInstructionsCopy', this.controller.get('activity.things.gt_instructions'));
    },
    isPublishNeeded: function isPublishNeeded() {
      var isActivityPublished = this.controller.get('activity.isActivityPublished');
      var hasGtInstructionChanged = !(this.controller.get('gtInstructionsCopy') === this.controller.get('activity.things.gt_instructions'));
      return isActivityPublished && hasGtInstructionChanged;
    },
    _setupOfferDropdown: function _setupOfferDropdown() {
      var _this2 = this;

      Ember.run.next(this, function () {
        var offers = _this2.controller.get('activity.offers').filterBy('status', 'active');

        _this2.controller.set('offerDropdownOptions', offers.map(function (offer) {
          return { display: offer.get('internal_title') + ' (' + offer.get('offer_id') + ')', value: offer.get('offer_id') };
        }));
      });
    },
    _validateActivityFields: function _validateActivityFields() {
      var activity = this.controller.get('activity');
      var attributes = activity.get('attributes');
      return this.validate({ model: attributes, attributes: ['vehicle_type', 'baggage_capacity', 'passenger_capacity'] });
    },


    actions: {
      editProduct: function editProduct() {
        if (this.controller.get('isAnySectionEditing')) {
          this.controller.set('sectionToBeEdited', 'product');
        } else {
          var activity = this.controller.get('activity');
          var attributes = activity.get('attributes');
          var things = activity.get('things');
          var activityCopy = {
            baggage_capacity: attributes.get('baggage_capacity'),
            passenger_capacity: attributes.get('passenger_capacity'),
            vehicle_type: attributes.get('vehicle_type'),
            gt_instructions: things.get('gt_instructions')
          };

          this.controller.set('activityCopy', activityCopy);
          this.controller.set('editingProduct', true);
        }
      },
      cancelProduct: function cancelProduct() {
        var activityCopy = this.controller.get('activityCopy');
        var activity = this.controller.get('activity');
        var attributes = activity.get('attributes');
        var things = activity.get('things');

        attributes.set('baggage_capacity', activityCopy.baggage_capacity);
        attributes.set('passenger_capacity', activityCopy.passenger_capacity);
        attributes.set('vehicle_type', activityCopy.vehicle_type);
        things.set('gt_instructions', activityCopy.gt_instructions);
        this.controller.set('activityDirty', false);
        this.controller.set('editingProduct', false);
        this.controller.set('errors', '');
      },
      setValidity: function setValidity() {},
      validateActivityIfInvalid: function validateActivityIfInvalid() {
        var _this3 = this;

        if (!Ember.isEmpty(this.controller.get('errors'))) {
          this.controller.set('errors', undefined);
          this._validateActivityFields().catch(function (error) {
            _this3.controller.set('errors', error);
          });
        }
      },
      validateOfferIfInvalid: function validateOfferIfInvalid() {},
      offerChange: function offerChange(offerId) {
        this._offerChange(offerId);
      },
      airportSelected: function airportSelected(airport) {
        this.controller.set('airportSelectedFromTypeAhead', true);
        this.controller.set('offerAttributes.airport', airport.value);
        this.controller.set('airportLabel', airport.display);
      },
      airportInputKeyUP: function airportInputKeyUP() {
        this.controller.set('offerDirty', true);
        if (this.controller.get('airportSelectedFromTypeAhead')) {
          this.controller.set('airportSelectedFromTypeAhead', false);
        }
      },
      airportInputChanged: function airportInputChanged(airport) {
        if (!this.controller.get('airportSelectedFromTypeAhead')) {
          this.controller.set('offerAttributes.airport', '');
          this.controller.set('airportLabel', '');
        }
        this.controller.set('airportSelectedFromTypeAhead', false);
      },
      zoneSelected: function zoneSelected(zone) {
        this.controller.set('zoneSelectedFromTypeAhead', true);
        if (zone && zone.id) {
          this.controller.set('offerAttributes.zones', [zone.id]);
        } else {
          this.controller.set('offerAttributes.zones', []);
        }
        this.controller.get('offerAttributes.zones').arrayContentDidChange();
      },
      zoneInputKeyUP: function zoneInputKeyUP() {
        if (this.controller.get('zoneSelectedFromTypeAhead')) {
          this.controller.set('zoneSelectedFromTypeAhead', false);
        }
        this.controller.set('offerDirty', true);
      },
      zoneInputChanged: function zoneInputChanged() {
        if (!this.controller.get('zoneSelectedFromTypeAhead')) {
          this.controller.set('zoneName', null);
          this.controller.set('offerAttributes.zones', []);
          this.controller.get('offerAttributes.zones').arrayContentDidChange();
        }
        this.controller.set('zoneSelectedFromTypeAhead', false);
      },
      directionChange: function directionChange(value) {
        this.controller.set('offerDirty', true);
        this.controller.set('offerAttributes.direction', value);
      },
      durationTypeChange: function durationTypeChange(value) {
        this.controller.set('offerDirty', true);
        this.controller.set('offerAttributes.duration_type', value);
      },
      vehicleChange: function vehicleChange(value) {
        this.controller.set('activityDirty', true);
        this.controller.set('activity.attributes.vehicle_type', value);
      },
      paxChange: function paxChange(value) {
        this.controller.set('activityDirty', true);
        this.controller.set('activity.attributes.passenger_capacity', parseInt(value));
      },
      baxChange: function baxChange(value) {
        this.controller.set('activityDirty', true);
        this.controller.set('activity.attributes.baggage_capacity', parseInt(value));
      },
      gtInstructionsChange: function gtInstructionsChange() {
        this.controller.set('activityDirty', true);
      },
      saveActivity: function saveActivity() {
        var _this4 = this;

        this.controller.set('savingActivity', true);
        this.controller.set('lastSaved', 'activity');
        this.controller.set('editingProduct', false);
        var activity = this.controller.get('activity');
        var attributes = activity.get('attributes');
        var isPublishNeeded = this.isPublishNeeded();
        this._validateActivityFields().then(function () {
          return activity.updateAttributes(activity.get('attributes'));
        }).then(function () {
          if (activity.get('isContentAndLogisticsMigratedToV4')) {
            return _this4.get('v4ContentService').updateThings(activity.get('id'), activity.get('things'));
          } else {
            return activity.updateThings(activity.get('things'));
          }
        }).then(function () {
          _this4.set('errors', '');
          if (isPublishNeeded) {
            return _this4.publishChanges(activity);
          } else {
            return _this4.get('notifications').success(_this4.get('i18n').t('components.alert.saveSuccess'));
          }
        }).then(function () {
          _this4.setUpPublishFieldsCopy();
          _this4.controller.set('activityDirty', false);
        }).catch(function (errors) {
          _this4.controller.set('editingProduct', true);
          _this4.controller.set('errors', errors);
          Ember.Logger.error('error saving activity', errors);
        }).finally(function () {
          _this4.controller.set('savingActivity', false);
        });
      },
      setAddressDirty: function setAddressDirty() {
        this.controller.set('locationDirty', true);
      },
      saveOffer: function saveOffer() {
        var _this5 = this;

        this.controller.set('savingOffer', true);
        this.controller.set('lastSaved', 'offer');
        var offerAttributes = this.controller.get('offerAttributes');
        var offer = this.controller.get('offer');
        var route = this;
        route.controller.set('editingOffer', false);
        offer.updateAttributes(offerAttributes).then(function () {
          return _this5.reloadActivity();
        }).then(function () {
          _this5.controller.set('offerDirty', false);
          _this5.get('notifications').success(_this5.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (errors) {
          route.controller.set('editingOffer', true);
          _this5.controller.set('errors', errors);
          Ember.Logger.error('error saving offer', errors);
        }).finally(function () {
          _this5.controller.set('savingOffer', false);
        });
      },
      editOffer: function editOffer() {
        if (this.controller.get('isAnySectionEditing')) {
          this.controller.set('sectionToBeEdited', 'offer');
        } else {
          this.controller.set('editingOffer', true);
        }
      },
      cancelOffer: function cancelOffer() {
        if (this.controller.get('offerDirty')) {
          var offer = this.controller.get('offer');
          this._offerChange(offer.id);
        }
        this.controller.set('editingOffer', false);
        this.controller.set('errors', '');
      },
      resetCurrentSection: function resetCurrentSection() {
        var sectionToBeEdited = this.controller.get('sectionToBeEdited');
        if (this.controller.get('editingProduct')) {
          this.send('cancelProduct');
        } else if (this.controller.get('editingOffer')) {
          this.send('cancelOffer');
        }
        switch (sectionToBeEdited) {
          case 'product':
            this.send('editProduct');
            break;
          case 'offer':
            this.send('editOffer');
            break;
        }
        this.controller.set('sectionToBeEdited', null);
      },
      cancelSectionEdit: function cancelSectionEdit() {
        this.controller.set('sectionToBeEdited', null);
      },
      willTransition: function willTransition() {
        //todo
        if (this.controller.get('activityDirty')) {
          this.rollbackActivity();
        }
        this.rollbackOffer();
        this.controller.set('selectedOffer', '');
        //for tests
        window.pageName = undefined;
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'gt-settings';

        this.controller.set('errors', '');
        this.controller.set('savingActivity', false);
        this.controller.set('savingOffer', false);
      }
    },

    // These functions exist here to allow dependency injection.
    validate: function validate() {
      return _formValidator.validate.apply(undefined, arguments);
    },
    scrollToFirstError: function scrollToFirstError() {
      return (0, _formValidator.scrollToFirstError)();
    },
    //instance ops are horrible to mock
    getFilteredAirports: function getFilteredAirports(airportCode) {
      return this.controller.get('airportSource')(airportCode);
    },
    _offerChange: function _offerChange(offerId) {
      var _this6 = this;

      this.controller.set('offerDirty', false);
      var offer = this.controller.get('activity.offers').findBy('offer_id', parseInt(offerId));
      offer.getGTAttributes().then(function (attributes) {
        _this6.controller.set('offer', offer);
        _this6.controller.set('offerAttributes', attributes);
        var zone = attributes.zones.length > 0 ? attributes.zones[0] : null;
        if (zone && zone.zoneId) {
          _this6.controller.set('zoneName', zone.zoneName);
          _this6.controller.set('offerAttributes.zones', [zone.zoneId]);
        } else {
          _this6.controller.set('zoneName', '');
          _this6.controller.set('offerAttributes.zones', []);
        }
      }).then(function () {
        var airportCode = _this6.controller.get('offerAttributes.airport');
        if (airportCode) {
          return _this6.getFilteredAirports(airportCode).then(function (airports) {
            _this6.controller.set('airportLabel', airports.data.data.findBy('value', airportCode).display);
          });
        } else {
          _this6.controller.set('airportLabel', '');
        }
      }).then(function () {
        _this6.controller.set('selectedOffer', offerId);
      }).catch(function (errors) {
        Ember.Logger.error('error changing offer:', errors);
        _this6.controller.set('errors', errors);
      });
    }
  });
});