define('lxso/routes/tagging-upload', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lxso/config/environment'], function (exports, _authenticatedRouteMixin, _featureFlag, _requirePermission, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, {
    requiredPermission: 'TagsUpload',
    i18n: Ember.inject.service(),

    setupController: function setupController() {
      this.controller.setProperties({
        'errors': [],
        'fileSelectionErrors': [],
        'taggingUploadDisabled': false
      });
    },


    actions: {
      didTransition: function didTransition() {
        window.pageName = 'tags';
      },
      selectTaggingCsv: function selectTaggingCsv() {
        this.controller.set('fileSelectionErrors', []);
        this.controller.set('errors', []);
        var fileSize = $('#taggingFile').get(0).files[0].size;
        if (fileSize > 5 * 1024 * 1024) {
          var errorString = this.get('i18n').t('errors.taggingUploadSize');
          this.controller.set('fileSelectionErrors', [errorString]);
          this.controller.set('taggingUploadDisabled', true);
        } else {
          this.controller.set('taggingUploadDisabled', false);
        }
      },
      uploadTaggingCsv: function uploadTaggingCsv() {
        var _this = this;

        var controller = this.controller;
        this.controller.set('fileSelectionErrors', []);
        this.controller.set('errors', []);
        var formData = new FormData();
        var selectedFile = $('#taggingFile')[0];
        if (selectedFile && selectedFile.files[0]) {
          formData.append('file', selectedFile.files[0], 'taggingfile');
          var self = this;

          this.controller.set('taggingUploadDisabled', true);
          this.controller.set('fileSelectionErrors', [this.get('i18n').t('pages.uploadTagging.fileUploading')]);

          $.ajax({
            url: _environment.default.api.host + '/' + _environment.default.api.namespace + '/activityTags/upload',
            type: 'POST',
            data: formData,
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            },
            dataType: 'json',
            processData: false,
            contentType: false,
            headers: {
              Accept: 'application/json'
            },
            success: function success(data) {
              _this.controller.set('taggingUploadDisabled', false);
              _this.controller.set('fileSelectionErrors', []);
              selectedFile.value = '';
              if (Array.isArray(data)) {
                if (data.length > 0) {
                  controller.set('errors', data);
                } else {
                  self.get('notifications').success(self.get('i18n').t('pages.uploadTagging.fileUploadSuccessful'));
                }
              }
            },
            error: function error(data) {
              _this.controller.set('fileSelectionErrors', []);
              _this.controller.set('taggingUploadDisabled', false);
              selectedFile.value = '';
              if (data.responseJSON && data.responseJSON.details && data.responseJSON.details.length) {
                controller.set('fileSelectionErrors', [data.responseJSON.details[0].message]);
              } else if (data.responseJSON && data.responseJSON.errorMessage) {
                controller.set('fileSelectionErrors', [data.responseJSON.errorMessage]);
              } else {
                controller.set('fileSelectionErrors', [data.responseText]);
              }
            }
          });
        } else {
          controller.set('fileSelectionErrors', [this.get('i18n').t('errors.noFileSelectError')]);
        }
      }
    }
  });
});