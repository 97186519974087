define('lxso/routes/hotel-zone', ['exports', 'uitk', 'lxso/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lodash/string', 'lxso/utils/form-validator', 'lxso/utils/omniture'], function (exports, _uitk, _environment, _authenticatedRouteMixin, _featureFlag, _requirePermission, _string2, _formValidator, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, {
    requiredPermission: 'V3InternalSettings',
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    session: Ember.inject.service(),
    i18n: Ember.inject.service(),
    supplierBranch: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    jsXlsx: Ember.inject.service(),
    fileSaver: Ember.inject.service(),

    model: function model(param, transition) {
      var zoneID = param.zoneId;
      var zone = void 0;

      if (zoneID === 'new') {
        zone = this.store.createRecord('hotel-zone');
        return Ember.RSVP.hash({ zone: zone });
      } else {
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/location_lists/lastHotelsSyncDate';
        zone = this.store.findRecord('hotel-zone', zoneID, { reload: true });
        var zoneAssociations = this.store.query('zone-association', { zoneID: zoneID });
        var hotelMasterListUpdate = this.get('ajaxWrapper').ajaxGet(url);
        return Ember.RSVP.hash({
          zone: zone,
          zoneAssociations: zoneAssociations,
          hotelMasterListUpdate: hotelMasterListUpdate
        });
      }
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var supplierBranchId = model.zone.get('supplierBranchId');
      var supplierBranchIdInSession = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
      if (supplierBranchId && supplierBranchIdInSession !== supplierBranchId) {
        return this.get('supplierBranch').loadActiveSupplierBranches([supplierBranchId]).then(function (activeSupplierBranches) {
          return _this.get('supplierBranch').updateSelectedBranch(activeSupplierBranches[0]);
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      this.controller.setProperties({
        zone: model.zone,
        zoneAssociations: model.zoneAssociations,
        errors: '',
        sectionToBeEdited: '',
        editingBasic: false,
        editingInternal: false,
        editingDetails: false,
        isAnySectionEditing: Ember.computed.or('editingBasic', 'editingInternal', 'editingDetails')
      });
      if (model.hotelMasterListUpdate) {
        var masterUpdateDate = model.hotelMasterListUpdate.lastSync;
        this.controller.set('hotelMasterListUpdateDate', masterUpdateDate);
      }

      this.setupBasicAndInternal();
      this.setupZoneType();
      this.initializeMap();
      Ember.run.next(function () {
        _this2.setupOpenSections();
      });
      Ember.run.schedule('afterRender', function () {
        _uitk.default.map.loadApi();
        _this2.setMapMarkers();
      });
    },
    resetController: function resetController() {
      var zone = this.controller.get('zone');
      if (zone && zone.get('hasDirtyAttributes')) {
        zone.rollbackAttributes();
      }
    },
    setupBasicAndInternal: function setupBasicAndInternal() {
      this.controller.set('zoneStatusOptions', ['Active', 'Inactive']);
      this.controller.set('zoneStatusValues', ['ACTIVE', 'INACTIVE']);
      this.controller.set('initialStatus', this.controller.get('zone.status'));

      var supplierBranchId = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
      var supplierBranchName = this.get('session.data.authenticated.selectedBranch.supplier_branch_name');
      this.controller.set('zone.supplierBranchId', supplierBranchId);
      this.controller.set('supplierBranchName', supplierBranchName);
      if (this.controller.get('zone.status')) {
        this.controller.set('noZoneStatus', false);
      } else {
        this.controller.set('noZoneStatus', true);
      }
    },
    setupZoneType: function setupZoneType() {
      var _this3 = this;

      var zone = this.controller.get('zone');
      var isNew = this.controller.get('zone.isNew');
      var options = ['Hotel', 'Postal Code'];
      var values = ['hotel', 'postal'];
      var hotelTableHeaders = ['Hotel Name', 'Hotel ID', 'Address', 'Address', 'City', 'State/Province', 'Country', 'Postal Code'];
      var hotelTableKeys = ['name', 'hotelId', 'address.street', 'address.streetSecondLine', 'address.city', 'address.state', 'address.country', 'address.postalCode'];

      if (zone.get('hotels.length') > 0) {
        var hotelIds = [];

        var sorted = zone.get('hotels').sortBy('name');
        sorted.forEach(function (hotel, index) {
          if (index === 0) {
            var latLong = hotel.get('latitude') + ',' + hotel.get('longitude');
            _this3.controller.set('firstHotelLatLong', latLong);
          }
          hotel.set('selected', true);
          hotelIds.push(hotel.get('hotelId'));
        });
        zone.set('hotels', sorted);
        this.controller.set('existingHotelIdsForSave', hotelIds);
        this.controller.set('searchResultsForSave', []);
        this.controller.set('hotelSearchResults', []);
        this.controller.set('zoneType', 'hotel');
      } else if (zone.get('postalCodes.length') > 0) {
        var currentPostalCodes = zone.get('postalCodes');
        this.controller.set('zoneType', 'postal');
        this.controller.set('postalCodesCopy', currentPostalCodes);
        this.controller.set('postalCodesForSave', currentPostalCodes);
        var postalCodesForDisplay = [];

        currentPostalCodes.forEach(function (code) {
          var codeObject = Ember.Object.create({
            code: code,
            selected: true
          });
          postalCodesForDisplay.push(codeObject);
        });
        this.controller.set('postalCodesForDisplay', postalCodesForDisplay);

        var newCodeObject = Ember.Object.create({
          code: null,
          selected: true
        });
        this.controller.set('newCodesToAdd', [newCodeObject]);
      } else if (zone.get('postalCodes.length') === 0 && zone.get('hotels.length') === 0) {
        options.unshift('Select');
        values.unshift('select');
        this.controller.set('noZoneType', true);
        this.controller.set('zoneType', 'select');
        this.controller.set('existingHotelIdsForSave', []);
        this.controller.set('searchResultsForSave', []);
        this.controller.set('hotelSearchResults', []);
        var _newCodeObject = Ember.Object.create({
          code: null,
          selected: true
        });
        this.controller.set('newCodesToAdd', [_newCodeObject]);
        this.controller.set('postalCodesForDisplay', []);
      }

      this.controller.set('hotelTableHeaders', hotelTableHeaders);
      this.controller.set('hotelTableKeys', hotelTableKeys);
      this.controller.set('zoneTypeOptions', options);
      this.controller.set('zoneTypeValues', values);
    },
    initializeMap: function initializeMap() {
      _uitk.default.map.config = {
        language: 'en'
      };
      _uitk.default.map.data = {
        'hotel-zone-map': {
          'markerData': {
            'markers': []
          }
        }
      };
    },
    setupOpenSections: function setupOpenSections() {
      var isNew = this.controller.get('zone.isNew');
      this.send('openStep', 'basic-information', true);
      this.send('openStep', 'internal-settings', !isNew);
      this.send('openStep', 'zone-details', !isNew);
      this.send('openStep', 'zone-association', !isNew);
    },
    setMapMarkers: function setMapMarkers() {
      var existingHotels = this.controller.get('zone.hotels');
      var hotelSearchResults = this.controller.get('hotelSearchResults');
      var markers = [];

      existingHotels.forEach(function (hotel) {
        if (hotel.get('selected')) {
          var marker = {
            'name': hotel.get('name') + ' (' + hotel.get('hotelId') + ')',
            'latlong': hotel.get('latitude') + ',' + hotel.get('longitude'),
            'content': '',
            'type': 'blue-pin'
          };
          markers.push(marker);
        }
      });

      if (hotelSearchResults) {
        hotelSearchResults.forEach(function (hotel) {
          if (hotel.get('selected')) {
            var marker = {
              'name': hotel.get('name') + ' (' + hotel.get('hotelId') + ')',
              'latlong': hotel.get('latitude') + ',' + hotel.get('longitude'),
              'content': '',
              'type': 'red-pin'
            };
            markers.push(marker);
          }
        });
      }

      _uitk.default.map.data['hotel-zone-map'].markerData.markers = markers;
      this.reloadMap();
    },
    reloadMap: function reloadMap() {
      if (_uitk.default.map.data['hotel-zone-map'].module) {
        _uitk.default.map.data['hotel-zone-map'].module.loadMarkers();
        _uitk.default.map.data['hotel-zone-map'].module.open();
      }
    },
    validateBasicInfo: function validateBasicInfo() {
      return (0, _formValidator.validate)({
        model: this.controller.get('zone'),
        attributes: ['zoneName', 'zoneDesc']
      });
    },
    validatePostalCodes: function validatePostalCodes() {
      return (0, _formValidator.validate)({
        model: this.controller.get('zone'),
        attributes: ['postalCodes']
      });
    },
    hotelSearchByIds: function hotelSearchByIds(paramsToSearchBy) {
      var _this4 = this;

      if (paramsToSearchBy.length > 900) {
        this.controller.set('searchTooLarge', true);
      } else {
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/location_lists/search/hotel_ids';
        this.get('ajaxWrapper').ajaxPost(url, { hotelIds: paramsToSearchBy }).then(function (results) {
          var resultsIds = [];
          results.forEach(function (result) {
            resultsIds.push(result.id);
          });
          var invalidIds = [];
          paramsToSearchBy.forEach(function (item) {
            if (!resultsIds.includes(item)) {
              invalidIds.push(item);
            }
          });
          _this4.parseAndFilter(results, 'id', invalidIds);
        }).then(function () {
          _this4.reloadMap();
        }).catch(function (error) {
          _this4.controller.set('errors', error);
          Ember.Logger.error('getting hotel search results', error);
        });
      }
    },
    hotelSearchByParams: function hotelSearchByParams() {
      var _this5 = this;

      var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/location_lists/search/hotels?page=0&pagination=false';
      var hotelSearch = this.controller.get('searchName') ? '&name=' + this.controller.get('searchName') : undefined;
      var citySearch = this.controller.get('searchCity') ? '&city=' + this.controller.get('searchCity') : undefined;
      var zipSearch = this.controller.get('searchZip') ? '&postalCode=' + this.controller.get('searchZip') : undefined;
      var paramsToAdd = [hotelSearch, citySearch, zipSearch];

      for (var i = 0; i < paramsToAdd.length; i++) {
        if (paramsToAdd[i]) {
          url = url.concat(paramsToAdd[i]);
        }
      }

      this.get('ajaxWrapper').ajaxGet(url).then(function (results) {
        if (results) {
          return _this5.parseAndFilter(results.hotels, 'params');
        } else {
          return _this5.controller.set('noResults', true);
        }
      }).then(function () {
        _this5.reloadMap();
      }).catch(function (error) {
        _this5.controller.set('errors', error);
        Ember.Logger.error('getting hotel search results', error);
      });
    },
    parseAndFilter: function parseAndFilter(results, searchType, invalidIds) {
      var parsed = new Ember.A([]);
      for (var i = 0; i < results.length; i++) {
        if (!this.controller.get('firstHotelLatLong') && i === 0) {
          var latLong = results[i].latitude + ',' + results[i].longitude;
          this.controller.set('firstHotelLatLong', latLong);
        }
        var resultHotel = Ember.Object.create({
          id: results[i].id,
          hotelId: results[i].id,
          address: results[i].address,
          selected: false,
          latitude: results[i].latitude,
          longitude: results[i].longitude,
          name: results[i].name
        });
        parsed.pushObject(resultHotel);
      }

      this.controller.set('searchResultsForSave', []);

      var existingHotels = this.controller.get('zone.hotels');
      var resultsToRemove = [];

      parsed.forEach(function (result) {
        existingHotels.forEach(function (hotel) {
          if (result.id === hotel.get('hotelId')) {
            resultsToRemove.push(result);
          }
        });
      });

      var removedHotels = [];
      resultsToRemove.forEach(function (resultToRemove) {
        parsed.removeObject(resultToRemove);
        removedHotels.push(resultToRemove.name + ' (' + resultToRemove.hotelId + ')');
      });

      this.controller.set('hotelSearchResults', parsed);
      if (searchType === 'id' && removedHotels.length > 0) {
        this.controller.set('removedHotels', removedHotels);
      }

      if (parsed.length === 0 && resultsToRemove.length === 0) {
        this.controller.set('noResults', true);
      }

      if (searchType === 'id' && invalidIds.length > 0) {
        this.controller.set('invalidIds', invalidIds);
      }
    },
    clearInputAndErrorsBulkUpload: function clearInputAndErrorsBulkUpload() {
      this.controller.set('searchName', '');
      this.controller.set('searchCity', '');
      this.controller.set('searchZip', '');
      this.controller.set('hotelSearchResults', []);
      $('#hotel-zone-bulk-upload').val('');
      this.controller.set('hotelZoneFileName', undefined);
      this.controller.set('hotelZoneFile', undefined);
      this.controller.set('bulkUploadErrors', []);
      this.controller.set('hotelZoneMappingBy', 'findHotels');
      this.controller.set('isHotelZoneBulkUpload', false);
      this.controller.set('noResults', false);
      this.controller.set('searchTooLarge', false);
      this.controller.set('invalidIds', []);
      this.controller.set('removedHotels', []);
    },

    showFileUploadButton: Ember.computed('hotelZoneFileName', function () {
      var selectedFileName = this.get('hotelZoneFileName');
      return selectedFileName === undefined;
    }),
    createCsvAndDownload: function createCsvAndDownload(data, fileName) {
      this.get('jsXlsx').createXlsxFile(data, 'hotelZoneMapping', fileName);
    },
    generateReportsBulkUpload: function generateReportsBulkUpload(failedRecords) {
      var fileName = 'failed-bulk-upload-reports.xlsx';
      this.createCsvAndDownload(failedRecords, fileName);
    },


    actions: {
      openStep: function openStep(headerClass, toggleValue) {
        var hasToggleValue = toggleValue === true || toggleValue === false;
        var link = document.getElementsByClassName('title-main toggle-trigger ' + headerClass);

        if (headerClass === 'basic-information') {
          if (hasToggleValue) {
            this.controller.set('basicOpen', toggleValue);
          } else {
            this.controller.toggleProperty('basicOpen');
          }
        } else if (headerClass === 'internal-settings') {
          if (hasToggleValue) {
            this.controller.set('internalOpen', toggleValue);
          } else {
            this.controller.toggleProperty('internalOpen');
          }
        } else if (headerClass === 'zone-details') {
          if (hasToggleValue) {
            this.controller.set('zoneDetailsOpen', toggleValue);
          } else {
            this.controller.toggleProperty('zoneDetailsOpen');
          }
        } else if (headerClass === 'zone-association') {
          if (hasToggleValue) {
            this.controller.set('zoneAssociationOpen', toggleValue);
          } else {
            this.controller.toggleProperty('zoneAssociationOpen');
          }
        }
        if (toggleValue === true) {
          $(link).addClass('open');
        } else if (toggleValue === false) {
          $(link).removeClass('open');
        } else {
          $(link).toggleClass('open');
        }
      },
      validateIfInvalid: function validateIfInvalid() {
        var _this6 = this;

        if (this.controller.get('errors')) {
          this.validateBasicInfo().then(function () {
            _this6.controller.set('errors', []);
          }).catch(function (errors) {
            _this6.controller.set('errors', errors);
          });
        }
      },
      validateDetailsIfInvalid: function validateDetailsIfInvalid() {
        var _this7 = this;

        if (this.controller.get('errors')) {
          this.validatePostalCodes().then(function () {
            _this7.controller.set('errors', []);
          }).catch(function (errors) {
            _this7.controller.set('errors', errors);
          });
        }
      },
      setBasicInfoDirty: function setBasicInfoDirty() {
        this.controller.set('isBasicInfoDirty', true);
      },
      resetCurrentSection: function resetCurrentSection() {
        var sectionToBeEdited = this.controller.get('sectionToBeEdited');
        if (this.controller.get('editingBasic')) {
          this.send('cancelEditingBasic');
        } else if (this.controller.get('editingInternal')) {
          this.send('cancelEditingInternal');
        } else if (this.controller.get('editingDetails')) {
          this.send('cancelEditZoneDetails');
        }

        switch (sectionToBeEdited) {
          case 'basicInfo':
            this.send('editBasicInfo');
            break;
          case 'internalSettings':
            this.send('editInternalSettings');
            break;
          case 'zoneDetails':
            this.send('editZoneDetails');
            break;
        }

        this.controller.set('sectionToBeEdited', null);
      },
      cancelSectionEdit: function cancelSectionEdit() {
        this.controller.set('sectionToBeEdited', null);
      },
      editBasicInfo: function editBasicInfo() {
        if (this.controller.get('isAnySectionEditing')) {
          this.controller.set('sectionToBeEdited', 'basicInfo');
        } else {
          this.controller.set('editingBasic', true);
        }
      },
      cancelEditingBasic: function cancelEditingBasic() {
        var supplierBranchIdInSession = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
        var isNew = this.controller.get('zone.isNew');
        if (isNew) {
          this.transitionTo('hotel-zone-list', supplierBranchIdInSession);
        } else {
          this.controller.set('editingBasic', false);
          this.controller.get('zone').rollbackAttributes();
          this.controller.set('errors', []);
          this.controller.set('isBasicInfoDirty', false);
        }
      },
      editInternalSettings: function editInternalSettings() {
        if (this.controller.get('isAnySectionEditing')) {
          this.controller.set('sectionToBeEdited', 'internalSettings');
        } else {
          this.controller.set('editingInternal', true);
        }
      },
      cancelEditingInternal: function cancelEditingInternal() {
        this.controller.set('editingInternal', false);
        this.controller.get('zone').rollbackAttributes();
      },
      editZoneDetails: function editZoneDetails() {
        if (this.controller.get('isAnySectionEditing')) {
          this.controller.set('sectionToBeEdited', 'zoneDetails');
        } else {
          this.controller.set('editingDetails', true);
          this.controller.set('selectAllLabel', 'deselectAll');
          this.controller.set('isSelectAll', false);
          this.controller.set('searchSelectAllLabel', 'selectAll');
          this.controller.set('isSearchSelectAll', true);
          this.controller.set('removedHotels', null);
          this.controller.set('invalidIds', null);
          if (this.get('featureFlags').checkEnabled('ZoneIdHotelListBulkUpload')) {
            this.clearInputAndErrorsBulkUpload();
          }
        }
      },
      cancelEditZoneDetails: function cancelEditZoneDetails() {
        this.controller.set('editingDetails', false);
        if (this.controller.get('zoneType') === 'hotel') {
          this.controller.set('noResults', false);
          this.controller.set('removedHotels', null);
          this.controller.set('searchName', null);
          this.controller.set('searchCity', null);
          this.controller.set('searchZip', null);
          this.controller.set('existingHotelIdsForSave', []);
          this.controller.set('searchResultsForSave', []);
          this.controller.set('hotelSearchResults', []);

          var allExistingHotels = this.controller.get('zone.hotels');

          allExistingHotels.forEach(function (hotel) {
            hotel.set('selected', true);
          });

          _uitk.default.map.data['hotel-zone-map'].markerData.markers = [];
          this.setMapMarkers();
        } else {
          var allPostalCodes = this.controller.get('postalCodesForDisplay');
          allPostalCodes.forEach(function (code) {
            code.set('selected', true);
          });
          var newCodeObject = Ember.Object.create({
            code: null,
            selected: true
          });
          this.controller.set('newCodesToAdd', [newCodeObject]);
        }
      },
      selectZoneStatus: function selectZoneStatus(value) {
        if (value === 'ACTIVE' || value === 'INACTIVE') {
          this.controller.set('zone.status', value);
          this.controller.set('noZoneStatus', false);
        } else {
          this.controller.set('noZoneStatus', true);
        }
      },
      selectZoneType: function selectZoneType(value) {
        if (value !== 'select') {
          this.controller.set('zoneType', value);
          this.controller.set('noZoneType', false);
          this.controller.set('editingDetails', true);
        } else {
          this.controller.set('noZoneType', true);
        }
      },
      saveHotelZone: function saveHotelZone() {
        var _this8 = this;

        var isNew = this.controller.get('zone.isNew');
        this.controller.set('errors', null);
        this.controller.set('saving', true);
        var supplierBranchId = this.controller.get('zone.supplierBranchId');
        this.validateBasicInfo().then(function () {
          var hotelZone = _this8.controller.get('zone');
          var status = _this8.controller.get('zone.status');
          var initialStatus = _this8.controller.get('initialStatus');

          if (status !== initialStatus) {
            return hotelZone.save({ adapterOptions: { supplierBranchId: supplierBranchId, dataChange: 'status' } });
          } else {
            return hotelZone.save({ adapterOptions: { supplierBranchId: supplierBranchId } });
          }
        }).then(function (response) {
          _this8.store.findRecord('supplierBranch', supplierBranchId).then(function (supplierBranch) {
            supplierBranch.get('hotelZones').pushObject(response);
          });
          _this8.controller.set('lastSavedSection', 'hotelZone');
          _this8.get('notifications').success(_this8.get('i18n').t('components.alert.saveSuccess'));
          _this8.controller.set('saving', false);
          _this8.controller.set('editingBasic', false);
          _this8.controller.set('editingInternal', false);
          _this8.controller.set('isBasicInfoDirty', false);
          if (isNew) {
            var zoneId = response.get('id');
            _this8.transitionTo('hotel-zone', zoneId);
          } else {
            _this8.refresh();
            _this8.reloadMap();
          }
        }).catch(function (error) {
          _this8.controller.set('errors', error);
          _this8.controller.set('saving', false);
          Ember.Logger.error('error saving hotel zone', error);
          if (error.errors) {
            // to show error message for backend errors only and not validation errors
            _this8.controller.set('lastSaved', 'basicInfo');
            _this8.get('notifications').error(_this8.get('i18n').t('components.alert.saveFailure'));
          }
        });
      },
      addPostalCode: function addPostalCode() {
        var newCodesToAdd = this.controller.get('newCodesToAdd');
        var newCode = Ember.Object.create({
          code: null,
          selected: true
        });

        newCodesToAdd.pushObject(newCode);
      },
      selectDeselectAllPostalCodes: function selectDeselectAllPostalCodes() {
        var selectAllLabel = this.controller.get('selectAllLabel');
        var postalCodesForDisplay = this.controller.get('postalCodesForDisplay');
        var selectedOrNot = void 0;

        if (selectAllLabel === 'selectAll') {
          selectedOrNot = true;
          this.controller.set('selectAllLabel', 'deselectAll');
          this.controller.set('isSelectAll', !selectedOrNot);
        } else {
          selectedOrNot = false;
          this.controller.set('selectAllLabel', 'selectAll');
          this.controller.set('isSelectAll', !selectedOrNot);
        }

        postalCodesForDisplay.forEach(function (codeObject) {
          codeObject.set('selected', selectedOrNot);
        });
      },
      selectDeselectSinglePostalCode: function selectDeselectSinglePostalCode(postalCodeObject) {
        if (postalCodeObject.get('selected')) {
          postalCodeObject.set('selected', false);
        } else {
          postalCodeObject.set('selected', true);
        }
      },
      savePostalCodes: function savePostalCodes() {
        var _this9 = this;

        this.controller.set('errors', null);
        var zoneId = this.controller.get('zone.id');
        var currentCodesUpdated = this.controller.get('postalCodesForDisplay');
        var newCodesToAdd = this.controller.get('newCodesToAdd');
        var postalCodesForSave = new Ember.A([]);

        currentCodesUpdated.forEach(function (currentCode) {
          var trimmedCode = _string2.default.trim(currentCode.code);
          if (currentCode.code !== null && currentCode.selected) {
            postalCodesForSave.push(trimmedCode);
          }
        });

        newCodesToAdd.forEach(function (newCode) {
          var trimmedCode = _string2.default.trim(newCode.code);
          if (newCode.code !== null) {
            postalCodesForSave.push(trimmedCode);
          }
        });

        this.controller.set('zone.postalCodes', postalCodesForSave);
        this.validatePostalCodes().then(function () {
          return _this9.controller.get('zone').save({ adapterOptions: { zoneId: zoneId, endPoint: 'postal_codes', postalCodes: postalCodesForSave } });
        }).then(function () {
          return _this9.controller.get('zone').reload();
        }).then(function (hotelZone) {
          _this9.controller.set('lastSavedSection', 'hotelZone');
          _this9.get('notifications').success(_this9.get('i18n').t('components.alert.saveSuccess'));
          _this9.controller.set('editingDetails', false);
          _this9.refresh();
        }).catch(function (error) {
          _this9.controller.set('errors', error);
          _this9.controller.set('zone.postalCodes', _this9.controller.get('postalCodesForDisplay'));
          Ember.Logger.error('error saving postal code', error);
        });
      },
      selectDeselectAllExistingHotels: function selectDeselectAllExistingHotels() {
        var selectAllLabel = this.controller.get('selectAllLabel');
        var existingHotelIdsForSave = this.controller.get('existingHotelIdsForSave');
        var allExistingHotels = this.controller.get('zone.hotels');
        var hotelIdsForSaveUpdated = [];
        var selectedOrNot = void 0;

        if (selectAllLabel === 'selectAll') {
          selectedOrNot = true;
          this.controller.set('selectAllLabel', 'deselectAll');
          this.controller.set('isSelectAll', !selectedOrNot);
        } else {
          selectedOrNot = false;
          this.controller.set('selectAllLabel', 'selectAll');
          this.controller.set('isSelectAll', !selectedOrNot);
        }

        allExistingHotels.forEach(function (hotel) {
          hotel.set('selected', selectedOrNot);
          if (selectedOrNot) {
            hotelIdsForSaveUpdated.push(hotel.get('hotelId'));
          }
        });
        this.controller.set('existingHotelIdsForSave', hotelIdsForSaveUpdated);

        this.setMapMarkers();
      },
      selectDeselectSingleExistingHotel: function selectDeselectSingleExistingHotel(index) {
        var existingHotelIdsForSave = this.controller.get('existingHotelIdsForSave');
        var hotelIdsForSaveUpdated = void 0;

        var itemToUpdate = this.controller.get('zone.hotels').objectAt(index);
        if (itemToUpdate.get('selected')) {
          itemToUpdate.set('selected', false);
          hotelIdsForSaveUpdated = existingHotelIdsForSave.filter(function (id) {
            return id !== itemToUpdate.get('hotelId');
          });
          this.controller.set('existingHotelIdsForSave', hotelIdsForSaveUpdated);
        } else {
          itemToUpdate.set('selected', true);
          existingHotelIdsForSave.push(itemToUpdate.get('hotelId'));
          this.controller.set('existingHotelIdsForSave', existingHotelIdsForSave);
        }
        this.setMapMarkers();
      },
      selectDeselectAllSearchResults: function selectDeselectAllSearchResults() {
        var searchSelectAllLabel = this.controller.get('searchSelectAllLabel');
        var searchResultsForSave = this.controller.get('searchResultsForSave');
        var hotelSearchResults = this.controller.get('hotelSearchResults');
        var searchResultsForSaveUpdated = [];
        var selectedOrNot = void 0;

        if (searchSelectAllLabel === 'selectAll') {
          selectedOrNot = true;
          this.controller.set('searchSelectAllLabel', 'deselectAll');
          this.controller.set('isSearchSelectAll', !selectedOrNot);
        } else {
          selectedOrNot = false;
          this.controller.set('searchSelectAllLabel', 'selectAll');
          this.controller.set('isSearchSelectAll', !selectedOrNot);
        }

        hotelSearchResults.forEach(function (hotel) {
          hotel.set('selected', selectedOrNot);
          if (selectedOrNot) {
            searchResultsForSaveUpdated.push(hotel.get('hotelId'));
          }
        });
        this.controller.set('searchResultsForSave', searchResultsForSaveUpdated);

        this.setMapMarkers();
      },
      selectDeselectSingleSearchResult: function selectDeselectSingleSearchResult(index) {
        var hotelSearchResults = this.controller.get('hotelSearchResults');
        var searchResultsForSave = this.controller.get('searchResultsForSave');
        var searchResultsForSaveUpdated = [];

        var hotelId = hotelSearchResults.objectAt(index).get('id');
        if (searchResultsForSave.includes(hotelId)) {
          hotelSearchResults.objectAt(index).set('selected', false);
          searchResultsForSaveUpdated = searchResultsForSave.filter(function (id) {
            return id !== hotelId;
          });
          this.controller.set('searchResultsForSave', searchResultsForSaveUpdated);
        } else {
          hotelSearchResults.objectAt(index).set('selected', true);
          searchResultsForSave.pushObject(hotelId);
          this.controller.set('searchResultsForSave', searchResultsForSave);
        }

        this.setMapMarkers();
      },
      submitSearch: function submitSearch() {
        this.controller.set('hotelSearchResults', []);
        this.controller.set('removedHotels', null);
        this.controller.set('invalidIds', null);
        this.controller.set('noResults', false);
        this.controller.set('isSearchSelectAll', true);
        this.controller.set('searchTooLarge', false);

        // Determines which route to search with:
        var nameOrId = this.controller.get('searchName');
        var paramsToCheck = nameOrId ? nameOrId.split(' ') : [];
        var byId = void 0;
        if (paramsToCheck.length > 0) {
          byId = true;
          paramsToCheck.forEach(function (searchParam, index) {
            var currentParam = _string2.default.trim(searchParam);
            if (byId) {
              var isNum = /^\d+$/.test(currentParam);
              if (!isNum) {
                byId = false;
              } else {
                paramsToCheck[index] = parseInt(currentParam);
              }
            }
          });
        }

        if (byId) {
          this.hotelSearchByIds(paramsToCheck);
        } else {
          this.hotelSearchByParams();
        }
      },
      saveHotels: function saveHotels() {
        var _this10 = this;

        this.controller.set('saving', true);
        this.controller.set('noResults', false);
        var existingHotelIdsForSave = this.controller.get('existingHotelIdsForSave');
        var searchResultsForSave = this.controller.get('searchResultsForSave');
        var allIdsToSave = existingHotelIdsForSave.concat(searchResultsForSave);

        var zoneId = this.controller.get('zone.id');
        return this.controller.get('zone').save({ adapterOptions: { zoneId: zoneId, endPoint: 'hotels', hotelIds: allIdsToSave } }).then(function () {
          _this10.controller.set('lastSavedSection', 'hotelZone');
          _this10.get('notifications').success(_this10.get('i18n').t('components.alert.saveSuccess'));
          _this10.controller.set('saving', false);
          _this10.controller.set('editingDetails', false);
          _this10.controller.set('existingHotelIdsForSave', []);
          _this10.controller.set('searchResultsForSave', []);
          _this10.controller.set('hotelSearchResults', []);
          _this10.controller.set('searchName', null);
          _this10.controller.set('searchCity', null);
          _this10.controller.set('searchZip', null);
          _this10.controller.set('firstHotelLatLong', null);
        }).then(function () {
          _this10.refresh();
          _this10.reloadMap();
        }).catch(function (error) {
          _this10.controller.set('saving', false);
          _this10.controller.set('editingDetails', false);
          _this10.controller.set('errors', error);
          Ember.Logger.error('error saving hotels', error);
        });
      },
      toggleRadioButton: function toggleRadioButton(hotelZoneMappingBy) {
        this.clearInputAndErrorsBulkUpload();
        this.controller.set('hotelZoneMappingBy', hotelZoneMappingBy);
        this.controller.set('isHotelZoneBulkUpload', hotelZoneMappingBy === 'bulkUpload');
      },
      uploadHotelZoneDetails: function uploadHotelZoneDetails(file) {
        if (file && file.target.files[0] && file.target.files[0].name) {
          this.controller.set('hotelZoneFile', file.target.files[0]);
          this.controller.set('hotelZoneFileName', file.target.files[0].name);
        } else {
          this.controller.set('hotelZoneFile', undefined);
          this.controller.set('hotelZoneFileName', undefined);
        }
        this.controller.set('bulkUploadErrors', []);
      },
      triggerFileSelect: function triggerFileSelect() {
        $('#hotel-zone-bulk-upload').trigger('click');
      },
      downloadSampleCSV: function downloadSampleCSV() {
        var sampleData = [{ HotelId: 17325721, ZoneId: 7 }, { HotelId: 17325937, ZoneId: 7 }];
        var fileName = 'bulk-hotel-zone-sample.xlsx';
        this.createCsvAndDownload(sampleData, fileName);
      },
      resetFile: function resetFile() {
        $('#hotel-zone-bulk-upload').val('');
        this.controller.set('hotelZoneFileName', undefined);
        this.controller.set('hotelZoneFile', undefined);
        this.controller.set('bulkUploadErrors', []);
      },
      saveBulkHotelZone: function saveBulkHotelZone() {
        var _this11 = this;

        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.HotelZoneBulkUpload');
        this.controller.set('saving', true);
        this.controller.set('noResults', false);
        var selectedFile = this.controller.get('hotelZoneFile');
        var zoneId = this.controller.get('zone.id');
        var url = _environment.default.api.host + '/api/v3/locationLists/' + zoneId + '/hotels';
        var formData = new FormData();
        formData.append('file', selectedFile);
        $.ajax({
          url: url,
          type: 'PUT',
          data: formData,
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          dataType: 'json',
          processData: false,
          contentType: false,
          headers: {
            Accept: 'application/json'
          }
        }).done(function (response) {
          _this11.controller.set('lastSavedSection', 'hotelZone');
          _this11.get('notifications').success(_this11.get('i18n').t('components.alert.saveSuccess'));
          _this11.controller.set('editingDetails', false);
          _this11.controller.set('bulkUploadSuccessResponse', response);
          _this11.controller.set('showHotelZoneBulkUploadResModal', true);
          _this11.clearInputAndErrorsBulkUpload();
          _this11.refresh();
          _this11.reloadMap();
        }).fail(function (error) {
          if (error.responseJSON && error.responseJSON.errorMessage) {
            _this11.controller.set('bulkUploadErrors', [error.responseJSON.errorMessage]);
          } else {
            _this11.controller.set('bulkUploadErrors', [error.responseText]);
          }
          Ember.Logger.error('error saving hotels', error);
        }).always(function () {
          _this11.controller.set('saving', false);
        });
      },
      closeBulkUploadSuccessResModal: function closeBulkUploadSuccessResModal() {
        var bulkUploadRes = this.controller.get('bulkUploadSuccessResponse');
        if (bulkUploadRes.failed > 0) {
          this.generateReportsBulkUpload(bulkUploadRes.failedRecords);
        }

        this.controller.set('showHotelZoneBulkUploadResModal', false);
        this.controller.set('bulkUploadSuccessResponse', undefined);
      }
    }
  });
});