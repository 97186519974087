define('lxso/mixins/require-supplier-branch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    supplierBranch: Ember.inject.service('supplier-branch'),

    /**
     * Checks that a user has a single supplier branch.
     * Displays the error template on error.
     *
     * __If `beforeModel` is overridden in a route that uses this mixin, the route's
     * implementation must call `this._super(...arguments)`__ so that the mixin's
     * `beforeModel` method is actually executed.
     */
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      return this.get('supplierBranch').getSelectedBranch().catch(function () {
        _this.transitionTo('branch-selection');
      });
    }
  });
});