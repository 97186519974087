define('lxso/validators/option-group-duration', ['exports', 'ember-cp-validations/validators/base', 'lxso/constants/option-group'], function (exports, _base, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OptionGroupDurationValidation = _base.default.extend({
    i18n: Ember.inject.service(),
    validate: function validate(value, options, model, attribute) {
      var durationMinutesPart = parseInt(model.get('durationMinutesPart'));
      var durationHoursPart = parseInt(model.get('durationHoursPart'));
      var durationDaysPart = parseInt(model.get('durationDaysPart'));
      var scheduleType = model.get('scheduleType');

      switch (scheduleType) {
        case _optionGroup.SCHEDULE_TYPES.START:
        case _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION:
          if (isNaN(durationMinutesPart) || isNaN(durationHoursPart) || durationMinutesPart <= 0 && durationHoursPart <= 0) {
            return this.get('i18n').t('pages.optionGroup.errorMessages.hoursAndMins').string;
          }
          break;
        case _optionGroup.SCHEDULE_TYPES.VALID_HOURS:
          if (isNaN(durationHoursPart) || durationHoursPart <= 0) {
            return this.get('i18n').t('pages.optionGroup.errorMessages.hours').string;
          }
          break;
        case _optionGroup.SCHEDULE_TYPES.VALID_DAYS:
        case _optionGroup.SCHEDULE_TYPES.MULTI_DAY:
          if (isNaN(durationDaysPart) || durationDaysPart <= 0) {
            return this.get('i18n').t('pages.optionGroup.errorMessages.invalidDaysInput').string;
          }
          break;
        default:
          return true;
      }
      return true;
    }
  });

  exports.default = OptionGroupDurationValidation;
});