define('lxso/validators/offer-group-size', ['exports', 'ember-cp-validations/validators/base', 'lodash'], function (exports, _base, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OfferGroupSize = _base.default.extend({
    featureFlags: Ember.inject.service(),
    i18n: Ember.inject.service(),
    validate: function validate(offerDetails, options, model, attribute) {
      'use strict';

      var offerDetailsToValidate = offerDetails.filter(function (offerDetail) {
        return offerDetail.status != 'inactive';
      });
      if (this.get('featureFlags').checkEnabled('MultipleOptionsGroupAllSchedules') || offerDetailsToValidate.length <= options.lte) {
        return true;
      } else {
        return model.get('i18n').t(options.messageKey, { maxCount: options.lte, currentCount: offerDetailsToValidate.length }).string;
      }
    }
  });

  OfferGroupSize.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = OfferGroupSize;
});