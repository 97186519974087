define('lxso/components/uitk-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    clickOutsideCloses: true,

    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.$('html').css({
        'overflow': 'hidden'
      });

      this.set('active', 'active');

      Ember.$('body').on('keydown', function (e) {
        if (e.keyCode == 27) {
          _this.sendAction('close');
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('active', undefined);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$('html').css({
        'overflow': 'auto'
      });
    }
  });
});