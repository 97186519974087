define('lxso/components/validation-error-list/component', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    errorListLength: Ember.computed('validationErrors', {
      get: function get() {
        var validationErrors = this.get('validationErrors');
        if (validationErrors) {
          if (validationErrors.selector) {
            return validationErrors.length;
          } else {
            return Object.keys(validationErrors).length;
          }
        }
      }
    }),
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('useInvalidClasses')) {
        Ember.run.next(this, function () {
          _this.set('errorListLength', Ember.$('.uitk-validation-error>li').length);
        });
      }
    },
    click: function click(e) {
      e.preventDefault();
      (0, _formValidator.scrollToFirstError)();
    }

    // Keeping in case we want to use validation list

    // formattedValidationErrors: Ember.computed('validationErrors', {
    //   get() {
    //     const validationErrors = this.get('validationErrors');
    //     const formattedValidationErrors = {};
    //     const keys = Object.keys(validationErrors);
    //     for (let i = 0; i < keys.length; i++) {
    //       formattedValidationErrors[keys[i]] = validationErrors[keys[i]].map((error) => {
    //         return error.includes(':;:') ? error.split(':;:')[1] : error;
    //       })
    //     }
    //     return formattedValidationErrors;
    //   }
    // })

  });
});