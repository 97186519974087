define('lxso/utils/change-history-details-display-modes/v4-price-group-price', ['exports', 'lxso/utils/change-history-details-display-modes/date', 'lxso/utils/change-history-details-display-modes/v4-schedule', 'lxso/utils/change-history-details-display-modes/generic'], function (exports, _date, _v4Schedule, _generic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = {
    partialName: 'v4-price-group-price',
    diff: function diff(oldValue, newValue, _ref) {
      var i18n = _ref.i18n;

      var oldData = oldValue ? getParsedData(oldValue) : {};
      var newData = newValue ? getParsedData(newValue) : {};
      var offerIds = [].concat(_toConsumableArray(getScheduleOfferIds(oldData)), _toConsumableArray(getScheduleOfferIds(newData))).uniq();
      function difference(displayMode, areDisplayable, value) {
        var newValue = value(newData);
        var oldValue = value(oldData);

        return areDisplayable(oldValue, newValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }

      return {
        travelStart: difference(_date.default, present, function (data) {
          return getPriceAttributeByKey(data, 'travelStart');
        }),
        travelEnd: difference(_date.default, present, function (data) {
          return getPriceAttributeByKey(data, 'travelEnd');
        }),
        bookingStart: difference(_date.default, present, function (data) {
          return getPriceAttributeByKey(data, 'bookingStart');
        }),
        bookingEnd: difference(_date.default, present, function (data) {
          return getPriceAttributeByKey(data, 'bookingEnd');
        }),
        ticketType: difference(_generic.default, present, function (data) {
          return getPriceAttributeByKey(data, 'ticketType');
        }),
        minimumTickets: difference(_generic.default, present, function (data) {
          return getPriceAttributeByKey(data, 'minimumTickets');
        }),
        basePrice: difference(_generic.default, present, function (data) {
          return getPriceAttributeByKey(data, 'basePrice', formatPrice);
        }),
        netPrice: difference(_generic.default, present, function (data) {
          return getPriceAttributeByKey(data, 'netPrice', formatPrice);
        }),
        priceCode: difference(_generic.default, present, function (data) {
          return getPriceAttributeByKey(data, 'priceCode');
        }),
        priceId: difference(_generic.default, present, function (data) {
          return getPriceAttributeByKey(data, 'priceId');
        }),
        schedules: getSchedulesOldAndNewByOffer(_v4Schedule.default, oldData.schedules, newData.schedules, offerIds)
      };
    }
  };


  function getParsedData(serializedDate) {
    var priceGroup = JSON.parse(serializedDate);
    priceGroup.price = JSON.parse(priceGroup.price);
    priceGroup.schedules = priceGroup.offerIdSchedules && priceGroup.offerIdSchedules.length ? priceGroup.offerIdSchedules.map(function (offerSchedule) {
      var parsedOfferSchedule = JSON.parse(offerSchedule);
      var schedule = JSON.parse(parsedOfferSchedule.schedule);
      var scheduleDetails = JSON.parse(schedule.details);
      return {
        offerId: parsedOfferSchedule.offerId,
        scheduleType: schedule.scheduleType,
        startTime: scheduleDetails.startTime,
        endTime: scheduleDetails.endTime,
        daysAvailable: scheduleDetails.daysAvailable
      };
    }) : [];
    delete priceGroup.offerIdSchedules;
    return priceGroup;
  }

  function getPriceAttributeByKey(data, key, formatter) {
    if (formatter) {
      return data.price ? formatter(data.price[key]) : null;
    } else {
      return data.price ? data.price[key] : null;
    }
  }

  function getSchedulesOldAndNewByOffer(displayMode, oldSchedules, newSchedules, offerIds) {
    return offerIds.map(function (offerId) {
      var oldValue = oldSchedules ? oldSchedules.find(function (schedule) {
        return schedule.offerId === offerId;
      }) : null;
      var newValue = newSchedules ? newSchedules.find(function (schedule) {
        return schedule.offerId === offerId;
      }) : null;
      return {
        displayMode: displayMode,
        oldValue: oldValue,
        newValue: newValue
      };
    });
  }

  function present(oldValue, newValue) {
    return Ember.isPresent(oldValue) || Ember.isPresent(newValue);
  }

  function formatPrice(price) {
    return parseFloat(price);
  }

  function getScheduleOfferIds(priceGroup) {
    return priceGroup && priceGroup.schedules ? priceGroup.schedules.map(function (s) {
      return s.offerId;
    }) : [];
  }
});