define('lxso/helpers/is-3p', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    threepSupplier: Ember.inject.service(),

    compute: function compute() {
      return this.get('threepSupplier').isSupplier3P();
    }
  });
});