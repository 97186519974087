define('lxso/models/booking-questions', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    name: [(0, _validation.validator)('presence', {
      presence: true,
      descriptionKey: 'pages.bookingQuestions.setName.name'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    supplierBranchId: _emberData.default.attr('number'),
    bookingQuestionTemplateId: _emberData.default.attr('number'),
    bookingQuestionDetails: (0, _attributes.fragmentArray)('fragments/booking-questions/booking-question-details'),
    bookingQuestionOfferDetails: (0, _attributes.fragmentArray)('fragments/booking-questions/booking-question-offer-details'),
    bookingQuestionOfferDetailsMap: (0, _attributes.fragmentArray)('fragments/booking-questions/booking-question-offer-details-map')
  });
});