define('lxso/validators/og-availability-by-days', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OgAvailabilityByDays = _base.default.extend({
    i18n: Ember.inject.service(),
    validate: function validate(availabilityByDays, options, model, attribute) {
      var errorMessage = this.get('i18n').t('pages.optionGroup.errorMessages.minOneAvailabilityDay').string;
      var selectedAvailabilityDays = availabilityByDays.get('availabilities').filter(function (availabilityDay) {
        return availabilityDay.get('available');
      });
      if (selectedAvailabilityDays.length < 1) {
        return errorMessage;
      } else {
        return true;
      }
    }
  });

  exports.default = OgAvailabilityByDays;
});