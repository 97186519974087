define('lxso/components/change-history/bucket/component', ['exports', 'lxso/utils/date-validators'], function (exports, _dateValidators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function limitWhenPresent(field, predicate) {
    return Ember.isPresent(field) ? predicate(moment().subtract(field, 'days')) : true;
  }

  exports.default = Ember.Component.extend({

    classNames: ['uitk-col'],

    filteredChanges: Ember.computed.filter('changes', function (change) {
      var occurredAt = change.get('occurredAt');
      return limitWhenPresent(this.get('fromAgeInDays'), function (date) {
        return (0, _dateValidators.isSameOrBefore)(occurredAt, date);
      }) && limitWhenPresent(this.get('toAgeInDays'), function (date) {
        return occurredAt.isAfter(date);
      });
    }),

    sortedChanges: Ember.computed.sort('filteredChanges', function (a, b) {
      var happenedAtDifferentTimes = b.get('occurredAt').diff(a.get('occurredAt'));
      return happenedAtDifferentTimes || b.get('id') - a.get('id');
    })
  });
});