define('lxso/components/platform-settings-url-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('formatedRelativePath', this.get('relativePath').substring(1));
    },

    actions: {
      onValueChange: function onValueChange(value) {
        if (value === '') {
          this.set('relativePath', '');
        } else {
          this.set('relativePath', '/' + value);
        }
        this.sendAction('onRelativePathChange');
      }
    }
  });
});