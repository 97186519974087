define('lxso/components/promotions/promotion-details-bulk-upload/component', ['exports', 'lxso/utils/form-validator', 'lxso/utils/omniture'], function (exports, _formValidator, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    session: Ember.inject.service(),
    i18n: Ember.inject.service(),
    fileSaver: Ember.inject.service(),
    jsXlsx: Ember.inject.service(),

    validatePromotionDetails: function validatePromotionDetails() {
      var attributeArr = ['totalDiscount', 'expediaFund', 'supplierFund', 'bulkUploadFileName'];
      return (0, _formValidator.validate)({ model: this.get('promotion'), attributes: attributeArr });
    },
    resetValidationError: function resetValidationError() {
      this.set('promotionDetailsValidationErrors', undefined);
    },


    showFileUploadButton: Ember.computed('promotion.bulkUploadFileName', function () {
      var selectedFileName = this.get('promotion.bulkUploadFileName');
      return selectedFileName === undefined;
    }),

    promoDetailPlayback: Ember.computed('promotion', 'recalculatePlayBack', function () {
      var promotion = this.get('promotion');
      return promotion.get('fundType') + ' | \n      ' + this.get('i18n').t('pages.promotion.promotionDetails.discountLabel') + '\n      ' + promotion.get('totalDiscount') + ' \n      ' + this.get('i18n').t('pages.promotion.promotionDetails.symbol.percentage');
    }),

    createCsvAndDownload: function createCsvAndDownload(data, fileName) {
      this.get('jsXlsx').createXlsxFile(data, 'promotion', fileName);
    },


    actions: {
      uploadPromotionDetails: function uploadPromotionDetails(file) {
        if (file && file.target.files[0] && file.target.files[0].name) {
          this.set('promotionFile', file.target.files[0]);
          this.set('promotion.bulkUploadFileName', file.target.files[0].name);
        } else {
          this.set('promotionFile', undefined);
          this.set('promotion.bulkUploadFileName', undefined);
        }
        this.resetValidationError();
      },
      resetFile: function resetFile() {
        $('#promotion-bulk-upload').val('');
        this.set('promotion.bulkUploadFileName', undefined);
        this.set('promotionFile', undefined);
      },
      editSection: function editSection() {
        this.sendAction('edit');
      },
      setFundType: function setFundType(type) {
        this.set('recalculatePlayBack', !this.get('recalculatePlayBack'));
        this.set('promotion.fundType', type);
        this.send('setExpediaSupplierFund');
      },
      setExpediaSupplierFund: function setExpediaSupplierFund() {
        var fundType = this.get('promotion.fundType');
        switch (fundType) {
          case 'Expedia Funded':
            this.set('promotion.expediaFund', 100);
            this.set('promotion.supplierFund', 0);
            break;

          case 'Supplier Funded':
            this.set('promotion.expediaFund', 0);
            this.set('promotion.supplierFund', 100);
            break;

          default:
            this.set('promotion.expediaFund', undefined);
            this.set('promotion.supplierFund', undefined);
            break;
        }

        this.get('promotion').notifyPropertyChange('expediaFund');
        this.get('promotion').notifyPropertyChange('supplierFund');
      },
      setValuesForSplitFunded: function setValuesForSplitFunded(changedInput) {
        var discountType = this.get('promotion.discountTypeUI');
        var totalDiscount = this.get('promotion.totalDiscount') || 0;
        var expediaFund = this.get('promotion.expediaFund') || 0;
        var supplierFund = this.get('promotion.supplierFund') || 0;

        if (discountType === 'Absolute') {
          if (totalDiscount) {
            var valueToSet = 0;
            if (changedInput === 'expediaFund') {
              valueToSet = (totalDiscount - this.get('promotion.expediaFund')).toFixed(2);
              this.set('promotion.supplierFund', parseFloat(valueToSet));
            } else {
              valueToSet = (totalDiscount - this.get('promotion.supplierFund')).toFixed(2);
              this.set('promotion.expediaFund', parseFloat(valueToSet));
            }
          }
        } else {
          var percentageToSet = 0;
          if (changedInput === 'expediaFund') {
            percentageToSet = 100 - this.get('promotion.expediaFund');
            this.set('promotion.supplierFund', parseInt(percentageToSet * 100) / 100);
          } else {
            percentageToSet = 100 - this.get('promotion.supplierFund');
            this.set('promotion.expediaFund', parseInt(percentageToSet * 100) / 100);
          }
        }
      },
      continueSection: function continueSection() {
        var _this = this;

        var promotionDiscount = this.get('promotion.totalDiscount');
        var promoUnit = 'Percentage';
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.promotion-new.continue.details', promotionDiscount, promoUnit);
        this.validatePromotionDetails().then(function () {
          _this.set('promotionDetailsValidationErrors', undefined);
          _this.sendAction('continue');
        }).catch(function (detailsValidationError) {
          _this.set('promotionDetailsValidationErrors', detailsValidationError);
          var i18n = _this.get('i18n');
          if (detailsValidationError.totalDiscount && detailsValidationError.totalDiscount.length > 0) {
            var discountErrors = detailsValidationError.totalDiscount;
            var minFivePercError = i18n.t('pages.promotion.promotionDetails.validationMsg.discountGTFive').string;
            var moreThan75Perc = i18n.t('pages.promotion.promotionDetails.validationMsg.percentageMoreThan75').string;
            if (discountErrors.indexOf(minFivePercError) >= 0) {
              (0, _omniture.sendOmnitureErrorsOnClick)('error.create.mindiscount');
            } else if (discountErrors.indexOf(moreThan75Perc) >= 0) {
              (0, _omniture.sendOmnitureErrorsOnClick)('error.create.maxdiscount');
            }
          }
        });
      },
      triggerFileSelect: function triggerFileSelect() {
        $('#promotion-bulk-upload').trigger('click');
      },
      downloadSampleCSV: function downloadSampleCSV() {
        var sampleData = [{ SupplierBranchId: 165352, ActivityId: 193728, OfferId: 193730, TicketTypeLabel: 'Adult' }];
        var fileName = 'bulk-promotions-sample.xlsx';
        this.createCsvAndDownload(sampleData, fileName);
      }
    }
  });
});