define('lxso/utils/change-history-details-display-modes/activity-title', ['exports', 'lxso/utils/change-history-details-display-modes/generic'], function (exports, _generic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'activity-title',
    diff: function diff(oldValue, newValue) {
      'use strict';

      return {
        oldValue: oldValue,
        newValue: newValue,
        displayMode: _generic.default
      };
    }
  };
});