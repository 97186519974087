define('lxso/models/suppliers', ['exports', 'ember-data', 'lxso/utils/validation', 'lxso/utils/auto-renew-rate'], function (exports, _emberData, _validation, _autoRenewRate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    name: [(0, _validation.validator)('presence', {
      presence: true,
      descriptionKey: 'pages.supplierBranchSettings.supplierSettings.supplierName'
    }), (0, _validation.validator)('length', {
      min: 3,
      allowString: true,
      messageKey: 'errors.customMessages.supplierBranch.supplierNameError'
    })],
    status: [(0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'errors.customMessages.offers.noSelectionMade'
    })],
    isAutoRenewRateEnabled: [(0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'errors.customMessages.offers.noSelectionMade'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    supplier_id: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    vendor_id: _emberData.default.attr('number'),
    branches: _emberData.default.attr('array'),
    auto_renew_rate_enabled: _emberData.default.attr('boolean', { defaultValue: false }),

    autoRenewRateStatusText: Ember.computed('auto_renew_rate_enabled', function () {
      return (0, _autoRenewRate.getAutoRateRenewLabel)(this.get('auto_renew_rate_enabled'));
    })
  });
});