define('lxso/components/actions-menu-tooltip/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      view: function view(id) {
        this.sendAction('view', id);
      },
      edit: function edit(id) {
        this.sendAction('edit', id);
      },
      pause: function pause(id) {
        this.sendAction('pause', id);
      },
      deactivateAction: function deactivateAction(id) {
        this.sendAction('deactivate', id);
      },
      cloneAction: function cloneAction(id) {
        this.sendAction('clone', id);
      },
      promoteAction: function promoteAction(id) {
        this.sendAction('promoteAction', id);
      },
      additionalAction: function additionalAction(id) {
        this.sendAction('additionalMenuOption', id);
      }
    }
  });
});