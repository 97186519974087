define('lxso/routes/activity/customer-service/select-contact', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    resetScroll: false,

    omniture: {
      pageName: 'page.LX-Supply.CustomerServiceInformation'
    },

    setupController: function setupController() {
      this.controller.set('activity', this.modelFor('activity'));
    },


    actions: {
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'customerContactPoint';
      },
      cancel: function cancel() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.CustServInfoCancel');
        this.replaceWith('activity.customer-service', this.controller.get('activity.id'));
      },
      addSupport: function addSupport(id) {
        var _this = this;

        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.CustServInfoAdd');
        var ids = this.controller.get('activity.customer_support_ids');
        ids.push(parseInt(id));
        this.controller.set('activity.customer_support_ids', ids);
        this.controller.set('canAdd', false);

        this.controller.get('activity').updateCustomerService({ 'customer_support_ids': ids }).then(function () {
          _this.replaceWith('activity.customer-service', _this.controller.get('activity.id'));
        });
      },
      sendOmnitureDataForAddPoc: function sendOmnitureDataForAddPoc() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AddPOC');
      },
      willDestroy: function willDestroy() {
        this._super.apply(this, arguments);
        this.controller.set('canAdd', true);
      }
    }
  });
});