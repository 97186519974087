define('lxso/models/offer-group/schedule-and-lang-info', ['exports', 'ember-data-model-fragments', 'ember-data', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberDataModelFragments, _emberData, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    schedules: [(0, _validation.validator)('offer-group-schedule-overlap', {
      volatile: true
    })]
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    schedules: (0, _attributes.fragmentArray)('offer-group/schedule'),
    languageInfo: (0, _attributes.fragment)('fragments/languageInfo'),
    offerDetails: _emberData.default.attr('array', { defaultValue: Ember.A([]) })
    //offerDetails is used for storing information on deleted offers
  });
});