define('lxso/adapters/video', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'api/v3',

    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.host + '/' + this.namespace + '/video/attach';
      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'POST', { data: data });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var action = snapshot.adapterOptions && snapshot.adapterOptions.action ? snapshot.adapterOptions.action : false;
      serializer.serializeIntoHash(data, type, snapshot);
      if (action === 'statusChange') {
        var url = this.host + '/' + this.namespace + '/video/' + snapshot.record.get('id') + '/status?status=' + data.status;
        if (data.status === 'REJECTED') {
          url += '&reason=' + data.rejectReason;
        }
        return this.ajax(url, 'PUT', { data: data });
      } else {
        var _url = this.host + '/' + this.namespace + '/video/' + snapshot.record.get('id');
        return this.ajax(_url, 'PUT', { data: data });
      }
    },
    query: function query(store, type, _query) {
      var activityId = _query.activityId;
      var url = this.host + '/' + this.namespace + '/video/activity/' + activityId;
      return this.ajax(url, 'GET');
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var videoId = snapshot.id;
      var url = this.host + '/' + this.namespace + '/video/' + videoId;
      return this.ajax(url, 'DELETE');
    }
  });
});