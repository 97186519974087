define('lxso/models/price-template/price-template-detail/price-type', ['exports', 'ember-data-model-fragments', 'ember-data'], function (exports, _emberDataModelFragments, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    price_type: _emberData.default.belongsTo('price-template-details'),
    //id handled by ember magic
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    status: _emberData.default.attr('string')
  });
});