define('lxso/components/change-history/link/component', ['exports', 'lxso/mixins/change-type-modes'], function (exports, _changeTypeModes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_changeTypeModes.default, {
    link: Ember.computed('change-item', function () {
      var changeItem = this.get('change-item');
      return this.getLink(changeItem);
    })
  });
});