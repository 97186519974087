define('lxso/mixins/paginate-source-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    // function to be invoked inside setupController once the source-array is defined inside the controller.
    initPaginateSourceArray: function initPaginateSourceArray(sourceArray) {
      this.setProperties({
        paginationSourceArray: Ember.computed(sourceArray, function () {
          return this.get(sourceArray);
        }),
        pageSizeOptions: ['5', '10', '50', '100', 'Show All'],
        pageSizeValues: [5, 10, 50, 100, 'Show All'],
        selectedPageSize: 10,
        pageSize: Ember.computed('selectedPageSize', function () {
          var pageSize = this.get('selectedPageSize');
          if (pageSize === 'Show All') {
            pageSize = this.get('paginationSourceArray.length') + 1;
          }
          return pageSize;
        }),
        currentPage: 1,
        totalPages: Ember.computed('paginationSourceArray.[]', 'pageSize', function () {
          var paginationSourceArray = this.get('paginationSourceArray');
          var pageSize = this.get('pageSize');
          var totalPages = Math.ceil(paginationSourceArray.get('length') / pageSize);
          return totalPages;
        }),
        pagedContent: Ember.computed('paginationSourceArray.[]', 'pageSize', 'currentPage', function () {
          var paginationSourceArray = this.get('paginationSourceArray');
          var pageSize = this.get('pageSize');
          var currentPage = this.get('currentPage');
          var pagedContent = paginationSourceArray.map(function (i) {
            return i;
          }).splice((currentPage - 1) * pageSize, pageSize);
          return pagedContent;
        }),
        pageList: Ember.computed('totalPages', function () {
          var totalPages = parseInt(this.get('totalPages'));
          return Array.from(Array(totalPages), function (_, i) {
            return i + 1;
          });
        })
      });
    },
    goToPage: function goToPage(pageNumber) {
      var currentPage = pageNumber;
      this.set('currentPage', currentPage);
    },


    actions: {
      showPreviousPage: function showPreviousPage() {
        this.goToPage(this.get('currentPage') - 1);
      },
      showNextPage: function showNextPage() {
        this.goToPage(this.get('currentPage') + 1);
      },
      handlePageSizeOptionsChange: function handlePageSizeOptionsChange(value) {
        this.set('selectedPageSize', value);
        this.goToPage(1);
      },
      handlePageChange: function handlePageChange(currentPage) {
        this.goToPage(parseInt(currentPage));
      }
    }
  });
});