define('lxso/components/error-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({

    errorsArray: Ember.computed('errors', function () {
      var errors = this.get('errors');

      if (Array.isArray(errors)) {
        return errors.length > 0 && errors[0].message ? errors.map(function (error) {
          return error.message;
        }).uniq() : errors.uniq();
      } else if ((typeof errors === 'undefined' ? 'undefined' : _typeof(errors)) === 'object') {
        var errorsArray = [];

        for (var error in errors) {
          if (errors.hasOwnProperty(error)) {
            errorsArray = errorsArray.concat(errors[error]);
          }
        }

        return errorsArray.uniq();
      }
    })

  });
});