define('lxso/components/activity-tags/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    groupCategoryTags: Ember.computed('activityTags', function () {
      var groupCategoryTags = [];
      var categoryTags = this.get('activityTags').tags.sortBy('TagType');
      var currentTagIndex = -1;
      for (var i = 0; i < categoryTags.length; i++) {
        if (i > 0 && groupCategoryTags[currentTagIndex].TagType == categoryTags[i].TagType) {
          groupCategoryTags[currentTagIndex].Tag = groupCategoryTags[currentTagIndex].Tag + ', ' + categoryTags[i].Tag;
        } else {
          groupCategoryTags.push({
            TagType: categoryTags[i].TagType,
            Tag: categoryTags[i].Tag
          });
          currentTagIndex++;
        }
      }
      return groupCategoryTags;
    }),

    groupGeoTags: Ember.computed('activityTags', function () {
      var groupedGeoTags = [];
      var geoTags = this.get('activityTags').geoTags.sortBy('TagType');
      var currentTagIndex = -1;
      for (var i = 0; i < geoTags.length; i++) {
        if (i > 0 && groupedGeoTags[currentTagIndex].TagType == geoTags[i].TagType) {
          groupedGeoTags[currentTagIndex].GAIAId = groupedGeoTags[currentTagIndex].GAIAId + ', ' + geoTags[i].GAIAId;
        } else {
          groupedGeoTags.push({
            TagType: geoTags[i].TagType,
            GAIAId: geoTags[i].GAIAId
          });
          currentTagIndex++;
        }
      }
      return groupedGeoTags;
    })
  });
});