define('lxso/authorizers/lxpc', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    authorize: function authorize() {
      // This block method available here sets a request header value.
      // So you could add an auth token to the request. It seems to set only one
      // header value, so if you need more I guess you're out of luck.
      // Our API uses a cookie rather than an auth token so we don't need to call
      // this method. We do seem to need to specify an authorizer even though
      // specifying an authorizer is presumably optional.
      // block();
    }
  });
});