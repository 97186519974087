define('lxso/helpers/epc-url', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.epcUrl = epcUrl;
  function epcUrl(params) {
    return '' + _environment.default.epc.lxAppUrl + params[0];
  }

  exports.default = Ember.Helper.helper(epcUrl);
});