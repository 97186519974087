define('lxso/helpers/option-group/is-clone-availability-row-enabled', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isCloneAvailabilityRowEnabled = isCloneAvailabilityRowEnabled;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function isCloneAvailabilityRowEnabled(params) {
    var _params = _slicedToArray(params, 4),
        availabilityByDaysEnum = _params[0],
        availabilityByOffers = _params[1],
        offerRowIndex = _params[2],
        offerId = _params[3];

    var daysAlreadySelected = availabilityByOffers.filter(function (offerAvailability, index) {
      return offerAvailability.get('offerId') == offerId;
    }).map(function (offerAvailability) {
      return offerAvailability.get('days');
    }).flat();

    var availableDays = availabilityByDaysEnum.filter(function (day) {
      return !daysAlreadySelected.includes(day.id);
    });
    return availableDays.length > 0;
  }

  exports.default = Ember.Helper.helper(isCloneAvailabilityRowEnabled);
});