define("lxso/utils/array-values-higher", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getArrayOfValuesHigher = getArrayOfValuesHigher;
  function getArrayOfValuesHigher(valuesToGet, upperLimit) {
    var returnArray = [];
    for (var i = valuesToGet; i <= upperLimit; i++) {
      returnArray.push(i);
    }
    return returnArray;
  }
});