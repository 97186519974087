define('lxso/components/hours-of-operation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    modelChanged: function () {
      this.set('renderFromTimeDisplay', this.get('ds-model').get('timeFromDisplayText'));
      this.set('renderToTimeDisplay', this.get('ds-model').get('timeToDisplayText'));
    }.observes('ds-model.openThisDay').on('init'),

    actions: {
      updatedFromTime: function updatedFromTime(time) {
        this.get('ds-model.from').setProperties({
          hours: time.hours,
          minutes: time.minutes
        });

        this.get('ds-model').set('timeFromDisplayText');
        this.set('renderFromTimeDisplay', this.get('ds-model').get('timeFromDisplayText'));

        this.send('validateForSequentialTimes');
      },
      updatedToTime: function updatedToTime(time) {
        this.get('ds-model.to').setProperties({
          hours: time.hours,
          minutes: time.minutes
        });

        this.get('ds-model').set('timeToDisplayText');
        this.set('renderToTimeDisplay', this.get('ds-model').get('timeToDisplayText'));

        this.send('validateForSequentialTimes');
      },
      toggleDaysFunction: function toggleDaysFunction(day) {
        //func is toggleDay over in customer-contact-point
        //day is the day model (ds-model)
        this.sendAction('checkbox-action', day);
        this.send('validateForSequentialTimes');
      },
      validateForSequentialTimes: function validateForSequentialTimes() {
        var fromHours = this.get('ds-model').get('from').get('hours');
        var toHours = this.get('ds-model').get('to').get('hours');

        //'from' should be earlier than 'to' (enforced by server-side validation)
        //5:00 - 15:00 is valid
        //15:00 - 5:00 is not

        //path to time inputs from this component: this childViews [0, 1] invalid
        //send 'invalid' to both child time inputs
        this.get('childViews').forEach(function (timeInputField) {
          /*
          Left off here trying to see if only a particular part of the model was dirty before
          marking both 'from' and 'to' fields as invalid
           console.log("fromDirty", this.get('ds-model').get('from').get('hasDirtyAttributes'));
          console.log("toDirty", this.get('ds-model').get('to').get('hasDirtyAttributes'));
           let fromDirty = this.get('ds-model').get('from').get('hasDirtyAttributes');
          let toDirty = this.get('ds-model').get('to').get('hasDirtyAttributes');
           let bothDirty = false;
          if (fromDirty && toDirty) {
            bothDirty = true;
          }
          */

          if (toHours < fromHours) {
            //&& bothDirty
            timeInputField.set('invalid', true);
          } else {
            timeInputField.set('invalid', false);
          }
        });
      }
    }
  });
});