define('lxso/models/activity/location', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation'], function (exports, _emberData, _emberDataModelFragments, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    city: (0, _validation.validator)('presence', true),
    country: (0, _validation.validator)('presence', true),
    formatted_address: (0, _validation.validator)('length', {
      min: 1
    }),
    latitude: [(0, _validation.validator)('number'), (0, _validation.validator)('exclusion', {
      in: [0]
    })],
    longitude: [(0, _validation.validator)('number'), (0, _validation.validator)('exclusion', {
      in: [0]
    })]
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    address_name: _emberData.default.attr('string', { defaultValue: '' }),
    formatted_address: _emberData.default.attr('string', { defaultValue: '' }),
    street: _emberData.default.attr('string', { defaultValue: '' }),
    street_second_line: _emberData.default.attr('string', { defaultValue: '' }),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string', { defaultValue: '' }),
    phone_number: _emberData.default.attr('string', { defaultValue: '' }),
    postal_code: _emberData.default.attr('string', { defaultValue: '' }),
    country: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    timezone: _emberData.default.attr('string', { defaultValue: '' })
  });
});