define('lxso/utils/change-history-details-display-modes/supplier-branch-settings', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lodash'], function (exports, _generic, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'supplier-branch-settings',
    diff: function diff(oldValue, newValue, _ref) {
      var i18n = _ref.i18n;

      var oldData = oldValue ? JSON.parse(oldValue) : {};
      var newData = newValue ? JSON.parse(newValue) : {};

      function difference(displayMode, value) {
        var newValue = value(newData);
        var oldValue = value(oldData);

        return !_lodash.default.isEqual(oldValue, newValue) && {
          displayMode: displayMode,
          oldValue: oldValue,
          newValue: newValue
        };
      }

      return {
        status: difference(_generic.default, function (data) {
          return data.status;
        }),
        supplierBranchName: difference(_generic.default, function (data) {
          return data.supplierBranchName;
        }),
        timeZone: difference(_generic.default, function (data) {
          return data.timeZone;
        }),
        accountVendorId: difference(_generic.default, function (data) {
          return data.accountVendorId;
        }),
        vendorBranchId: difference(_generic.default, function (data) {
          return data.vendorBranchId;
        }),
        salesForceCode: difference(_generic.default, function (data) {
          return data.salesForceCode;
        })
      };
    }
  };
});