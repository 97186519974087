define('lxso/validators/custom-date', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomDate = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var errorMessage = model.get('i18n').t('errors.customMessages.offers.' + options.messageKey).string;

      if (options.genericMessage) {
        errorMessage = model.get('i18n').t('' + options.messageKey).string;
      }

      var thisDate = new Date(value);

      if (options.before) {
        var beforeDate = new Date(options.before);
        if (beforeDate.getTime() < thisDate.getTime()) {
          return errorMessage;
        }
      }

      if (options.after) {
        var afterDate = options.after === 'now' ? new Date() : new Date(options.after);
        afterDate.setHours(0, 0, 0, 0);

        if (afterDate.getTime() > thisDate.getTime() && (options.validateAfterDateAlways || !model.id && !options.allowOnEdit)) {
          return errorMessage;
        }
      }
      return true;
    }
  });

  CustomDate.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = CustomDate;
});