define('lxso/mixins/change-type-modes', ['exports', 'lxso/utils/change-history-details-display-modes'], function (exports, _changeHistoryDetailsDisplayModes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var changeTypes = {
    'SupplierBranch.NotificationSettings': {
      displayMode: _changeHistoryDetailsDisplayModes.emailNotificationSettings,
      link: {
        page: 'supplier-branch-settings',
        bySupplierBranch: true
      }
    },
    'SupplierBranch.Settings': {
      displayMode: _changeHistoryDetailsDisplayModes.supplierBranchSettings,
      link: {
        page: 'supplier-branch-settings',
        bySupplierBranch: true
      }
    },
    'SupplierBranch.ConnectivityStatus': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: {
        page: 'supplier-branch-settings',
        bySupplierBranch: true
      }
    },
    'Activity.Content.CancellationAddendum': {
      displayMode: _changeHistoryDetailsDisplayModes.sentenceContent,
      link: { page: 'activity.customer-service' }
    },
    'Activity.Content.Description': {
      displayMode: _changeHistoryDetailsDisplayModes.sentenceContent,
      link: { page: 'activity.overview' }
    },
    'Activity.Content.Publish': {
      link: { page: 'activity.overview' }
    },
    'Activity.Content.Exclusions': {
      displayMode: _changeHistoryDetailsDisplayModes.listContent,
      link: { page: 'activity.details' }
    },
    'Activity.Content.Highlights': {
      displayMode: _changeHistoryDetailsDisplayModes.listContent,
      link: { page: 'activity.overview' }
    },
    'Activity.Content.Inclusions': {
      displayMode: _changeHistoryDetailsDisplayModes.listContent,
      link: { page: 'activity.details' }
    },
    'Activity.Content.KnowBeforeYouBook': {
      displayMode: _changeHistoryDetailsDisplayModes.listContent,
      link: { page: 'activity.details' }
    },
    'Activity.Content.KnowBeforeYouGo': {
      displayMode: _changeHistoryDetailsDisplayModes.listContent,
      link: { page: 'activity.details' }
    },
    'Activity.Content.LongTitle': {
      displayMode: _changeHistoryDetailsDisplayModes.activityTitle,
      link: { page: 'activity.overview' }
    },
    'Activity.Content.RedemptionInstruction': {
      displayMode: _changeHistoryDetailsDisplayModes.sentenceContent,
      link: { page: 'activity.customer-service' }
    },
    'Activity.Content.RedemptionType': {
      displayMode: _changeHistoryDetailsDisplayModes.sentenceContent,
      link: { page: 'activity.customer-service' }
    },
    'Activity.Content.TermsAndConditions': {
      displayMode: _changeHistoryDetailsDisplayModes.sentenceContent,
      link: { page: 'activity.customer-service' }
    },
    'Activity.Content.GTInstructions': {
      displayMode: _changeHistoryDetailsDisplayModes.sentenceContent,
      link: { page: 'activity.gt-settings' }
    },
    'Activity.Categories': {
      displayMode: _changeHistoryDetailsDisplayModes.listContent,
      link: { page: 'activity.activity-settings' }
    },
    'Activity.CancellationPolicy': {
      displayMode: _changeHistoryDetailsDisplayModes.cancellationPolicy,
      link: { page: 'activity.customer-service' }
    },
    'Activity.Status': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'products', bySupplierBranch: true }
    },
    'Activity.InternalCategory': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.activity-settings' }
    },
    'Activity.SuggestedRank': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.activity-settings' }
    },
    'Activity.ConnectivityStatus': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.activity-settings' }
    },
    'Activity.BookingCutoff': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.customer-service' }
    },
    'Activity.Locations.Primary': {
      displayMode: _changeHistoryDetailsDisplayModes.location,
      link: { page: 'activity.location' }
    },
    'Activity.Locations.Redemption': {
      displayMode: _changeHistoryDetailsDisplayModes.location,
      link: { page: 'activity.location' }
    },
    'Activity.Location': {
      displayMode: _changeHistoryDetailsDisplayModes.location,
      link: { page: 'activity.location' }
    },
    'Activity.LanguagesSupported': {
      displayMode: _changeHistoryDetailsDisplayModes.languagesSupported,
      link: { page: 'activity.activity-settings' }
    },
    'Activity.SupplierOverrideName': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.activity-settings' }
    },
    'Activity.Support': {
      displayMode: _changeHistoryDetailsDisplayModes.contactInformation,
      link: { page: 'activity.customer-service' }
    },
    'Activity.Photos': {
      displayMode: _changeHistoryDetailsDisplayModes.photo,
      link: { page: 'activity.photos' }
    },
    'Activity.Photos.HeroImage': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.photos' }
    },
    'Activity.TicketTypes': {
      displayMode: _changeHistoryDetailsDisplayModes.listContent
    },
    'Activity.CertifiedOwnership': {
      displayMode: _changeHistoryDetailsDisplayModes.certifiedOwnership,
      link: { page: 'activity.overview' }
    },
    'Offer': {
      displayMode: _changeHistoryDetailsDisplayModes.offer,
      link: { page: 'activity.offer' }
    },
    'Offer.BookingCutoff': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.offer' }
    },
    'Offer.Duration': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.offer' }
    },
    'Offer.InventoryType': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.offer' }
    },
    'Offer.LimitedInventoryDaily': {
      displayMode: _changeHistoryDetailsDisplayModes.limitedInventoryDaily,
      link: {
        page: 'manage-availability',
        params: function params(change) {
          var details = change ? JSON.parse(change) : {};
          return { from: details.date, to: details.date };
        },

        bySupplierBranch: true
      }
    },
    'Offer.LimitedInventoryDayOfWeek': {
      displayMode: _changeHistoryDetailsDisplayModes.limitedInventory,
      link: { page: 'activity.offer' }
    },
    'Offer.Price': {
      displayMode: _changeHistoryDetailsDisplayModes.price,
      link: { page: 'activity.offer' }
    },
    'Offer.PartnerTicketTypeId': {
      displayMode: _changeHistoryDetailsDisplayModes.partnerTicketTypeId,
      link: { page: 'activity.offer' }
    },
    'Offer.Status': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.offer' }
    },
    'Offer.Title': {
      displayMode: _changeHistoryDetailsDisplayModes.listContent,
      link: { page: 'activity.offer' }
    },
    'Offer.Schedule': {
      displayMode: _changeHistoryDetailsDisplayModes.schedule,
      link: { page: 'activity.offer' }
    },
    'Offer.ConnectivityStatus': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: {
        page: 'activity.option-settings',
        optionSettings: true
      }
    },
    'Offer.Catalogs': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: {
        page: 'activity.option-settings',
        optionSettings: true
      }
    },
    'Offer.NegativeAvailability': {
      link: {
        page: 'manage-availability',
        params: function params(dateRange) {
          var _dateRange$split = dateRange.split('/'),
              _dateRange$split2 = _slicedToArray(_dateRange$split, 2),
              from = _dateRange$split2[0],
              to = _dateRange$split2[1];

          return { from: from, to: to };
        },

        bySupplierBranch: true
      }
    },
    'Offer.isPriceByVolume': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.offer' }
    },
    'Offer.isHotelPickupEnabled': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.offer' }
    },
    'Offer.isEligibleInPrebookPaths': {
      displayMode: _changeHistoryDetailsDisplayModes.generic,
      link: { page: 'activity.offer' }
    },
    'Promotion': {
      displayMode: _changeHistoryDetailsDisplayModes.promotion,
      link: {
        page: 'promotion',
        params: function params(promotionObj) {
          var promotionDetails = JSON.parse(promotionObj);
          return {
            redirectUrl: 'promote',
            promotionId: promotionDetails.id,
            promotionName: promotionDetails.name
          };
        },

        byPromotion: true
      }
    },
    'Activity.Videos': {
      displayMode: _changeHistoryDetailsDisplayModes.video,
      link: { page: 'activity.photos' }
    },
    'BookingQuestion': {
      displayMode: _changeHistoryDetailsDisplayModes.bookingQuestions,
      link: {
        page: 'booking-questions',
        params: function params(bookingQuestionsObj) {
          var bookingQuestionDetails = JSON.parse(bookingQuestionsObj);
          return {
            bookingQuestionTemplateId: bookingQuestionDetails.id,
            bookingQuestionTemplateName: bookingQuestionDetails.name
          };
        },

        byBookingQuestions: true
      }
    },
    'Offer.PriceCode': {
      displayMode: _changeHistoryDetailsDisplayModes.priceCode,
      link: { page: 'activity.offer' }
    },
    'OfferGroup': {
      displayMode: _changeHistoryDetailsDisplayModes.offerGroup,
      link: {
        page: 'activity.offer',
        params: function params(offerGroup) {
          var offerGroupDetails = JSON.parse(offerGroup);
          return {
            offerGroupId: offerGroupDetails.id,
            offerGroupName: offerGroupDetails.name
          };
        },

        byOfferGroup: true
      }
    },
    'OfferGroup.Details': {
      displayMode: _changeHistoryDetailsDisplayModes.v4offerGroup,
      link: {
        page: 'activity.option-group.group',
        pageInactive: 'activity.option-group',
        params: function params(offerGroup) {
          var offerGroupDetails = JSON.parse(offerGroup);
          return {
            offerGroupName: offerGroupDetails.offerGroupName
          };
        },

        byV4OfferGroup: true
      }
    },
    'OfferGroup.Offer': {
      displayMode: _changeHistoryDetailsDisplayModes.v4offerGroupOffer,
      link: {
        page: 'activity.option-group',
        params: function params(offer) {
          var offerDetails = JSON.parse(offer);
          return {
            offerTitle: offerDetails.offerTitle
          };
        },

        byV4OfferGroupOffer: true
      }
    },
    'OfferGroup.PriceGroup': {
      displayMode: _changeHistoryDetailsDisplayModes.v4PriceGroup,
      link: {
        page: 'activity.option-group.group',
        pageInactive: 'activity.option-group',
        params: function params(offerGroup) {
          var offerGroupDetails = JSON.parse(offerGroup);
          return {
            offerGroupName: offerGroupDetails.offerGroupName
          };
        },

        byV4OfferGroupPriceGroup: true
      }
    },
    'PriceGroup.Price': {
      displayMode: _changeHistoryDetailsDisplayModes.v4PriceGroupPrice,
      link: {
        page: 'activity.option-group.group',
        pageInactive: 'activity.option-group',
        params: function params(offerGroup) {
          var offerGroupDetails = JSON.parse(offerGroup);
          return {
            offerGroupName: offerGroupDetails.offerGroupName
          };
        },

        byV4PriceGroupPrice: true
      }
    }
  };

  var defaultChangeType = {
    displayMode: _changeHistoryDetailsDisplayModes.generic,
    link: {}
  };

  exports.default = Ember.Mixin.create({
    changeType: function changeType(attribute) {
      return changeTypes[attribute] || defaultChangeType;
    },
    getChangeTypeDisplayMode: function getChangeTypeDisplayMode(attribute) {
      var changeType = this.changeType(attribute);
      return changeType.displayMode;
    },
    getLink: function getLink(changeItem) {
      if (!changeItem.get('changeDetail')) {
        return {};
      }

      var changeDetail = changeItem.get('changeDetail');
      var value = changeDetail.get('newValue') || changeDetail.get('oldValue');
      var changeType = this.changeType(changeDetail.get('attribute'));
      var params = Ember.getWithDefault(changeType, 'link.params', function () {
        return undefined;
      });
      var page = this.page(changeType, changeItem);
      var pageInactive = Ember.get(changeType, 'link.pageInactive');
      var bySupplierBranch = Ember.get(changeType, 'link.bySupplierBranch');
      var byPromotion = Ember.get(changeType, 'link.byPromotion');
      var byBookingQuestions = Ember.get(changeType, 'link.byBookingQuestions');
      var byOfferGroup = Ember.get(changeType, 'link.byOfferGroup');
      var byV4OfferGroup = Ember.get(changeType, 'link.byV4OfferGroup');
      var byV4OfferGroupOffer = Ember.get(changeType, 'link.byV4OfferGroupOffer');
      var byV4OfferGroupPriceGroup = Ember.get(changeType, 'link.byV4OfferGroupPriceGroup');
      var byV4PriceGroupPrice = Ember.get(changeType, 'link.byV4PriceGroupPrice');
      var optionSettings = Ember.get(changeType, 'link.optionSettings');
      return {
        page: page,
        pageInactive: pageInactive,
        params: params(value),
        bySupplierBranch: bySupplierBranch,
        byPromotion: byPromotion,
        byBookingQuestions: byBookingQuestions,
        byOfferGroup: byOfferGroup,
        byV4OfferGroup: byV4OfferGroup,
        byV4OfferGroupOffer: byV4OfferGroupOffer,
        byV4OfferGroupPriceGroup: byV4OfferGroupPriceGroup,
        byV4PriceGroupPrice: byV4PriceGroupPrice,
        optionSettings: optionSettings
      };
    },
    page: function page(changeType, changeItem) {
      var page = Ember.get(changeType, 'link.page');
      var isOfferGroup = Ember.get(changeType, 'link.byOfferGroup');
      if (page === 'activity.offer' && Ember.isBlank(changeItem.get('offer.offerId')) && !isOfferGroup) {
        return '';
      }
      return page;
    }
  });
});