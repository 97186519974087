define('lxso/models/option-group-ticket-type-restrictions', ['exports', 'ember-data-model-fragments/attributes', 'lxso/utils/validation', 'ember-data'], function (exports, _attributes, _validation, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Validations = (0, _validation.build)({
    ticketTypeRestrictionOverlap: {
      validators: [(0, _validation.validator)('og-ticket-type-restriction', {
        volatile: true
      })]
    }
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    restrictions: (0, _attributes.fragmentArray)('fragments/option-group/ticket-type-restriction'),
    offerGroupId: _emberData.default.attr('number')
  });
});