define('lxso/models/platform', ['exports', 'ember-data', 'lxso/utils/validation', 'ember-api-actions'], function (exports, _emberData, _validation, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    supportDistributionList: [(0, _validation.validator)('presence', true), (0, _validation.validator)('length', {
      min: 5,
      messageKey: 'errors.tooShort'
    }), (0, _validation.validator)('length', {
      max: 100,
      messageKey: 'errors.tooLong'
    })],
    name: [(0, _validation.validator)('presence', true), (0, _validation.validator)('length', {
      min: 3,
      max: 75,
      messageKey: 'errors.customMessages.basic.tooShort'
    }), (0, _validation.validator)('input-regex-validation', {
      messageKey: 'errors.customMessages.basic.invalidCharacters'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    created_by: _emberData.default.attr('string'),
    created_date: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    isConnectivityEnabled: _emberData.default.attr('boolean', { defaultValue: false }),
    last_saved_by: _emberData.default.attr('string'),
    last_saved_date: _emberData.default.attr('string'),
    supportDistributionList: _emberData.default.attr('string', { defaultValue: '' }), //email
    hasIntegrationUrlConfiguration: _emberData.default.attr('boolean'),
    hasProductionUrlConfiguration: _emberData.default.attr('boolean'),
    //We do not use the configurations attribute of the
    //object the platform call returns because it is
    //incomplete and in a different format
    //than other url integration objects
    integrationUrlConfiguration: _emberData.default.belongsTo('platform-url-configuration'),
    productionUrlConfiguration: _emberData.default.belongsTo('platform-url-configuration'),
    platformSupplierBranches: _emberData.default.hasMany('supplier-branch'),
    isRedemptionEnabled: _emberData.default.attr('boolean', { defaultValue: false }),
    isBookAndHoldEnabled: _emberData.default.attr('boolean', { defaultValue: false }),
    isPricingIngestionEnabled: _emberData.default.attr('boolean', { defaultValue: false }),
    isHotelPickupEnabled: _emberData.default.attr('boolean', { defaultValue: false }),
    apiVersion: _emberData.default.attr('string', { defaultValue: '2.0' }),
    connectivityType: _emberData.default.attr('string')
  });
});