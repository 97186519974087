define('lxso/utils/change-history-details-display-modes/certified-ownership', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'certified-ownership',
    diff: function diff(oldValue, newValue) {
      var newData = newValue && JSON.parse(newValue) || {};
      return {
        user: newData.user,
        date: newData.publishedAt && formatDate(newData.publishedAt)
      };
    }
  };


  function formatDate(dateString) {
    return moment(dateString).format('YYYY-MM-DD');
  }
});