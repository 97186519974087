define('lxso/components/promotions/promotion-audience/component', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('isInternalUser', this.get('permissions').checkEnabled('V3InternalSettings'));
      this.set('audienceRules', {});
      this.setAudienceSwitches();
    },
    setAudienceSwitches: function setAudienceSwitches() {
      var audienceArray = this.get('promotionAudiences');
      if (audienceArray && audienceArray.get('length')) {
        this.set('audienceRules.mip', audienceArray.indexOf(1) >= 0);
        this.set('audienceRules.mod', audienceArray.indexOf(2) >= 0);
        this.set('audienceRules.guest', audienceArray.indexOf(3) >= 0);
      } else {
        this.initAudienceRule();
      }
    },
    initAudienceRule: function initAudienceRule() {
      this.set('audienceRules.mip', false);
      this.set('audienceRules.mod', false);
      this.set('audienceRules.guest', false);
    },


    actions: {
      editSection: function editSection() {
        this.sendAction('edit');
      },
      continueSection: function continueSection() {
        this.set('promotionAudiences', []);
        var audiencesToOmniture = [];
        for (var property in this.get('audienceRules')) {
          if (this.get('audienceRules').hasOwnProperty(property)) {
            switch (property) {
              case 'mip':
                if (this.get('audienceRules.mip')) {
                  this.get('promotionAudiences').pushObject(1);
                  audiencesToOmniture.push('mip');
                }
                break;
              case 'mod':
                if (this.get('audienceRules.mod')) {
                  this.get('promotionAudiences').pushObject(2);
                  audiencesToOmniture.push('mod');
                }
                break;
              case 'guest':
                if (this.get('audienceRules.guest')) {
                  this.get('promotionAudiences').pushObject(3);
                  audiencesToOmniture.push('guest');
                }
                break;
            }
          }
        }
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.promotion-new.continue.audience', audiencesToOmniture.join(','));
        this.sendAction('continue');
      }
    }
  });
});