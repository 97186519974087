define('lxso/helpers/is-option-group-row-filled', ['exports', 'lxso/constants/option-group', 'lxso/constants/language-type-ids'], function (exports, _optionGroup, _languageTypeIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isOptionGroupRowFilled = isOptionGroupRowFilled;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function isOptionGroupRowFilled(params) {
    var _params = _slicedToArray(params, 4),
        languageTypeId = _params[0],
        languageIds = _params[1],
        startTime = _params[2],
        scheduleType = _params[3];

    var isLanguageValid = languageTypeId === _languageTypeIds.LANG_TYPE_IDS.NO_COMMENTARY || languageIds.length > 0;
    var isStartTypeSchedule = scheduleType === _optionGroup.SCHEDULE_TYPES.START || scheduleType === _optionGroup.SCHEDULE_TYPES.MULTI_DAY;

    var _ref = startTime ? startTime.split(':') : ['', ''],
        _ref2 = _slicedToArray(_ref, 2),
        hh = _ref2[0],
        mm = _ref2[1];

    var isStartTimeValid = hh && hh.length && mm && mm.length;
    return isLanguageValid && (!isStartTypeSchedule || isStartTimeValid);
  }

  exports.default = Ember.Helper.helper(isOptionGroupRowFilled);
});