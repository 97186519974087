define('lxso/helpers/has-overlapping-capacity-tt', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasOverlappingCapacityTt = hasOverlappingCapacityTt;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function hasOverlappingCapacityTt(params) {
    var _params = _slicedToArray(params, 3),
        capacityTicketTypeIdAttrsMap = _params[0],
        prices = _params[1],
        currentPrice = _params[2];

    if (currentPrice) {
      //checking if currentPrice ticket type overlaps with other selected ticket types
      if (currentPrice.get('isSelected')) {
        return false;
      } else {
        var currentTicketTypeIdAttrs = capacityTicketTypeIdAttrsMap[currentPrice.get('ticketTypeId')];
        var selectedTicketTypeIds = prices.filter(function (p) {
          return p.get('isSelected');
        }).map(function (p) {
          return p.get('ticketTypeId');
        });
        var hasOverlap = selectedTicketTypeIds.some(function (tt) {
          return capacityTicketTypeIdAttrsMap[tt].minPerGroup <= currentTicketTypeIdAttrs.maxPerGroup && capacityTicketTypeIdAttrsMap[tt].maxPerGroup >= currentTicketTypeIdAttrs.minPerGroup;
        });
        return hasOverlap;
      }
    } else {
      //check if there are any overlapping ticket types among all available capacity TTs
      var sortedTicketTypeAttrs = prices.map(function (p) {
        return p.get('ticketTypeId');
      }).map(function (tt) {
        return capacityTicketTypeIdAttrsMap[tt];
      }).sort(function (ttAttrsA, ttAttrsB) {
        return ttAttrsA.minPerGroup - ttAttrsB.minPerGroup;
      });
      var _hasOverlap = false;
      for (var i = 0; i < sortedTicketTypeAttrs.length - 1; i++) {
        if (sortedTicketTypeAttrs[i].maxPerGroup <= sortedTicketTypeAttrs[i + 1].minPerGroup) {
          _hasOverlap = true;
          break;
        }
      }
      return _hasOverlap;
    }
  }

  exports.default = Ember.Helper.helper(hasOverlappingCapacityTt);
});