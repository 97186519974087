define('lxso/adapters/hotel-zone', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'api/v1',
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    findRecord: function findRecord(store, type, param, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.host + '/' + this.namespace + '/location_lists/zones/' + param;
      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'GET');
    },
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.host + '/' + this.namespace + '/location_lists/zones';
      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'POST', { data: data });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var zoneId = snapshot.id;
      var endPoint = snapshot.adapterOptions.endPoint;
      var dataChange = snapshot.adapterOptions.dataChange;
      var url = this.host + '/' + this.namespace + '/location_lists/zones/' + zoneId;

      if (endPoint) {
        url = url.concat('/' + endPoint);
      }

      serializer.serializeIntoHash(data, type, snapshot);

      var putData = void 0;
      if (endPoint === 'postal_codes') {
        putData = {
          postalCodes: snapshot.adapterOptions.postalCodes,
          username: this.get('session.data.authenticated.display_name')
        };
      } else if (endPoint === 'hotels') {
        putData = {
          hotelIds: snapshot.adapterOptions.hotelIds,
          username: this.get('session.data.authenticated.display_name')
        };
      } else if (dataChange === 'status') {
        putData = {
          statusChanged: true,
          zoneDetails: data.zoneDesc ? data.zoneDesc : '',
          zoneName: data.zoneName,
          status: data.status === 'ACTIVE' ? 1 : 0
        };
      } else {
        putData = data;
        putData.zoneDetails = putData.zoneDesc;
      }

      return this.ajax(url, 'PUT', { data: putData });
    }
  });
});