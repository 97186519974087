define('lxso/components/supplier-settings-margin/component', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),

    init: function init() {
      this._super.apply(this, arguments);
      var productsName = [];
      var productsValue = [];

      var supplierActivities = this.get('supplierActivities');
      if (supplierActivities) {
        supplierActivities.filter(function (activity) {
          return activity.status === 'active' && Ember.isPresent(activity.long_title) && (activity.version == '3' || activity.version == '4');
        }).sort(function (a, b) {
          return a.long_title.localeCompare(b.long_title);
        }).forEach(function (activity) {
          productsName.push(activity.long_title);
          productsValue.push(activity.activity_id);
        });
        this.resetFull();
      }

      this.set('productsName', productsName);
      this.set('productsValue', productsValue);
      this.set('currentOfferValue', null);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('supplierMarginEdit')) {
        this.set('productMarginDirty', false);
        this.set('baseMarginDirty', false);
        this.resetFull();
      }
    },
    resetFull: function resetFull() {
      'use strict';

      this.set('currentProductValue', '');
      this.set('currentOfferValue', '');
      this.reset();
    },
    reset: function reset() {
      'use strict';

      this.unloadMarginOverrides();
      this.setProperties({
        marginOverride: undefined,
        isUpdateProductMargin: false,
        isUpdateTicketTypeMargin: false,
        isUpdateOfferTypeMargin: false,
        errors: ''
      });
      this.resetOffervalue();
    },
    validateProductTicketTypeMargin: function validateProductTicketTypeMargin() {
      'use strict';

      var marginOverride = this.get('marginOverride');
      var attributes = [];
      if (this.get('isUpdateProductMargin')) {
        attributes.push('product_margin');
      }
      if (this.get('isUpdateTicketTypeMargin')) {
        attributes.push('ticket_type_margins');
        marginOverride.get('ticket_type_margins').arrayContentDidChange();
      }
      if (this.get('isUpdateOfferTypeMargin')) {
        attributes.push('offer_margins');
        marginOverride.get('offer_margins').arrayContentDidChange();
      }
      return (0, _formValidator.validate)({ model: marginOverride, attributes: attributes });
    },
    unloadSupplierBranch: function unloadSupplierBranch() {
      'use strict';

      var supplierBranch = this.get('supplierBranch');
      this.get('store').unloadRecord(supplierBranch);
    },
    unloadMarginOverrides: function unloadMarginOverrides() {
      'use strict';

      this.get('store').unloadAll('supplier-branch/margin-overrides');
      this.get('store').unloadAll('supplier-branch/ticket-type-margin');
      this.get('store').unloadAll('supplier-branch/offer-margin');
    },
    unloadOfferMarginOverrides: function unloadOfferMarginOverrides() {
      'use strict';

      this.get('store').unloadAll('supplier-branch/offer-margin');
    },
    resetOffervalue: function resetOffervalue() {
      this.set('currentOffer', null);
      this.set('currentOfferValue', null);
      this.set('isUpdateOfferTypeMargin', false);
    },
    rollbackOffers: function rollbackOffers() {
      this.get('marginOverride.offer_margins').toArray().forEach(function (item) {
        if (item.get('hasDirtyAttributes')) {
          item.rollbackAttributes();
        }
      });
      this.send('validateProductTicketTypeMarginIfInvalid');
    },
    convertMarginDecimalToPercent: function convertMarginDecimalToPercent(decimal) {
      return (decimal * 100).toFixed(2);
    },

    //extract to route
    updateMargin: function updateMargin() {
      var _this = this;

      var supplierBranch = this.get('supplierBranch');

      return (0, _formValidator.validate)({ model: supplierBranch, attributes: ['baseMargin'] }).then(function () {
        return supplierBranch.updateSupplierBranchMargin({
          margin: (supplierBranch.get('baseMargin') / 100).toFixed(4) * 1,
          update_existing_prices: false
        });
      }).then(function (resp) {
        supplierBranch.set('baseMargin', _this.convertMarginDecimalToPercent(resp.margin));
        supplierBranch.reload();
      }).catch(function (reason) {
        return _this.set('errors', reason);
      });
    },

    //extract to route
    updateProductTicketTypeMargin: function updateProductTicketTypeMargin() {
      'use strict';

      var marginOverride = this.get('marginOverride');
      return marginOverride ? this.validateProductTicketTypeMargin().then(function () {
        return marginOverride.save();
      }) : Ember.RSVP.Promise.resolve();
    },
    confirmationCheck: function confirmationCheck() {
      var _this2 = this;

      var supplierBranch = this.get('supplierBranch');

      return new Ember.RSVP.Promise(function (resolve) {
        if (supplierBranch.changedAttributes().baseMargin) {
          _this2.set('showConfirmationModal', true);
          _this2.set('confirmationPromise', resolve);
        } else {
          resolve(true);
        }
      });
    },


    actions: {
      updateMargins: function updateMargins() {
        var _this3 = this;

        //todo update copy
        this.confirmationCheck().then(function (confirmed) {
          _this3.set('showConfirmationModal', false);
          _this3.set('confirmationPromise', null);
          if (confirmed) {
            _this3.updateMargin().then(function () {
              return _this3.updateProductTicketTypeMargin();
            }).then(function () {
              _this3.set('productMarginDirty', false);
              _this3.set('baseMarginDirty', false);
              _this3.sendAction('showSuccess');
              _this3.sendAction('setEditMode', false);
            }).catch(function (reason) {
              Ember.Logger.error('error saving margin', reason);
              _this3.set('errors', reason);
            });
          }
        });

        //todo create promise array and build error object accordingly
      },
      editMarginSection: function editMarginSection() {
        this.sendAction('setEditMode', true);
      },
      confirmUpdate: function confirmUpdate() {
        return this.get('confirmationPromise')(true);
      },
      cancelUpdate: function cancelUpdate() {
        return this.get('confirmationPromise')(false);
      },

      //change to computed value, add getting to route, or refactor to take enum
      updateOfferLists: function updateOfferLists() {
        var offerNames = [];
        var offerValues = [];
        var offerMarginList = this.get('marginOverride.offer_margins');
        if (offerMarginList) {
          offerMarginList.forEach(function (offer) {
            offerNames.push(offer.get('title') + ' (' + offer.get('id') + ')');
            offerValues.push(offer.get('id'));
          });
        }
        this.set('offerNames', offerNames);
        this.set('offerValues', offerValues);
      },

      //extract to route
      productSelected: function productSelected(activityId) {
        'use strict';

        var _this4 = this;

        this.set('productMarginDirty', !!activityId);
        this.reset();

        if (!activityId) {
          return;
        }

        this.set('currentProductValue', activityId);
        var route = this;
        this.get('store').queryRecord('supplier-branch/margin-overrides', { activityId: activityId }).then(function (record) {
          _this4.set('marginOverride', record);

          _this4.set('isUpdateProductMargin', Ember.isPresent(record.get('product_margin')));
          var checked = record.get('ticket_type_margins').any(function (item) {
            return item.get('margin') > 0;
          });
          _this4.set('isUpdateTicketTypeMargin', checked);
          route.send('updateOfferLists');
        }).catch(function (reason) {
          return _this4.set('errors', reason);
        });
      },
      updateProduct: function updateProduct() {
        'use strict';

        var checked = this.toggleProperty('isUpdateProductMargin');
        if (!checked) {
          this.set('marginOverride.product_margin', undefined);
          this.send('validateProductTicketTypeMarginIfInvalid');
        }
      },
      updateTicketType: function updateTicketType(uncheck) {
        'use strict';

        var checked = this.toggleProperty('isUpdateTicketTypeMargin');
        if (!checked) {
          this.get('marginOverride.ticket_type_margins').toArray().forEach(function (item) {
            return item.set('margin', undefined);
          });
          this.send('validateProductTicketTypeMarginIfInvalid');
        }
      },
      validateProductTicketTypeMarginIfInvalid: function validateProductTicketTypeMarginIfInvalid() {
        'use strict';

        var _this5 = this;

        if (!Ember.isEmpty(this.get('errors'))) {
          this.validateProductTicketTypeMargin().then(function () {
            return _this5.set('errors', '');
          }).catch(function (reason) {
            _this5.set('errors', reason);
          });
        }
      },
      baseMarginChange: function baseMarginChange() {
        this.set('baseMarginDirty', true);
      },
      offerSelected: function offerSelected(offerId) {
        'use strict';

        this.rollbackOffers();
        if (!offerId) {
          this.resetOffervalue();
          return;
        }
        var record = this.get('store').peekRecord('supplier-branch/offer-margin', offerId);
        this.set('currentOfferValue', offerId);
        this.set('currentOffer', record);
        this.set('isUpdateOfferTypeMargin', Ember.isPresent(record.get('margin')));
        record.set('isSelected', true);
        if (this.get('isUpdateOfferTypeMargin')) {
          this.send('validateProductTicketTypeMarginIfInvalid');
        }
      },
      updateOfferType: function updateOfferType(event) {
        'use strict';

        var checked = this.toggleProperty('isUpdateOfferTypeMargin');
        if (!checked) {
          this.get('currentOffer').set('margin', undefined);
          this.send('validateProductTicketTypeMarginIfInvalid');
        }
      },
      cancel: function cancel(e) {
        this.sendAction('cancel');
      }
    }
  });
});