define('lxso/services/v4-content-service', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajaxWrapper: Ember.inject.service(),

    updateBasic: function updateBasic(activityId, inputRequestBody) {
      var url = _environment.default.api.hostV4 + '/content/' + activityId + '/basic';
      var requestBody = {
        short: inputRequestBody.get('long_title'),
        long: inputRequestBody.get('description')
      };
      return this.get('ajaxWrapper').ajaxPut(url, requestBody);
    },
    updateHighlights: function updateHighlights(activityId, requestBody) {
      var url = _environment.default.api.hostV4 + '/content/' + activityId + '/highlights';
      return this.get('ajaxWrapper').ajaxPut(url, requestBody);
    },
    updateThings: function updateThings(activityId, requestBody) {
      var url = _environment.default.api.hostV4 + '/content/' + activityId + '/things';
      var body = {
        know_before_you_book: requestBody.get('know_before_you_book'),
        know_before_you_go: requestBody.get('know_before_you_go'),
        gt_instructions: requestBody.get('gt_instructions')
      };
      return this.get('ajaxWrapper').ajaxPut(url, body);
    }
  });
});