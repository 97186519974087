define('lxso/serializers/report', ['exports', 'ember-data', 'lxso/utils/booking-questions'], function (exports, _emberData, _bookingQuestions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var nestedProperties = ['activity', 'supplier', 'pos', 'prices', 'hotelAndFlight', 'traveler', 'activityGuestLocationDetailsModel'];
  var priceFields = ['Netamount', 'Baseprice'];
  var dateTimeFields = ['Transactiondatetime', 'OriginalBookingDate'];

  var dateFormat = 'YYYY-MM-DD';
  var dateTimeFormat = dateFormat + ' HH:mm:ss';
  var dobPassportfields = ['DOB', 'PassportNumber', 'PassportNationality', 'PassportExpirationDate'];

  function flatten(record) {
    nestedProperties.forEach(function (nestedProperty) {
      if (record.hasOwnProperty(nestedProperty)) {
        var nestedFields = record[nestedProperty];
        for (var key in nestedFields) {
          if (nestedFields.hasOwnProperty(key)) {
            record[key] = nestedFields[key];
          }
        }
      }
    });
  }

  function transformHotelAndFlight(hash) {
    hash.DestinationArrivalDate = moment(hash.ArrivalFlightDateTime || hash.Traveldate).format(dateFormat);

    divideDateTime('Arrival');
    divideDateTime('Departure');

    prependAirlineCodeToFlightNumber('Arrival');
    prependAirlineCodeToFlightNumber('Departure');

    function transform(_direction, _prereq, _method) {
      var prereq = _direction + _prereq;
      if (hash[prereq]) {
        _method(prereq);
      }
    }

    function divideDateTime(direction) {
      transform(direction, 'FlightDateTime', function (target) {
        var flightDateTime = hash[target].split(' ');
        hash['Destination' + direction + 'FlightDate'] = flightDateTime[0];
        hash['Destination' + direction + 'FlightTime'] = flightDateTime[1];
      });
    }

    function prependAirlineCodeToFlightNumber(direction) {
      transform(direction, 'AirlineCode', function (prefix) {
        var flightNumber = direction + 'FlightNumber';
        hash['Destination' + direction + 'FlightNumber'] = hash[prefix] + ' - ' + hash[flightNumber];
      });
    }
  }

  function transformStatus(hash) {
    var statusMap = new Map([['complete', 'Purchase'], ['cancelled', 'Cancellation'], ['withhold fulfillment', 'Withhold Fulfillment']]);

    if (hash.Salestatus && statusMap.get(hash.Salestatus)) {
      hash.Salestatus = statusMap.get(hash.Salestatus);
    }
  }

  function roundPrices(hash) {
    if (hash.originalNetAmount && hash.originalBasePrice) {
      priceFields = ['Netamount', 'Baseprice', 'originalNetAmount', 'originalBasePrice'];
    } else {
      priceFields = ['Netamount', 'Baseprice'];
    }

    priceFields.forEach(function (property) {
      hash[property] = Math.round(hash[property] * 100) / 100;
    });
  }

  function formatDateTime(hash, fieldName, format) {
    hash[fieldName] = moment.parseZone(hash[fieldName]).format(format);
  }
  function processTravelerInfo(travelerInfoMap) {
    var travelerInfo = {};
    for (var key in travelerInfoMap) {
      if (travelerInfoMap.hasOwnProperty(key)) {
        var question = (0, _bookingQuestions.default)(key);
        if (question) {
          travelerInfo[question] = travelerInfoMap[key];
        }
      }
    }
    return travelerInfo;
  }
  function transformTravelerInfoBQ(travelersInfo) {
    var allTravelersInfo = [];
    if (travelersInfo) {
      travelersInfo.map(function (ti) {
        allTravelersInfo.push(processTravelerInfo(ti));
      });
    }
    return allTravelersInfo;
  }

  function getTravelerInfoStringForReportFile(BQ_TravelerInfoObj, featureFlags) {
    var travelerInfoReports = '';
    var dobPassportFlagEnabled = featureFlags.checkEnabled('BQPassportDob');
    var isVbp = BQ_TravelerInfoObj.length > 1 ? true : false;
    for (var i = 0; i < BQ_TravelerInfoObj.length; i++) {
      var travelerRow = void 0;
      if (isVbp) {
        travelerRow = 'Traveler ' + (i + 1) + ' - ';
      } else {
        travelerRow = '';
      }
      var travelerDetailsStrList = [];
      var travelerDetails = BQ_TravelerInfoObj[i];
      for (var field in travelerDetails) {
        if (dobPassportfields.indexOf(field) > -1 && !dobPassportFlagEnabled) {
          continue;
        }
        travelerDetailsStrList.push(field + ' : ' + travelerDetails[field]);
      }
      if (i > 0) {
        travelerInfoReports += ' | ';
      }
      travelerInfoReports += travelerRow + travelerDetailsStrList.join('; ');
    }
    return travelerInfoReports;
  }

  function getPickupDropOffInfoStringForReportFile(BQ_PickupDropOffInfoObj, featureFlags, i18n) {
    var pickUpDropOffInfoReports = '';
    var pickupFlagEnabled = featureFlags.checkEnabled('BQPickupInformation');
    var BQFlagEnabled = featureFlags.checkEnabled('BookingQuestions');
    var infoSize = Object.keys(BQ_PickupDropOffInfoObj).length;
    var count = 0;
    if (pickupFlagEnabled && BQFlagEnabled) {
      for (var key in BQ_PickupDropOffInfoObj) {
        var label = i18n.t('pages.report.labels.' + key);
        if (BQ_PickupDropOffInfoObj[key].length) {
          pickUpDropOffInfoReports += label.string.split(' ').join('') + ' : ' + (BQ_PickupDropOffInfoObj[key][0].toUpperCase() + BQ_PickupDropOffInfoObj[key].slice(1));
        } else {
          pickUpDropOffInfoReports += label.string.split(' ').join('') + ' : ' + BQ_PickupDropOffInfoObj[key];
        }
        if (++count < infoSize) {
          pickUpDropOffInfoReports += '; ';
        }
      }
    }
    return pickUpDropOffInfoReports;
  }

  function transformPickUpDropOffInfoBQ(pickUpInfo, infoType, i18n) {
    var pickupDropOffObj = {};
    var rawDate = void 0;
    var dateHours = void 0;
    var dateMinutes = void 0;
    var timePostFix = void 0;
    var finalDate = void 0;
    var medium = void 0;
    if (pickUpInfo.medium != 'noPickupRequired' && pickUpInfo.medium != 'noDropoffRequired') {
      rawDate = new Date(pickUpInfo.dateTime);
      dateHours = rawDate.getHours();
      dateMinutes = rawDate.getMinutes() < 10 ? '0' + rawDate.getMinutes() : rawDate.getMinutes();
      timePostFix = dateHours < 12 ? 'AM' : 'PM';
      finalDate = pickUpInfo.dateTime.split(' ')[0] + ' ' + dateHours % 12 + ':' + dateMinutes + ' ' + timePostFix;
      medium = pickUpInfo.medium;
      if (pickUpInfo.medium != 'hotel') {
        if (infoType == 'pickup') {
          pickupDropOffObj.pickupDate = finalDate;
          pickupDropOffObj.dropOffLocation = pickUpInfo.pickUpDropOff;
          pickupDropOffObj.pickUpMedium = medium;
        } else {
          pickupDropOffObj.dropOffDate = finalDate;
          pickupDropOffObj.arrivalLocation = pickUpInfo.pickUpDropOff;
          pickupDropOffObj.dropOffMedium = medium;
        }
      } else {
        pickupDropOffObj.hotelName = pickUpInfo.location;
      }
      if (pickUpInfo.medium == 'flight') {
        pickupDropOffObj.flightNumber = pickUpInfo.vehicleNumber;
        pickupDropOffObj.airportName = pickUpInfo.location;
      } else if (pickUpInfo.medium == 'rail') {
        pickupDropOffObj.trainNumber = pickUpInfo.vehicleNumber;
        pickupDropOffObj.stationName = pickUpInfo.location;
      } else if (pickUpInfo.medium == 'bus') {
        pickupDropOffObj.busNumber = pickUpInfo.vehicleNumber;
        pickupDropOffObj.stationName = pickUpInfo.location;
      } else if (pickUpInfo.medium == 'ship') {
        pickupDropOffObj.shipNumber = pickUpInfo.vehicleNumber;
        pickupDropOffObj.portName = pickUpInfo.location;
      } else if (pickUpInfo.medium == 'other') {
        if (infoType == 'pickup') {
          pickupDropOffObj.arrivingBy = pickUpInfo.vehicleNumber;
          pickupDropOffObj.arrivalLocation = pickUpInfo.location;
        } else {
          pickupDropOffObj.departingBy = pickUpInfo.vehicleNumber;
          pickupDropOffObj.dropOffLocation = pickUpInfo.location;
        }
      }
    } else {
      if (infoType == 'pickup') {
        pickupDropOffObj.pickUpMedium = i18n.t('pages.report.labels.noPickup').string;
      } else {
        pickupDropOffObj.dropOffMedium = i18n.t('pages.report.labels.noDropoff').string;
      }
    }

    return pickupDropOffObj;
  }

  function transformBookingQuestionanswers(hash, featureFlags, i18n) {
    if (hash.bookingQuestionAnswers) {
      if (featureFlags.checkEnabled('BQTravelerInformation')) {
        hash.BQ_TravelerInfoObj = transformTravelerInfoBQ(hash.bookingQuestionAnswers.travelerInformation);
        hash['BQ Traveler Information'] = getTravelerInfoStringForReportFile(hash.BQ_TravelerInfoObj, featureFlags);
        if (hash.bookingQuestionAnswers.pickUpInformation) {
          hash.BQ_PickupInfoObj = transformPickUpDropOffInfoBQ(hash.bookingQuestionAnswers.pickUpInformation, 'pickup', i18n);
          hash['BQ Pickup Information'] = getPickupDropOffInfoStringForReportFile(hash.BQ_PickupInfoObj, featureFlags, i18n);
        }
        if (hash.bookingQuestionAnswers.dropOffInformation) {
          hash.BQ_DropOffInfoObj = transformPickUpDropOffInfoBQ(hash.bookingQuestionAnswers.dropOffInformation, 'dropoff', i18n);
          hash['BQ DropOff Information'] = getPickupDropOffInfoStringForReportFile(hash.BQ_DropOffInfoObj, featureFlags, i18n);
        }
      }
    }
  }

  exports.default = _emberData.default.JSONSerializer.extend({
    phoneUtil: Ember.inject.service('phone-util'),
    featureFlags: Ember.inject.service(),
    i18n: Ember.inject.service(),
    guid: function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }

      return '' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },
    normalize: function normalize(typeClass, hash) {
      hash.id = this.guid();
      hash.SupplierReferenceNumber = hash.Itinerarynumber === '-1' ? 'N/A' : hash.Itinerarynumber;
      hash.PointOfSale = hash.Posgroupname;
      if (hash.activityGuestLocationDetailsModel && (hash.activityGuestLocationDetailsModel.GuestLocationName || hash.activityGuestLocationDetailsModel.GuestLocationAddress)) {
        var guestLocationParts = [];
        if (hash.activityGuestLocationDetailsModel.GuestLocationName) {
          guestLocationParts.push(hash.activityGuestLocationDetailsModel.GuestLocationName);
        }
        if (hash.activityGuestLocationDetailsModel.GuestLocationAddress) {
          guestLocationParts.push(hash.activityGuestLocationDetailsModel.GuestLocationAddress);
        }
        hash.CustomerStayingAt = guestLocationParts.join('; ');
      }

      flatten(hash);
      transformHotelAndFlight(hash);
      transformStatus(hash);
      if (this.get('featureFlags').checkEnabled('BookingQuestions')) {
        transformBookingQuestionanswers(hash, this.get('featureFlags'), this.get('i18n'));
      }
      roundPrices(hash);
      hash.Travelerphone = this.get('phoneUtil.formatPhone')(hash.Travelerphone);
      dateTimeFields.forEach(function (fieldName) {
        return formatDateTime(hash, fieldName, dateTimeFormat);
      });

      return this._super.apply(this, arguments);
    }
  });
});