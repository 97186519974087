define('lxso/mixins/require-gt-permission', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    permissions: Ember.inject.service('permissions'),
    supplierBranch: Ember.inject.service('supplier-branch'),

    beforeModel: function beforeModel(transition) {
      if (Ember.isNone(this.requiredPermission)) {
        throw new Ember.Error('The requiredPermission property must be set');
      }

      var superResult = this._super.apply(this, arguments);
      var permissions = this.get('permissions');
      var supplierBranchId = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
      var whitelistedSupplierBranch = _environment.default.whiteListedGTSupplierBranch.contains(supplierBranchId);

      var hasPermission = permissions.checkEnabled(this.requiredPermission);

      if (!hasPermission) {
        if (!whitelistedSupplierBranch && !transition.isAborted) {
          transition.abort();
          var isAuthenticated = permissions.checkEnabled('isAuthenticated');
          if (isAuthenticated) {
            this.intermediateTransitionTo('error', new Ember.Error('Permission is denied'));
          } else {
            this.get('session').invalidate();
          }
        }
      }
      return new Ember.RSVP.resolve(superResult);
    }
  });
});