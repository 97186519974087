define('lxso/components/map-location/component', ['exports', 'uitk', 'lodash/collection', 'lxso/config/environment', 'lxso/utils/validation', 'lxso/utils/form-validator', 'lxso/mixins/example-modal'], function (exports, _uitk, _collection, _environment, _validation, _formValidator, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    city: (0, _validation.validator)('presence', {
      presence: true,
      description: 'City'
    }),
    country: (0, _validation.validator)('presence', {
      presence: true,
      description: 'Country'
    }),
    addressResults: (0, _validation.validator)('address-results', {
      messageKey: 'errors.customMessages.addressResults.noMatch'
    }),
    formatted_address: (0, _validation.validator)('length', {
      min: 1,
      lazy: false,
      messageKey: 'errors.customMessages.populateMap'
    })
  });

  exports.default = Ember.Component.extend(_exampleModal.default, Validations, {
    classNames: 'map-location',
    featureFlags: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.setValidity(this.get('index'), false);

      var countryValidate = function countryValidate(arr, query, selected) {
        if (typeof query !== 'undefined' && query.length > 0 && Object.keys(selected).length !== 0) {
          return false;
        } else {
          return true;
        }
      };

      this.set('countryValidate', countryValidate);
      this.set('center-coordinates', '47.613583,-122.196481');

      var currentMap = this.get('location-id');
      if (_uitk.default.map.data) {
        _uitk.default.map.data[currentMap] = {};
        _uitk.default.map.data[currentMap] = {
          'markerData': {
            'markers': []
          },
          'mapOptions': {
            'scrollwheel': false,
            'draggable': false,
            'zoom': 2
          }
        };
      } else {
        _uitk.default.map.data = {};
        _uitk.default.map.data[currentMap] = {};
        _uitk.default.map.data[currentMap] = {
          'markerData': {
            'markers': []
          },
          'mapOptions': {
            'scrollwheel': false,
            'draggable': false,
            'zoom': 2
          }
        };
      }

      Ember.run.scheduleOnce('afterRender', function () {
        if (window.google) {
          _uitk.default.map.initPlugin();
          var city = _this.get('city');
          var country = _this.get('country');
          var timezone = _this.get('timezone');
          var formatted = _this.get('formatted_address');
          var latitude = _this.get('latitude');
          var longitude = _this.get('longitude');
          _this.set('countryDisplay', country);

          _this.mapInit();
          if (Ember.isPresent(timezone)) {
            _this.set('timezoneDisplay', timezone.replace('_', ' '));
          }

          if (Ember.isPresent(latitude) && Ember.isPresent(longitude)) {
            var location = {
              G: latitude,
              K: longitude
            };
            _this.setMapMarker(location);
          } else {
            if (Ember.isPresent(formatted)) {
              _this.getLatLong(formatted);
            }
            if (Ember.isPresent(city) && Ember.isPresent(country)) {
              _this.validateLocation().then(function () {
                _this.updateMap();
              });
            }
          }
        }
      });
    },
    keyUp: function keyUp(e) {
      var _this2 = this;

      var tabKeyPressed = parseInt(e.keyCode) === 9;

      this.setAddressDirty(this.get('index'), true);

      if (this.get('usingManualEntry') && !tabKeyPressed) {
        this.set('formatted_address', '');
      }

      if (this.get('mapErrors')) {
        this.validateLocation().then(function () {
          return _this2.set('mapErrors', undefined);
        }).catch(function (err) {
          return _this2.set('mapErrors', err);
        });
      }
    },
    change: function change() {
      this.setAddressDirty(this.get('index'), true);
    },
    mapInit: function mapInit() {
      // Create autocomplete object, restricting search to geographical location types:
      var autocomplete = new google.maps.places.Autocomplete( /**@type{!HTMLInputElement}**/document.getElementById('address-formatted-' + this.get('index')), { types: ['geocode'] });

      // Set listener on autocomplete object, will update map when address is selected:
      this.addressSelected(autocomplete);
      return autocomplete;
    },
    addressSelected: function addressSelected(autocomplete) {
      $('#address-formatted-' + this.get('index')).keypress(function (event) {
        if (event.keyCode === 10 || event.keyCode === 13) {
          event.preventDefault();
        }
      });

      var that = this;
      autocomplete.addListener('place_changed', function () {
        var place = this.getPlace();
        that.emptyFields();
        that.fillInAddress(place);
        that.updateMap();
      });
    },
    fillInAddress: function fillInAddress(place) {
      // Create variables to concatenated address later
      var streetNumber = null;
      var streetName = null;

      // Set formatted address
      this.set('formatted_address', place.formatted_address);

      // Loop through address components and set them to their form/ember value based on thier google 'type'
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = place.address_components[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var component = _step.value;

          var element = component.types[0];
          switch (element) {
            case 'street_number':
              streetNumber = component.long_name;
              break;
            case 'route':
              streetName = component.long_name;
              break;
            case 'locality':
              this.set('city', component.long_name);
              break;
            case 'administrative_area_level_1':
              this.set('address_state', component.long_name);
              break;
            case 'country':
              this.countryFilter(component);
              break;
            case 'postal_code':
              this.set('postal_code', component.long_name);
              break;
          }
        }

        // Concat street address if needed
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (streetNumber !== null && streetName !== null) {
        var fullStreet = '' + streetNumber + ' ' + ('' + streetName);
        this.set('street', fullStreet);
      } else if (streetName !== null) {
        this.set('street', streetName);
      }
    },
    countryFilter: function countryFilter(country) {
      var baseArray = this.get('countrySource');
      var exactLocationMatch = baseArray.findBy('display', country.long_name);
      if (exactLocationMatch) {
        this.set('countryDisplay', exactLocationMatch.display);
        this.set('country', exactLocationMatch.display);
      } else {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = baseArray[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var arrayCountry = _step2.value;

            if (arrayCountry.display.includes(country.long_name) && country.long_name !== '') {
              this.set('countryDisplay', arrayCountry.display);
              this.set('country', arrayCountry.display);
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }
    },
    validateLocation: function validateLocation() {
      return (0, _formValidator.validate)({ model: this, attributes: ['city', 'country'] });
    },


    //force validate components so errors show
    //if invalid components exist
    onValidateComponentTrigger: function () {
      var _this3 = this;

      (0, _formValidator.validate)({ model: this, attributes: ['city', 'country', 'formatted_address'] }).catch(function (err) {
        if (!_this3.get('usingManualEntry')) {
          _this3.toggleForm();
        }
        _this3.set('mapErrors', err);
        (0, _formValidator.scrollToFirstError)();
      });
    }.observes('validateComponentTrigger'),

    getLatLong: function getLatLong(address) {
      var _this4 = this;

      var geo = new google.maps.Geocoder();
      geo.geocode({ address: address }, function (results, status) {
        var place = results[0];
        var lat = _this4.get('latitude');
        var lng = _this4.get('longitude');

        if (results.length === 0 && lat && lng) {
          // This is primarily for V2 migrated offers, if we don't find the correct
          // location based on google search by address, we will locate by lat/lng
          // which all V2 offers have by default
          _this4.findByLatLong(lat, lng);
        } else {
          _this4.set('addressResults', results);
          _this4.set('addressStatus', status);

          (0, _formValidator.validate)({ model: _this4, attributes: ['addressResults'] }).then(function () {
            _this4.set('mapWarning', undefined);
            if (results.length > 1) {
              _this4.set('mapWarning', _this4.get('i18n').t('errors.customMessages.location.warningMessageStart') + address + _this4.get('i18n').t('errors.customMessages.location.warningMessageEnd'));
            }
            _this4.fillInAddress(place);
            _this4.set('mapErrors', []);
            _this4.setMapMarker(results[0].geometry.location);
          }).catch(function (err) {
            _this4.set('mapErrors', err);
            _this4.sendAction('mapLocationValidityChange', err);
          });
        }
      });
    },
    findByLatLong: function findByLatLong(latitude, longitude) {
      var _this5 = this;

      var latlng = { lat: latitude, lng: longitude };
      var geo = new google.maps.Geocoder();

      geo.geocode({ 'location': latlng }, function (results) {
        if (results.length > 0) {
          // Find the most precise result by looping through types. Types by order:
          // premise, street_address, point_of_interest, park, natural_feature,
          // route, intersection, political, country
          var typeOrder = {
            'premise': 1,
            'street_address': 2,
            'point_of_interest': 3,
            'park': 4,
            'natural_feature': 5,
            'route': 6,
            'intersection': 7,
            'political': 8,
            'country': 9
          };

          var bestResultIndex = 0;
          var highestTypeValue = 10;

          for (var i = 0; i < results.length; i++) {
            var currentTypeKey = results[i].types[0];
            var currentTypeValue = typeOrder[currentTypeKey];
            if (currentTypeValue < highestTypeValue) {
              highestTypeValue = currentTypeValue;
              bestResultIndex = i;
            }
          }

          var bestResult = results[bestResultIndex];
          _this5.fillInAddress(bestResult);
          _this5.set('mapErrors', []);
          _this5.setMapMarker(bestResult.geometry.location);
        } else {
          _this5.set('addressResults', results);

          (0, _formValidator.validate)({ model: _this5, attributes: ['addressResults'] }).then(function () {
            _this5.set('mapWarning', undefined);
          }).catch(function (err) {
            _this5.set('mapErrors', err);
            _this5.sendAction('mapLocationValidityChange', err);
          });
        }
      });
    },
    setMapMarker: function setMapMarker(location) {
      var lat = location.G ? location.G : location.lat();
      var long = location.K ? location.K : location.lng();
      var center = lat + ',' + long;
      var name = this.get('address_name') || '';

      var marker = {
        name: name,
        'latlong': center,
        'content': '',
        'type': 'blue-pin'
      };

      this.set('center-coordinates', center);
      this.set('map-title', name);

      var currentMap = this.get('location-id');

      // Sets all of the marker data
      if (_uitk.default.map.data[currentMap].markerData.markers.length > 0) {
        _uitk.default.map.data[currentMap].markerData.markers = [];
      }
      _uitk.default.map.data[currentMap].markerData.markers.push(marker);
      _uitk.default.map.data[currentMap].mapOptions.center = new google.maps.LatLng(lat, long);
      _uitk.default.map.data[currentMap].mapOptions.zoom = 15;
      this.reloadMap(currentMap);

      this.setTimeZone(center);
      this.set('latitude', lat);
      this.set('longitude', long);
      this.setValidity(this.get('index'), true);
    },
    reloadMap: function reloadMap(currentMap) {
      if (_uitk.default.map.data[currentMap].module) {
        _uitk.default.map.data[currentMap].module.loadMarkers();

        // Reopens the map, causing it to refresh with all of the new data
        _uitk.default.map.data[currentMap].module.open();
      }
    },
    unsetMapMarker: function unsetMapMarker() {
      var currentMap = this.get('location-id');
      _uitk.default.map.data[currentMap].markerData.markers = [];
      this.reloadMap(currentMap);
    },
    setTimeZone: function setTimeZone(location) {
      if (this.get('featureFlags').checkEnabled('IntegrateGoogleMapsApiKey')) {
        this.setTimeZoneWithAPIKey(location);
      } else {
        this.setTimeZoneWithClientId(location);
      }
    },
    setTimeZoneWithAPIKey: function setTimeZoneWithAPIKey(location) {
      var _this6 = this;

      var timestamp = Math.floor(Date.now() / 1000);
      var loc = location;
      var baseUrl = 'external/maps/timezone';
      var params = 'location=' + loc + '&timestamp=' + timestamp + '&channel=LocalExpertPartnerCentral';
      var timezoneUrl = _environment.default.api.host + '/api/v3/' + baseUrl + '?' + params;
      this.get('ajaxWrapper').ajaxGet(timezoneUrl).then(function (response) {
        var timezone = response.timeZoneId.replace('_', ' ');
        _this6.set('timezoneDisplay', timezone);
        _this6.set('timezone', response.timeZoneId);
      });
    },
    setTimeZoneWithClientId: function setTimeZoneWithClientId(location) {
      var _this7 = this;

      var timestamp = Math.floor(Date.now() / 1000);
      var loc = location;
      var timezoneUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/system/map_signature';
      var url = '/maps/api/timezone/json';
      var params = url + '?location=' + loc + '&timestamp=' + timestamp + '&sensor=false&client=gme-expedia';
      params += '&channel=expedia-LXInformation';
      var timezoneData = {
        params: params
      };

      this.get('ajaxWrapper').ajaxPost(timezoneUrl, timezoneData).then(function (resp) {
        var getParams = timezoneData.params + '&signature=' + resp.data;
        var timeURL = 'https://maps.googleapis.com' + getParams;

        Ember.$.ajax({
          url: timeURL,
          dataType: 'json',
          success: function success(results) {
            var timezone = results.timeZoneId.replace('_', ' ');

            _this7.set('timezoneDisplay', timezone);

            // Setting entire time zone object in case we need access to anything later
            _this7.set('timezone', results.timeZoneId);
          },
          error: function error() {}
        });
      });
    },
    updateMap: function updateMap() {
      var _this8 = this;

      this.set('mapErrors', null);
      // this.setAddressDirty(this.get('index'), false);
      this.validateLocation().then(function () {
        var formatted = _this8.get('formatted_address');
        var manual = _this8.get('usingManualEntry');

        if (formatted && !manual) {
          _this8.getLatLong(_this8.get('formatted_address'));
        } else {
          var fields = ['street', 'street_second_line', 'city', 'address_state', 'postal_code', 'countryDisplay'].map(function (name) {
            return _this8.get(name).trim();
          });
          _this8.getLatLong(fields.join(' '));
        }
      }).catch(function (errors) {
        Ember.Logger.error('errors object', errors);
        _this8.set('mapErrors', errors);
        (0, _formValidator.scrollToFirstError)();
        _this8.setValidity(_this8.get('index'), false);
        _this8.sendAction('mapLocationValidityChange', errors);
      });
    },
    emptyFields: function emptyFields() {
      this.set('city', '');
      this.set('countryDisplay', '');
      this.set('country', '');
      this.set('address_state', '');
      this.set('postal_code', '');
      this.set('street', '');
      this.set('street_second_line', '');
      this.set('formatted_address', '');
    },
    toggleForm: function toggleForm() {
      var link = document.getElementById('a-enter-manually-expand-' + this.get('index'));
      var form = document.getElementById('address-form-' + this.get('index'));

      //Expand form
      if (form.style.display === 'block') {
        form.style.display = 'none';
        this.set('usingManualEntry', false);
      } else {
        form.style.display = 'block';
        this.set('usingManualEntry', true);
      }

      //Toggle arrow
      $(link).toggleClass('open');
    },


    actions: {
      checkEmpty: function checkEmpty(event) {
        //If autocomplete empties, form empties
        if (event.target.value.length === 0) {
          this.emptyFields();
        }
      },
      validateIfInvalid: function validateIfInvalid(e) {
        var _this9 = this;

        this.setAddressDirty(this.get('index'), true);
        if (this.get('mapErrors')) {
          this.validateLocation().then(function () {
            _this9.set('mapErrors', undefined);
            _this9.sendAction('setValidity', _this9.get('index'), true);
            _this9.sendAction('mapLocationValidityChange', false);
          }).catch(function (err) {
            _this9.set('mapErrors', err);
            _this9.sendAction('setValidity', _this9.get('index'), false);
            _this9.sendAction('mapLocationValidityChange', err);
          });
        }
      },
      populateMap: function populateMap() {
        this.updateMap();
      },
      selectedCountry: function selectedCountry(data) {
        this.set('country', data.display);
        this.send('validateIfInvalid');
      },


      // Sets bounds of search within geographical location of user:
      geolocate: function geolocate() {
        var that = this;
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            var geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            var circle = new google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy
            });
          });
        }
      },
      expandForm: function expandForm() {
        this.toggleForm();
      }
    }
  });
});