define('lxso/helpers/is-even', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEven = isEven;
  function isEven(params) {
    return params[0] % 2 === 0 ? true : false;
  }

  exports.default = Ember.Helper.helper(isEven);
});