define('lxso/components/paginated-sort-table/component', ['exports', 'lxso/utils/array-values'], function (exports, _arrayValues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'paginated-table',
    isSelectAll: Ember.computed.equal('allButtonLabel', 'selectAll'),

    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setupPageDropdown();
      this.setupPaginationButtons();
      this.itemsToDisplay();
    },
    setupPageDropdown: function setupPageDropdown() {
      var options = this.get('pageOptions');
      var values = this.get('pageValues');
      var selectedItem = this.get('itemsPerPage');
      var isOptionsQuickView = this.get('isOptionsQuickView');

      if (!isOptionsQuickView && (!options || !values || !selectedItem)) {
        this.set('pageOptions', ['5', '10', '50', '100', 'Show All']);
        this.set('pageValues', [5, 10, 50, 100, 'Show All']);
        this.set('itemsPerPage', 10);
      }
    },
    setupPaginationButtons: function setupPaginationButtons() {
      var numberOfItems = this.get('tableItems').get('length');
      var itemsPerPage = this.get('itemsPerPage') === 'Show All' ? numberOfItems : this.get('itemsPerPage');
      var numberOfPages = void 0;
      var remainder = void 0;

      if (numberOfItems % itemsPerPage === 0) {
        remainder = 0;
        numberOfPages = numberOfItems / itemsPerPage;
      } else {
        remainder = numberOfItems % itemsPerPage;
        numberOfPages = (numberOfItems - remainder) / itemsPerPage + 1;
      }

      var pageButtons = (0, _arrayValues.getArrayOfValues)(numberOfPages);

      this.set('itemRemainder', remainder);
      this.set('numberOfPages', numberOfPages);
      this.set('numberOfItems', numberOfItems);
      this.set('pageButtons', pageButtons);
      if (this.get('currentPageFromCampaign') != undefined) {
        this.set('currentPage', this.get('currentPageFromCampaign'));
        this.set('navViewLeft', true);
      }

      if (Ember.typeOf(this.get('currentPage')) === 'undefined' || this.get('currentPage') > numberOfPages) {
        this.set('currentPage', 1);
        this.set('navViewLeft', true);
      } else {
        this.send('pageLinkSelected', this.get('currentPage'));
      }
    },
    itemsToDisplay: function itemsToDisplay() {
      var tableItems = this.get('tableItems');
      var numberOfItems = this.get('tableItems').get('length');
      var itemsPerPage = this.get('itemsPerPage');
      var currentPage = this.get('currentPage');
      var numberOfPages = this.get('numberOfPages');
      var remainder = this.get('remainder');
      var sliceStart = 0;
      var sliceEnd = void 0;
      var displayItems = void 0;

      if (currentPage === numberOfPages && remainder === 0) {
        sliceStart = numberOfItems - itemsPerPage;
        displayItems = tableItems.slice(sliceStart);
      } else if (currentPage === numberOfPages && remainder > 0) {
        sliceStart = numberOfItems - remainder;
        displayItems = tableItems.slice(sliceStart);
      } else if (currentPage === 1 && itemsPerPage === 'Show All') {
        displayItems = tableItems;
      } else if (currentPage === 1) {
        displayItems = tableItems.slice(0, itemsPerPage);
      } else {
        sliceStart = (currentPage - 1) * itemsPerPage;
        sliceEnd = currentPage * itemsPerPage;
        displayItems = tableItems.slice(sliceStart, sliceEnd);
      }
      itemsPerPage = parseInt(itemsPerPage === 'Show All' ? numberOfItems : itemsPerPage);
      var paginationStart = sliceStart + 1;
      var maxPaginationEnd = paginationStart + itemsPerPage - 1;
      var paginationEnd = maxPaginationEnd > numberOfItems ? numberOfItems : maxPaginationEnd;
      this.set('paginationStart', paginationStart);
      this.set('paginationEnd', paginationEnd);
      this.set('displayItems', displayItems);
      this.set('firstToDisplay', displayItems[0]);
    },


    actions: {
      pageDropdownChanged: function pageDropdownChanged(value) {
        this.set('itemsPerPage', value);
        this.setupPaginationButtons();
        this.itemsToDisplay();
      },
      pageLinkSelected: function pageLinkSelected(value) {
        var lastPage = this.get('numberOfPages');
        if (value === 1 || value === 2 || value === 3) {
          this.set('navViewLeft', true);
          this.set('navViewRight', false);
          this.set('navViewMiddle', false);
        } else if (value === lastPage || value === lastPage - 1 || value === lastPage - 2) {
          this.set('navViewRight', true);
          this.set('navViewLeft', false);
          this.set('navViewMiddle', false);
        } else {
          this.set('navViewMiddle', true);
          this.set('navViewLeft', false);
          this.set('navViewRight', false);
        }

        this.set('currentPage', value);
        this.itemsToDisplay();
      },
      selectDeselectAll: function selectDeselectAll() {
        this.sendAction('selectAllAction');
      },
      setSortParams: function setSortParams(headerKey, sortAscending) {
        this.sendAction('setSortParams', headerKey, sortAscending);
      },
      selectDeselectRow: function selectDeselectRow(item, index) {
        this.sendAction('selectDeselectRow', item, index);
      },
      selectDeselectAllRows: function selectDeselectAllRows() {
        this.sendAction('selectDeselectAllRows');
      },
      selectRow: function selectRow(index) {
        var currentPage = this.get('currentPage');
        var itemsPerPage = this.get('itemsPerPage');
        var indexInAllItems = itemsPerPage === 'Show All' ? index : itemsPerPage * (currentPage - 1) + index;
        var selectedItem = this.get('tableItems').objectAt(indexInAllItems);

        this.sendAction('selectSingleAction', indexInAllItems, selectedItem);
      },
      editRow: function editRow(id) {
        this.sendAction('editRow', id);
      },
      deactivateRow: function deactivateRow(id) {
        this.sendAction('deactivateRow', id);
      },
      cloneRow: function cloneRow(id) {
        this.sendAction('cloneRow', id);
      },
      promoteRow: function promoteRow(id) {
        this.sendAction('promoteRow', id);
      }
    }
  });
});