define('lxso/models/activity/basic', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation', 'lxso/utils/regexPatterns'], function (exports, _emberData, _emberDataModelFragments, _validation, _regexPatterns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    long_title: {
      descriptionKey: Ember.computed('model.isGroundTransfer', function () {
        if (this.get('model.isGroundTransfer')) {
          return 'pages.createactivity.labels.groundTransfer';
        } else {
          return 'fieldNames.activity.activityName';
        }
      }),
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('length', {
        min: 4,
        max: 75,
        messageKey: 'errors.customMessages.basic.tooShort'
      }), (0, _validation.validator)('input-regex-validation', {
        messageKey: 'errors.customMessages.basic.invalidCharacters'
      })]
    },
    short_title: (0, _validation.validator)('presence', true),
    internal_title: (0, _validation.validator)('alias', 'long_title'),
    suggested_for: (0, _validation.validator)('presence', true),
    description: {
      descriptionKey: 'fieldNames.activity.description',
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        messageKey: 'errors.customMessages.basic.noDescriptionEntered'
      }), (0, _validation.validator)('markdown-stripped-length', {
        min: 4,
        max: 2500,
        messageKey: 'errors.customMessages.basic.tooShort'
      }), (0, _validation.validator)('input-regex-validation', {
        messageKey: 'errors.customMessages.basic.invalidCharacters'
      })]
    }
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    internal_title: _emberData.default.attr('string', { defaultValue: '' }),
    short_title: _emberData.default.attr('string', { defaultValue: '' }),
    long_title: _emberData.default.attr('string', { defaultValue: '' }),
    suggested_for: _emberData.default.attr('string', { defaultValue: '' }),
    description: _emberData.default.attr('string', { defaultValue: '' })
  });
});