define('lxso/mixins/platform-settings/platform-details', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _validatePlatformDetails: function _validatePlatformDetails(platform) {
      return (0, _formValidator.validate)({ model: platform, attributes: ['name', 'supportDistributionList'] });
    },
    initPlatformDetails: function initPlatformDetails(model) {
      this.controller.set('isPlatformDetailsOpen', true);
      this.controller.set('platform', model);
    },
    togglePlatformDetailsEdit: function togglePlatformDetailsEdit() {
      this.controller.set('editingPlatformDetails', !this.controller.get('editingPlatformDetails'));
    },

    actions: {
      savePlatform: function savePlatform(platform) {
        var _this = this;

        this.controller.set('saving', true);
        var isNewPlatform = platform.get('isNew');
        this.controller.set('lastSaved', 'platformDetails');
        this._validatePlatformDetails(platform).then(function () {
          return platform.save();
        }).then(function (platform) {
          _this.send('resetPlatformDetailsFormDirty');
          if (isNewPlatform) {
            _this.transitionTo('platform', platform.id);
          } else {
            _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
            _this.togglePlatformDetailsEdit();
          }
        }).catch(function (errors) {
          Ember.Logger.error('error saving platform details', errors);
          _this.controller.set('errors', errors);
        }).finally(function () {
          _this.controller.set('saving', false);
        });
      },
      editPlatformDetails: function editPlatformDetails() {
        if (this.controller.get('isAnySectionEditing')) {
          this.controller.set('sectionToBeEdited', 'platformDetails');
        } else {
          this.togglePlatformDetailsEdit();
        }
      },
      cancelPlatformDetails: function cancelPlatformDetails() {
        this.controller.get('platform').rollbackAttributes();
        this.togglePlatformDetailsEdit();
        this.send('resetPlatformDetailsFormDirty');
        this.controller.set('errors', []);
      },
      setPlatformDetailsFormDirty: function setPlatformDetailsFormDirty() {
        this.controller.set('isPlatformDetailsDirty', true);
      },
      resetPlatformDetailsFormDirty: function resetPlatformDetailsFormDirty() {
        this.controller.set('isPlatformDetailsDirty', false);
      },
      validatePlatformDetails: function validatePlatformDetails(platform) {
        var _this2 = this;

        this._validatePlatformDetails(platform).then(function () {
          _this2.controller.set('errors', '');
        }).catch(function (errors) {
          Ember.Logger.error('error saving platform details', errors);
          _this2.controller.set('errors', errors);
        });
      },
      transitionToPlatformList: function transitionToPlatformList() {
        this.transitionTo('platform-list');
      }
    }
  });
});