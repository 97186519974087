define('lxso/components/booking-questions/bq-modals/bq-products-option-groups-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    uitk: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('noProductsSelected', this.get('selectedProducts').length <= 0);
      this.setNoOptionsSelected();
      this.set('finalSelectedProductsCount', this.get('selectedProductsAndOptionGroups').uniqBy('activityId').length);
    },
    setNoOptionsSelected: function setNoOptionsSelected() {
      var noOptionsSelected = this.get('selectedProducts').filter(function (p) {
        return p.offerGroups.filter(function (og) {
          return og.get('isOptionGroupSelected');
        }).length > 0;
      }).length === 0;
      this.set('noOptionsSelected', noOptionsSelected);
    },


    actions: {
      onProductSelect: function onProductSelect(product) {
        var selectedCount = 0;
        product.set('isProductSelected', !product.isProductSelected);

        this.get('productList').forEach(function (listProduct) {
          if (listProduct.id == product.id) {
            listProduct.set('isProductSelected', product.get('isProductSelected'));
          }
          if (listProduct.isProductSelected) {
            selectedCount++;
          }
        });
        this.set('noProductsSelected', selectedCount <= 0);

        // set select all checkbox
        this.set('productsSelectAllEnabled', selectedCount > 0 && selectedCount == this.get('productList').length);
      },
      onOptionGroupSelect: function onOptionGroupSelect(optionGroup, selectedProduct) {
        optionGroup.set('isOptionGroupSelected', !optionGroup.isOptionGroupSelected);
        selectedProduct.set('isAllOptionGroupsSelected', selectedProduct.offerGroups.length === selectedProduct.offerGroups.filter(function (og) {
          return og.isOptionGroupSelected;
        }).length);
        this.setNoOptionsSelected();
      },
      toggleSelectAllProducts: function toggleSelectAllProducts() {
        var _this = this;

        var selectedCount = 0;
        this.set('productsSelectAllEnabled', !this.get('productsSelectAllEnabled'));
        this.get('productList').forEach(function (listProduct) {
          listProduct.set('isProductSelected', _this.get('productsSelectAllEnabled'));
          if (listProduct.isProductSelected) {
            selectedCount++;
          }
        });
        this.set('noProductsSelected', selectedCount <= 0);
      },
      toggleSelectAllOptionsGroupsUnderProduct: function toggleSelectAllOptionsGroupsUnderProduct(selectedProduct) {
        var selectedOptionsGroupsCount = 0;
        selectedProduct.toggleProperty('isAllOptionGroupsSelected');
        selectedProduct.offerGroups.forEach(function (listOptionGroup) {
          listOptionGroup.set('isOptionGroupSelected', selectedProduct.get('isAllOptionGroupsSelected'));
          if (listOptionGroup.isOptionGroupSelected) {
            selectedOptionsGroupsCount++;
          }
        });

        this.setNoOptionsSelected();
      },
      goBackToProductsSection: function goBackToProductsSection() {
        this.set('optionsView', false);
        this.set('productsView', true);
        this.setNoOptionsSelected();
        this.set('noProductsSelected', this.get('selectedProducts').length <= 0);
      },
      saveProductSelection: function saveProductSelection() {
        this.send('setSelectedProductsList');
        this.set('productsView', false);
        this.set('optionsView', true);
        this.setNoOptionsSelected();
      },
      saveOptionsSelection: function saveOptionsSelection() {
        this.send('setSelectedOptionsList');
        this.sendAction('completeProductsAndOptionsSection');
      },
      setSelectedOptionsList: function setSelectedOptionsList() {
        var selectedProductsAndOptionGroups = [];
        this.get('selectedProducts').forEach(function (selectedProduct) {
          selectedProduct.offerGroups.forEach(function (optionGroup) {
            if (optionGroup.isOptionGroupSelected) {
              var tempProduct = {};
              tempProduct.activityId = selectedProduct.id;
              tempProduct.offerGroupId = optionGroup.id;
              selectedProductsAndOptionGroups.push(tempProduct);
            }
          });
        });
        this.set('selectedProductsAndOptionGroups', selectedProductsAndOptionGroups);
        this.set('finalSelectedProductsCount', this.get('selectedProducts').length);
      },
      skipOptionsSelection: function skipOptionsSelection() {
        this.get('selectedProducts').forEach(function (selectedProduct) {
          Ember.set(selectedProduct, 'isAllOptionGroupsSelected', false);
          selectedProduct.offerGroups.forEach(function (optionGroup) {
            optionGroup.set('isOptionGroupSelected', false);
          });
        });
        this.set('finalSelectedProductsCount', this.get('selectedProducts').length);
        this.set('selectedProductsAndOptionGroups', []);
        this.sendAction('completeProductsAndOptionsSection');
      },
      setSelectedProductsList: function setSelectedProductsList() {
        var selectedProducts = [];
        this.get('productList').forEach(function (product) {
          if (product.isProductSelected) {
            selectedProducts.push(product);
          }
        });
        this.set('selectedProducts', selectedProducts);
      },
      toggleProductOptionsAccordion: function toggleProductOptionsAccordion(index) {
        var link = document.getElementsByClassName('toggle-trigger product-options-accordion-' + index);
        $(link).toggleClass('open');

        var optionsList = document.getElementsByClassName('product-options-accordion-body-' + index);
        $(optionsList).toggleClass('hidden');
      }
    }
  });
});