define('lxso/utils/change-history-details-display-modes/v4-schedule', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lxso/utils/change-history-details-display-modes/table'], function (exports, _generic, _table) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'v4-schedule',
    diff: function diff(oldValue, newValue, _ref) {
      var i18n = _ref.i18n;

      var toString = function toString(detail) {
        return detailToString(detail, translator(i18n));
      };

      return {
        name: {
          displayMode: _generic.default,
          oldValue: oldValue && fixGeneratedName(oldValue.offerId),
          newValue: newValue && fixGeneratedName(newValue.offerId)
        },
        details: {
          displayMode: _table.default,
          oldValue: oldValue ? getDaysOfWeekSchedule(oldValue).map(toString) : [],
          newValue: newValue ? getDaysOfWeekSchedule(newValue).map(toString) : []
        }
      };
    }
  };


  function getDaysOfWeekSchedule(scheduleDetails) {
    var days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    var daysAvailable = scheduleDetails.daysAvailable || [];
    var daysOfWeekScheduleDetails = daysAvailable.map(function (day) {
      return {
        start: scheduleDetails.startTime ? scheduleDetails.startTime : undefined,
        end: scheduleDetails.endTime ? scheduleDetails.endTime : undefined,
        day: day,
        dayIndex: days.indexOf(day)
      };
    });

    return new Array(7).fill(undefined).map(function (dayOfWeek, dayIndex) {
      return daysOfWeekScheduleDetails.find(function (sd) {
        return days.indexOf(sd.day) === dayIndex;
      });
    });
  }

  function fixGeneratedName(offerId) {
    return 'Option: ' + offerId;
  }

  function detailToString(detail, translate) {
    var timeRange = timeRangeFrom(detail);
    var detailType = detailTypeFrom(timeRange);

    return translate(detailType.type, timeRange);
  }

  function translator(i18n) {
    return function (key, timeRange) {
      return i18n.t('pages.changeHistory.labels.scheduleDetail.' + key, formatRange(timeRange)).string;
    };
  }

  function timeRangeFrom(detail) {
    return detail && { start: parseTime(detail.start), end: parseTime(detail.end) };
  }

  function parseTime(time) {
    return time && moment(time, 'HH:mm');
  }

  function formatRange(detail) {
    return { start: formatTime(detail && detail.start), end: formatTime(detail && detail.end) };
  }

  function formatTime(time) {
    return time && time.format('hh:mm A');
  }

  var detailTypes = [{
    type: 'notAvailable',
    applies: function applies(timeRange) {
      return timeRange === undefined;
    }
  }, {
    type: 'open',
    applies: function applies(_ref2) {
      var start = _ref2.start,
          end = _ref2.end;

      return start === undefined && end === undefined;
    }
  }, {
    type: 'startTime',
    applies: function applies(_ref3) {
      var end = _ref3.end;

      return end === undefined;
    }
  }, {
    type: 'allDay',
    applies: function applies(_ref4) {
      var start = _ref4.start,
          end = _ref4.end;

      var startOfDay = moment().startOf('day');
      var endOfDay = moment(startOfDay).set({ hour: 23, minute: 59 });
      return start.isSame(startOfDay) && end.isSameOrAfter(endOfDay);
    }
  }, {
    type: 'timeRangeToNextDay',
    applies: function applies(_ref5) {
      var start = _ref5.start,
          end = _ref5.end;

      return start.isAfter(end);
    }
  }, {
    type: 'timeRange',
    applies: function applies(_ref6) {
      var start = _ref6.start,
          end = _ref6.end;

      return start.isSameOrBefore(end);
    }
  }];

  function detailTypeFrom(timeRange) {
    return detailTypes.find(function (detailType) {
      return detailType.applies(timeRange);
    });
  }
});