define('lxso/components/bq-selected-offers-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      openStep: function openStep(className) {
        var link = document.getElementsByClassName('toggle-trigger ' + className);
        $(link).toggleClass('open');

        var contents = document.getElementsByClassName(className + '-content');
        $(contents).toggleClass('close');
      }
    }
  });
});