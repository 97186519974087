define('lxso/utils/change-history-details-display-modes/schedule-details', ['exports', 'lxso/utils/change-history-details-display-modes/generic', 'lxso/utils/change-history-details-display-modes/table'], function (exports, _generic, _table) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'schedule-details',
    diff: function diff(oldValue, newValue, _ref) {
      var i18n = _ref.i18n;

      var toString = function toString(detail) {
        return detailToString(detail, translator(i18n));
      };

      return {
        name: {
          displayMode: _generic.default,
          oldValue: oldValue && fixGeneratedName(oldValue.name),
          newValue: newValue && fixGeneratedName(newValue.name)
        },
        details: {
          displayMode: _table.default,
          oldValue: oldValue && oldValue.times ? detailsForDaysOfWeek(oldValue.times).map(toString) : [],
          newValue: newValue && newValue.times ? detailsForDaysOfWeek(newValue.times).map(toString) : []
        }
      };
    }
  };


  function fixGeneratedName(name) {
    return name && name.replace(/^Offer: (\d+)$/, 'Option: $1');
  }

  function detailsForDaysOfWeek(details) {
    return new Array(7).fill(undefined).map(function (dayOfWeek, dayIndex) {
      return findDetail(details, dayIndex);
    });
  }

  function findDetail(details, dayIndex) {
    return details.find(function (detail) {
      return detail.day & 1 << dayIndex;
    });
  }

  function detailToString(detail, translate) {
    var timeRange = timeRangeFrom(detail);
    var detailType = detailTypeFrom(timeRange);

    return translate(detailType.type, timeRange);
  }

  function translator(i18n) {
    return function (key, timeRange) {
      return i18n.t('pages.changeHistory.labels.scheduleDetail.' + key, formatRange(timeRange)).string;
    };
  }

  function timeRangeFrom(detail) {
    return detail && { start: parseTime(detail.start), end: parseTime(detail.end) };
  }

  function parseTime(time) {
    return time && moment(time, 'HH:mm');
  }

  function formatRange(detail) {
    return { start: formatTime(detail && detail.start), end: formatTime(detail && detail.end) };
  }

  function formatTime(time) {
    return time && time.format('hh:mm A');
  }

  var detailTypes = [{
    type: 'notAvailable',
    applies: function applies(timeRange) {
      return timeRange === undefined;
    }
  }, {
    type: 'open',
    applies: function applies(_ref2) {
      var start = _ref2.start,
          end = _ref2.end;

      return start === undefined && end === undefined;
    }
  }, {
    type: 'startTime',
    applies: function applies(_ref3) {
      var end = _ref3.end;

      return end === undefined;
    }
  }, {
    type: 'allDay',
    applies: function applies(_ref4) {
      var start = _ref4.start,
          end = _ref4.end;

      var startOfDay = moment().startOf('day');
      var endOfDay = moment(startOfDay).set({ hour: 23, minute: 59 });
      return start.isSame(startOfDay) && end.isSameOrAfter(endOfDay);
    }
  }, {
    type: 'timeRangeToNextDay',
    applies: function applies(_ref5) {
      var start = _ref5.start,
          end = _ref5.end;

      return start.isAfter(end);
    }
  }, {
    type: 'timeRange',
    applies: function applies(_ref6) {
      var start = _ref6.start,
          end = _ref6.end;

      return start.isSameOrBefore(end);
    }
  }];

  function detailTypeFrom(timeRange) {
    return detailTypes.find(function (detailType) {
      return detailType.applies(timeRange);
    });
  }
});