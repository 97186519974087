define('lxso/validators/input-regex-validation', ['exports', 'lxso/utils/regexPatterns', 'ember-cp-validations/validators/base'], function (exports, _regexPatterns, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InputRegexValidation = _base.default.extend({
    i18n: Ember.inject.service(),

    validate: function validate(value, options, model, attribute) {
      var invalidChar = [];

      if (value && value.length) {
        for (var i = 0; i < value.length; i++) {
          if (!_regexPatterns.validForm.validFormCharWithUnderscore.test(value[i]) && !invalidChar.includes(value[i])) {
            invalidChar.push(value[i]);
          }
        }
      }

      if (invalidChar.length > 0) {
        var invalidString = invalidChar.join(' ');
        var fieldName = options.descriptionKey;
        var errorText = options.messageKey;
        var translatedFieldName = this.get('i18n').t(fieldName);
        var translatedErrorText = this.get('i18n').t(errorText, { description: translatedFieldName });
        if (options.composableErrorMessage) {
          return {
            errorText: translatedErrorText,
            errorCharacters: invalidChar
          };
        } else {
          var error = translatedErrorText + ': ' + invalidString;
          return error;
        }
      } else {
        return true;
      }
    }
  });

  exports.default = InputRegexValidation;
});