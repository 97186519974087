define('lxso/controllers/report', ['exports', 'lxso/models/report', 'lxso/mixins/date-range', 'lxso/mixins/report-sort', 'lxso/mixins/report-filter', 'lxso/utils/date-validators', 'lxso/utils/reports-to-array'], function (exports, _report, _dateRange, _reportSort, _reportFilter, _dateValidators, _reportsToArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var API_DATE_FIELD_BY_DATE_TYPE_MAP = new Map([['arrival', 'Arrival'], ['status', 'Transaction']]);
  var DEFAULT_SEARCH_DATETYPE = API_DATE_FIELD_BY_DATE_TYPE_MAP.keys().next().value;

  var SELECT_SEARCH_TYPES_MAP = new Map([['date range', 'date-range-div'], ['itinerary number', 'supplier-reference-number-div']]);
  var DEFAULT_SELECT_SEARCH_TYPE = SELECT_SEARCH_TYPES_MAP.values().next().value;

  exports.default = Ember.Controller.extend(_dateRange.default, _reportSort.default, _reportFilter.default, {
    JsonToXmlSpreadsheet: Ember.inject.service(),
    uitk: Ember.inject.service(),
    fileSaver: Ember.inject.service(),
    dateFormat: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    jsXlsx: Ember.inject.service(),
    maxDateRange: moment.duration(92, 'days'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('selectSearchTypes', {
        selected: DEFAULT_SELECT_SEARCH_TYPE,
        options: [],
        values: []
      });
      SELECT_SEARCH_TYPES_MAP.forEach(function (value, key) {
        _this.get('selectSearchTypes.options').push(key);
        _this.get('selectSearchTypes.values').push(value);
      });
      var v3BookingReportOnBranchLevelEnabled = this.get('featureFlags').checkEnabled('V3BookingReportsOnBranchLevel');
      this.set('branchLevelEnabled', v3BookingReportOnBranchLevelEnabled);
    },
    getOrSetDefault: function getOrSetDefault(name, defaultValue) {
      var controllerValue = this.get(name);
      return controllerValue ? controllerValue : this.set(name, defaultValue);
    },
    setSearchDateType: function setSearchDateType(inputElement) {
      var searchDateType = this.getOrSetDefault('searchDateType', DEFAULT_SEARCH_DATETYPE);
      var inputToCheck = '#searchDateType' + searchDateType.capitalize();
      var element = inputElement ? inputElement : Ember.$(inputToCheck);
      element.prop('checked', true);
      this.triggerTransitionTo({ searchDateType: searchDateType });
    },
    setSearchType: function setSearchType() {
      var selectedValue = this.get('model.selectedSearchType');
      if (!selectedValue) {
        selectedValue = this.getOrSetDefault('selectSearchTypes.selected', DEFAULT_SELECT_SEARCH_TYPE);
      }
      this.send('selectSearch', selectedValue);
    },
    showSelectedSearchType: function showSelectedSearchType(inputElement) {
      var selectedType = this.get('selectSearchTypes.selected');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = SELECT_SEARCH_TYPES_MAP.values()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var v = _step.value;

          var id = '#' + v;
          var element = inputElement ? inputElement.find(id) : Ember.$(id);
          if (v === selectedType) {
            element.show();
          } else {
            element.hide();
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    setup: function setup() {
      this._super.apply(this, arguments);
      var selectedSearchType = this.getOrSetDefault('selectedSearchType', DEFAULT_SELECT_SEARCH_TYPE);
      if (selectedSearchType === DEFAULT_SELECT_SEARCH_TYPE) {
        this.loadReportsByDateRange();
      } else {
        this.loadReportsBySupplierReferenceId();
      }
    },
    triggerTransitionTo: function triggerTransitionTo(updatedParams) {
      var queryParams = Ember.$.extend(this.get('model'), updatedParams);
      this.get('route').transitionTo({ queryParams: queryParams });
    },
    runQuery: function runQuery(params) {
      var _this2 = this;

      this.set('reports', []);
      this.set('reportsLoaded', false);

      var handleSuccess = function handleSuccess(reports) {
        _this2.set('reports', reports);
        _this2.set('reportsLoaded', true);
      };

      var handleError = function handleError() {
        var route = _this2.get('route');
        route.intermediateTransitionTo('error', new Ember.Error('Booking reports could not load'));
      };

      this.store.query('report', params).then(handleSuccess, handleError);
    },
    loadReportsByDateRange: function loadReportsByDateRange() {
      if (this.get('dateRangeIsValid')) {
        var model = this.get('model');
        var dateFormat = this.get('dateFormat.URL_DATE_FORMAT');
        var isThirdParty = this.get('isThirdParty');
        var params = void 0;
        if (isThirdParty) {
          params = {
            supplierBranchID: this.get('supplierBranchValues')[0],
            startDate: this.get('dateRangeFrom').format(dateFormat),
            endDate: this.get('dateRangeTo').format(dateFormat),
            dateType: API_DATE_FIELD_BY_DATE_TYPE_MAP.get(this.get('searchDateType'))
          };
        } else {
          params = {
            supplierID: model.supplierId,
            startDate: this.get('dateRangeFrom').format(dateFormat),
            endDate: this.get('dateRangeTo').format(dateFormat),
            dateType: API_DATE_FIELD_BY_DATE_TYPE_MAP.get(this.get('searchDateType'))
          };
        }

        this.runQuery(params);
      }
    },
    loadReportsBySupplierReferenceId: function loadReportsBySupplierReferenceId() {
      if (this.get('supplierReferenceNumber')) {
        var model = this.get('model');
        var params = {
          supplierID: model.supplierId,
          supplierReferenceNumber: this.get('supplierReferenceNumber')
        };
        this.runQuery(params);
      }
    },


    isRangeLengthValid: Ember.computed('from', 'to', function () {
      'use strict';

      var maxDateRange = this.get('maxDateRange');
      var from = this.get('dateRangeFrom');
      var to = this.get('dateRangeTo');
      return (0, _dateValidators.isInclusiveRangeLengthIsAtMost)(maxDateRange, from, to);
    }),

    displayedDateRange: Ember.computed('from', 'to', function () {
      var dateFormatUSA = this.get('dateFormat.USA_DATE_FORMAT');

      return {
        fromFormated: this.get('dateRangeFrom').format(dateFormatUSA),
        toFormated: this.get('dateRangeTo').format(dateFormatUSA)
      };
    }),

    filteredReports: Ember.computed('reports', 'filters', function () {
      var reports = this.get('reports');
      var filters = this.get('filters');
      this.get('uitk').reinitResponsiveTable('#booking-report-table');

      if (reports && reports.toArray) {
        return reports.toArray().filter(function (item) {
          var passed = filters.every(function (filter) {
            return filter(item);
          });
          return passed;
        });
      } else {
        console.warn('report controller: filteredReports: There are no reports to filter!');
        return [];
      }
    }),

    isJustOneResult: Ember.computed('filteredReports', function () {
      var filteredReports = this.get('filteredReports');
      return filteredReports && filteredReports.length === 1;
    }),

    headerTitle: Ember.computed('searchDateType', 'status', 'filteredReports', function () {
      var _this3 = this;

      var dateType = this.getOrSetDefault('searchDateType', DEFAULT_SEARCH_DATETYPE);
      var status = this.get('status');
      var getTitle = function getTitle(title) {
        var postfix = _this3.get('isJustOneResult') ? 'single' + title : 'multiple' + title + 's';
        var lastWord = _this3.get('branchLevelEnabled') ? 'For' : '';
        return 'pages.report.labels.' + postfix + lastWord;
      };

      switch (status) {
        case 'Purchase':
          if (dateType === 'status') {
            return getTitle('Purchase');
          }
          return getTitle('Arrival');
        case 'Cancellation':
          return getTitle('Cancellation');
        default:
          return getTitle('Result');
      }
    }),

    resetSearchDateType: Ember.observer('isDateRangePickerCollapsed', function () {
      var _this4 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var element = _this4.testElement ? _this4.testElement : undefined;
        _this4.setSearchDateType(element);
      });
    }),

    actions: {
      clear: function clear() {
        this._super.apply(this, arguments);
      },
      searchOnDatetype: function searchOnDatetype(event) {
        var searchDateType = event.target.value.trim();
        this.triggerTransitionTo({ searchDateType: searchDateType });
      },
      search: function search() {
        if (this.get('dateRangeIsValid')) {
          this.loadReportsByDateRange();
          if (!this.get('pinned')) {
            this.send('triggerDateRangePicker', true);
          }
        }
      },
      triggerThumbTack: function triggerThumbTack(element) {
        var elem = element ? element : Ember.$('#thumbtack-trigger');
        var pinned = 'pinned';

        elem.toggleClass(pinned);
        this.set('pinned', elem.hasClass(pinned));
      },
      exportReport: function exportReport(type) {
        var JsonToXmlSpreadsheet = this.get('JsonToXmlSpreadsheet');
        var reports = this.get('sortedReports');
        var reportModelMap = Ember.get(_report.default, 'attributes');
        if (!this.get('featureFlags').checkEnabled('BookingQuestions') || !this.get('featureFlags').checkEnabled('BQTravelerInformation')) {
          reportModelMap.delete('BQ_TravelerInfo');
        }

        reportModelMap.delete('BQ_PickupInfoObj');
        reportModelMap.delete('BQ_DropOffInfoObj');
        reportModelMap.delete('BQ_TravelerInfoObj');
        if (type === 'xlsx') {
          var arrayOfReports = (0, _reportsToArray.default)(reports, reportModelMap);
          this.get('jsXlsx').createXlsxFromArrayOfArrays(arrayOfReports, 'Report', 'report.xlsx');
        } else {
          var spreadsheet = JsonToXmlSpreadsheet.convert(reports, reportModelMap, true);
          var xmlBlob = new Blob([spreadsheet], { type: 'application/xml' });
          this.get('fileSaver').saveAs(xmlBlob, 'report.xml');
        }
      },
      details: function details(id) {
        this.set('reportDetails', this.store.peekRecord('report', id));
        this.get('uitk').modal.create({
          modalId: 'details',
          content: '#detailsModal'
        });
      },
      triggerDateRangePicker: function triggerDateRangePicker() {
        var collapsed = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        this.set('isDateRangePickerCollapsed', collapsed);
        this.notifyPropertyChange('isDateRangePickerCollapsed');
      },
      selectSearch: function selectSearch(selectedValue) {
        this.set('selectSearchTypes.selected', selectedValue);
        this.set('model.selectedSearchType', selectedValue);
        this.showSelectedSearchType();
        this.triggerTransitionTo({ selectedSearchType: selectedValue });
      },
      setSupplierReferenceNumber: function setSupplierReferenceNumber(e) {
        this.set('supplierReferenceNumber', e.target.value);
      },
      searchSupplierReferenceNumber: function searchSupplierReferenceNumber() {
        if (this.get('supplierReferenceNumber')) {
          this.loadReportsBySupplierReferenceId();
        }
      }
    }
  });
});