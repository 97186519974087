define('lxso/models/ticket-types-v4', ['exports', 'ember-data-model-fragments/attributes', 'lxso/utils/validation', 'ember-data'], function (exports, _attributes, _validation, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Validations = (0, _validation.build)({
    ticketTypesOverlap: {
      validators: [(0, _validation.validator)('ticket-types-v4-overlap', {
        volatile: true
      })]
    },
    capacityTicketTypesOverlap: {
      validators: [(0, _validation.validator)('capacity-ticket-types-v4-overlap', {
        volatile: true
      })]
    }
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    ticketTypes: (0, _attributes.fragmentArray)('fragments/ticket-types-v4/ticket-type'),
    capacityTicketTypes: (0, _attributes.fragmentArray)('fragments/ticket-types-v4/capacity-ticket-type'),
    activityId: _emberData.default.attr('number')
  });
});