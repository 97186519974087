define('lxso/helpers/is-promo-action-enabled', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isPromoActionEnabled = isPromoActionEnabled;
  function isPromoActionEnabled(params) {
    var action = params[1];
    var showActionForStatuses = [];
    if (action === 'edit') {
      showActionForStatuses = ['Active'];
      return showActionForStatuses.indexOf(params[0]) >= 0;
    } else if (action === 'deactivate') {
      showActionForStatuses = ['Active', 'Pending'];
      return showActionForStatuses.indexOf(params[0]) >= 0;
    }
  }

  exports.default = Ember.Helper.helper(isPromoActionEnabled);
});