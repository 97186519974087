define('lxso/validators/diff-base-reference', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /*
    The difference between reference price and the base price
    must be less than 1000.
  
    This is to prevent scenarios where a user might input $80 as
    the tour price and $10,000 as its strikethrough price.
  
    This validation should only run if the feature flag
    "V3StrikeThroughPricing" is turned on and the user
    has "V3StrikeThroughPricing" permission.
  */

  var diffBaseReference = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      'use strict';

      //set on edit.js when the model is created

      var flagStatus = model.store.get('hasV3StrikeThroughPricingFlag') && model.store.get('hasV3StrikeThroughPricingPermission');

      var newPrices = value;
      var errorIndexes = [];
      var errorString = void 0;

      if (flagStatus) {
        //Only allow difference of $1000 or less between marketing price and retail price
        for (var i = 0; i < newPrices.length; i++) {
          if (newPrices[i].reference - newPrices[i].base > 1000) {
            var result = this.createErrorMessage('baseReferenceDiff', value, options);
            errorIndexes.push(i);
            errorString = result;
          }
        }
      }
      //flag or no flag, this validator has to return something
      return errorString ? errorIndexes + ':;:' + errorString : true;
    }
  });

  exports.default = diffBaseReference;
});