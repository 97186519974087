define('lxso/models/health-and-safety', ['exports', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'ember-api-actions', 'lxso/utils/validation'], function (exports, _emberDataModelFragments, _attributes, _emberApiActions, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    healthAndSafetySelected: [(0, _validation.validator)('presence', {
      presence: true,
      descriptionKey: 'pages.healthAndSafety.instruction'
    })],
    enhancedCleaningQuestions: [(0, _validation.validator)('custom-health-and-safety-validation', {
      messageKey: 'pages.healthAndSafety.labels.errors.enhancedCleaningError',
      volatile: true
    })],
    travelerSafetyQuestions: [(0, _validation.validator)('custom-health-and-safety-validation', {
      messageKey: 'pages.healthAndSafety.labels.errors.travelerSafetyError',
      volatile: true
    })],
    covidStatusQuestions: [(0, _validation.validator)('custom-health-and-safety-validation', {
      messageKey: 'pages.healthAndSafety.labels.errors.covidStatusQuestions',
      volatile: true
    })]
  });

  exports.default = DS.Model.extend(Validations, {
    activityID: DS.get('number'),
    isNewHealthAndSafetyInfo: DS.attr('boolean'),
    healthAndSafetySelected: DS.attr('string'),
    enhancedCleaningQuestions: (0, _attributes.fragmentArray)('fragments/health-and-safety/health-and-safety-questions'),
    travelerSafetyQuestions: (0, _attributes.fragmentArray)('fragments/health-and-safety/health-and-safety-questions'),
    covidStatusQuestions: (0, _attributes.fragmentArray)('fragments/health-and-safety/health-and-safety-questions'),
    i18n: Ember.inject.service()
  });
});