define('lxso/routes/branch-selection', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/config/environment', 'lxso/mixins/enum-getter'], function (exports, _authenticatedRouteMixin, _environment, _enumGetter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var landingPages = [{
    requiredPermission: 'V3Product',
    pageName: 'products',
    params: function params(selectedBranch) {
      return selectedBranch.supplier_branch_id;
    }
  }, {
    requiredPermission: 'ReportsTabView',
    pageName: 'report',
    params: function params(selectedBranch) {
      return selectedBranch.supplier_id;
    }
  }];

  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _enumGetter.default, {
    supplierBranch: Ember.inject.service('supplier-branch'),
    featureFlags: Ember.inject.service(),
    session: Ember.inject.service('session'),
    permissions: Ember.inject.service('permissions'),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    i18n: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.SupplierBranchSelection'
    },

    beforeModel: function beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        if (window.location.search.split('code=')[1]) {
          var token = window.location.search.split('code=')[1];
          if (history.pushState) {
            var url = window.location.origin + window.location.pathname + window.location.hash;
            window.history.pushState({ path: url }, '', url);
          }
          this.transitionTo('oidc-auth', { queryParams: { code: token } });
        } else {
          this._super.apply(this, arguments);
        }
      } else {
        this._super.apply(this, arguments);
      }
    },
    model: function model() {
      var _this = this;

      return this.get('supplierBranch').getId().then(function () {
        var supplierBranchIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        return _this.loadSupplierBranches(supplierBranchIds);
      });
    },
    afterModel: function afterModel() {
      this.send('updateMenuData');
    },
    loadSupplierBranch: function loadSupplierBranch(supplierBranchId) {
      return this.loadSupplierBranches([supplierBranchId]);
    },
    loadSupplierBranches: function loadSupplierBranches(supplierBranchIds) {
      var _this2 = this;

      return this.get('supplierBranch').loadActiveSupplierBranches(supplierBranchIds).then(function (activeSupplierBranches) {
        return _this2.selectUniqueOrReturn(activeSupplierBranches);
      }).catch(function (errors) {
        if (errors.responseJSON && errors.responseJSON.title) {
          _this2.controller.set('errors', [errors.responseJSON.title]);
          Ember.Logger.error(errors.responseJSON.title);
        }
      });
    },
    loadSupplierBranchById: function loadSupplierBranchById(supplierBranchId) {
      var _this3 = this;

      return this.get('supplierBranch').loadActiveSupplierBranches([supplierBranchId]).then(function (activeSupplierBranches) {
        if (activeSupplierBranches.length === 1) {
          _this3.select(activeSupplierBranches[0]);
        } else if (activeSupplierBranches.length === 0) {
          _this3.controller.set('isSearching', false);
          _this3.controller.set('errors', _this3.get('i18n').t('pages.selectBranch.expressSearch.inactiveSupplierBranch'));
        }
      }).catch(function (errors) {
        _this3.controller.set('isSearching', false);
        if (errors.responseJSON && errors.responseJSON.title) {
          _this3.controller.set('errors', [errors.responseJSON.title]);
          Ember.Logger.error(errors.responseJSON.title);
        } else {
          _this3.controller.set('errors', [_this3.get('i18n').t('pages.selectBranch.expressSearch.serverBadRequest')]);
        }
      });
    },
    selectUniqueOrReturn: function selectUniqueOrReturn(supplierBranches) {
      if (supplierBranches.length === 1) {
        this.select(supplierBranches[0]);
      } else {
        return supplierBranches;
      }
    },
    select: function select(supplierBranch) {
      var _this4 = this;

      this.get('supplierBranch').updateSelectedBranch(supplierBranch);

      this.findLandingPage().then(function (landingPage) {
        if (landingPage) {
          _this4.transitionTo(landingPage.pageName, landingPage.params(supplierBranch));
        } else {
          _this4.intermediateTransitionTo('error', new Ember.Error('No permitted page is available'));
        }
      });
    },
    findLandingPage: function findLandingPage() {
      var _this5 = this;

      var findFirstPermittedPage = function findFirstPermittedPage(nextPage) {
        return function (currentPage) {
          return currentPage || (_this5.get('permissions').checkEnabled(nextPage.requiredPermission) ? nextPage : undefined);
        };
      };

      return landingPages.reduce(function (promise, nextLandingPage) {
        return promise.then(findFirstPermittedPage(nextLandingPage));
      }, Ember.RSVP.resolve());
    },
    setupController: function setupController(controller, supplierBranches) {
      this._super.apply(this, arguments);
      var route = this;
      controller.set('filteredBranches', Ember.computed('branchFilter', function () {
        var branchFilter = this.getWithDefault('branchFilter', '').toUpperCase();
        return supplierBranches.filter(function (branch) {
          return branch.supplier_branch_name.toUpperCase().includes(branchFilter);
        });
      }));
      var domain = _environment.default.api.host + '/' + _environment.default.api.namespace;
      var state = 'active,suspendedexpedia,suspendedpaused';
      var url = domain + '/supplier_branches/search_typeahead?state=' + state + '&name=';
      var url3p = domain + '/supplier_branches/search_typeahead?is3p=true&state=' + state + '&name=';

      controller.set('supplierBranchSource', this.getEnumFunction(url));
      controller.set('supplierBranchSource3p', this.getEnumFunction(url3p));

      var zoneUrl = domain + '/location_lists/search/zones?nameDetails=';
      controller.set('zoneNamesSource', this.getEnumFunction(zoneUrl));
      controller.set('session', this.get('session'));
      controller.set('searchBy', 'branchName');
      controller.set('isSearching', false);
      this.clearInputAndErrors();
      controller.set('isSearchById', Ember.computed('searchBy', function () {
        var searchBy = controller.get('searchBy');
        return searchBy === 'branchId' || searchBy === 'activityId' || searchBy === 'optionId' || searchBy === 'zoneId';
      }));
      controller.set('expressSearchLabel', Ember.computed('searchBy', function () {
        var searchBy = controller.get('searchBy');
        var searchByPart1 = route.get('i18n').t('pages.selectBranch.expressSearch.searchByLabel');
        var searchByPart2 = route.get('i18n').t('pages.selectBranch.expressSearch.searchLabel.' + searchBy);
        var label = '' + searchByPart1 + searchByPart2;
        return label;
      }));
    },
    loadProductPage: function loadProductPage(activityId) {
      var _this6 = this;

      this.updateSupplierBranchUsingActivityId(activityId).then(function () {
        _this6.transitionTo('activity.overview', activityId);
      }).catch(function (err) {
        var errors = [];
        _this6.controller.set('isSearching', false);
        if (err.responseJSON && err.responseJSON.title) {
          errors.push(err.responseJSON.title);
        } else if (err === 'inactive') {
          errors.push(_this6.get('i18n').t('pages.selectBranch.expressSearch.inactiveBranchProduct'));
        } else {
          errors.push(_this6.get('i18n').t('pages.selectBranch.expressSearch.serverBadRequest'));
        }
        _this6.controller.set('errors', errors);
        Ember.Logger.error(errors);
      });
    },
    updateSupplierBranchUsingSupplierBranchId: function updateSupplierBranchUsingSupplierBranchId(supplierBranchId) {
      var _this7 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this7.get('supplierBranch').loadActiveSupplierBranches([supplierBranchId]).then(function (activeSupplierBranches) {
          if (activeSupplierBranches.length === 1) {
            return _this7.get('supplierBranch').updateSelectedBranchInSession(activeSupplierBranches[0]);
          } else {
            reject('inactive');
          }
        }).then(function (supplierBranch) {
          resolve(supplierBranch);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    updateSupplierBranchUsingActivityId: function updateSupplierBranchUsingActivityId(activityId) {
      var _this8 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this8.store.findRecord('activity', activityId).then(function (activity) {
          var supplierBranchId = activity.get('supplier_branch.supplier_branch_id');
          return _this8.updateSupplierBranchUsingSupplierBranchId(supplierBranchId);
        }).then(function (response) {
          return resolve(response);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    loadOptionOrOptionGroupPage: function loadOptionOrOptionGroupPage(optionId) {
      var _this9 = this;

      var route = this;
      var offer = void 0;
      var activity = void 0;
      this.store.findRecord('offer', optionId).then(function (offerResponse) {
        offer = offerResponse;
        return route.store.findRecord('activity', offerResponse.get('activityId'));
      }).then(function (activityResponse) {
        activity = activityResponse;
        var supplierBranchId = activity.get('supplier_branch.supplier_branch_id');
        return _this9.updateSupplierBranchUsingSupplierBranchId(supplierBranchId);
      }).then(function (supplierBranchResponse) {
        var activityId = activity.get('id');
        var offerGroupId = offer.get('offerGroupId');
        var offerStatus = offer.get('status');
        if (activity && activity.get('migrationStatus.offer')) {
          if (offerStatus === 'active' && offerGroupId) {
            _this9.transitionTo('activity.option-group.group', activityId, offerGroupId, { queryParams: { direct: true } });
          } else {
            _this9.transitionTo('activity.option-group', activityId, { queryParams: { direct: true } });
          }
        } else {
          _this9.transitionTo('activity.offer', activityId, optionId, { queryParams: { direct: true } });
        }
      }).catch(function (err) {
        var errors = [];
        _this9.controller.set('isSearching', false);
        if (err.responseJSON && err.responseJSON.title) {
          errors.push(err.responseJSON.title);
        } else if (err === 'inactive') {
          errors.push(_this9.get('i18n').t('pages.selectBranch.expressSearch.inactiveBranchOption'));
        } else {
          errors.push(_this9.get('i18n').t('pages.selectBranch.expressSearch.serverBadRequest'));
        }
        _this9.controller.set('errors', errors);
        Ember.Logger.error(errors);
      });
    },
    loadZonePage: function loadZonePage(zoneId) {
      var _this10 = this;

      if (zoneId) {
        this.store.findRecord('hotel-zone', zoneId).then(function () {
          _this10.transitionTo('hotel-zone', zoneId);
        }).catch(function (errors) {
          _this10.controller.set('isSearching', false);
          _this10.controller.set('errors', [_this10.get('i18n').t('pages.selectBranch.expressSearch.unavailableZone')]);
        });
      }
    },
    clearInputAndErrors: function clearInputAndErrors() {
      this.controller.set('idToSearch', '');
      this.controller.set('branchFilter', '');
      this.controller.set('errors', '');
    },
    validateSearchId: function validateSearchId(searchId) {
      var searchIdNum = Number(searchId);
      if (searchIdNum > 0 && Number.isInteger(searchIdNum)) {
        return true;
      } else {
        return this.get('i18n').t('pages.selectBranch.expressSearch.invalidIdMessage');
      }
    },


    actions: {
      branchSelectedInTypeahead: function branchSelectedInTypeahead(item) {
        this.loadSupplierBranch(item.value);
      },
      branchSelected: function branchSelected(supplierBranch) {
        this.select(supplierBranch);
      },
      zoneSelectedInTypeahead: function zoneSelectedInTypeahead(item) {
        this.loadZonePage(item.id);
      },
      willTransition: function willTransition() {
        //for tests
        delete window.pageName;
      },
      expressSearch: function expressSearch(searchBy) {
        this.clearInputAndErrors();
        this.controller.set('searchBy', searchBy);
      },
      onSearchById: function onSearchById(searchBy, idToSearch) {
        this.controller.set('isSearching', true);
        this.controller.set('errors', '');
        var isValid = this.validateSearchId(idToSearch);
        if (isValid === true) {
          switch (searchBy) {
            case 'branchId':
              this.loadSupplierBranchById(idToSearch);
              break;
            case 'activityId':
              this.loadProductPage(idToSearch);
              break;
            case 'optionId':
              this.loadOptionOrOptionGroupPage(idToSearch);
              break;
            case 'zoneId':
              this.loadZonePage(idToSearch);
              break;
          }
        } else {
          this.controller.set('errors', [isValid]);
          this.controller.set('isSearching', false);
        }
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'selectSupplierBranch';
      }
    }
  });
});