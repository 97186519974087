define('lxso/routes/activity/photos/staging', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    resetScroll: false,

    mediavault: Ember.inject.service('mediavault'),
    uploader: Ember.inject.service('uploader'),
    featureFlags: Ember.inject.service(),

    session: Ember.inject.service('session'),

    omniture: {
      pageName: 'page.LX-Supply.CheckPhotos'
    },

    reloadCount: 0,

    setupController: function setupController() {
      var _this = this;

      // Try to set the controller from the activity.photos.index
      // If this fails, the didTransition function will redirect to the photos index
      try {
        this.set('photosController', this.controllerFor('activity.photos'));
      } catch (e) {}

      this.controller.setProperties({
        'activity': this.modelFor('activity'),
        'uploader': this.get('uploader'),
        'rejectedFiles': []
      });

      this.controller.set('tooManyImages', Ember.computed('uploader.files.length', function () {
        var numberOfImages = _this.get('photosController.approvedAssets.length') + _this.get('uploader.files.length');
        return numberOfImages > _environment.default.mvapi.maxImages;
      }));

      this.get('uploader.files').forEach(function (file) {
        var errors = file.get('errors');
        if (errors) {
          _this.controller.get('rejectedFiles').pushObject({
            name: file.get('file.name'),
            errors: errors
          });
          file.destroy();
        }
      });
    },
    createAsset: function createAsset(tags, activityHasHeroImage) {
      'use strict';

      var assetTags = tags.slice();

      // If the activity does not have a hero image, add the heroImageTag
      if (!activityHasHeroImage) {
        assetTags.push(_environment.default.mvapi.heroImageTag);
      }

      var assetSupplierName = this.get('session.session.authenticated.selectedBranch.supplier_name');
      // Create an asset
      return this.store.createRecord('asset', {
        tags: assetTags,
        supplierName: assetSupplierName
      });
    },
    reloadAssets: function reloadAssets(assets) {
      var _this2 = this;

      return assets.reload().then(function (assets) {
        var allDisplayImagesLoaded = assets.every(function (asset) {
          return asset.get('displayImage');
        });
        if (!allDisplayImagesLoaded) {
          _this2.incrementProperty('reloadCount');
          if (_environment.default.activities.photos.reload.limit > _this2.get('reloadCount')) {
            return Ember.run.debounce(_this2, _this2.get('reloadAssets'), assets, _environment.default.activities.photos.reload.delayInMs);
          }
        }
        _this2.set('reloadCount', 0);
      });
    },
    uploadFile: function uploadFile(asset, file, productId) {
      'use strict';

      file.set('asset', asset);
      return asset.uploadFile(file, productId);
    },
    uploadFailed: function uploadFailed(asset, file) {
      'use strict';

      this.get('notifications').error(this.get('i18n').t('pages.photos.staging.errors.upload'));
      asset.destroyRecord();
      file.destroy();
      this.controller.set('uploading', false);
    },
    syncUploadPhotos: function syncUploadPhotos(iterator, tags) {
      var _this3 = this;

      var activityHasHeroImage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      var item = iterator.next();
      var productId = null;
      var self = this;

      if (tags != undefined && !Ember.isEmpty(tags)) {
        var assetTags = tags.slice();
        productId = assetTags[0].slice(2);
      }
      if (!item.done) {
        var file = item.value;
        var asset = this.createAsset(tags, activityHasHeroImage);
        var res = this.uploadFile(asset, file, productId);
        res.then(function (success) {}).catch(function (e) {
          self.get('notifications').error(_this3.get('i18n').t('pages.photos.staging.errors.upload'));
          self.controller.set('showLoader', false);
          self.controller.set('uploading', false);
          self.send('cancel');
        });
        this.syncUploadPhotos(iterator, tags);
      }
    },


    loadFinished: Ember.observer('uploader.progress', function () {
      'use strict';

      if (this.get('uploader.progress') == 100) {
        var activity = this.controller.get('activity');
        // Reload the assets for this activity
        this.reloadAssets(activity.get('assets'));
        // Transition back to the photos page
        this.replaceWith('activity.photos', activity.get('id'));
        this.controller.set('uploading', false);
      }
    }),

    actions: {
      didTransition: function didTransition() {
        if (!this.get('photosController.stagedFiles')) {
          this.send('cancel');
        }
      },
      unstageImage: function unstageImage(file) {
        file.destroy();
      },
      cancel: function cancel() {
        this.get('uploader.files').forEach(function (file) {
          file.destroy();
        });

        this.replaceWith('activity.photos', this.controller.get('activity.id'));
      },
      uploadPhotos: function uploadPhotos() {
        var activity = this.controller.get('activity');
        var activityId = activity.get('id');
        var activityTags = ['' + _environment.default.mvapi.activityIdTagPrefix + activityId];
        var tags = Ember.$.merge(activityTags, _environment.default.mvapi.imageTags);
        // Determine if the activity currently has a hero image
        var activityHasHeroImage = activity.get('assets').any(function (asset) {
          return asset.get('tags').includes('lxheromedia');
        });
        var files = this.get('uploader.files').values();

        this.controller.set('uploading', true);
        this.syncUploadPhotos(files, tags, activityHasHeroImage);
      }
    }
  });
});