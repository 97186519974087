define('lxso/serializers/terms-condition', ['exports', 'ember-data', 'lxso/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({

    primaryKey: 'activity_id',

    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      payload.links = {
        activity: _environment.default.api.host + '/' + _environment.default.api.namespace + '/activities/' + payload.activity_id + '/terms_and_condition'
      };
      return this._super.apply(this, arguments);
    }
  });
});