define('lxso/models/supplier-branch/offer-margin', ['exports', 'ember-data', 'lxso/utils/validation'], function (exports, _emberData, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    margin: [(0, _validation.validator)('number', {
      allowBlank: true,
      allowString: true,
      gte: 0,
      messageKey: 'errors.customMessages.supplierBranch.marginTooSmall'
    }), (0, _validation.validator)('number', {
      allowBlank: true,
      allowString: true,
      lte: 100,
      messageKey: 'errors.customMessages.supplierBranch.marginTooBig'
    }), (0, _validation.validator)('format', {
      allowBlank: true,
      regex: /^\d+(\.\d{1,2})?$/,
      messageKey: 'errors.customMessages.supplierBranch.marginWrongFormat'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    margin_overrides: _emberData.default.belongsTo('supplier-branch/margin_overrides'),
    offer_id: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    margin: _emberData.default.attr('string'),
    isSelected: _emberData.default.attr('boolean', { defaultValue: false })
  });
});