define('lxso/models/fragments/price-group-v4/availability-by-offer', ['exports', 'ember-data', 'ember-data-model-fragments', 'lxso/utils/validation', 'lxso/constants/option-group'], function (exports, _emberData, _emberDataModelFragments, _validation, _optionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    startTime: {
      descriptionKey: 'pages.optionGroup.optionGroupSection.priceV4.availability.openingTime',
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        disabled: Ember.computed.not('model.isSelected')
      }), (0, _validation.validator)('time-format', {
        showSuggestions: true,
        disabled: Ember.computed.not('model.startTime')
      })]
    },
    endTime: {
      descriptionKey: 'pages.optionGroup.optionGroupSection.priceV4.availability.closingTime',
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        disabled: Ember.computed.not('model.isSelected')
      }), (0, _validation.validator)('time-format', {
        showSuggestions: true,
        disabled: Ember.computed.not('model.endTime')
      })]
    },
    timeRange: (0, _validation.validator)('time-range', {
      start: Ember.computed.readOnly('model.startTime'),
      end: Ember.computed.readOnly('model.endTime'),
      disabled: Ember.computed.not('model.isSelected'),
      descriptionKey: 'pages.optionGroup.errorMessages.openingBeforeClosing'
    }),
    capacity: [(0, _validation.validator)('number', {
      gte: 1,
      allowString: true,
      allowBlank: true,
      integer: true,
      descriptionKey: 'errors.descriptionKeys.capacity',
      disabled: Ember.computed.not('model.isSelected')
    }), (0, _validation.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isSelected')
    })],
    days: [(0, _validation.validator)('array-length', {
      gte: 1,
      descriptionKey: 'pages.optionGroup.optionGroupSection.priceV4.availability.days',
      disabled: Ember.computed.not('model.isSelected'),
      messageKey: 'pages.optionGroup.errorMessages.minOneDay'
    })]
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    i18n: Ember.inject.service(),
    isSelected: _emberData.default.attr('boolean'),
    available: _emberData.default.attr('boolean'),
    days: _emberData.default.attr('array'),
    offerId: _emberData.default.attr('number'),
    capacity: _emberData.default.attr('number'),
    startTime: _emberData.default.attr('string'),
    endTime: _emberData.default.attr('string')
  });
});