define('lxso/mixins/report-sort', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ORDER_ASCENDING = 'ASC';
  var ORDER_DESCENDING = 'DESC';
  var DEFAULT_SORT_BY = 'Transactiondatetime';
  var DEFAULT_ORDER = ORDER_DESCENDING;
  var DEFAULT_SORTING = DEFAULT_SORT_BY + '_' + DEFAULT_ORDER;
  var SORT_OPTION_BY_VALUE_MAP = new Map([['Travel Date', 'DestinationArrivalDate'], ['Status Date', 'Transactiondatetime'], ['Product Name', 'Activitytitle'], ['Option Name', 'Offertitle']]);
  var SORT_DROPDOWN = '#sortOptions';

  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortOptions', {
        selected: DEFAULT_SORTING,
        options: [],
        values: [],
        add: function add(key, option) {
          this.options.push(option);
          this.values.push(key);
        }
      });
      SORT_OPTION_BY_VALUE_MAP.forEach(function (value, key) {
        this.get('sortOptions').add(value + '_' + ORDER_ASCENDING, key + ' (ascending)');
        this.get('sortOptions').add(value + '_' + ORDER_DESCENDING, key + ' (descending)');
      }, this);
    },
    setSortBy: function setSortBy() {
      var sortBy = this.getOrSetDefault('sortBy', DEFAULT_SORT_BY);
      var order = this.getOrSetDefault('order', DEFAULT_ORDER);

      Ember.$(SORT_DROPDOWN).val(sortBy + '_' + order);
      this.triggerTransitionTo({ sortBy: sortBy, order: order });
    },


    sortedReports: Ember.computed('filteredReports', 'sortBy', 'order', function () {
      var sortBy = this.getOrSetDefault('sortBy', DEFAULT_SORT_BY);
      var order = this.getOrSetDefault('order', DEFAULT_ORDER);

      return this.get('filteredReports').sort(function (a, b) {
        var aValue = a.get(sortBy);
        var bValue = b.get(sortBy);
        var compared = aValue.localeCompare(bValue);
        return (order === ORDER_ASCENDING ? 1 : -1) * compared;
      });
    }),

    actions: {
      clear: function clear() {
        Ember.$(SORT_DROPDOWN).val(DEFAULT_SORTING);
        this.send('sort', DEFAULT_SORTING);
      },
      sort: function sort(sortValue) {
        var sort = sortValue.split('_');
        var sortBy = sort[0];
        var order = sort[1];
        this.triggerTransitionTo({ sortBy: sortBy, order: order });
      }
    }
  });
});