define('lxso/models/terms-condition', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    liability_waiver: _emberData.default.attr('boolean', { defaultValue: false }),
    age_restriction: _emberData.default.attr('number', { defaultValue: 0 }),
    optional_content: _emberData.default.attr('string', { defaultValue: '' }),
    update_activity_content: _emberData.default.attr('string', { defaultValue: '' }),

    isAgeRestricted: Ember.computed.gt('age_restriction', 0),
    isOptionalContent: Ember.computed('optional_content', function () {
      return this.get('optional_content').length > 0;
    })
  });
});