define('lxso/mixins/example-modal', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    i18n: Ember.inject.service(),

    omnitureValues: {
      photos: 'SeeTheGuideline',
      examplevoucher: 'ExampleVoucher',
      examplelisting: 'ExampleListing',
      offerlist: 'WhatOptionsLookLike',
      offerschedule: 'ScheduleTypeHelp',
      bookingcutoffstart: 'BookingCutoffHelp',
      cancellationcutoff: 'CustCancelPolicyHelp',
      mealtype: 'FoodBevInclusionsHelp',
      languagetype: 'CommentaryInclusionHelp',
      offertitle: 'OptionTitleHelp',
      offerdescription: 'OptionalDescriiptionHelp',
      offercapacity: 'CapacityTypeHelp',
      offervolumepricing: 'TicketPricingHelp',
      pricingsection: 'PriceTypeHelp',
      offertickettype: 'TicketTypeHelp',
      redemptioninstructions: 'RedemptionMore'
    },

    showMore: function showMore(modalName, modalTitle) {
      var _this = this;

      this.send('sendOmnitureDataOnShowMore', modalName);
      var templatePath = 'static-modals/modal-' + modalName;
      var titleText = modalTitle; //get titleString variable from modal-nnnn.jsp
      var translate = function translate(translationString) {
        var regex = /\{\{t\s'(.+)'\}\}/g;
        return translationString.replace(regex, function (match, $1) {
          return _this.get('i18n').t($1);
        });
      };
      Ember.$.ajax({
        url: templatePath + '.hbs',
        success: function success(template) {
          uitk.modal.create({
            modalId: 'modal-name-' + modalName,
            content: translate(template),
            animation: 'fade-in-none-out',
            title: titleText
          });
        }
      });
    },


    actions: {
      showExample: function showExample() {
        this.showMore.apply(this, arguments);
      },
      showMore: function showMore() {
        this.showMore.apply(this, arguments);
      },
      sendOmnitureDataOnShowMore: function sendOmnitureDataOnShowMore(modalName) {
        var omnitureValue = this.get('omnitureValues')[modalName.replace(/-/g, '')];
        if (omnitureValue) {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.' + omnitureValue);
        }
      }
    }
  });
});