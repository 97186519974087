define('lxso/utils/change-history-details-display-modes/cancellation-policy', ['exports', 'lxso/utils/change-history-details-display-modes/list'], function (exports, _list) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'list-with-label',
    diff: function diff(oldValue, newValue) {
      'use strict';

      return {
        oldValue: JSON.parse(oldValue).map(function (x) {
          return x.minimumHours;
        }),
        newValue: JSON.parse(newValue).map(function (x) {
          return x.minimumHours;
        }),
        displayMode: _list.default
      };
    }
  };
});