define('lxso/validators/option-group-prices', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OptionGroupPricesValidation = _base.default.extend({
    i18n: Ember.inject.service(),
    validate: function validate(prices, options, model, attribute) {
      var errorMessage = this.get('i18n').t('pages.optionGroup.errorMessages.minOnePrice').string;
      var selectedPrices = prices.filter(function (p) {
        return p.get('isSelected');
      });
      if (selectedPrices.length < 1) {
        return errorMessage;
      } else {
        return true;
      }
    }
  });

  exports.default = OptionGroupPricesValidation;
});