define('lxso/utils/validation', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.build = exports.validator = undefined;


  function build() {
    var validations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var globalOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return (0, _emberCpValidations.buildValidations)(validations, Ember.merge(globalOptions, { lazy: false }));
  }

  exports.validator = _emberCpValidations.validator;
  exports.build = build;
});