define('lxso/components/booking-questions/bq-question-details/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    uitk: Ember.inject.service(),

    actions: {
      onQuestionSelect: function onQuestionSelect(section, question, parent) {
        this.sendAction('toggleQuestionSelection', section, question, parent);
      },
      onSelectAll: function onSelectAll() {
        this.sendAction('toggleSelectAll');
      },
      openChildQuestions: function openChildQuestions(questionName) {
        var link = document.getElementsByClassName('toggle-trigger booking-question-' + questionName);
        $(link).toggleClass('open');

        var data = document.getElementsByClassName('children-of-' + questionName);
        $(data).toggleClass('close');
      }
    }
  });
});