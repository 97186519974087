define('lxso/validators/decimal-place-validation', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DecimalPlaceValidation = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var errorMessage = model.get('i18n').t('' + options.messageKey).string;

      if (parseFloat(value).toString().split('.')[1] && parseFloat(value).toString().split('.')[1].length > options.decimalLength) {
        return errorMessage;
      }

      return true;
    }
  });

  DecimalPlaceValidation.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });

  exports.default = DecimalPlaceValidation;
});