define('lxso/helpers/array-to-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arrayToString = arrayToString;
  function arrayToString(params) {
    return params[0] ? params[0].join(params[1]) : '';
  }

  exports.default = Ember.Helper.helper(arrayToString);
});