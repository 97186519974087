define('lxso/models/stop-sell', ['exports', 'ember-data', 'lxso/utils/validation'], function (exports, _emberData, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({
    quantity: {
      descriptionKey: 'pages.offer.labels.inventoryOverride',
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('number', {
        gte: 0,
        allowString: true,
        messageKey: 'errors.customMessages.inventoryOverride.gteZero'
      })]
    }
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    uid: _emberData.default.attr(),
    date: _emberData.default.attr('string'),
    activityId: _emberData.default.attr('number'),
    activityTitle: _emberData.default.attr('string'),
    offerId: _emberData.default.attr('number'),
    offerTitle: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    bookingCount: _emberData.default.attr('number'),
    isStopSellOnDate: _emberData.default.attr('boolean'),
    isLimitedInventory: _emberData.default.attr('boolean'),
    offerGroupId: _emberData.default.attr('number'),
    startTime: _emberData.default.attr('string')
  });
});