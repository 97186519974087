define('lxso/validators/valid-date', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ValidDate = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var errorMessage = model.get('i18n').t('errors.customMessages.invalidDate').string;
      var thisDate = new Date(value);
      return thisDate == 'Invalid Date' ? errorMessage : true;
    }
  });

  ValidDate.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = ValidDate;
});