define('lxso/routes/manage-availability', ['exports', 'lxso/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-supplier-branch', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lxso/mixins/example-modal', 'lxso/utils/omniture'], function (exports, _environment, _authenticatedRouteMixin, _requireSupplierBranch, _featureFlag, _requirePermission, _exampleModal, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var maxDateRangeLength = moment.duration(18, 'months');
  var MaxTimeInDay = '23:59:59';

  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, _requirePermission.default, _featureFlag.default, _exampleModal.default, {
    featureName: 'manageAvailability',
    requiredPermission: 'V3ManageAvailability',
    featureFlags: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    dateFormat: Ember.inject.service('date-format'),
    uitk: window.uitk,
    staticData: Ember.inject.service(),

    queryParams: {
      from: { refreshModel: true },
      to: { refreshModel: true },
      activity: { refreshModel: true },
      option: { refreshModel: true }
    },

    omniture: {
      pageName: 'page.LX-Supply.AvailabilityManager'
    },

    model: function model(params) {
      if (params.from) {
        params.from = this.getDatePickerFormat(params.from);
      }
      if (params.to) {
        params.to = this.getDatePickerFormat(params.to);
      }
      var supplierBranchRecord = this.store.findRecord('supplier-branch', params.supplierId);
      return new Ember.RSVP.hash({
        params: params,
        activities: supplierBranchRecord.then(function (supplierBranch) {
          return supplierBranch.activities();
        }),
        connectivityStatus: supplierBranchRecord.then(function (supplierBranch) {
          return supplierBranch.get('connectivity_status');
        }),
        platform: supplierBranchRecord.then(function (supplierBranch) {
          return supplierBranch.get('platform');
        }),
        stopSells: params.from ? this.store.query('stopSell', params) : []
      });
    },
    setupController: function setupController(controller, model) {
      var route = this;
      this.controller.set('params', model.params);
      this.controller.set('maxRange', 15);
      this.controller.set('selectedActivity', controller.activity);
      this.controller.set('selectedOption', controller.option);
      this.controller.set('noOffersAvailable', false);
      this.controller.set('supplierBranch', model.params.supplierId);
      this.setupDropdown(model.activities);
      this.controller.set('fromValue', Ember.computed('from', function () {
        return this.get('from') ? route.getDatePickerFormat(this.get('from')) : '';
      }));
      this.controller.set('toValue', Ember.computed('to', function () {
        return this.get('to') ? route.getDatePickerFormat(this.get('to')) : '';
      }));
      this.controller.set('activities', model.activities);
      this.controller.set('stopSells', model.stopSells);
      this.controller.set('selectedDays', this.setupStopSells(model.stopSells));
      this.controller.set('maxDateRange', moment(moment().add(18, 'M')._d).format('MM/DD/YYYY'));
      var selectedActivity = this.controller.get('selectedActivity');
      var stopSellForConnectedFlag = this.get('featureFlags').checkEnabled('StopSellForSuperAdmin');
      this.setupOptionsDropdown(selectedActivity);
      controller.set('externalSupplier', model.connectivityStatus);
      controller.set('stopSellForConnectedFlag', stopSellForConnectedFlag);
      this.controller.set('platform', model.platform);
      this.controller.set('isGacRequestSent', false);
      this.controller.set('isGacTriggered', false);
      this.controller.set('dateMoreThan18MonthsError', false);
      this.enableGacButton();
      this.setupGacButton();
    },
    setupGacButton: function setupGacButton() {
      // GT Platform Id, SuperShuttle:13 Hudson:16
      var connectivityType = this.controller.get('platform.connectivityType');
      var isPlatformConnectivityEnabled = this.controller.get('platform.isConnectivityEnabled');
      var isSupplierBranchConnectivityEnabled = this.controller.get('externalSupplier');
      var platformId = this.controller.get('platform.id');

      if ((connectivityType === 'GalaxyConnect' || connectivityType === 'APIConnectivity') && platformId != 13 && platformId != 16 && isPlatformConnectivityEnabled && isSupplierBranchConnectivityEnabled) {
        this.controller.set('isGacButtonVisible', true);
      } else {
        this.controller.set('isGacButtonVisible', false);
      }
    },
    setupDropdown: function setupDropdown(activities) {
      if (activities) {
        var sortActivityTitleByAlphabetical = function sortActivityTitleByAlphabetical(a, b) {
          if (a.long_title !== undefined && b.long_title !== undefined) {
            if (a.long_title.trim() < b.long_title.trim()) {
              return -1;
            }
            if (a.long_title.trim() > b.long_title.trim()) {
              return 1;
            }
          }
          return 0;
        };

        ;
        activities.sort(sortActivityTitleByAlphabetical);
      }

      var options = new Ember.A([]);
      var values = new Ember.A([]);
      if (activities) {
        activities.forEach(function (activity) {
          if ((activity.version === '3' || activity.version === '4') && (activity.status === 'active' || activity.status === 'suspended')) {
            var title = activity.long_title;
            var id = activity.activity_id;
            options.push(title);
            values.push(id);
          }
        });
      }

      this.controller.set('options', options);
      this.controller.set('values', values);
    },
    setupOptionsDropdown: function setupOptionsDropdown(selectedActivity) {
      var offerNames = new Ember.A([]);
      var offerIds = new Ember.A([]);
      if (selectedActivity) {
        var stopSells = this.controller.get('stopSells');
        stopSells = stopSells.filter(function (offer) {
          return offer.get('activityId') == selectedActivity;
        });
        var offers = stopSells.map(function (offer) {
          var title = offer.get('offerTitle');
          var id = offer.get('offerId');
          var startTime = offer.get('startTime') || MaxTimeInDay;
          var name = title.concat(' (', id, ')');
          offerNames.push(name);
          offerIds.push(id);
          return {
            name: name,
            id: id,
            startTime: startTime,
            offerGroupId: offer.get('offerGroupId')
          };
        }).uniqBy('id').sort(function (a, b) {
          var startTimeA = a.startTime || MaxTimeInDay;
          var startTimeB = b.startTime || MaxTimeInDay;
          var offerGroupIdSort = (a.offerGroupId || 0) - (b.offerGroupId || 0);
          var timeSort = moment(startTimeA, 'HH:mm:ss').unix() - moment(startTimeB, 'HH:mm:ss').unix();
          if (offerGroupIdSort !== 0) {
            return offerGroupIdSort;
          } else {
            return timeSort;
          }
        });
        offerNames = offers.map(function (offer) {
          return offer.name;
        });
        offerIds = offers.map(function (offer) {
          return offer.id;
        });
      }
      this.controller.set('offerOptions', offerNames);
      this.controller.set('offerValues', offerIds);
      if (Ember.isEmpty(offerNames)) {
        this.controller.set('noOffersAvailable', true);
        this.controller.set('selectedOption', null);
      }
    },
    setupStopSells: function setupStopSells(stopSells) {
      var selectedActivity = this.controller.get('selectedActivity');

      var ssDate = Ember.Object.extend({
        date: null,
        activities: null
      });
      var Activity = Ember.Object.extend({
        activityId: null,
        activityTitle: null,
        offers: null
      });

      var filteredStopSells = stopSells;
      var offer = this.controller.get('selectedOption');
      if (offer) {
        filteredStopSells = stopSells.filter(function (stopSell) {
          return stopSell.get('offerId') == offer;
        });
      }

      var stopSellsWithDate = filteredStopSells.reduce(function (dates, stopSell) {
        var date = dates.find(function (date) {
          return date.get('date').toString() === stopSell.get('date').toString();
        });
        if (!date) {
          var newActivity = Activity.create({
            activityId: stopSell.get('activityId'),
            activityTitle: stopSell.get('activityTitle'),
            offers: new Ember.A([stopSell])
          });

          var newDate = ssDate.create({
            date: stopSell.get('date'),
            activities: new Ember.A([newActivity])
          });
          dates.push(newDate);
        } else {
          var activities = date.get('activities');
          var activity = activities.find(function (activity) {
            return activity.get('activityId') === stopSell.get('activityId');
          });
          if (!activity) {
            var _newActivity = Activity.create({
              activityId: stopSell.get('activityId'),
              activityTitle: stopSell.get('activityTitle'),
              offers: new Ember.A([stopSell])
            });
            activities.push(_newActivity);
          } else {
            activity.offers.push(stopSell);
          }
        }
        return dates;
      }, new Ember.A([]));

      stopSellsWithDate.forEach(function (stopSellsForADay) {
        stopSellsForADay.get('activities').forEach(function (activity) {
          var offers = activity.get('offers');
          activity.set('offers', offers.sort(function (a, b) {
            var startTimeA = a.get('startTime') || MaxTimeInDay;
            var startTimeB = b.get('startTime') || MaxTimeInDay;
            var offerGroupIdSort = (a.get('offerGroupId') || 0) - (b.get('offerGroupId') || 0);
            var timeSort = moment(startTimeA, 'HH:mm:ss').unix() - moment(startTimeB, 'HH:mm:ss').unix();
            if (offerGroupIdSort !== 0) {
              return offerGroupIdSort;
            } else {
              return timeSort;
            }
          }));
        });
      });
      return stopSellsWithDate.sortBy('date');
    },
    getDatePickerFormat: function getDatePickerFormat(dateValue) {
      'use strict';

      var pickerFormat = this.get('dateFormat.DATEPICKER_FORMAT');
      var urlFormat = this.get('dateFormat.URL_DATE_FORMAT');
      return moment(dateValue, [pickerFormat, urlFormat]).format(pickerFormat);
    },
    getUrlFormat: function getUrlFormat(dateValue) {
      'use strict';

      var pickerFormat = this.get('dateFormat.DATEPICKER_FORMAT');
      var urlFormat = this.get('dateFormat.URL_DATE_FORMAT');
      return moment(dateValue, [pickerFormat, urlFormat]).format(urlFormat);
    },
    getISOFormat: function getISOFormat(dateValue) {
      'use strict';

      var pickerFormat = this.get('dateFormat.DATEPICKER_FORMAT');
      var urlFormat = this.get('dateFormat.URL_DATE_FORMAT');
      return moment.parseZone(dateValue, [pickerFormat, urlFormat]).toISOString();
    },
    errorSaving: function errorSaving() {
      this.get('notifications').error(this.get('i18n').t('components.alert.saveFailure'));
      this.controller.set('selectedDays', this.setupStopSells(this.controller.get('stopSells')));
    },
    gacButtonTriggered: function gacButtonTriggered() {
      this.controller.set('isGacTriggered', false);
      this.controller.set('disableGacButton', true);
      this.controller.set('isGacRequestSent', true);
    },
    setToDate: function setToDate(from, to) {
      var fromDate = moment(from);
      var toDate = moment(to);
      var currentDate = moment(this.getDatePickerFormat(new Date()));
      this.controller.set('currentDate', currentDate);
      var maxToDate = moment(currentDate).add(maxDateRangeLength);
      if (fromDate.isAfter(toDate)) {
        this.controller.set('to', this.getUrlFormat(fromDate));
      } else if (toDate.isAfter(maxToDate)) {
        this.controller.set('to', this.getUrlFormat(maxToDate));
      } else {
        this.controller.set('to', this.getUrlFormat(toDate));
      }
    },
    enableGacButton: function enableGacButton() {
      if (this.controller.get('params').option === undefined || this.controller.get('params').option === null || this.controller.get('params').option === '') {
        this.controller.set('disableGacButton', true);
      } else {
        this.controller.set('disableGacButton', false);
      }
    },


    actions: {
      toggleSelectedDays: function toggleSelectedDays(isTurnOn) {
        var _this = this;

        // This opens the on page interstitial, and disables the page while
        // we wait for the API
        var savingMessage = this.get('i18n').t('pages.manageAvailability.alerts.savingChanges');

        this.get('uitk').publish('results.changed', {
          contentId: 'body-app-container',
          scrollToTop: false,
          message: savingMessage.string,
          showLoader: false,
          disable: true });

        var selectedActivity = this.controller.get('selectedActivity');
        var selectedOption = this.controller.get('selectedOption');
        var fromDate = this.controller.get('from');
        var toDate = this.controller.get('to');
        var offers = this.controller.get('stopSells');
        if (selectedActivity) {
          offers = offers.filter(function (offer) {
            return offer.get('activityId') == selectedActivity;
          });
        }
        if (selectedOption) {
          offers = offers.filter(function (offer) {
            return offer.get('offerId') == selectedOption;
          });
        }
        var offersWithDuplicates = offers.map(function (offer) {
          return offer.get('offerId');
        });
        var offerIds = offersWithDuplicates.filter(function (offer, pos) {
          return offersWithDuplicates.indexOf(offer) == pos;
        });
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/supplier_branches/' + (this.controller.get('supplierBranch') + '/stop_sell');
        var ajaxReq = void 0;

        if (isTurnOn) {
          var data = {
            start_date: this.getISOFormat(fromDate),
            end_date: this.getISOFormat(toDate),
            offerIds: offerIds
          };
          ajaxReq = this.get('ajaxWrapper').ajaxPut(url, data);
        } else {
          var _data = {
            start_date: this.getUrlFormat(fromDate),
            end_date: this.getUrlFormat(toDate),
            offerIds: offerIds
          };
          ajaxReq = this.get('ajaxWrapper').ajaxPost(url, _data);
        }

        ajaxReq.then(function () {
          _this.store.query('stopSell', _this.controller.get('params')).then(function (stopSells) {
            var selectedDays = _this.setupStopSells(stopSells);
            _this.controller.set('selectedDays', selectedDays);
          });
        }).then(function () {
          // This closes the on page interstitial and enables the page
          _this.get('uitk').publish('results.complete', { contentId: 'body-app-container' });

          _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function () {
          return _this.errorSaving();
        });
      },
      updateFromDate: function updateFromDate(date) {
        var maxFromDate = moment().add(maxDateRangeLength);
        if (date && moment(date).isValid()) {
          if (maxFromDate.isSameOrAfter(date)) {
            this.controller.set('from', this.getUrlFormat(date));
            this.setToDate(this.getUrlFormat(date), this.controller.get('to'));
            this.controller.set('activity', this.controller.get('selectedActivity'));
            this.controller.set('option', this.controller.get('selectedOption'));
            this.controller.set('dateMoreThan18MonthsError', false);
            this.controller.set('invalidDateError', false);
          } else {
            this.controller.set('dateMoreThan18MonthsError', true);
            this.controller.set('selectedDays', false);
            this.controller.set('invalidDateError', false);
          }
        }
      },
      updateToDate: function updateToDate(date) {
        var maxDate = moment().add(maxDateRangeLength);
        var fromDate = moment(this.controller.get('from'));
        if (date && moment(date).isValid() && moment(date).isSameOrAfter(fromDate)) {
          if (maxDate.isSameOrAfter(date)) {
            this.setToDate(this.controller.get('from'), date);
            this.controller.set('activity', this.controller.get('selectedActivity'));
            this.controller.set('option', this.controller.get('selectedOption'));
            this.controller.set('dateMoreThan18MonthsError', false);
            this.controller.set('invalidDateError', false);
          } else {
            this.controller.set('dateMoreThan18MonthsError', true);
            this.controller.set('selectedDays', false);
            this.controller.set('invalidDateError', false);
          }
        } else {
          if (this.controller.get('from')) {
            this.setToDate(this.controller.get('from'), date);
          }
          this.controller.set('selectedDays', false);
          this.controller.set('invalidDateError', true);
          this.controller.set('dateMoreThan18MonthsError', false);
        }
      },
      activitySelect: function activitySelect(activity) {
        this.enableGacButton();
        if (this.controller.get('fromValue')) {
          this.replaceWith('manage-availability', { queryParams: { activity: activity, option: null } });
        } else {
          this.controller.set('selectedActivity', activity);
        }
      },
      optionSelect: function optionSelect(option) {
        this.enableGacButton();
        if (this.controller.get('fromValue')) {
          this.replaceWith('manage-availability', { queryParams: { option: option } });
        } else {
          this.controller.set('selectedOption', option);
        }
      },
      gacButtonClicked: function gacButtonClicked() {
        var _this2 = this;

        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AvailMgr.UpdateGAC');
        this.controller.set('isGacTriggered', true);
        var offerId = this.controller.get('selectedOption');
        var data = {
          'startDate': this.getUrlFormat(this.controller.get('fromValue')),
          'endDate': this.getUrlFormat(this.controller.get('toValue')),
          'userEmail': this.get('session.data.authenticated.display_name')
        };
        var url = _environment.default.api.host + '/api/v3/offer/' + offerId + '/triggerOfferAvailabilityUpdate';

        this.get('ajaxWrapper').ajaxPut(url, data).then(function () {
          return _this2.gacButtonTriggered();
        }).catch(function () {
          return _this2.gacButtonTriggered();
        });
      },
      toggleStopSell: function toggleStopSell(model, date, toggleValue) {
        var _this3 = this;

        // This opens the on page interstitial, and disables the page while
        // we wait for the API
        var savingMessage = this.get('i18n').t('pages.manageAvailability.alerts.savingChanges');

        this.get('uitk').publish('results.changed', {
          contentId: 'body-app-container',
          scrollToTop: false,
          message: savingMessage.string,
          showLoader: false,
          disable: true });

        var offersToUpdate = [];
        var offer = model.data;

        if (offer) {
          offersToUpdate.push(offer.offerId);
        } else if (model.get('offers')) {
          model.get('offers').forEach(function (offer) {
            return offersToUpdate.push(offer.get('offerId'));
          });
        } else if (model.get('activities')) {
          model.get('activities').forEach(function (activity) {
            return activity.get('offers').forEach(function (offer) {
              return offersToUpdate.push(offer.get('offerId'));
            });
          });
        }

        var singleDate = date ? date : model.get('date');
        var data = {
          'start_date': singleDate,
          'end_date': singleDate,
          'offerIds': offersToUpdate
        };
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/supplier_branches/' + (this.controller.get('supplierBranch') + '/stop_sell');
        // POST to create SS PUT to delete SS
        if (toggleValue) {
          this.get('ajaxWrapper').ajaxPut(url, data).then(function () {
            _this3.store.query('stopSell', _this3.controller.get('params')).then(function (stopSells) {
              var selectedDays = _this3.setupStopSells(stopSells);
              _this3.controller.set('selectedDays', selectedDays);
            });
          }).then(function () {
            // This closes the on page interstitial and enables the page
            _this3.get('uitk').publish('results.complete', { contentId: 'body-app-container' });

            _this3.get('notifications').success(_this3.get('i18n').t('components.alert.saveSuccess'));
          }).catch(function () {
            return _this3.errorSaving();
          });
        } else {
          this.get('ajaxWrapper').ajaxPost(url, data).then(function () {
            _this3.store.query('stopSell', _this3.controller.get('params')).then(function (stopSells) {
              var selectedDays = _this3.setupStopSells(stopSells);
              _this3.controller.set('selectedDays', selectedDays);
            });
          }).then(function () {
            // This closes the on page interstitial and enables the page
            _this3.get('uitk').publish('results.complete', { contentId: 'body-app-container' });

            _this3.get('notifications').success(_this3.get('i18n').t('components.alert.saveSuccess'));
          }).catch(function () {
            return _this3.errorSaving();
          });
        }
      }
    }
  });
});