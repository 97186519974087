define('lxso/models/postal-code', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    zoneId: _emberData.default.attr('number'),
    postalCode: _emberData.default.attr('string'),
    createDate: _emberData.default.attr('string'),
    updateDate: _emberData.default.attr('string'),
    lastUpdatedBy: _emberData.default.attr('string')
  });
});