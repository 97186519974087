define('lxso/serializers/supplier-branch/margin-overrides', ['exports', 'ember-data', 'lxso/mixins/underscored-json-attributes'], function (exports, _emberData, _underscoredJsonAttributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_underscoredJsonAttributes.default, _emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'activity_id',
    attrs: {
      ticket_type_margins: { embedded: 'always' },
      offer_margins: { embedded: 'always' },
      offer_group_margins: { embedded: 'always' }
    },

    normalizePayload: function normalizePayload(payload) {
      'use strict';

      if (payload.product_margin) {
        payload.product_margin = (payload.product_margin * 100).toFixed(2);
      }

      payload.ticket_type_margins.map(function (item) {
        if (item.margin) {
          item.margin = (item.margin * 100).toFixed(2);
        }
        return item;
      });

      payload.offer_margins.map(function (item) {
        if (item.margin) {
          item.margin = (item.margin * 100).toFixed(2);
        } else {
          item.margin = null;
        }
        return item;
      });

      if (payload.offer_group_margins != undefined) {
        payload.offer_group_margins.map(function (item) {
          if (item.margin) {
            item.margin = (item.margin * 100).toFixed(2);
          } else {
            item.margin = null;
          }
          return item;
        });
      }

      if (payload.activity_id) {
        payload.activityDetails = payload.activity_id;
      }
    },
    normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
      'use strict';

      this.normalizePayload(payload);
      return this._super.apply(this, arguments);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payloadArray, id, requestType) {
      'use strict';

      var _this = this;

      payloadArray.forEach(function (payload) {
        _this.normalizePayload(payload);
      });
      return this._super.apply(this, arguments);
    },
    serializeJson: function serializeJson(json) {
      'use strict';

      if (Ember.isEmpty(json.product_margin)) {
        delete json.product_margin;
      } else {
        json.product_margin = (json.product_margin / 100).toFixed(4) * 1;
      }

      json.ticket_type_margins.map(function (item) {
        if (Ember.isEmpty(item.margin)) {
          delete item.margin;
        } else {
          item.margin = (item.margin / 100).toFixed(4) * 1;
        }
        return item;
      });

      json.offer_margins.map(function (item) {
        if (Ember.isEmpty(item.margin)) {
          delete item.margin;
        } else {
          item.margin = (item.margin / 100).toFixed(4) * 1;
        }
        delete item.is_selected;

        return item;
      });

      if (json.offer_group_margins != undefined) {
        json.offer_group_margins.map(function (item) {
          if (Ember.isEmpty(item.margin)) {
            delete item.margin;
          } else {
            item.margin = (item.margin / 100).toFixed(4) * 1;
          }

          return item;
        });
      }

      return json;
    },
    serialize: function serialize(snapshot, options) {
      'use strict';

      var json = this._super.apply(this, arguments);
      return this.serializeJson(json);
    }
  });
});