define('lxso/helpers/appendPercentSign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.appendPercentSign = appendPercentSign;
  function appendPercentSign(args /*, hash*/) {
    return args[0] + '%';
  }

  exports.default = Ember.Helper.helper(appendPercentSign);
});