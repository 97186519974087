define('lxso/components/time-unit-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var durations = new Map([['minutes', 1], ['hours', 60], ['days', 1440]]);

  exports.default = Ember.Component.extend({
    init: function init() {
      'use strict';

      this._super.apply(this, arguments);
      var timeUnitOptions = [];
      var timeUnitValues = [];
      durations.forEach(function (value, key) {
        'use strict';

        timeUnitOptions.push(key);
        timeUnitValues.push(value);
      });
      this.set('timeUnitOptions', timeUnitOptions);
      this.set('timeUnitValues', timeUnitValues);
    },


    actions: {
      onChange: function onChange() {
        this.sendAction.apply(this, ['on-change'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});