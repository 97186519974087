define('lxso/models/change-history/change-detail', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var format = function format(dateValue) {
    return moment(dateValue).format('LL');
  };

  var titleMappers = {
    'Offer.NegativeAvailability': function OfferNegativeAvailability(value) {
      var _value$split = value.split('/'),
          _value$split2 = _slicedToArray(_value$split, 2),
          from = _value$split2[0],
          to = _value$split2[1];

      return from === to ? format(from) : format(from) + ' - ' + format(to);
    },
    'Option.StopSell.Capacity': function OptionStopSellCapacity(value) {
      return format(JSON.parse(value).date);
    },
    'Promotion': function Promotion(value) {
      return format(JSON.parse(value));
    }
  };

  exports.default = _emberDataModelFragments.default.Fragment.extend({
    attribute: _emberData.default.attr('string'),
    oldValue: _emberData.default.attr('string', { defaultValue: '' }),
    newValue: _emberData.default.attr('string', { defaultValue: '' }),

    title: Ember.computed('attribute', 'oldValue', 'newValue', function () {
      var titleFrom = titleMappers[this.get('attribute')] || function (value) {
        return value;
      };
      return titleFrom(this.get('newValue') || this.get('oldValue'));
    })
  });
});