define('lxso/components/last-updated/link-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = linkData;
  function linkData(changeItem, urlDateFormat) {
    var changeDate = changeItem.get('occurredAt').format(urlDateFormat);

    return {
      route: 'change-history',
      routeParams: {
        supplierBranchId: changeItem.get('supplierBranchId')
      },
      queryParams: {
        from: changeDate,
        to: changeDate,
        productId: changeItem.get('product.productId'),
        offerId: changeItem.get('offer.offerId'),
        changeDetail: changeItem.get('dropdownDescription')
      }
    };
  }
});