define('lxso/serializers/video', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    primaryKey: 'video_id',

    normalize: function normalize(typClass, payload) {
      if (payload.link) {
        var videoId = payload.link.substring(payload.link.lastIndexOf('/') + 1);
        payload.videoId = videoId;
      }
      return this._super.apply(this, arguments);
    }
  });
});