define('lxso/adapters/price-group', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _application.default.extend({
    featureFlags: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    namespace: 'api/v3',
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var offerId = snapshot.adapterOptions.offerId;
      var isOfferGroup = snapshot.adapterOptions.isOfferGroup;
      var url = void 0;
      if (isOfferGroup) {
        url = this.host + '/' + this.namespace + '/offerGroup/' + offerId + '/price';
      } else {
        url = this.host + '/' + this.namespace + '/offer/' + offerId + '/price';
      }

      serializer.serializeIntoHash(data, type, snapshot);

      delete data.offer;
      return this.ajax(url, 'POST', { data: data });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var offerId = snapshot.adapterOptions.offerId;
      var oldPriceGroup = snapshot.adapterOptions.oldPg;
      var isOfferGroup = snapshot.adapterOptions.isOfferGroup;
      var travelStartPrev = snapshot.adapterOptions.travelStartPrev;
      var travelEndPrev = snapshot.adapterOptions.travelEndPrev;
      var isLimitedInventory = snapshot.adapterOptions.isLimitedInventory;

      var url = void 0;
      if (isOfferGroup) {
        url = this.host + '/' + this.namespace + '/offerGroup/' + offerId + '/price';
      } else {
        url = this.host + '/' + this.namespace + '/offer/' + offerId + '/price';
      }

      serializer.serializeIntoHash(data, type, snapshot, oldPriceGroup.id);

      var pricesWithoutUpdate = [];
      var pricesToUpdate = [];
      data.prices.forEach(function (price, idx) {
        price.id = parseInt(price.id);
        if (isOfferGroup) {
          delete price.partnerTicketTypeId;
          var minMax = price.minMaxOld ? price.minMaxOld.split('::') : [];
          if (price.minimumTravelers && price.maximumTravelers && minMax.length) {
            if (price.minimumTravelers == minMax[0] && price.maximumTravelers == minMax[1] && price.ticketTypeId === price.ticketTypeIdOld || price.operation == 'delete' || price.operation == 'create') {
              pricesWithoutUpdate.push(price);
            } else {
              pricesToUpdate.push(price);
            }
          } else {
            pricesWithoutUpdate.push(price);
          }
        }
      });

      if (!isOfferGroup) {
        delete data.priceGroupId;
      }

      if (pricesToUpdate.length) {
        var pricesToDelete = pricesToUpdate.map(function (price) {
          return Object.assign({}, price, {
            operation: 'delete',
            minimumTravelers: parseInt(price.minMaxOld.split('::')[0]),
            maximumTravelers: parseInt(price.minMaxOld.split('::')[1]),
            ticketTypeId: price.ticketTypeIdOld
          });
        });
        var pricesToRecreate = pricesToUpdate.map(function (price) {
          return Object.assign({}, price, { operation: 'create', id: null });
        });
        data.prices = [].concat(pricesWithoutUpdate, _toConsumableArray(pricesToRecreate), _toConsumableArray(pricesToDelete));
      }

      delete data.offer;

      if (isLimitedInventory) {
        var hasOverlap = this.hasOverlappingDates(travelStartPrev, travelEndPrev, data);
        if (hasOverlap) {
          return this.ajax(url, 'PUT', { data: data });
        } else {
          return this.createNewPrice(data, offerId, isOfferGroup);
        }
      } else {
        return this.ajax(url, 'PUT', { data: data });
      }
    },
    hasOverlappingDates: function hasOverlappingDates(oldTravelStart, oldTravelEnd, updatedPg) {
      var updatedTravelStart = updatedPg.travelStart;
      var updatedTravelEnd = updatedPg.travelEnd;
      var hasOverlap = moment(updatedTravelStart).isBetween(oldTravelStart, oldTravelEnd, 'day', '[]') || moment(updatedTravelEnd).isBetween(oldTravelStart, oldTravelEnd, 'day', '[]') || moment(updatedTravelStart).isBefore(oldTravelStart) && moment(updatedTravelEnd).isAfter(oldTravelEnd);
      return hasOverlap;
    },
    createNewPrice: function createNewPrice(data, offerId, isOfferGroup) {
      var _this = this;

      var url = void 0;
      var priceGroupId = void 0;
      if (isOfferGroup) {
        url = this.host + '/' + this.namespace + '/offerGroup/' + offerId + '/price';
        priceGroupId = data.priceGroupId;
        delete data.priceGroupId;
      } else {
        url = this.host + '/' + this.namespace + '/offer/' + offerId + '/price';
      }
      var priceIdsToDelete = this.clearAllIdsAndGetPriceIdsToDelete(data);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.ajax(url, 'POST', { data: data }).then(function (priceCreateSuccess) {
          if (_this.get('featureFlags').checkEnabled('MultipleOptionsGroup') && isOfferGroup) {
            url = _this.host + '/api/v3/offerGroup/' + offerId + '/price/' + priceGroupId;
          } else {
            url = _this.host + '/api/v3/offer/' + offerId + '/price';
          }
          _this.get('ajaxWrapper').ajaxDelete(url, { priceIds: priceIdsToDelete }, undefined, { dataType: 'text' }).then(function () {
            return resolve(priceCreateSuccess);
          });
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    clearAllIdsAndGetPriceIdsToDelete: function clearAllIdsAndGetPriceIdsToDelete(data) {
      delete data.scheduleId;
      var priceIdsToDelete = [];
      data.prices.forEach(function (price) {
        var id = parseInt(price.id);
        if (id) {
          priceIdsToDelete.push(id);
        }
        delete price.minMaxOld;
        delete price.id;
        delete price.ticketTypeIdOld;
      });
      data.availabilities.forEach(function (availability) {
        delete availability.startDate;
        delete availability.endDate;
      });
      return priceIdsToDelete;
    }
  });
});