define('lxso/helpers/add-one', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var addOne = function addOne(params) {
    return params[0] + 1;
  };

  exports.default = Ember.Helper.helper(addOne);
});