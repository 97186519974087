define('lxso/routes/confirmation', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-supplier-branch', 'lxso/mixins/require-permission', 'lxso/utils/omniture'], function (exports, _authenticatedRouteMixin, _requireSupplierBranch, _requirePermission, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, _requirePermission.default, {
    requiredPermission: 'V3Product',

    omniture: {
      pageName: 'page.LX-Supply.PublishConfirmation'
    },

    model: function model(params) {
      this._super.apply(this, arguments);

      return new Ember.RSVP.hash({
        activity: this.store.find('activity', params.id)
      });
    },
    setupController: function setupController(controller, model) {
      this.controller.set('activity', model.activity);
      this.controller.set('activityId', this.controller.get('activity.id'));
      this.controller.set('supplierBranchId', this.get('session.data.authenticated.selectedBranch').supplier_branch_id);
      this.controller.set('activityTitle', this.controller.get('activity.basic.internal_title'));
    },
    willTransition: function willTransition() {
      delete window.pageName;
    },
    didTransition: function didTransition() {
      window.pageName = 'PublishConfirmation';
    }
  });
});