define('lxso/components/report-detail/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    label: Ember.computed('id', function () {
      var id = this.get('id');
      return 'pages.report.labels.' + id;
    }),

    displayValue: Ember.computed('value', 'condition', function () {
      var value = this.get('value');
      var condition = this.get('condition');
      var isDisplayed = value && (typeof condition === 'undefined' || condition);
      if (Array.isArray(value) && value.length == 0) {
        return '--';
      } else {
        return isDisplayed ? value : '--';
      }
    }),

    actions: {
      resendNotification: function resendNotification() {
        this.sendAction('resendNotification');
      },
      linkAction: function linkAction(params) {
        this.sendAction('linkAction', params);
      }
    }
  });
});