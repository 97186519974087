define('lxso/components/bq-selected-questions-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    staticData: Ember.inject.service(),
    init: function init() {
      var travelerInfo = {
        name: null,
        dob: null,
        passport: {
          nationality: null,
          number: null,
          expiration: null
        },
        safety: {
          weight: null,
          height: null
        }
      };
      var pickupInfo = {
        arrivalLocations: {
          airports: null,
          trainStations: null,
          busStations: null,
          shipOrCruisePorts: null,
          others: null
        },
        hotels: null
      };
      var dropOffInfo = {
        arrivalLocations: {
          airports: null,
          trainStations: null,
          busStations: null,
          shipOrCruisePorts: null,
          others: null
        },
        hotels: null
      };
      var hasTravelInfo = false;
      var hasPickupInfo = false;
      var hasDropoffInfo = false;
      this.get('bookingQuestions').forEach(function (item) {
        var fieldId = item.get('fieldId');
        if (item.get('isEnabled')) {
          if (fieldId >= 1 && fieldId <= 7) {
            hasTravelInfo = true;
          }
          if (fieldId >= 8 && fieldId <= 13) {
            hasPickupInfo = true;
          }
          if (fieldId >= 14 && fieldId <= 19) {
            hasDropoffInfo = true;
          }

          switch (fieldId) {
            case 1:
              travelerInfo.name = true;
              break;
            case 2:
              travelerInfo.dob = true;
              break;
            case 3:
              travelerInfo.passport.nationality = true;
              break;
            case 4:
              travelerInfo.passport.number = true;
              break;
            case 5:
              travelerInfo.passport.expiration = true;
              break;
            case 6:
              travelerInfo.safety.weight = true;
              break;
            case 7:
              travelerInfo.safety.height = true;
              break;
            case 8:
              pickupInfo.arrivalLocations.airports = true;
              break;
            case 9:
              pickupInfo.arrivalLocations.trainStations = true;
              break;
            case 10:
              pickupInfo.arrivalLocations.busStations = true;
              break;
            case 11:
              pickupInfo.arrivalLocations.shipOrCruisePorts = true;
              break;
            case 12:
              pickupInfo.arrivalLocations.others = true;
              break;
            case 13:
              pickupInfo.hotels = true;
              break;
            case 14:
              dropOffInfo.arrivalLocations.airports = true;
              break;
            case 15:
              dropOffInfo.arrivalLocations.trainStations = true;
              break;
            case 16:
              dropOffInfo.arrivalLocations.busStations = true;
              break;
            case 17:
              dropOffInfo.arrivalLocations.shipOrCruisePorts = true;
              break;
            case 18:
              dropOffInfo.arrivalLocations.others = true;
              break;
            case 19:
              dropOffInfo.hotels = true;
              break;
          }
        }
      });
      this.set('travelerInfo', travelerInfo);
      this.set('hasTravelInfo', hasTravelInfo);
      this.set('hasPickupInfo', hasPickupInfo);
      this.set('hasDropoffInfo', hasDropoffInfo);
      this.set('pickupInfo', pickupInfo);
      this.set('dropOffInfo', dropOffInfo);
      this._super.apply(this, arguments);
    },

    actions: {
      openStep: function openStep(className) {
        var link = document.getElementsByClassName('toggle-trigger ' + className);
        $(link).toggleClass('open');

        var contents = document.getElementsByClassName(className + '-content');
        $(contents).toggleClass('close');
      }
    }
  });
});