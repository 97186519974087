define('lxso/models/offer', ['exports', 'ember-data', 'ember-api-actions', 'ember-data-model-fragments/attributes', 'lxso/utils/validation', 'lxso/utils/regexPatterns'], function (exports, _emberData, _emberApiActions, _attributes, _validation, _regexPatterns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var maximumHours = Ember.computed('model.permissions', 'model.isNew', function () {
    if (this.get('model.permissions').checkEnabled('V3InternalSettings')) {
      return 1440;
    } else {
      if (this.get('model.isNew')) {
        return 24;
      } else {
        return 1440;
      }
    }
  });

  var Validations = (0, _validation.build)({
    shortTitle: {
      descriptionKey: 'pages.offer.labels.offerTitle',
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('length', { min: 4 }), (0, _validation.validator)('format', {
        regex: _regexPatterns.validForm.validFormChar,
        messageKey: 'errors.customMessages.offers.invalidChars'
      })]
    },
    isLimitedInventory: (0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'errors.customMessages.offers.noSelectionMade'
    }),
    bookingCutoffInHours: {
      descriptionKey: 'pages.offer.labels.bookingCutoff',
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('number', {
        gte: 0,
        allowString: true,
        messageKey: 'errors.customMessages.offers.mustBeNumber'
      }), (0, _validation.validator)('number', {
        lte: 504,
        allowString: true,
        messageKey: 'errors.customMessages.offers.mustBeLessThan'
      }), (0, _validation.validator)('booking-cutoff-not-greater-than-24', {
        messageKey: 'errors.customMessages.offers.bookingCutoffNotGreaterThan24',
        isNew: Ember.computed('data.bookingCutoffInHours', function () {
          return this.get('model.isNew');
        }),
        previousValue: Ember.computed('model.isNew', function () {
          return this.get('model.data.bookingCutoffInHours');
        }).volatile()
      })]
    },
    durationInMinutes: {
      descriptionKey: 'pages.offer.labels.duration',
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('number', {
        gte: 0,
        allowString: true,
        messageKey: 'errors.customMessages.offers.mustBeNumber'
      })]
    },
    cancellationPolicyType: {
      descriptionKey: 'pages.offer.labels.cancellationPolicy',
      validators: [(0, _validation.validator)('presence', {
        presence: true,
        messageKey: 'errors.customMessages.offers.noSelectionMade'
      })]
    },
    cancellationCutoffInHours: {
      descriptionKey: 'pages.offer.labels.cancellationCutoff',
      validators: [(0, _validation.validator)('presence', true), (0, _validation.validator)('number', {
        gte: 0,
        allowString: true,
        messageKey: 'errors.customMessages.offers.mustBeNumber'
      }), (0, _validation.validator)('number', {
        lte: maximumHours,
        allowString: true,
        messageKey: Ember.computed('model.permissions', function () {
          if (this.get('model.permissions').checkEnabled('V3InternalSettings')) {
            return 'errors.customMessages.offers.mustBeLessThan1440';
          } else {
            return 'errors.customMessages.offers.mustBeLessThan24';
          }
        })
      }), (0, _validation.validator)('cancellation-cutoff-cannot-be-greater', {
        messageKey: 'errors.customMessages.offers.cancellationCutoffNotGreater',
        previousValue: Ember.computed('data.cancellationCutoffInHours', function () {
          return this.get('model.data.cancellationCutoffInHours');
        }).volatile()
      })]
    },
    offerDetails: {
      validators: [(0, _validation.validator)('duplicate-offer-details', {
        volatile: true
      }), (0, _validation.validator)('offer-group-size', {
        lte: 15,
        messageKey: 'errors.customMessages.offerGroup.maxNumberOfOffers'
      })]
    }
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    permissions: Ember.inject.service(),
    i18n: Ember.inject.service(),
    shortTitle: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    activityId: _emberData.default.attr('number'),
    offerGroupId: _emberData.default.attr('number'),
    isLimitedInventory: _emberData.default.attr('boolean', { defaultValue: true }),
    bookingCutoffInHours: _emberData.default.attr('number', { defaultValue: 0 }),
    durationInMinutes: _emberData.default.attr('number'),
    isMultipleOptionGroup: _emberData.default.attr('boolean', { defaultValue: false }),
    schedulesAndLanguageInfo: (0, _attributes.fragmentArray)('offer-group/schedule-and-lang-info', {
      defaultValue: [{
        schedules: [{
          tagId: new Date().getTime().toString(),
          startTime: '',
          endTime: ''
        }],
        languageInfo: {
          tagId: new Date().getTime().toString(),
          languageIDs: Ember.A([5]),
          languageTypeId: 0
        }
      }]
    }),

    offerDetails: _emberData.default.attr('array'),
    cancellationPolicyType: _emberData.default.attr('string', { defaultValue: 'Refundable' }),
    cancellationCutoffInHours: _emberData.default.attr('number', { defaultValue: 0 }),
    status: _emberData.default.attr('string'),
    platformId: _emberData.default.attr('number', { defaultValue: 0 }),
    offerAttributes: (0, _attributes.fragment)('fragments/offer-attributes', {
      defaultValue: {
        languageInfo: {
          languageIDs: Ember.A([5]),
          languageTypeId: 0
        }
      }
    }),
    prices: _emberData.default.hasMany('priceGroup', { async: true }),

    reviews: (0, _emberApiActions.instanceOp)({
      path: 'reviews',
      type: 'get'
    })
  });
});