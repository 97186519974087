define('lxso/services/feature-flags', ['exports', 'lxso/mixins/enabler'], function (exports, _enabler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_enabler.default, {
    enablerModel: 'enums/feature',
    enablerFieldToCheck: 'isEnabled'
  });
});