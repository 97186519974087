define('lxso/mixins/supplier-settings/supplier-branch-settings', ['exports', 'lxso/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    initSupplierBranchSettings: function initSupplierBranchSettings() {
      this.controller.set('isSupplierBranchSettingsOpen', true);
      this.controller.set('editingSupplierBranchSettings', false);
      this.controller.set('savingSupplierBranchSettings', false);
      this.setIsBranchSuspended();
    },
    setIsBranchSuspended: function setIsBranchSuspended() {
      var status = this.controller.get('supplierBranch.status');
      this.controller.set('isBranchSuspendedExpedia', status === 'SuspendedExpedia');
      this.controller.set('isBranchSuspendedPaused', status === 'SuspendedPaused');
    },


    actions: {
      retryVendorBranchId: function retryVendorBranchId(supplierBranch) {
        var _this = this;

        this.controller.set('lastSaved', 'supplier-branch-settings');
        this.controller.set('processingVendorBranchIdRetry', true);
        var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/supplier_branches/tshopvendorbranch';
        var postData = {
          supplierBranchId: parseInt(supplierBranch.get('id')),
          accountingVendorId: supplierBranch.get('affiliate_id')
        };
        this.get('ajaxWrapper').ajaxPost(url, postData).then(function (response) {
          _this.controller.set('processingVendorBranchIdRetry', false);
          supplierBranch.set('vendor_branch_id', response.vendorBranchId);
          _this.get('notifications').success(_this.get('i18n').t('components.alert.saveSuccess'));
        }).catch(function (errors) {
          _this.controller.set('processingVendorBranchIdRetry', false);
          Ember.Logger.error('error creating supplier branch Vendor ID', errors);
          _this.controller.set('errors', errors);
          _this.get('notifications').error(_this.get('i18n').t('components.alert.saveFailure'));
        });
      },
      supplierBranchStatusSelected: function supplierBranchStatusSelected(status) {
        this.controller.set('supplierBranch.status', status);
      },
      supplierBranchAutoRenewRateSelected: function supplierBranchAutoRenewRateSelected(isAutoRenewRateEnabled) {
        this.controller.set('supplierBranch.auto_renew_rate_enabled', isAutoRenewRateEnabled);
      },
      timeZoneSelected: function timeZoneSelected(timeZone) {
        this.controller.set('supplierBranch.default_timezone', timeZone);
      },
      supplierBranchTypeSelected: function supplierBranchTypeSelected(branchType) {
        this.controller.set('supplierBranch.supplier_branch_type', branchType);
      },
      editSupplierBranchSettings: function editSupplierBranchSettings() {
        if (this.isEditAllowed('supplierBranchSettings')) {
          this.controller.set('editingSupplierBranchSettings', true);
        } else {
          this.controller.set('sectionToBeEdited', 'supplierBranchSettings');
        }
      },
      cancelEditSupplierBranchSettings: function cancelEditSupplierBranchSettings() {
        this.controller.get('supplierBranch').rollbackAttributes();
        this.controller.set('editingSupplierBranchSettings', false);
      },
      saveSupplierBranchSettings: function saveSupplierBranchSettings() {
        var _this2 = this;

        var route = this;
        route.setIsBranchSuspended();
        route.controller.set('lastSaved', 'supplier-branch-settings');
        route.controller.set('showSupplierBranchSettingsNotification', true);
        route.controller.set('savingSupplierBranchSettings', true);

        var operationalData = {
          status: route.controller.get('supplierBranch.status'),
          primary_dm: route.controller.get('supplierBranch.primary_dm'),
          supplier_branch_type: route.controller.get('supplierBranch.supplier_branch_type'),
          auto_renew_rate_enabled: route.controller.get('supplierBranch.auto_renew_rate_enabled')
        };
        var basicData = {
          time_zone: route.controller.get('supplierBranch.default_timezone'),
          account_vendor_id: route.controller.get('supplierBranch.affiliate_id'),
          supplier_branch_name: route.controller.get('supplierBranch.supplier_branch_name')
        };

        var referencesData = {
          salesforce_code: route.controller.get('supplierBranch.salesforce_code'),
          vendor_branch_id: parseInt(route.controller.get('supplierBranch.vendor_branch_id'))
        };

        if (this.controller.get('partnerSupplierDetails.partner_id')) {
          referencesData.partner_id = this.controller.get('partnerSupplierDetails.partner_id');
          referencesData.partner_supplier_branch_id = this.controller.get('partnerSupplierDetails.partner_supplier_branch_id');
          referencesData.connectivity_status = this.controller.get('partnerSupplierDetails.connectivity_status');
        }

        route.controller.get('supplierBranch').updateOperational(operationalData).then(function () {
          return route.controller.get('supplierBranch').updateBasic(basicData);
        }).then(function () {
          return route.controller.get('supplierBranch').updateReferences(referencesData);
        }).then(function (success) {
          route.controller.set('errors', []);
          // could not find a better way to tell ember that these attributes are dirty.
          return route.controller.get('supplierBranch').reload();
        }).then(function (supplierBranchDetails) {
          var supplierBranch = JSON.parse(JSON.stringify(supplierBranchDetails));
          supplierBranch.supplier_branch_id = supplierBranchDetails.get('id');
          _this2.get('supplierBranchService').updateSelectedBranch(supplierBranch);

          route.get('notifications').success(route.get('i18n').t('components.alert.saveSuccess'));
          route.controller.set('editingSupplierBranchSettings', false);
        }).catch(function (errors) {
          Ember.Logger.error('error updating supplier branch settings', errors);
          route.controller.set('errors', errors);
          route.get('notifications').error(route.get('i18n').t('components.alert.saveFailure'));
        }).finally(function () {
          route.controller.set('savingSupplierBranchSettings', false);
        });
      }
    }
  });
});