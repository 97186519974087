define('lxso/models/fragments/price-group-v4/availability-by-days', ['exports', 'ember-data-model-fragments/attributes', 'lxso/utils/validation', 'ember-data', 'ember-data-model-fragments'], function (exports, _attributes, _validation, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _validation.build)({});

  var availabilityDefaults = {
    startTime: '06:00',
    endTime: '18:00',
    available: true,
    capacity: 100
  };

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    priceGroupId: _emberData.default.attr('number'),
    offerGroupId: _emberData.default.attr('number'),
    availabilities: (0, _attributes.fragmentArray)('fragments/price-group-v4/availability-by-day', { defaultValue: [Object.assign({ day: 'SUNDAY' }, availabilityDefaults), Object.assign({ day: 'MONDAY' }, availabilityDefaults), Object.assign({ day: 'TUESDAY' }, availabilityDefaults), Object.assign({ day: 'WEDNESDAY' }, availabilityDefaults), Object.assign({ day: 'THURSDAY' }, availabilityDefaults), Object.assign({ day: 'FRIDAY' }, availabilityDefaults), Object.assign({ day: 'SATURDAY' }, availabilityDefaults)] })
  });
});