define('lxso/mixins/confirmation-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    confirm: function confirm(message) {
      'use strict';

      var _this = this;

      Ember.$.ajax({
        url: 'templates/partials/confirmation.hbs'
      }).then(function (template) {
        'use strict';

        var component = _this.create(template, message);
        _this.set('confirmationComponent', component);
      });
    },
    create: function create(template, message) {
      'use strict';

      var owner = Ember.getOwner(this);
      var component = Ember.Component.create({
        layout: Ember.HTMLBars.compile(template),
        renderer: owner.lookup('renderer:-dom')
      });
      var that = this;

      Ember.setOwner(component, owner);

      component.set('confirmationMessage', message);
      component.reopen({
        actions: {
          cancel: function cancel() {
            that.send('cancelled');
            that.close();
          },
          confirmed: function confirmed() {
            that.send('confirmed');
            that.close();
          }
        }
      });
      component.appendTo('body');

      return component;
    },
    close: function close() {
      'use strict';

      this.get('confirmationComponent').destroy();
    }
  });
});