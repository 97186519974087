define('lxso/helpers/is-equal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEqual = isEqual;


  //usage:
  // {{#if (is-equal index otherIndex)}}
  // {{#if (is-equal link-list 'triplet-links')}}

  function isEqual(params) {
    return params.length !== 2 ? false : params[0] === params[1];
  }

  exports.default = Ember.Helper.helper(isEqual);
});