define('lxso/components/option-group/options/component', ['exports', 'lodash/array', 'lxso/constants/option-group', 'lxso/constants/language-type-ids', 'lxso/mixins/option-group/option-group-v4', 'lxso/utils/form-validator', 'lxso/utils/option-group'], function (exports, _array2, _optionGroup, _languageTypeIds, _optionGroupV, _formValidator, _optionGroup2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_optionGroupV.default, {
    i18n: Ember.inject.service(),
    classNameBindings: ['componentClasses'],
    isSelectAllOptionsChecked: false,
    options: Ember.computed('optionGroup.offers.@each.status', function () {
      var statusesToDisplayLowerCase = [_optionGroup.STATUS.ACTIVE.toLowerCase(), _optionGroup.STATUS.SUSPENDED.toLowerCase()];
      return this.get('optionGroup.offers').filter(function (option) {
        return statusesToDisplayLowerCase.includes(option.get('status').toLowerCase());
      });
    }),
    isSelectAndDeleteOptionsEnabled: Ember.computed('options.@each.isSelected', function () {
      return this.get('options').any(function (option) {
        return option.get('isSelected');
      });
    }),
    isDaysBasedScheduleType: Ember.computed('optionGroup.attributes.scheduleType', function () {
      var scheduleType = this.get('optionGroup.attributes.scheduleType');
      return scheduleType === _optionGroup.SCHEDULE_TYPES.VALID_DAYS || scheduleType == _optionGroup.SCHEDULE_TYPES.MULTI_DAY;
    }),
    isOptionsPreFilled: Ember.computed('optionGroup.attributes.scheduleType', function () {
      var scheduleType = this.get('optionGroup.attributes.scheduleType');
      var isStartTimeTypeSchedule = scheduleType === _optionGroup.SCHEDULE_TYPES.START || scheduleType == _optionGroup.SCHEDULE_TYPES.MULTI_DAY;
      return !isStartTimeTypeSchedule;
    }),
    isOptionRowDeleteModalOpen: false,
    showOptionUpdateAlert: false,
    onOptionDeleteConfirm: null,
    durationColumnTitle: Ember.computed('optionGroup.attributes.scheduleType', function () {
      var scheduleType = this.get('optionGroup.attributes.scheduleType');
      var baseKey = 'pages.optionGroup.optionGroupSection.options.commentaryAndSchedule.table';
      if (scheduleType === _optionGroup.SCHEDULE_TYPES.START) {
        return baseKey + '.scheduleStartEnd';
      } else if (scheduleType === _optionGroup.SCHEDULE_TYPES.MULTI_DAY) {
        return baseKey + '.scheduleMultiDayStart';
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('SCHEDULE_TYPES', _optionGroup.SCHEDULE_TYPES);
      this.set('LANG_TYPES', _languageTypeIds.LANG_TYPE_IDS);
      this.setLanguageTypes();
      this.setLanguageList();
    },
    setLanguageTypes: function setLanguageTypes() {
      var languageTypeIds = Ember.A([_languageTypeIds.LANG_TYPE_IDS.LIVE_COMMENTARY, _languageTypeIds.LANG_TYPE_IDS.AUDIO_GUIDE, _languageTypeIds.LANG_TYPE_IDS.WRITTEN_GUIDE, _languageTypeIds.LANG_TYPE_IDS.NO_COMMENTARY]);
      var commentaryTranslationBaseKey = 'pages.optionGroup.optionGroupSection.options.commentaryAndSchedule.commentaryTypes';
      this.setRadioButtonGroupData(languageTypeIds, commentaryTranslationBaseKey, 'languageTypes');
    },
    setLanguageList: function setLanguageList() {
      this.set('languageListKeys', this.get('languageEnum').map(function (language) {
        return language.id;
      }));
      this.set('languageListLabels', this.get('languageEnum').map(function (language) {
        return language.label;
      }));
    },
    setRadioButtonGroupData: function setRadioButtonGroupData(values, labelBaseKey, modelKey) {
      var _this = this;

      var labelValuePairsForRadioButtons = values.map(function (value) {
        return {
          label: _this.get('i18n').t(labelBaseKey + '.' + value),
          value: value
        };
      });
      this.set(modelKey, labelValuePairsForRadioButtons);
    },
    updateSelectAllCheckbox: function updateSelectAllCheckbox() {
      this.set('isSelectAllOptionsChecked', this.get('options').length > 0 && this.get('options').every(function (option) {
        return option.get('isSelected');
      }));
    },
    validateOptions: function validateOptions() {
      var _this2 = this;

      var self = this;
      var errors = {};
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var hasOptionsAsGroupErrors = false;
        _this2.getOptionsAsGroupValidations().catch(function (optionsAsGroupValidations) {
          hasOptionsAsGroupErrors = true;
          Object.assign(errors, optionsAsGroupValidations);
        }).finally(function () {
          self.getIndividualOptionRowsValidations().then(function (individualOptionRowValidations) {
            if ((0, _optionGroup2.anyRejects)(individualOptionRowValidations) || hasOptionsAsGroupErrors) {
              Object.assign(errors, {
                optionRow: (0, _optionGroup2.normalizeOffersErrors)(individualOptionRowValidations)
              });
              reject(errors);
            } else {
              resolve();
            }
          });
        });
      });
    },
    getOptionsAsGroupValidations: function getOptionsAsGroupValidations() {
      var attributes = this.get('hasExistingPrices') ? ['offers', 'optionGroupOverlap'] : ['optionGroupOverlap'];
      return (0, _formValidator.validate)({ model: this.get('optionGroup'), attributes: attributes });
    },
    getIndividualOptionRowsValidations: function getIndividualOptionRowsValidations() {
      var offerAttributesToValidate = [];
      if (this.get('optionGroup.attributes.scheduleType') == _optionGroup.SCHEDULE_TYPES.START) {
        offerAttributesToValidate = ['startTime'];
      } else if (this.get('optionGroup.attributes.scheduleType') == _optionGroup.SCHEDULE_TYPES.MULTI_DAY) {
        offerAttributesToValidate = ['startTime'];
      }
      return Ember.RSVP.allSettled(this.get('optionGroup.offers').filter(function (optionRow) {
        return optionRow.get('status') === _optionGroup.STATUS.ACTIVE;
      }).map(function (optionRow) {
        return (0, _formValidator.validate)({ model: optionRow, attributes: offerAttributesToValidate }, { model: optionRow.get('languageInfo'), attributes: ['languageTypeId', 'languageIds'] });
      }));
    },
    _validateIfInvalid: function _validateIfInvalid() {
      var _this3 = this;

      if (this.get('errors')) {
        this.validateOptions().then(function () {
          _this3.set('errors', null);
        }).catch(function (errors) {
          _this3.set('errors', errors);
        });
      }
    },
    deleteAllSelectedOptions: function deleteAllSelectedOptions() {
      var _this4 = this;

      var optionsToDelete = this.get('options').filter(function (option) {
        return option.get('isSelected');
      });
      optionsToDelete.forEach(function (optionToDelete) {
        _this4.deleteOption(optionToDelete);
        optionToDelete.set('isSelected', false);
        //isSelected is reset since new options created after a delete were getting auto selected on options save.
      });
      this.updateSelectAllCheckbox();
      this._validateIfInvalid();
    },
    deleteOption: function deleteOption(option) {
      option.set('status', _optionGroup.STATUS.INACTIVE);
      this.updateSelectAllCheckbox();
      this._validateIfInvalid();
    },
    setFormDirty: function setFormDirty(isDirty) {
      this.set('isDirty', isDirty);
    },


    actions: {
      openStep: function openStep() {
        if (this.get('isOptionGroupOptionsEnabled')) {
          this.toggleProperty('isOptionGroupOptionsOpen');
          this.set('showOptionUpdateAlert', false);
        }
      },
      validateIfInvalid: function validateIfInvalid() {
        this._validateIfInvalid();
      },
      handleLanguageTypeSelect: function handleLanguageTypeSelect(option, languageTypeID) {
        this.setFormDirty(true);
        var selectedLanguageIds = option.get('languageInfo.languageIds');
        option.set('languageInfo.languageTypeId', languageTypeID);
        if (languageTypeID === _languageTypeIds.LANG_TYPE_IDS.NO_COMMENTARY) {
          option.set('languageInfo.languageIds', Ember.A([]));
        } else if (languageTypeID === _languageTypeIds.LANG_TYPE_IDS.WRITTEN_GUIDE) {
          if (_array2.default.intersection(selectedLanguageIds, _languageTypeIds.SPOKEN_CHINESE_LANG_IDS).length > 0) {
            option.set('languageInfo.languageIds', selectedLanguageIds.filter(function (langId) {
              return !_languageTypeIds.SPOKEN_CHINESE_LANG_IDS.includes(langId);
            }));
          }
        } else if (_languageTypeIds.SPOKEN_LANG_TYPE_IDS.includes(languageTypeID)) {
          if (_array2.default.intersection(selectedLanguageIds, _languageTypeIds.WRITTEN_CHINESE_LANG_IDS).length > 0) {
            option.set('languageInfo.languageIds', selectedLanguageIds.filter(function (langId) {
              return !_languageTypeIds.WRITTEN_CHINESE_LANG_IDS.includes(langId);
            }));
          }
        }
        this._validateIfInvalid();
      },
      selectMultipleLanguages: function selectMultipleLanguages(option, languageID) {
        this.setFormDirty(true);
        var existingLangIds = option.get('languageInfo.languageIds');
        var newLanguageArray = new Ember.A(existingLangIds.slice());
        var index = existingLangIds.indexOf(languageID);
        if (existingLangIds.includes(languageID)) {
          newLanguageArray.splice(index, 1);
        } else {
          newLanguageArray.pushObject(languageID);
        }
        option.set('languageInfo.languageIds', newLanguageArray);
        this._validateIfInvalid();
      },
      handleFromToTimeChange: function handleFromToTimeChange() {
        this.setFormDirty(true);
        this._validateIfInvalid();
      },
      handleSelectAllOptionsCheckboxChange: function handleSelectAllOptionsCheckboxChange() {
        var isSelectAllOptionsChecked = this.get('isSelectAllOptionsChecked');
        this.get('options').map(function (option) {
          option.set('isSelected', isSelectAllOptionsChecked);
        });
      },
      handleSelectOptionCheckboxChange: function handleSelectOptionCheckboxChange() {
        this.updateSelectAllCheckbox();
      },
      handleDeleteAllSelectedOptions: function handleDeleteAllSelectedOptions() {
        var _this5 = this;

        this.toggleProperty('isOptionRowDeleteModalOpen');
        this.set('deleteModalTitle', this.get('i18n').t('pages.optionGroup.deleteOptionRowModel.deleteSelectedTitle'));
        this.set('deleteModalBody', this.get('i18n').t('pages.optionGroup.deleteOptionRowModel.deleteSelectedBody'));
        var onOptionDeleteConfirm = function onOptionDeleteConfirm() {
          _this5.deleteAllSelectedOptions();
        };
        this.set('onOptionDeleteConfirm', onOptionDeleteConfirm);
      },
      handleDeleteOption: function handleDeleteOption(option) {
        var _this6 = this;

        this.toggleProperty('isOptionRowDeleteModalOpen');
        this.set('deleteModalTitle', this.get('i18n').t('pages.optionGroup.deleteOptionRowModel.deleteTitle'));
        this.set('deleteModalBody', this.get('i18n').t('pages.optionGroup.deleteOptionRowModel.deleteBody'));
        var onOptionDeleteConfirm = function onOptionDeleteConfirm() {
          _this6.deleteOption(option);
        };
        this.set('onOptionDeleteConfirm', onOptionDeleteConfirm);
      },
      handleCancelDeleteRow: function handleCancelDeleteRow() {
        this.toggleProperty('isOptionRowDeleteModalOpen');
        this.set('onOptionDeleteConfirm', null);
      },
      handleDeleteRowConfirm: function handleDeleteRowConfirm() {
        this.setFormDirty(true);
        this.get('onOptionDeleteConfirm')();
        this.send('handleCancelDeleteRow');
      },
      handleAddOption: function handleAddOption() {
        this.setFormDirty(true);
        this.get('optionGroup.offers').createFragment(this.getDefaultOfferDetailRecord());
        this.updateSelectAllCheckbox();
      },
      handleCopyOption: function handleCopyOption(option) {
        this.setFormDirty(true);
        var insertPosition = this.get('optionGroup.offers').indexOf(option) + 1;
        this.get('optionGroup.offers').insertAt(insertPosition, this.getOfferDetailRecordCopy(option));
      },
      saveOfferGroupOptions: function saveOfferGroupOptions() {
        var _this7 = this;

        this.validateOptions().then(function () {
          var optionGroup = _this7.get('optionGroup');
          return _this7.saveOptionGroupOptions(optionGroup);
        }).then(function () {
          if (_this7.get('hasExistingPrices') && _this7.get('activity.migrationStatus.price')) {
            _this7.set('showOptionUpdateAlert', true);
          }
        }).catch(function (errors) {
          _this7.set('errors', errors);
          (0, _formValidator.scrollToFirstError)();
        });
      },
      optionUpdateAlertClose: function optionUpdateAlertClose() {
        this.set('showOptionUpdateAlert', false);
      },
      continue: function _continue() {
        this.continueOptionGroupOptions();
      }
    }
  });
});