define('lxso/components/contact-us-link/component', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['no-padding'],

    actions: {
      contactUsLinkAction: function contactUsLinkAction() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.ContactUs');
        if (this.menuItemClick) {
          this.menuItemClick('contactUs');
        }
        window.open('https://support.localexpertpartnercentral.com/index.php/contact-us/');
      }
    }
  });
});