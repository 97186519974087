define('lxso/serializers/supplier-branch/offer-group-margin', ['exports', 'ember-data', 'lxso/mixins/underscored-json-attributes'], function (exports, _emberData, _underscoredJsonAttributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_underscoredJsonAttributes.default, _emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'offer_group_id',
    attrs: {
      offers: { embedded: 'always' }
    }
  });
});