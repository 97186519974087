define('lxso/app', ['exports', 'ember-resolver', 'ember-load-initializers', 'lxso/config/environment'], function (exports, _emberResolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  // Patch the environment config with any runtime options
  if (_typeof(window.RuntimeENV) === 'object') {
    Ember.$.extend(true, _environment.default, window.RuntimeENV);
    delete window.RuntimeENV;
  }

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _emberResolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});