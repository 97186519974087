define('lxso/constants/base-ticket-types', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var BASE_TICKET_TYPES = {
    INFANT: 'INFANT',
    CHILD: 'CHILD',
    YOUTH: 'YOUTH',
    ADULT: 'ADULT',
    SENIOR: 'SENIOR',
    STUDENT: 'STUDENT',
    TRAVELER: 'TRAVELER'
  };

  var BASE_TICKET_TYPES_SHARED_OR_MASS_GT = {
    INFANT: 'INFANT',
    CHILD: 'CHILD',
    ADULT: 'ADULT'
  };

  var BASE_TYPES = {
    STANDARD: 'STANDARD',
    CAPACITY: 'CAPACITY'
  };

  var BASE_TICKET_TYPES_LIST = [BASE_TICKET_TYPES.INFANT, BASE_TICKET_TYPES.CHILD, BASE_TICKET_TYPES.YOUTH, BASE_TICKET_TYPES.ADULT, BASE_TICKET_TYPES.SENIOR, BASE_TICKET_TYPES.TRAVELER, BASE_TICKET_TYPES.STUDENT];

  var BASE_TICKET_TYPES_SHARED_OR_MASS_GT_LIST = [BASE_TICKET_TYPES.INFANT, BASE_TICKET_TYPES.CHILD, BASE_TICKET_TYPES.ADULT];

  var INDEPENDENTLY_BOOKABLE_TICKET_TYPES = [BASE_TICKET_TYPES.TRAVELER, BASE_TICKET_TYPES.STUDENT, BASE_TICKET_TYPES.ADULT];

  var MIN_TICKETS_PER_BOOKING_DEFAULT = null;
  var MAX_TICKETS_PER_BOOKING_DEFAULT = null;
  var TICKETS_PER_BOOKING_UPPER_LIMIT = 8;
  var PERSONS_PER_CAPACITY_TICKET_UPPER_LIMIT = 8;

  exports.BASE_TICKET_TYPES = BASE_TICKET_TYPES;
  exports.BASE_TICKET_TYPES_LIST = BASE_TICKET_TYPES_LIST;
  exports.BASE_TICKET_TYPES_SHARED_OR_MASS_GT_LIST = BASE_TICKET_TYPES_SHARED_OR_MASS_GT_LIST;
  exports.BASE_TYPES = BASE_TYPES;
  exports.INDEPENDENTLY_BOOKABLE_TICKET_TYPES = INDEPENDENTLY_BOOKABLE_TICKET_TYPES;
  exports.MIN_TICKETS_PER_BOOKING_DEFAULT = MIN_TICKETS_PER_BOOKING_DEFAULT;
  exports.MAX_TICKETS_PER_BOOKING_DEFAULT = MAX_TICKETS_PER_BOOKING_DEFAULT;
  exports.TICKETS_PER_BOOKING_UPPER_LIMIT = TICKETS_PER_BOOKING_UPPER_LIMIT;
  exports.PERSONS_PER_CAPACITY_TICKET_UPPER_LIMIT = PERSONS_PER_CAPACITY_TICKET_UPPER_LIMIT;
});