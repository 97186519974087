define('lxso/serializers/option', ['exports', 'ember-data', 'lxso/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({

    primaryKey: 'option_id',

    attrs: {
      activity: 'activity_id'
    },

    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      // Add a links object to find associated supplier branch
      payload.links = {
        activity: _environment.default.api.host + '/' + _environment.default.api.namespace + '/activities/' + payload.activity_id
      };

      return this._super.apply(this, arguments);
    }
  });
});