define('lxso/routes/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-supplier-branch'], function (exports, _authenticatedRouteMixin, _requireSupplierBranch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, {
    beforeModel: function beforeModel(transition) {
      return this._super.apply(this, arguments);
    },


    supplierBranch: Ember.inject.service('supplier-branch'),

    model: function model() {
      var _this = this;

      this._super.apply(this, arguments);

      return this.get('supplierBranch').getSelectedBranch().then(function (result) {
        _this.replaceWith('products', result.supplier_branch_id);
      });
    }
  });
});