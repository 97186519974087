define('lxso/components/supplier-margin-section/component', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    validateProductTicketTypeMargin: function validateProductTicketTypeMargin() {
      'use strict';

      var supplierBranch = this.get('supplierBranch');
      var marginOverrideList = this.get('marginOverrideList');
      var attributes = ['product_margin', 'ticket_type_margins', 'offer_margins', 'offer_group_margins'];
      var marginPromiseList = [];
      marginOverrideList.forEach(function (marginOverride) {
        marginOverride.get('ticket_type_margins').arrayContentDidChange();
        marginOverride.get('offer_margins').arrayContentDidChange();
        marginOverride.get('offer_group_margins').arrayContentDidChange();
        var validationPromise = (0, _formValidator.validate)({ model: marginOverride, attributes: attributes });
        marginPromiseList.push(validationPromise);
      });

      var supplierBaseMarginValidate = (0, _formValidator.validate)({ model: supplierBranch, attributes: ['baseMargin'] });
      marginPromiseList.push(supplierBaseMarginValidate);

      return Ember.RSVP.map(marginPromiseList, function (data) {
        return data;
      });
    },
    validateBaseMargin: function validateBaseMargin() {
      var supplierBranch = this.get('supplierBranch');
      var res = (0, _formValidator.validate)({ model: supplierBranch, attributes: ['baseMargin'] });
      return res;
    },
    convertMarginDecimalToPercent: function convertMarginDecimalToPercent(decimal) {
      return (decimal * 100).toFixed(2);
    },
    confirmationCheck: function confirmationCheck() {
      var _this = this;

      var supplierBranch = this.get('supplierBranch');

      return new Ember.RSVP.Promise(function (resolve) {
        if (supplierBranch.changedAttributes().baseMargin) {
          _this.set('showConfirmationModal', true);
          _this.set('confirmationPromise', resolve);
        } else {
          resolve(true);
        }
      });
    },
    setFormDirty: function setFormDirty(state) {
      this.set('formDirty', state);
    },
    keyUp: function keyUp() {
      this.setFormDirty(true);
    },


    actions: {
      editMarginSection: function editMarginSection() {
        this.sendAction('setEditMode', true);
      },
      confirmUpdate: function confirmUpdate() {
        return this.get('confirmationPromise')(true);
      },
      cancelUpdate: function cancelUpdate() {
        return this.get('confirmationPromise')(false);
      },
      goToActivity: function goToActivity(activityId) {
        this.sendAction('goToActivity', activityId);
      },
      goToOptions: function goToOptions(activityId, optionId) {
        this.sendAction('goToOptions', activityId, optionId);
      },
      cancelEdit: function cancelEdit(e) {
        this.setFormDirty(false);
        this.sendAction('cancel');
      },
      saveMargin: function saveMargin() {
        var _this2 = this;

        this.validateBaseMargin().then(function () {
          return _this2.confirmationCheck().then(function (confirmed) {
            _this2.set('showConfirmationModal', false);
            _this2.set('confirmationPromise', null);
            if (confirmed) {
              return _this2.validateProductTicketTypeMargin();
            } else {
              return false;
            }
          });
        }).then(function (confirmed) {
          if (confirmed) {
            _this2.set('errors', undefined);
            _this2.setFormDirty(false);
            _this2.sendAction('saveMarginSettings');
          }
        }).catch(function (errRes) {
          _this2.set('errors', errRes);
        });
      }
    }
  });
});