define('lxso/routes/activity/customer-service/customer-contact-point', ['exports', 'lodash/array', 'lxso/utils/form-validator', 'lxso/utils/omniture'], function (exports, _array2, _formValidator, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    resetScroll: false,

    i18n: Ember.inject.service(),
    staticData: Ember.inject.service('static-data'),

    omniture: {
      pageName: 'page.LX-Supply.NewCustomerServiceInformation'
    },

    deactivate: function deactivate() {
      if (this.store.peekRecord('supplier-branch/supplier-support', 1)) {
        this.store.unloadRecord(this.store.peekRecord('supplier-branch/supplier-support', 1));
      }

      if (this.store.peekRecord('supplier-branch/supplier-support', -1)) {
        this.store.unloadRecord(this.store.peekRecord('supplier-branch/supplier-support', -1));
      }
    },
    setupController: function setupController() {
      // gets the country code array from the static-data service
      var countryCodeSource = this.get('staticData').getCountryCodes();

      this.controller.set('toggle24Hours', true);

      //Country code uses the typeahead with its own validation
      var countryCodeValidate = function countryCodeValidate(arr, query, selected) {
        //valid cases: has a selected item, or length is 0
        //selected is the list element the user clicked
        //query is the stuff the user typed in the box
        if (query.length > 0 && Object.keys(selected).length > 0 || query.length === 0) {
          return false;
        } else {
          return true;
        }
      };

      this.controller.set('countryCodeValidate', countryCodeValidate);

      countryCodeSource.forEach(function (val) {
        val.label = val.phoneCode + ' (' + val.label + ')';
      });

      this.controller.set('country_code', '');
      this.controller.set('countryCodeSource', countryCodeSource);

      this.controller.set('activity', this.modelFor('activity'));
    },


    actions: {
      cancel: function cancel() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.NewCustServInfoCancel');
        var activityId = this.controller.get('activity.id');
        this.replaceWith('activity.customer-service.select-contact', activityId);
      },
      close: function close() {
        this.replaceWith('activity.customer-service', this.controller.get('activity.id'));
      },
      sendOmnitureDataForExistingPoc: function sendOmnitureDataForExistingPoc() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.ExistingUser');
      },


      //24 hours checkbox
      //checked: all days are 24 hours a day
      //unchecked: day hours can be individually edited
      toggleHoursFunction: function toggleHoursFunction() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.24hoursCheckbox');
        this.controller.set('toggle24Hours', !this.controller.get('toggle24Hours'));
        //set each day to openThisDay = true
        if (this.controller.get('toggle24Hours') === false) {
          this.controller.get('newSupplierSupport.hours_of_operation').forEach(function (model) {
            model.set('openThisDay', true); //check all the days when the user toggles 24 hours off
          });
        }
      },
      validateIfInvalid: function validateIfInvalid() {
        var _this = this;

        if (this.controller.get('errors')) {
          var newSupplierSupport = this.controller.get('newSupplierSupport');
          (0, _formValidator.validate)({
            model: newSupplierSupport.get('phone'),
            attributes: ['phone_country_code', 'phone_area_code', 'phone', 'phone_ext', 'phone_usage']
          }, {
            model: newSupplierSupport,
            attributes: ['email']
          }).then(function () {
            return _this.controller.set('errors', undefined);
          }).catch(function (errors) {
            return _this.controller.set('errors', errors);
          });
        }
      },
      setDirty: function setDirty() {
        this.controller.set('dirty', true);
      },
      toggleDay: function toggleDay(day) {
        //called when checking/unchecking a day checkbox
        day.set('openThisDay', !day.get('openThisDay'));
      },


      //call this action to persist the usage dropdown's selection
      setPhoneUsage: function setPhoneUsage(value) {
        this.controller.set('newSupplierSupport.phone.phone_usage', value);
      },


      //call this action to persist the toll type selection
      setTollType: function setTollType(value) {
        this.controller.set('newSupplierSupport.phone.phone_toll', value);
      },
      updatedTypeahead: function updatedTypeahead(item) {
        this.controller.set('newSupplierSupport.phone.phone_country_code', item.phoneCode);
      },
      addContactPoint: function addContactPoint() {
        var _this2 = this;

        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.NewCustServInfoContinue');
        var route = this;
        var controller = route.controller;
        var activity = route.controller.get('activity');
        var supplierBranch = this.controller.get('supplierBranch');
        var currentSupplierSupport = [];
        var newSupplierSupport = route.controller.get('newSupplierSupport');

        controller.set('saving', true);

        //push the new Supplier Support contact point to supplierSupport
        // only once we're at the save step
        var supplierSupport = [];
        var postSupplierIdArr = [];

        supplierBranch.get('supplier_support').forEach(function (val) {
          if (val.id) {
            currentSupplierSupport.push(val);
          }
        });

        currentSupplierSupport.forEach(function (val) {
          if (val.id) {
            supplierSupport.push(parseInt(val.id));
          }
        });

        currentSupplierSupport.pushObject(newSupplierSupport);

        var supplierSupportArray = currentSupplierSupport.toArray();
        this.controller.set('dirty', false);

        (0, _formValidator.validate)({
          model: newSupplierSupport.get('phone'),
          attributes: ['phone_country_code', 'phone_area_code', 'phone', 'phone_ext', 'phone_usage']
        }, {
          model: newSupplierSupport,
          attributes: ['email']
        }).then(function () {
          var updateSupplierBranch = supplierBranch.updateSupplierSupport({
            supplier_support: supplierSupportArray
          }).then(function (resp) {
            resp.supplier_support.forEach(function (val) {
              postSupplierIdArr.push(val.support_id);
            });

            var dif = _array2.default.difference(postSupplierIdArr, supplierSupport);
            var ids = _this2.controller.get('activity.customer_support_ids') ? _this2.controller.get('activity.customer_support_ids') : [];

            if (dif.length === 1) {
              ids.push(dif[0]);
            }

            _this2.controller.set('activity.customer_support_ids', ids);
            _this2.controller.get('activity').updateCustomerService({ 'customer_support_ids': ids });
          });
          return updateSupplierBranch;
        }).then(function () {
          var supplierBranch = _this2.controller.get('activity.supplier_branch_full');
          return supplierBranch;
        }).then(function (resp) {
          var reload = resp.reload();
          return reload;
        }).then(function () {
          route.replaceWith('activity.customer-service', activity.get('id'));
        }).catch(function (reason) {
          controller.set('saving', false);
          controller.set('errors', reason);
          Ember.Logger.error('Something went wrong: ', reason);
        });
      },
      didTransition: function didTransition() {
        var _this3 = this;

        //for tests
        window.pageName = 'newCustomerContactPoint';

        this.controller.set('newSupplierSupport', null);
        this.controller.set('supplierBranch', null);

        // Create a new support fragment
        var newSupplierSupport = this.store.createRecord('supplier-branch/supplier-support', {
          'id': 1,
          'address': this.store.createFragment('supplier-branch/supplier-support/address'),
          'phone': this.store.createFragment('supplier-branch/supplier-support/phone')
        });

        this.controller.set('newSupplierSupport', newSupplierSupport);

        this.store.find('supplier-branch', this.controller.get('activity').get('supplier_branch').get('supplier_branch_id')).then(function (resp) {
          _this3.controller.set('supplierBranch', resp);
          _this3.controller.set('supplierSupport', resp.get('supplier_support'));
        });

        ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'].forEach(function (value) {
          newSupplierSupport.get('hours_of_operation').createFragment({
            day: value,
            from: _this3.store.createFragment('supplier-branch/supplier-support/time'),
            to: _this3.store.createFragment('supplier-branch/supplier-support/time')
          });
        });

        this.controller.set('errors', undefined);
        this.controller.set('saving', false);
      },
      willTransition: function willTransition() {
        //for tests
        delete window.pageName;
      }
    }
  });
});