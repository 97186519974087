define('lxso/routes/activity/option-group/group', ['exports', 'lodash/array', 'lxso/mixins/example-modal', 'lxso/mixins/feature-flag', 'lxso/mixins/option-group/option-group-v4', 'lxso/mixins/option-group/price-v3', 'lxso/mixins/option-group/price-v4', 'lxso/constants/option-group', 'lxso/mixins/publish-api', 'lxso/constants/status-v4'], function (exports, _array2, _exampleModal, _featureFlag, _optionGroupV, _priceV, _priceV2, _optionGroup, _publishApi, _statusV) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PUBLISHED_NEEDED_ATTRS = Ember.A(['shortTitle', 'description']);

  exports.default = Ember.Route.extend(_featureFlag.default, _exampleModal.default, _optionGroupV.default, _priceV.default, _priceV2.default, _publishApi.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    featureFlags: Ember.inject.service(),
    routeHistory: Ember.inject.service(),
    publishValidation: Ember.inject.service('publish-validation'),
    languageEnum: Ember.inject.service('language-enum'),
    nameInHeader: Ember.inject.service(),
    staticData: Ember.inject.service(),
    netPrice: Ember.inject.service(),
    optionGroupService: Ember.inject.service(),
    responseErrors: Ember.inject.service(),
    featureName: 'OptionGroupV4',
    omniture: {
      pageName: 'page.LX-Supply.OptionGroup'
    },
    queryParams: {
      clone: {
        refreshModel: true
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
    },
    model: function model(params, transition) {
      var activityId = transition.params.activity.id;
      var optionGroupId = params.optionGroupId;
      var isNewOptionGroup = optionGroupId === 'new' || params.clone === 'true';
      var activity = this.modelFor('activity');
      var ticketTypesV3 = void 0;
      var optionGroup = void 0;
      var v3OptionGroup = void 0;
      var ticketTypeRestrictions = void 0;
      if (optionGroupId === 'new') {
        optionGroup = this.store.createRecord('option-group', this.getDefaultOptionGroupRecord(activityId, activity));
      } else if (Number.isFinite(parseInt(optionGroupId))) {
        var optionGroupIdInt = parseInt(optionGroupId);
        if (params.clone === 'true') {
          optionGroup = this.cloneOptionGroup(optionGroupIdInt);
        } else {
          optionGroup = this.getOptionGroup(optionGroupIdInt);
          if (activity.get('migrationStatus.price')) {
            ticketTypeRestrictions = this.store.findRecord('option-group-ticket-type-restrictions', optionGroupIdInt);
          } else {
            v3OptionGroup = this.getV3OptionGroup(optionGroupIdInt);
            ticketTypesV3 = this.store.peekRecord('activity', activityId).get('ticketTypes');
          }
        }
      } else {
        this.transitionToRouteNotFound(transition);
      }
      return Ember.RSVP.hash({
        optionGroup: optionGroup,
        v3OptionGroup: v3OptionGroup,
        isNewOptionGroup: isNewOptionGroup,
        activity: activity,
        ticketTypesV3: ticketTypesV3,
        v4PriceGroups: this.getV4PriceGroups(optionGroup, activity, isNewOptionGroup),
        ticketTypeMargins: this.getTicketTypeMargins(optionGroup, activity, isNewOptionGroup),
        ticketTypeRestrictions: ticketTypeRestrictions
      });
    },
    cloneOptionGroup: function cloneOptionGroup(optionGroupId) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.getOptionGroup(optionGroupId).then(function (offerToBeCloned) {
          var cloneOptionGroupRecord = _this.cloneOptionGroupRecord(offerToBeCloned);
          resolve(cloneOptionGroupRecord);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    cloneOptionGroupRecord: function cloneOptionGroupRecord(existing) {
      var cloneOptionGroupRecord = {
        activityId: existing.get('activityId'),
        shortTitle: existing.get('shortTitle'),
        description: existing.get('description'),
        status: existing.get('status'),
        attributes: {
          autoRenewRateEnabled: existing.get('attributes.autoRenewRateEnabled'),
          availabilityType: existing.get('attributes.availabilityType'),
          durationInMinutes: existing.get('attributes.durationInMinutes'),
          mealType: existing.get('attributes.mealType'),
          pricesByVolume: existing.get('attributes.pricesByVolume'),
          pricingIngestionEnabled: existing.get('attributes.pricingIngestionEnabled'),
          scheduleType: existing.get('attributes.scheduleType'),
          validityDays: existing.get('attributes.validityDays'),
          durationDaysPart: existing.get('attributes.durationDaysPart'),
          durationHoursPart: existing.get('attributes.durationHoursPart'),
          durationMinutesPart: existing.get('attributes.durationMinutesPart')
        },
        bookingCutoff: {
          cutoffType: existing.get('bookingCutoff.cutoffType'),
          bookingCutoffInHours: existing.get('bookingCutoff.bookingCutoffInHours')
        },
        cancellationPolicy: {
          policyType: existing.get('cancellationPolicy.policyType'),
          cutoffInHours: existing.get('cancellationPolicy.cutoffInHours')
        },
        pickupEnabled: existing.get('pickupEnabled'),
        pickupInfo: {
          pickupType: existing.get('pickupInfo.pickupType')
        },
        offers: []
      };
      return this.store.createRecord('option-group', cloneOptionGroupRecord);
    },
    getV4PriceGroups: function getV4PriceGroups(optionGroup, activity, isNewOptionGroup) {
      var _this2 = this;

      if (activity.get('migrationStatus.price') && !isNewOptionGroup) {
        return optionGroup.then(function (optionGroup) {
          var queryOptions = _this2.constructPriceGroupQueryOptions(optionGroup);
          return _this2.store.query('price-group-v4', queryOptions);
        });
      } else {
        return null;
      }
    },
    constructPriceGroupQueryOptions: function constructPriceGroupQueryOptions(optionGroup) {
      var offerStatusesForPricing = [_statusV.default.ACTIVE, _statusV.default.SUSPENDED];
      return {
        optionGroupId: optionGroup.get('id'),
        availabilityType: optionGroup.get('attributes.availabilityType'),
        scheduleType: optionGroup.get('attributes.scheduleType'),
        offerIds: optionGroup.get('offers').filter(function (optionRow) {
          return offerStatusesForPricing.includes(optionRow.get('status'));
        }).map(function (offer) {
          return offer.get('offerIds');
        }).flat()
      };
    },
    getTicketTypeMargins: function getTicketTypeMargins(optionGroup, activity, isNewOptionGroup) {
      var _this3 = this;

      if (isNewOptionGroup) {
        return null;
      } else {
        return optionGroup.then(function (og) {
          if (og.get('offers.length') && activity.get('migrationStatus.price')) {
            var offerId = og.get('offers').objectAt(0).get('offerIds')[0];
            return _this3.get('netPrice').load(offerId);
          }
        });
      }
    },
    closeTicketsSection: function closeTicketsSection() {
      this.controllerFor('activity.option-group').set('isTicketsOpen', false);
    },
    setupController: function setupController(controller, model) {
      var sectionToEdit = this.get('optionGroupService').getSectionToEdit();
      var optionGroupModel = this.modelFor('activity.option-group');
      var platform = optionGroupModel.platform,
          languageEnum = optionGroupModel.languageEnum,
          ticketTypesV4 = optionGroupModel.ticketTypesV4;

      controller.setProperties({
        activity: model.activity,
        optionGroup: model.optionGroup,
        v3OptionGroup: model.v3OptionGroup,
        ticketTypesV3: model.ticketTypesV3,
        ticketTypeRestrictions: model.ticketTypeRestrictions,
        platform: platform,
        languageEnum: languageEnum,
        ticketTypesV4: ticketTypesV4,
        v4PriceGroups: model.v4PriceGroups ? model.v4PriceGroups.toArray() : Ember.A([]),
        isNew: model.isNewOptionGroup,
        isOptionGroupDetailsOpen: model.isNewOptionGroup || sectionToEdit === _optionGroup.SECTIONS.DETAILS,
        isOptionGroupDetailsDirty: false,
        savingOptionGroupDetails: false,
        isOptionGroupOptionsOpen: sectionToEdit === _optionGroup.SECTIONS.OPTIONS,
        isOptionGroupOptionsEnabled: !model.isNewOptionGroup,
        isOptionGroupOptionsDirty: false,
        savingOptionGroupOptions: false,
        isOptionGroupPriceV3Enabled: false,
        isOptionGroupPriceV3Open: sectionToEdit === _optionGroup.SECTIONS.V3_PRICING,
        isOptionGroupPriceV3Dirty: false,
        hasExistingPrices: false,
        updatingPriceWithIngestion: false,
        isOptionGroupDisabled: false, //Use this flag if we need to disable editing of OG in any cases(Eg: Report Reader)
        SECTIONS: _optionGroup.SECTIONS,
        isOptionGroupCancelModalOpen: false,
        isOptionGroupPriceV4Enabled: false,
        optionGroupParentControllerModel: this.controllerFor('activity.option-group')
      });
      this.closeTicketsSection();
      var optionGroupId = model.isNewOptionGroup ? 'new' : parseInt(model.optionGroup.get('id'));
      this.controllerFor('activity.option-group').set('optionGroupId', optionGroupId);
      this._setLastUpdated(null);
      //for new group creation we set lastUpdated flag at option-group level, which should be cleared on route load.
      this.setEditMode(model.isNewOptionGroup);
      if (model.optionGroup) {
        this.scrollToSelectedOptionGroup();
        // When sections like tickets are placed above, we can use scroll to show optionGroup in visible area of screen.
        if (model.activity.get('migrationStatus.price')) {
          this.setUpPriceV4Section();
        } else {
          this.setUpPriceV3Section();
        }
        this.controller.set('isHoursOfOpGT', this.isHoursOfOpGT());
      }
    },
    isHoursOfOpGT: function isHoursOfOpGT() {
      var isGTBranch = this.get('session.session.authenticated.selectedBranch.supplier_branch_type') === 'GT';
      var isHrsOfOpSchedule = this.controller.get('optionGroup.attributes.scheduleType') === _optionGroup.SCHEDULE_TYPES.HOURS_OF_OPERATION;
      return isGTBranch && isHrsOfOpSchedule;
    },
    setEditMode: function setEditMode(isNewOptionGroup) {
      if (isNewOptionGroup) {
        this.get('optionGroupService').setCrudMode(_optionGroup.CRUD_MODES.CREATE);
      } else if (!this.get('optionGroupService').getCrudMode()) {
        this.get('optionGroupService').setCrudMode(_optionGroup.CRUD_MODES.UPDATE);
      }
      this.controller.set('crudMode', this.get('optionGroupService').getCrudMode());
      this.controller.set('isOptionGroupUpdateEnabled', false);
    },
    transitionToRouteNotFound: function transitionToRouteNotFound(transition) {
      transition.abort();
      var error = new Ember.Error('Invalid OptionGroupId');
      this.intermediateTransitionTo('route-not-found', error);
    },
    getV3OptionGroup: function getV3OptionGroup(optionGroupId) {
      var optionGroup = this.store.peekRecord('option-group', optionGroupId);
      if (optionGroup.get('offers').filter(function (offer) {
        return offer.get('status') === _optionGroup.STATUS.ACTIVE;
      }).length && optionGroup.get('offers').get('content').objectAt(0).get('subOffers.length')) {
        return Ember.RSVP.resolve(this.store.findRecord('offer', optionGroupId, { adapterOptions: { isOfferGroup: true } }));
      } else {
        return null;
      }
    },
    getOptionGroup: function getOptionGroup(optionGroupId) {
      return this.store.findRecord('option-group', optionGroupId, { reload: true });
    },
    updateOptionGroupList: function updateOptionGroupList() {
      var _this4 = this;

      this.store.query('option-group', { activityId: this.controller.get('activity.id') }).then(function (optionGroupList) {
        _this4.controllerFor('activity.option-group').set('optionGroupList', optionGroupList);
      });
    },
    _publishOptionChanges: function _publishOptionChanges(activity, resolveResponse) {
      var _this5 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this5.publishChanges(activity, true).then(function () {
          return resolve(resolveResponse);
        }).catch(function () {
          return reject();
        });
      });
    },
    scrollToSelectedOptionGroup: function scrollToSelectedOptionGroup() {
      Ember.run.later(function () {
        var anchorElement = Ember.$('#option-group-box').offset();
        if (anchorElement) {
          Ember.$('html, body').animate({
            scrollTop: anchorElement.top - 100
          }, 250);
        }
      });
    },
    _saveOptionGroupDetails: function _saveOptionGroupDetails(optionGroup, isNewOptionGroup) {
      var _this6 = this;

      var changedAttributes = Object.keys(optionGroup.changedAttributes());
      optionGroup.save({ adapterOptions: { section: _optionGroup.SECTIONS.DETAILS } }).then(function (optionGroup) {
        _this6.controller.set('isOptionGroupUpdateEnabled', true);
        var hasAttrsToPublishChanged = _array2.default.intersection(changedAttributes, PUBLISHED_NEEDED_ATTRS).length > 0;
        if (hasAttrsToPublishChanged && _this6.controller.get('activity.isActivityPublished')) {
          return _this6._publishOptionChanges(_this6.controller.get('activity'), optionGroup);
        } else {
          return optionGroup;
        }
      }).then(function (response) {
        if (isNewOptionGroup) {
          _this6.updateOptionGroupList();
        }
        _this6.transitionTo('activity.option-group.group', response.id);
        _this6.controller.set('isOptionGroupDetailsOpen', false);
        _this6.controller.set('isOptionGroupOptionsEnabled', true);
        _this6.controller.get('optionGroup').notifyPropertyChange('attributes');
        _this6.controller.set('isOptionGroupDetailsDirty', false);
        _this6.controller.set('isOptionGroupOptionsOpen', true);
        _this6.get('optionGroupService').setSectionToEdit(_optionGroup.SECTIONS.OPTIONS);
      }).finally(function () {
        _this6.controller.set('savingOptionGroupDetails', false);
      });
    },
    _saveOptionGroupDetailsAndOptionsForGTHoursOfOp: function _saveOptionGroupDetailsAndOptionsForGTHoursOfOp(optionGroup, isNewOptionGroup) {
      var _this7 = this;

      //For GT, options section is non editable and we auto create an option while details is saved.
      var changedAttributes = Object.keys(optionGroup.changedAttributes());
      var hasAttrsToPublishChanged = _array2.default.intersection(changedAttributes, PUBLISHED_NEEDED_ATTRS).length > 0;
      var isPublishedActivity = this.controller.get('activity.isActivityPublished');
      var hasExistingOptions = optionGroup.get('offers').filter(function (offerRow) {
        return offerRow.get('offerIds') && offerRow.get('offerIds').length > 0;
      }).length > 0;
      optionGroup.save({ adapterOptions: { section: _optionGroup.SECTIONS.DETAILS } }).then(function (optionGroup) {
        if (!hasExistingOptions) {
          optionGroup.get('offers').createFragment(_this7.getDefaultOfferDetailRecord());
          return optionGroup.save({ adapterOptions: { section: _optionGroup.SECTIONS.OPTIONS } });
        } else {
          return optionGroup;
        }
      }).then(function (optionGroup) {
        _this7.controller.set('isOptionGroupUpdateEnabled', true);
        if (isPublishedActivity && (hasAttrsToPublishChanged || !hasExistingOptions)) {
          return _this7._publishOptionChanges(_this7.controller.get('activity'), optionGroup);
        } else {
          return optionGroup;
        }
      }).then(function () {
        var v3OptionGroup = _this7.controller.get('v3OptionGroup');
        var optionGroupId = _this7.controller.get('optionGroup.id');
        if (!v3OptionGroup) {
          return _this7.store.findRecord('offer', optionGroupId, { adapterOptions: { isOfferGroup: true } });
        } else {
          return v3OptionGroup;
        }
      }).then(function (v3OptionGroup) {
        if (isNewOptionGroup) {
          _this7.updateOptionGroupList();
        }
        _this7.setUpPriceV3Section();
        _this7.controller.set('v3OptionGroup', v3OptionGroup);
        _this7.controller.set('isOptionGroupDetailsOpen', false);
        _this7.controller.set('isOptionGroupOptionsOpen', false);
        _this7.get('optionGroupService').setSectionToEdit(_optionGroup.SECTIONS.V3_PRICING);
        _this7.controller.get('optionGroup').notifyPropertyChange('offers');
        _this7.controller.get('optionGroup').notifyPropertyChange('attributes');
        _this7.controller.set('isOptionGroupOptionsDirty', false);
        _this7.controller.set('isOptionGroupDetailsDirty', false);
        _this7.controller.set('isOptionGroupOptionsEnabled', true);
        _this7.transitionTo('activity.option-group.group', v3OptionGroup.get('id'));
      }).finally(function () {
        _this7.controller.set('savingOptionGroupDetails', false);
      });
    },
    _setLastUpdated: function _setLastUpdated(lastUpdated) {
      this.controllerFor('activity.option-group').set('lastUpdated', lastUpdated);
    },


    actions: {
      saveOptionGroupDetails: function saveOptionGroupDetails(optionGroup) {
        this.controller.set('savingOptionGroupDetails', true);
        var isHoursOfOpGT = this.isHoursOfOpGT();
        this.controller.set('isHoursOfOpGT', isHoursOfOpGT);
        var isNewOptionGroup = this.controller.get('isNew');
        if (isNewOptionGroup) {
          this._setLastUpdated(_optionGroup.SECTIONS.OPTION_GROUP);
        } else {
          this._setLastUpdated(_optionGroup.SECTIONS.DETAILS);
        }
        if (isHoursOfOpGT) {
          this._saveOptionGroupDetailsAndOptionsForGTHoursOfOp(optionGroup, isNewOptionGroup);
        } else {
          this._saveOptionGroupDetails(optionGroup, isNewOptionGroup);
        }
      },
      saveOptionGroupOptions: function saveOptionGroupOptions(optionGroup) {
        var _this8 = this;

        this._setLastUpdated(_optionGroup.SECTIONS.OPTIONS);
        this.controller.set('savingOptionGroupOptions', true);
        var changedAttrs = optionGroup.changedAttributes();
        return optionGroup.save({ adapterOptions: { section: _optionGroup.SECTIONS.OPTIONS } }).then(function (optionGroup) {
          _this8.controller.set('isOptionGroupUpdateEnabled', true);
          if (_this8.controller.get('activity.isActivityPublished')) {
            return _this8._publishOptionChanges(_this8.controller.get('activity'), optionGroup);
          } else {
            return optionGroup;
          }
        }).then(function () {
          var v3OptionGroup = _this8.controller.get('v3OptionGroup');
          var optionGroupId = _this8.controller.get('optionGroup.id');
          if (!v3OptionGroup) {
            return _this8.store.findRecord('offer', optionGroupId, { adapterOptions: { isOfferGroup: true } });
          } else {
            return v3OptionGroup;
          }
        }).then(function (v3OptionGroup) {
          _this8.controller.set('v3OptionGroup', v3OptionGroup);
          var optionGroup = _this8.controller.get('optionGroup');
          if (changedAttrs && changedAttrs.offers && _this8.controller.get('activity.migrationStatus.price')) {
            var queryOptions = _this8.constructPriceGroupQueryOptions(optionGroup);
            return _this8.store.query('price-group-v4', queryOptions);
          } else {
            return _this8.controller.get('v4PriceGroups');
          }
        }).then(function (v4PriceGroups) {
          _this8.controller.set('v4PriceGroups', v4PriceGroups ? v4PriceGroups.toArray() : Ember.A([]));
          _this8.controller.set('isOptionGroupDetailsOpen', false);
          _this8.controller.set('isOptionGroupOptionsOpen', false);
          _this8.get('optionGroupService').setSectionToEdit(_optionGroup.SECTIONS.V3_PRICING);
          _this8.setUpPriceV3Section();
          _this8.setUpPriceV4Section();
          _this8.controller.get('optionGroup').notifyPropertyChange('offers');
          _this8.controller.set('isOptionGroupOptionsDirty', false);
        }).finally(function () {
          _this8.controller.set('savingOptionGroupOptions', false);
        });
      },
      continueOptionGroupOptions: function continueOptionGroupOptions() {
        this.controller.set('isOptionGroupOptionsOpen', false);
        this.get('optionGroupService').setSectionToEdit(_optionGroup.SECTIONS.V3_PRICING);
        this.setUpPriceV3Section();
      },
      updateAndSaveOptionGroup: function updateAndSaveOptionGroup(optionGroup) {
        this._setLastUpdated(_optionGroup.SECTIONS.OPTION_GROUP);
        var isAddMode = this.get('optionGroupService').getCrudMode() === _optionGroup.CRUD_MODES.CREATE;
        var translationBaseKey = 'pages.optionGroup.optionGroupSection.notifications';
        var translationKey = isAddMode ? 'addSuccess' : 'updateSuccess';
        this.closeTicketsSection();
        this.get('notifications').success(this.get('i18n').t(translationBaseKey + '.' + translationKey));
        this.transitionTo('activity.option-group');
      },
      cancelOptionGroup: function cancelOptionGroup(optionGroup) {
        this.controller.set('isOptionGroupCancelModalOpen', true);
        this.controller.get('v4PriceGroups').forEach(function (priceGroup) {
          priceGroup.rollbackAttributes();
        });
      },
      handleCancelYesClick: function handleCancelYesClick() {
        var isAddMode = this.get('optionGroupService').getCrudMode() === _optionGroup.CRUD_MODES.CREATE;
        var isNewOptionGroup = this.controller.get('isNew');
        this.get('responseErrors').clearErrors();
        if (isAddMode && !isNewOptionGroup) {
          this._setLastUpdated(_optionGroup.SECTIONS.OPTION_GROUP);
          this.get('notifications').success(this.get('i18n').t('pages.optionGroup.optionGroupSection.notifications.cancel'));
        }
        this.transitionTo('activity.option-group');
      },
      handleCancelNoClick: function handleCancelNoClick() {
        this.controller.set('isOptionGroupCancelModalOpen', false);
      },
      setLastUpdated: function setLastUpdated(lastUpdated) {
        this._setLastUpdated(lastUpdated);
      },
      publishOptionChanges: function publishOptionChanges() {
        this._publishOptionChanges(this.controller.get('activity'), {});
      },
      enableOptionGroupUpdateButton: function enableOptionGroupUpdateButton(shouldEnable) {
        //To enable "Update & save option group" button at the bottom of page once we made any changes in OG.
        this.controller.set('isOptionGroupUpdateEnabled', shouldEnable);
      }
    },

    resetController: function resetController(controller, isExiting) {
      this.controllerFor('activity.option-group').set('optionGroupId', null);
      if (isExiting) {
        this.get('optionGroupService').setCrudMode(null);
        this.get('optionGroupService').setSectionToEdit(null);
        this.controllerFor('activity.option-group').set('optionGroupId', null);
        this._cancelEditingPg();

        var optionGroup = controller.get('optionGroup');
        if (optionGroup && optionGroup.get('hasDirtyAttributes')) {
          optionGroup.rollbackAttributes();
        }

        var v3OptionGroup = controller.get('v3OptionGroup');
        if (v3OptionGroup && v3OptionGroup.get('hasDirtyAttributes')) {
          v3OptionGroup.rollbackAttributes();
        }

        controller.set('clone', undefined);
        controller.set('editingPg', false);
        controller.set('ticketTypesV3', undefined);
      }
    }
  });
});