define('lxso/models/promotion', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'lxso/utils/validation'], function (exports, _emberData, _attributes, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var disablePercentageValidations = Ember.computed('model.discountTypeUI', function () {
    return this.get('model.discountTypeUI') !== 'Percentage';
  });

  var notSplitFunded = Ember.computed('model.fundType', function () {
    return this.get('model.fundType') !== 'Expedia and Supplier Funded';
  });

  var totalDiscountLessThan75 = Ember.computed('model.fundType', 'model.discountTypeUI', function () {
    return this.get('model.discountTypeUI') !== 'Percentage' || this.get('model.totalDiscount') < 75;
  });

  var travelEndDateAfter = Ember.computed('model.isNew', 'model.travelStartDate', 'model.travelEndDate', function () {
    return this.get('model.travelStartDate');
  });

  var Validations = (0, _validation.build)({
    name: [(0, _validation.validator)('presence', {
      presence: true,
      descriptionKey: 'pages.promotion.promotionName.name'
    })],
    bookingStartDate: [(0, _validation.validator)('presence', {
      presence: true
    }), (0, _validation.validator)('valid-date', {
      lazy: true
    }), (0, _validation.validator)('promotion-custom-date', {
      after: 'now',
      messageKey: 'errors.customMessages.promotion.dates.bookingStartDate',
      dependantKeys: ['model.bookingEndDate'],
      disabled: Ember.computed.not('model.isNew')
    })],

    bookingEndDate: [(0, _validation.validator)('presence', {
      presence: true
    }), (0, _validation.validator)('valid-date', {
      lazy: true
    }), (0, _validation.validator)('promotion-custom-date', {
      after: 'now',
      before: Ember.computed.readOnly('model.travelEndDate'),
      messageKey: 'errors.customMessages.promotion.dates.bookingEndDate',
      dependantKeys: ['model.bookingStartDate']
    }), (0, _validation.validator)('promotion-custom-date', {
      after: Ember.computed.readOnly('model.bookingStartDate'),
      messageKey: 'errors.customMessages.promotion.dates.bookingEndDate',
      dependantKeys: ['model.bookingStartDate']
    })],

    travelStartDate: [(0, _validation.validator)('presence', {
      presence: true
    }), (0, _validation.validator)('valid-date', {
      lazy: true
    }), (0, _validation.validator)('promotion-custom-date', {
      after: Ember.computed.readOnly('model.bookingStartDate'),
      messageKey: 'errors.customMessages.promotion.dates.travelStartDate',
      dependantKeys: ['model.bookingStartDate', 'model.travelEndDate'],
      disabled: Ember.computed.not('model.isNew')
    })],

    travelEndDate: [(0, _validation.validator)('presence', {
      presence: true
    }), (0, _validation.validator)('valid-date', {
      lazy: true
    }), (0, _validation.validator)('promotion-custom-date', {
      after: travelEndDateAfter,
      messageKey: 'errors.customMessages.promotion.dates.travelEndDate',
      dependantKeys: ['model.travelStartDate']
    })],

    audiences: [(0, _validation.validator)('array-length', {
      gte: 1,
      messageKey: 'errors.customMessages.promotion.audiences'
    })],

    shoppingPaths: [(0, _validation.validator)('array-length', {
      gte: 1,
      messageKey: 'errors.customMessages.promotion.shoppingPath'
    })],

    totalDiscount: [(0, _validation.validator)('presence', {
      presence: true
    }), (0, _validation.validator)('number', {
      gte: 5,
      allowString: true,
      allowBlank: true,
      disabled: disablePercentageValidations,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.discountGTFive'
    }), (0, _validation.validator)('number', {
      gt: 0,
      allowString: true,
      allowBlank: true,
      disabled: !disablePercentageValidations,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.discountGTZero'
    }), (0, _validation.validator)('custom-promotion-discount-by-fund-type', {
      isAbsolute: disablePercentageValidations,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.percentageMoreThan75',
      disabled: totalDiscountLessThan75,
      dependantKeys: ['model.totalDiscount', 'model.fundType'],
      lazy: false
    }), (0, _validation.validator)('decimal-place-validation', {
      decimalLength: 2,
      allowBlank: true,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.twoDecimalPlacesAllowed'
    }), (0, _validation.validator)('custom-campaign-promotion-discount', {
      messageKey: 'errors.customMessages.promotion.discount'
    })],

    expediaFund: [(0, _validation.validator)('presence', {
      presence: true,
      disabled: notSplitFunded
    }), (0, _validation.validator)('custom-promotion-details-fund', {
      isAbsolute: disablePercentageValidations,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.discountGTZeroSplit',
      disabled: notSplitFunded,
      dependantKeys: ['model.supplierFund', 'model.totalDiscount'],
      lazy: false
    }), (0, _validation.validator)('number', {
      gt: 0,
      allowString: true,
      disabled: notSplitFunded,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.discountGTZeroSplit'
    }), (0, _validation.validator)('number', {
      lte: 100,
      allowString: true,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.percentageGTHundredSplit',
      disabled: disablePercentageValidations,
      lazy: false
    })],

    bulkUploadFileName: [(0, _validation.validator)('presence', {
      presence: true,
      messageKey: 'pages.promotion.bulkUpload.promotionDetails.fileRequired'
    })],

    supplierFund: [(0, _validation.validator)('presence', {
      presence: true,
      disabled: notSplitFunded
    }), (0, _validation.validator)('custom-promotion-details-fund', {
      isAbsolute: disablePercentageValidations,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.discountGTZeroSplit',
      disabled: notSplitFunded,
      dependantKeys: ['model.expediaFund', 'model.totalDiscount']
    }), (0, _validation.validator)('number', {
      gt: 0,
      allowString: true,
      disabled: notSplitFunded,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.discountGTZeroSplit'
    }), (0, _validation.validator)('number', {
      lte: 100,
      allowString: true,
      messageKey: 'pages.promotion.promotionDetails.validationMsg.percentageGTHundredSplit',
      disabled: disablePercentageValidations
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    promotionId: _emberData.default.attr('number'),
    supplierBranchId: _emberData.default.attr('number'),
    status: _emberData.default.attr('string', { defaultValue: 'Active' }),
    fundType: _emberData.default.attr('string', { defaultValue: 'Supplier Funded' }),
    funders: _emberData.default.attr('array'),
    products: _emberData.default.attr('string'),
    bulkUploadFileName: _emberData.default.attr('string'),
    discountTypeUI: _emberData.default.attr('string', { defaultValue: 'Percentage' }),
    expediaFundType: _emberData.default.attr('string'),
    supplierFundType: _emberData.default.attr('string'),
    totalDiscount: _emberData.default.attr('number'),
    expediaFund: _emberData.default.attr('number'),
    supplierFund: _emberData.default.attr('number'),
    audiences: _emberData.default.attr('array', { defaultValue: [1, 2] }),
    shoppingPaths: _emberData.default.attr('array', { defaultValue: [1, 2] }),
    normalizedAudiences: _emberData.default.attr('array'),
    normalizedShoppingPath: _emberData.default.attr('array'),
    promotionDetails: (0, _attributes.fragment)('fragments/promotion/promotion-details'),
    i18n: Ember.inject.service(),
    travelStartDate: _emberData.default.attr('date-string'),
    travelEndDate: _emberData.default.attr('date-string'),
    bookingStartDate: _emberData.default.attr('date-string'),
    bookingEndDate: _emberData.default.attr('date-string'),
    freeLXOptionSelected: _emberData.default.attr('boolean'),
    promotionType: _emberData.default.attr('string', { defaultValue: 'Standard' }),
    campaignId: _emberData.default.attr('number'),
    campaignName: _emberData.default.attr('string')
  });
});