define('lxso/routes/activity/publish', ['exports', 'lxso/config/environment', 'lxso/mixins/publish-api'], function (exports, _environment, _publishApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_publishApi.default, {
    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    publishValidation: Ember.inject.service('publish-validation'),
    session: Ember.inject.service('session'),
    featureFlags: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.ActivityPublish'
    },

    model: function model() {
      var activity = this.modelFor('activity');
      return this.get('publishValidation').getPublishValidation(activity);
    },
    afterModel: function afterModel(modelResult) {
      if (this.get('featureFlags').checkEnabled('V3Pricing')) {
        for (var key in modelResult) {
          if (modelResult[key] === false) {
            if (modelResult.hasTicketTypes) {
              this.transitionTo('activity.tour-options');
            } else {
              this.transitionTo('activity.ticket-setup');
            }
          }
        }
      }
    },
    setupController: function setupController(ctrl, publishValidation) {
      this.controller.set('isContentCertified', false);
      this.controller.set('activity', this.modelFor('activity'));
      this.controller.set('errors', undefined);
      this.publishCheckSetup(publishValidation);
    },
    saveFailure: function saveFailure() {
      this.controller.set('errors', true);
    },
    saveSuccess: function saveSuccess(activityId) {
      this.transitionTo('confirmation', activityId);
    },
    publishCheckSetup: function publishCheckSetup(publishValidation) {
      this.controller.set('isActivityPublished', this.controller.get('activity').get('isActivityPublished'));
      var isGroundTransfer = this.controller.get('activity.isViewOnlyForGroundTransfer');
      var invalidPublishable = 0;
      for (var key in publishValidation) {
        var isSkip = isGroundTransfer && (key === 'hasHighlights' || key === 'hasInclusions' || key === 'hasRedemptionInstructions' || key === 'hasHealthAndSafetyInfo');
        var isSkipHealthAndSafety = key === 'hasHealthAndSafetyInfo' && !this.get('featureFlags').checkEnabled('HealthAndSafety');
        if (!isSkip && publishValidation[key] === false && !isSkipHealthAndSafety) {
          invalidPublishable++;
        }
      }
      this.controller.set('isPublishable', invalidPublishable === 0);
      var overviewValidation = publishValidation.hasDescription && (publishValidation.hasHighlights || isGroundTransfer);
      this.controller.set('validOverview', overviewValidation);
      var detailsValidation = (publishValidation.hasInclusions || isGroundTransfer) && publishValidation.hasKnowBeforeYouBook && publishValidation.hasKnowBeforeYouGo;
      this.controller.set('validDetails', detailsValidation);
      var photosValidation = publishValidation.hasPhoto || this.controller.get('activity.assets.content').length > 0;
      this.controller.set('validPhotos', photosValidation);
      var healthAndSafetyValidation = publishValidation.hasHealthAndSafetyInfo;
      if (this.controller.get('activity.isHealthAndSafetyEnabled')) {
        this.controller.set('validHealthAndSafetyInfo', healthAndSafetyValidation);
      } else {
        this.controller.set('validHealthAndSafetyInfo', true);
      }
      this.controller.set('validLocation', publishValidation.hasLocation);
      var policiesValidation = publishValidation.hasContactInformation && (publishValidation.hasRedemptionInstructions || isGroundTransfer);
      this.controller.set('validPolicies', policiesValidation);
      // this.controller.set('validTicketType', publishValidation.hasTicketTypes);
      // this.controller.set('validPricing', publishValidation.hasPricing);

      if (this.controller.get('validPricing') === false && optionModels.length === 1) {
        var incompleteOptionId = optionModels[0].id;
        this.transitionTo('activity.tour-options.edit', incompleteOptionId);
      }
    },


    actions: {
      publish: function publish() {
        var _this = this;

        var activityId = this.controller.get('activity.id');
        this.controller.set('saving', true);
        this.publishChanges(this.controller.get('activity'), true).then(function () {
          return _this.saveSuccess(activityId);
        }).catch(function () {
          return _this.saveFailure();
        }).finally(function () {
          return _this.controller.set('saving', false);
        });
      },
      toggleContentCertified: function toggleContentCertified() {
        if (this.controller.get('isContentCertified')) {
          this.controller.set('isContentCertified', false);
        } else {
          this.controller.set('isContentCertified', true);
        }
      },
      willTransition: function willTransition() {
        delete window.pageName;
      },
      didTransition: function didTransition() {
        window.pageName = 'ActivityPublish';
      },
      invalidPublish: function invalidPublish() {
        this.get('session').set('data.fromPublish', true);
      }
    }
  });
});