define('lxso/components/from-to-time/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    classNameBindings: ['isV4OptionGroup:from-to-time-v4-option-group'],
    duration: Ember.computed('fromDisplay', 'toDisplay', 'invalid', 'selectedDays', 'selectedHours', function () {
      var _this = this;

      var from = this.get('fromDisplay');
      var to = this.get('toDisplay');
      var invalid = this.get('invalid');
      var days = this.get('selectedDays') || 0;
      var isMultiDay = this.get('multiDay');

      //remove after schedule type flag enable
      var selectedHours = this.get('selectedHours') || 0;

      var defaultValues = { hours: '-', minutes: '--' };

      if (this.get('calculateDuration') && from && to && from.indexOf(':') && to.indexOf(':') && !invalid) {
        var fromMinutes = this.getMinutes(from);
        var toMinutes = void 0;
        if (to === '00:00') {
          toMinutes = this.getMinutes('24:00');
        } else {
          toMinutes = this.getMinutes(to);
        }

        var totalMinutes = void 0;
        if (fromMinutes > toMinutes) {
          var duration1 = 1440 - fromMinutes;
          totalMinutes = duration1 + toMinutes;
        } else if (toMinutes > fromMinutes) {
          totalMinutes = toMinutes - fromMinutes;
        } else if (toMinutes === fromMinutes) {
          totalMinutes = 1440;
        }

        Ember.run.next(function () {
          //remove selected hours after schedule type change enabled
          if (isMultiDay) {
            _this.set('durationInMinutes', days * 60 * 24);
          } else {
            _this.set('durationInMinutes', totalMinutes + days * 60 * 24 + selectedHours * 60);
          }
          if (_this.validateIfInvalid) {
            _this.sendAction('validateIfInvalid');
          }
        });

        var durationHours = Math.floor(totalMinutes / 60 + selectedHours);
        var durationMinutes = totalMinutes % 60;
        if (isNaN(durationHours) || isNaN(durationMinutes)) {
          return defaultValues;
        } else {
          return { days: days, hours: durationHours, minutes: durationMinutes };
        }
      } else {
        return defaultValues;
      }
    }),
    toDisplayCalculated: Ember.observer('fromDisplay', 'durationInMinutes', function () {
      if (this.get('calculateToDisplay')) {
        var from = this.get('fromDisplay');
        var durationInMinutes = this.get('durationInMinutes');
        var fromMinutes = this.getMinutes(from);
        var toMinutes = fromMinutes + durationInMinutes;
        if (isFinite(toMinutes)) {
          var toTimeToDisplay = this.getTimeToDisplayFromMinutes(toMinutes);
          this.set('toDisplay', toTimeToDisplay);
        } else {
          this.set('toDisplay', '00:00');
        }
        this.set('nextDay', toMinutes > 24 * 60);
      }
    }),
    nextDay: Ember.computed('fromDisplay', 'toDisplay', function () {
      var from = this.get('fromDisplay');
      var to = this.get('toDisplay');
      // Only check if its the nextDay if we have all time pieces and they are complete
      if (from && to && from.length === 5 && to.length === 5) {
        var fromMinutes = this.getMinutes(from);
        var toMinutes = void 0;
        if (to === '00:00') {
          toMinutes = this.getMinutes('24:00');
        } else {
          toMinutes = this.getMinutes(to);
        }
        return fromMinutes > toMinutes || fromMinutes === toMinutes || from === '00:00' && to === '00:00';
      } else {
        return false;
      }
    }),
    getMinutes: function getMinutes(time) {
      var timePieces = time.split(':');
      return +timePieces[0] * 60 + +timePieces[1];
    },
    getTimeToDisplayFromMinutes: function getTimeToDisplayFromMinutes(durationPlusFromMinutes) {
      var minutes = durationPlusFromMinutes % (24 * 60);
      var hoursPart = parseInt(minutes / 60);
      var minutesPart = minutes % 60;
      return this.getDoubleDigitNumber(hoursPart) + ':' + this.getDoubleDigitNumber(minutesPart);
    },
    getDoubleDigitNumber: function getDoubleDigitNumber(num) {
      return num > 9 ? '' + num : '0' + num;
    },

    actions: {
      fromFormatted: function fromFormatted() {
        this.sendAction('change');
      },
      setValidationErrorOnUpdate: function setValidationErrorOnUpdate(isInvalid) {
        var validationErrors = isInvalid ? Ember.A([this.get('i18n').t('errors.customMessages.offers.invalidTime').string]) : Ember.A([]);
        this.set('startTimeValidationErrors', validationErrors);
      }
    }
  });
});