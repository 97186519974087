define('lxso/helpers/errors-for-overlap', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.errorsForOverlap = errorsForOverlap;
  function errorsForOverlap(params) {
    if (params[1] && params[1][0]) {
      var errorString = params[1][0].split(':;:')[1];
      var errorIndexesArray = params[1][0].split(':;:')[0].split(',');
      var elementIndex = '' + params[2] + params[3];

      if (_lodash.default.includes(errorIndexesArray, elementIndex)) {
        return [errorString];
      }
    }
  }

  exports.default = Ember.Helper.helper(errorsForOverlap);
});