define('lxso/components/options-quick-view/multiple-options-view-schedule/component', ['exports', 'lxso/utils/omniture'], function (exports, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('hideExtraOptions', true);
    },


    actions: {
      showAllSchedules: function showAllSchedules() {
        this.set('hideExtraOptions', false);
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.OptionList.Schedule.ShowMore');
      },
      hideExtraSchedules: function hideExtraSchedules() {
        this.set('hideExtraOptions', true);
      }
    }
  });
});