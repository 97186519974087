define('lxso/components/days-of-week-checkboxes/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      toggleDaysFunction: function toggleDaysFunction(day) {
        day.set('active', !day.get('active'));
      }
    }
  });
});