define('lxso/helpers/array-concat', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arrayConcat = arrayConcat;
  function arrayConcat(arrays) {
    if (!arrays) {
      return;
    }
    var returnArray = new Ember.A([]);
    var builtArray = arrays.reduce(function (previous, current) {
      //es lint preventing clean arrow function with ternary.
      if (Ember.isArray(current)) {
        return previous.concat(current);
      } else {
        return previous;
      }
    }, returnArray);
    return builtArray.length > 0 ? builtArray : null;
  }

  exports.default = Ember.Helper.helper(arrayConcat);
});