define('lxso/helpers/breadcrumbs-status-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.breadcrumbsStatusDisplay = breadcrumbsStatusDisplay;
  function breadcrumbsStatusDisplay(params) {
    var statusValue = params[0];

    var statusMap = new Map([['active', 'active'], ['inactive', 'inactive'], ['deactivated', 'deactivated'], ['suspended', 'suspended mapping'], ['suspended_expedia', 'suspended expedia'], ['suspended_paused', 'suspended paused']]);

    var statusToDisplay = statusMap.get(statusValue);
    return statusToDisplay;
  }

  exports.default = Ember.Helper.helper(breadcrumbsStatusDisplay);
});