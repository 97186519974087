define('lxso/services/endless-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    oldScrollTop: 0,

    initScrollHandler: function initScrollHandler() {
      var _this = this;

      Ember.$('html').addClass('forcedScrollbar');
      Ember.$(window).on('scroll', function () {
        return _this.scrollHandler();
      });
    },
    deactivateEndlessScrolling: function deactivateEndlessScrolling() {
      'use strict';

      Ember.$('html').removeClass('forcedScrollbar');
      Ember.$('html').css('height', '');
      Ember.$(window).off('scroll');
    },
    scrollHandler: function scrollHandler() {
      'use strict';

      var _this2 = this;

      var newScrollTop = Ember.$(window).scrollTop();

      var userHasScrolledDown = function userHasScrolledDown() {
        var innerHeight = window.innerHeight;
        var pageYOffset = document.documentElement.scrollTop || window.pageYOffset;
        var offsetHeight = document.body.offsetHeight;
        return innerHeight + pageYOffset >= offsetHeight;
      };

      var hasScrollTopChange = function hasScrollTopChange() {
        var changeDetected = _this2.get('oldScrollTop') !== newScrollTop;

        if (!changeDetected) {
          var oldHeight = Ember.$('html').height();
          var newHeight = +oldHeight + 1;
          Ember.$('html').height(newHeight);
        }

        return changeDetected;
      };

      if (this.get('hasNotReachedEnd') && userHasScrolledDown() && hasScrollTopChange()) {
        this.set('oldScrollTop', newScrollTop);
        this.get('continueCallback')();
      }
    },
    setScrollHandler: function setScrollHandler(hasNotReachedEnd, continueCallback) {
      'use strict';

      if (!Ember.$('html').hasClass('forcedScrollbar')) {
        this.initScrollHandler();
      }
      this.set('hasNotReachedEnd', hasNotReachedEnd);
      this.set('continueCallback', continueCallback);
      Ember.$(window).scrollTop(this.get('oldScrollTop'));
    }
  });
});