define('lxso/instance-initializers/reset-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* appInstance */{
    Ember.Route.reopen({
      render: function render() {
        this._super.apply(this, arguments);

        if (this.resetScroll !== false) {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  exports.default = {
    name: 'reset-scroll',
    initialize: initialize
  };
});