define('lxso/models/report', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var usaDateFormat = 'MMM DD, YYYY';
  var usaDateTimeFormat = usaDateFormat + ' HH:mm:ss';

  function formatPrice(price, currency) {
    if (price) {
      var formattedPrice = Number(price.replace(/[^0-9.]+/g, ''));
      return formattedPrice.toFixed(2) + ' ' + currency;
    }
  }

  exports.default = _emberData.default.Model.extend({
    'Supplierbranchid': _emberData.default.attr('string'),
    'Supplierbranchname': _emberData.default.attr('string'),
    'Salestatus': _emberData.default.attr('string'),
    'Transactiondatetime': _emberData.default.attr('string'),
    'OriginalBookingDate': _emberData.default.attr('string'),
    'Activitytitle': _emberData.default.attr('string'),
    'Offertitle': _emberData.default.attr('string'),
    'SupplierReferenceNumber': _emberData.default.attr('string'),
    'Vouchernumber': _emberData.default.attr('string'),
    'Tickettype': _emberData.default.attr('string'),
    'PriceCode': _emberData.default.attr('string'),
    'LeadTraveler': _emberData.default.attr('string'),
    'Travelerphone': _emberData.default.attr('string'),
    'Traveleremail': _emberData.default.attr('string'),
    'Netamount': _emberData.default.attr('string'),
    'Netcurrency': _emberData.default.attr('string'),
    'Baseprice': _emberData.default.attr('string'),
    'Basecurrency': _emberData.default.attr('string'),
    'PointOfSale': _emberData.default.attr('string'),
    'DestinationArrivalDate': _emberData.default.attr('string'),
    'DestinationDepartureFlightDate': _emberData.default.attr('string'),
    'PickupLocation': _emberData.default.attr('string'),
    'DropoffLocation': _emberData.default.attr('string'),
    'DestinationArrivalFlightNumber': _emberData.default.attr('string'),
    'DestinationArrivalFlightTime': _emberData.default.attr('string'),
    'DestinationDepartureFlightNumber': _emberData.default.attr('string'),
    'DestinationDepartureFlightTime': _emberData.default.attr('string'),
    'Journey': _emberData.default.attr('string'),
    'NotificationEmailStatus': _emberData.default.attr('array'),
    'Saleid': _emberData.default.attr('string'),
    'Offerid': _emberData.default.attr('string'),
    'Activityid': _emberData.default.attr('string'),
    'CustomerStayingAt': _emberData.default.attr('string'),
    'originalNetAmount': _emberData.default.attr('string'),
    'originalNetAmountCurrency': _emberData.default.attr('string'),
    'originalBasePrice': _emberData.default.attr('string'),
    'originalBasePriceCurrency': _emberData.default.attr('string'),
    'promotionId': _emberData.default.attr('string'),
    'BQ_TravelerInfoObj': (0, _attributes.fragmentArray)('fragments/reports/bq-traveler-info'),
    'BQ Traveler Information': _emberData.default.attr('string'),
    'BQ_PickupInfoObj': _emberData.default.attr(),
    'BQ_DropOffInfoObj': _emberData.default.attr(),
    'BQ Pickup Information': _emberData.default.attr('string'),
    'BQ DropOff Information': _emberData.default.attr('string'),

    'isBooked': Ember.computed('SaleStatus', function () {
      return this.get('Salestatus') === 'Purchase';
    }),

    'isCancelled': Ember.computed('Salestatus', function () {
      return this.get('Salestatus') === 'Cancellation';
    }),

    'DisplayStatusDate': Ember.computed('Transactiondatetime', function () {
      return moment(this.get('Transactiondatetime')).format(usaDateFormat);
    }),

    'DisplayStatusDateTime': Ember.computed('Transactiondatetime', function () {
      return moment(this.get('Transactiondatetime')).format(usaDateTimeFormat);
    }),

    'DisplayOriginalBookingDate': Ember.computed('OriginalBookingDate', function () {
      return moment(this.get('OriginalBookingDate')).format(usaDateFormat);
    }),

    'DisplayOriginalBookingDateTime': Ember.computed('OriginalBookingDate', function () {
      return moment(this.get('OriginalBookingDate')).format(usaDateTimeFormat);
    }),

    'DisplayArrivalDate': Ember.computed('DestinationArrivalDate', function () {
      return moment(this.get('DestinationArrivalDate')).format(usaDateFormat);
    }),

    'DisplayDepartureDate': Ember.computed('DestinationDepartureFlightDate', function () {
      var departureFlightDate = this.get('DestinationDepartureFlightDate');
      if (departureFlightDate) {
        return moment(this.get('DestinationDepartureFlightDate')).format(usaDateFormat);
      }
    }),

    'DisplayNetPrice': Ember.computed('Netamount', 'Netcurrency', function () {
      return formatPrice(this.get('Netamount'), this.get('Netcurrency'));
    }),

    'DisplayRetailPrice': Ember.computed('Baseprice', 'Basecurrency', function () {
      return formatPrice(this.get('Baseprice'), this.get('Basecurrency'));
    }),

    'DisplayOriginalNetAmount': Ember.computed('originalNetAmount', 'originalNetAmountCurrency', function () {
      return formatPrice(this.get('originalNetAmount'), this.get('originalNetAmountCurrency'));
    }),

    'DisplayOriginalBasePrice': Ember.computed('originalBasePrice', 'originalBasePriceCurrency', function () {
      return formatPrice(this.get('originalBasePrice'), this.get('originalBasePriceCurrency'));
    }),

    'isVbpGroupTicket': Ember.computed('BQ_TravelerInfoObj', function () {
      return this.get('BQ_TravelerInfoObj').get('length') > 1;
    })

  });
});