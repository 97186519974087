define('lxso/validators/platform-url-format', ['exports', 'ember-cp-validations/validators/format'], function (exports, _format) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PlatformUrlFormat = _format.default.extend({
    validate: function validate(value, options, model, attribute) {
      var defaultOptions = {
        allowBlank: true,
        regex: /^([/]|[/][a-zA-Z0-9]([a-zA-Z0-9/._-]*))$/,
        messageKey: 'errors.customMessages.platformSettings.relativePath'
      };
      var mergedOptions = Object.assign(options, defaultOptions);
      return this._super(value, mergedOptions, model, attribute);
    }
  });

  exports.default = PlatformUrlFormat;
});