define('lxso/components/timing-capacity/component', ['exports', 'lxso/utils/form-validator'], function (exports, _formValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    daysOfWeek: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
    classNames: ['timing-capacity'],
    continueDisabled: false,
    init: function init() {
      if (this.get('isPublished')) {
        this.set('continueDisabled', true);
      }
      this.set('hasBeenSaved', false);
      this.clearErrors();
      this._super.apply(this, arguments);
      this.setupVariableCapacities();
    },
    setupVariableCapacities: function setupVariableCapacities() {
      var _this = this;

      var timingCapacities = this.get('timingCapacities');
      if (timingCapacities.get('length') === 0) {
        this.send('addEmptyTimingCapacity');
      } else {
        timingCapacities.forEach(function (timingCapacity) {
          var mondayCapacity = timingCapacity.get('monday');
          var isVariable = _this.get('daysOfWeek').some(function (day) {
            return timingCapacity.get(day) != mondayCapacity;
          });
          timingCapacity.set('isActuallyVariableCapacity', isVariable);
          if (!isVariable) {
            timingCapacity.set('capacity', mondayCapacity);
          }
        });
      }
    },
    change: function change() {
      this.set('continueDisabled', false);

      if (this.get('startTimeErrors') || this.get('hasBeenSaved')) {
        this.setCapacities();
        this.setAndAreErrors();
      }
    },
    setCapacities: function setCapacities() {
      var _this2 = this;

      var timingCapacities = this.get('timingCapacities');
      timingCapacities.forEach(function (timingCapacity) {
        if (!timingCapacity.get('isActuallyVariableCapacity')) {
          timingCapacity.set('capacity', parseInt(timingCapacity.get('capacity') || 0));
          _this2.get('daysOfWeek').forEach(function (day) {
            timingCapacity.set(day, timingCapacity.get('capacity'));
          });
        } else {
          _this2.get('daysOfWeek').forEach(function (day) {
            timingCapacity.set(day, parseInt(timingCapacity.get(day)) || 0);
          });
        }
      });
    },

    actions: {
      saveTimingCapacity: function saveTimingCapacity() {
        this.set('continueDisabled', true);
        var timingCapacities = this.get('timingCapacities');
        this.setCapacities();
        var areValidationErrors = this.setAndAreErrors();
        if (!areValidationErrors) {
          timingCapacities.arrayContentDidChange();
          this.sendAction('saveOPA', timingCapacities);
        } else {
          this.set('hasBeenSaved', true);
          this.set('continueDisabled', false);
        }
        //validate individuals - time, capacity
        //validate no duplicates
      },
      deleteItem: function deleteItem(index) {
        this.set('continueDisabled', false);
        var timingCapacities = this.get('timingCapacities');
        if (timingCapacities.get('length') > 0) {
          timingCapacities.removeAt(index, 1);
        }
        return false;
      },
      setVariableCapacity: function setVariableCapacity(timingCapacity, checked) {
        var _this3 = this;

        var clearCapacities = function clearCapacities(timingCapacity) {
          _this3.get('daysOfWeek').forEach(function (day) {
            return timingCapacity.set(day, undefined);
          });
        };
        if (checked) {
          clearCapacities(timingCapacity);
        }
        timingCapacity.set('isActuallyVariableCapacity', checked);
      },
      addEmptyTimingCapacity: function addEmptyTimingCapacity() {
        this.sendAction('addEmptyTimingCapacity', this.get('groupId'));
      }
    },
    setAndAreErrors: function setAndAreErrors() {
      var _this4 = this;

      this.clearErrors();
      this.set('startTimeErrors', null);

      var timingCapacities = this.get('timingCapacities');

      var areValidationErrors = false;
      var validationErrors = [];
      timingCapacities.forEach(function (timingCapacity, index) {
        if (!timingCapacity.get('validations.isValid')) {
          areValidationErrors = true;
          _this4.set('validationErrors-' + index, timingCapacity.get('validations.errors'));
        }
      });

      function validateDuplicateStartTimes(timingCapacities) {
        return timingCapacities.any(function (timingCapacityCompareFrom, index, timingCapacities) {
          var startTimeCompareFrom = timingCapacityCompareFrom.get('start_time');
          var startTimeArrayCompareFrom = startTimeCompareFrom.split(':');
          var hoursCompareFrom = parseInt(startTimeArrayCompareFrom[0]);
          var minutesCompareFrom = parseInt(startTimeArrayCompareFrom[1]);
          return timingCapacities.some(function (timingCapacityCompareTo, someIndex) {
            if (index === someIndex) {
              return false;
            }
            var startTimeCompareTo = timingCapacityCompareTo.get('start_time');
            var startTimeArrayCompareTo = startTimeCompareTo.split(':');
            var hoursCompareTo = parseInt(startTimeArrayCompareTo[0]);
            var minutesCompareTo = parseInt(startTimeArrayCompareTo[1]);
            return hoursCompareFrom === hoursCompareTo && minutesCompareFrom === minutesCompareTo;
          });
        });
      }

      if (!areValidationErrors) {
        if (validateDuplicateStartTimes(timingCapacities)) {
          areValidationErrors = true;
          this.set('startTimeErrors', { errors: this.get('i18n').t('pages.timingCapacity.errors.duplicate_start_time') });
        }
      }
      return areValidationErrors;
    },
    clearErrors: function clearErrors() {
      var _this5 = this;

      this.set('startTimeErrors', null);
      this.get('timingCapacities').forEach(function (timingCapacity, index) {
        _this5.set('validationErrors-' + index, null);
      });
    }
  });
});