define('lxso/helpers/logical-and', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.logicalAnd = logicalAnd;
  function logicalAnd(params) {
    return params.every(function (item) {
      return !!item || item === 0;
    });
  }

  exports.default = Ember.Helper.helper(logicalAnd);
});