define('lxso/components/uitk-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function _require(condition, message) {
    if (!condition) {
      throw message;
    }
  }

  exports.default = Ember.Component.extend({
    classNames: 'uitk-dropdown-component',

    getOptions: Ember.computed('options', 'values', 'selected-value', function () {
      var _this = this;

      var options = this.get('options');
      var values = this.get('values') ? this.get('values') : options;
      var selectedValue = this.get('selected-value');

      _require(Array.isArray(options), 'Options must be an array');
      _require(Array.isArray(values), 'Values must be an array');
      _require(options.length === values.length, 'Options and values must have the same length');
      if (selectedValue !== undefined) {
        Ember.run.schedule('afterRender', function () {
          Ember.$(_this.element).find('select').val(selectedValue);
        });
      }
      return options.map(function (label, index) {
        return { label: label, value: values[index] };
      });
    }),

    actions: {
      onSelect: function onSelect(target) {
        var text = this.$(target).find('option:selected').text();
        this.sendAction('action', target.value, text);
      }
    }
  });
});