define('lxso/adapters/ticket-type', ['exports', 'lxso/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    //we have to findAll because the api only gives us ticket types as an array, not as individuals
    //queryRecord is only for use with query.id, which there's no matching api route for

    findAll: function findAll(store, type, sinceToken, snapshot) {
      //pass id in as a function
      var activityId = snapshot.adapterOptions.activityId;
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.hostV4 + '/activity/' + activityId + '/ticket-types';
      return this.ajax(url, 'GET');
    }
  });
});