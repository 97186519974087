define('lxso/serializers/campaign-meta-data', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      promotion: {
        embedded: 'always'
      }
    },
    normalize: function normalize(typeClass, hash) {
      hash.id = hash.campaignId;
      return this._super.apply(this, arguments);
    }
  });
});