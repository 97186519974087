define('lxso/routes/platform-list', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission'], function (exports, _authenticatedRouteMixin, _featureFlag, _requirePermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requirePermission.default, {
    requiredPermission: 'PlatformRead',

    beforeModel: function beforeModel() {
      this.store.unloadAll('platform');
    },
    model: function model() {
      return this.store.findAll('platform', { reload: true });
    },
    setupController: function setupController(controller, model) {
      this.controller.set('platforms', model);
    },


    actions: {
      editPlatform: function editPlatform(platform) {
        this.transitionTo('platform', platform.id);
      }
    }
  });
});