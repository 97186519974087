define('lxso/utils/change-history-details-display-modes/limited-inventory-daily', ['exports', 'lxso/utils/change-history-details-display-modes/generic'], function (exports, _generic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    partialName: 'limited-inventory-daily',
    diff: function diff(oldValue, newValue) {
      var oldData = oldValue ? JSON.parse(oldValue) : {};
      var newData = newValue ? JSON.parse(newValue) : {};

      return {
        date: {
          oldValue: format(oldData.date),
          newValue: format(newData.date),
          displayMode: _generic.default
        },
        capacity: {
          oldValue: oldData.quantity,
          newValue: newData.quantity,
          displayMode: _generic.default
        }
      };
    }
  };


  function format(dateString) {
    return dateString ? moment(dateString).format('YYYY-MM-DD') : '';
  }
});