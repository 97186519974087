define('lxso/routes/createactivity', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/config/environment', 'lxso/mixins/require-supplier-branch', 'lxso/mixins/require-permission', 'lxso/utils/form-validator', 'lxso/utils/omniture', 'lxso/constants/status-v4'], function (exports, _authenticatedRouteMixin, _environment, _requireSupplierBranch, _requirePermission, _formValidator, _omniture, _statusV) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, _requirePermission.default, {
    requiredPermission: 'V3CreateProduct',
    supplierBranch: Ember.inject.service('supplier-branch'),
    session: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service('ajax-wrapper'),
    staticData: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    activityPublishFlag: Ember.inject.service(),
    tshop: Ember.inject.service(),
    v4ContentService: Ember.inject.service(),
    v4CatalogService: Ember.inject.service(),
    activityMigrateSupport: Ember.inject.service(),

    omniture: {
      pageName: 'page.LX-Supply.SelectProductType'
    },

    model: function model() {
      var route = this;
      var store = route.store;
      var branchId = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
      var isAllOffersMigratedToV4 = this.get('activityMigrateSupport').fetchOfferMigrateStatus(branchId);
      var activity = store.createRecord('activity', {
        basic: store.createFragment('activity/basic'),
        operational: store.createFragment('activity/operational'),
        supplier_branch: store.createFragment('activity/supplier_branch'),
        attributes: store.createFragment('activity/attributes')
      });
      activity.set('activity_type', 'Tours');

      return Ember.RSVP.hash({
        isAllOffersMigratedToV4: isAllOffersMigratedToV4,
        activity: activity
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this.controller.set('activity', model.activity);
      this.controller.set('isAllOffersMigratedToV4', model.isAllOffersMigratedToV4);
      this.controller.set('typeaheadError', false);
      this.controller.set('errors', []);

      this.controller.set('tourCategory', 'Other Tours');
      this.controller.set('attractionCategory', 'Attractions');
      this.get('activityPublishFlag').setPublishFlag(false);
      var groundTransferCategories = this.get('staticData').getGroundTransferCategories();
      this.controller.set('groundTransferCategoryNames', groundTransferCategories);
      this.controller.set('groundTransferCategoryValues', groundTransferCategories);
      this.get('supplierBranch').getSelectedBranch().then(function (branch) {
        _this.controller.set('supplierBranchType', branch.supplier_branch_type);
      });
    },
    sendOmnitureDataOnSave: function sendOmnitureDataOnSave(category, isGroundTransfer) {
      var event = '';
      if (category === 'Shared Transfer') {
        event = 'event22';
      } else if (category === 'Private Transfer') {
        event = 'event23';
      } else if (category === 'Mass Transportation') {
        event = 'event24';
      } else {
        event = 'event9'; //event for Tours
      }
      if (isGroundTransfer) {
        (0, _omniture.default)({
          pageName: 'page.LX-Supply.SaveGT',
          events: event
        });
      } else {
        (0, _omniture.default)({
          pageName: 'page.LX-Supply.SaveProduct',
          events: event
        });
      }
    },


    actions: {
      setType: function setType(type) {
        (0, _omniture.default)({
          events: 'event9' //event9 tracks successful advances through this page ("Tour" button clicks)
          //event10 is reserved for "Other" when it becomes available
        });

        this.transitionTo('activity.name', type);
      },
      createActivity: function createActivity(category) {
        var _this2 = this;

        this.controller.set('currentCategory', category);
        var route = this;
        var store = this.store;
        var isV4ActivityCreateEnabled = this.get('featureFlags').checkEnabled('ActivityCreateV4');
        var isV4ActivityCreateWithContentEnabled = this.get('featureFlags').checkEnabled('ActivityCreateV4WithContentAndLogistics');
        var controller = this.controller;
        var activity = route.controller.get('activity');
        var isGroundTransfer = false;
        activity.set('operational.internal_category', category);

        if (category == this.controller.get('tourCategory')) {
          activity.set('basic.long_title', this.controller.get('tourTitle'));
        } else if (this.controller.get('groundTransferCategoryValues').includes(category)) {
          isGroundTransfer = true;
          activity.set('basic.long_title', this.controller.get('groundTransferTitle'));
        } else {
          activity.set('basic.long_title', this.controller.get('attractionTitle'));
        }
        activity.set('basic.isGroundTransfer', isGroundTransfer);
        this.get('supplierBranch').getSelectedBranch().then(function (supplier) {
          activity.set('supplier_branch.supplier_branch_id', supplier.supplier_branch_id);
          return (0, _formValidator.validate)({ model: activity.get('basic'), attributes: ['long_title'] });
        }).then(function () {
          controller.set('saving', true);
          activity.set('basic.internal_title', activity.get('basic.long_title'));
          if (isV4ActivityCreateEnabled) {
            var v4ActivityCreateUrl = _environment.default.api.hostV4 + '/activity';
            var body = {
              status: _statusV.default.ACTIVE,
              version: 3,
              internalTitle: activity.get('basic.internal_title'),
              supplierBranchId: activity.get('supplier_branch.supplier_branch_id'),
              internalCategory: activity.get('operational.internal_category'),
              autoRenewRateEnabled: _this2.get('session.session.authenticated.selectedBranch.auto_renew_rate_enabled')
            };

            var isOffersMigrated = _this2.controller.get('isAllOffersMigratedToV4');
            var urlWithQueryParams = v4ActivityCreateUrl + '?createMigratedOfferGroups=' + isOffersMigrated;
            return _this2.get('ajaxWrapper').ajaxPost(urlWithQueryParams, body, isOffersMigrated);
          } else {
            return activity.createNew({
              version: 3,
              supplier_branch_id: activity.get('supplier_branch.supplier_branch_id'),
              internal_category: activity.get('operational.internal_category'),
              internal_title: activity.get('basic.internal_title')
            });
          }
        }).then(function (resp) {
          var activityId = isV4ActivityCreateEnabled ? resp : resp.activity_id;
          activity.set('id', activityId);
          //either we should get migration status as response of v4ActivityCreate API or use a feature flag as above
          //or make an extra request to activity get API to fetch migration status, but read DB delay might cause issues.
          if (isV4ActivityCreateWithContentEnabled) {
            return _this2.get('v4ContentService').updateBasic(activityId, activity.get('basic'));
          } else {
            return activity.updateBasic(activity.get('basic'));
          }
        }).then(function () {
          if (isV4ActivityCreateWithContentEnabled) {
            return _this2.get('v4CatalogService').updateActivityTitle(activity.get('id'), activity.get('basic'));
          } else {
            return;
          }
        }).then(function () {
          activity.set('attributes.suggested_rank', '99');
          if (isGroundTransfer) {
            var uxGrouping = Ember.A(['Private Transfers']);
            activity.set('attributes.user_experience_group', uxGrouping);
          }
          return activity.updateAttributes(activity.get('attributes'));
        }).then(function () {
          _this2.sendOmnitureDataOnSave(category, isGroundTransfer);
          controller.set('saving', false);
          if (isV4ActivityCreateEnabled) {
            var vendorId = _this2.get('session.session.authenticated.selectedBranch.vendor_id');
            var vendorBranchId = _this2.get('session.session.authenticated.selectedBranch.vendor_branch_id');
            _this2.get('tshop').createTShopIdForActivity(activity.get('id'), vendorId, vendorBranchId);
          }
          _this2.transitionTo('activity.overview', activity.get('id'));
        }).catch(function (reason) {
          controller.set('saving', false);
          if (category == _this2.controller.get('groundTransferCategory')) {
            reason.long_title_gt = reason.long_title;
            delete reason.long_title;
          }
          controller.set('errors', reason);
          controller.set('activity.id', null);
        });
      },
      categorySelected: function categorySelected(category) {
        this.controller.set('groundTransferCategory', category);
      },
      willTransition: function willTransition() {
        //for tests
        delete window.pageName;
      },
      didTransition: function didTransition() {
        //for tests
        this.controller.set('tourTitle', '');
        this.controller.set('attractionTitle', '');
        this.controller.set('groundTransferTitle', '');
        window.pageName = 'createActivityRoute';
      }
    }

  });
});